import React, { useState } from 'react';
import {
  Box,
  Button,
  Typography,
  TextField,
  Modal,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';
import {
  getDocumentVersionsById,
  deleteVersionRequest,
  downloadVersionRequest
} from '../../../constants/requests';
import { style } from './styles';
import { useLoading } from '../../../hooks/useLoading';
import { useNotifications } from '../../../hooks/useNotifications';
import moment from 'moment';
import TemplateTable from './TemplateTable';
import ModalNotFound from '../../Services/common/ModalNotFound';

const VersionManager = ({
  heading,
  formDataText,
  entityId,
  uploadRequest,
  refreshCB,
  docManagerId
}) => {
  const { request } = useLoading();
  const { showNotifications } = useNotifications();
  const [selectedFile, setSelectedFile] = useState(null);
  const [idOfDocument, setIdOfDocument] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [openVersionTable, setOpenVersionTable] = useState(false);
  const [versionView, setVersionView] = useState([]);

  const handleClickOpen = () => {
    setOpenDialog(true);
  };
  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleCloseVersionTable = () => {
    setOpenVersionTable(false);
  };

  const handleReUpload = async () => {
    const formData = new FormData();
    console.log(docManagerId);
    formData.append(formDataText, selectedFile);
    try {
      const res = await request(() => uploadRequest(entityId, formData));
      console.log(res);
      if (
        res?.response?.data?.statusCode >= 400 ||
        res?.response?.data?.statusCode <= 500
      ) {
        showNotifications('error', res.response.data.message, 5000);
      } else if (res.data.statusCode === 200) {
        await refreshCB();
        showNotifications('success', res.data.message, 5000);
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
    handleClose();
  };

  const handleVersions = async () => {
    setOpenVersionTable(true);
    try {
      const res = await request(() => getDocumentVersionsById(docManagerId));
      if (res.data.statusCode === 200) {
        setIdOfDocument(res.data.body._id);
        const versions = res.data.body.versions.map((version) => {
          return {
            versionId: version.versionId,
            createdAt: moment(version.createdAt).format('DD/MM/YYYY HH:mm:ss'),
            Action: 'Delete, Download'
          };
        });
        setVersionView(versions);
        showNotifications('success', res.data.message, 5000);
      } else {
        showNotifications('error', res.data.message, 5000);
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  const handleDeleteVersions = async (idx) => {
    const versionToBeRemoved = versionView[idx]['versionId'];
    try {
      const res = await request(() =>
        deleteVersionRequest(idOfDocument, versionToBeRemoved)
      );
      if (res.data.statusCode === 200) {
        const newVersionView = versionView.filter(
          (version) => version['versionId'] !== versionToBeRemoved
        );
        setVersionView(newVersionView);
        showNotifications('success', res.data.message, 5000);
      } else {
        showNotifications('error', res.data.message, 5000);
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  const handleDownloadVersion = async (idx) => {
    try {
      console.log(versionView[idx]);
      const versionToBeDownloaded = versionView[idx]['versionId'];
      console.log(idOfDocument, versionToBeDownloaded, 'Yes');
      const res = await request(() =>
        downloadVersionRequest(idOfDocument, versionToBeDownloaded)
      );
      if (res.data.statusCode === 200) {
        window.location = res.data.body['signedUrl'];
        showNotifications('success', 'Downloaded successfully', 5000);
      } else if (res?.response?.data?.statusCode) {
        showNotifications('error', res.response.data.message, 5000);
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  return (
    <>
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-around',
          marginBottom: '40px'
        }}
      >
        <Typography variant="h5">
          {docManagerId ? 'ReUpload' : 'Upload'} {heading}
        </Typography>
        <TextField
          type="file"
          name="master"
          label={`Upload ${formDataText}`}
          onChange={(e) => setSelectedFile(e.target.files[0])}
          InputLabelProps={{ shrink: true }}
        />
        <Box
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            gap: '10px'
          }}
        >
          <Button
            variant="contained"
            style={{ height: '40px' }}
            onClick={handleVersions}
          >
            View
          </Button>
          <Button
            variant="contained"
            style={{ height: '40px', background: '#000', color: '#fff' }}
            onClick={docManagerId ? handleClickOpen : handleReUpload}
          >
            {docManagerId ? 'ReUpload' : 'Upload'}
          </Button>
        </Box>
      </Box>

      <Dialog
        open={openDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" style={{ color: 'red' }}>
          {'!Warning'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            style={{ color: 'red' }}
          >
            All Old Data will be Retained on ReUpload. But Please make changes
            for new data accordingly
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Disagree</Button>
          <Button onClick={handleReUpload} autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>

      <Modal
        open={openVersionTable}
        onClose={handleCloseVersionTable}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={style}
          style={{ width: '80%', height: '80%', overflowX: 'scroll' }}
        >
          <Box
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: '20px'
            }}
          >
            <Typography
              fullWidth
              id="modal-modal-title"
              variant="h5"
              component="h2"
            >
              {heading} Versions Info
            </Typography>
            <Button
              color="error"
              variant="contained"
              onClick={handleCloseVersionTable}
            >
              Close
            </Button>
          </Box>
          {versionView.length > 0 ? (
            <TemplateTable
              data={versionView}
              deleteCB={handleDeleteVersions}
              downloadCB={handleDownloadVersion}
            />
          ) : (
            <ModalNotFound data={`Versions For This ${heading}`} />
          )}
        </Box>
      </Modal>
    </>
  );
};

export default VersionManager;
