import React, { useState, useEffect } from 'react';
import { HolidayList } from '../../../components/HolidayList';
import { MasterHeader } from '../../../components/Master/Header';
import { MonthlyCalendarView } from '../../../components/MonthlyCalendarView';
import {
  getHolidaysOfMonthRequest,
  addHolidayRequest
} from '../../../constants/requests';
import { useDataLayerValue } from '../../../contextAPI/DataLayer';
import { useLoading } from '../../../hooks/useLoading';
import { useNotifications } from '../../../hooks/useNotifications';

export const MasterHoliday = () => {
  const today = new Date();
  const [month, setMonth] = useState(today.getMonth() + 1);
  const [year, setYear] = useState(today.getFullYear());
  const [holidays, setHolidays] = useState([]);
  const [updated, setUpdated] = useState(false);
  const { request } = useLoading();
  const { showNotifications } = useNotifications();
  const [user] = useDataLayerValue();
  const { fname, lname, email } = user;
  const { CREATE, DELETE } = user.rights.Holiday;

  const refresh = () => {
    setUpdated(!updated);
  };

  const getHolidays = async () => {
    try {
      const response = await request(() =>
        getHolidaysOfMonthRequest(month, year)
      );
      if (response.data.statusCode === 200) {
        setHolidays(response.data.body.days);
      } else {
        setHolidays([]);
        showNotifications('error', response.data.message, 5000);
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
      console.log(err);
    }
  };

  const addHoliday = async (dateStr) => {
    const holidayDate = new Date(dateStr);
    // console.log(holidayDate);
    const reqBody = {
      empName: `${fname} ${lname}`,
      empEmail: email,
      day: holidayDate.getDate()
    };
    // console.log(reqBody);
    try {
      request(() =>
        addHolidayRequest(
          reqBody,
          holidayDate.getMonth() + 1,
          holidayDate.getFullYear()
        ).then((response) => {
          if (response.data.statusCode === 200) {
            showNotifications('success', response.data.message, 5000);
            setUpdated(!updated);
          } else {
            showNotifications('error', response.data.message, 5000);
          }
        })
      );
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
      console.log(err);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    addHoliday(event.target.dateToBeAdded.value);
  };

  useEffect(() => {
    getHolidays();
  }, [month, year, updated]);

  return (
    <>
      <MasterHeader
        title="Holidays"
        options={{
          importOption: false,
          exportOption: false,
          createOption: false
        }}
      />
      <br />
      <MonthlyCalendarView
        month={month}
        year={year}
        highlightedDays={holidays}
      />
      <HolidayList
        holidays={holidays}
        month={month}
        year={year}
        setMonth={setMonth}
        setYear={setYear}
        refresh={refresh}
        holidayAdmin={DELETE}
        fname={fname}
        lname={lname}
        email={email}
      />
      {CREATE ? (
        <form onSubmit={handleSubmit}>
          <label>
            Add Holiday:
            <input type="date" name="dateToBeAdded" />
          </label>
          <input type="submit" value="Submit" />
        </form>
      ) : null}
    </>
  );
};
