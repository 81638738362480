import React from 'react';
import { AppBar, Toolbar, Tooltip, IconButton, Avatar } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import NotificationsIcon from '@mui/icons-material/Notifications';
import LogoutIcon from '@mui/icons-material/Logout';
import { useDataLayerValue } from '../../contextAPI/DataLayer';
import { Link, useNavigate } from 'react-router-dom';

export const Navbar = ({ handleDrawerOpen, open }) => {
  const [user, dispatch] = useDataLayerValue();
  function handleLogout() {
    localStorage.removeItem('token');
    dispatch({
      type: 'SET_LOGGED',
      loggedIn: false
    });
  }
  const navigate = useNavigate();
  return (
    <AppBar position="fixed" open={open} sx={{ backgroundColor: 'white' }}>
      <Toolbar>
        <IconButton
          color="black"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          sx={{ mr: 2, ...(open && { display: 'none' }) }}
        >
          <MenuIcon />
        </IconButton>
        <Tooltip title="Notifications">
          <IconButton
            size="large"
            color="inherit"
            sx={{ ml: 'auto' }}
            onClick={() => {
              navigate('/');
            }}
          >
            <NotificationsIcon sx={{ color: 'black' }} />
          </IconButton>
        </Tooltip>
        <Tooltip title="Logout">
          <IconButton
            size="large"
            color="inherit"
            onClick={() => handleLogout()}
          >
            <LogoutIcon sx={{ color: 'black' }} />
          </IconButton>
        </Tooltip>
        <Tooltip title="View Your Details">
          <Link to="/master/data" state={{ vals: user, editOption: true }}>
            <Avatar
              sx={{
                bgcolor: 'grey',
                cursor: 'pointer',
                height: 40,
                width: 40,
                ml: 2
              }}
            >
              {user.fname.charAt(0) + user.lname.charAt(0)}
            </Avatar>
          </Link>
        </Tooltip>
      </Toolbar>
    </AppBar>
  );
};
