import React, { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import { Button, Typography } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { useNavigate, useParams } from 'react-router-dom';
import { getAllVersionsOfPolicyTemplateById } from '../../../constants/requests';
import { useLoading } from '../../../hooks/useLoading';
import { useNotifications } from '../../../hooks/useNotifications';
import AddNewQuestionTable from '../Components/AddNewQuestionTable';

const FillPolicyTemplate = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { request } = useLoading();
  const { showNotifications } = useNotifications();

  const [policyName, setPolicyName] = useState('');
  const [policyType, setPolicyType] = useState('');
  const [policyTemplateTypes, setPolicyTemplateTypes] = useState([]);
  const [tableTagsType, setTableTagsType] = useState('Tags');

  const [originalTableData, setOriginalTableData] = useState([]);
  const [possible, setPossible] = useState(false);
  const [tableData, setTableData] = useState([]);

  const getCurrentPolicyTemplate = async () => {
    try {
      const response = await request(() =>
        getAllVersionsOfPolicyTemplateById(params.id)
      );
      if (response?.data?.statusCode === 200) {
        setPolicyName(response.data.body.name);
        setPolicyType(response.data.body.type);
        const tags =
          response.data.body.wordDocumentID.versions.at(-1).data.tags;
        const { data } = response.data.body.wordDocumentID.versions.at(-1);
        const tableNames = data?.tables ? Object.keys(data.tables) : [];
        const newTableData = tags.map(({ Tag, Question }) => ({
          Tag,
          Question,
          Type: 'tag',
          status: 'common'
        }));
        tableNames.forEach((tableName) => {
          newTableData.push({
            Tag: tableName,
            Question: 'Q. ',
            Type: 'table',
            status: 'common'
          });
        });
        setTableData([...newTableData]);
        setOriginalTableData([...newTableData]);
        setPossible(true);
      } else {
        if (response.response.data.statusCode !== 200) {
          showNotifications('error', response.response.data.message, 5000);
        }
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  useEffect(() => {
    getCurrentPolicyTemplate();
    originalTableData;
  }, []);

  return (
    <React.Fragment>
      <Box
        style={{
          marginBottom: '25px',
          display: 'flex',
          justifyContent: 'space-between'
        }}
      >
        <Button
          sx={{
            color: 'white',
            backgroundColor: 'black',
            paddingLeft: 1
          }}
          variant="contained"
          onClick={() => navigate(`/policy`)}
        >
          <ChevronLeftIcon />
          Back
        </Button>
      </Box>
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '30px'
        }}
      >
        <Typography sx={{ fontWeight: 'bold', flexGrow: 1 }} variant="h4">
          Policy Data
        </Typography>
      </Box>
      <Box>
        {possible && (
          <AddNewQuestionTable
            tableData={tableData}
            setTableData={setTableData}
            policyName={policyName}
            setPolicyName={setPolicyName}
            policyType={policyType}
            setPolicyType={setPolicyType}
            policyTemplateTypes={policyTemplateTypes}
            setPolicyTemplateTypes={setPolicyTemplateTypes}
            tableTagsType={tableTagsType}
            setTableTagsType={setTableTagsType}
            disableTagsAndTables={true}
          />
        )}
      </Box>
    </React.Fragment>
  );
};

export default FillPolicyTemplate;
