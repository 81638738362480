import React from 'react';
import { Box, Button, Modal, Typography } from '@mui/material';
import { deletePolicyEngagement } from '../../../constants/requests';
import { useLoading } from '../../../hooks/useLoading';
import { useNotifications } from '../../../hooks/useNotifications';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  borderRadius: '10px',
  boxShadow: 24,
  p: 4,
  maxHeight: '100%',
  minWidth: '600px'
};

const DeleteClientPolicyModal = ({
  openDeleteModal,
  handleCloseDeleteModal,
  selectEngagementId,
  getClients
}) => {
  const { request } = useLoading();
  const { showNotifications } = useNotifications();

  const deletePolicyHandler = async () => {
    try {
      const response = await request(() =>
        deletePolicyEngagement(selectEngagementId)
      );
      if (response?.data?.statusCode === 200) {
        handleCloseDeleteModal();
        getClients();
        showNotifications('success', response.data.message, 5000);
      } else {
        if (response.response.data.statusCode !== 200) {
          showNotifications('error', response.response.data.message, 5000);
        }
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  return (
    <Modal
      open={openDeleteModal}
      onClose={handleCloseDeleteModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography
          variant="h5"
          component="h2"
          style={{ marginBottom: '20px' }}
        >
          Delete Client
        </Typography>
        <Typography
          id="modal-modal-description"
          style={{ marginBottom: '20px' }}
        >
          Are you sure you want to delete this Client? Note: This action is
          irreversible
        </Typography>
        <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button
            variant="contained"
            color="error"
            style={{ marginTop: '10px' }}
            onClick={deletePolicyHandler}
          >
            Delete Client
          </Button>
          <Button
            variant="contained"
            style={{ marginTop: '10px' }}
            onClick={handleCloseDeleteModal}
          >
            Close
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default DeleteClientPolicyModal;
