import React, { useState, useEffect } from 'react';
import { Box, Button, Modal, Typography } from '@mui/material';
import { useLoading } from '../../../hooks/useLoading';
import { useNotifications } from '../../../hooks/useNotifications';
import { useParams, useNavigate } from 'react-router-dom';
import {
  findAllLibraryTypeByThreat,
  findGuidelineById,
  findGuidelinesByProperties,
  findThreatById,
  findThreatByProperties,
  updateThreatById
} from '../../../constants/requests';
import CustomGrid from '../common/CustomGrid';
import CreateEditDialog from '../common/CreateEditDialog';
import DeleteDialog from '../common/DeleteDialog';
import CustomGuideTable from '../AssetRepository/CustomGuideTable';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4
};

const ThreatImpact = () => {
  const navigate = useNavigate();
  const { currentAssetTabName, assetId } = useParams();
  const [ThreatData, setThreatData] = useState(null);
  console.log(currentAssetTabName);
  const [ThreatId, setId] = useState(null);
  const { request } = useLoading();
  const { showNotifications } = useNotifications();

  const [delRow, setDelRow] = useState(null);
  const [GuidelinesData, setGuidelinesData] = useState(null);

  const [addRecordForm, setAddRecordForm] = useState([]);
  const [openAddRecordModal, setOpenAddRecordModal] = useState(false);
  const [openDeleteRecordModal, setOpenDeleteRecordModal] = useState(false);

  const handleOpenAddRecordModal = () => setOpenAddRecordModal(true);
  const handleCloseAddRecordModal = () => setOpenAddRecordModal(false);
  const handleOpenDeleteRecordModal = (row) => {
    setDelRow(row);
    setOpenDeleteRecordModal(true);
  };
  const handleCloseDeleteRecordModal = () => {
    setDelRow(null);
    setOpenDeleteRecordModal(false);
  };

  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  //for nomalised impact potential
  const [openN, setOpenN] = useState(false);

  const handleOpenN = () => setOpenN(true);
  const handleCloseN = () => setOpenN(false);

  //for Aggregate impact potential
  const [openA, setOpenA] = useState(false);

  const handleOpenA = () => setOpenA(true);
  const handleCloseA = () => setOpenA(false);

  const createAddRecordForm = (metaProperties) => {
    const { fields } = metaProperties;
    const recordForm = Object.keys(fields)
      .map((key) => {
        if (
          key === 'Threat' ||
          key === 'Normalised Impact Potential' ||
          key === 'Impact Potential' ||
          key === 'Aggregate Threat Impact Potential'
        ) {
          return null;
        }
        return {
          type: fields[key]['fieldType'],
          fieldName: key,
          fieldLabel: `Enter ${key}`,
          value: '',
          required: false,
          dropDownOptions: fields[key]?.['options']
        };
      })
      .filter((field) => field !== null);
    setAddRecordForm(recordForm);
  };
  const loadApplication = async () => {
    try {
      const threats = await request(() => findAllLibraryTypeByThreat());
      if (threats.data.statusCode !== 200) {
        //console.log('threat not found');
        showNotifications('error', 'No Threat Found', 5000);
        return;
      }

      const threatrows = [...ThreatData['data']['rows']['Threat Impact']];
      let newrows = [];
      let tot_threats = [];
      let threats_in_lib = [];
      threatrows.forEach((threat) => {
        tot_threats.push(threat['Threat']);
      });
      threats.data.body.forEach((threat) => {
        threats_in_lib.push(threat['description']);
      });
      let addthreat = [];
      threats_in_lib.forEach((threat) => {
        if (!tot_threats.includes(threat)) {
          addthreat.push(threat);
        }
      });
      let removethreat = [];
      tot_threats.forEach((threat) => {
        if (!threats_in_lib.includes(threat)) {
          removethreat.push(threat);
        }
      });
      threatrows.forEach((row) => {
        if (!removethreat.includes(row['Threat'])) {
          newrows.push(row);
        }
      });
      threats.data.body.forEach((row) => {
        if (addthreat.includes(row['description'])) {
          newrows.push({
            'Threat No': row['name'],
            Threat: row['description']
          });
        }
      });

      const newThreatData = { ...ThreatData };
      newThreatData.data.rows['Threat Impact'] = newrows;
      const response = await request(() =>
        updateThreatById(ThreatId, newThreatData)
      );
      // UpdateThreatNoWithLibrary();

      if (response.data.statusCode === 200) {
        console.log(response);
        setThreatData(newThreatData);
        showNotifications('success', response.data.message, 5000);
      } else {
        console.log(response);
        showNotifications('error', response.data.message, 5000);
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };
  const getThreatRepositoryById = async (ThreatId) => {
    try {
      const res = await request(() => findThreatById(ThreatId));

      if (res.data.statusCode === 200) {
        setThreatData(res.data.body);
        console.log(res.data.body.data);
        createAddRecordForm(ThreatData.data.meta.data);
        showNotifications('success', res.data.message, 5000);
      } else {
        showNotifications('error', res.data.message, 5000);
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  const getThreatRepository = async () => {
    try {
      const res = await request(() => findThreatByProperties());

      if (res.data.statusCode === 200) {
        setId(res.data.body[0]._id);
        //console.log(res.data.body);

        getThreatRepositoryById(res.data.body[0]._id);
        showNotifications('success', res.data.message, 5000);
      } else {
        showNotifications('error', res.data.message, 5000);
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  const handleSave = async () => {
    try {
      const payload = ThreatData;

      console.log(payload);
      const res = await request(() => updateThreatById(ThreatId, payload));
      if (res.data.statusCode === 200) {
        console.log(res);
        setThreatData(ThreatData);
        showNotifications('success', res.data.message, 5000);
      } else {
        showNotifications('error', res.data.message, 5000);
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  const handleAddRecord = async (newRecord) => {
    try {
      console.log(newRecord);
      // UpdateThreatNoWithLibrary();
      const newData = {};
      Object.keys(newRecord).map((record) => {
        newData[record] = newRecord[record]['value'];
      });

      //matching from library threat
      const res = await request(() => findAllLibraryTypeByThreat());
      console.log(res);
      const threatNo = newData['Threat No']; // Threat No from the newRecord
      console.log(newData['Threat No']);
      const matchedThreat = res.data.body.find(
        (threat) => threat.name === threatNo
      );
      if (matchedThreat) {
        newData['Threat'] = matchedThreat.description;
      }

      const forewarning = parseInt(newData['Forewarning'], 10) || 0;
      const speedOfOnset = parseInt(newData['Speed of Onset'], 10) || 0;
      const duration = parseInt(newData['Duration'], 10) || 0;
      const impactOnHR =
        parseInt(newData['Impact on Human Resources'], 10) || 0;
      const impactOnInfoAssets =
        parseInt(newData['Impact on Information Assets'], 10) || 0;
      const impactOnIntangibleAssets =
        parseInt(
          newData[
            'Impact on the Intangible Assets (legal intangible assets such as copyrights, patents, and trademarks; reputation etc.)'
          ],
          10
        ) || 0;
      const likelihood = parseInt(newData['Threat Likelihood'], 10) || 0;

      const impactPotential =
        forewarning +
        speedOfOnset +
        duration +
        impactOnHR +
        impactOnInfoAssets +
        impactOnIntangibleAssets;

      newData['Impact Potential'] = impactPotential;
      newData['Normalised Impact Potential'] = impactPotential / 2;

      const aggregateimpactpotential = impactPotential * likelihood;
      newData['Aggregate Threat Impact Potential'] = aggregateimpactpotential;

      //console.log(newData['Aggregate Threat Impact Potential']);

      const newThreatData = { ...ThreatData };

      let newRows = newThreatData['data']['rows']['Threat Impact'];
      newRows = [...newRows, newData];
      newThreatData['data']['rows']['Threat Impact'] = newRows;
      const payload = newThreatData;

      let response = await request(() => updateThreatById(ThreatId, payload));
      console.log(response);
      if (response.data.statusCode === 200) {
        console.log(newThreatData);
        setThreatData(newThreatData);
        handleCloseAddRecordModal();
        showNotifications('success', response.data.message, 5000);
      } else {
        showNotifications('error', response.data.message, 5000);
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
      console.log(err);
    }
  };

  const handleDeleteRecord = async () => {
    try {
      const filteredData = ThreatData['data']['rows']['Threat Impact'].filter(
        (row) => JSON.stringify(row) !== JSON.stringify(delRow)
      );

      const newThreatData = { ...ThreatData };
      ThreatData['data']['rows']['Threat Impact'] = filteredData;
      const payload = newThreatData;

      const response = await request(() => updateThreatById(ThreatId, payload));

      if (response.data.statusCode === 200) {
        setThreatData(newThreatData);
        handleCloseDeleteRecordModal();
        showNotifications('success', response.data.message, 5000);
      } else {
        showNotifications('error', response.data.message, 5000);
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
      console.log(err);
    }
  };

  const handleRowUpdate = (newRow, oldRow) => {
    const newThreatRows = [...ThreatData.data.rows['Threat Impact']];
    console.log(newThreatRows);

    const forewarning = parseInt(newRow['Forewarning'], 10) || 0;
    const speedOfOnset = parseInt(newRow['Speed of Onset'], 10) || 0;
    const duration = parseInt(newRow['Duration'], 10) || 0;
    const likelihood = parseInt(newRow['Threat Likelihood'], 10) || 0;
    const impactOnHR = parseInt(newRow['Impact on Human Resources'], 10) || 0;
    const impactOnInfoAssets =
      parseInt(newRow['Impact on Information Assets'], 10) || 0;
    const impactOnIntangibleAssets =
      parseInt(
        newRow[
          'Impact on the Intangible Assets (legal intangible assets such as copyrights, patents, and trademarks; reputation etc.)'
        ],
        10
      ) || 0;

    const impactPotential =
      forewarning +
      speedOfOnset +
      duration +
      impactOnHR +
      impactOnInfoAssets +
      impactOnIntangibleAssets;

    newRow['Impact Potential'] = impactPotential;
    newRow['Normalised Impact Potential'] = impactPotential / 2;
    newRow['Aggregate Threat Impact Potential'] = impactPotential * likelihood;

    // console.log(newRow['Aggregate Threat Impact Potential']);
    //console.log(newRow['Impact Potential']);
    //console.log(newRow['Normalised Impact Potential']);

    newThreatRows.forEach((element, index) => {
      if (JSON.stringify(oldRow) === JSON.stringify(element)) {
        newThreatRows[index] = newRow;
      }
    });

    const newThreatData = { ...ThreatData };
    //console.log(newThreatData);
    newThreatData.data.rows['Threat Impact'] = newThreatRows;
    // console.log(newThreatData);

    setThreatData(newThreatData);
    //console.log(ThreatData);
  };

  const getGuidelinesRepositoryById = async (GuidelinesId) => {
    try {
      const res = await request(() => findGuidelineById(GuidelinesId));

      if (res.data.statusCode === 200) {
        setGuidelinesData(res.data.body);

        showNotifications('success', res.data.message, 5000);
      } else {
        showNotifications('error', res.data.message, 5000);
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  const getGuidelinesRepository = async () => {
    try {
      const res = await request(() => findGuidelinesByProperties());

      if (res.data.statusCode === 200) {
        getGuidelinesRepositoryById(res.data.body[2]._id);

        showNotifications('success', res.data.message, 5000);
      } else {
        showNotifications('error', res.data.message, 5000);
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  const handleClick = () => {
    navigate(-1);
  };

  useEffect(() => {
    getThreatRepository();
    getGuidelinesRepository();
  }, []);

  return (
    <React.Fragment>
      <Box
        style={{
          marginBottom: '25px',
          display: 'flex',
          justifyContent: 'space-between'
        }}
      ></Box>
      <Button
        sx={{
          color: 'white',
          backgroundColor: 'black',
          flexDirection: 'end',
          marginBottom: '20px'
        }}
        variant="contained"
        onClick={handleClick}
      >
        <ChevronLeftIcon />
        Asset Repository
      </Button>

      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          gap: '10px',
          marginBottom: '10px'
        }}
      >
        <Typography sx={{ fontWeight: 'bold' }} variant="h4">
          Threat Impact
        </Typography>
        <Button
          sx={{
            color: 'white',
            backgroundColor: 'black',
            flexDirection: 'end'
          }}
          variant="contained"
          onClick={() => {
            navigate(`/services/libraryMasterIso`);
          }}
        >
          <ChevronRightIcon />
          LibraryMaster
        </Button>
      </Box>

      <Box style={{ marginBottom: '40px' }}>
        <Box
          style={{
            display: 'flex',
            gap: '10px',
            marginBottom: '10px',
            justifyContent: 'flex-end',
            alignItems: 'flex-end'
          }}
        >
          <Button
            sx={{
              color: 'white',
              backgroundColor: 'black'
            }}
            variant="contained"
            onClick={handleOpenAddRecordModal}
          >
            Add Record
          </Button>

          <Button
            variant="contained"
            color="success"
            onClick={handleSave}
            disabled={ThreatData === null}
          >
            Save
          </Button>
          <Button
            onClick={loadApplication}
            sx={{
              color: 'white',
              backgroundColor: 'blue',
              alignContent: 'space-between'
            }}
            variant="contained"
          >
            Refresh
          </Button>
          <Button
            sx={{
              color: 'white',
              backgroundColor: '#ff5722',
              alignContent: 'center'
            }}
            variant="contained"
            disabled={ThreatData === null}
            onClick={() => {
              navigate(
                `/services/asset/${currentAssetTabName}/${assetId}/threat/${ThreatId}/threatVul`
              );
            }}
          >
            Connect Threat Vulnerability
          </Button>
        </Box>
      </Box>

      <Box
        style={{
          display: 'flex',
          justifyContent: 'end',
          marginBottom: '40px'
        }}
      ></Box>

      {ThreatData !== null && ThreatData.data !== null ? (
        <CustomGrid
          rows={ThreatData.data.rows['Threat Impact']}
          fieldsMeta={ThreatData.data.meta.fields}
          tableMeta={ThreatData.data.meta.Threat}
          handleChangeCB={handleRowUpdate}
          deleteCB={handleOpenDeleteRecordModal}
        />
      ) : (
        <Box style={{ display: 'flex', justifyContent: 'center' }}>
          <Typography sx={{ fontWeight: 'bold', color: 'Red' }} variant="h5">
            No Data Available to Track
          </Typography>
        </Box>
      )}

      <Box
        style={{
          display: 'flex',
          marginTop: '40px',
          marginBottom: '10px',
          justifyContent: 'space-between'
        }}
      >
        <Button variant="contained" color="success" onClick={handleOpen}>
          Guideline For Threat Impact Potential
        </Button>

        <Button variant="contained" color="success" onClick={handleOpenN}>
          Guideline For Normalised Threat Impact Potential
        </Button>
        <Button variant="contained" color="success" onClick={handleOpenA}>
          Guideline For Aggregate Threat Impact Potential
        </Button>
      </Box>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            <Box sx={{ margin: '10px' }} flexGrow={1}>
              <Typography
                sx={{ fontWeight: 'bold', textAlign: 'center' }}
                variant="h5"
              >
                Impact Potential
              </Typography>

              {GuidelinesData !== null ? (
                <CustomGuideTable
                  rows={GuidelinesData.data.rows['ThreatTable']}
                  fieldsMeta={GuidelinesData.data.meta.fields}
                  tableMeta={GuidelinesData.data.meta.ThreatTable}
                  tabName={`See`}
                />
              ) : null}
            </Box>
          </Box>
        </Box>
      </Modal>

      <Modal
        open={openN}
        onClose={handleCloseN}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 650,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4
          }}
        >
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            <Box sx={{ margin: '11px' }} flexGrow={2}>
              <Typography
                sx={{ fontWeight: 'bold', textAlign: 'center' }}
                variant="h5"
              >
                Normalised Impact Potential
              </Typography>
              {GuidelinesData !== null ? (
                <CustomGuideTable
                  rows={GuidelinesData.data.rows['Normalized Threat Impact']}
                  fieldsMeta={GuidelinesData.data.meta.fields}
                  tableMeta={GuidelinesData.data.meta.NormalizedThreatImpact}
                />
              ) : null}
            </Box>
          </Box>
        </Box>
      </Modal>

      <Modal
        open={openA}
        onClose={handleCloseA}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 650,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4
          }}
        >
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            <Box sx={{ margin: '11px' }} flexGrow={2}>
              <Typography
                sx={{ fontWeight: 'bold', textAlign: 'center' }}
                variant="h5"
              >
                Aggregate Impact Potential
              </Typography>
              {GuidelinesData !== null ? (
                <CustomGuideTable
                  rows={GuidelinesData.data.rows['ThreatImpact']}
                  fieldsMeta={GuidelinesData.data.meta.fields}
                  tableMeta={GuidelinesData.data.meta.ThreatImpact}
                />
              ) : null}
            </Box>
          </Box>
        </Box>
      </Modal>

      {/* Update Threat Repository Modal */}
      <Modal
        open={openAddRecordModal}
        onClose={handleCloseAddRecordModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <CreateEditDialog
          formData={addRecordForm}
          onCloseCB={handleCloseAddRecordModal}
          refreshPageContentCB={getThreatRepository}
          entityName="Record"
          customUpdateCB={handleAddRecord}
        />
      </Modal>
      <Modal
        open={openDeleteRecordModal}
        onClose={handleCloseDeleteRecordModal}
      >
        <DeleteDialog
          entityName={`Record`}
          customDeleteCB={handleDeleteRecord}
          onCloseCB={handleCloseDeleteRecordModal}
        />
      </Modal>
    </React.Fragment>
  );
};

export default ThreatImpact;
