import React, { useState, useEffect } from 'react';
import { Box, Modal, Button, Typography } from '@mui/material';
import {
  createService,
  updateService,
  getAllService,
  getServiceById,
  createFieldRefrence,
  getAllFieldRefrences,
  deleteServiceRequest,
  getFieldRefrencesById,
  deleteFieldRefrenceRequest,
  updateFieldRefrenceRequest
} from '../../constants/requests';
import { useLoading } from '../../hooks/useLoading';
import { useNotifications } from '../../hooks/useNotifications';
import DeleteDialog from '../Services/common/DeleteDialog';
import TemplateTable from '../TemplateEditor/common/TemplateTable';
import CreateEditDialog from '../Services/common/CreateEditDialog';

const createServiceModalFormFields = [
  {
    type: 'textField',
    fieldName: 'name',
    fieldLabel: 'Enter Service Name',
    value: '',
    required: false
  }
];

const createFieldRefModalFormFields = [
  {
    type: 'textField',
    fieldName: 'fieldName',
    fieldLabel: 'Enter Field Name',
    value: '',
    required: false
  },
  {
    type: 'textField',
    fieldName: 'reference',
    fieldLabel: 'Enter Reference',
    value: '',
    required: false
  },
  {
    type: 'textField',
    fieldName: 'description',
    fieldLabel: 'Enter Short Description',
    value: '',
    required: false
  },
  {
    type: 'dropDown',
    fieldName: 'modelName',
    fieldLabel: 'Select Model Name',
    value: '',
    required: false,
    dropDownOptions: [
      {
        label: 'Engagement',
        value: 'Engagement'
      },
      {
        label: 'Understanding',
        value: 'Understanding'
      },
      {
        label: 'Application',
        value: 'Application'
      },
      {
        label: 'OrgTable',
        value: 'OrgTable'
      },
      {
        label: 'RCM',
        value: 'RCM'
      },
      {
        label: 'Miscellaneous',
        value: 'Miscellaneous'
      }
    ]
  }
];

const FieldAndServiceCreator = () => {
  const { request } = useLoading();
  const { showNotifications } = useNotifications();
  const [serviceId, setServiceId] = useState(null);
  const [fieldRefId, setFieldRefId] = useState(null);
  const [services, setServices] = useState([]);
  const [fieldRefs, setFieldRefs] = useState([]);
  const [openAddFieldModal, setOpenAddFieldModal] = useState(false);
  const [openAddServiceModal, setOpenAddServiceModal] = useState(false);
  const [openEditServiceModal, setOpenEditServiceModal] = useState(false);
  const [openEditFieldRefModal, setOpenEditFieldRefModal] = useState(false);
  const [openDeleteServiceModal, setOpenDeleteServiceModal] = useState(false);
  const [openDeleteFieldRefModal, setOpenDeleteFieldRefModal] = useState(false);

  const handleOpenAddFieldModal = () => setOpenAddFieldModal(true);
  const handleOpenAddServiceModal = () => setOpenAddServiceModal(true);
  const handleCloseAddFieldModal = () => setOpenAddFieldModal(false);
  const handleCloseAddServiceModal = () => setOpenAddServiceModal(false);

  const handleOpenEditServiceModal = (idx) => {
    const sId = services[idx]['_id'];
    setServiceId(sId);
    setOpenEditServiceModal(true);
  };
  const handleCloseEditServiceModal = () => {
    setServiceId(null);
    setOpenEditServiceModal(false);
  };

  const handleOpenEditFieldRefModal = (idx) => {
    const fId = fieldRefs[idx]['_id'];
    setFieldRefId(fId);
    setOpenEditFieldRefModal(true);
  };
  const handleCloseEditFieldRefModal = () => {
    setFieldRefId(null);
    setOpenEditFieldRefModal(false);
  };

  const handleOpenDeleteServiceModal = (idx) => {
    const sId = services[idx]['_id'];
    setServiceId(sId);
    setOpenDeleteServiceModal(true);
  };
  const handleCloseDeleteServiceModal = () => {
    setServiceId(null);
    setOpenDeleteServiceModal(false);
  };

  const handleOpenDeleteFieldRefModal = (idx) => {
    const fId = fieldRefs[idx]['_id'];
    setFieldRefId(fId);
    setOpenDeleteFieldRefModal(true);
  };
  const handleCloseDeleteFieldRefModal = () => {
    setFieldRefId(null);
    setOpenDeleteFieldRefModal(false);
  };

  const getServices = async () => {
    try {
      const res = await request(() => getAllService());
      if (res.data.statusCode === 200) {
        const serviceList = res.data.body.map((service, idx) => {
          return {
            _id: service._id,
            'S.No': idx + 1,
            'Service Name': service.name,
            Action: 'Button'
          };
        });
        setServices(serviceList);
        showNotifications('success', res.data.message, 5000);
      } else {
        showNotifications('error', res.data.message, 5000);
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  const getFieldRefrences = async () => {
    try {
      const res = await request(() => getAllFieldRefrences());
      if (res.data.statusCode === 200) {
        const fieldList = res.data.body.map((field, idx) => {
          return {
            _id: field._id,
            'S.No': idx + 1,
            'Field Name': field.fieldName,
            Reference: field.reference,
            'Model Name': field.modelName,
            Description: field.description,
            Action: 'Button'
          };
        });
        setFieldRefs(fieldList);
        showNotifications('success', res.data.message, 5000);
      } else {
        showNotifications('error', res.data.message, 5000);
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  useEffect(() => {
    getServices();
    getFieldRefrences();
  }, []);

  return (
    <React.Fragment>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          margin: '20px 0px'
        }}
      >
        <Typography sx={{ fontWeight: 'bold' }} variant="h4">
          Services
        </Typography>
        <Button variant="contained" onClick={handleOpenAddServiceModal}>
          Create
        </Button>
      </Box>

      {services.length > 0 ? (
        <TemplateTable
          data={services}
          updateCB={handleOpenEditServiceModal}
          deleteCB={handleOpenDeleteServiceModal}
        />
      ) : null}

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          margin: '20px 0px'
        }}
      >
        <Typography sx={{ fontWeight: 'bold' }} variant="h4">
          Field Refrences
        </Typography>
        <Button variant="contained" onClick={handleOpenAddFieldModal}>
          Create
        </Button>
      </Box>
      {fieldRefs.length > 0 ? (
        <TemplateTable
          data={fieldRefs}
          updateCB={handleOpenEditFieldRefModal}
          deleteCB={handleOpenDeleteFieldRefModal}
        />
      ) : null}

      <Modal
        open={openAddServiceModal}
        onClose={handleCloseAddServiceModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <CreateEditDialog
          formData={createServiceModalFormFields}
          onCloseCB={handleCloseAddServiceModal}
          refreshPageContentCB={getServices}
          entityName="Service"
          createEntityRequest={createService}
        />
      </Modal>

      <Modal
        open={openAddFieldModal}
        onClose={handleCloseAddFieldModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <CreateEditDialog
          formData={createFieldRefModalFormFields}
          onCloseCB={handleCloseAddFieldModal}
          refreshPageContentCB={getFieldRefrences}
          entityName="Field Reference"
          createEntityRequest={createFieldRefrence}
        />
      </Modal>

      <Modal
        open={openEditServiceModal}
        onClose={handleCloseEditServiceModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <CreateEditDialog
          formData={createServiceModalFormFields}
          onCloseCB={handleCloseEditServiceModal}
          refreshPageContentCB={getServices}
          entityName="Service"
          entityId={serviceId}
          getEntityByIdRequest={getServiceById}
          updateEntityRequest={updateService}
        />
      </Modal>

      <Modal
        open={openEditFieldRefModal}
        onClose={handleCloseEditFieldRefModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <CreateEditDialog
          formData={createFieldRefModalFormFields}
          onCloseCB={handleCloseEditFieldRefModal}
          refreshPageContentCB={getFieldRefrences}
          entityName="Field Reference"
          entityId={fieldRefId}
          getEntityByIdRequest={getFieldRefrencesById}
          updateEntityRequest={updateFieldRefrenceRequest}
        />
      </Modal>

      <Modal
        open={openDeleteServiceModal}
        onClose={handleCloseDeleteServiceModal}
      >
        <DeleteDialog
          entityName="Service"
          deleteRequest={deleteServiceRequest}
          entityId={serviceId}
          onCloseCB={handleCloseDeleteServiceModal}
          refreshPageContentCB={getServices}
        />
      </Modal>

      <Modal
        open={openDeleteFieldRefModal}
        onClose={handleCloseDeleteFieldRefModal}
      >
        <DeleteDialog
          entityName="Field Reference"
          deleteRequest={deleteFieldRefrenceRequest}
          entityId={fieldRefId}
          onCloseCB={handleCloseDeleteFieldRefModal}
          refreshPageContentCB={getFieldRefrences}
        />
      </Modal>
    </React.Fragment>
  );
};

export default FieldAndServiceCreator;
