import React, { useState } from 'react';
import {
  Box,
  Button,
  Modal,
  Typography,
  TextField,
  FormHelperText
} from '@mui/material';
import { reverPolicyTemplateVersion } from '../../../constants/requests';
import { useLoading } from '../../../hooks/useLoading';
import { useNotifications } from '../../../hooks/useNotifications';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  borderRadius: '10px',
  boxShadow: 24,
  p: 4,
  maxHeight: '100%',
  minWidth: '600px'
};

const RevertPolicyVersionModal = ({
  openRevertPolicyVersionModal,
  handleCloseRevertPolicyVersionModal,
  getCurrentPolicyTemplateVersions,
  selectPolicyVersionId,
  selectPolicyId,
  selectPolicyVersionNumber
}) => {
  const { request } = useLoading();
  const { showNotifications } = useNotifications();

  const [comment, setComment] = useState('');
  const [commentError, setCommentError] = useState(false);

  const setCommentHandler = (event) => {
    if (event.target.value.trim().length) {
      setCommentError(false);
    }
    setComment(event.target.value);
  };

  const resetAllValues = () => {
    setComment('');
    setCommentError(false);
  };

  const revertSelectedVersionHandler = async () => {
    if (!comment.trim().length) {
      setCommentError(true);
      return;
    }
    try {
      const formData = new FormData();
      formData.append('comments', comment);
      const response = await request(() =>
        reverPolicyTemplateVersion(
          selectPolicyId,
          selectPolicyVersionId,
          formData
        )
      );
      if (response?.data?.statusCode === 200) {
        getCurrentPolicyTemplateVersions();
        handleCloseRevertPolicyVersionModal();
        resetAllValues();
        showNotifications('success', 'Version reverted successfully', 5000);
      } else {
        if (response.response.data.statusCode !== 200) {
          showNotifications('error', response.response.data.message, 5000);
        }
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  return (
    <Modal
      open={openRevertPolicyVersionModal}
      onClose={handleCloseRevertPolicyVersionModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography
          variant="h5"
          component="h2"
          style={{ marginBottom: '20px' }}
        >
          Revert Version to {selectPolicyVersionNumber}
        </Typography>
        <Typography
          id="modal-modal-description"
          style={{ marginBottom: '20px' }}
        >
          {`Are you sure you want to revert this Policy to Version ${selectPolicyVersionNumber}? Note: This action is
          irreversible`}
        </Typography>
        <TextField
          fullWidth
          id="filled-multiline-static"
          label="Add Comment"
          multiline
          rows={4}
          defaultValue={comment}
          variant="filled"
          sx={{ marginBottom: '5px' }}
          onChange={(event) => {
            setCommentHandler(event);
          }}
          error={commentError}
        />
        {commentError && (
          <FormHelperText> Please add a comment.</FormHelperText>
        )}
        <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button
            variant="contained"
            color="error"
            style={{ marginTop: '10px' }}
            onClick={revertSelectedVersionHandler}
          >
            Revert Version
          </Button>
          <Button
            variant="contained"
            style={{ marginTop: '10px' }}
            onClick={() => {
              handleCloseRevertPolicyVersionModal();
              resetAllValues();
            }}
          >
            Close
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default RevertPolicyVersionModal;
