import React, { useState } from 'react';
import {
  Box,
  Button,
  Typography,
  TextField,
  MenuItem,
  Collapse
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

const SingleLineForm = ({
  formData,
  heading,
  fieldOptions,
  typeOptions,
  saveFields,
  viewCB,
  testingSheetType,
  setOpenUploadModal
}) => {
  const fields = {};
  formData &&
    Object.keys(formData).map((key) => {
      return (fields[formData[key]] = '');
    });

  const [open, setOpen] = useState(false);
  const [inputFields, setInputFields] = useState([fields]);

  const addInputField = () => {
    setInputFields([...inputFields, fields]);
  };

  const handleChange = (index, e) => {
    const { name, value } = e.target;
    console.log(index + ' ' + e + ' ' + name + ' ' + value);
    const list = [...inputFields];
    list[index][name] = value;
    console.log(list);
    setInputFields(list);
    saveFields(list, heading);
  };
  return (
    <>
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '20px'
        }}
      >
        <Box
          style={{
            display: 'flex',
            justifyContent: 'flex-start'
          }}
        >
          {open ? (
            <KeyboardArrowDownIcon
              style={{ marginTop: '5px' }}
              onClick={() => setOpen(!open)}
            />
          ) : (
            <KeyboardArrowRightIcon
              style={{ marginTop: '5px' }}
              onClick={() => setOpen(!open)}
            />
          )}
          <Typography variant="h5">{heading}</Typography>
        </Box>
        <Box
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            gap: '10px'
          }}
        >
          <Box
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              gap: '10px'
            }}
          >
            <Button variant="contained" onClick={() => viewCB(heading)}>
              View
            </Button>
            <Button
              sx={{ background: 'black', color: 'white' }}
              variant="contained"
              onClick={() => addInputField()}
            >
              Add
            </Button>
            {heading === 'TestingSheetTab' && testingSheetType === 'BDO' && (
              <Button
                color="success"
                variant="contained"
                onClick={() => setOpenUploadModal(true)}
              >
                Upload
              </Button>
            )}
          </Box>
        </Box>
      </Box>

      <Box>
        <Collapse in={open}>
          {inputFields.map((data, index) => {
            const [field1, field2, field3] = Object.keys(data);
            return (
              <Box
                key={index}
                style={{
                  marginBottom: '25px',
                  display: 'flex',
                  gap: '10px',
                  justifyContent: 'space-around'
                }}
              >
                <TextField
                  fullWidth
                  select
                  name={field1}
                  onChange={(e) => handleChange(index, e)}
                  value={inputFields[field1]}
                  label={`Select ${field1}`}
                >
                  {fieldOptions.map((option) => (
                    <MenuItem value={option.value} key={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  fullWidth
                  select
                  name={field2}
                  onChange={(e) => handleChange(index, e)}
                  value={inputFields[field2]}
                  label={`Select ${field2}`}
                >
                  {typeOptions.map((option) => (
                    <MenuItem value={option.value} key={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  fullWidth
                  name={field3}
                  value={inputFields[field3]}
                  onChange={(e) => handleChange(index, e)}
                  label={`Enter ${field3}`}
                />
              </Box>
            );
          })}
        </Collapse>
      </Box>
    </>
  );
};

export default SingleLineForm;
