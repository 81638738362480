import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { getRole } from '../common/GetUserRole';
import { Box, Button, Typography } from '@mui/material';
import moment from 'moment';
import CustomTable from '../common/CustomTable';
import { useNotifications } from '../../../hooks/useNotifications';
import { useLoading } from '../../../hooks/useLoading';
import MergingIdentifiers from '../common/MergingIdentifiers';
import {
  updateRCMRequest,
  getEngagementByIdRequest,
  getApplicationByIdRequest,
  getWorkflowHandlerByIdRequest
} from '../../../constants/requests';
import Navbar from '../common/Navbar';
import { TextareaAutosize } from '@mui/base/TextareaAutosize';

import { useNavigate } from 'react-router-dom';

export default function AIT() {
  const navigate = useNavigate();
  const { showNotifications } = useNotifications();
  const { engagementId } = useParams();
  const { request } = useLoading();
  const [RCMDataArray, setRCMDataArray] = useState([]);
  const [appNames, setAppNames] = useState([]);
  const [RCMrows, setRCMrows] = useState([]);
  const [meta, setMeta] = useState(null);
  const [tableMeta, setTableMeta] = useState(null);
  const [fields, setFields] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [identifier, setIdentifier] = useState([]);
  const [rowIndex, setRowIndex] = useState([]);
  const [rows, setRows] = useState([]);
  const [userType, setUserType] = useState('PROJECT_MEMBER');
  const [workflowHandlerStatus, setWorkflowHandlerStatus] = useState(null);
  let count = 0;

  const defaultConclusion = `Taking into considerations procedures performed below and additional procedures performed, significant issues have been noted during testing of ITGCs on account of which there would be (comfort / no comfort) on ITGCs for (Application Name(s)) application for the period (DD-MM-YY) to (DD-MM-YY).  The core assuance team will not be able to rely on underlying data or automated controls.`;

  const saveIdentifiers = (tags) => {
    setIdentifier(tags);
  };

  const getApplications = async () => {
    try {
      let response = await request(() =>
        getEngagementByIdRequest(engagementId)
      );
      if (response.data.body.workflowHandlerId !== null) {
        const workflowHandler = await request(() =>
          getWorkflowHandlerByIdRequest(response.data.body.workflowHandlerId)
        );
        setWorkflowHandlerStatus(workflowHandler.data.body.status);
      }
      if (response.data.statusCode === 200) {
        const application = response.data.body.applicationID;
        const applicationData = application.map((item) => {
          return {
            applicationId: item._id,
            rcmId: item.RCMId,
            applicationName: item.applicationName
          };
        });
        const sDate = response.data.body.understandingId.auditStartDate;
        const eDate = response.data.body.understandingId.auditEndDate;
        setStartDate(moment(sDate).format('DD-MM-YYYY'));
        setEndDate(moment(eDate).format('DD-MM-YYYY'));
        await getRCM(applicationData);
      } else {
        showNotifications('error', response.data.message, 5000);
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  const getRCM = async (application) => {
    try {
      for (let i = 0; i < application.length; i++) {
        const response = await request(() =>
          getApplicationByIdRequest(application[i].applicationId)
        );

        if (response.data.statusCode === 200) {
          // eslint-disable-next-line no-prototype-builtins
          if (!response.data.body.hasOwnProperty('RCMId')) {
            continue;
          }
          if (response.data.body.RCMId === null) {
            continue;
          }
          // console.log(response.data.body.RCMId)
          const RCM = response.data.body.RCMId.data;
          if (!meta) {
            setMeta(RCM.meta);
            setIdentifier(RCM.meta.RowsToMerge);
            setTableMeta({
              'S.No': {
                isEditable: false
              },
              'Application Name': {
                isEditable: false
              },
              ...RCM.meta.AIT
            });
            setFields({
              ...RCM.meta.fields,
              'S.No': {
                fieldType: 'textField'
              },
              'Application Name': {
                fieldType: 'textField'
              }
            });
          }

          const filtered_rows = RCM['rows']['Master Table'].filter(
            (row) =>
              row['Additional Conclusion'] === 'Exception noted' ||
              (row['Additional Conclusion'] === 'N/A' &&
                row['Result'] === 'Exception noted')
          );
          // console.log(RCM['rows']['Master Table'])
          // console.log(filtered_rows)

          let RCMData = {};
          RCMData['applicationName'] = application[i].applicationName;
          RCMData['rcmID'] = application[i].rcmId;
          let indices = [];
          RCMData['rows'] = filtered_rows.map((row, idx) => {
            let obj = {};
            indices.push(row['S.No'] - 1);
            obj['Application Name'] = application[i].applicationName;
            obj['S.No'] = ++count;
            obj['ApplicationNo'] = i;
            obj['index'] = idx;
            // eslint-disable-next-line array-callback-return
            Object.keys(RCM.meta.AIT).map((key) => {
              obj[key] = row[key];
            });
            return obj;
          });

          setAppNames((appNames) => [
            ...appNames,
            application[i].applicationName
          ]);

          setRCMDataArray((RCMDataArray) => [...RCMDataArray, RCMData]);

          setRows((rows) => [...rows, ...RCMData['rows']]);

          setRCMrows((RCMrows) => [...RCMrows, RCM.rows['Master Table']]);

          setRowIndex((rowIndex) => [...rowIndex, indices]);

          showNotifications('success', response.data.message, 5000);
        } else {
          showNotifications('error', response.data.message, 5000);
        }
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
      console.log(err);
    }
  };

  const handleRowChange = (idx, name, cellValue) => {
    let newData = Object.assign(RCMDataArray);
    let newRows = Object.assign(rows);
    newRows[idx][name] = cellValue;
    setRows(newRows);
    let cnt = newRows[idx]['ApplicationNo'];
    let index = newRows[idx]['index'];
    newData[cnt]['rows'][index][name] = cellValue;
    setRCMDataArray(newData);
  };

  const handleSave = async () => {
    try {
      for (let i = 0; i < RCMDataArray.length; i++) {
        const UpdatedRCM = RCMrows[i];
        for (let idx = 0; idx < RCMDataArray[i]['rows'].length; idx++) {
          const obj = RCMDataArray[i]['rows'][idx];
          const index = rowIndex[i][idx];
          delete obj['Application Name'];
          delete obj['S.No'];
          delete obj['ApplicationNo'];
          delete obj['index'];
          UpdatedRCM[index] = { ...RCMrows[i][index], ...obj };
        }
        const filterTags = identifier.filter((val) => val !== '');
        const RCMObj = {
          meta: { ...meta, RowsToMerge: filterTags },
          rows: {
            'Master Table': UpdatedRCM
          }
        };

        const payload = {
          data: RCMObj
        };

        const id = RCMDataArray[i]['rcmID'];

        let response = await request(() => updateRCMRequest(id, payload));
        if (response.data.statusCode === 200) {
          showNotifications('success', response.data.message, 5000);
        } else {
          showNotifications('error', response.data.message, 5000);
        }
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  useEffect(() => {
    getApplications();
    setUserType(getRole());
  }, []);

  return (
    <>
      <Navbar pageName="AIT" engagementId={engagementId} />

      {rows.length > 0 ? (
        <Box
          style={{
            marginBottom: '25px',
            display: 'flex',
            justifyContent: 'flex-end',
            gap: '4px'
          }}
        >
          <Button
            variant="contained"
            color="success"
            disabled={RCMDataArray.length === 0}
            onClick={handleSave}
          >
            Save
          </Button>
          {(workflowHandlerStatus === 'Completed' || userType === 'ADMIN') && (
            <Button
              variant="contained"
              color="warning"
              disabled={RCMDataArray.length === 0}
              onClick={() =>
                navigate(`/services/engagement/${engagementId}/convertppt`)
              }
            >
              Export To PPT
            </Button>
          )}
        </Box>
      ) : null}
      {rows.length > 0 ? (
        <Box
          style={{
            display: 'flex',
            justifyContent: 'start',
            alignItems: 'center',
            marginBottom: '10px'
          }}
        >
          <Typography sx={{ fontWeight: 'bold' }} variant="h6">
            Conclusion
          </Typography>
          <TextareaAutosize
            value={defaultConclusion}
            style={{ width: '100%' }}
            minRows={3}
            maxRows={4}
            disabled
          />
        </Box>
      ) : null}
      {rows.length > 0 ? (
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <Box
            style={{
              display: 'flex',
              justifyContent: 'start',
              alignItems: 'center',
              marginBottom: '20px'
            }}
          >
            <Typography sx={{ fontWeight: 'bold' }} variant="h7">
              Application Names
            </Typography>
            {rows.length > 0 &&
              appNames.map((app, idx) => {
                return (
                  <Typography
                    key={`APP-${idx}`}
                    variant="h7"
                    sx={{ padding: '5px', marginLeft: '5px' }}
                  >
                    {app}
                  </Typography>
                );
              })}
          </Box>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'start',
              alignItems: 'center',
              marginBottom: '20px'
            }}
          >
            <Typography sx={{ fontWeight: 'bold' }} variant="h7">
              Start Date:
            </Typography>
            <Typography sx={{ padding: '5px', marginLeft: '5px' }} variant="h7">
              {startDate}
            </Typography>
            <Typography sx={{ fontWeight: 'bold' }} variant="h7">
              End Date:
            </Typography>
            <Typography sx={{ padding: '5px', marginLeft: '5px' }} variant="h7">
              {endDate}
            </Typography>
          </Box>
        </Box>
      ) : null}
      {rows.length > 0 && (
        <MergingIdentifiers
          initialTags={identifier}
          saveIdentifiers={saveIdentifiers}
        />
      )}
      {rows.length > 0 ? (
        <CustomTable
          fieldsMeta={fields}
          tableMeta={tableMeta}
          rows={rows}
          stickyColumnName=" "
          handleChangeCB={handleRowChange}
          tabName={'Master Table'}
          topStyle="50px"
        />
      ) : (
        <Box style={{ display: 'flex', justifyContent: 'center' }}>
          <Typography sx={{ fontWeight: 'bold', color: 'Red' }} variant="h5">
            No Ineffective Controls are Present
          </Typography>
        </Box>
      )}
    </>
  );
}
