export const initialState = {
  loggedIn: false,
  _id: '',
  fname: '',
  profilePicture: '',
  lname: '',
  about: '',
  role: '',
  roleId: '',
  employmentType: '',
  department: '',
  departmentId: '',
  designation: '',
  gender: '',
  address: '',
  city: '',
  state: '',
  zip: '',
  country: '',
  phone: '',
  email: '',
  dateOfBirth: '',
  dateOfJoining: '',
  maritalStatus: '',
  reportingManager: '',
  reportingManagerEmail: '',
  reportingManagerName: '',
  workHoursCurrentWeek: 0,
  workMinutesCurrentWeek: 0,
  tags: '',
  aadharNumber: '',
  SSN: '',
  PAN: '',
  bankAccountNumber: '',
  IFSCCode: '',
  qualificationDocuments: '',
  //salary information
  ctc: 0,
  grossSalary: 0,
  baseSalary: 0,
  HRA: 0,
  medicalAllowance: 0,
  conveyanceAllowance: 0,
  specialAllowance: 0,
  PFContribution: 0,
  ESIContribution: 0,
  PTDeduction: 0,
  totalDeductions: 0,
  netSalary: 0,
  isActive: true,
  HRAdmin: false,
  clientAdmin: false,
  projectAdmin: false,
  holidayAdmin: false,

  isVerifiedBySPOC: false,
  profile: [],
  //
  createdAt: '',
  rights: {},
  superAdmin: false
};
function reducer(state, action) {
  switch (action.type) {
    case 'SET_USER':
      // console.log('🕺', action);
      return {
        ...state,
        ...action.details
      };
    case 'SET_LOGGED':
      return {
        ...state,
        loggedIn: action.loggedIn
      };
    case 'SET_RIGHTS':
      return {
        ...state,
        rights: action.rights
      };
    case 'SET_SUPERADMIN':
      return {
        ...state,
        superAdmin: action.superAdmin
      };
    case 'SET_VERIFIED':
      return {
        ...state,
        isVerifiedBySPOC: action.isVerifiedBySPOC
      };
    default:
      return state;
  }
}

export default reducer;
