// Gap_Assesment
import React, { useEffect, useState } from 'react';
import {
  Box,
  Table,
  Button,
  TableCell,
  tableCellClasses,
  TableHead,
  TableRow,
  TableBody,
  TableContainer,
  Typography
} from '@mui/material';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { useNavigate, useParams } from 'react-router-dom';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {
  getEngagementPolicyById,
  getGapIdFromPolicyEngagementId,
  getPolicyEngagementById,
  updateEngagementPolicyById_Table,
  getAllPolicyScope
} from '../../../constants/requests';
import { useLoading } from '../../../hooks/useLoading';
import { useNotifications } from '../../../hooks/useNotifications';
import DeleteIcon from '@mui/icons-material/Delete';
import AddEngagementPolicyModal from '../../Policies/Components/AddEngagementPolicyModal';
import PolicyScopeModal from '../../Policies/Policy/Components/PolicyScopeModal';
import { MultiSelectComponent } from '../../Policies/Policy/Components/MultiSelectComponent';
import PreviewPolicyModal from '../../Policies/Policy/Components/PreviewPolicyModal';
import PublishPolicyModal from '../../Policies/Policy/Components/PublishPolicyModal';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14
  }
}));

const StyledTableRow = styled(TableRow)(() => ({
  '&:last-child td, &:last-child th': {
    border: 0
  }
}));

const Gap_Assesment = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { request } = useLoading();
  const { showNotifications } = useNotifications();
  const [policies, setPolicies] = useState([]);
  const [clientName, setClientName] = useState('Client');
  const [selectPolicyId, setSelectedPolicyId] = useState('');
  const [gapId, setGapId] = useState(null);
  const [open, setOpen] = useState(false);
  const [policyScopes, setPolicyScopes] = useState([]);
  const [policyData, setPolicyData] = useState({});
  const [openPreview, setOpenPreview] = useState(false);

  const [policyNameIdMapping, setPolicyNameIdMapping] = useState({});

  const [openAddEngagementPolicyModal, setOpenAddEngagementPolicyModal] =
    useState(false);
  const handleOpenAddEngagementPolicyModal = () =>
    setOpenAddEngagementPolicyModal(true);
  const handleCloseAddEngagementPolicyModal = () =>
    setOpenAddEngagementPolicyModal(false);

  const [openPreviewPolicyModal, setOpenPreviewPolicyModal] = useState(false);
  const handleOpenPreviewPolicyModal = () => setOpenPreviewPolicyModal(true);
  const handleClosePreviewPolicyModal = () => setOpenPreviewPolicyModal(false);

  const [openPublishPolicyModal, setOpenPublishPolicyModal] = useState(false);
  const handleOpenPublishPolicyModal = () => setOpenPublishPolicyModal(true);
  const handleClosePublishPolicyModal = () => setOpenPublishPolicyModal(false);

  const getAllPolicies = async (policyIds) => {
    try {
      const promises = policyIds.map(async (id) => {
        const response = await request(() => getEngagementPolicyById(id));
        console.log(response);

        setPolicyData((policyData) => ({
          ...policyData,
          ...{ [id]: response.data.body }
        }));
        setPolicyNameIdMapping((policyNameIdMapping) => ({
          ...policyNameIdMapping,
          ...{ [response.data.body.name]: id }
        }));
        return response.data.body;
      });
      const policyArray = await Promise.all(promises);
      policyArray.sort((a, b) => b.isGeneric - a.isGeneric);
      setPolicies(policyArray);
    } catch (error) {
      showNotifications(
        'error',
        'Some error while fetching all the policies',
        5000
      );
    }
  };

  console.log(clientName);
  console.log(policyNameIdMapping);

  const getSelectedEngagement = async () => {
    try {
      console.log(params);

      const response = await request(() =>
        getPolicyEngagementById(params.engagementId)
      );
      if (response?.data?.statusCode === 200) {
        console.log(response.data);
        setClientName(response.data.body.clientName);
        getAllPolicies(response.data.body.policies);
      } else {
        if (response.response.data.statusCode !== 200) {
          showNotifications('error', response.response.data.message, 5000);
        }
      }

      const policyScopesResponse = await request(() => getAllPolicyScope());
      if (policyScopesResponse?.data?.statusCode === 200) {
        const AllPolicyScopes = policyScopesResponse.data.body.map(
          (policyScope) => {
            return policyScope.name;
          }
        );
        setPolicyScopes(AllPolicyScopes);
      } else {
        if (policyScopesResponse.response.data.statusCode !== 200) {
          showNotifications(
            'error',
            policyScopesResponse.response.data.message,
            5000
          );
        }
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  const getGapId = async () => {
    try {
      console.log(params);

      const response = await request(() =>
        getGapIdFromPolicyEngagementId(params.engagementId)
      );
      if (response?.data?.statusCode === 200) {
        setGapId(response.data.body._id);
      } else {
        if (response.response.data.statusCode !== 200) {
          showNotifications('error', response.response.data.message, 5000);
        }
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  const savePolicyScope = async (policyId) => {
    try {
      const response = await request(() =>
        updateEngagementPolicyById_Table(policyId, {
          scope: policyData[policyId]['scope']
        })
      );
      if (response?.data?.statusCode === 200) {
        showNotifications('success', 'Policy Scope updated successfully', 5000);
      } else {
        if (response.response.data.statusCode !== 200) {
          showNotifications('error', response.response.data.message, 5000);
        }
      }
    } catch (error) {
      showNotifications('error', error.toString(), 5000);
    }
  };

  const updatePolicyScopes = async (newValue, policyId) => {
    let newObject = { ...policyData };
    newObject[policyId]['scope'] = newValue;
    setPolicyData(newObject);
    await savePolicyScope(policyId);
  };

  useEffect(() => {
    getGapId();
    getSelectedEngagement(params.engagementId);
  }, []);

  return (
    <React.Fragment>
      <Box
        style={{
          marginBottom: '25px',
          display: 'flex',
          justifyContent: 'space-between'
        }}
      >
        <Button
          sx={{
            color: 'white',
            backgroundColor: 'black',
            paddingLeft: 1
          }}
          variant="contained"
          onClick={() => {
            navigate('/policy/engagement');
          }}
        >
          <ChevronLeftIcon />
          Engagements
        </Button>
        <Button
          sx={{
            color: 'white',
            backgroundColor: 'black',
            paddingLeft: 1
          }}
          disabled={gapId === null}
          variant="contained"
          onClick={() => {
            navigate(`/gapTracker/gapiso/${gapId}`);
          }}
        >
          Gap Tracker
          <ChevronRightIcon />
        </Button>
      </Box>
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '20px'
        }}
      >
        <Typography sx={{ fontWeight: 'bold', flexGrow: 1 }} variant="h4">
          {/* {clientName} - Policies */}
          Project Manager
        </Typography>
        <Box>
          <Button
            variant="contained"
            sx={{ backgroundColor: 'black', height: 50, marginRight: 2 }}
            onClick={() => {
              setOpen(true);
              handleOpenAddEngagementPolicyModal();
            }}
          >
            Add Template
          </Button>

          <Button
            variant="contained"
            color="error"
            sx={{ height: 50 }}
            onClick={() => {
              handleOpenPublishPolicyModal();
            }}
          >
            Publish Policies
          </Button>
        </Box>
      </Box>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 500 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell
                key={`s.no`}
                align="left"
                sx={{ minWidth: '110px' }}
              >
                Version No.
              </StyledTableCell>
              <StyledTableCell key={'client-name'}>Policy Name</StyledTableCell>
              <StyledTableCell key={'client-type'} sx={{ minWidth: '110px' }}>
                Type
              </StyledTableCell>
              {/* <StyledTableCell key={`industry`} align="left">
                Status
              </StyledTableCell> */}
              <StyledTableCell
                key={'Aumyaa-key-spoc'}
                sx={{ minWidth: '110px' }}
              >
                Aumyaa Key SPOC
              </StyledTableCell>
              <StyledTableCell
                key={'Client-key-spoc'}
                sx={{ minWidth: '110px' }}
              >
                Client Key SPOC
              </StyledTableCell>
              <StyledTableCell key={'Aumyaa-spoc'}>Aumyaa SPOC</StyledTableCell>
              <StyledTableCell key={'client-spoc'}>Client SPOC</StyledTableCell>
              <StyledTableCell
                key={`options`}
                align="center"
                sx={{ minWidth: '150px' }}
              >
                Options
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {policies.map((data, idx) => {
              return (
                <StyledTableRow
                  hover
                  style={{ cursor: 'pointer' }}
                  key={`policy-row-${idx}`}
                >
                  <StyledTableCell
                    key={`policy-row-${idx}-sno`}
                    onClick={() => {
                      navigate(
                        `/policy/engagement/policy/${params.engagementId}/${data._id}`
                      );
                    }}
                  >
                    {data.versionNo}.0
                  </StyledTableCell>
                  <StyledTableCell
                    key={`policy-row-${idx}-name`}
                    onClick={() => {
                      navigate(
                        `/policy/engagement/policy/${params.engagementId}/${data._id}`
                      );
                    }}
                  >
                    {data.name}
                  </StyledTableCell>
                  <StyledTableCell
                    key={`policy-row-${idx}-type`}
                    onClick={() => {
                      navigate(
                        `/policy/engagement/policy/${params.engagementId}/${data._id}`
                      );
                    }}
                  >
                    {data.type}
                  </StyledTableCell>
                  {/* <StyledTableCell
                    key={`policy-row-${idx}-incomplete`}
                    onClick={() => {
                      navigate(
                        `/policy/engagement/policy/${params.engagementId}/${data._id}`
                      );
                    }}
                  >
                    In-Completed
                  </StyledTableCell> */}
                  <StyledTableCell
                    key={`aumyaa-key-spoc-${idx}-type`}
                    onClick={() => {
                      navigate(
                        `/policy/engagement/policy/${params.engagementId}/${data._id}`
                      );
                    }}
                  >
                    {data.aumyaaApprover &&
                    data.aumyaaApprover.name.length !== 0
                      ? `${data.aumyaaApprover.name} (${data.aumyaaApprover.email})`
                      : ' '}
                  </StyledTableCell>
                  <StyledTableCell
                    key={`client-key-spoc-${idx}-type`}
                    onClick={() => {
                      navigate(
                        `/policy/engagement/policy/${params.engagementId}/${data._id}`
                      );
                    }}
                  >
                    {data.clientApprover &&
                    data.clientApprover.name.length !== 0
                      ? `${data.clientApprover.name} (${data.clientApprover.email})`
                      : ' '}
                  </StyledTableCell>
                  <StyledTableCell key={`aumyaa-spoc-${idx}-type`}>
                    <PolicyScopeModal name="aumyaa" policyId={data._id} />
                  </StyledTableCell>
                  <StyledTableCell key={`client-spoc-${idx}-type`}>
                    <PolicyScopeModal name="client" policyId={data._id} />
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    key={`policy-row-${idx}-button`}
                  >
                    <Box
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        paddingLeft: '10px',
                        width: '300px',
                        alignItems: 'center'
                      }}
                    >
                      {data.isGeneric && (
                        <Button
                          variant="contained"
                          sx={{ marginRight: '5px' }}
                          onClick={() => {
                            setOpenPreview(true);
                            setSelectedPolicyId(data._id);
                            handleOpenPreviewPolicyModal();
                          }}
                        >
                          Preview
                        </Button>
                      )}
                      <MultiSelectComponent
                        listOfPolicyScopes={policyScopes}
                        currentValue={data.scope}
                        updateValue={updatePolicyScopes}
                        policyId={data._id}
                      />

                      <DeleteIcon
                        onClick={() => {
                          setSelectedPolicyId(data._id);
                        }}
                      />
                    </Box>
                  </StyledTableCell>
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Publish Policy Modal */}
      <PublishPolicyModal
        openPublishPolicyModal={openPublishPolicyModal}
        handleClosePublishPolicyModal={handleClosePublishPolicyModal}
      />

      {/*Policy Preview Modal*/}
      <PreviewPolicyModal
        openPreviewPolicyModal={openPreviewPolicyModal}
        handleClosePreviewPolicyModal={handleClosePreviewPolicyModal}
        getSelectedEngagement={getSelectedEngagement}
        selectPolicyId={selectPolicyId}
        engagementId={params.engagementId}
        openPreview={openPreview}
        setOpenPreview={setOpenPreview}
      />

      {/*Add Policy Modal*/}
      <AddEngagementPolicyModal
        openAddEngagementPolicyModal={openAddEngagementPolicyModal}
        handleCloseAddEngagementPolicyModal={
          handleCloseAddEngagementPolicyModal
        }
        getPreviousSelectedEngagement={getSelectedEngagement}
        open={open}
        setOpen={setOpen}
      />
    </React.Fragment>
  );
};

export default Gap_Assesment;
