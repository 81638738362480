import React, { useState } from 'react';
import { BACKEND_URL } from '../../config';
import { useDataLayerValue } from '../../contextAPI/DataLayer';
import { Form } from '../Form';

const sampleFields = [
  {
    label: 'A',
    name: 'fname',
    display: 'auto',
    type: 'text'
  },
  {
    label: 'B',
    name: 'lname',
    display: 'auto',
    type: 'text',
    value: 'Surname'
  },
  {
    label: 'C',
    name: 'age',
    display: 'required',
    type: 'Number'
  },
  {
    label: 'D',
    name: 'email',
    display: 'hidden',
    type: 'email'
  },
  {
    label: 'E',
    name: 'nickName1',
    display: 'required',
    type: 'text'
  },
  {
    label: 'F',
    name: 'nickName2',
    display: 'optional',
    type: 'text'
  },
  {
    label: 'G',
    name: 'nickName3',
    display: 'required',
    type: 'date'
  },
  {
    label: 'Role',
    name: 'newRole',
    display: 'required',
    type: 'dropdown',
    value: [
      {
        id: 1,
        name: 'L1'
      },
      {
        id: 2,
        name: 'L2'
      },
      {
        id: 3,
        name: 'L3'
      }
    ],
    info: ['name', 'id']
  }
];

export const Dashboard = () => {
  const [open, setOpen] = useState(false);
  const [{ fname, lname, email }] = useDataLayerValue();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      Hello {fname + ' ' + lname + ' ' + email}
      <button onClick={handleOpen}>Click Me</button>
      <Form
        title="Random Form Check"
        url={BACKEND_URL}
        open={open}
        handleClose={handleClose}
        fields={sampleFields}
        type="post"
      />
    </div>
  );
};
