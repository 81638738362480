import React, { useState } from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNotifications } from '../../hooks/useNotifications';
import axios from 'axios';
import Grid from '@mui/material/Grid';
import { useNavigate } from 'react-router-dom';
import { BACKEND_URL } from '../../config';
import { useDataLayerValue } from '../../contextAPI/DataLayer';
const theme = createTheme();

export default function SignIn() {
  const navigate = useNavigate();
  const [{ _id }] = useDataLayerValue();
  const [step, setStep] = React.useState(0);

  const { showNotifications } = useNotifications();

  const [fields, setFields] = useState({
    email: '',
    otp: '',
    password: '',
    confirmPassword: ''
  });

  const [viewRules, setViewRules] = useState(false);

  let colour1 = 'red',
    colour2 = 'red',
    colour3 = 'red',
    colour4 = 'red',
    colour5 = 'red';

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFields((prev) => {
      return { ...prev, [name]: value };
    });
  };

  if (fields.password.length >= 8) {
    colour1 = 'green';
  }
  if (fields.password.match(/^(?=.*[A-Z]).*$/)) {
    colour2 = 'green';
  }
  if (fields.password.match(/^(?=.*[a-z]).*$/)) {
    colour3 = 'green';
  }
  if (fields.password.match(/^(?=.*[0-9]).*$/)) {
    colour4 = 'green';
  }
  if (
    fields.password.match(/^(?=.*[~`!@#$%^&*()--+={}[\]|\\:;"'<>,.?/_₹]).*$/)
  ) {
    colour5 = 'green';
  }

  function showText() {
    switch (step) {
      case 0:
        return 'Enter OTP';
      case 1:
        return 'Validate OTP';
      case 2:
        return 'Reset Password';
      default:
        return 'Overflow';
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    if (step === 0) {
      axios
        .post(
          BACKEND_URL + '/otp/add',
          {
            email: fields.email
          },
          {
            headers: {
              employeeid: _id
            }
          }
        )
        .then((res) => {
          if (res.data.statusCode === 201) {
            showNotifications(
              'success',
              'OTP Sent to your Email Address',
              2000
            );
            setStep(1);
          } else {
            console.log(res.data);
            showNotifications('error', res.data.message, 2000);
          }
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
          showNotifications('error', 'Error', 2000);
        });
    } else if (step === 1) {
      axios
        .get(BACKEND_URL + '/otp/verify/' + fields.otp, {
          params: {
            email: fields.email
          },
          headers: {
            employeeid: _id
          }
        })
        .then((res) => {
          if (res.data.statusCode === 404) {
            showNotifications('error', 'Invalid OTP', 2000);
          } else {
            showNotifications('success', 'OTP Verified', 2000);
            setStep(2);
          }
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
          showNotifications('error', 'Error', 2000);
        });
    } else if (step === 2) {
      if (fields.password !== fields.confirmPassword) {
        showNotifications('error', 'Passwords do not match', 2000);
        return;
      } else if (
        colour1 === 'red' ||
        colour2 === 'red' ||
        colour3 === 'red' ||
        colour4 === 'red' ||
        colour5 === 'red'
      ) {
        showNotifications('error', 'Password must follow the rules', 2000);
      } else {
        console.log(fields);
        axios
          .post(
            BACKEND_URL + '/credentials/employee/update/',
            {
              email: fields.email,
              password: fields.password
            },
            {
              headers: {
                employeeid: _id
              }
            }
          )
          .then((res) => {
            console.log(res.data);
            if (res.data.statusCode === 200) {
              //check
              showNotifications('success', 'Password Reset Successfully', 2000);
              setTimeout(() => {
                navigate('/');
              }, 1000);
            } else showNotifications('error', res.data.message, 2000);
          })
          .catch((err) => showNotifications('error', err.data.message, 2000));
      }
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <Typography component="h1" variant="h5">
            Reset Password
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            {
              <TextField
                required
                disabled={step === 0 ? false : true}
                margin="normal"
                fullWidth
                type="email"
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                onChange={handleChange}
                autoFocus
              />
            }
            {step === 1 && (
              <>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="otp"
                  label="OTP"
                  name="otp"
                  autoFocus
                  placeholder="Enter OTP"
                  onChange={handleChange}
                />
              </>
            )}
            {step === 2 && (
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    onChange={handleChange}
                    autoComplete="new-password"
                    onFocus={() => {
                      setViewRules(true);
                    }}
                    onBlur={() => {
                      setViewRules(false);
                    }}
                  />
                </Grid>
                {viewRules && (
                  <Grid item xs={12}>
                    <p>
                      <i
                        style={{
                          color: colour1,
                          fontSize: '20px'
                        }}
                        className="fa fa-check-circle"
                        aria-hidden="true"
                      ></i>{' '}
                      At least 8 characters
                    </p>
                    <p>
                      <i
                        style={{
                          color: colour2,
                          fontSize: '20px'
                        }}
                        className="fa fa-check-circle"
                        aria-hidden="true"
                      ></i>{' '}
                      At least 1 uppercase letter
                    </p>
                    <p>
                      <i
                        style={{
                          color: colour3,
                          fontSize: '20px'
                        }}
                        className="fa fa-check-circle"
                        aria-hidden="true"
                      ></i>{' '}
                      At least 1 lowercase letter
                    </p>
                    <p>
                      <i
                        style={{
                          color: colour4,
                          fontSize: '20px'
                        }}
                        className="fa fa-check-circle"
                        aria-hidden="true"
                      ></i>{' '}
                      At least 1 number
                    </p>
                    <p>
                      <i
                        style={{
                          color: colour5,
                          fontSize: '20px'
                        }}
                        className="fa fa-check-circle"
                        aria-hidden="true"
                      ></i>{' '}
                      At least 1 special character
                    </p>
                    <p>
                      <i
                        style={{
                          color: 'green',
                          fontSize: '20px'
                        }}
                        className="fa fa-check-circle"
                        aria-hidden="true"
                      ></i>{' '}
                      Encrypted
                    </p>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    name="confirmPassword"
                    label="Confirm Password"
                    type="password"
                    id="confirmPassword"
                    autoComplete="new-password"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  {fields.confirmPassword === '' ? (
                    ''
                  ) : fields.password === fields.confirmPassword ? (
                    <p
                      style={{
                        color: 'green',
                        fontWeight: 'bold'
                      }}
                    >
                      {' '}
                      Passwords match{' '}
                    </p>
                  ) : (
                    <p
                      style={{
                        color: 'red',
                        fontWeight: 'bold'
                      }}
                    >
                      {' '}
                      Passwords not match{' '}
                    </p>
                  )}
                </Grid>
              </Grid>
            )}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              {showText()}
            </Button>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
