import React from 'react';
import { TableHead, TableRow, TableCell, Typography } from '@mui/material';

export const ProjectTableHead = ({ currHeaders }) => {
  return (
    <TableHead>
      <TableRow>
        {currHeaders.map((currHeader) => {
          return (
            <TableCell
              key={currHeader.id}
              align="center"
              sx={{ minWidth: '150px' }}
            >
              <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                {currHeader.title}
              </Typography>
            </TableCell>
          );
        })}
        <TableCell align="center" sx={{ minWidth: '100px' }}></TableCell>
      </TableRow>
    </TableHead>
  );
};
