import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Typography } from '@mui/material';
import {
  TaskAllHeaders,
  TaskNonMonetaryHeaders
} from '../../../constants/TaskMasterHeaders';
import { useLoading } from '../../../hooks/useLoading';
import {
  getEmployeesRequest,
  getProjectByNameRequest,
  getTasksRequest
} from '../../../constants/requests';
import { useDataLayerValue } from '../../../contextAPI/DataLayer';
import { TaskTable } from './TaskTable';
import { TaskHeader } from './TaskHeader';
import { ADMINISTRATOR_ROLE_ID } from '../../../constants/AdministratorConstants';

export const MasterTask = () => {
  const { projectName } = useParams();
  const { request } = useLoading();
  const [user] = useDataLayerValue();
  const { _id, roleId } = user;
  const { CREATE, ASSIGN, VIEW_REPORT } = user.rights.Project;
  const navigate = useNavigate();
  if (VIEW_REPORT === false) {
    navigate('/');
  }
  const [data, setData] = useState({ body: [] });
  const [allEmployees, setAllEmployees] = useState([]);
  const headers =
    roleId === ADMINISTRATOR_ROLE_ID ? TaskAllHeaders : TaskNonMonetaryHeaders;
  const [projectData, setProjectData] = useState({ exists: true });

  const getData = async () => {
    try {
      const response = await request(() => getTasksRequest(projectName));
      if (response.data.statusCode === 200) {
        response.data.body = response.data.body
          .map((task) => {
            return {
              ...task,
              startDate: new Date(task.startDate).toDateString(),
              endDate: new Date(task.endDate).toDateString(),
              reminderTime: new Date(task.reminderTime).toLocaleString('en-GB')
            };
          })
          .sort((task1, task2) => task1.jobName.localeCompare(task2.jobName));
        setData(response.data);
      } else {
        console.log('some error happened');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getEmployeeData = async () => {
    try {
      const response = await request(() => getEmployeesRequest());
      if (response.status === 200) {
        response.data.body = response.data.body
          .filter((employee) => {
            return (
              'email' in employee &&
              employee.email !== null &&
              employee.email !== '' &&
              'isActive' in employee &&
              employee.isActive
            );
          })
          .map((employee) => {
            return {
              ...employee,
              employeeDisplay: `${employee.fname} ${employee.lname} (${employee.email})`
            };
          })
          .sort((employeeA, employeeB) => {
            if (employeeA.fname === employeeB.fname) {
              return employeeA.lname < employeeB.lname ? -1 : 1;
            } else {
              return employeeA.fname < employeeB.fname ? -1 : 1;
            }
          });
        setAllEmployees(response.data.body);
      } else {
        console.log('some error happened');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getProjectData = async () => {
    try {
      const response = await request(() =>
        getProjectByNameRequest(projectName)
      );
      if (response.data.statusCode === 200) {
        document.title = `${projectName} Tasks`;
        response.data.body.exists = true;
        setProjectData(response.data.body);
      } else if (response.data.statusCode === 404) {
        document.title = `Project Not Found`;
        setProjectData({ exists: false });
      } else {
        console.log('some error happened');
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getData();
    getEmployeeData();
    getProjectData();
  }, []);

  return projectData.exists === false ? (
    <h1>No such project exists</h1>
  ) : roleId === ADMINISTRATOR_ROLE_ID ||
    ('projectWorkerIds' in projectData &&
      projectData.projectWorkerIds.includes(_id)) ||
    ('projectName' in projectData && projectData.projectName === 'Leave') ? (
    <>
      <Typography sx={{ fontWeight: 'bold' }} variant="h4">
        {projectName}
      </Typography>
      <TaskHeader
        title="Tasks"
        options={{
          importOption: false,
          exportOption: false,
          createOption: CREATE,
          assignOption: ASSIGN
        }}
        links={{}}
        projectName={projectName}
        notAssinedEmployees={allEmployees.filter(
          (e) => !projectData.projectWorkerIds.includes(e._id)
        )}
        getEmployeeData={getEmployeeData}
        ProjectWorkers={
          projectData.projectWorkerIds && allEmployees.length != 0
            ? projectData.projectWorkerIds.map((e) => {
                return {
                  _id: e,
                  employeeDisplay: allEmployees.find((emp) => emp._id === e)
                    ?.employeeDisplay
                };
              })
            : []
        }
        getProjectData={getProjectData}
      />
      <TaskTable headers={headers} data={data} />
    </>
  ) : (
    <h1>You are not assigned to this project.</h1>
  );
};
