import React from 'react';
import ReactDom from 'react-dom';
import { Box, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const MODAL_STYLES = {
  position: 'fixed',
  top: '50%',
  left: '50%',
  maxHeight: '90%',
  maxWidth: '90%',
  transform: 'translate(-50%, -50%)',
  backgroundColor: '#FFF',
  padding: '50px',
  zIndex: 10000000000,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center'
};

const OVERLAY_STYLES = {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0, 0, 0, .7)',
  zIndex: 10000000000,
  padding: '1vw'
};

export const Modal = ({ open, children, handleClose }) => {
  if (!open) return null;

  return ReactDom.createPortal(
    <>
      <Box style={OVERLAY_STYLES} />
      <Box style={MODAL_STYLES}>
        <IconButton
          style={{ position: 'fixed', top: '3%', right: '3%' }}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
        {children}
      </Box>
    </>,
    document.getElementById('portal')
  );
};
