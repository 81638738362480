export const TagsToSkip = [
  'logo',
  'policy_name',
  'generic_introduction',
  'generic_objective',
  'generic_scope',
  'generic_responsibility',
  'generic_content',
  'generic_exceptions',
  'generic_violation',
  'generic_review',
  'generic_reference'
];
