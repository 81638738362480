import { TableContainer, Paper, Table } from '@mui/material';
import { TaskTableBody } from '../TaskTableBody';
import { TaskTableHead } from '../TaskTableHead';
import React from 'react';

export const TaskTable = ({ headers, data }) => {
  return (
    <TableContainer
      component={Paper}
      sx={{ width: '100%', mt: 5, maxHeight: '65vh' }}
    >
      <Table stickyHeader>
        <TaskTableHead currHeaders={headers} />
        <TaskTableBody currHeaders={headers} data={data} />
      </Table>
    </TableContainer>
  );
};
