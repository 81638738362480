import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Button, TextField, Typography, MenuItem } from '@mui/material';
import {
  updateRCMRequest,
  getRCMByIdRequest,
  downloadCSARequest,
  getApplicationByIdRequest
} from '../../../constants/requests';
import { useLoading } from '../../../hooks/useLoading';
import { useNotifications } from '../../../hooks/useNotifications';
import { stickyColumnTemplateMapping } from '../common/stickyColumnTemplateMapping';
import moment from 'moment';
import Navbar from '../common/Navbar';
import TabPanel from '@mui/lab/TabPanel';
import FileDownload from 'js-file-download';
import TabContext from '@mui/lab/TabContext';
import CustomTable from '../common/CustomTable';

const setConditionalDateObj = {
  'Control status Complied/Not Complied by Control Performer (if complied ask for evidence upload/folder reference else if not action to be taken gap tracker to open)':
    {
      equals: 'Complied',
      set: 'Control Performer User id Date and Time',
      calculate: 'Complied On(Time/Delay)',
      minus: 'Due date'
    },
  'Control status complied/Not complied by control owner(Approve/Refer back (reason to be mentioned) if approve status change to comply)':
    {
      equals: 'Complied',
      set: 'Control Owner User id Date and time'
    }
};

const CSA = () => {
  const { request } = useLoading();
  const { showNotifications } = useNotifications();
  const { engagementId, applicationId } = useParams();
  const [RCMId, setRCMId] = useState(null);
  const [RCMData, setRCMData] = useState(null);
  const [currentRCMTabName, setCurrentRCMTabName] = useState(null);
  const [csaRows, setCSARows] = useState([]);

  const handleChange = (rowNumber, fieldName, cellValue, tabName) => {
    const newCSAData = [...csaRows];
    csaRows[rowNumber][fieldName] = cellValue;

    if (fieldName in setConditionalDateObj) {
      if (cellValue === setConditionalDateObj[fieldName]['equals']) {
        const currentDate = moment();
        csaRows[rowNumber][setConditionalDateObj[fieldName]['set']] =
          moment().format('yyyy-MM-DD');
        if (setConditionalDateObj[fieldName]['calculate'] !== undefined) {
          const dueDate =
            csaRows[rowNumber][setConditionalDateObj[fieldName]['minus']];
          if (dueDate !== '') {
            csaRows[rowNumber][setConditionalDateObj[fieldName]['calculate']] =
              currentDate.diff(dueDate, 'days') + ' days';
          }
        }
      }
    }

    setCSARows(newCSAData);

    const newRCMData = {
      meta: RCMData.meta,
      rows: {
        [tabName]: RCMData.rows[tabName].map(
          (rowObj) =>
            csaRows.find((csaObj) => csaObj['S.No'] === rowObj['S.No']) ||
            rowObj
        )
      }
    };
    setRCMData(newRCMData);
  };

  const removeDuplicates = (duplicateString) => {
    return [...new Set(duplicateString.split(','))].join(', ').slice(0, -2);
  };

  const quarterCondition = (frequency) => {
    const QUARTER = moment().quarter();
    switch (frequency) {
      case '':
        return false;
      case 'Annual':
        return QUARTER === 4;
      case 'Half-Yearly':
        return QUARTER === 2 || QUARTER === 4;
      default:
        return true;
    }
  };

  // Hardcoded Function required only for SOC2.0 & ISO27001 for now.
  const getCSAData = async (RCMBody) => {
    try {
      const { POFdata, data, _id } = RCMBody;
      setRCMId(_id);

      let csaData = [];
      for (const tabName in data.rows) {
        for (const row of data.rows[tabName]) {
          let POFObj = { finalSubProcess: '', finalCriteriaNumbers: '' };
          if (POFdata !== null) {
            for (const POFRow of POFdata.rows['PointOfFocus']) {
              if (POFRow['Link Controls'].includes(row['Control No.'])) {
                POFObj.finalSubProcess += POFRow['Trust Principle'] + ',';
                POFObj.finalCriteriaNumbers += POFRow['TSC Ref#'] + ',';
              }
            }
          }
          row['Evidence uploaded'] = row['Control No.'];
          row['If Yes Upload Evidence or Reference to Folder'] =
            row['Control No.'];
          row['Sub Process(Trust principle)'] = removeDuplicates(
            POFObj.finalSubProcess
          );
          row['Control Self Assessment Question'] =
            'I confirm that ' + row['Control Activity'];
          row['Control Objective No.(Common Criterea  No.)'] = removeDuplicates(
            POFObj.finalCriteriaNumbers
          );
        }
        const csaFilter = data.rows[tabName].filter((row) =>
          quarterCondition(row['Control Frequency'])
        );
        csaData = [...csaData, ...csaFilter];
      }

      const payload = {
        data: data
      };
      let response = await request(() => updateRCMRequest(_id, payload));
      if (response.data.statusCode === 200) {
        setRCMData(data);
        setCSARows(csaData);
        showNotifications('success', response.data.message, 5000);
      } else {
        showNotifications('error', response.data.message, 5000);
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
      console.log(err);
    }
  };

  const getRCM = async () => {
    try {
      let response = await request(() =>
        getApplicationByIdRequest(applicationId)
      );
      if (response.data.statusCode === 200) {
        const RCMId = response.data.body.RCMId?._id;
        if (!RCMId) {
          setRCMData(null);
          return;
        }
        response = await request(() => getRCMByIdRequest(RCMId));
        await getCSAData(response.data.body);
        setCurrentRCMTabName(Object.keys(response.data.body.data.rows)[0]);
        showNotifications('success', response.data.message, 5000);
      } else {
        showNotifications('error', response.data.message, 5000);
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
      console.log(err);
    }
  };

  const handleSave = async () => {
    try {
      const payload = {
        data: RCMData
      };

      for (const row of csaRows) {
        if (
          row['CSA Applicable(Yes/No/NA)'] !== undefined &&
          row['CSA Applicable(Yes/No/NA)'] !== 'Yes'
        ) {
          if (
            row['Remarks by Client Organisation(if No or NA)'] !== undefined &&
            row['Remarks by Client Organisation(if No or NA)'] === ''
          ) {
            showNotifications(
              'error',
              'Remarks by Client Organisation cannot be empty',
              5000
            );
            return;
          }
        }
      }

      let response = await request(() => updateRCMRequest(RCMId, payload));
      if (response.data.statusCode === 200) {
        showNotifications('success', response.data.message, 5000);
      } else {
        showNotifications('error', response.data.message, 5000);
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
      console.log(err);
    }
  };

  const handleDownload = async () => {
    try {
      const res = await request(() => downloadCSARequest(applicationId));
      if (res?.response?.data?.statusCode) {
        showNotifications('error', res.response.data.message, 5000);
      } else {
        FileDownload(res.data, `CSA_${applicationId}.xlsx`);
        showNotifications('success', 'Downloaded successfully', 5000);
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  useEffect(() => {
    getRCM();
  }, []);

  return (
    <React.Fragment>
      <Navbar
        pageName="CSA"
        engagementId={engagementId}
        applicationId={applicationId}
      />
      <Box
        style={{
          marginBottom: '25px',
          display: 'flex',
          justifyContent: 'flex-end',
          gap: '10px'
        }}
      >
        {RCMData && csaRows.length > 0 ? (
          <Button
            variant="contained"
            style={{ color: 'white', background: 'black' }}
            onClick={handleDownload}
          >
            Download
          </Button>
        ) : null}
        {RCMData && csaRows.length > 0 ? (
          <TextField
            disabled={RCMData === null}
            select
            label="Tab Name"
            value={currentRCMTabName}
            onChange={(e) => setCurrentRCMTabName(e.target.value)}
            style={{ minWidth: '200px' }}
            size="small"
            InputLabelProps={{ shrink: true }}
          >
            {RCMData &&
              Object.keys(RCMData.rows)?.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
          </TextField>
        ) : null}
        {RCMData && csaRows.length > 0 ? (
          <Button variant="contained" color="success" onClick={handleSave}>
            Save
          </Button>
        ) : null}
      </Box>
      <TabContext value={currentRCMTabName}>
        {RCMData && csaRows.length > 0 ? (
          Object.keys(RCMData.rows).map((RCMTab) => {
            return (
              <TabPanel
                key={`RCM Table: ${RCMTab}`}
                value={RCMTab}
                index={RCMTab}
                sx={{ p: '0px' }}
              >
                <CustomTable
                  tabName={RCMTab}
                  rows={csaRows}
                  tableMeta={RCMData.meta.CSA}
                  handleChangeCB={handleChange}
                  fieldsMeta={RCMData.meta.fields}
                  stickyColumnName={
                    stickyColumnTemplateMapping['CSA']['column']
                  }
                  topStyle={stickyColumnTemplateMapping['CSA']['style']}
                />
              </TabPanel>
            );
          })
        ) : (
          <Box style={{ display: 'flex', justifyContent: 'center' }}>
            <Typography sx={{ fontWeight: 'bold', color: 'Red' }} variant="h5">
              No Records For This Quarter Found. Please Select Control Frequency
              in RCM First.
            </Typography>
          </Box>
        )}
      </TabContext>
    </React.Fragment>
  );
};

export default CSA;
