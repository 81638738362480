import React, { useEffect } from 'react';
import {
  Box,
  Table,
  TableCell,
  tableCellClasses,
  TableHead,
  TableRow,
  TableBody,
  TableContainer,
  Typography
} from '@mui/material';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { useState } from 'react';
import { useLoading } from '../../../hooks/useLoading';
import { useNotifications } from '../../../hooks/useNotifications';
import { getAllVersionsOfPolicyTemplateById } from '../../../constants/requests';
import RevertPolicyVersionModal from './RevertPolicyVersionModal';
import HistoryIcon from '@mui/icons-material/History';
import DownloadIcon from '@mui/icons-material/Download';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14
  }
}));

const StyledTableRow = styled(TableRow)(() => ({
  '&:last-child td, &:last-child th': {
    border: 0
  }
}));

const AllPolicyTemplateVersions = ({
  selectPolicyId,
  handleDownloadWordVersion
}) => {
  const { request } = useLoading();
  const { showNotifications } = useNotifications();

  const [policyName, setPolicyName] = useState('random');
  const [tableData, setTableData] = useState([]);

  const [selectPolicyVersionId, setSelectedPolicyVersionId] = useState('');
  const [selectPolicyVersionNumber, setSelectedPolicyVersionNumber] =
    useState('1.0');

  const getCurrentPolicyTemplateVersions = async () => {
    try {
      const response = await request(() =>
        getAllVersionsOfPolicyTemplateById(selectPolicyId)
      );

      if (response?.data?.statusCode === 200) {
        setPolicyName(response.data.body.name);
        const newTableData = response.data.body.wordDocumentID.versions.map(
          (version, idx) => {
            return {
              version: `${idx + 1}.0`,
              _id: selectPolicyId,
              versionId: version.versionId,
              comment: version.comments,
              versionNo: idx
            };
          }
        );
        setTableData(newTableData);
      } else {
        if (response.response.data.statusCode !== 200) {
          showNotifications('error', response.response.data.message, 5000);
        }
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  useEffect(() => {
    getCurrentPolicyTemplateVersions();
    handleDownloadWordVersion;
  }, []);

  const [openRevertPolicyVersionModal, setOpenRevertPolicyVersionModal] =
    useState(false);
  const handleOpenRevertPolicyVersionModal = () =>
    setOpenRevertPolicyVersionModal(true);
  const handleCloseRevertPolicyVersionModal = () =>
    setOpenRevertPolicyVersionModal(false);

  return (
    <React.Fragment>
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '30px'
        }}
      >
        <Typography sx={{ fontWeight: 'bold', flexGrow: 1 }} variant="h4">
          Versions - {policyName}
        </Typography>
      </Box>
      <TableContainer
        component={Paper}
        sx={{ maxHeight: 500, overflow: 'auto' }}
      >
        <Table sx={{ minWidth: 500 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell key={`table-version-header`} align="center">
                Version No.
              </StyledTableCell>
              <StyledTableCell key={`table-comment-header`} align="center">
                Comment
              </StyledTableCell>
              <StyledTableCell key={`table-option-header`} align="center">
                Options
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData.map((data) => {
              return (
                <StyledTableRow
                  hover
                  style={{ cursor: 'pointer' }}
                  key={`policy-row-${data._id}-${data.version}`}
                >
                  {Object.keys(data).map((key) => {
                    if (
                      key !== '_id' &&
                      key !== 'versionId' &&
                      key !== 'versionNo'
                    ) {
                      return (
                        <StyledTableCell
                          key={`policy-row-cell-${Math.random(data._id)}`}
                          sx={{ maxWidth: '400px' }}
                        >
                          <Box
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              gap: '5px',
                              fontSize: key === 'comment' ? '15px' : '17px'
                            }}
                          >
                            {data[key]}
                          </Box>
                        </StyledTableCell>
                      );
                    }
                  })}
                  <StyledTableCell align="center">
                    <Box
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        gap: '5px'
                      }}
                    >
                      <HistoryIcon
                        onClick={() => {
                          setSelectedPolicyVersionId(data.versionId);
                          setSelectedPolicyVersionNumber(data.version);
                          handleOpenRevertPolicyVersionModal();
                        }}
                      />
                      <DownloadIcon
                        sx={{ marginLeft: '2px' }}
                        onClick={() => {
                          handleDownloadWordVersion(
                            selectPolicyId,
                            data.versionNo
                          );
                        }}
                      />
                    </Box>
                  </StyledTableCell>
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      {/*Revert To Policy Modal*/}
      <RevertPolicyVersionModal
        openRevertPolicyVersionModal={openRevertPolicyVersionModal}
        handleCloseRevertPolicyVersionModal={
          handleCloseRevertPolicyVersionModal
        }
        getCurrentPolicyTemplateVersions={getCurrentPolicyTemplateVersions}
        selectPolicyVersionId={selectPolicyVersionId}
        selectPolicyId={selectPolicyId}
        selectPolicyVersionNumber={selectPolicyVersionNumber}
      />
    </React.Fragment>
  );
};

export default AllPolicyTemplateVersions;
