import React, { useState } from 'react';
import { Box, Button, Typography, Modal, TextField } from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useLoading } from '../../../hooks/useLoading';
import { useNotifications } from '../../../hooks/useNotifications';
import { saveSocExcel } from '../../../constants/requests';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  marginBottom: '10px',
  bgcolor: 'background.paper',
  borderRadius: '10px',
  boxShadow: 24,
  p: 4,
  overflow: 'scroll',
  maxHeight: '100%',
  minWidth: '600px'
};
const SaveModal = ({ openSaveModal, handleCloseSaveModal, fetchedData }) => {
  const { request } = useLoading();
  const { showNotifications } = useNotifications();
  const [companyName, setCompanyName] = useState('');
  const [startYear, setStartYear] = useState(new Date().getFullYear() + 1);
  const [endYear, setEndYear] = useState(new Date().getFullYear() + 1);
  const handleSubmit = async () => {
    if (!companyName || !startYear || !endYear) {
      showNotifications('error', 'Please fill all the fields', 5000);
      return;
    }
    if (startYear > endYear) {
      showNotifications(
        'error',
        'Start Year cannot be greater than End Year',
        5000
      );
      return;
    }
    const payload = {
      companyName: companyName,
      reportFY: `${startYear}-${endYear}`,
      json: fetchedData
    };

    try {
      const response = await request(() => saveSocExcel(payload));
      if (response && response.status === 200) {
        showNotifications('success', 'Report Saved Successfully', 5000);
        handleCloseSaveModal();
      } else {
        showNotifications('error', 'Something went wrong', 5000);
      }
    } catch (error) {
      showNotifications('error', 'Something went wrong', 5000);
    }
  };
  return (
    <Modal
      open={openSaveModal}
      onClose={handleCloseSaveModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={style}
        style={{ width: '50%', height: '95%', overflowX: 'scroll' }}
      >
        <Typography
          fullWidth
          id="modal-modal-title"
          variant="h5"
          component="h2"
          style={{ marginBottom: '10px' }}
        >
          Save Report
        </Typography>
        <TextField
          fullWidth
          value={companyName}
          name="companyName"
          label="Enter Company Name"
          onChange={(e) => setCompanyName(e.target.value)}
        />
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DesktopDatePicker
            label="Report Start Year"
            views={['year']}
            style={{ marginTop: 20 }}
            inputFormat="YYYY"
            renderInput={(params) => (
              <TextField
                name="reportStartYear"
                required
                fullWidth
                {...params}
                style={{ marginTop: 20 }}
              />
            )}
            value={startYear}
            onChange={(newValue) =>
              setStartYear(newValue?.format('YYYY').toString())
            }
          />
          <DesktopDatePicker
            style={{ marginTop: 20 }}
            label="Report End Year"
            views={['year']}
            inputFormat="YYYY"
            renderInput={(params) => (
              <TextField
                name="reportEndYear"
                value={endYear}
                required
                fullWidth
                style={{ marginTop: 20 }}
                {...params}
              />
            )}
            value={endYear}
            onChange={(newValue) =>
              setEndYear(newValue?.format('YYYY').toString())
            }
          />
        </LocalizationProvider>
        <Box
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginTop: 20
          }}
        >
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            Submit
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleCloseSaveModal}
          >
            Cancel
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default SaveModal;
