import React, { memo, useState } from 'react';
import moment from 'moment';
import { MenuItem, TextField } from '@mui/material';
import Select from 'react-select';
import { TextareaAutosize } from '@mui/base/TextareaAutosize';


export default memo(function GetTableCell({
  rowNumber,
  fieldName,
  fieldType,
  value,
  onChangeCB,
  isEditable,
  options,
  styles,
  multiSelectOptions,
  fieldValueArray,
  minRowsTable = 3,
  maxRowsTable = 5
}) {
  const [localValue, setLocalValue] = useState(value);

  if (fieldName === 'dateOfRequest') {
    return (
      <TextField
        fullWidth
        align="left"
        name={fieldName}
        disabled={!isEditable}
        value={
          moment(value).format('yyyy-MM-DD') === 'Invalid date'
            ? `Enter ${fieldName}`
            : moment(value).format('yyyy-MM-DD')
        }
        label={`Enter ${fieldName}`}
        InputLabelProps={{
          shrink: true
        }}
        onChange={(e) => onChangeCB(rowNumber, fieldName, e.target.value)}
      />
    );
  }

  switch (fieldType) {
    case 'textField':
      return (
        <TextField
          id={`rcm-cell-${rowNumber}-${fieldName}`}
          disabled={!isEditable}
          fullWidth
          value={localValue}
          label={`Enter ${fieldName}`}
          onChange={(e) => setLocalValue(e.target.value)}
          onBlur={(e) => onChangeCB(rowNumber, fieldName, e.target.value)}
          style={styles}
        />
      );
    case 'multiLineTextField':
      return (
        <TextareaAutosize
          id={`rcm-cell-${rowNumber}-${fieldName}`}
          disabled={!isEditable}
          value={localValue}
          placeholder={`Enter ${fieldName}`}
          onChange={(e) => setLocalValue(e.target.value)}
          onBlur={(e) => {
            onChangeCB(rowNumber, fieldName, e.target.value);
          }}
          minRows={minRowsTable}
          maxRows={maxRowsTable}
          style={{ ...styles, width: '100%' }}
        />
      );

    case 'date':
      return (
        <TextField
          fullWidth
          align="left"
          name={fieldName}
          disabled={!isEditable}
          type="date"
          value={moment(localValue).format('yyyy-MM-DD')}
          onChange={(e) => setLocalValue(e.target.value)}
          label={`Enter ${fieldName}`}
          InputLabelProps={{
            shrink: true
          }}
          onBlur={(e) => onChangeCB(rowNumber, fieldName, e.target.value)}
        />
      );

    case 'dropDown':
      return (
        <TextField
          id={`rcm-cell-${rowNumber}-${fieldName}`}
          disabled={!isEditable}
          select
          label="Choose"
          fullWidth
          value={localValue}
          onChange={(e) => setLocalValue(e.target.value)}
          onBlur={(e) => onChangeCB(rowNumber, fieldName, e.target.value)}
          style={styles}
        >
          {options?.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      );

    case 'multiselect':
      return (
        <Select
          options={multiSelectOptions}
          value={fieldValueArray}
          isMulti
          name={fieldName}
        />
      );

    default:
      return null;
  }
});
