import { TableContainer, Paper, Table } from '@mui/material';
import React from 'react';
import { ProjectTableBody } from '../ProjectTableBody';
import { ProjectTableHead } from '../ProjectTableHead';

export const ProjectTable = ({
  headers,
  data,
  toggleUpdate,
  url,
  fields,
  options
}) => {
  return (
    <TableContainer
      component={Paper}
      sx={{ width: '100%', mt: 5, maxHeight: '72vh' }}
    >
      <Table stickyHeader>
        <ProjectTableHead currHeaders={headers} />
        <ProjectTableBody
          currHeaders={headers}
          data={data}
          toggleUpdate={toggleUpdate}
          url={url}
          fields={fields}
          options={options}
        />
      </Table>
    </TableContainer>
  );
};
