import React, { useEffect, useState } from 'react';
import { Box, Modal, Typography, Button } from '@mui/material';
import { useLoading } from '../../../hooks/useLoading';
import { useNotifications } from '../../../hooks/useNotifications';
import GetTableCell from '../Components/GetTableCell';
import { createGenericPolicyMaster } from '../../../constants/requests';
// import { createTemplateType } from '../../../constants/requests';
// import { createTemplateType } from '../../../constants/requests';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 750,
  bgcolor: 'white',
  boxShadow: 24,
  borderRadius: '10px',
  maxHeight: '85vh',
  overflowY: 'auto',
  p: 4
};

const AddGenericContentModal = ({
  openAddGenericContentModal,
  handleCloseAddGenericContentModal,
  getPolicyDetails,
  open,
  setOpen
}) => {
  const { request } = useLoading();
  const { showNotifications } = useNotifications();
  const [policyContent, setPolicyContent] = useState({
    generic_introduction: '',
    generic_objective: '',
    generic_scope: '',
    generic_responsibility: '',
    generic_exceptions: '',
    generic_violation: '',
    generic_review: '',
    generic_reference: ''
  });
  const [policyName, setPolicyName] = useState('');

  useEffect(() => {
    open;
    setOpen;
  }, []);

  const createGenericPolicyContent = async () => {
    try {
      const data = { ...policyContent, name: policyName };
      const response = await request(() => createGenericPolicyMaster(data));
      if (response?.data?.statusCode === 201) {
        handleCloseAddGenericContentModal();
        getPolicyDetails();
        setPolicyContent({
          generic_introduction: '',
          generic_objective: '',
          generic_scope: '',
          generic_responsibility: '',
          generic_exceptions: '',
          generic_violation: '',
          generic_review: '',
          generic_reference: ''
        });
        setPolicyName('');
        showNotifications('success', response.data.message, 5000);
      } else {
        if (response.response.data.statusCode !== 201) {
          showNotifications('error', response.response.data.message, 5000);
        }
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  const policyContentChangeHandler = (rowNumber, heading, value) => {
    const newPolicyContent = { ...policyContent };
    newPolicyContent[heading] = value;
    setPolicyContent(newPolicyContent);
  };

  const policyContentChangeHandler2 = (rowNumber, heading, value) => {
    setPolicyName(value);
  };

  return (
    <Modal
      open={openAddGenericContentModal}
      onClose={handleCloseAddGenericContentModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography
          id="modal-modal-title"
          variant="h4"
          component="h2"
          sx={{ textAlign: 'center', marginBottom: 1, fontWeight: 600 }}
        >
          New Policy Master
        </Typography>
        <Box key={Math.random()} sx={{ marginTop: '25px' }}>
          <Typography
            variant="h6"
            sx={{ fontWeight: '600', fontSize: '25px' }}
            key={Math.random()}
          >
            Generic Policy Master Name
          </Typography>
          <GetTableCell
            key={Math.random()}
            rowNumber={'1'}
            fieldName={'generic_name'}
            fieldType={'multiLineTextField'}
            value={policyName}
            onChangeCB={policyContentChangeHandler2}
            isEditable={true}
          />
        </Box>
        <Box>
          {Object.keys(policyContent)
            .slice(0, 4)
            .map((heading) => {
              return (
                <Box key={Math.random()} sx={{ marginTop: '10px' }}>
                  <Typography
                    variant="h6"
                    sx={{ fontWeight: '600', fontSize: '25px' }}
                    key={Math.random()}
                  >
                    {heading.split('_')[1].charAt(0).toUpperCase() +
                      heading.split('_')[1].slice(1)}{' '}
                  </Typography>
                  <GetTableCell
                    key={Math.random()}
                    rowNumber={'1'}
                    fieldName={heading}
                    fieldType={'multiLineTextField'}
                    value={policyContent[heading]}
                    onChangeCB={policyContentChangeHandler}
                    isEditable={true}
                  />
                </Box>
              );
            })}

          {Object.keys(policyContent)
            .slice(4)
            .map((heading) => {
              return (
                <Box key={Math.random()} sx={{ marginTop: '10px' }}>
                  <Typography
                    variant="h6"
                    sx={{ fontWeight: '600', fontSize: '25px' }}
                    key={Math.random()}
                  >
                    {heading.split('_')[1].charAt(0).toUpperCase() +
                      heading.split('_')[1].slice(1)}{' '}
                  </Typography>
                  <GetTableCell
                    key={Math.random()}
                    rowNumber={'1'}
                    fieldName={heading}
                    fieldType={'multiLineTextField'}
                    value={policyContent[heading]}
                    onChangeCB={policyContentChangeHandler}
                    isEditable={true}
                  />
                </Box>
              );
            })}
        </Box>

        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '2px'
          }}
        >
          <Button
            variant="contained"
            style={{ marginTop: '10px' }}
            onClick={createGenericPolicyContent}
          >
            Create
          </Button>
          <Button
            variant="contained"
            style={{ marginTop: '10px' }}
            onClick={() => {
              setPolicyName('');
              setPolicyContent({
                generic_introduction: '',
                generic_objective: '',
                generic_scope: '',
                generic_responsibility: '',
                generic_exceptions: '',
                generic_violation: '',
                generic_review: '',
                generic_reference: ''
              });
              handleCloseAddGenericContentModal();
            }}
          >
            Close
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default AddGenericContentModal;
