import React, { useState } from 'react';
import { TextField, Chip, Box } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';

const EmailList = ({ chips, setChips }) => {
  const [errorMessage, setErrorMessage] = useState('');

  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    if (event.key === 'Enter' && inputValue.trim() !== '') {
      const trimmedInput = inputValue.trim();
      if (validateEmail(trimmedInput) && !chips.includes(trimmedInput)) {
        setChips((prevChips) => [...prevChips, trimmedInput]);
        setErrorMessage('');
      } else {
        setErrorMessage('Invalid email format');
      }
      event.target.value = '';
    }
  };

  const handleInputPaste = (event) => {
    const pastedText = event.clipboardData.getData('text');
    const extractedEmails = extractEmails(pastedText);
    const validEmails = extractedEmails.filter((email, index, self) => {
      const lowerCaseEmail = email.toLowerCase();
      return (
        validateEmail(email) &&
        !chips.some((chip) => chip.toLowerCase() === lowerCaseEmail) &&
        self.indexOf(email) === index
      );
    });
    setChips((prevChips) => [...prevChips, ...validEmails]);
    event.target.value = '';
    event.preventDefault();
  };

  const handleRemoveChip = (chip) => {
    setChips((prevChips) => prevChips.filter((c) => c !== chip));
  };

  const extractEmails = (text) => {
    const emailRegex = /[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}/g;
    return text.match(emailRegex) || [];
  };

  const validateEmail = (email) => {
    // Basic email format validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  return (
    <React.Fragment>
      <Box sx={{ marginBottom: '15px' }}>
        {chips.map((chip) => (
          <Chip
            key={chip}
            label={chip}
            onDelete={() => handleRemoveChip(chip)}
            deleteIcon={<CancelIcon />}
            style={{ margin: '4px' }}
          />
        ))}
      </Box>
      <TextField
        variant="outlined"
        label="Type or paste email addresses and press `Enter`"
        onKeyDown={handleInputChange}
        onPaste={handleInputPaste}
        fullWidth
        error={!!errorMessage}
        helperText={errorMessage}
      />
    </React.Fragment>
  );
};

export default EmailList;
