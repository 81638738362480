import React, { useState } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
// import Box from "@mui/material/Box";
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
// import Button from "@mui/material/Button";
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import AddressForm from './AddressForm';
import IdentificationForm from './CEODetails';
import axios from 'axios';
import { useDataLayerValue } from '../../contextAPI/DataLayer';
import { useNotifications } from '../../hooks/useNotifications';
import { BACKEND_URL } from '../../config';

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const steps = ['Client Company Details', 'Client CEO & Project Details'];

const theme = createTheme();

export default function Checkout() {
  const [{ _id, fname, lname, email }] = useDataLayerValue();
  const { showNotifications } = useNotifications();
  const [clientDetails, setClientDetails] = useState({
    zohoLinkID: '',
    name: '',
    type: '',
    PAN: '',
    currency: '',
    riskType: '',
    // billingMethod: "",
    companySize: '',
    companyDesc: '',

    address: '',
    city: '',
    state: '',
    zip: '',
    country: '',
    phone: '',
    email: '',
    registeredDate: '',
    ceofName: '',
    ceolName: '',
    ceoEmail: '',
    ceoPhone: ''

    // industry: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setClientDetails((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = (e) => {
    console.log(clientDetails);
    e.preventDefault();
    if (activeStep === steps.length - 1) {
      console.log(clientDetails);
      axios
        .post(
          BACKEND_URL + '/clients/add',
          {
            ...clientDetails,
            addedByID: _id,
            addedByName: `${fname} ${lname}`,
            addedByEmail: email
          },
          {
            headers: {
              employeeid: _id
            }
          }
        )
        .then((res) => {
          console.log(res.data);
          if (res.data.statusCode === 201) {
            console.log('I am here: \n', res.data);
            setActiveStep(activeStep + 1);
          } else showNotifications('error', res.data.message, 2000);
        })
        .catch((err) => showNotifications('error', err.data.message, 2000));
    } else setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <AddressForm
            handleNext={handleNext}
            handleChange={handleChange}
            clientDetails={clientDetails}
          />
        );
      case 1:
        return (
          <IdentificationForm
            handleNext={handleNext}
            handleBack={handleBack}
            handleChange={handleChange}
            clientDetails={clientDetails}
          />
        );
      default:
        throw new Error('Unknown step');
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container component="main" maxWidth="md" sx={{ mb: 4 }}>
        <Paper
          variant="outlined"
          sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
        >
          <Typography component="h1" variant="h4" align="center">
            Add Client
          </Typography>
          <Stepper activeStep={activeStep} sx={{ pt: 3, pb: 5 }}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <React.Fragment>
            {activeStep === steps.length ? (
              <React.Fragment>
                <Typography variant="h5" gutterBottom>
                  Client Added
                </Typography>
                <Typography variant="subtitle1">
                  Client was added Successfully.
                </Typography>
              </React.Fragment>
            ) : (
              <React.Fragment>{getStepContent(activeStep)}</React.Fragment>
            )}
          </React.Fragment>
        </Paper>
        <Copyright />
      </Container>
    </ThemeProvider>
  );
}
