import React, { useEffect, useState } from 'react';
import {
  Box,
  Table,
  Button,
  TableCell,
  tableCellClasses,
  TableHead,
  TableRow,
  TableBody,
  TableContainer,
  Typography
} from '@mui/material';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { useNavigate, useParams } from 'react-router-dom';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import {
  downloadMasterExcelFromJson,
  getEngagementPolicyById,
  getGapIdFromPolicyEngagementId,
  getPolicyEngagementById,
  updateEngagementPolicyById_Table,
  getAllPolicyScope
} from '../../../constants/requests';
import { useLoading } from '../../../hooks/useLoading';
import { useNotifications } from '../../../hooks/useNotifications';
import DeleteIcon from '@mui/icons-material/Delete';
import UploadExcelModal from '../Components/UploadExcelModal';
import DeleteEngagementPolicyModal from '../Components/DeleteEngagmentPolicyModal';
import AddEngagementPolicyModal from '../Components/AddEngagementPolicyModal';
import fileDownload from 'js-file-download';
import PolicyScopeModal from './Components/PolicyScopeModal';
import { MultiSelectComponent } from './Components/MultiSelectComponent';
import PreviewPolicyModal from './Components/PreviewPolicyModal';
import PublishPolicyModal from './Components/PublishPolicyModal';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14
  }
}));

const StyledTableRow = styled(TableRow)(() => ({
  '&:last-child td, &:last-child th': {
    border: 0
  }
}));

const AllEngagementPolicies = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { request } = useLoading();
  const { showNotifications } = useNotifications();
  const [policies, setPolicies] = useState([]);
  const [clientName, setClientName] = useState('Client');
  const [selectPolicyId, setSelectedPolicyId] = useState('');
  const [gapId, setGapId] = useState(null);
  const [open, setOpen] = useState(false);
  const [policyScopes, setPolicyScopes] = useState([]);
  const [policyData, setPolicyData] = useState({});
  const [openPreview, setOpenPreview] = useState(false);

  const [policyNameIdMapping, setPolicyNameIdMapping] = useState({});
  const [openUploadExcelModal, setOpenUploadExcelModal] = useState(false);
  const handleOpenUploadExcelModal = () => setOpenUploadExcelModal(true);
  const handleCloseUploadExcelModal = () => setOpenUploadExcelModal(false);

  const [openAddEngagementPolicyModal, setOpenAddEngagementPolicyModal] =
    useState(false);
  const handleOpenAddEngagementPolicyModal = () =>
    setOpenAddEngagementPolicyModal(true);
  const handleCloseAddEngagementPolicyModal = () =>
    setOpenAddEngagementPolicyModal(false);

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const handleOpenDeleteModal = () => setOpenDeleteModal(true);
  const handleCloseDeleteModal = () => setOpenDeleteModal(false);

  const [openPreviewPolicyModal, setOpenPreviewPolicyModal] = useState(false);
  const handleOpenPreviewPolicyModal = () => setOpenPreviewPolicyModal(true);
  const handleClosePreviewPolicyModal = () => setOpenPreviewPolicyModal(false);

  const [openPublishPolicyModal, setOpenPublishPolicyModal] = useState(false);
  const handleOpenPublishPolicyModal = () => setOpenPublishPolicyModal(true);
  const handleClosePublishPolicyModal = () => setOpenPublishPolicyModal(false);

  const getAllPolicies = async (policyIds) => {
    try {
      const promises = policyIds.map(async (id) => {
        const response = await request(() => getEngagementPolicyById(id));

        setPolicyData((policyData) => ({
          ...policyData,
          ...{ [id]: response.data.body }
        }));
        setPolicyNameIdMapping((policyNameIdMapping) => ({
          ...policyNameIdMapping,
          ...{ [response.data.body.name]: id }
        }));
        return response.data.body;
      });
      const policyArray = await Promise.all(promises);
      policyArray.sort((a, b) => b.isGeneric - a.isGeneric);

      setPolicies(policyArray);
    } catch (error) {
      showNotifications(
        'error',
        'Some error while fetching all the policies',
        5000
      );
    }
  };

  const getSelectedEngagement = async () => {
    try {
      const response = await request(() => getPolicyEngagementById(params.id));
      if (response?.data?.statusCode === 200) {
        //console.log(response.data);
        setClientName(response.data.body.clientName);
        getAllPolicies(response.data.body.policies);
      } else {
        if (response.response.data.statusCode !== 200) {
          showNotifications('error', response.response.data.message, 5000);
        }
      }

      const policyScopesResponse = await request(() => getAllPolicyScope());
      if (policyScopesResponse?.data?.statusCode === 200) {
        const AllPolicyScopes = policyScopesResponse.data.body.map(
          (policyScope) => {
            return policyScope.name;
          }
        );
        setPolicyScopes(AllPolicyScopes);
      } else {
        if (policyScopesResponse.response.data.statusCode !== 200) {
          showNotifications(
            'error',
            policyScopesResponse.response.data.message,
            5000
          );
        }
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  const getGapId = async () => {
    try {
      const response = await request(() =>
        getGapIdFromPolicyEngagementId(params.id)
      );
      if (response?.data?.statusCode === 200) {
        setGapId(response.data.body._id);
      } else {
        if (response.response.data.statusCode !== 200) {
          showNotifications('error', response.response.data.message, 5000);
        }
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  const savePolicyScope = async (policyId) => {
    try {
      const response = await request(() =>
        updateEngagementPolicyById_Table(policyId, {
          scope: policyData[policyId]['scope']
        })
      );
      if (response?.data?.statusCode === 200) {
        showNotifications('success', 'Policy Scope updated successfully', 5000);
      } else {
        if (response.response.data.statusCode !== 200) {
          showNotifications('error', response.response.data.message, 5000);
        }
      }
    } catch (error) {
      showNotifications('error', error.toString(), 5000);
    }
  };

  const updatePolicyScopes = async (newValue, policyId) => {
    let newObject = { ...policyData };
    newObject[policyId]['scope'] = newValue;
    setPolicyData(newObject);
    await savePolicyScope(policyId);
  };

  useEffect(() => {
    getGapId();
    getSelectedEngagement(params.id);
  }, []);

  const masterExcelDownloadHandler = async (id) => {
    try {
      const res = await request(() => downloadMasterExcelFromJson(id));
      if (res?.status === 200) {
        fileDownload(res.data, `${clientName}_excel.xlsx`);
        showNotifications('success', 'Downloaded successfully', 5000);
      } else {
        if (res.response.data.statusCode !== 200) {
          showNotifications('error', res.response.data.message, 5000);
        }
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  //For Upload Excel File Modal
  const [policyExcelTagsTables, setPolicyExceTagsTables] = useState([]);
  const setPoliciesdata = async () => {
    const newPolicyExcelTagsTables = policies.map((policy) => {
      return {
        name: policy.name,
        message: {
          text: ''
        },
        result: 'indifferent'
      };
    });
    setPolicyExceTagsTables(newPolicyExcelTagsTables);
  };

  return (
    <React.Fragment>
      <Box
        style={{
          marginBottom: '25px',
          display: 'flex',
          justifyContent: 'space-between'
        }}
      >
        <Button
          sx={{
            color: 'white',
            backgroundColor: 'black',
            paddingLeft: 1
          }}
          variant="contained"
          onClick={() => {
            navigate('/policy/engagement');
          }}
        >
          <ChevronLeftIcon />
          Engagements
        </Button>
        {policies &&
          policies.length > 0 &&
          policies[0].type !== 'ISO Generic' && (
            <>
              <Button
                sx={{
                  color: 'white',
                  backgroundColor: 'black',
                  paddingLeft: 1
                }}
                disabled={gapId === null}
                variant="contained"
                onClick={() => {
                  navigate(`/gapTracker/gap/${gapId}`);
                }}
              >
                Gap Tracker
                <ChevronRightIcon />
              </Button>
            </>
          )}
      </Box>

      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '20px'
        }}
      >
        <Typography sx={{ fontWeight: 'bold', flexGrow: 1 }} variant="h4">
          {clientName} - Policies
        </Typography>
        <Box>
          <Button
            variant="contained"
            sx={{ backgroundColor: 'black', height: 50, marginRight: 2 }}
            onClick={() => {
              setOpen(true);
              handleOpenAddEngagementPolicyModal();
            }}
          >
            Add Policy
          </Button>

          {policies &&
            policies.length > 0 &&
            policies[0].type !== 'ISO Generic' && (
              <>
                <Button
                  variant="contained"
                  sx={{ height: 50, marginRight: 2 }}
                  onClick={() => {
                    masterExcelDownloadHandler(params.id);
                  }}
                >
                  Download Excel File
                </Button>
                <Button
                  variant="contained"
                  //hidden={policies[0].type==='ISO Generic'}
                  sx={{ height: 50, marginRight: 2 }}
                  onClick={() => {
                    setPoliciesdata();
                    handleOpenUploadExcelModal();
                  }}
                >
                  Upload Excel File
                </Button>
              </>
            )}
          <Button
            variant="contained"
            color="error"
            sx={{ height: 50 }}
            onClick={() => {
              handleOpenPublishPolicyModal();
            }}
          >
            Publish Policies
          </Button>
        </Box>
      </Box>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 500 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell
                key={`s.no`}
                align="left"
                sx={{ minWidth: '110px' }}
              >
                Version No.
              </StyledTableCell>
              <StyledTableCell key={'client-name'}>Policy Name</StyledTableCell>
              <StyledTableCell key={'client-type'} sx={{ minWidth: '110px' }}>
                Type
              </StyledTableCell>
              {/* <StyledTableCell key={`industry`} align="left">
                Status
              </StyledTableCell> */}
              <StyledTableCell
                key={'Aumyaa-key-spoc'}
                sx={{ minWidth: '110px' }}
              >
                Aumyaa Key SPOC
              </StyledTableCell>
              <StyledTableCell
                key={'Client-key-spoc'}
                sx={{ minWidth: '110px' }}
              >
                Client Key SPOC
              </StyledTableCell>
              <StyledTableCell key={'Aumyaa-spoc'}>Aumyaa SPOC</StyledTableCell>
              <StyledTableCell key={'client-spoc'}>Client SPOC</StyledTableCell>
              <StyledTableCell
                key={`options`}
                align="center"
                sx={{ minWidth: '150px' }}
              >
                Options
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {policies.map((data, idx) => {
              return (
                <StyledTableRow
                  hover
                  style={{ cursor: 'pointer' }}
                  key={`policy-row-${idx}`}
                >
                  <StyledTableCell
                    key={`policy-row-${idx}-sno`}
                    onClick={() => {
                      navigate(
                        `/policy/engagement/policy/${params.id}/${data._id}`
                      );
                    }}
                  >
                    {data.versionNo}.0
                  </StyledTableCell>
                  <StyledTableCell
                    key={`policy-row-${idx}-name`}
                    onClick={() => {
                      navigate(
                        `/policy/engagement/policy/${params.id}/${data._id}`
                      );
                    }}
                    sx={{
                      textDecoration: 'underline',
                      cursor: 'pointer',
                      color: '#032cfc'
                    }}
                  >
                    {data.name}
                  </StyledTableCell>
                  <StyledTableCell
                    key={`policy-row-${idx}-type`}
                    onClick={() => {
                      navigate(
                        `/policy/engagement/policy/${params.id}/${data._id}`
                      );
                    }}
                  >
                    {data.type}
                  </StyledTableCell>
                  {/* <StyledTableCell
                    key={`policy-row-${idx}-incomplete`}
                    onClick={() => {
                      navigate(
                        `/policy/engagement/policy/${params.id}/${data._id}`
                      );
                    }}
                  >
                    In-Completed
                  </StyledTableCell> */}
                  <StyledTableCell
                    key={`aumyaa-key-spoc-${idx}-type`}
                    onClick={() => {
                      navigate(
                        `/policy/engagement/policy/${params.id}/${data._id}`
                      );
                    }}
                  >
                    {data.aumyaaApprover &&
                    data.aumyaaApprover.name.length !== 0
                      ? `${data.aumyaaApprover.name} (${data.aumyaaApprover.email})`
                      : ' '}
                  </StyledTableCell>
                  <StyledTableCell
                    key={`client-key-spoc-${idx}-type`}
                    onClick={() => {
                      navigate(
                        `/policy/engagement/policy/${params.id}/${data._id}`
                      );
                    }}
                  >
                    {data.clientApprover &&
                    data.clientApprover.name.length !== 0
                      ? `${data.clientApprover.name} (${data.clientApprover.email})`
                      : ' '}
                  </StyledTableCell>
                  <StyledTableCell key={`aumyaa-spoc-${idx}-type`}>
                    <PolicyScopeModal name="aumyaa" policyId={data._id} />
                  </StyledTableCell>
                  <StyledTableCell key={`client-spoc-${idx}-type`}>
                    <PolicyScopeModal name="client" policyId={data._id} />
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    key={`policy-row-${idx}-button`}
                  >
                    <Box
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        paddingLeft: '10px',
                        width: '300px',
                        alignItems: 'center'
                      }}
                    >
                      {data.isGeneric && (
                        <Button
                          variant="contained"
                          sx={{ marginRight: '5px' }}
                          onClick={() => {
                            setOpenPreview(true);
                            setSelectedPolicyId(data._id);
                            handleOpenPreviewPolicyModal();
                          }}
                        >
                          Preview
                        </Button>
                      )}
                      <MultiSelectComponent
                        listOfPolicyScopes={policyScopes}
                        currentValue={data.scope}
                        updateValue={updatePolicyScopes}
                        policyId={data._id}
                      />

                      <DeleteIcon
                        onClick={() => {
                          handleOpenDeleteModal();
                          setSelectedPolicyId(data._id);
                        }}
                      />
                    </Box>
                  </StyledTableCell>
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Publish Policy Modal */}
      <PublishPolicyModal
        openPublishPolicyModal={openPublishPolicyModal}
        handleClosePublishPolicyModal={handleClosePublishPolicyModal}
      />

      {/*Policy Preview Modal*/}
      <PreviewPolicyModal
        openPreviewPolicyModal={openPreviewPolicyModal}
        handleClosePreviewPolicyModal={handleClosePreviewPolicyModal}
        getSelectedEngagement={getSelectedEngagement}
        selectPolicyId={selectPolicyId}
        engagementId={params.id}
        openPreview={openPreview}
        setOpenPreview={setOpenPreview}
      />

      {/*Upload Excel Modal*/}
      <UploadExcelModal
        openUploadExcelModal={openUploadExcelModal}
        handleCloseUploadExcelModal={handleCloseUploadExcelModal}
        policyExcelTagsTables={policyExcelTagsTables}
        setPolicyExceTagsTables={setPolicyExceTagsTables}
        policyNameIdMapping={policyNameIdMapping}
      />

      {/*Add Policy Modal*/}
      <AddEngagementPolicyModal
        openAddEngagementPolicyModal={openAddEngagementPolicyModal}
        handleCloseAddEngagementPolicyModal={
          handleCloseAddEngagementPolicyModal
        }
        getPreviousSelectedEngagement={getSelectedEngagement}
        open={open}
        setOpen={setOpen}
      />

      {/*Delete Policy Modal*/}
      <DeleteEngagementPolicyModal
        openDeleteModal={openDeleteModal}
        handleCloseDeleteModal={handleCloseDeleteModal}
        selectPolicyId={selectPolicyId}
        getSelectedEngagement={getSelectedEngagement}
        engagementId={params.id}
      />
    </React.Fragment>
  );
};

export default AllEngagementPolicies;
