import React, { useState } from 'react';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { useDataLayerValue } from '../../contextAPI/DataLayer';
import ApproveModal from './ApproveModal';
import ReferBackModal from './ReferBackModal';
import RejectModal from './RejectModal';
import { fields } from '../../constants/WorkflowTableFields';
import { BACKEND_URL } from '../../config';

function TableRows({ row, onAction }) {
  const [{ _id }] = useDataLayerValue();
  const header = {
    employeeid: _id
  };

  const [acceptOpen, setAcceptOpen] = React.useState(false);
  const handleAcceptOpen = () => setAcceptOpen(true);
  const handleAcceptClose = () => {
    setAcceptOpen(false);
    setcurrComments('');
  };

  const [rejectOpen, setRejectOpen] = React.useState(false);
  const handleRejectOpen = () => setRejectOpen(true);
  const handleRejectClose = () => {
    setRejectOpen(false);
    setcurrComments('');
  };

  const [referBackOpen, setReferBackOpen] = React.useState(false);
  const handleReferBackOpen = () => setReferBackOpen(true);
  const handleReferBackClose = () => {
    setReferBackOpen(false);
    setcurrComments('');
  };

  const [currcomments, setcurrComments] = useState('');

  function handleChange(e) {
    setcurrComments(e.target.value);
  }

  function handleAccept() {
    console.log('Accept Message: ', currcomments);
    axios
      .post(
        BACKEND_URL + '/workflow-notification/approve/' + row.notificationId,
        {
          comment: currcomments
        },
        {
          headers: header
        }
      )
      .then(() => {
        onAction();
      });
    handleAcceptClose();
  }

  function handleReject() {
    console.log('Reject Message: ', currcomments);
    axios
      .post(
        BACKEND_URL + '/workflow-notification/reject/' + row.notificationId,
        {
          comment: currcomments
        },
        {
          headers: header
        }
      )
      .then(() => {
        onAction();
      });
    handleRejectClose();
  }

  function handleReferBack() {
    console.log('Refer Back Message: ', currcomments);
    axios
      .post(
        BACKEND_URL + '/workflow-notification/refer-back/' + row.notificationId,
        {
          comment: currcomments
        },
        {
          headers: header
        }
      )
      .then(() => {
        onAction();
      });
    handleReferBackClose();
  }
  return (
    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
      {fields.map((field, idx) => {
        return field.val === 'activeWorkflowId' ? (
          <TableCell key={idx} align="center" sx={{ minWidth: '100px' }}>
            <Link to={'/workflow/approvals/' + row[field.val]}>
              {'Click to View'}
            </Link>
          </TableCell>
        ) : field.val === 'actions' ? (
          <TableCell
            key={idx}
            align="center"
            sx={{
              minWidth: '110px'
            }}
          >
            <button
              style={{
                color: 'green',
                border: '2px solid green',
                padding: '4px',
                margin: '5px',
                minWidth: '80px',
                borderRadius: '4px',
                backgroundColor: 'white'
              }}
              onClick={handleAcceptOpen}
            >
              Approve
            </button>
            <button
              style={{
                border: '2px solid black',
                padding: '4px',
                margin: '5px',
                borderRadius: '6px',
                backgroundColor: 'white',
                minWidth: '80px'
              }}
              onClick={handleReferBackOpen}
            >
              Refer Back
            </button>
            <button
              style={{
                color: 'red',
                border: '2px solid red',
                padding: '4px',
                margin: '5px',
                borderRadius: '6px',
                backgroundColor: 'white',
                minWidth: '80px'
              }}
              onClick={handleRejectOpen}
            >
              Reject
            </button>{' '}
            <ApproveModal
              handleAccept={handleAccept}
              acceptOpen={acceptOpen}
              handleAcceptClose={handleAcceptClose}
              handleChange={handleChange}
              currcomments={currcomments}
            />
            <RejectModal
              handleReject={handleReject}
              rejectOpen={rejectOpen}
              handleRejectClose={handleRejectClose}
              handleChange={handleChange}
              currcomments={currcomments}
            />
            <ReferBackModal
              handleReferBack={handleReferBack}
              referBackOpen={referBackOpen}
              handleReferBackClose={handleReferBackClose}
              handleChange={handleChange}
              currcomments={currcomments}
            />
          </TableCell>
        ) : (
          <TableCell key={idx} align="center" sx={{ minWidth: '100px' }}>
            {row[field.val]}
          </TableCell>
        );
      })}
    </TableRow>
  );
}

export default TableRows;
