import React, { useEffect, useState } from 'react';
import { Box, Button, Modal, Typography, TextField } from '@mui/material';
import { useLoading } from '../../../hooks/useLoading';
import { useNotifications } from '../../../hooks/useNotifications';
import {
  addNewPoliciesToEngagement,
  getAllGenericPolicyMaster,
  getAllPolicyTemplates,
  getEngagementPolicyById,
  getPolicyEngagementById
} from '../../../constants/requests';
import { DataGrid } from '@mui/x-data-grid';
import { useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import { getAllTemplateType } from '../../../constants/requests';
import MenuItem from '@mui/material/MenuItem';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 750,
  bgcolor: 'white',
  boxShadow: 24,
  borderRadius: '10px',
  maxHeight: '85vh',
  overflowY: 'auto',
  p: 4
};

const columns = [
  {
    field: 'version',
    headerClassName: 'super-app-theme--header',
    headerName: 'Version No.',
    width: 100
  },
  {
    field: 'policyName',
    headerClassName: 'super-app-theme--header',
    headerName: 'Policy name',
    width: 250
  },
  {
    field: 'type',
    headerClassName: 'super-app-theme--header',
    headerName: 'Type',
    width: 200
  }
];

const AddEngagementPolicyModal = ({
  openAddEngagementPolicyModal,
  handleCloseAddEngagementPolicyModal,
  getPreviousSelectedEngagement,
  open,
  setOpen
}) => {
  const { request } = useLoading();
  const { showNotifications } = useNotifications();
  const params = useParams();

  const [policyData, setPolicyData] = useState([]);
  const [policyTemplateTypes, setPolicyTemplateTypes] = useState([]);
  const [policyMasterTypes, setPolicyMasterTypes] = useState([]);

  const [allSelectedPolicies, setAllSelectedPolicies] = useState([]);
  const selectPolicies = (selectedPolicies) => {
    setAllSelectedPolicies(selectedPolicies);
  };

  const [inputFields, setInputFields] = useState([
    // { id: uuidv4(), field: '', operation: '', value: '' }
  ]);

  const handleRemoveFields = (id) => {
    const values = [...inputFields];
    values.splice(
      values.findIndex((value) => value.id === id),
      1
    );
    setInputFields(values);
  };

  const handleAddFields = () => {
    setInputFields([
      ...inputFields,
      { id: uuidv4(), name: '', type: '', genericPolicyMasterID: '' }
    ]);
  };

  const handleDeleteFields = () => {
    setInputFields(inputFields.slice(0, inputFields.length - 1));
  };

  const handleVarFieldInput = (id, event, name) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i[name] = event.target.value;
      }
      return i;
    });

    setInputFields(newInputFields);
  };

  const getPolicies = async () => {
    try {
      const response = await request(() => getAllPolicyTemplates());
      if (response?.data?.statusCode === 200) {
        const newTableData = response.data.body.map((data) => {
          return {
            version: `${data.wordDocumentID.versions.length}.0`,
            policyName: data.name,
            id: data._id,
            type: data.type
          };
        });
        const allEngagementPoliciesData = await getSelectedEngagement(
          params.id
        );
        const filteredTableData = newTableData.filter((policyTemplate) => {
          return !allEngagementPoliciesData.some(
            (policy) => policy.templateID === policyTemplate.id
          );
        });
        setPolicyData(filteredTableData);
      } else {
        if (response.response.data.statusCode !== 200) {
          showNotifications('error', response.response.data.message, 5000);
        }
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  const getAllPolicies = async (policyIds) => {
    try {
      const promises = policyIds.map(async (id) => {
        const response = await request(() => getEngagementPolicyById(id));
        return response.data.body;
      });
      const policyArray = await Promise.all(promises);
      const sortedPolicies = [...policyArray].sort((a, b) =>
        a.name.localeCompare(b.name, undefined, { sensitivity: 'base' })
      );
      return sortedPolicies;
    } catch (error) {
      return [];
    }
  };

  const getSelectedEngagement = async () => {
    try {
      const response = await request(() => getPolicyEngagementById(params.id));
      if (response.data.statusCode === 200) {
        const allEngagementPoliciesData = await getAllPolicies(
          response.data.body.policies
        );
        return allEngagementPoliciesData;
      } else {
        return [];
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
      return [];
    }
  };

  const getTemplateTypes = async () => {
    try {
      const response = await request(() => getAllTemplateType());
      if (response?.data?.statusCode === 200) {
        setPolicyTemplateTypes(response.data.body);
      } else {
        if (response.response.data.statusCode !== 200) {
          showNotifications('error', response.response.data.message, 5000);
        }
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  const getGenericContentMaster = async () => {
    try {
      const response = await request(() => getAllGenericPolicyMaster());
      if (response?.data?.statusCode === 200) {
        setPolicyMasterTypes(response.data.body);
      } else {
        if (response.response.data.statusCode !== 200) {
          showNotifications('error', response.response.data.message, 5000);
        }
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  useEffect(() => {
    getPolicies();
    getTemplateTypes();
    getGenericContentMaster();
  }, []);

  const addNewPoliciesHandler = async (id) => {
    const checkGenericEmptyFields = inputFields.every(
      (obj) =>
        obj.name !== '' && obj.type !== '' && obj.genericPolicyMasterID !== ''
    );
    if (!checkGenericEmptyFields) {
      showNotifications(
        'error',
        'Please fill all the fields for generic policies',
        5000
      );
      return;
    }
    const genericPolicies = inputFields.map((policy) => {
      return {
        name: policy.name,
        type: policy.type,
        genericPolicyMasterID: policy.genericPolicyMasterID
      };
    });
    const clientPolicyObj = {
      templateIDs: allSelectedPolicies,
      genericPolicies
    };
    const counter = allSelectedPolicies.length;
    try {
      const response = await request(() =>
        addNewPoliciesToEngagement(id, clientPolicyObj)
      );
      if (response.data.statusCode === 200) {
        getPreviousSelectedEngagement(id);
        handleCloseAddEngagementPolicyModal();
        showNotifications(
          'success',
          `Added new ${counter > 1 ? 'policies' : 'policy'} successfully`,
          5000
        );
      } else {
        showNotifications('error', response.data.message, 5000);
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  if (open) {
    getPolicies();
    setOpen(false);
  }

  return (
    <Modal
      open={openAddEngagementPolicyModal}
      onClose={handleCloseAddEngagementPolicyModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography
          id="modal-modal-title"
          variant="h5"
          component="h2"
          sx={{ textAlign: 'center', marginBottom: 1, fontWeight: 600 }}
        >
          Select Policy Template
        </Typography>

        <Box sx={{ marginTop: '15px' }}>
          <Box
            sx={{
              height: 400,
              width: '100%',
              '& .MuiDataGrid-columnHeaders': {
                backgroundColor: '#5A5A5A',
                color: 'white',
                fontSize: 16
              }
            }}
          >
            <DataGrid
              rowHeight={60}
              rows={policyData}
              columns={columns}
              pageSize={5}
              rowsPerPageOptions={[5]}
              checkboxSelection
              onRowSelectionModelChange={(selectedPolicies) => {
                selectPolicies(selectedPolicies);
              }}
            />
          </Box>
        </Box>

        <Typography
          id="modal-modal-title"
          variant="h5"
          component="h2"
          sx={{
            textAlign: 'center',
            marginBottom: 2,
            fontWeight: 600,
            marginTop: 2.5
          }}
        >
          Create Generic Policy
        </Typography>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '25px'
          }}
        >
          <IconButton onClick={handleDeleteFields}>
            <RemoveIcon />
          </IconButton>
          <TextField
            sx={{
              width: inputFields.length < 10 ? '38px' : '45px',
              marginLeft: '10px',
              marginRight: '10px'
            }}
            value={inputFields.length}
            disabled
          />
          <IconButton onClick={handleAddFields}>
            <AddIcon />
          </IconButton>
        </Box>
        <Box
          sx={{
            width: '100%',
            '& .MuiDataGrid-columnHeaders': {
              backgroundColor: '#5A5A5A',
              color: 'white',
              fontSize: 16
            }
          }}
        >
          {inputFields.map((field) => (
            <Grid
              container
              spacing={2}
              key={field.id}
              sx={{ marginBottom: '10px' }}
            >
              <Grid item xs={4}>
                <TextField
                  id="value"
                  name="value"
                  value={field.name}
                  onChange={(e) => handleVarFieldInput(field.id, e, 'name')}
                  label="Policy Name *"
                  fullWidth
                  autoComplete="value"
                />
              </Grid>
              <Grid item xs={3}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Policy Type *
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    defaultValue={''}
                    label="Policy Type *"
                    onChange={(e) => handleVarFieldInput(field.id, e, 'type')}
                  >
                    {policyTemplateTypes.map((type) => {
                      return (
                        <MenuItem value={type.name} key={type.id}>
                          {type.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Master Type *
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    defaultValue={''}
                    label="Master Type *"
                    onChange={(e) => {
                      handleVarFieldInput(field.id, e, 'genericPolicyMasterID');
                    }}
                  >
                    {policyMasterTypes.map((type) => {
                      return (
                        <MenuItem value={type._id} key={type._id}>
                          {type.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={1}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center'
                  }}
                >
                  <IconButton onClick={() => handleRemoveFields(field.id)}>
                    <RemoveIcon />
                  </IconButton>
                </Box>
              </Grid>
              <Grid item xs={1}>
                <IconButton onClick={handleAddFields}>
                  <AddIcon />
                </IconButton>
              </Grid>
            </Grid>
          ))}
        </Box>
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '10px'
          }}
        >
          <Button
            variant="contained"
            style={{ marginTop: '10px' }}
            onClick={() => {
              addNewPoliciesHandler(params.id);
            }}
          >
            Save
          </Button>
          <Button
            variant="contained"
            style={{ marginTop: '10px' }}
            onClick={() => {
              handleCloseAddEngagementPolicyModal();
            }}
          >
            Close
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default AddEngagementPolicyModal;
