import React from 'react';
import { Box, Drawer, Divider, List, Typography } from '@mui/material';
import BarChartIcon from '@mui/icons-material/BarChart';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import WorkIcon from '@mui/icons-material/Work';
import HandshakeIcon from '@mui/icons-material/Handshake';
import PunchClockIcon from '@mui/icons-material/PunchClock';
import AirlineSeatFlatIcon from '@mui/icons-material/AirlineSeatFlat';
import LockPersonIcon from '@mui/icons-material/LockPerson';
import ArticleIcon from '@mui/icons-material/Article';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import PsychologyAltIcon from '@mui/icons-material/PsychologyAlt';
import PersonPinIcon from '@mui/icons-material/PersonPin';
import BusinessIcon from '@mui/icons-material/Business';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import { SidebarButton } from './SidebarButton';
import { useDataLayerValue } from '../../contextAPI/DataLayer';
import SchemaIcon from '@mui/icons-material/Schema';
import { useTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import HandymanIcon from '@mui/icons-material/Handyman';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import PolicyIcon from '@mui/icons-material/Policy';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { SidebarButtonHeader } from './SidebarButtonHeader';
import SettingsIcon from '@mui/icons-material/Settings';
import GroupIcon from '@mui/icons-material/Group';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
// import AddLinkIcon from '@mui/icons-material/AddLink';

export function Sidebar({ open, handleDrawerClose }) {
  const drawerWidth = '260px';
  const [user] = useDataLayerValue();
  const theme = useTheme();

  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
          backgroundColor: 'black',
          color: 'white'
        }
      }}
      variant="persistent"
      anchor="left"
      open={open}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'end',
          alignItems: 'flex-start'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
            marginLeft: '15px',
            p: 2,
            textAlign: 'center'
          }}
        >
          <img
            src="/logo.png"
            width="25%"
            alt=""
            style={{ align: 'center', marginLeft: 'auto', marginRight: 'auto' }}
          />
          <Typography variant="h6" sx={{ align: 'center' }}>
            AumyaaERM Tool
          </Typography>
        </Box>
        <IconButton
          style={{ color: 'white', backgroundColor: 'black', outline: 'none' }}
          onClick={handleDrawerClose}
        >
          {theme.direction === 'ltr' ? (
            <ChevronLeftIcon />
          ) : (
            <ChevronRightIcon />
          )}
        </IconButton>
      </Box>

      <Divider sx={{ borderColor: '#2D3748' }} />
      <List>
        <SidebarButton
          url="/"
          icon={<BarChartIcon />}
          title="Dashboard"
          styling={{
            mx: 2,
            marginBottom: '6px',
            padding: 0,
            marginTop: '10px',
            marginLeft: 2.2
          }}
        />

        {'Client' in user.rights && user.rights.Client.VIEW_REPORT ? (
          <SidebarButton
            url="/master/clients"
            icon={<HandshakeIcon />}
            title="Clients"
            styling={{ mx: 2, marginBottom: '6px', padding: 0 }}
          />
        ) : null}

        <Accordion
          sx={{
            backgroundColor: 'black',
            color: 'white',
            marginBottom: '3px'
          }}
          disableGutters
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}
            sx={{
              padding: 0,
              paddingRight: '18px',
              height: '10px',
              marginBottom: '3px'
            }}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <SidebarButtonHeader
              icon={<BusinessCenterIcon />}
              title="Service Delivery"
            />
          </AccordionSummary>
          <AccordionDetails sx={{ padding: 0 }}>
            {'Project' in user.rights && user.rights.Project.VIEW_REPORT ? (
              <SidebarButton
                url="/master/projects"
                icon={<WorkIcon />}
                title="Projects"
                styling={{ mx: 2, marginBottom: '3px', padding: 0 }}
              />
            ) : null}
            <SidebarButton
              url="/services/engagement"
              icon={<FileCopyIcon />}
              title="Engagements"
              styling={{ mx: 2, marginBottom: '3px', padding: 0 }}
            />
          </AccordionDetails>
        </Accordion>

        <Accordion
          sx={{
            backgroundColor: 'black',
            color: 'white',
            marginBottom: '3px'
          }}
          disableGutters
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}
            sx={{
              padding: 0,
              paddingRight: '18px',
              height: '10px',
              marginBottom: '3px'
            }}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <SidebarButtonHeader
              icon={<HandymanIcon />}
              title="General Utility Tool"
            />
          </AccordionSummary>
          <AccordionDetails sx={{ padding: 0 }}>
            <SidebarButton
              url="/general-utility/excel-to-ppt"
              icon={<ChangeCircleIcon />}
              title="Excel To PPT"
              styling={{ mx: 2, marginBottom: '3px', padding: 0 }}
            />
            <SidebarButton
              url="/general-utility/pdf-to-excel"
              icon={<ChangeCircleIcon />}
              title="PDF To Excel"
              styling={{ mx: 2, marginBottom: '3px', padding: 0 }}
            />
          </AccordionDetails>
        </Accordion>

        <Accordion
          sx={{
            backgroundColor: 'black',
            color: 'white',
            marginBottom: '3px'
          }}
          disableGutters
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}
            sx={{
              padding: 0,
              paddingRight: '18px',
              height: '10px',
              marginBottom: '3px'
            }}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <SidebarButtonHeader icon={<PolicyIcon />} title="Policy" />
          </AccordionSummary>
          <AccordionDetails sx={{ padding: 0 }}>
            <SidebarButton
              url="/policy"
              icon={<ContentPasteIcon />}
              title="Templates"
              styling={{ mx: 2, marginBottom: '3px', padding: 0 }}
            />
            <SidebarButton
              url="/policy/engagement"
              icon={<ArticleIcon />}
              title="Policies"
              styling={{ mx: 2, marginBottom: '3px', padding: 0 }}
            />
            {/* {'Employee' in user.rights && user.rights.Employee.VIEW_REPORT ? (
              <SidebarButton
                url="/scf"
                icon={<AddLinkIcon />}
                title="SCF"
                styling={{ mx: 2, marginBottom: '3px', padding: 0 }}
              />
            ) : null} */}
          </AccordionDetails>
        </Accordion>

        <Accordion
          sx={{
            backgroundColor: 'black',
            color: 'white',
            marginBottom: '3px'
          }}
          disableGutters
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}
            sx={{
              padding: 0,
              paddingRight: '18px',
              height: '10px',
              marginBottom: '3px'
            }}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <SidebarButtonHeader icon={<GroupIcon />} title="HR" />
          </AccordionSummary>
          <AccordionDetails sx={{ padding: 0 }}>
            {'Employee' in user.rights && user.rights.Employee.VIEW_REPORT ? (
              <SidebarButton
                url="/master/employees"
                icon={<PeopleAltIcon />}
                title="Employees"
                styling={{ mx: 2, marginBottom: '3px', padding: 0 }}
              />
            ) : null}
              {user.HRAdmin ? (
          <SidebarButton
            url="/master/offerLetter"
            icon={<ReceiptLongIcon />}
            title="Offer Letter"
            styling={{ mx: 2, marginBottom: '3px', padding: 0 }}
          />
        ) : null}
            <SidebarButton
              url="/master/timesheets"
              icon={<PunchClockIcon />}
              title="Timesheets"
              styling={{ mx: 2, marginBottom: '3px', padding: 0 }}
            />
            <SidebarButton
              url="/master/holidays"
              icon={<AirlineSeatFlatIcon />}
              title="Holidays"
              styling={{ mx: 2, marginBottom: '3px', padding: 0 }}
            />
            {'Department' in user.rights &&
            user.rights.Department.VIEW_REPORT ? (
              <SidebarButton
                url="/master/departments"
                icon={<BusinessIcon />}
                title="Departments"
                styling={{ mx: 2, marginBottom: '0px', padding: 0 }}
              />
            ) : null}
          </AccordionDetails>
        </Accordion>

        <Accordion
          sx={{
            backgroundColor: 'black',
            color: 'white',
            marginBottom: '3px'
          }}
          disableGutters
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}
            sx={{
              padding: 0,
              paddingRight: '18px',
              height: '10px',
              marginBottom: '3px'
            }}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <SidebarButtonHeader icon={<SettingsIcon />} title="Settings" />
          </AccordionSummary>
          <AccordionDetails sx={{ padding: 0 }}>
            {'WorkflowType' in user.rights &&
            user.rights.WorkflowType.VIEW_REPORT ? (
              <SidebarButton
                url="/templateEditor"
                icon={<ModeEditIcon />}
                title="Template Editor"
                styling={{ mx: 2, marginBottom: '3px', padding: 0 }}
              />
            ) : null}
            {'WorkflowType' in user.rights &&
            user.rights.WorkflowType.VIEW_REPORT ? (
              <SidebarButton
                url="/fieldAndServiceCreator"
                icon={<AddCircleOutlineIcon />}
                title="Field Refrences And Services"
                styling={{ mx: 2, marginBottom: '3px', padding: 0 }}
              />
            ) : null}
            {'Log' in user.rights && user.rights.Log.VIEW_REPORT ? (
              <SidebarButton
                url="/master/logs"
                icon={<ArticleIcon />}
                title="Log Reports"
                styling={{ mx: 2, marginBottom: '3px', padding: 0 }}
              />
            ) : null}
            {'Role' in user.rights && user.rights.Role.VIEW_REPORT ? (
              <SidebarButton
                url="/master/roles"
                icon={<PersonPinIcon />}
                title="Roles"
                styling={{ mx: 2, marginBottom: '3px', padding: 0 }}
              />
            ) : null}
            {'ProfileRight' in user.rights &&
            user.rights.ProfileRight.VIEW_REPORT ? (
              <SidebarButton
                url="/master/profileRights"
                icon={<PsychologyAltIcon />}
                title="Profile Rights"
                styling={{ mx: 2, marginBottom: '3px', padding: 0 }}
              />
            ) : null}
            {'BroadRole' in user.rights && user.rights.BroadRole.VIEW_REPORT ? (
              <SidebarButton
                url="/master/broadRoles"
                icon={<ManageAccountsIcon />}
                title="Broad Roles"
                styling={{ mx: 2, marginBottom: '3px', padding: 0 }}
              />
            ) : null}
            {'WorkflowType' in user.rights &&
            user.rights.WorkflowType.VIEW_REPORT ? (
              <SidebarButton
                url="/workflow/type"
                icon={<SchemaIcon />}
                title="Workflow Type"
                styling={{ mx: 2, marginBottom: '3px', padding: 0 }}
              />
            ) : null}
            {'WorkflowType' in user.rights &&
            user.rights.WorkflowType.VIEW_REPORT ? (
              <SidebarButton
                url="/workflow/handlers"
                icon={<SchemaIcon />}
                title="Workflow Handlers"
                styling={{ mx: 2, marginBottom: '3px', padding: 0 }}
              />
            ) : null}
            <SidebarButton
              url="/password/update"
              icon={<LockPersonIcon />}
              title="Change Password"
              styling={{ mx: 2, marginBottom: '50px', padding: 0 }}
            />
          </AccordionDetails>
        </Accordion>
      </List>
    </Drawer>
  );
}
