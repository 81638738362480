import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useState } from 'react';
import { Form } from '../../Form';

export const HeaderWithCreateModal = ({
  title,
  options,
  creationFields,
  reqUrl,
  reqHeaders
}) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
          m: -1
        }}
      >
        <Typography sx={{ m: 1, fontWeight: 'bold' }} variant="h4">
          {title}
        </Typography>
        {options.createOption ? (
          <>
            <Button
              variant="contained"
              onClick={handleOpen}
              sx={{ color: 'white', backgroundColor: 'black' }}
            >
              Add {title}
            </Button>
            <Form
              title={`Fields marked with (*) are required`}
              url={reqUrl}
              open={open}
              handleClose={handleClose}
              fields={creationFields}
              type="post"
              headers={reqHeaders}
            />
          </>
        ) : null}
      </Box>
    </Box>
  );
};
