import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Modal,
  Typography,
  FormControl,
  InputLabel,
  NativeSelect,
  InputBase
} from '@mui/material';
import {
  getAllSCFGovernance,
  getEngagementPolicyById,
  getSCFGovernanceById,
  updateEngagementPolicyById_Table
} from '../../../constants/requests';
import { useLoading } from '../../../hooks/useLoading';
import { useNotifications } from '../../../hooks/useNotifications';
import { useParams } from 'react-router-dom';
import TemplateTable from '../../TemplateEditor/common/TemplateTable';
import { styled } from '@mui/material/styles';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 680,
  bgcolor: 'white',
  boxShadow: 24,
  borderRadius: '10px',
  p: 4
};

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(2)
  },
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)'
    }
  }
}));

const AddGovernancesModal = ({
  openAddGovernancesModal,
  handleCloseAddGovernancesModal,
  getPolicyDetailsForGovernance
}) => {
  //crud operations pure ekdum
  const { request } = useLoading();
  const { showNotifications } = useNotifications();
  const params = useParams();

  const [standardId, setStandardId] = useState();
  const [allGovernances, setAllGovernances] = useState([]);
  const [policyData, setPolicyData] = useState();

  const [policyGovernancesData, setPolicyGovernancesData] = useState([]);

  const getPolicyHandler = async (allGovernancesData) => {
    try {
      const response = await request(() =>
        getEngagementPolicyById(params.policyid)
      );
      if (response?.data?.statusCode === 200) {
        setPolicyData(response.data.body);
        const governanceIDs = response.data.body.SCF.governanceIDs;
        const policyStandardsList = allGovernancesData.filter((obj) =>
          governanceIDs.includes(obj._id)
        );

        const newPolicyStandardsList = policyStandardsList.map(
          (policyStandard) => {
            return {
              name: policyStandard.name,
              type: policyStandard.type,
              _id: policyStandard._id,
              Action: 'Button'
            };
          }
        );
        setPolicyGovernancesData(newPolicyStandardsList);
      } else {
        if (response.response.data.statusCode !== 200) {
          showNotifications('error', response.response.data.message, 5000);
        }
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  const handleAddStandard = async () => {
    try {
      const response = await request(() => getSCFGovernanceById(standardId));
      if (response?.data?.statusCode === 200) {
        const newStandard = {
          _id: response.data.body._id,
          name: response.data.body.name,
          type: response.data.body.type,
          Action: 'Button'
        };
        setPolicyGovernancesData([...policyGovernancesData, newStandard]);
        showNotifications('success', response.data.message, 5000);
      } else {
        if (response.response.data.statusCode !== 200) {
          showNotifications('error', response.response.data.message, 5000);
        }
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  const handleDeleteGovernances = async (idx) => {
    const newPolicyGovernancesData = policyGovernancesData.filter(
      (_, standardIdx) => idx !== standardIdx
    );
    setPolicyGovernancesData(newPolicyGovernancesData);
  };

  const handleSave = async () => {
    try {
      const allGovernanceIds = policyGovernancesData.map(
        (standard) => standard._id
      );
      const scfData = policyData.SCF;
      scfData.governanceIDs = allGovernanceIds;
      const updatedClientData = {
        SCF: scfData
      };
      const response = await request(() =>
        updateEngagementPolicyById_Table(params.policyid, updatedClientData)
      );
      getPolicyDetailsForGovernance();
      if (response?.data?.statusCode === 200) {
        getPolicyHandler(allGovernances);
        showNotifications('success', 'Save successful', 5000);
      } else {
        if (response.response.data.statusCode !== 200) {
          showNotifications('error', response.response.data.message, 5000);
        }
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  const getAllSCFGovernanceHandler = async () => {
    try {
      const response = await request(() => getAllSCFGovernance());
      if (response?.data?.statusCode === 200) {
        setAllGovernances(response.data.body);
        getPolicyHandler(response.data.body);
      } else {
        if (response.response.data.statusCode !== 200) {
          showNotifications('error', response.response.data.message, 5000);
        }
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  useEffect(() => {
    getAllSCFGovernanceHandler();
    policyGovernancesData;
  }, []);

  return (
    <Modal
      open={openAddGovernancesModal}
      onClose={handleCloseAddGovernancesModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography
          id="modal-modal-title"
          variant="h5"
          component="h2"
          sx={{ textAlign: 'center', marginBottom: 3, fontWeight: 600 }}
        >
          Add Governances
        </Typography>
        <Box
          style={{
            width: '100%',
            alignItems: 'center',
            gap: '10px',
            marginBottom: '13px',
            display: 'flex'
          }}
        >
          <FormControl fullWidth variant="standard">
            <InputLabel
              variant="standard"
              shrink={true}
              htmlFor="uncontrolled-native"
            >
              Select Governances
            </InputLabel>
            <NativeSelect
              value={standardId}
              input={<BootstrapInput />}
              onChange={(e) => {
                setStandardId(e.target.value);
              }}
              inputProps={{
                name: 'standardId',
                id: 'uncontrolled-native',
                shrink: true
              }}
            >
              {allGovernances.length > 0 &&
                allGovernances.map((Governances) => (
                  <option key={Governances._id} value={Governances._id}>
                    {Governances.name}
                  </option>
                ))}
            </NativeSelect>
          </FormControl>
          <Button
            variant="contained"
            sx={{
              marginTop: 2,
              marginRight: 2,
              height: '46px',
              color: 'white',
              background: 'black'
            }}
            onClick={handleAddStandard}
          >
            Add
          </Button>
        </Box>
        <Box sx={{ marginTop: '25px', minHeight: '300px' }}>
          {policyGovernancesData.length > 0 ? (
            <TemplateTable
              data={policyGovernancesData}
              deleteCB={handleDeleteGovernances}
              widthStyle={{ minWidth: '200px' }}
            />
          ) : (
            <Box style={{ display: 'flex', justifyContent: 'center' }}>
              <Typography
                sx={{ fontWeight: 'bold', color: 'Red' }}
                variant="h5"
              >
                No Governances Linked To This Policy
              </Typography>
            </Box>
          )}
        </Box>
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '10px'
          }}
        >
          <Button
            variant="contained"
            style={{ marginTop: '10px' }}
            onClick={() => {
              handleSave();
            }}
          >
            Save
          </Button>
          <Button
            variant="contained"
            style={{ marginTop: '10px' }}
            onClick={() => {
              handleCloseAddGovernancesModal();
            }}
          >
            Close
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default AddGovernancesModal;
