export const fields = [
  {
    key: 'Department',
    val: 'department'
  },
  {
    key: 'Role',
    val: 'role'
  },
  {
    key: 'Current Status',
    val: 'status'
  },
  {
    key: 'View Details',
    val: 'activeWorkflowId'
  },
  {
    key: 'Available Actions',
    val: 'actions'
  }
];
