import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Typography } from '@mui/material';
import { EmployeeView } from '../../../components/TimesheetViews/EmployeeView';
import { ManagerView } from '../../../components/TimesheetViews/ManagerView';
import { useLoading } from '../../../hooks/useLoading';
import { htmlToCSV } from '../../../utils/convertToCsv';
import { useDataLayerValue } from '../../../contextAPI/DataLayer';
import DownloadIcon from '@mui/icons-material/Download';
import {
  getTimesheetApprovals,
  getTimesheetsRequestByEmployee,
  bulkApproveTimesheets,
  getHolidays
} from '../../../constants/requests';
import PropTypes from 'prop-types';
import { MasterHeader } from '../../../components/Master/Header';
import { MasterTable } from '../../../components/Master/Table';
import { headers } from '../../../constants/ApprovalHeaders';
import { GET_TIMESHEET_URL } from '../../../constants/urls';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Box,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Tabs,
  Tab
} from '@mui/material';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

export const Timesheet = ({ createTimeSheetVisiblity }) => {
  const [empData, setEmpData] = useState([]);
  const [managerData, setManagerData] = useState([]);
  const [excludedDates, setExcludedDates] = useState([]);
  const [approvalDate, setApprovalDate] = useState({ body: [] });
  const { request } = useLoading();
  const [user, dispatch] = useDataLayerValue(); // eslint-disable-line
  const email = user.email;
  // console.log({ user });
  const { projectName } = useParams();
  const [fromDate, setStartDate] = useState();
  const [toDate, setEndDate] = useState();
  const initDates = async () => {
    const fromDate = new Date(),
      toDate = new Date();
    toDate.setDate(toDate.getDate() + (7 - toDate.getDay()));
    fromDate.setDate(fromDate.getDate() - fromDate.getDay() + 1);
    setStartDate(fromDate);
    setEndDate(toDate);
    const res = await request(getHolidays);
    const excludedDates = [];
    // const dateArray=[];
    for (const date of res.data.body) {
      for (const day of date.days) {
        excludedDates.push(new Date(date.year, date.month - 1, day));
      }
    }
    setExcludedDates([...excludedDates]);
  };
  const sampleFields = [
    {
      label: 'Name',
      name: 'name',
      display: 'required',
      type: 'text'
    },
    {
      label: 'Phone',
      name: 'phone',
      display: 'required',
      type: 'number'
    }
  ];
  const getEmpData = async () => {
    try {
      // console.log({ projectName });

      const data = {
        projectName,
        employeeEmail: email,
        isApproved: { $gte: 0 }
      };
      const response = await request(() =>
        getTimesheetsRequestByEmployee(data)
      );
      // console.log(response);
      if (response.status === 200) {
        // console.log(response.data.body);
        setEmpData([...response.data.body]);
      } else {
        console.log('some error happened');
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getManagerData = async () => {
    try {
      // console.log({ projectName });

      const data = {
        projectName,
        managerEmail: email
      };
      const response = await request(() =>
        getTimesheetsRequestByEmployee(data)
      );
      // console.log(response);
      if (response.status === 200) {
        // console.log(response.data.body);
        setManagerData([...response.data.body]);
      } else {
        console.log('some error happened');
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getApprovalData = async () => {
    try {
      const response = await request(() => getTimesheetApprovals(user._id));
      // console.log({ response });
      if (response.status === 200) {
        // console.log('response', response.data);
        setApprovalDate(response.data);
      } else {
        console.log('some error happened');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const exportToCsv = (id) => {
    htmlToCSV(id, id + '.csv');
  };

  useEffect(() => {
    document.title = `Timesheets`;
    getEmpData();
    getManagerData();
    getApprovalData();
  }, []);

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const findHeaders = (headers, data) => {
    // console.log({ data });
    if (data.body.length === 0) return [];
    const currHeaders = [];
    for (let i = 0; i < headers.length; i++) {
      if (headers[i].key in data.body[0]) currHeaders.push(headers[i]);
    }
    return currHeaders;
  };

  const currHeaders = findHeaders(headers, approvalDate);
  const csvReport = {
    data: approvalDate.body,
    headers: currHeaders,
    filename: 'PendingTimesheets.csv'
  };

  const [updated, setUpdated] = useState(false); // eslint-disable-line

  const toggleUpdate = () => {
    console.log('Toggling');
    setUpdated((prevUpdated) => !prevUpdated);
  };

  const bulkApprove = async () => {
    let result = approvalDate.body.map((a) => {
      return a._id;
    });
    const bulkApproveObj = {
      timesheetIds: result,
      managerName: user.fname + ' ' + user.lname,
      managerEmail: user.email
    };
    // console.log(bulkApproveObj);
    try {
      const response = await request(() =>
        bulkApproveTimesheets(bulkApproveObj)
      );
      // console.log(response);
      if (response.data.statusCode === 200) {
        console.log('Bulk Approved');
        window.location.reload();
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    initDates();
  }, []);

  useEffect(() => {
    getEmpData();
    getManagerData();
  }, [fromDate, toDate]);

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: '10px',
          marginTop: '10px',
          marginBottom: '10px'
        }}
      >
        <Typography
          sx={{ m: 1, fontWeight: 'bold', mb: 1, align: 'center' }}
          variant="h4"
        >
          Timesheets
        </Typography>

        {createTimeSheetVisiblity === true ? (
          <Link to="add">
            <Button variant="contained">Create Timesheet</Button>
          </Link>
        ) : null}
      </div>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Your Timesheets</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="Employee View" {...a11yProps(0)} />
              <Tab label="Manager View" {...a11yProps(1)} />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <div className="flex" style={{ display: 'flex', gridGap: '1rem' }}>
              <Button
                onClick={() => {
                  exportToCsv('employeeTable');
                }}
                startIcon={<DownloadIcon />}
                sx={{ mr: 1, color: 'black' }}
              >
                Export
              </Button>
              <span style={{ paddingRight: '10px' }}>
                <span>Pick Start Date: </span>
                <input
                  type="date"
                  onChange={(e) => {
                    setStartDate(e.target.value);
                  }}
                  name="startDate"
                  id="startDate"
                />
              </span>
              <span style={{ paddingRight: '10px' }}>
                <span>Pick End Date: </span>
                <input
                  type="date"
                  onChange={(e) => {
                    setEndDate(e.target.value);
                  }}
                  name="endDate"
                  id="endDate"
                />
              </span>
            </div>
            <EmployeeView
              data={empData}
              fromDate={new Date(fromDate)}
              toDate={new Date(toDate)}
              excludedDates={excludedDates}
            ></EmployeeView>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <div className="flex" style={{ display: 'flex', gridGap: '1rem' }}>
              <Button
                onClick={() => {
                  exportToCsv('managerTable');
                }}
                startIcon={<DownloadIcon />}
                sx={{ mr: 1, color: 'black' }}
              >
                Export
              </Button>
              <span style={{ paddingRight: '10px' }}>
                <span>Pick Start Date: </span>
                <input
                  type="date"
                  onChange={(e) => {
                    setStartDate(e.target.value);
                  }}
                  name="startDate"
                  id="startDate"
                />
              </span>
              <span style={{ paddingRight: '10px' }}>
                <span>Pick End Date: </span>
                <input
                  type="date"
                  onChange={(e) => {
                    setEndDate(e.target.value);
                  }}
                  name="endDate"
                  id="endDate"
                />
              </span>
            </div>

            <ManagerView
              data={managerData}
              fromDate={new Date(fromDate)}
              toDate={new Date(toDate)}
              excludedDates={excludedDates}
            ></ManagerView>
          </TabPanel>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>Pending Approvals</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <MasterHeader
            title=""
            options={{
              importOption: false,
              exportOption: true,
              createOption: false,
              bulkApproveOption: true
            }}
            toggleUpdate={toggleUpdate}
            bulkApprove={bulkApprove}
            arrsize={approvalDate.body.length}
            csvReport={csvReport}
            links={{}}
          />
          <MasterTable
            headers={headers}
            data={approvalDate}
            toggleUpdate={false}
            csvReport={csvReport}
            fields={sampleFields}
            url={GET_TIMESHEET_URL}
            options={{
              editOption: false,
              deleteOption: false,
              rightsOption: false,
              approveOption: true,
              declineOption: true
            }}
          />
        </AccordionDetails>
      </Accordion>
    </>
  );
};
