import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { useDataLayerValue } from '../../contextAPI/DataLayer';
import { BACKEND_URL } from '../../config';
import { Link } from 'react-router-dom';
function WorkflowHandlerIndividual() {
  const { id } = useParams();
  const [details, setDetails] = useState({});
  const [endPoint, setEndPoint] = useState({});
  const [data, setData] = useState({});
  const [activeWorkflows, setActiveWorkflows] = useState([]);
  const [{ _id }] = useDataLayerValue();
  useEffect(() => {
    document.title = 'Workflow Handler Information';
    axios
      .get(BACKEND_URL + '/workflow-handler/' + id, {
        headers: {
          employeeid: _id
        }
      })
      .then((res) => {
        console.log(res.data.body);
        setDetails(res.data.body);
        setEndPoint(res.data.body.endPoint);
        setData(res.data.body.data);
      })
      .catch((er) => console.log(er));

    axios
      .get(BACKEND_URL + '/active-workflow/workflow-handler/' + id, {
        headers: {
          employeeid: _id
        }
      })
      .then((res) => {
        console.log(res.data.body);
        setActiveWorkflows(res.data.body);
      })
      .catch((er) => console.log(er));
  }, []);

  const stylesObj = {
    container: {
      backgroundColor: 'whitesmoke',
      boxShadow: '2px 2px 10px grey',
      marginTop: '30px',
      padding: '60px 90px'
    },
    keyval: {
      display: 'flex'
    },
    key: {
      marginRight: '10px',
      fontWeight: 'bolder'
    },
    parts: {
      marginLeft: '40px'
    },
    parts2: {
      marginLeft: '70px'
    },
    partss: {
      marginLeft: '20px'
    }
  };

  return (
    <div>
      <h3>Workflow Handler Information</h3>
      <div style={stylesObj.container}>
        <span style={stylesObj.keyval}>
          <p style={stylesObj.key}>Handler ID:</p>
          <p>{details._id}</p>
        </span>
        <span style={stylesObj.keyval}>
          <p style={stylesObj.key}>Current Status:</p>
          <p>{details.status}</p>
        </span>
        <br />
        <>
          <span style={stylesObj.keyval}>
            <p style={stylesObj.key}>
              <li>ENDPOINT</li>
            </p>
          </span>
          <br />
          <div style={stylesObj.parts}>
            <span style={stylesObj.keyval}>
              <p style={stylesObj.key}>URL: </p>
              <p>{endPoint.url}</p>
            </span>
            <span style={stylesObj.keyval}>
              <p style={stylesObj.key}>METHOD: </p>
              <p>{endPoint.method}</p>
            </span>
          </div>
        </>
        <br />
        <>
          <span style={stylesObj.keyval}>
            <p style={stylesObj.key}>
              <li>DATA</li>
            </p>
          </span>
          <br />
          <div style={stylesObj.parts}>
            <span style={stylesObj.keyval}>
              <p style={stylesObj.key}>Request URL: </p>
              <p>{data.RequestUrl}</p>
            </span>
            <span style={stylesObj.keyval}>
              <p style={stylesObj.key}>METHOD: </p>
              <p>{data.method}</p>
            </span>
            <span style={stylesObj.keyval}>
              <p style={stylesObj.key}>Employee ID of the Initiator:</p>
              <p>{data.initiator}</p>
            </span>
            <p style={stylesObj.key}>Request Body:</p>
            <pre>{JSON.stringify(data.RequestBody, null, '\t')}</pre>
          </div>
        </>
        <>
          <span style={stylesObj.keyval}>
            <p style={stylesObj.key}>
              <li>ACTIVE WORKFLOWS ASSOCIATED WITH THIS HANDLER</li>
            </p>
          </span>
          {activeWorkflows.map((flow, idx) => {
            return (
              <React.Fragment key={idx}>
                <div style={stylesObj.parts}>{`Active Workflow ${
                  idx + 1
                }: `}</div>
                <br />
                <span style={stylesObj.keyval}>
                  <div style={stylesObj.parts2}>
                    <b>Workflow ID:</b>
                  </div>
                  <div style={stylesObj.partss}>
                    <Link to={'/workflow/approvals/' + flow._id}>
                      {flow._id}
                    </Link>
                  </div>
                </span>
                <span style={stylesObj.keyval}>
                  <div style={stylesObj.parts2}>
                    <b>Current Level:</b>
                  </div>
                  <div style={stylesObj.partss}>{flow.currApprover + 1}</div>
                </span>
                <span style={stylesObj.keyval}>
                  <div style={stylesObj.parts2}>
                    <b>Status:</b>
                  </div>
                  <div style={stylesObj.partss}>{flow.status}</div>
                </span>
              </React.Fragment>
            );
          })}
        </>
      </div>
    </div>
  );
}

export default WorkflowHandlerIndividual;
