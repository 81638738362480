import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Modal,
  Table,
  TableCell,
  tableCellClasses,
  TableHead,
  TableRow,
  TableBody,
  TableContainer,
  Typography
} from '@mui/material';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useLoading } from '../../../hooks/useLoading';
import { useNotifications } from '../../../hooks/useNotifications';
import {
  getAssignedProjectsByEmployeeId,
  getEngagementsRequest,
  deleteEngagementRequest,
  createEngagementRequest,
  getEngagementByIdRequest,
  updateEngagementRequest,
  getEmployeesRequest,
  getServicesRequest
} from '../../../constants/requests';
import DeleteDialog from '../common/DeleteDialog';
import CreateEditDialog from './CreateEditDialog';
import { useDataLayerValue } from '../../../contextAPI/DataLayer';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14
  }
}));

const StyledTableRow = styled(TableRow)(() => ({
  // '&:nth-of-type(odd)': {
  // 	backgroundColor: theme.palette.action.hover,
  // },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0
  }
}));

const tableHeaders = [
  'Project Name',
  'Aumyaa SPOC Name 1',
  'Aumyaa SPOC Email 1',
  'Aumyaa SPOC Name 2',
  'Aumyaa SPOC Email 2',
  'Service Type',
  'Client SPOC Name',
  'Client SPOC Email'
];

export default function Engagements() {
  const navigate = useNavigate();
  const { request } = useLoading();
  const { showNotifications } = useNotifications();
  const [{ _id }] = useDataLayerValue();

  const [engagements, setEngagements] = useState([]);
  const [selectedEngagementId, setSelectedEngagementId] = useState('');

  const [projectNames, setProjectNames] = useState([]);
  const [serviceNames, setServiceNames] = useState([]);

  const [openAddModal, setOpenAddModal] = React.useState(false);
  const handleOpenAddModal = () => setOpenAddModal(true);
  const handleCloseAddModal = () => setOpenAddModal(false);

  const [openEditModal, setOpenEditModal] = React.useState(false);
  const handleOpenEditModal = () => setOpenEditModal(true);
  const handleCloseEditModal = () => setOpenEditModal(false);

  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const handleOpenDeleteModal = () => setOpenDeleteModal(true);
  const handleCloseDeleteModal = () => setOpenDeleteModal(false);

  const getProjectsAssignedToEmployee = async () => {
    try {
      const response = await request(() =>
        getAssignedProjectsByEmployeeId(_id)
      );
      if (response.data.statusCode === 200) {
        console.log(response.data);
        const projectNames = response.data.body.map((project) => {
          return {
            label: project.projectName,
            value: project._id
          };
        });
        setProjectNames(projectNames);
      } else {
        showNotifications('error', response.data.message, 5000);
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
      console.log(err);
    }
  };

  const getEngagements = async () => {
    try {
      // Get all projects
      const servicesResponse = await request(() => getServicesRequest());

      const serviceNames = servicesResponse.data.body.map((service) => {
        return {
          label: service.name,
          value: service._id
        };
      });

      const serviceIdToName = {};
      servicesResponse.data.body.forEach(
        (service) => (serviceIdToName[service._id] = service.name)
      );
      setServiceNames(serviceNames);

      // Get employees
      const employeeResponse = await request(() => getEmployeesRequest());
      const employeeIDToName = {};
      employeeResponse.data.body.forEach((employee) => {
        employeeIDToName[employee._id] = `${employee.fname} ${employee.lname}`;
      });

      // Get all engagements
      const response = await request(() => getEngagementsRequest(_id));
      if (response.data.statusCode === 200) {
        const engagements = response.data.body.map((engagement) => {
          const {
            _id,
            projectId,
            aumyaaSPOCID1,
            aumyaaSPOCEmail1,
            aumyaaSPOCID2,
            aumyaaSPOCEmail2,
            serviceId,
            clientSPOCName,
            clientSPOCEmail
          } = engagement;

          return {
            _id,
            projectName: projectId.projectName,
            aumyaaSPOCName1: employeeIDToName[aumyaaSPOCID1],
            aumyaaSPOCEmail1,
            aumyaaSPOCID2: employeeIDToName[aumyaaSPOCID2],
            aumyaaSPOCEmail2,
            serviceType: serviceIdToName[serviceId],
            clientSPOCName,
            clientSPOCEmail
          };
        });
        setEngagements(engagements);
      } else {
        showNotifications('error', response.data.message, 5000);
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
      console.log(err);
    }
  };

  useEffect(() => {
    getEngagements();
    getProjectsAssignedToEmployee();
  }, []);

  const createEditFormData = [
    {
      type: 'dropDown',
      fieldName: 'projectId',
      fieldLabel: 'Project Name',
      dropDownOptions: projectNames,
      value: '',
      required: true
    },
    {
      type: 'dropDown',
      fieldName: 'aumyaaSPOCID1',
      fieldLabel: 'Aumyaa SPOC Name 1',
      dropDownOptions: [],
      value: '',
      required: true
    },
    {
      type: 'textField',
      fieldName: 'aumyaaSPOCEmail1',
      fieldLabel: 'Aumyaa SPOC Email 1',
      value: '',
      required: true
    },
    {
      type: 'dropDown',
      fieldName: 'aumyaaSPOCID2',
      fieldLabel: 'Aumyaa SPOC Name 2',
      dropDownOptions: [],
      value: '',
      required: false
    },
    {
      type: 'textField',
      fieldName: 'aumyaaSPOCEmail2',
      fieldLabel: 'Aumyaa SPOC Email 2',
      value: '',
      required: false
    },
    {
      type: 'dropDown',
      fieldName: 'serviceId',
      fieldLabel: 'Service Type',
      dropDownOptions: serviceNames,
      value: '',
      required: true
    },
    {
      type: 'textField',
      fieldName: 'clientSPOCName',
      fieldLabel: 'Client SPOC Name',
      value: '',
      required: false
    },
    {
      type: 'textField',
      fieldName: 'clientSPOCEmail',
      fieldLabel: 'Client SPOC Email',
      value: '',
      required: false
    }
  ];

  return (
    <>
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '30px'
        }}
      >
        <Typography sx={{ fontWeight: 'bold' }} variant="h4">
          Engagements
        </Typography>
        <Button
          sx={{ color: 'white', backgroundColor: 'black' }}
          variant="contained"
          onClick={handleOpenAddModal}
        >
          Add Engagement
        </Button>
      </Box>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              {tableHeaders.map((tableHeader, idx) => {
                return (
                  <StyledTableCell
                    key={`engagement-header-${idx}`}
                    align="right"
                  >
                    {tableHeader}
                  </StyledTableCell>
                );
              })}
              <StyledTableCell align="center">Options</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {React.Children.toArray(
              engagements.map((engagement) => {
                return (
                  <StyledTableRow hover style={{ cursor: 'pointer' }}>
                    {Object.entries(engagement).map(([key, value]) => {
                      if (key === '_id') return <></>; // skip adding _id to the table
                      return (
                        <StyledTableCell
                          onClick={() =>
                            navigate(
                              `/services/engagement/${engagement['_id']}/understanding`
                            )
                          }
                          align="right"
                        >
                          {value}
                        </StyledTableCell>
                      );
                    })}
                    <StyledTableCell align="center">
                      {
                        <Box
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            gap: '5px'
                          }}
                        >
                          <EditIcon
                            onClick={() => {
                              handleOpenEditModal();
                              setSelectedEngagementId(engagement['_id']);
                            }}
                          />
                          <DeleteIcon
                            onClick={() => {
                              handleOpenDeleteModal();
                              setSelectedEngagementId(engagement['_id']);
                            }}
                          />
                        </Box>
                      }
                    </StyledTableCell>
                  </StyledTableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Create Engagement Modal */}
      <Modal
        open={openAddModal}
        onClose={handleCloseAddModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <CreateEditDialog
          formData={createEditFormData}
          onCloseCB={handleCloseAddModal}
          refreshPageContentCB={getEngagements}
          entityName="Engagement"
          createEntityRequest={createEngagementRequest}
        />
      </Modal>

      {/* Update Engagement Modal */}
      <Modal
        open={openEditModal}
        onClose={handleCloseEditModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <CreateEditDialog
          formData={createEditFormData}
          onCloseCB={handleCloseEditModal}
          refreshPageContentCB={getEngagements}
          entityId={selectedEngagementId}
          entityName="Engagement"
          getEntityByIdRequest={getEngagementByIdRequest}
          updateEntityRequest={updateEngagementRequest}
        />
      </Modal>
      {/* Delete Engagement Modal */}
      <Modal
        open={openDeleteModal}
        onClose={handleCloseDeleteModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <DeleteDialog
          entityName="Engagement"
          entityId={selectedEngagementId}
          deleteRequest={deleteEngagementRequest}
          onCloseCB={handleCloseDeleteModal}
          refreshPageContentCB={getEngagements}
        />
      </Modal>
    </>
  );
}
