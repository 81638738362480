import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  ProjectAllHeaders,
  ProjectNonMonetaryHeaders
} from '../../../constants/ProjectMasterHeaders';
import { useLoading } from '../../../hooks/useLoading';
import {
  getProjectsRequest,
  getClientsRequest
} from '../../../constants/requests';
import { useDataLayerValue } from '../../../contextAPI/DataLayer';
import { ProjectHeader } from './ProjectHeader';
import { ProjectTable } from './ProjectTable';
import { GET_PROJECT_URL } from '../../../constants/urls';
import { BACKEND_URL } from '../../../config';
import { ADMINISTRATOR_ROLE_ID } from '../../../constants/AdministratorConstants';

const projectEditFields = [
  {
    label: 'Zoho Link ID',
    name: 'zohoLinkId',
    display: 'optional',
    type: 'url'
  },
  {
    label: 'Risk*',
    name: 'risk',
    display: 'required',
    type: 'dropdown',
    value: [
      {
        id: 'Low',
        name: 'Low'
      },
      {
        id: 'Medium',
        name: 'Medium'
      },
      {
        id: 'High',
        name: 'High'
      }
    ],
    info: ['name', 'id']
  },
  {
    label: 'Description',
    name: 'description',
    display: 'required',
    type: 'text'
  },
  {
    label: 'Ongoing',
    name: 'isActive',
    display: 'required',
    type: 'dropdown',
    value: [
      {
        name: 'Yes',
        id: true
      },
      {
        name: 'No',
        id: false
      }
    ],
    info: ['name', 'id']
  },
  {
    label: 'Start Date*',
    name: 'startDate',
    display: 'required',
    type: 'date',
    value: new Date().toDateString()
  },
  {
    label: 'End Date*',
    name: 'endDate',
    display: 'required',
    type: 'date',
    value: new Date().toDateString()
  },
  {
    label: 'Currency Code*',
    name: 'currencyCode',
    display: 'required',
    type: 'text',
    value: 'INR'
  },
  {
    label: 'Project Cost*',
    name: 'projectCost',
    display: 'required',
    type: 'Number'
  },
  {
    label: 'Budget Amount*',
    name: 'budgetAmount',
    display: 'required',
    type: 'Number'
  },
  {
    label: 'Cost Budget',
    name: 'costBudget',
    display: 'optional',
    type: 'Number'
  },
  {
    label: 'Budget Hours*',
    name: 'budgetHours',
    display: 'required',
    type: 'Number'
  },
  {
    label: 'Billing Type',
    name: 'billingType',
    display: 'optional',
    type: 'text'
  },
  {
    label: 'Budget Type',
    name: 'budgetType',
    display: 'optional',
    type: 'text'
  }
];

export const MasterProject = () => {
  const { request } = useLoading();
  const [user] = useDataLayerValue();
  const { _id, roleId } = user;
  const { CREATE, BULK_UPLOAD, EDIT, VIEW_REPORT } = user.rights.Project;
  const navigate = useNavigate();
  if (VIEW_REPORT === false) {
    navigate('/');
  }
  const headers =
    roleId === ADMINISTRATOR_ROLE_ID
      ? ProjectAllHeaders
      : ProjectNonMonetaryHeaders;
  const [data, setData] = useState({ body: [] });
  const [clients, setClients] = useState([]);
  const [updated, setUpdated] = useState(false);

  const toggleUpdate = () => {
    setUpdated((prevUpdated) => !prevUpdated);
  };

  const getData = async () => {
    try {
      const response = await request(getProjectsRequest);
      if (response.status === 200) {
        response.data.body = response.data.body
          .map((project) => {
            return {
              ...project,
              startDate: new Date(project.startDate).toDateString(),
              endDate: new Date(project.endDate).toDateString(),
              projectWorkersDisplay: project.projectWorkerNames.join(', ')
            };
          })
          .sort((project1, project2) =>
            project1.projectName.localeCompare(project2.projectName)
          );
        if (roleId !== ADMINISTRATOR_ROLE_ID) {
          response.data.body = response.data.body.filter(
            (project) =>
              project.projectName === 'Leave' ||
              ('projectWorkerIds' in project &&
                project.projectWorkerIds.includes(_id))
          );
        }
        setData(response.data);
      } else {
        console.log('some error happened');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getClients = async () => {
    try {
      const response = await request(getClientsRequest);
      if (response.status === 200) {
        response.data.body = response.data.body.sort((client1, client2) =>
          client1.name.localeCompare(client2.name)
        );
        setClients(response.data.body);
      } else {
        console.log('some error happened');
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    document.title = `Projects`;
    getData();
    getClients();
  }, [updated]);

  return (
    <>
      <ProjectHeader
        title="Projects"
        options={{
          importOption: BULK_UPLOAD,
          exportOption: false,
          createOption: CREATE
        }}
        toggleUpdate={toggleUpdate}
        links={{
          importLink: BACKEND_URL + '/projects/upload'
        }}
        clients={clients}
      />
      <ProjectTable
        headers={headers}
        data={data}
        toggleUpdate={toggleUpdate}
        url={GET_PROJECT_URL}
        fields={projectEditFields}
        options={{
          editOption: EDIT,
          deleteOption: false
        }}
      />
    </>
  );
};
