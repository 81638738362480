import React, { createContext, useContext, useMemo, useState } from 'react';
import { useNotifications } from './useNotifications';

const LoadingContext = createContext();

export const LoadingProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { showNotifications } = useNotifications();

  const request = async (func) => {
    setIsLoading(true);
    try {
      const res = await func();
      setIsLoading(false);
      return res;
    } catch (error) {
      setIsLoading(false);
      showNotifications('error', error.message, 5000);
      return error;
    }
  };

  const value = useMemo(
    () => ({
      isLoading,
      request
    }),
    [isLoading]
  );

  return (
    <LoadingContext.Provider value={value}>{children}</LoadingContext.Provider>
  );
};

export const useLoading = () => useContext(LoadingContext);
