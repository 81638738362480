import React from 'react';
import { MenuItem, TextField } from '@mui/material';

export const getFormField = ({
  type,
  formFieldsState,
  fieldName,
  fieldLabel,
  handleChangeCB,
  dropDownOptions
}) => {
  switch (type) {
    case 'date':
      return (
        <TextField
          fullWidth
          style={{ marginBottom: '10px' }}
          type="date"
          name={fieldName}
          label={`${fieldLabel}`}
          onChange={handleChangeCB}
          InputLabelProps={{
            shrink: true
          }}
          required={formFieldsState[fieldName].required}
          error={formFieldsState[fieldName].error}
          helperText={
            formFieldsState[fieldName].error &&
            formFieldsState[fieldName].required
              ? 'Required'
              : ''
          }
        />
      );
    case 'file':
      return (
        <TextField
          label={`Upload ${fieldName}`}
          name={fieldName}
          type="file"
          onChange={handleChangeCB}
          InputLabelProps={{ shrink: true }}
        />
      );
    case 'textField':
    case 'multiLineTextField':
      return (
        <TextField
          required={formFieldsState[fieldName].required}
          error={formFieldsState[fieldName].error}
          helperText={
            formFieldsState[fieldName].error &&
            formFieldsState[fieldName].required
              ? 'Required'
              : ''
          }
          label={fieldLabel}
          name={fieldName}
          fullWidth
          style={{ marginBottom: '10px' }}
          value={formFieldsState[fieldName].value}
          onChange={handleChangeCB}
        />
      );
    case 'dropDown':
      return (
        <TextField
          required={formFieldsState[fieldName].required}
          error={formFieldsState[fieldName].error}
          helperText={
            formFieldsState[fieldName].error &&
            formFieldsState[fieldName].required
              ? 'Required'
              : ''
          }
          select
          label={fieldLabel}
          name={fieldName}
          fullWidth
          style={{ marginBottom: '10px' }}
          value={formFieldsState[fieldName].value}
          onChange={handleChangeCB}
        >
          {React.Children.toArray(
            dropDownOptions.map((option) => (
              <MenuItem value={option.value}>{option.label}</MenuItem>
            ))
          )}
        </TextField>
      );
    default:
      return null;
  }
};
