import React, { useState } from 'react';
import { Box, Typography, Button } from '@mui/material';
import { useDataLayerValue } from '../../../../contextAPI/DataLayer';
import { Form } from '../../../../components/Form';
import TabForm from '../../../../components/TabForm';
import {
  TASK_CREATION_URL,
  ASSIGN_EMPLOYEE_PROJECT_URL,
  DEASSIGN_EMPLOYEE_PROJECT_URL
} from '../../../../constants/urls';

const taskCreationFields = (projectName) => [
  {
    label: 'Zoho Link ID',
    name: 'zohoLinkId',
    display: 'optional',
    type: 'url'
  },
  {
    label: 'Task Name*',
    name: 'jobName',
    display: 'required',
    type: 'text'
  },
  {
    label: 'Project Name*',
    name: 'projectName',
    display: 'hidden',
    type: 'text',
    value: projectName
  },
  {
    label: 'Start Date*',
    name: 'startDate',
    display: 'required',
    type: 'date'
  },
  {
    label: 'End Date*',
    name: 'endDate',
    display: 'required',
    type: 'date'
  },
  {
    label: 'Hours*',
    name: 'hours',
    display: 'required',
    type: 'Number'
  },
  {
    label: 'Rate Per Hour',
    name: 'ratePerHour',
    display: 'optional',
    type: 'Number'
  },
  {
    label: 'Task Budget Hours',
    name: 'taskBudgetHours',
    display: 'optional',
    type: 'Number'
  },
  {
    label: 'Description',
    name: 'description',
    display: 'optional',
    type: 'text'
  },
  {
    label: 'Attachment',
    name: 'attachment',
    display: 'optional',
    type: 'text'
  },
  {
    label: 'Status',
    name: 'status',
    display: 'optional',
    type: 'text'
  },
  {
    label: 'Is Billable',
    name: 'billableStatus',
    display: 'required',
    type: 'dropdown',
    value: [
      {
        id: true,
        name: 'true'
      },
      {
        id: false,
        name: 'false'
      }
    ],
    info: ['name', 'id']
  },
  {
    label: 'Is Active',
    name: 'isActive',
    display: 'required',
    type: 'dropdown',
    value: [
      {
        id: true,
        name: 'true'
      },
      {
        id: false,
        name: 'false'
      }
    ],
    info: ['name', 'id']
  },
  {
    label: 'Reminder',
    name: 'reminder',
    display: 'optional',
    type: 'text'
  },
  {
    label: 'Reminder Time',
    name: 'reminderTime',
    display: 'optional',
    type: 'datetime-local'
  }
];

const assignEmployeeFields = (Employees) => [
  {
    label: 'Employee',
    name: 'employeeId',
    display: 'required',
    type: 'dropdown',
    value: Employees,
    info: ['employeeDisplay', '_id']
  }
];

export const TaskHeader = ({
  title,
  options,
  projectName,
  notAssinedEmployees,
  getEmployeeData,
  ProjectWorkers,
  getProjectData
}) => {
  console.log(ProjectWorkers);
  console.log(notAssinedEmployees);
  const [openTaskCreation, setOpenTaskCreation] = useState(false);
  const [openManageEmployee, setOpenManageEmployee] = useState(false);

  const [{ fname, lname, email }] = useDataLayerValue();

  const handleOpenTaskCreation = () => {
    setOpenTaskCreation(true);
  };
  const handleCloseTaskCreation = () => {
    setOpenTaskCreation(false);
  };

  const handleOpenManageEmployee = () => {
    setOpenManageEmployee(true);
  };
  const handleCloseManageEmployee = () => {
    setOpenManageEmployee(false);
  };

  const handleSubmitAssignEmployee = () => {
    getEmployeeData();
    getProjectData();
  };
  const handleSubmitDeAssignEmployee = () => {
    getEmployeeData();
    getProjectData();
  };

  return (
    <Box>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
          m: -1
        }}
      >
        <Typography sx={{ m: 1, fontWeight: 'bold' }} variant="h4">
          {title}
        </Typography>
        {options.assignOption ? (
          <>
            <Button
              variant="contained"
              onClick={handleOpenManageEmployee}
              sx={{ color: 'white', backgroundColor: 'black' }}
            >
              Manage Employee
            </Button>
            <TabForm
              open={openManageEmployee}
              handleClose={handleCloseManageEmployee}
              forms={[
                {
                  title: 'Assign Employee',
                  url: ASSIGN_EMPLOYEE_PROJECT_URL(projectName),
                  fields: assignEmployeeFields(notAssinedEmployees),
                  type: 'post',
                  onSubmit: handleSubmitAssignEmployee
                },
                {
                  title: 'De-Assign Employee',
                  url: DEASSIGN_EMPLOYEE_PROJECT_URL(projectName),
                  fields: assignEmployeeFields(ProjectWorkers),
                  type: 'post',
                  onSubmit: handleSubmitDeAssignEmployee
                }
              ]}
              headers={{
                empname: `${fname} ${lname}`,
                empemail: email
              }}
            />
          </>
        ) : null}
        {options.createOption ? (
          <>
            <Button
              variant="contained"
              onClick={handleOpenTaskCreation}
              sx={{ color: 'white', backgroundColor: 'black' }}
            >
              Add {title}
            </Button>
            <Form
              title={`Fields marked with (*) are required`}
              url={TASK_CREATION_URL(projectName)}
              open={openTaskCreation}
              handleClose={handleCloseTaskCreation}
              fields={taskCreationFields(projectName)}
              type="post"
              headers={{
                empname: `${fname} ${lname}`,
                empemail: email
              }}
            />
          </>
        ) : null}
      </Box>
    </Box>
  );
};
