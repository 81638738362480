import React from 'react';
import {
  Table,
  Button,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  TableContainer,
  tableCellClasses
} from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import GetTableCell from '../../Services/common/GetTableCell';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14
  },
  padding: '0px 5px 0px 5px'
}));

const StyledTableRow = styled(TableRow)(() => ({
  '&:last-child td, &:last-child th': {
    border: 0
  },
  padding: '0px auto',
  margin: '0px'
}));

function SetTable({ data, saveFields, tableMeta, changeType, deleteCB }) {
  return (
    <>
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table
            stickyHeader
            sx={{ minWidth: 700 }}
            aria-label="customized table"
          >
            <TableHead>
              <TableRow>
                {tableMeta &&
                  Object.keys(tableMeta).map((header) => {
                    return (
                      header !== '_id' && (
                        <StyledTableCell
                          align="left"
                          style={{
                            minWidth: '100px'
                          }}
                        >
                          {tableMeta[header]['displayName']}
                        </StyledTableCell>
                      )
                    );
                  })}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((val, index) => (
                <StyledTableRow
                  style={{ cursor: 'pointer', padding: '15px 0' }}
                  hover
                  key={`row-${uuidv4()}`}
                >
                  {Object.keys(tableMeta).map((key) => (
                    <StyledTableCell
                      align="left"
                      style={{
                        minWidth: '100px'
                      }}
                      key={`cells-${uuidv4()}`}
                    >
                      {key === 'Action' ? (
                        <Button
                          color="error"
                          variant="contained"
                          onClick={() => deleteCB(index)}
                        >
                          Delete
                        </Button>
                      ) : changeType !== undefined ? (
                        <GetTableCell
                          rowNumber={index}
                          fieldName={key}
                          fieldType={
                            key === changeType['change'] &&
                            val[changeType['check']] === changeType['equals']
                              ? changeType['to']
                              : tableMeta[key]['fieldType']
                          }
                          value={val[key]}
                          isEditable={
                            val.isAdditional
                              ? true
                              : tableMeta[key]['isEditable']
                          }
                          options={tableMeta[key]['options']}
                          onChangeCB={saveFields}
                          isAdditional={val.isAdditional ? true : false}
                        />
                      ) : (
                        <GetTableCell
                          rowNumber={index}
                          fieldName={key}
                          fieldType={tableMeta[key]['fieldType']}
                          value={val[key]}
                          isEditable={
                            val.isAdditional
                              ? true
                              : tableMeta[key]['isEditable']
                          }
                          options={tableMeta[key]['options']}
                          onChangeCB={saveFields}
                          isAdditional={val.isAdditional ? true : false}
                        />
                      )}
                    </StyledTableCell>
                  ))}
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
}

export default SetTable;
