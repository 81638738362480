import React, { useEffect, useState } from 'react';
import {
  Box,
  Modal,
  Typography,
  TextField,
  Button,
  Accordion,
  AccordionDetails,
  AccordionSummary
} from '@mui/material';
import { updateEngagementPolicyById_Table } from '../../../constants/requests';
import { useLoading } from '../../../hooks/useLoading';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CircularProgress from '@mui/material/CircularProgress';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CancelIcon from '@mui/icons-material/Cancel';
import { getJsonFromMasterExcel } from '../../../constants/requests';
import { useParams } from 'react-router-dom';
import { useNotifications } from '../../../hooks/useNotifications';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 650,
  bgcolor: 'white',
  boxShadow: 24,
  borderRadius: '10px',
  p: 4
};

const UploadExcelModal = ({
  openUploadExcelModal,
  handleCloseUploadExcelModal,
  policyExcelTagsTables,
  setPolicyExceTagsTables,
  policyNameIdMapping
}) => {
  const { showNotifications } = useNotifications();
  const { request } = useLoading();
  const params = useParams();

  const [file, setFile] = useState(null);
  const handleFileSelection = (event) => {
    setFile(event.target.files[0]);
  };

  const [allValid, setAllValid] = useState(false);

  const excelValidationHandler = async () => {
    const newPolicyExcelTagsTablesLoading = policyExcelTagsTables.map(
      (policy) => {
        return {
          ...policy,
          result: 'loading'
        };
      }
    );
    setPolicyExceTagsTables(newPolicyExcelTagsTablesLoading);
    try {
      const formData = new FormData();
      formData.append('file', file);
      const response = await getJsonFromMasterExcel(params.id, formData);
      if (response?.data?.statusCode === 200) {
        setAllValid(response.data.body.valid);
        const newPolicyExcelTagsTables = policyExcelTagsTables.map((policy) => {
          return {
            ...policy,
            result: response.data.body.policyValidations[policy.name].valid,
            message: {
              text:
                Math.random() > 0.5 ? 'All Correct' : 'Missing Tags and Tables',
              response:
                response.data.body.policyValidations[policy.name].validations
            }
          };
        });
        setPolicyExceTagsTables(newPolicyExcelTagsTables);
        showNotifications('success', 'Data Validated successfully', 5000);
      } else {
        if (response.response.data.statusCode !== 200) {
          showNotifications('error', response.response.data.message, 5000);
        }
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  const handleSave = async () => {
    try {
      let success = true;
      for (let i = 0; i < policyExcelTagsTables.length; i++) {
        const tagsAndTables = policyExcelTagsTables[i].message.response;
        const name = policyExcelTagsTables[i].name;
        let tags = [];
        let tables = {};
        tagsAndTables.forEach((tagOrTable) => {
          if (Object.keys(tagOrTable).includes('Tag')) {
            tags.push({
              Tag: tagOrTable['Tag'],
              Question: tagOrTable['Question'],
              Answer: tagOrTable['value']
            });
          } else {
            tables[tagOrTable['Table']] = tagOrTable['value'];
          }
        });
        const updatedData = {
          tags: tags,
          tables: tables
        };
        const response = await request(() =>
          updateEngagementPolicyById_Table(
            policyNameIdMapping[name],
            updatedData
          )
        );
        if (response.data.statusCode !== 200) {
          showNotifications('error', response.data.message, 5000);
          success = false;
        }
      }

      if (success) {
        handleCloseUploadExcelModal();
        setAllValid(false);
        showNotifications('success', 'Save Successful', 5000);
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  useEffect(() => {
    allValid;
  }, []);

  return (
    <Modal
      open={openUploadExcelModal}
      onClose={handleCloseUploadExcelModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography
          id="modal-modal-title"
          variant="h5"
          component="h2"
          sx={{ textAlign: 'center', marginBottom: 3, fontWeight: 600 }}
        >
          Upload Excel File
        </Typography>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-around'
          }}
        >
          <TextField type="file" onChange={handleFileSelection} />
          <Button
            variant="contained"
            sx={{ width: 100, height: '50px' }}
            disabled={!file}
            onClick={excelValidationHandler}
          >
            Validate
          </Button>
        </Box>
        <Box
          sx={{
            marginTop: 5,
            maxHeight: '500px',
            overflow: 'auto',
            padding: 2
          }}
        >
          {policyExcelTagsTables.map((data) => {
            const iconMap = {
              indifferent: <RadioButtonUncheckedIcon />,
              loading: <CircularProgress size={20} />,
              correct: <CheckCircleIcon color="success" />,
              default: <CancelIcon color="error" />
            };

            //if data.result
            let status = 'indifferent';
            if (data.result === 'loading') {
              status = 'loading';
            } else if (data.result === true) {
              status = 'correct';
            } else if (data.result === false) {
              status = 'default';
            } else {
              status = 'indifferent';
            }
            const IconType = iconMap[status];

            return (
              <Accordion key={1} disableGutters>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  sx={{ height: '55px' }}
                >
                  <Box sx={{ marginRight: 2 }}>{IconType}</Box>
                  <Typography>{data.name}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant="body1" gutterBottom>
                    <Box
                      sx={{
                        margin: 'auto',
                        textAlign: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                        maxHeight: '250px',
                        flexWrap: 'wrap'
                      }}
                    >
                      {data.message.response &&
                        data.message.response.map((data) => {
                          if ('Tag' in data) {
                            return (
                              <Box key={data.Tag}>
                                {data.Tag}{' '}
                                {data.found ? (
                                  <CheckCircleIcon color="success" />
                                ) : (
                                  <CancelIcon color="error" />
                                )}
                              </Box>
                            );
                          } else {
                            return (
                              <Box key={data.Table}>
                                {data.Table}{' '}
                                {data.found ? (
                                  <CheckCircleIcon color="success" />
                                ) : (
                                  <CancelIcon color="error" />
                                )}
                              </Box>
                            );
                          }
                        })}
                    </Box>
                  </Typography>
                </AccordionDetails>
              </Accordion>
            );
          })}
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'end' }}>
          <Button
            variant="contained"
            sx={{ width: 100, marginTop: 3 }}
            disabled={!file || !allValid}
            onClick={handleSave}
          >
            Save
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default UploadExcelModal;
