import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  tableCellClasses,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate, useParams } from 'react-router-dom';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useLoading } from '../../../hooks/useLoading';
import { useNotifications } from '../../../hooks/useNotifications';
import { createNewPolicyTemplateVersion } from '../../../constants/requests';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import AddCommentModal from './AddCommentModal';
import { TagsToSkip } from '../../../constants/TagsToSkip';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    fontSize: 15,
    fontWeight: 500
  }
}));

const AddNewQuestionTable = ({
  tableData,
  setTableData,
  file,
  policyName,
  setPolicyName,
  policyType,
  setPolicyType,
  policyTemplateTypes,
  tableTagsType,
  setTableTagsType,
  disableTagsAndTables
}) => {
  const { request } = useLoading();
  const { showNotifications } = useNotifications();
  const params = useParams();
  const navigate = useNavigate();

  const [comment, setComment] = useState('');
  const [openAddCommentModal, setOpenAddCommentModal] = useState(false);
  const handleOpenAddCommentModal = () => setOpenAddCommentModal(true);
  const handleCloseAddCommentModal = () => setOpenAddCommentModal(false);

  const handleQuestionChange = (index, event) => {
    const { value } = event.target;
    const newData = [...tableData];
    newData[index].Question = value;
    setTableData(newData);
  };

  const handleTypeChange = (index, event) => {
    const { value } = event.target;
    const newData = [...tableData];
    newData[index].Type = value;
    setTableData(newData);
  };

  const createNewPolicyVersionHandler = async () => {
    const tags = [];
    const tables = [];
    tableData.forEach((data) => {
      if (!TagsToSkip.includes(data.Tag)) {
        if (data.Type === 'tag') {
          if (data.status === 'removed') return;
          tags.push({ Tag: data.Tag, Question: data.Question, Answer: '' });
        } else {
          if (data.status === 'removed') return;
          tables.push(data.Tag);
        }
      }
    });
    try {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('tags', JSON.stringify(tags));
      formData.append('tables', JSON.stringify(tables));
      formData.append('comments', comment);

      const response = await request(() =>
        createNewPolicyTemplateVersion(params.id, formData)
      );
      if (response?.data?.statusCode === 200) {
        navigate('/policy');
        showNotifications('success', 'New Version created successfully', 5000);
      } else {
        if (response.response.data.statusCode !== 200) {
          showNotifications('error', response.response.data.message, 5000);
        }
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  useEffect(() => {
    tableTagsType, setPolicyType, policyTemplateTypes;
  }, []);

  const displayTableData = tableData.filter(({ status }) =>
    ({ Tags: ['common', 'new'], New: ['new'], Removed: ['removed'] }[
      tableTagsType
    ]?.includes(status))
  );

  if (tableData.length === 0) {
    return <Typography variant="h4"> No Tags Found. </Typography>;
  }

  return (
    <React.Fragment>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', justifyContent: 'left' }}>
          <TextField
            id="outlined-basic"
            label="Policy Name"
            name="Policy Name"
            value={policyName}
            variant="outlined"
            sx={{ margin: 2, marginLeft: 0, width: 250 }}
            onChange={(event) => {
              setPolicyName(event.target.value);
            }}
            disabled
          />
          <TextField
            id="outlined-basic"
            label="Policy Type"
            name="Policy Type"
            value={policyType}
            variant="outlined"
            sx={{ margin: 2, marginLeft: 0, width: 250 }}
            onChange={(event) => {
              setPolicyName(event.target.value);
            }}
            disabled
          />
        </Box>
        <Box>
          {!disableTagsAndTables && (
            <Box>
              <FormControl sx={{ margin: 2, width: 250 }}>
                <InputLabel id="demo-simple-select-label">Tags</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={tableTagsType}
                  label="Tags"
                  onChange={(event) => {
                    setTableTagsType(event.target.value);
                  }}
                >
                  <MenuItem value="Tags" key="Tags">
                    Tags
                  </MenuItem>
                  <MenuItem value="New" key="New">
                    New Tags
                  </MenuItem>
                  <MenuItem value="Removed" key="Removed">
                    Removed Tags
                  </MenuItem>
                </Select>
              </FormControl>
              <Button
                variant="contained"
                sx={{ margin: 2, height: '56px' }}
                onClick={() => {
                  //create a modal at this point and give those options in here, simple as that -- do it all tomorrow brother
                  //let's create a modal for this point
                  handleOpenAddCommentModal();
                }}
                disabled={policyName.length === 0 || policyType.length === 0}
              >
                Add
              </Button>
            </Box>
          )}
        </Box>
      </Box>
      {displayTableData.length === 0 ? (
        <Typography variant="h5"> No Tags Found. </Typography>
      ) : (
        <TableContainer sx={{ marginBottom: 5 }}>
          <Table sx={{ border: 2, borderColor: 'rgba(224, 224, 224, 1)' }}>
            <TableHead>
              <TableRow>
                <StyledTableCell>Tags</StyledTableCell>
                <StyledTableCell>Questions</StyledTableCell>
                <StyledTableCell>Type</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {displayTableData.map((data, index) => {
                const rowColor =
                  data.status === 'removed'
                    ? '#ff3333'
                    : data.status === 'new'
                    ? '#33ff77'
                    : 'rgba(224, 224, 224, 1)';
                const borderSize = data.status === 'common' ? '1px' : '5px';
                return (
                  <TableRow key={index}>
                    <TableCell
                      sx={{
                        width: 250,
                        borderLeft: `${borderSize} solid ${rowColor}`
                      }}
                    >
                      {data.Tag}
                    </TableCell>
                    <TableCell>
                      <TextField
                        value={data.Question}
                        fullWidth
                        disabled={
                          disableTagsAndTables === true
                            ? true
                            : data.Type === 'table' ||
                              TagsToSkip.includes(data.Tag)
                        }
                        onChange={(event) => handleQuestionChange(index, event)}
                      />
                    </TableCell>
                    <TableCell sx={{ width: 200 }}>
                      <FormControl fullWidth>
                        <Select
                          value={data.Type}
                          displayEmpty
                          inputProps={{ 'aria-label': 'Without label' }}
                          onChange={(event) => handleTypeChange(index, event)}
                          disabled={
                            disableTagsAndTables === true ? true : false
                          }
                        >
                          <MenuItem value="tag">Tag</MenuItem>
                          <MenuItem value="table">Table</MenuItem>
                        </Select>
                      </FormControl>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {/*Delete Policy Modal*/}
      <AddCommentModal
        openAddCommentModal={openAddCommentModal}
        handleCloseAddCommentModal={handleCloseAddCommentModal}
        comment={comment}
        setComment={setComment}
        createNewPolicyVersionHandler={createNewPolicyVersionHandler}
      />
    </React.Fragment>
  );
};

export default AddNewQuestionTable;
