import React, { useState, useEffect } from 'react';
import {
  createAll,
  deleteLibraryById,
  findAllLibraryType,
  updateLibraryById
} from '../../../../constants/requests';
import {
  Box,
  Typography,
  Button,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material';
import { useLoading } from '../../../../hooks/useLoading';
import { useNotifications } from '../../../../hooks/useNotifications';

const style = {
  width: '100%',
  bgcolor: 'white',
  borderRadius: '5px',
  maxHeight: '85vh',
  overflowY: 'auto',
  p: 4
};

function Vulnerability() {
  const { request } = useLoading();
  const { showNotifications } = useNotifications();
  const [isOpen, setIsOpen] = useState(false);
  const [formData, setFormData] = useState({
    description: '',
    name: '',
    type: 'vulnerability'
  });
  const [data, setData] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const dta = ['vulnerability'];
      const response = await request(() => findAllLibraryType(dta[0]));
      setData(response.data.body);
    } catch (error) {
      showNotifications('error', error.toString(), 5000);
      console.error(error);
    }
  };

  const handleOpenDialog = () => {
    setIsOpen(true);
  };

  const handleCloseDialog = () => {
    setIsOpen(false);
  };

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleAddData = async () => {
    try {
      const response = await request(() => createAll([formData]));
      if (response.data.statusCode == 200) {
        console.log(response.data); // You can do something with the response if needed
        handleCloseDialog();
        fetchData(); // Refresh the data after adding
        showNotifications('success', response.data.message, 5000);
      } else {
        showNotifications('error', response.response.data.message, 5000);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleUpdateData = async (threatvulId) => {
    try {
      const response = await request(() =>
        updateLibraryById(threatvulId, formData)
      );

      if (response?.data?.statusCode === 200) {
        showNotifications('success', 'Save successful', 5000);

        console.log(response.data); // You can do something with the response if needed
        handleCloseDialog();
        fetchData();
        showNotifications('success', response.data.message, 5000);
      } else {
        if (response.response.data.statusCode !== 200) {
          showNotifications('error', response.response.data.message, 5000);
        }
      }
    } catch (error) {
      showNotifications('error', error.toString(), 5000);
      console.error(error);
    }
  };

  const handleEdit = (threatvulId) => {
    const selectedData = data.find((item) => item._id === threatvulId);
    setFormData(selectedData);
    handleOpenDialog();
  };

  const handleDelete = async (threatvulId) => {
    try {
      console.log(threatvulId);

      const response = await request(() => deleteLibraryById(threatvulId));
      // Handle successful deletion
      if (response.data.statusCode === 200) {
        fetchData();
        showNotifications('success', response.data.message, 5000);
      } else {
        showNotifications('error', response.data.message, 5000);
      }
    } catch (err) {
      console.log(err);
      showNotifications('error', err.toString(), 5000);
      // Handle error
    }
  };

  return (
    <Box sx={style}>
      <Box>
        <Button
          variant="contained"
          style={{
            marginTop: '10px',
            backgroundColor: 'black',
            justifyItems: 'end'
          }}
          onClick={handleOpenDialog}
        >
          Add Vulnerability
        </Button>

        {isOpen && (
          <Box
            className="dialog"
            sx={{ margin: '20px', padding: '100px 100px 100px' }}
          >
            <Box className="dialog-content">
              <Typography variant="h4" sx={{ margin: '20px' }}>
                {formData._id ? 'Update Vulnerability' : 'Add Vulnerability'}
              </Typography>
              <form>
                <Box>
                  <label htmlFor="description">Description* :</label>
                  <input
                    type="text"
                    id="description"
                    name="description"
                    value={formData.description}
                    placeholder="Description"
                    required
                    onChange={handleInputChange}
                  />
                </Box>
                <Box>
                  <label htmlFor="name">Name* :</label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    placeholder="Name"
                    required
                    value={formData.name}
                    onChange={handleInputChange}
                  />
                </Box>
                {formData._id ? (
                  <Button
                    variant="contained"
                    style={{
                      marginTop: '10px',
                      marginInline: '4px',
                      backgroundColor: 'black'
                    }}
                    type="button"
                    onClick={() => handleUpdateData(formData._id)}
                  >
                    Update
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    style={{
                      marginTop: '10px',
                      marginInline: '4px',
                      backgroundColor: 'black'
                    }}
                    type="button"
                    onClick={handleAddData}
                  >
                    Add
                  </Button>
                )}
                <Button
                  variant="contained"
                  style={{
                    marginTop: '10px',
                    marginInline: '2px',
                    backgroundColor: 'black'
                  }}
                  type="button"
                  onClick={handleCloseDialog}
                >
                  Cancel
                </Button>
              </form>
            </Box>
          </Box>
        )}
      </Box>
      <Typography variant="h4">Vulnerability List</Typography>
      <Box>
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Vulnerability Name</TableCell>
                <TableCell>Vulnerability Description</TableCell>

                <TableCell>Vulnerability Actions</TableCell>
                {/* Add more table headers if needed */}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((item) => (
                <TableRow
                  key={item._id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {item.name}
                  </TableCell>

                  <TableCell>{item.description}</TableCell>

                  <TableCell>
                    <Box
                      style={{
                        display: 'flex',
                        marginTop: '2px'
                      }}
                    >
                      <Button
                        variant="contained"
                        sx={{
                          justifyContent: 'space-between',
                          marginLeft: '2px',
                          marginRight: '2px',
                          backgroundColor: 'black'
                        }}
                        onClick={() => handleEdit(item._id)}
                      >
                        Edit
                      </Button>
                      <Button
                        variant="contained"
                        sx={{
                          justifyContent: 'space-between',
                          backgroundColor: 'black'
                        }}
                        onClick={() => handleDelete(item._id)}
                      >
                        Delete
                      </Button>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
}

export default Vulnerability;
