import React from 'react';
import {
  Box,
  Table,
  Button,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  TableContainer,
  tableCellClasses
} from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14
  },
  padding: '0px 5px 0px 5px'
}));

const StyledTableRow = styled(TableRow)(() => ({
  '&:last-child td, &:last-child th': {
    border: 0
  },
  padding: '0px auto',
  margin: '0px'
}));

const normalStyle = {
  minWidth: '100px'
};

const marginStyle = {
  marginTop: '20px',
  marginBottom: '20px'
};

function TemplateTable({ data, deleteCB, updateCB, downloadCB, widthStyle }) {
  const getTable = () => {
    if (data.length == 0) return;
    return data.map((val, idx) => (
      <StyledTableRow
        style={{ cursor: 'pointer' }}
        hover
        key={`row-${uuidv4()}`}
      >
        {Object.keys(val).map((key) => {
          return key === 'Action' ? (
            <Box
              style={{
                display: 'flex',
                gap: '10px',
                margin: '10px 0'
              }}
            >
              {deleteCB && (
                <Button
                  color="error"
                  variant="contained"
                  style={widthStyle && marginStyle}
                  onClick={() => deleteCB(idx)}
                >
                  Delete
                </Button>
              )}
              {updateCB && (
                <Button
                  sx={{ background: 'black', color: 'white' }}
                  variant="contained"
                  onClick={() => updateCB(idx)}
                >
                  Update
                </Button>
              )}
              {downloadCB && (
                <Button
                  sx={{ background: 'black', color: 'white' }}
                  variant="contained"
                  onClick={() => downloadCB(idx)}
                >
                  Download
                </Button>
              )}
            </Box>
          ) : (
            key !== '_id' && val[key] !== undefined && (
              <StyledTableCell
                sx={widthStyle ? widthStyle : normalStyle}
                style={{
                  minHeight: '0px',
                  padding: '10px'
                }}
                align="left"
              >
                {val[key] !== null && val[key].toString()}
              </StyledTableCell>
            )
          );
        })}
      </StyledTableRow>
    ));
  };

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table
          stickyHeader
          sx={{ minWidth: 700 }}
          aria-label="customized table"
        >
          <TableHead>
            <TableRow>
              {data &&
                Object.keys(data[0]).map((header) => {
                  return (
                    header !== '_id' && (
                      <StyledTableCell
                        align="left"
                        sx={widthStyle ? widthStyle : normalStyle}
                      >
                        {header}
                      </StyledTableCell>
                    )
                  );
                })}
            </TableRow>
          </TableHead>
          <TableBody>{getTable()}</TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}

export default TemplateTable;
