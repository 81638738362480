import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useDataLayerValue } from '../../../contextAPI/DataLayer';
import { useNavigate } from 'react-router-dom';
const logSheets = [
  {
    name: 'Employee Logs',
    sheet_id: '1QSFIQTW7aj38tjNpYKExK1vnkJiamf1YqT1tpJD1srY'
  },
  {
    name: 'Employee Inactive Logs',
    sheet_id: '1uS0BW3imizKn2I8sKDgm-IC24d2xmLyXuu6vrtrNiO0'
  },
  {
    name: 'Timesheet Logs',
    sheet_id: '1CC-enSu8AChca0qm8dfpeU45yf-fCmKHzYwmqONXOjo'
  },
  {
    name: 'Project Logs',
    sheet_id: '1raBxQs6p0M0QPfM-8513F3dtdHlsCWNrbIYj5vesg4w'
  }
];

const handleClick = (sheet_id) => {
  const url = `https://docs.google.com/spreadsheets/d/${sheet_id}/export?format=pdf`;
  window.open(url, '_blank');
};

export const MasterLogs = () => {
  const [user] = useDataLayerValue();
  const { DOWNLOAD, VIEW_REPORT } = user.rights.Log;
  const navigate = useNavigate();
  if (VIEW_REPORT === false) {
    navigate('/');
  }

  return (
    <Box sx={{ m: 2 }}>
      <Typography sx={{ m: 1, mb: 4, fontWeight: 'bold' }} variant="h4">
        Log Reports
      </Typography>
      {!DOWNLOAD ? (
        <Typography sx={{ m: 1, mb: 4, fontWeight: 'bold' }} variant="h5">
          Nothing To Show Here
        </Typography>
      ) : (
        logSheets.map((logSheet) => {
          return (
            // eslint-disable-next-line
            <Button
              variant="contained"
              sx={{
                m: 3,
                width: '15vw',
                color: 'white',
                backgroundColor: 'black',
                display: 'block'
              }}
              onClick={() => handleClick(logSheet.sheet_id)}
            >
              {logSheet.name}
            </Button>
          );
        })
      )}
    </Box>
  );
};
