import React, { useRef } from 'react';
import { Cancel } from '@mui/icons-material';
import { Stack, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';

const Identifier = ({ data, handleDelete }) => {
  return (
    data && (
      <>
        <Box
          sx={{
            background: '#283240',
            height: '30%',
            display: 'flex',
            padding: '0.1rem',
            margin: '0 0.5rem 0 0',
            justifyContent: 'center',
            alignContent: 'center',
            color: '#ffffff'
          }}
        >
          <Stack direction="row" gap={1}>
            <Typography>{data}</Typography>
            <Cancel
              sx={{ cursor: 'pointer' }}
              onClick={() => {
                handleDelete(data);
              }}
            />
          </Stack>
        </Box>
      </>
    )
  );
};

/*
  Initial Tags - Pass initial state fetched from DB
  saveIdentifiers - A function to set final state of tags
*/

const MergingIdentifiers = ({ initialTags, saveIdentifiers }) => {
  const tagRef = useRef();

  const handleDelete = (value) => {
    const newtags = initialTags.filter((val) => val !== value);
    saveIdentifiers(newtags);
  };
  const handleOnSubmit = (e) => {
    e.preventDefault();
    const newTags = [...initialTags, tagRef.current.value];
    saveIdentifiers(newTags);
    tagRef.current.value = '';
  };
  return (
    <Box sx={{ flexGrow: 1 }}>
      <form onSubmit={handleOnSubmit}>
        <TextField
          inputRef={tagRef}
          fullWidth
          variant="standard"
          size="small"
          sx={{ margin: '1rem 0' }}
          margin="none"
          placeholder={
            initialTags.length < 10
              ? 'Create Tags for Row Numbers to be Merged'
              : ''
          }
          InputProps={{
            startAdornment: (
              <Box sx={{ margin: '0 0.2rem 0 0', display: 'flex' }}>
                {initialTags.length > 0 &&
                  initialTags.map((data, index) => {
                    return (
                      <Identifier
                        data={data}
                        handleDelete={handleDelete}
                        key={index}
                      />
                    );
                  })}
              </Box>
            )
          }}
        />
      </form>
    </Box>
  );
};

export default MergingIdentifiers;
