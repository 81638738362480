import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Select,
  Checkbox,
  MenuItem,
  TextField,
  InputLabel,
  Typography,
  FormControl,
  ListItemText
} from '@mui/material';
import {
  updateRCMRequest,
  getRCMByIdRequest
} from '../../../constants/requests';
import { useLoading } from '../../../hooks/useLoading';
import { useDataLayerValue } from '../../../contextAPI/DataLayer';
import { useNotifications } from '../../../hooks/useNotifications';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import CustomTable from '../common/CustomTable';
import CancelIcon from '@mui/icons-material/Cancel';
import VerifiedIcon from '@mui/icons-material/Verified';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const systemOptions = [
  {
    label: 'AZURE',
    value: 'AZURE'
  },
  {
    label: 'AWS',
    value: 'AWS'
  },
  {
    label: 'RANKSPACE',
    value: 'RANKSPACE'
  }
];

const CUEC = () => {
  const navigate = useNavigate();
  const { request } = useLoading();
  const { showNotifications } = useNotifications();
  const [{ isVerifiedBySPOC }, dispatch] = useDataLayerValue();
  const { engagementId, applicationId, rcmId } = useParams();
  const [system, setSystem] = useState(null);
  const [cuecData, setCUECData] = useState(null);
  const [currentTabName, setcurrentTabName] = useState(null);
  const [systemRows, setSystemRows] = useState([]);

  const toggleVerification = (fieldName, cellValue) => {
    if (fieldName === 'Verified(Yes/No)' && cellValue === 'No') {
      dispatch({
        type: 'SET_VERIFIED',
        isVerifiedBySPOC: false
      });
    }
  };

  const handleChange = (rowNumber, fieldName, cellValue, tabName) => {
    const modifiedData = { ...cuecData };
    modifiedData.rows[tabName][rowNumber][fieldName] = cellValue;
    toggleVerification(fieldName, cellValue);
    setCUECData(modifiedData);
  };

  const handleCustomChange = (rowNumber, fieldName, cellValue) => {
    const modifiedData = [...systemRows];
    modifiedData[rowNumber][fieldName] = cellValue;
    toggleVerification(fieldName, cellValue);
    setSystemRows(modifiedData);
  };

  const setVerification = (verificationData) => {
    let flag = true;
    for (const table of Object.keys(verificationData)) {
      for (const row of verificationData[table]) {
        if (row['Verified(Yes/No)'] === 'No') {
          flag = false;
          break;
        }
      }
      if (!flag) break;
    }

    if (flag) {
      dispatch({
        type: 'SET_VERIFIED',
        isVerifiedBySPOC: true
      });
    }
    if (!flag) {
      dispatch({
        type: 'SET_VERIFIED',
        isVerifiedBySPOC: false
      });
    }
  };

  const handleVerification = () => {
    const verifiedData = { ...cuecData };
    for (const table of Object.keys(verifiedData.rows)) {
      for (const row of verifiedData.rows[table]) {
        row['Verified(Yes/No)'] = 'Yes';
      }
    }
    dispatch({
      type: 'SET_VERIFIED',
      isVerifiedBySPOC: true
    });
    showNotifications(
      'success',
      'Successfully Verified All The Rows. Please Save The Changes',
      500
    );
  };

  const handleSystemChange = (e) => {
    setSystem(e.target.value);
    const modifiedData = { ...cuecData };
    let arr = [];
    for (const csocRow of modifiedData.rows['CSOC']) {
      if (e.target.value.includes(csocRow['System Ref.'])) {
        arr.push(csocRow);
        csocRow['CUEC/CSOC Compliant(Yes/No)'] = 'Yes';
      } else {
        csocRow['CUEC/CSOC Compliant(Yes/No)'] = 'No';
      }
    }
    setSystemRows(arr);
    setCUECData(modifiedData);
  };

  const loadApplication = async () => {
    try {
      const res = await request(() => getRCMByIdRequest(rcmId));
      if (res.data.statusCode === 200) {
        let criterias = [],
          prev = null;
        for (const header of Object.keys(res.data.body.POFdata.rows)) {
          for (const row of res.data.body.POFdata.rows[header]) {
            const option = {
              label: row['TSC Ref#'],
              value: row['TSC Ref#']
            };
            if (prev !== option.value) {
              criterias.push(option);
              prev = option.value;
            }
          }
        }

        if (
          res.data.body.CUEC_CSOCData.meta.fields['Related Criteria'][
            'options'
          ] === undefined
        ) {
          res.data.body.CUEC_CSOCData.meta.fields['Related Criteria'][
            'options'
          ] = criterias;
        }

        setVerification(res.data.body.CUEC_CSOCData.rows);
        const csocRows = res.data.body.CUEC_CSOCData.rows['CSOC'].filter(
          (row) => row['System Ref.'] === res.data.body.SystemRef[0]
        );
        setSystemRows(csocRows);
        setSystem(res.data.body.SystemRef);
        setCUECData(res.data.body.CUEC_CSOCData);
        setcurrentTabName(Object.keys(res.data.body.CUEC_CSOCData.rows)[0]);
        showNotifications('success', res.data.message, 5000);
      } else {
        showNotifications('error', res.data.message, 5000);
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  const handleSave = async () => {
    try {
      const payload = {
        CUEC_CSOCData: cuecData,
        SystemRef: system
      };

      setVerification(cuecData.rows);
      let response = await request(() => updateRCMRequest(rcmId, payload));
      if (response.data.statusCode === 200) {
        showNotifications('success', response.data.message, 5000);
      } else {
        showNotifications('error', response.data.message, 5000);
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
      console.log(err);
    }
  };

  useEffect(() => {
    loadApplication();
  }, []);

  return (
    <React.Fragment>
      <Box
        style={{
          marginBottom: '25px',
          display: 'flex',
          justifyContent: 'space-between'
        }}
      >
        <Button
          sx={{
            color: 'white',
            backgroundColor: 'black',
            paddingLeft: 1
          }}
          variant="contained"
          onClick={() => {
            navigate(
              `/services/engagement/${engagementId}/application/${applicationId}/rcm/${rcmId}/section3`
            );
          }}
        >
          <ChevronLeftIcon />
          Section - 3
        </Button>
        <Button
          sx={{
            color: 'white',
            backgroundColor: 'black',
            paddingLeft: 1
          }}
          variant="contained"
          onClick={() => {
            navigate(
              `/services/engagement/${engagementId}/application/${applicationId}/drl`
            );
          }}
        >
          DRL
          <ChevronRightIcon />
        </Button>
      </Box>
      <Box
        style={{
          marginBottom: '25px',
          display: 'flex',
          justifyContent: 'space-between'
        }}
      >
        <Typography sx={{ fontWeight: 'bold' }} variant="h4">
          CUEC/CSOC
        </Typography>
        {cuecData ? (
          <Box
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              gap: '10px'
            }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={handleVerification}
            >
              Verify All
            </Button>
            <TextField
              disabled={cuecData === null}
              select
              label="Tab Name"
              value={currentTabName}
              onChange={(e) => setcurrentTabName(e.target.value)}
              style={{ minWidth: '200px' }}
              size="small"
              InputLabelProps={{ shrink: true }}
            >
              {cuecData &&
                Object.keys(cuecData.rows)?.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
            </TextField>
            <Button variant="contained" color="success" onClick={handleSave}>
              Save
            </Button>
          </Box>
        ) : null}
      </Box>
      {cuecData && isVerifiedBySPOC ? (
        <Box style={{ display: 'flex', justifyContent: 'center' }}>
          <Typography sx={{ fontWeight: 'bold', color: 'Green' }} variant="h5">
            <VerifiedIcon color="Green" fontSize="small" />
            Verified
          </Typography>
        </Box>
      ) : (
        <Box style={{ display: 'flex', justifyContent: 'center' }}>
          <Typography sx={{ fontWeight: 'bold', color: 'Red' }} variant="h5">
            <CancelIcon color="Red" fontSize="small" />
            Not Verified
          </Typography>
        </Box>
      )}

      <TabContext value={currentTabName}>
        {cuecData ? (
          Object.keys(cuecData.rows).map((CUECTab) => {
            return (
              <TabPanel
                key={`CUEC Table: ${CUECTab}`}
                value={CUECTab}
                index={CUECTab}
                sx={{ p: '0px' }}
              >
                {CUECTab === 'CSOC' ? (
                  <FormControl fullWidth>
                    <InputLabel
                      variant="standard"
                      shrink={true}
                      htmlFor="uncontrolled-native"
                    >
                      Select Systems
                    </InputLabel>
                    <Select
                      multiple
                      displayEmpty
                      fullWidth
                      style={{ margin: '20px 0' }}
                      name="system"
                      value={system}
                      onChange={handleSystemChange}
                      renderValue={(selected) => {
                        if (
                          selected.toString() === '' ||
                          selected.length === 0
                        ) {
                          return <em>Select</em>;
                        }
                        return selected.filter((val) => val !== '').join(',');
                      }}
                    >
                      <MenuItem disabled value="">
                        <em>Select</em>
                      </MenuItem>
                      {systemOptions?.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          <Checkbox
                            checked={system.indexOf(option.value) > -1}
                          />
                          <ListItemText primary={option.label} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                ) : null}

                <CustomTable
                  tabName={CUECTab}
                  rows={
                    CUECTab === 'CSOC' ? systemRows : cuecData.rows[CUECTab]
                  }
                  handleChangeCB={
                    CUECTab === 'CSOC' ? handleCustomChange : handleChange
                  }
                  tableMeta={cuecData.meta[CUECTab]}
                  fieldsMeta={cuecData.meta.fields}
                />
              </TabPanel>
            );
          })
        ) : (
          <Box style={{ display: 'flex', justifyContent: 'center' }}>
            <Typography sx={{ fontWeight: 'bold', color: 'Red' }} variant="h5">
              No Records Found.
            </Typography>
          </Box>
        )}
      </TabContext>
    </React.Fragment>
  );
};

export default CUEC;
