import React, { useState } from 'react';
import { Box, Typography, Button } from '@mui/material';
import { useNotifications } from '../../../../hooks/useNotifications';
import { useDataLayerValue } from '../../../../contextAPI/DataLayer';
import { Form } from '../../../../components/Form';
import { PROJECT_CREATION_URL } from '../../../../constants/urls';
import UploadIcon from '@mui/icons-material/Upload';
import axios from 'axios';
import { Modal } from '../../../../components/Modal';

const projectCreationFields = (clients) => [
  {
    label: 'Zoho Link ID',
    name: 'zohoLinkId',
    display: 'optional',
    type: 'url'
  },
  {
    label: 'Client*',
    name: 'clientId',
    display: 'optional',
    type: 'dropdown',
    value: clients,
    info: ['name', '_id']
  },
  {
    label: 'Project Name*',
    name: 'projectName',
    display: 'required',
    type: 'text'
  },
  {
    label: 'Risk*',
    name: 'risk',
    display: 'required',
    type: 'dropdown',
    value: [
      {
        id: 'Low',
        name: 'Low'
      },
      {
        id: 'Medium',
        name: 'Medium'
      },
      {
        id: 'High',
        name: 'High'
      }
    ],
    info: ['name', 'id']
  },
  {
    label: 'Description*',
    name: 'description',
    display: 'required',
    type: 'text'
  },
  {
    label: 'Ongoing*',
    name: 'isActive',
    display: 'required',
    type: 'dropdown',
    value: [
      {
        id: true,
        name: 'true'
      },
      {
        id: false,
        name: 'false'
      }
    ],
    info: ['name', 'id']
  },
  {
    label: 'Start Date*',
    name: 'startDate',
    display: 'required',
    type: 'date',
    value: new Date().toDateString()
  },
  {
    label: 'End Date*',
    name: 'endDate',
    display: 'required',
    type: 'date',
    value: new Date().toDateString()
  },
  {
    label: 'Currency Code*',
    name: 'currencyCode',
    display: 'required',
    type: 'text',
    value: 'INR'
  },
  {
    label: 'Project Cost*',
    name: 'projectCost',
    display: 'required',
    type: 'Number'
  },
  {
    label: 'Budget Amount*',
    name: 'budgetAmount',
    display: 'required',
    type: 'Number'
  },
  {
    label: 'Cost Budget',
    name: 'costBudget',
    display: 'optional',
    type: 'Number'
  },
  {
    label: 'Budget Hours*',
    name: 'budgetHours',
    display: 'required',
    type: 'Number'
  },
  {
    label: 'Billing Type',
    name: 'billingType',
    display: 'optional',
    type: 'text'
  },
  {
    label: 'Budget Type',
    name: 'budgetType',
    display: 'optional',
    type: 'text'
  }
];

export const ProjectHeader = ({
  title,
  options,
  links,
  toggleUpdate,
  clients
}) => {
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [{ fname, lname, email, _id }] = useDataLayerValue();
  const { showNotifications } = useNotifications();
  // console.log(clients.map((client) => client.name));

  const handleOpen = () => {
    setOpen(true);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const [isSelected, setIsSelected] = useState(false);

  const [selectedCSVFile, setSelectedCSVFile] = useState({
    data: '',
    name: ''
  });

  async function handleCSVUpload() {
    if (!isSelected) {
      showNotifications('error', 'No file selected', 5000);
      return;
    }
    const formData = new FormData();
    formData.append('file', selectedCSVFile.data);
    formData.append('fileName', selectedCSVFile.name);
    try {
      const res = await axios.post(links.importLink, formData, {
        headers: {
          employeeid: _id,
          empname: `${fname} ${lname}`,
          empemail: email
        }
      });
      console.log(res);
      if (res.data.statusCode !== 201) {
        showNotifications('error', res.data.message, 5000);
      } else {
        showNotifications('success', res.data.message, 5000);
      }
      console.log(res);
      handleCloseModal();
      setIsSelected(false);
      toggleUpdate();
    } catch (ex) {
      showNotifications('error', 'Error Occurred', 5000);
    }
  }

  function handleCsvChange(e) {
    console.log(e.target.files[0]);
    setSelectedCSVFile({
      data: e.target.files[0],
      name: e.target.files[0].name
    });
    setIsSelected(true);
    // console.log("I was Changed");
  }
  console.log(selectedCSVFile);

  return (
    <Box>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
          m: -1
        }}
      >
        <Typography sx={{ m: 1, fontWeight: 'bold' }} variant="h4">
          {title}
        </Typography>
        {options.importOption ? (
          <Button
            startIcon={<UploadIcon />}
            sx={{ mr: 1, color: 'black' }}
            onClick={handleOpenModal}
          >
            Import
          </Button>
        ) : null}
        {options.createOption ? (
          <>
            <Button
              variant="contained"
              onClick={handleOpen}
              sx={{ color: 'white', backgroundColor: 'black' }}
            >
              Add {title}
            </Button>
            <Form
              title={`Fields marked with (*) are required`}
              url={PROJECT_CREATION_URL}
              open={open}
              handleClose={handleClose}
              fields={projectCreationFields(clients)}
              type="post"
              headers={{
                employeeid: _id,
                empname: `${fname} ${lname}`,
                empemail: email
              }}
            />
          </>
        ) : null}
      </Box>
      <Modal open={openModal} handleClose={handleCloseModal}>
        <Typography variant="h5">Upload CSV/Excel File</Typography>
        <input
          type="file"
          name="upload-csv"
          id="upload-csv"
          onChange={handleCsvChange}
          accept=".csv,.xlsx"
          style={{ margin: '1.5vw 0' }}
        />
        <Button onClick={handleCSVUpload}>Upload</Button>
      </Modal>
    </Box>
  );
};
