import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Modal,
  Button,
  Collapse,
  InputBase,
  InputLabel,
  Typography,
  FormControl,
  NativeSelect
} from '@mui/material';
import {
  getAllControls,
  createGovernance,
  getAllGovernances,
  getControlsByGovernanceId,
  connectGovernaceToControls
} from '../../constants/requests';
import { styled } from '@mui/material/styles';
import { useLoading } from '../../hooks/useLoading';
import { useNotifications } from '../../hooks/useNotifications';
import ControlForm from './ControlForm';
import CreateEditDialog from '../Services/common/CreateEditDialog';
import TemplateTable from '../TemplateEditor/common/TemplateTable';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { useNavigate } from 'react-router-dom';

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(2)
  },
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)'
    }
  }
}));

const screenWise = {
  field1: 'control',
  field2: 'data'
};

const emptyList = {
  value: null,
  label: 'Select'
};

const addGovernanceForm = [
  {
    type: 'textField',
    fieldName: 'name',
    fieldLabel: 'Enter Name',
    value: '',
    required: true
  },
  {
    type: 'dropDown',
    fieldName: 'type',
    fieldLabel: 'Select type',
    value: '',
    required: true,
    dropDownOptions: [
      {
        label: 'Standard',
        value: 'Standard'
      },
      {
        label: 'Control',
        value: 'Control'
      },
      {
        label: 'Risk',
        value: 'Risk'
      },
      {
        label: 'Compliance',
        value: 'Compliance'
      }
    ]
  }
];

const SCF = () => {
  const initialRender = useRef(true);
  const { request } = useLoading();
  const { showNotifications } = useNotifications();
  const [govId, setGovId] = useState(null);
  const [govNames, setGovNames] = useState([]);
  const [controlData, setControlData] = useState({});
  const [controlNames, setControlNames] = useState([]);
  const [connectedData, setConnectedData] = useState([]);
  const [tableOpen, setTableOpen] = useState(false);
  const [openAddGovernanceModal, setOpenAddGovernanceModal] = useState(false);
  const navigate = useNavigate();

  const handleOpenAddGovernanceModal = () => setOpenAddGovernanceModal(true);
  const handleCloseAddGovernanceModal = () => setOpenAddGovernanceModal(false);

  const handleChange = (controlsList) => {
    const newControlData = { ...controlData };
    for (const controlObj of controlsList) {
      const { control, data } = controlObj;
      if (control === '') continue;
      newControlData[control] = data;
    }
    setControlData(newControlData);
  };

  const getControlsList = async () => {
    try {
      const response = await request(() => getAllControls());
      if (response.data.statusCode === 200) {
        const controlNameList = response.data.body.map((controlData) => {
          return {
            value: controlData._id,
            label: controlData['data']['SCF Control']
          };
        });
        setControlNames([emptyList, ...controlNameList]);
        showNotifications('success', response.data.message, 5000);
      } else {
        showNotifications('error', response.data.message, 5000);
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  const getConnectedGovernances = async () => {
    try {
      const response = await request(() => getControlsByGovernanceId(govId));
      if (response.data.statusCode === 200) {
        const connectedControls = response.data.body.controls.map(
          (control, idx) => {
            return {
              'S.No': idx + 1,
              _id: control['control']['_id'],
              ...control['control']['data'],
              data: control['data'],
              Action: 'Delete'
            };
          }
        );
        let oldConnectedData = {};
        for (const control of connectedControls) {
          oldConnectedData[control._id] = control.data;
        }
        setControlData(oldConnectedData);
        setConnectedData(connectedControls);
        showNotifications('success', response.data.message, 5000);
      } else {
        showNotifications('error', response.data.message, 5000);
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  const getGovernaceList = async () => {
    try {
      const response = await request(() => getAllGovernances());
      if (response.data.statusCode === 200) {
        const govNameList = response.data.body.map((govData) => {
          return {
            value: govData._id,
            label: govData['name']
          };
        });
        setGovNames([emptyList, ...govNameList]);
        showNotifications('success', response.data.message, 5000);
      } else {
        showNotifications('error', response.data.message, 5000);
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  const handleDeleteControl = async (idx) => {
    try {
      const filteredList = connectedData.filter(
        (_, controlIdx) => idx !== controlIdx
      );
      let filteredConnection = {};
      for (const control of filteredList) {
        filteredConnection[control._id] = control.data;
      }
      const allControlIds = Object.keys(filteredConnection).map((control) => {
        return {
          controlId: control,
          data: controlData[control]
        };
      });
      const payload = {
        connect: allControlIds
      };
      const response = await request(() =>
        connectGovernaceToControls(govId, payload)
      );
      if (response.data.statusCode === 200) {
        setConnectedData(filteredList);
        showNotifications('success', response.data.message, 5000);
      } else {
        showNotifications('error', response.data.message, 5000);
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  const handleSave = async () => {
    try {
      const allControlIds = Object.keys(controlData).map((control) => {
        return {
          controlId: control,
          data: controlData[control]
        };
      });
      const payload = {
        connect: allControlIds
      };
      const response = await request(() =>
        connectGovernaceToControls(govId, payload)
      );
      if (response.data.statusCode === 200) {
        await getConnectedGovernances();
        showNotifications('success', response.data.message, 5000);
      } else {
        showNotifications('error', response.data.message, 5000);
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  useEffect(() => {
    getControlsList();
    getGovernaceList();
  }, []);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
    } else {
      getConnectedGovernances();
    }
  }, [govId]);

  return (
    <React.Fragment>
      <Box
        style={{
          marginBottom: '25px',
          display: 'flex',
          justifyContent: 'space-between'
        }}
      >
        <Button
          sx={{
            color: 'white',
            backgroundColor: 'black',
            paddingLeft: 1
          }}
          variant="contained"
          onClick={() => navigate(`/policy`)}
        >
          <ChevronLeftIcon />
          Back
        </Button>
      </Box>
      <Box
        style={{
          marginBottom: '25px',
          display: 'flex',
          justifyContent: 'space-between'
        }}
      >
        <Typography sx={{ fontWeight: 'bold' }} variant="h4">
          Link Governance To Controls
        </Typography>
        <Box
          style={{
            marginBottom: '25px',
            display: 'flex',
            justifyContent: 'flex-end'
          }}
        >
          <Button
            variant="contained"
            sx={{
              marginTop: 2,
              marginRight: 2,
              color: 'white',
              background: 'black'
            }}
            onClick={handleOpenAddGovernanceModal}
          >
            Create Governance
          </Button>
          <Button
            color="success"
            variant="contained"
            sx={{
              marginTop: 2,
              marginRight: 2
            }}
            onClick={handleSave}
          >
            Save
          </Button>
        </Box>
      </Box>

      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          gap: '10px',
          marginBottom: '25px'
        }}
      >
        <FormControl fullWidth variant="standard">
          <InputLabel
            variant="standard"
            shrink={true}
            htmlFor="uncontrolled-native"
          >
            Select Governance
          </InputLabel>
          <NativeSelect
            value={govId}
            onChange={(e) => setGovId(e.target.value)}
            input={<BootstrapInput />}
            inputProps={{
              name: 'govId',
              id: 'uncontrolled-native',
              shrink: true
            }}
          >
            {govNames.length > 0 &&
              govNames.map((gov) => (
                <option key={gov.value} value={gov.value}>
                  {gov.label}
                </option>
              ))}
          </NativeSelect>
        </FormControl>
      </Box>

      {connectedData.length > 0 ? (
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            marginBottom: '15px'
          }}
        >
          <Box
            style={{
              display: 'flex',
              justifyContent: 'flex-start'
            }}
          >
            {tableOpen ? (
              <KeyboardArrowDownIcon
                style={{ marginTop: '5px' }}
                onClick={() => setTableOpen(!tableOpen)}
              />
            ) : (
              <KeyboardArrowRightIcon
                style={{ marginTop: '5px' }}
                onClick={() => setTableOpen(!tableOpen)}
              />
            )}
            <Typography sx={{ marginBottom: '20px' }} variant="h5">
              Linked Controls
            </Typography>
          </Box>
          <Collapse in={tableOpen}>
            <TemplateTable
              data={connectedData}
              deleteCB={handleDeleteControl}
              widthStyle={{ minWidth: '200px' }}
            />
          </Collapse>
        </Box>
      ) : govId === null ? null : (
        <Box style={{ display: 'flex', justifyContent: 'center' }}>
          <Typography sx={{ fontWeight: 'bold', color: 'Red' }} variant="h5">
            No Controls Linked To This Governance
          </Typography>
        </Box>
      )}

      <ControlForm
        formData={screenWise}
        heading="Add Controls"
        fieldOptions={controlNames}
        saveFields={handleChange}
      />

      <Modal
        open={openAddGovernanceModal}
        onClose={handleCloseAddGovernanceModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <CreateEditDialog
          formData={addGovernanceForm}
          onCloseCB={handleCloseAddGovernanceModal}
          refreshPageContentCB={getGovernaceList}
          entityName="Governance"
          createEntityRequest={createGovernance}
        />
      </Modal>
    </React.Fragment>
  );
};

export default SCF;
