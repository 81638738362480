import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Modal,
  Typography,
  MenuItem,
  TextField
} from '@mui/material';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import { useLoading } from '../../../../hooks/useLoading';
import { useNotifications } from '../../../../hooks/useNotifications';
import {
  findGapISOById,
  findGapByProperties,
  updateGapISOById,
  findAllLibraryType
} from '../../../../constants/requests';
import CustomGrid from '../../common/CustomGrid';
import CreateEditDialog from '../../common/CreateEditDialog';
import DeleteDialog from '../../common/DeleteDialog';
import UploadDialog from './uploadGap';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

const GapIso = () => {
  const navigate = useNavigate();
  const [gapData, setgapData] = useState(null);

  const [gapId, setId] = useState(null);
  const { request } = useLoading();
  const { showNotifications } = useNotifications();
  const [currentGapAssessmentTabName, setcurrentGapAssessmentTabName] =
    useState('');
  const [delRow, setDelRow] = useState(null);
  const [otherScreenFields, setOtherScreenFields] = useState('');
  const [metaData, setMetaData] = useState({});

  const [addRecordForm, setAddRecordForm] = useState([]);
  const [openAddRecordModal, setOpenAddRecordModal] = useState(false);
  const [openDeleteRecordModal, setOpenDeleteRecordModal] = useState(false);

  const [openUploadModal, setOpenUploadModal] = useState(false);
  const handleOpenUploadModal = () => setOpenUploadModal(true);
  const handleCloseUploadModal = () => setOpenUploadModal(false);

  //const handleOpenAddRecordModal = () => setOpenAddRecordModal(true);
  const handleCloseAddRecordModal = () => setOpenAddRecordModal(false);
  const handleOpenDeleteRecordModal = (row) => {
    setDelRow(row);
    setOpenDeleteRecordModal(true);
  };
  const handleCloseDeleteRecordModal = () => {
    setDelRow(null);
    setOpenDeleteRecordModal(false);
  };

  const handleOpenAddRecordModal = () => createAddRecordForm();

  const createAddRecordForm = () => {
    //console.log(gapData.data);
    const { meta, rows } = gapData.data;
    //console.log(meta);
    const { GapTracker, SOA, fields } = meta;
    //console.log(GapTracker);

    if ('GapTracker' === currentGapAssessmentTabName) {
      const recordForm = Object.keys(GapTracker).map((key) => {
        return {
          type: fields[key]['fieldType'],
          fieldName: key,
          fieldLabel: `Enter ${key}`,
          value:
            key === 'S.No' ? rows[currentGapAssessmentTabName].length + 1 : '',
          required: false,
          dropDownOptions: fields[key]?.['options']
        };
      });
      const otherForm = Object.keys(fields).map((key) => {
        return {
          type: fields[key]['fieldType'],
          fieldName: key,
          fieldLabel: `Enter ${key}`,
          value: '',
          required: false,
          dropDownOptions: fields[key]?.['options']
        };
      });

      setAddRecordForm(recordForm);
      setOtherScreenFields(otherForm);
      setOpenAddRecordModal(true);
    } else if ('SOA' === currentGapAssessmentTabName) {
      const recordForm = Object.keys(SOA).map((key) => {
        return {
          type: fields[key]['fieldType'],
          fieldName: key,
          fieldLabel: `Enter ${key}`,
          value:
            key === 'S.No' ? rows[currentGapAssessmentTabName].length + 1 : '',
          required: false,
          dropDownOptions: fields[key]?.['options']
        };
      });
      const otherForm = Object.keys(fields).map((key) => {
        return {
          type: fields[key]['fieldType'],
          fieldName: key,
          fieldLabel: `Enter ${key}`,
          value: '',
          required: false,
          dropDownOptions: fields[key]?.['options']
        };
      });

      setAddRecordForm(recordForm);
      setOtherScreenFields(otherForm);
      setOpenAddRecordModal(true);
    }
  };

  const getGapAssessmentById = async (gapId) => {
    try {
      const res = await request(() => findGapISOById(gapId));
      //console.log(res.data.body.data.meta);
      //console.log(res.data.body.data.rows);

      if (res.data.statusCode === 200) {
        setgapData(res.data.body);
        setcurrentGapAssessmentTabName(Object.keys(res.data.body.data.rows)[0]);
        setMetaData(gapData.data.meta[currentGapAssessmentTabName]);

        showNotifications('success', res.data.message, 5000);
      } else {
        showNotifications('error', res.data.message, 5000);
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  const getGapAssessment = async () => {
    try {
      const res = await request(() => findGapByProperties());
      console.log(res);
      if (res.data.statusCode === 200) {
        getGapAssessmentById(res.data.body[0]._id);
        setId(res.data.body[0]._id);
        showNotifications('success', res.data.message, 5000);
      } else {
        showNotifications('error', res.data.message, 5000);
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  const handleSave = async () => {
    try {
      const payload = gapData;
      console.log(payload);
      const res = await request(() => updateGapISOById(gapId, payload));
      if (res.data.statusCode === 200) {
        setgapData(gapData);
        //console.log(gapData);
        showNotifications('success', res.data.message, 5000);
      } else {
        showNotifications('error', res.data.message, 5000);
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  const handleAddRecord = async (newRecord) => {
    try {
      const screenData = {},
        otherScreenData = {};
      Object.keys(newRecord).map((record) => {
        screenData[record] = newRecord[record]['value'];
      });

      otherScreenFields.map((row) => {
        otherScreenData[row['FieldName']] = '';
      });
      const newData = { ...otherScreenData, ...screenData };

      const newgapData = { ...gapData };

      let newRows = newgapData['data']['rows'][currentGapAssessmentTabName];
      console.log(newRows);
      newRows = [...newRows, newData];
      newgapData['data']['rows'][currentGapAssessmentTabName] = newRows;
      const payload = newgapData;

      let response = await request(() => updateGapISOById(gapId, payload));

      if (response.data.statusCode === 200) {
        setgapData(newgapData);
        handleCloseAddRecordModal();
        showNotifications('success', response.data.message, 5000);
      } else {
        showNotifications('error', response.data.message, 5000);
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
      console.log(err);
    }
  };

  const handleDeleteRecord = async () => {
    try {
      const filteredData = gapData['data']['rows'][
        currentGapAssessmentTabName
      ].filter((row) => JSON.stringify(row) !== JSON.stringify(delRow));

      const newgapData = { ...gapData };
      gapData['data']['rows'][currentGapAssessmentTabName] = filteredData;
      const payload = newgapData;

      const response = await request(() => updateGapISOById(gapId, payload));

      if (response.data.statusCode === 200) {
        setgapData(newgapData);
        handleCloseDeleteRecordModal();
        showNotifications('success', response.data.message, 5000);
      } else {
        showNotifications('error', response.data.message, 5000);
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
      console.log(err);
    }
  };

  const handleRowUpdate = (newRow, oldRow) => {
    const newAssetRows = [...gapData.data.rows[currentGapAssessmentTabName]];

    newAssetRows.forEach((element, index) => {
      if (JSON.stringify(oldRow) === JSON.stringify(element)) {
        //console.log('index ' + index + ' element ' + JSON.stringify(element));
        newAssetRows[index] = newRow;
      }
    });
    const newgapData = { ...gapData };
    newgapData.data.rows[currentGapAssessmentTabName] = newAssetRows;

    setgapData(newgapData);
  };

  const handleUploadSave = async (gap) => {
    try {
      const payload = {
        data: gap
      };

      let response = await request(() => updateGapISOById(gapId, payload));
      if (response.data.statusCode === 200) {
        showNotifications('success', response.data.message, 5000);
      } else {
        showNotifications('error', response.data.message, 5000);
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
      console.log(err);
    }
  };

  const handleClick = () => {
    navigate(-1);
  };

  //to map control no with library control no
  const loadApplication = async () => {
    try {
      getControlData();

      console.log(data.data);
      console.log(metaData);
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
      console.log(err);
    }
  };

  const [data, setData] = useState([]);

  const getControlData = async () => {
    try {
      const dta = ['control'];
      const response = await request(() => findAllLibraryType(dta[0]));
      setData(response.data.body);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    loadApplication();
    getGapAssessment();
  }, []);

  return (
    <React.Fragment>
      <Box
        style={{
          marginBottom: '25px',
          display: 'flex',
          justifyContent: 'space-between'
        }}
      ></Box>
      <Button
        sx={{
          color: 'white',
          backgroundColor: 'black',
          flexDirection: 'end',
          marginBottom: '20px'
        }}
        variant="contained"
        onClick={handleClick}
      >
        <ChevronLeftIcon />
        UnderStanding
      </Button>
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          gap: '10px',
          marginBottom: '10px'
        }}
      >
        <Typography sx={{ fontWeight: 'bold' }} variant="h4">
          Gap Assessment
        </Typography>
      </Box>

      <Box style={{ marginBottom: '40px' }}>
        <Box
          style={{
            display: 'flex',
            gap: '10px',
            marginBottom: '10px',
            justifyContent: 'flex-end',
            alignItems: 'flex-end'
          }}
        >
          <Button
            sx={{
              color: 'white',
              backgroundColor: 'black'
            }}
            variant="contained"
            onClick={handleOpenAddRecordModal}
            disabled={gapData === null}
          >
            Add Record
          </Button>
          <Button
            color="success"
            sx={{ alignContent: 'space-between' }}
            variant="contained"
            disabled={gapData === null}
            onClick={handleOpenUploadModal}
          >
            Upload Gap Assessment
          </Button>

          <Button
            sx={{
              color: 'white',
              backgroundColor: '#ff5722',
              alignContent: 'space-between'
            }}
            variant="contained"
            disabled={gapData === null}
            onClick={() => {
              navigate(`/services/assetrepository`);
            }}
          >
            Connect AssetRepository
          </Button>
        </Box>

        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            margin: '40px 0px 10px'
          }}
        ></Box>

        <Box
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginBottom: '30px',
            gap: '10px'
          }}
        >
          {gapData && (
            <TextField
              disabled={gapData === null}
              select
              label="Tab Name"
              value={currentGapAssessmentTabName}
              onChange={(e) => setcurrentGapAssessmentTabName(e.target.value)}
              style={{ minWidth: '200px' }}
              size="small"
              InputLabelProps={{ shrink: true }}
            >
              {gapData.data.rows &&
                Object.keys(gapData.data.rows)?.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
            </TextField>
          )}
          <Button
            variant="contained"
            color="success"
            onClick={handleSave}
            disabled={gapData === null}
          >
            Save
          </Button>
        </Box>
      </Box>

      <TabContext value={currentGapAssessmentTabName}>
        {gapData ? (
          Object.keys(gapData.data.rows).map((AssetTab) => {
            return (
              <TabPanel
                key={`Asset Table: ${AssetTab}`}
                value={AssetTab}
                index={AssetTab}
                sx={{ p: '0px' }}
              >
                <CustomGrid
                  tabName={AssetTab}
                  rows={gapData.data.rows[AssetTab]}
                  fieldsMeta={gapData.data.meta.fields}
                  tableMeta={gapData.data.meta[AssetTab]}
                  handleChangeCB={handleRowUpdate}
                  deleteCB={handleOpenDeleteRecordModal}
                />
              </TabPanel>
            );
          })
        ) : (
          <Box style={{ display: 'flex', justifyContent: 'center' }}>
            <Typography sx={{ fontWeight: 'bold', color: 'Red' }} variant="h5">
              No Data Available to Track
            </Typography>
          </Box>
        )}
      </TabContext>

      {/* Update Gap Assessment Modal */}
      <Modal
        open={openAddRecordModal}
        onClose={handleCloseAddRecordModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <CreateEditDialog
          formData={addRecordForm}
          onCloseCB={handleCloseAddRecordModal}
          refreshPageContentCB={getGapAssessment}
          entityName="Record"
          customUpdateCB={handleAddRecord}
        />
      </Modal>
      <Modal
        open={openDeleteRecordModal}
        onClose={handleCloseDeleteRecordModal}
      >
        <DeleteDialog
          entityName={`Record`}
          customDeleteCB={handleDeleteRecord}
          onCloseCB={handleCloseDeleteRecordModal}
        />
      </Modal>
      {/*upload Gap Assessment */}
      <Modal
        open={openUploadModal}
        onClose={handleCloseUploadModal}
        aria-labelledby="modal-upload-Gap Assessment"
        aria-describedby="modal-upload-Gap Assessment-description"
      >
        <UploadDialog
          name="UPLOAD GAP ASSESSMENT / SOA"
          onCloseCB={handleCloseUploadModal}
          gapData={gapData}
          handleUploadSave={handleUploadSave}
          refreshPageContentCB={getGapAssessment}
        />
      </Modal>
    </React.Fragment>
  );
};

export default GapIso;
