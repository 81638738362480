import React, { useEffect, useState } from 'react';
import Modal from '@mui/material/Modal';
import { useLoading } from '../../../../hooks/useLoading';
import { useNotifications } from '../../../../hooks/useNotifications';
import { getEngagementPolicyById } from '../../../../constants/requests';
import { updateEngagementPolicyById_Table } from '../../../../constants/requests';
import { styled } from '@mui/material/styles';
const { v4: uuidv4 } = require('uuid');
import TextFieldRow from './TextFieldRow';

import {
  Box,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  tableCellClasses
} from '@mui/material';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  bgcolor: 'white',
  boxShadow: 24,
  borderRadius: '10px',
  p: 4
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    fontSize: '18px',
    fontWeight: 520
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
    height: '50px'
  }
}));

export default function PolicyScopeModal({ name, policyId }) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { request } = useLoading();
  const { showNotifications } = useNotifications();
  const scopeTypes = ['Preparer', 'Reviewer', 'Approver'];
  const [policyScopeData, setPolicyScopeData] = useState({
    aumyaaApprover: {
      name: '',
      email: '',
      role: ''
    },
    aumyaaPreparer: {
      name: '',
      email: '',
      role: ''
    },
    aumyaaReviewer: {
      name: '',
      email: '',
      role: ''
    },
    clientApprover: {
      name: '',
      email: '',
      role: ''
    },
    clientPreparer: {
      name: '',
      email: '',
      role: ''
    },
    clientReviewer: {
      name: '',
      email: '',
      role: ''
    }
  });

  const updatePolicyScopeDataHandler = (type, name, email, role) => {
    const tempObj = { name, email, role };
    const newPolicyScopeData = { ...policyScopeData };
    newPolicyScopeData[type] = tempObj;
    setPolicyScopeData(newPolicyScopeData);
  };

  const isEmailValid = (email) => {
    if (email === '') return true;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const updatePolicyDetailsHandler = async () => {
    try {
      //validations
      const isEmailFormatValidForAll = (policyScopeData) => {
        for (const key in policyScopeData) {
          if (!isEmailValid(policyScopeData[key].email)) {
            return false;
          }
        }
        return true;
      };
      if (!isEmailFormatValidForAll(policyScopeData)) {
        showNotifications('error', 'Please fix the format for email.', 5000);
        return;
      }

      const response = await request(() =>
        updateEngagementPolicyById_Table(policyId, policyScopeData)
      );
      if (response.data.statusCode === 200) {
        showNotifications('success', 'Policy Scope updated successfully', 5000);
      } else {
        showNotifications('error', response.data.message, 5000);
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  const getPolicyDetails = async () => {
    try {
      const response = await request(() => getEngagementPolicyById(policyId));
      if (response.data.statusCode === 200) {
        const newPolicyScopeData = {};
        Object.keys(policyScopeData).forEach((type) => {
          newPolicyScopeData[type] = null;
          if (response.data.body[type] == null) {
            newPolicyScopeData[type] = policyScopeData[type];
          } else {
            newPolicyScopeData[type] = response.data.body[type];
          }
        });
        setPolicyScopeData(newPolicyScopeData);
      } else {
        showNotifications('error', response.data.message, 5000);
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  useEffect(() => {
    getPolicyDetails();
    policyScopeData;
  }, []);

  return (
    <div>
      <Button
        variant="contained"
        sx={{ marginRight: '20px' }}
        onClick={handleOpen}
      >
        {name}
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby={`${name}-spoc-button`}
      >
        {/* <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        {name}
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        Hello World
                    </Typography>
                </Box> */}
        <Box sx={style}>
          <TableContainer sx={{ overflow: 'auto' }}>
            <Table
              sx={{
                border: 2,
                borderColor: 'rgba(224, 224, 224, 1)',
                marginTop: '5px'
              }}
            >
              <TableHead>
                <TableRow>
                  <StyledTableCell sx={{ minWidth: '90px' }}></StyledTableCell>
                  <StyledTableCell
                    sx={{ minWidth: '300px', textAlign: 'center' }}
                  >
                    {name}
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {scopeTypes.map((type, idx) => {
                  return (
                    <TableRow key={`${type}-row`}>
                      <StyledTableCell
                        key={`${type}-row-cell-${idx}-1`}
                        sx={{
                          backgroundColor: 'grey',
                          color: 'white',
                          textAlign: 'center'
                        }}
                      >
                        <Box sx={{ fontSize: '15px', fontWeight: 500 }}>
                          {type}
                        </Box>
                      </StyledTableCell>
                      <StyledTableCell
                        key={`${type}-row-cell-${idx}-2`}
                        sx={{ padding: 1 }}
                      >
                        <TextFieldRow
                          key={uuidv4()}
                          type={`${name}${type}`}
                          data={policyScopeData[`${name}${type}`]}
                          onChangeCB={updatePolicyScopeDataHandler}
                        />
                      </StyledTableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <Button
            sx={{
              marginTop: '20px',
              height: '40px',
              float: 'right'
            }}
            variant="contained"
            onClick={updatePolicyDetailsHandler}
          >
            Save
          </Button>
        </Box>
      </Modal>
    </div>
  );
}
