import React, { useState } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet
} from 'react-router-dom';
import { Snackbar, Alert, Box } from '@mui/material';
import { useLoading } from '../hooks/useLoading';
import { useNotifications } from '../hooks/useNotifications';
import { LoadingOverlay } from '../components/LoadingOverlay';
import { Home } from '../pages/Home';
import { Sidebar } from '../components/Sidebar/';
import { Navbar } from '../components/Navbar';
import { styled } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { MasterEmployee } from '../pages/Master/Employee';
import { MasterProject } from '../pages/Master/Project';
import SignUp from '../pages/SignUp/SignUp';
import SignIn from '../pages/SignIn/SignIn';
import AddEmployee from '../pages/AddEmployee/AddEmployee';
import AddClient from '../pages/AddClient/AddClient';
import { MasterTask } from '../pages/Master/Task';
import { MasterTimesheet } from '../pages/Master/Timesheet';
import { MasterClient } from '../pages/Master/Client';
import { MasterHoliday } from '../pages/Master/Holiday';
import PrivateRoute from './PrivateRoute';
import { Timesheet } from '../pages/Master/TimesheetView';
import { CreateTimesheet } from '../pages/Master/TimesheetView/CreateTimesheet';
import UpdatePassword from '../pages/PasswordConfig/UpdatePassword';
import ResetPassword from '../pages/PasswordConfig/ResetPassword';
import EntityData from '../components/Master/EntityData';
import { MasterLogs } from '../pages/Master/DRLLogs';
import { ProfileRights } from '../pages/Master/ProfileRights';
import { BroadRoles } from '../pages/Master/BroadRoles';
import { MasterRoles } from '../pages/Master/Roles';
import { MasterDepartment } from '../pages/Master/Department';
import AllTasks from '../pages/WorkflowNotification/AllTasks';
import Engagements from '../pages/Services/Engagement';
import Understanding from '../pages/Services/Understanding';
import RCM from '../pages/Services/RCM';
import Testing from '../pages/Services/Testing';
import AIT from '../pages/Services/AIT';
import ActiveWorkflow from '../components/ActiveWorkflow';
import DRL from '../pages/Services/DRL';
import MLP from '../pages/Services/MLP';
import DownloadDocuments from '../pages/Services/DownloadDocuments';
import NotFound from '../pages/Services/NotFound/NotFound';
import Sampling from '../pages/Services/Sampling';
import TemplateHome from '../pages/TemplateEditor/TemplateHome';
import TemplateCreator from '../pages/TemplateEditor/TemplateCreator';
import DocumentCreator from '../pages/TemplateEditor/DocumentCreator';
import Evidences from '../pages/Services/Evidences';
import PolicyTemplates from '../pages/Policies/PolicyTemplate/PolicyTemplates';
import AddPolicyTemplate from '../pages/Policies/PolicyTemplate/AddPolicyTemplate';
import EditPolicyTemplate from '../pages/Policies/PolicyTemplate/EditPolicyTemplate';
import FillPolicyTemplate from '../pages/Policies/PolicyTemplate/FillPolicyTemplate';
import PolicyEngagements from '../pages/Policies/PolicyEngagement/PolicyEngagements';
import AddPolicyEngagement from '../pages/Policies/PolicyEngagement/AddPolicyEngagement';
import AllEngagementPolicies from '../pages/Policies/Policy/AllEngagementPolicies';
import FillEngagementPolicy from '../pages/Policies/Policy/FillEngagementPolicy';
import PolicyMaster from '../pages/Policies/Master/PolicyMaster';
import AddPolicyTemplateVersion from '../pages/Policies/PolicyTemplate/AddPolicyTemplateVersion';
import EditPolicyEngagement from '../pages/Policies/PolicyEngagement/EditPolicyEngagement';
import GapTracker from '../pages/GapTracker';
import WorkflowHandlerIndividual from '../components/WorkflowHandler/WorkflowHandlerIndividual';
import WorkflowTypeIndividual from '../components/WorkflowType/WorkflowTypeIndividual';
import WorkflowType from '../pages/Master/WorkflowType/index';
import WorkflowHandler from '../pages/Master/WorkflowHandler/index';
import WorkflowTypeForm from '../pages/WorkflowType/WorkflowTypeForm';
import FieldAndServiceCreator from '../pages/FieldAndServiceCreator';
import ConnectControlsToPolicyTemplate from '../pages/Policies/PolicyTemplate/ConnectControlsToPolicyTemplate';
/*Asset Repository*/
import SCF from '../pages/SCF';
import AssetRepository from '../pages/Services/AssetRepository';
import RiskRepository from '../pages/Services/RiskRegister';
import GapAssesment from '../pages/Services/GapAssesment';
import CSA from '../pages/Services/CSA';
import POF from '../pages/Services/POF';
import OrgTable from '../pages/Services/OrgTable';
import ConvertPPT from '../pages/Services/ConvertPPT';
// import PolicyScope from '../pages/Policies/Policy/PolicyScope';
import TrustServiceCriteria from '../pages/Services/TrustServiceCriteria';
import Section3 from '../pages/Services/Section3';
import CUEC from '../pages/Services/CUEC-CSOC';
import Threat from '../pages/Services/ThreatRegister';
import ThreatVulnerability from '../pages/Services/ThreatRegister/ThreatVulnerablility';
//import Gap_Tracker from '../pages/Services/AssetRepository/Gap_Tracker';
import LibraryMaster from '../pages/Services/AssetRepository/LibraryMaster';
import InternalAudit from '../pages/Services/AssetRepository/Internal_Audit';
import GapIso from '../pages/Services/AssetRepository/Gap_Assessment';
import SOAIso from '../pages/Services/AssetRepository/SOA';
//General Utility Routes
import ExcelToPPT from '../pages/GeneralUtility/ExcelToPPT';

import { OfferLetter } from '../pages/Master/OfferLetter';
import PDFToExcel from '../pages/GeneralUtility/PDFToExcel';

export const Routers = () => {
  const { isLoading } = useLoading();
  const { isOpen, type, message, duration, handleClose } = useNotifications();

  return (
    <Router>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={isOpen}
        autoHideDuration={duration}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={type} sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
      <LoadingOverlay isLoading={isLoading} />
      <Switches />
    </Router>
  );
};

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    minWidth: '0',
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen
      }),
      marginLeft: 0
    })
  })
);

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end'
}));

export default function PersistentDrawerLeft() {
  const [open, setOpen] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Navbar open={open} handleDrawerOpen={handleDrawerOpen} />
      <Sidebar open={open} handleDrawerClose={handleDrawerClose} />
      <Main open={open}>
        <DrawerHeader />
        <Outlet />
      </Main>
    </Box>
  );
}

const Switches = () => {
  return (
    <Routes>
      <Route path="/signin" exact element={<SignIn />} />
      <Route path="/signup" exact element={<SignUp />} />
      <Route path="/password/reset" exact element={<ResetPassword />} />
      <Route element={<PersistentDrawerLeft />}>
        <Route
          path="/"
          exact
          element={
            <PrivateRoute>
              <AllTasks />
              <Timesheet createTimeSheetVisiblity={false} />
            </PrivateRoute>
          }
        />
        <Route
          path="/home"
          exact
          element={
            <PrivateRoute>
              <Home />
            </PrivateRoute>
          }
        />
        <Route
          path="/master/register/employee"
          exact
          element={
            <PrivateRoute>
              <AddEmployee />
            </PrivateRoute>
          }
        />
        <Route
          path="password/update"
          element={
            <PrivateRoute>
              <UpdatePassword />
            </PrivateRoute>
          }
        />
        <Route path="workflow">
          <Route
            path="type"
            exact
            element={
              <PrivateRoute>
                <WorkflowType />
              </PrivateRoute>
            }
          />
          <Route
            path="type/add"
            exact
            element={
              <PrivateRoute>
                <WorkflowTypeForm />
              </PrivateRoute>
            }
          />
          <Route
            path="handlers"
            exact
            element={
              <PrivateRoute>
                <WorkflowHandler />
              </PrivateRoute>
            }
          />
          <Route
            path="approvals"
            exact
            element={
              <PrivateRoute>
                <AllTasks />
              </PrivateRoute>
            }
          />
          <Route
            path="approvals/:id"
            exact
            element={
              <PrivateRoute>
                <ActiveWorkflow />
              </PrivateRoute>
            }
          />
          <Route
            path="handler/:id"
            exact
            element={
              <PrivateRoute>
                <WorkflowHandlerIndividual />
              </PrivateRoute>
            }
          />
          <Route
            path="type/:id"
            exact
            element={
              <PrivateRoute>
                <WorkflowTypeIndividual />
              </PrivateRoute>
            }
          />
        </Route>
        <Route path="/master">
          <Route
            path="data"
            exact
            element={
              <PrivateRoute>
                <EntityData />
              </PrivateRoute>
            }
          />
          <Route
            path="logs"
            element={
              <PrivateRoute>
                <MasterLogs />
              </PrivateRoute>
            }
          />
          <Route
            path="employees"
            element={
              <PrivateRoute>
                <MasterEmployee />
              </PrivateRoute>
            }
          />
          <Route
            path="holidays"
            element={
              <PrivateRoute>
                <MasterHoliday />
              </PrivateRoute>
            }
          />
          <Route
            path="offerLetter"
            element={
              <PrivateRoute>
                <OfferLetter />
              </PrivateRoute>
            }
          />
          <Route
            path="clients"
            element={
              <PrivateRoute>
                <MasterClient />
              </PrivateRoute>
            }
          />
          <Route
            path="logs"
            element={
              <PrivateRoute>
                <MasterLogs />
              </PrivateRoute>
            }
          />
          <Route
            path="roles"
            element={
              <PrivateRoute>
                <MasterRoles />
              </PrivateRoute>
            }
          />
          <Route
            path="profileRights"
            element={
              <PrivateRoute>
                <ProfileRights />
              </PrivateRoute>
            }
          />
          <Route
            path="broadRoles"
            element={
              <PrivateRoute>
                <BroadRoles />
              </PrivateRoute>
            }
          />
          <Route
            path="departments"
            element={
              <PrivateRoute>
                <MasterDepartment />
              </PrivateRoute>
            }
          />
          <Route path="projects">
            <Route
              path=""
              element={
                <PrivateRoute>
                  <MasterProject />
                </PrivateRoute>
              }
            />
            <Route path=":projectName">
              <Route
                path=""
                element={
                  <PrivateRoute>
                    <MasterTask />
                  </PrivateRoute>
                }
              />
              <Route
                path=":taskName"
                element={
                  <PrivateRoute>
                    <MasterTimesheet />
                  </PrivateRoute>
                }
              />
            </Route>
          </Route>

          <Route path="timesheets">
            <Route
              path=""
              element={
                <PrivateRoute>
                  <Timesheet createTimeSheetVisiblity={true} />
                </PrivateRoute>
              }
            />
            <Route
              path="add"
              element={
                <PrivateRoute>
                  <CreateTimesheet />
                </PrivateRoute>
              }
            />
          </Route>
          <Route
            path="register/client"
            element={
              <PrivateRoute>
                <AddClient />
              </PrivateRoute>
            }
          />
        </Route>
        <Route path="404">
          <Route
            path=""
            element={
              <PrivateRoute>
                <NotFound />
              </PrivateRoute>
            }
          />
        </Route>
        <Route path="services">
          <Route
            path="engagement"
            element={
              <PrivateRoute>
                <Engagements />
              </PrivateRoute>
            }
          />
          <Route
            path="engagement/:engagementId/understanding"
            element={
              <PrivateRoute>
                <Understanding />
              </PrivateRoute>
            }
          />

          <Route
            path="engagement/:engagementId/orgTable/:orgTableId"
            element={
              <PrivateRoute>
                <OrgTable />
              </PrivateRoute>
            }
          />
          <Route
            path="engagement/:engagementId/trustServiceCriteria/:trustServiceId"
            element={
              <PrivateRoute>
                <TrustServiceCriteria />
              </PrivateRoute>
            }
          />
          <Route
            path="engagement/:engagementId/mlp"
            element={
              <PrivateRoute>
                <MLP />
              </PrivateRoute>
            }
          />

          <Route
            path="engagement/:engagementId/application/:applicationId/csa"
            element={
              <PrivateRoute>
                <CSA />
              </PrivateRoute>
            }
          />
          <Route
            path="engagement/:engagementId/application/:applicationId/rcm"
            element={
              <PrivateRoute>
                <RCM />
              </PrivateRoute>
            }
          />

          <Route
            path="engagement/:engagementId/application/:applicationId/rcm/:rcmId/pof"
            element={
              <PrivateRoute>
                <POF />
              </PrivateRoute>
            }
          />
          <Route
            path="engagement/:engagementId/application/:applicationId/rcm/:rcmId/section3"
            element={
              <PrivateRoute>
                <Section3 />
              </PrivateRoute>
            }
          />
          <Route
            path="engagement/:engagementId/application/:applicationId/rcm/:rcmId/cuec-csoc"
            element={
              <PrivateRoute>
                <CUEC />
              </PrivateRoute>
            }
          />
          <Route
            path="engagement/:engagementId/application/:applicationId/drl/:drlId/sampling"
            element={
              <PrivateRoute>
                <Sampling />
              </PrivateRoute>
            }
          />
          <Route
            path="engagement/:engagementId/application/:applicationId/evidence/:sampleId"
            element={
              <PrivateRoute>
                <Evidences />
              </PrivateRoute>
            }
          />
          <Route
            path="engagement/:engagementId/application/:applicationId/testing"
            element={
              <PrivateRoute>
                <Testing />
              </PrivateRoute>
            }
          />

          <Route
            path="engagement/:engagementId/application/:applicationId/drl"
            element={
              <PrivateRoute>
                <DRL />
              </PrivateRoute>
            }
          />

          <Route
            path="engagement/:engagementId/download-documents"
            element={
              <PrivateRoute>
                <DownloadDocuments />
              </PrivateRoute>
            }
          />
          <Route
            path="engagement/:engagementId/convertppt"
            element={
              <PrivateRoute>
                <ConvertPPT />
              </PrivateRoute>
            }
          />
          <Route
            path="engagement/:engagementId/ait"
            element={
              <PrivateRoute>
                <AIT />
              </PrivateRoute>
            }
          />

          {/*asset Repository*/}
          <Route path="assetrepository" element={<AssetRepository />} />
          {/*Threat */}
          <Route
            path=":currentAssetTabName/:assetId/threat"
            element={<Threat />}
          />
          {/*Threat Vulnerability */}
          <Route
            path="asset/:currentAssetTabName/:assetId/threat/:ThreatId/threatVul"
            element={<ThreatVulnerability />}
          />
          <Route
            path="asset/:currentAssetTabName/:assetId/threatVul/:ThreatvulId/risk"
            element={<RiskRepository />}
          />
          <Route path="libraryMasterIso" element={<LibraryMaster />} />
          {/*Internal Audit*/}
          <Route path="internalAudit" element={<InternalAudit />} />
          {/*asset gap assessment*/}
          <Route path="gapassessmentIso" element={<GapIso />} />
          <Route path="soaIso" element={<SOAIso />} />

          {/*
          <Route
            path="engagement/:engagementId/gapassesment"
            element={
              <PrivateRoute>
                <Gap_Tracker />
              </PrivateRoute>
            }
          />
          */}
        </Route>

        <Route path="general-utility">
          <Route
            path="excel-to-ppt"
            element={
              <PrivateRoute>
                <ExcelToPPT />
              </PrivateRoute>
            }
          />
          <Route
            path="pdf-to-excel"
            element={
              <PrivateRoute>
                <PDFToExcel />
              </PrivateRoute>
            }
          />
        </Route>

        <Route path="gapTracker">
          <Route
            path="gapiso/:gapId"
            element={
              <PrivateRoute>
                <GapAssesment />
              </PrivateRoute>
            }
          />
        </Route>
        <Route path="fieldAndServiceCreator">
          <Route
            path=""
            element={
              <PrivateRoute>
                <FieldAndServiceCreator />
              </PrivateRoute>
            }
          />
        </Route>

        <Route path="templateEditor">
          <Route
            path=""
            element={
              <PrivateRoute>
                <TemplateHome />
              </PrivateRoute>
            }
          />
          <Route
            path="createTemplate/:templateId"
            element={
              <PrivateRoute>
                <TemplateCreator />
              </PrivateRoute>
            }
          />
          <Route
            path="addDocument/:documentId"
            element={
              <PrivateRoute>
                <DocumentCreator />
              </PrivateRoute>
            }
          />
        </Route>
        <Route path="policy">
          <Route
            path=""
            element={
              <PrivateRoute>
                <PolicyTemplates />
              </PrivateRoute>
            }
          />
          <Route
            path="add"
            element={
              <PrivateRoute>
                <AddPolicyTemplate />
              </PrivateRoute>
            }
          />
          <Route
            path="master"
            element={
              <PrivateRoute>
                <PolicyMaster />
              </PrivateRoute>
            }
          />
          <Route
            path="edit/:id"
            element={
              <PrivateRoute>
                <EditPolicyTemplate />
              </PrivateRoute>
            }
          />
          <Route
            path="version/:id"
            element={
              <PrivateRoute>
                <AddPolicyTemplateVersion />
              </PrivateRoute>
            }
          />
          <Route
            path="fill/:id"
            element={
              <PrivateRoute>
                <FillPolicyTemplate />
              </PrivateRoute>
            }
          />
          <Route
            path="addControls/:policyTemplateId"
            element={
              <PrivateRoute>
                <ConnectControlsToPolicyTemplate />
              </PrivateRoute>
            }
          />
          <Route
            path="engagement"
            element={
              <PrivateRoute>
                <PolicyEngagements />
              </PrivateRoute>
            }
          />
          <Route
            path="engagement/add"
            element={
              <PrivateRoute>
                <AddPolicyEngagement />
              </PrivateRoute>
            }
          />
          <Route
            path="engagement/:id/edit"
            element={
              <PrivateRoute>
                <EditPolicyEngagement />
              </PrivateRoute>
            }
          />
          <Route
            path="engagement/:id"
            element={
              <PrivateRoute>
                <AllEngagementPolicies />
              </PrivateRoute>
            }
          />
          <Route
            path="engagement/policy/:id/:policyid"
            element={
              <PrivateRoute>
                <FillEngagementPolicy />
              </PrivateRoute>
            }
          />
        </Route>
        <Route path="gapTracker">
          <Route
            path="gap/:gapId"
            element={
              <PrivateRoute>
                <GapTracker />
              </PrivateRoute>
            }
          />
        </Route>
        <Route path="scf">
          <Route
            path=""
            element={
              <PrivateRoute>
                <SCF />
              </PrivateRoute>
            }
          />
        </Route>
      </Route>
    </Routes>
  );
};
