import React, { useEffect, useState } from 'react';
import {
  TextField,
  Button,
  Chip,
  Box,
  Typography,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material';
import { useLoading } from '../../../hooks/useLoading';
import {
  createDepartment,
  deleteDepartmentById,
  findDepartmentByProperties
} from '../../../constants/requests';
import { useNotifications } from '../../../hooks/useNotifications';

const DepartmentForm = () => {
  const [department, setDepartment] = useState('');
  const [tags, setTags] = useState([]);
  const [tagInput, setTagInput] = useState('');
  const { request } = useLoading();
  const [departmentData, setDepartmentData] = useState([]);
  const { showNotifications } = useNotifications();

  const handleDepartmentChange = (event) => {
    setDepartment(event.target.value);
  };

  const handleTagInputChange = (event) => {
    setTagInput(event.target.value);
  };

  const handleAddTag = () => {
    if (tagInput.trim() !== '') {
      setTags([...tags, tagInput.trim()]);
      setTagInput('');
    }
  };

  const handleRemoveTag = (tag) => {
    setTags(tags.filter((t) => t !== tag));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const data = {
        name: department,
        tags: tags
      };

      const response = await request(() => createDepartment(data));

      if (response.data.statusCode == 201 || response.data.statusCode == 200) {
        console.log(response.data);

        showNotifications('success', response.data.message, 5000);
      } else {
        showNotifications('error', response.data.message, 5000);
      }
    } catch (error) {
      console.error(error);
      showNotifications('error', error.toString(), 5000);
    }
  };

  const getDepartment = async () => {
    try {
      const res = await request(() => findDepartmentByProperties());
      console.log(res);

      if (res.data.statusCode === 200) {
        setDepartmentData(res.data.body);
        showNotifications('success', res.data.message, 5000);
      } else {
        showNotifications('error', res.data.message, 5000);
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  const handleDelete = async (delId) => {
    try {
      const response = await request(() => deleteDepartmentById(delId));
      // Handle successful deletion
      if (response.data.statusCode === 200) {
        getDepartment();
        console.log(response.data);
        showNotifications('success', response.data.message, 5000);
      } else {
        showNotifications('error', response.data.message, 5000);
      }
    } catch (err) {
      console.log(err);
      showNotifications('error', err.toString(), 5000);
      // Handle error
    }
  };

  console.log(departmentData);

  useEffect(() => {
    getDepartment();
  }, []);

  return (
    <>
      <Box>
        <Typography sx={{ fontWeight: 'bold' }} variant="h4">
          Add Companies and Departments
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            label="Company"
            value={department}
            onChange={handleDepartmentChange}
            variant="outlined"
            margin="normal"
            fullWidth
            required
          />

          <TextField
            label="Department"
            value={tagInput}
            onChange={handleTagInputChange}
            variant="outlined"
            margin="normal"
            fullWidth
          />

          <Button variant="contained" color="primary" onClick={handleAddTag}>
            Add Tag
          </Button>

          <div style={{ marginTop: 10 }}>
            {tags.map((tag) => (
              <Chip
                key={tag}
                label={tag}
                onDelete={() => handleRemoveTag(tag)}
                style={{ marginRight: 5, marginTop: 5 }}
              />
            ))}
          </div>

          <Button
            type="submit"
            variant="contained"
            color="primary"
            style={{ marginTop: 10 }}
          >
            Submit
          </Button>
        </form>
      </Box>

      <Typography variant="h4">Department List</Typography>
      <Box>
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Companies</TableCell>
                <TableCell>Departments</TableCell>
                <TableCell>Actions</TableCell>
                {/* Add more table headers if needed */}
              </TableRow>
            </TableHead>
            <TableBody>
              {departmentData.map((item) => (
                <TableRow
                  key={item._id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {item.name}
                  </TableCell>

                  <TableCell component="th" scope="row">
                    {item.tags.join(', ')}
                  </TableCell>

                  <TableCell>
                    <Box
                      style={{
                        display: 'flex',
                        marginTop: '2px'
                      }}
                    >
                      <Button
                        variant="contained"
                        sx={{
                          justifyContent: 'space-between',
                          backgroundColor: 'black'
                        }}
                        onClick={() => handleDelete(item._id)}
                      >
                        Delete
                      </Button>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
};

export default DepartmentForm;
