import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import {
  Box,
  Button,
  MenuItem,
  Tab,
  Table,
  TableRow,
  TableCell,
  tableCellClasses,
  TableContainer,
  TextField,
  Typography,
  Modal
} from '@mui/material';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Paper from '@mui/material/Paper';
import moment from 'moment';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { styled } from '@mui/material/styles';
import { useLoading } from '../../../hooks/useLoading';
import { useNotifications } from '../../../hooks/useNotifications';
// import { stickyColumnTemplateMapping } from '../common/stickyColumnTemplateMapping';
import GetTableCell from '../common/GetTableCell';
import CustomGrid from '../common/CustomGrid';
import { style } from './styles';
import {
  getApplicationByIdRequest,
  getEngagementByIdRequest,
  // getEngagementByIdRequest,
  getRCMByIdRequest,
  updateRCMRequest,
  // getServiceById,
  getGapIdFromSOC2EngagementId,
  updateGapTrackerByGapId,
  getServiceById,
  downloadTestingSheet
} from '../../../constants/requests';
import Navbar from '../common/Navbar';
import FileDownload from 'js-file-download';
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  paddingTop: '0px',
  paddingBottom: '0px',
  paddingRight: '5px',
  paddingLeft: '5px',
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14
  },
  outline: 'none',
  border: 'none'
}));

const StyledTableRow = styled(TableRow)(() => ({
  paddingTop: '0px',
  paddingBottom: '0px',
  margin: '0px',
  '&:last-child td, &:last-child th': {
    border: 0
  },
  border: '0px solid black',
  outline: 'none'
}));

const testingTabObject = {
  'Test Results': ['Test Procedure', 'Test Performed', 'Observation', 'Result'],
  'Additional Procedures / Mitigating Factors': [
    'Additional Test Procedure',
    'Additional Test Performed',
    'Additional Observation',
    'Additional Conclusion'
  ],
  'Overall Conclusion': [
    'Test Procedure',
    'Test Performed',
    'Observation',
    'Result'
  ]
};

const SOC2testingTabObject = {
  'Design Effectiveness': [
    'Test Procedures',
    'WP Reference',
    'Design & Compliance Testing',
    'Design  Effectiveness'
  ],
  'Operating Table': [
    'Test Procedures',
    'Control No.',
    'Operational Effectiveness Testing',
    'Operating Effectiveness'
  ],
  'Overall Conclusion': [
    'Test Procedures',
    'Control Activity',
    'Overall Conclusion (Effective / Ineffective)'
  ]
};

const tabStyle = {
  outline: 'none',
  overflow: 'auto',
  whiteSpace: 'nowrap'
};

const tableBoxStyle = {
  display: 'flex',
  flexDirection: 'Column',
  justifyContent: 'center',
  marginBottom: '30px',
  border: '2px solid black',
  borderRadius: '3px'
};

const tableHeaderStyle = {
  textAlign: 'center',
  backgroundColor: 'orange',
  color: 'white',
  fontSize: '20px',
  fontWeight: 'bold',
  borderTopLeftRadius: '3px',
  borderTopRightRadius: '3px',
  borderBottom: '2px solid black'
};
/**
 * @author Tejas Sharma, Aditya Teltia
 * The following options are for testing sheet type
 * 1. Mazar
 * 2. BDO
 *
 * For Mazar:
 * Only tab based sheets are created in the Testing Screen
 * The excel template can have multiple tabs
 * TestingTabSheet dropdown is not used in this case
 *
 * For BDO:
 * Seperate Testing sheets are created for each and every row of the Template
 * The fields which needs to be shown in the Testing sheet are selected from the TestingTabSheet dropdown
 * In this case, an upload button across the Testing Dropdown is enabled where the user can upload the testing sheet template
 * This template will be used to create the testing sheets while downloading
 */

export default function Testing() {
  const { showNotifications } = useNotifications();
  const { request } = useLoading();
  const navigate = useNavigate();
  const { applicationId, engagementId } = useParams();
  const [applicationName, setApplicationName] = useState('null');
  const [value, setValue] = useState('1');
  const [RCMData, setRCMData] = useState(null);
  const [RCMId, setRCMId] = useState(null);
  const [type, setType] = useState(null);
  const [socScope, setSocScope] = useState(null);
  const [gapTracker, setGapTracker] = useState(null);
  const [testingSheetType, setTestingSheetType] = useState(null);
  const [dynamicTestingTabObject, setDynamicTestingTabObject] = useState(null);
  const [openDownloadModal, setOpenDownloadModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const getRCM = async () => {
    try {
      let response = await request(() =>
        getApplicationByIdRequest(applicationId)
      );
      if (response.data.statusCode === 200) {
        const RCMId = response.data.body.RCMId?._id;
        setApplicationName(response.data.body.applicationName);
        if (!RCMId) {
          // RCM not created yet
          setRCMData(null);
          return;
        }
        response = await request(() => getRCMByIdRequest(RCMId));
        // console.log(response.data.body.data.meta.TestingSheetTab);
        setDynamicTestingTabObject({
          'Test Procedure': response.data.body.data.meta.TestingSheetTab
        });
        setTestingSheetType(response.data.body.testingSheetType);
        setRCMData(response.data.body.data);
        setRCMId(response.data.body._id);
        // console.log(response.data.body.templateType);
        setType(response.data.body.templateType);

        response = await request(() => getEngagementByIdRequest(engagementId));

        const res = await request(() =>
          getServiceById(response.data.body.serviceId)
        );
        if (res.data.body.name === 'SOC2.0') {
          setSocScope(response.data.body.understandingId.socScope);
          const gapTrackerResponse = await request(() =>
            getGapIdFromSOC2EngagementId(engagementId)
          );
          setGapTracker(gapTrackerResponse.data.body);
        }
      } else {
        showNotifications('error', response.data.message, 5000);
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
      console.log(err);
    }
  };
  const getExtension = (contentType) => {
    switch (contentType) {
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        return 'xlsx';
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        return 'docx';
      default:
        return 'xlsx';
    }
  };
  const handleDownloadClose = () => {
    setOpenDownloadModal(false);
    setSelectedFile(null);
    setErrorMessage(null);
  };
  const getFormattedDate = () => {
    return moment().format('D_MMM_YYYY_h_mm_ss_a');
  };
  const handleDownload = async () => {
    if (selectedFile === null) {
      setErrorMessage('Please select a file');
      return;
    }
    const formData = new FormData();
    formData.append('file', selectedFile);
    formData.append('data', JSON.stringify(RCMData.rows));
    try {
      const response = await request(() => downloadTestingSheet(formData));
      if (response?.response?.data?.statusCode) {
        showNotifications('error', response.response.data.message, 5000);
      } else {
        FileDownload(
          response.data,
          `testing_${getFormattedDate()}.${getExtension(
            response.headers['content-type']
          )}`
        );
        showNotifications('success', 'Downloaded successfully', 5000);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleChangeRCM = (rowNumber, fieldName, cellValue, table) => {
    const modifiedRCMData = { ...RCMData };
    modifiedRCMData.rows[table][rowNumber][fieldName] = cellValue;
    setRCMData(modifiedRCMData);
  };

  const handleChangeRow = (newRow, oldRow, RCMTab) => {
    const newRCMRows = [...RCMData['rows'][RCMTab]];
    // console.log(dynamicTestingTabObject);
    newRCMRows.forEach((element, index) => {
      if (JSON.stringify(oldRow) === JSON.stringify(element)) {
        newRCMRows[index] = newRow;
      }
    });
    const newRCMData = { ...RCMData };
    newRCMData['rows'][RCMTab] = newRCMRows;
    setRCMData(newRCMData);
  };
  const handleSave = async () => {
    try {
      let NewRcmData = { ...RCMData };

      if (
        (type === 'SOC2.0' || type === 'SOC1.0' || type === 'ISAE 3402') &&
        (socScope === 'Readiness SOC1' || socScope === 'Readiness SOC2')
      ) {
        const gapTrackerMeta = gapTracker.data.meta;
        let gapTrackerRows = gapTracker.data.rows['GapTracker'];
        const gapId = gapTracker['_id'];

        RCMData['rows']['Master Table'].map((row, index) => {
          if (
            row['goToGapTracker'] &&
            row['goToGapTracker'] === 'No' &&
            (row['Operating Effectiveness'] === 'Ineffective' ||
              row['Design  Effectiveness'] === 'Ineffective')
          ) {
            gapTrackerRows.push({
              'S.No': gapTrackerRows.length + 1,
              Process: '',
              'Subprocess (Domain)': '',
              Area: '',
              'Process  owner': '',
              'Aumyaa SPOC Name': '',
              Category: '',
              'SOP (Policy Reference)': '',
              'Date on which discussed': '',
              'SCF Number': '',
              'Control Objective': '',
              'Control Number': ``,
              'Risk Number': '',
              Risk: '',
              'CA Number': `${row['Control No.']}`,
              'Control Activity': `${row['Control Activity']}`,
              'Policy Statement': '',
              'Brief Description of Deficiency (Observation)': `${row['Observation']}`,
              Impact: '',
              Recommendation: '',
              'Action plan': '',
              'Expected Date of Closure(Timeline)': '',
              'Current Process / Under Automation': '',
              'Process Owner Name': '',
              'Process Owner Role': '',
              'Process  Owner Email': '',
              'Control Owner Name': '',
              'Control Owner Role': '',
              'Control Owner Email': '',
              'Reliant (Yes/No)': '',
              'Evidence annexure': '',
              'Status (Open/Closed/Drop)': 'Open',
              'Actual Date of closure': '',
              RCMId: `${RCMId}`
            });

            NewRcmData['rows']['Master Table'][index]['goToGapTracker'] = 'Yes';
          }
        });

        const gapTrackerPayload = {
          data: {
            meta: gapTrackerMeta,
            rows: {
              GapTracker: gapTrackerRows
            }
          }
        };

        let response = await request(() =>
          updateGapTrackerByGapId(gapTrackerPayload, gapId)
        );
        if (response.data.statusCode === 200) {
          showNotifications('success', response.data.message, 5000);
        } else {
          showNotifications('error', response.data.message, 5000);
        }
      }
      const payload = {
        data: NewRcmData
      };

      const res = await request(() => updateRCMRequest(RCMId, payload));
      if (res.data.statusCode === 200) {
        setRCMData(NewRcmData);
        showNotifications('success', res.data.message, 5000);
      } else {
        showNotifications('error', res.data.message, 5000);
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
      console.log(err);
    }
  };

  useEffect(() => {
    getRCM();
  }, []);

  return (
    <>
      <Navbar
        pageName="TESTING"
        engagementId={engagementId}
        applicationId={applicationId}
      />
      <Box>
        {(type === 'SOC2.0' || type === 'SOC1.0' || type === 'ISAE 3402') &&
          (socScope === 'Readiness SOC1' || socScope === 'Readiness SOC2') && (
            <Button
              sx={{
                color: 'white',
                backgroundColor: 'black',
                float: 'right'
              }}
              variant="contained"
              onClick={() => navigate(`/gapTracker/gap/${gapTracker['_id']}`)}
            >
              Gap Tracker
              <ChevronRightIcon />
            </Button>
          )}
      </Box>
      <TabContext value={value}>
        <Box style={{ display: 'none' }}>
          <TabList aria-label="lab API tabs example">
            {RCMData
              ? Object.keys(RCMData['rows']).map((table, idx) => {
                  return (
                    <Tab
                      key={`Tab Number ${idx + 1}`}
                      style={tabStyle}
                      label={table}
                      value={`${idx + 1}`}
                      onClick={() => {
                        setValue(`${idx + 1}`);
                      }}
                    />
                  );
                })
              : null}

            {RCMData
              ? type === 'BDO_SAP' ||
                type === 'Template B1' ||
                type === 'BDO_GENERIC' ||
                type === 'Template B2' ||
                type === 'SOC2.0' ||
                type === 'SOC1.0' ||
                type === 'ISAE 3402'
                ? RCMData.rows['Master Table'].map((row, idx) => {
                    return (
                      <Tab
                        key={`Tab Number ${idx + 2}`}
                        style={tabStyle}
                        label={`${idx + 1}`}
                        value={`${idx + 2}`}
                        onClick={() => {
                          setValue(`${idx + 2}`);
                        }}
                      />
                    );
                  })
                : null
              : null}
          </TabList>
        </Box>
        <Box
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: '10px 0px 10px'
          }}
        >
          <TextField
            variant="standard"
            size="small"
            value={value}
            style={{
              backgroundColor: 'white',
              color: 'rgba(0, 0, 0, 0.38)',
              outline: 'none',
              borderRadius: '2px',
              marginRight: '10px',
              fontSize: '15px',
              padding: '5px',
              minWidth: '50px',
              textAlign: 'center'
            }}
            select
          >
            {RCMData
              ? Object.keys(RCMData.rows).map((table, idx) => {
                  return (
                    <MenuItem
                      key={`dropdown item number ${idx + 1}`}
                      value={`${idx + 1}`}
                      onClick={() => {
                        setValue(`${idx + 1}`);
                      }}
                    >
                      {table}
                    </MenuItem>
                  );
                })
              : null}
            {RCMData
              ? type === 'BDO_SAP' ||
                type === 'Template B1' ||
                type === 'Template B2' ||
                type === 'BDO_GENERIC' ||
                type === 'SOC2.0' ||
                type === 'SOC1.0' ||
                type === 'ISAE 3402' ||
                testingSheetType === 'BDO'
                ? RCMData.rows['Master Table'].map((row, index) => {
                    return (
                      <MenuItem
                        key={`dropdown item number ${index}`}
                        value={`${index + 2}`}
                        onClick={() => {
                          setValue(`${index + 2}`);
                        }}
                      >
                        Sheet {index + 1}
                      </MenuItem>
                    );
                  })
                : null
              : null}
          </TextField>
        </Box>
        {RCMData
          ? Object.keys(RCMData.rows).map((table, index1) => {
              return (
                <TabPanel
                  value={`${index1 + 1}`}
                  index={index1 + 1}
                  key={`Tab-data-${index1 + 1}`}
                >
                  <Box
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      marginBottom: '30px'
                    }}
                  >
                    <TextField
                      name="applicationName"
                      label="Application Name"
                      disabled
                      value={applicationName}
                    />
                    <Button
                      color="success"
                      variant="contained"
                      onClick={handleSave}
                    >
                      Save
                    </Button>
                  </Box>
                  {RCMData ? (
                    <CustomGrid
                      tabName={table}
                      rows={RCMData.rows[table]}
                      tableMeta={RCMData.meta.Testing}
                      handleChangeCB={handleChangeRow}
                      fieldsMeta={RCMData.meta.fields}
                    />
                  ) : null}
                </TabPanel>
              );
            })
          : null}

        {RCMData
          ? type === 'BDO_SAP' ||
            type === 'BDO_GENERIC' ||
            type === 'SOC2.0' ||
            type === 'SOC1.0' ||
            type === 'ISAE 3402' ||
            type === 'Template B1' ||
            type === 'Template B2' ||
            testingSheetType === 'BDO'
            ? RCMData.rows['Master Table'].map((row, idx) => {
                return (
                  <TabPanel
                    value={`${idx + 2}`}
                    index={`${idx + 2}`}
                    key={`Testing Sheet - ${idx + 2}`}
                  >
                    <Box
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        marginBottom: '30px'
                      }}
                    >
                      <Button
                        color="success"
                        variant="contained"
                        onClick={handleSave}
                      >
                        Save
                      </Button>
                    </Box>
                    {testingSheetType === 'BDO'
                      ? Object.keys(dynamicTestingTabObject).map(
                          (tableName, index) => {
                            // console.log(tableName, dynamicTestingTabObject);
                            return (
                              <>
                                <Box style={tableBoxStyle}>
                                  <Box style={tableHeaderStyle}>
                                    {tableName}
                                  </Box>
                                  <TableContainer component={Paper}>
                                    <Table
                                      sx={{
                                        minWidth: 700
                                      }}
                                      aria-label="customized table"
                                      style={{
                                        marginBottom: '10px'
                                      }}
                                    >
                                      {Object.keys(
                                        dynamicTestingTabObject[tableName]
                                      ).map((key) => {
                                        // console.log(key);
                                        return (
                                          <StyledTableRow
                                            hover
                                            key={`RCM-${tableName}-${idx}-${index}-${key}`}
                                          >
                                            <StyledTableCell
                                              style={{
                                                minWidth: '50px',
                                                minHeight: '0px'
                                              }}
                                              align="left"
                                            >
                                              <TextField
                                                // variant = "standard"
                                                fullWidth
                                                multiline
                                                defaultValue={
                                                  dynamicTestingTabObject[
                                                    tableName
                                                  ][key].displayName
                                                }
                                                disabled={true}
                                                style={{
                                                  outline: 'none',
                                                  marginBottom: '7px',
                                                  marginTop: '3px'
                                                }}
                                                // disableUnderline={true}
                                              ></TextField>
                                            </StyledTableCell>
                                            <StyledTableCell
                                              style={{
                                                minWidth: '300px',
                                                minHeight: '0px'
                                              }}
                                              align="left"
                                            >
                                              <GetTableCell
                                                key={`rcm-cell-${idx}-${key}`}
                                                rowNumber={idx}
                                                fieldName={key}
                                                fieldType={
                                                  RCMData.meta.fields[key]
                                                    .fieldType === undefined
                                                    ? 'multiLineTextField'
                                                    : RCMData.meta.fields[key]
                                                        .fieldType
                                                }
                                                // tableName={'Master Table'}
                                                // value={row[key]}
                                                value={
                                                  RCMData['rows'][
                                                    'Master Table'
                                                  ][idx][key]
                                                }
                                                onChangeCB={(
                                                  rowNumber,
                                                  fieldName,
                                                  cellValue
                                                ) =>
                                                  handleChangeRCM(
                                                    rowNumber,
                                                    fieldName,
                                                    cellValue,
                                                    'Master Table'
                                                  )
                                                }
                                                isEditable={
                                                  dynamicTestingTabObject[
                                                    tableName
                                                  ][key].isEditable
                                                }
                                                options={
                                                  RCMData.meta.fields[key]
                                                    .options === undefined
                                                    ? {}
                                                    : RCMData.meta.fields[key]
                                                        .options
                                                }
                                                styles={{
                                                  padding: '10px',
                                                  margin: '5px',
                                                  borderRadius: '5px'
                                                }}
                                              />
                                            </StyledTableCell>
                                          </StyledTableRow>
                                        );
                                      })}
                                    </Table>
                                  </TableContainer>
                                </Box>
                              </>
                            );
                          }
                        )
                      : type !== 'SOC2.0' &&
                        type !== 'SOC1.0' &&
                        type !== 'ISAE 3402'
                      ? Object.keys(testingTabObject).map(
                          (tableName, index) => {
                            return (
                              <>
                                <Box style={tableBoxStyle}>
                                  <Box style={tableHeaderStyle}>
                                    {tableName}
                                  </Box>
                                  <TableContainer component={Paper}>
                                    <Table
                                      sx={{
                                        minWidth: 700
                                      }}
                                      aria-label="customized table"
                                      style={{
                                        marginBottom: '10px'
                                      }}
                                    >
                                      {testingTabObject[tableName].map(
                                        (key) => {
                                          return (
                                            <StyledTableRow
                                              hover
                                              key={`RCM-${tableName}-${idx}-${index}-${key}`}
                                            >
                                              <StyledTableCell
                                                style={{
                                                  minWidth: '50px',
                                                  minHeight: '0px'
                                                }}
                                                align="left"
                                              >
                                                <TextField
                                                  // variant = "standard"
                                                  fullWidth
                                                  multiline
                                                  defaultValue={
                                                    RCMData.meta
                                                      .TestingSheetTab[key]
                                                      .displayName
                                                  }
                                                  disabled={true}
                                                  style={{
                                                    outline: 'none',
                                                    marginBottom: '7px',
                                                    marginTop: '3px'
                                                  }}
                                                  // disableUnderline={true}
                                                ></TextField>
                                              </StyledTableCell>
                                              <StyledTableCell
                                                style={{
                                                  minWidth: '300px',
                                                  minHeight: '0px'
                                                }}
                                                align="left"
                                              >
                                                <GetTableCell
                                                  key={`rcm-cell-${idx}-${key}`}
                                                  rowNumber={idx}
                                                  fieldName={key}
                                                  fieldType={
                                                    RCMData.meta.fields[key]
                                                      .fieldType === undefined
                                                      ? 'multiLineTextField'
                                                      : RCMData.meta.fields[key]
                                                          .fieldType
                                                  }
                                                  // tableName={'Master Table'}
                                                  // value={row[key]}
                                                  value={
                                                    key === 'Result' &&
                                                    tableName ===
                                                      'Overall Conclusion'
                                                      ? row[
                                                          `Additional Conclusion`
                                                        ] !== 'N/A'
                                                        ? row[
                                                            `Additional Conclusion`
                                                          ]
                                                        : row[key]
                                                      : tableName ===
                                                          'Overall Conclusion' &&
                                                        key !== 'Result'
                                                      ? row[key].concat(
                                                          '\n\n',
                                                          row[
                                                            `Additional ${key}`
                                                          ]
                                                        )
                                                      : row[key]
                                                  }
                                                  onChangeCB={(
                                                    rowNumber,
                                                    fieldName,
                                                    cellValue
                                                  ) =>
                                                    handleChangeRCM(
                                                      rowNumber,
                                                      fieldName,
                                                      cellValue,
                                                      'Master Table'
                                                    )
                                                  }
                                                  isEditable={
                                                    tableName ===
                                                    'Overall Conclusion'
                                                      ? false
                                                      : true
                                                  }
                                                  options={
                                                    RCMData.meta.fields[key]
                                                      .options
                                                  }
                                                  styles={{
                                                    padding: '10px',
                                                    margin: '5px',
                                                    borderRadius: '5px'
                                                  }}
                                                />
                                              </StyledTableCell>
                                            </StyledTableRow>
                                          );
                                        }
                                      )}
                                    </Table>
                                  </TableContainer>
                                </Box>
                              </>
                            );
                          }
                        )
                      : Object.keys(SOC2testingTabObject).map(
                          (tableName, index) => {
                            return (
                              <>
                                <Box style={tableBoxStyle}>
                                  <Box style={tableHeaderStyle}>
                                    {tableName}
                                  </Box>
                                  <TableContainer component={Paper}>
                                    <Table
                                      sx={{
                                        minWidth: 700
                                      }}
                                      aria-label="customized table"
                                      style={{
                                        marginBottom: '10px'
                                      }}
                                    >
                                      {SOC2testingTabObject[tableName].map(
                                        (key) => {
                                          return (
                                            <StyledTableRow
                                              hover
                                              key={`RCM-${tableName}-${idx}-${index}-${key}`}
                                            >
                                              <StyledTableCell
                                                style={{
                                                  minWidth: '50px',
                                                  minHeight: '0px'
                                                }}
                                                align="left"
                                              >
                                                <TextField
                                                  // variant = "standard"
                                                  fullWidth
                                                  multiline
                                                  defaultValue={
                                                    RCMData.meta
                                                      .TestingSheetTab[key]
                                                      .displayName
                                                  }
                                                  disabled={true}
                                                  style={{
                                                    outline: 'none',
                                                    marginBottom: '7px',
                                                    marginTop: '3px'
                                                  }}
                                                  // disableUnderline={true}
                                                ></TextField>
                                              </StyledTableCell>
                                              <StyledTableCell
                                                style={{
                                                  minWidth: '300px',
                                                  minHeight: '0px'
                                                }}
                                                align="left"
                                              >
                                                <GetTableCell
                                                  key={`rcm-cell-${idx}-${key}`}
                                                  rowNumber={idx}
                                                  fieldName={key}
                                                  fieldType={
                                                    RCMData.meta.fields[key]
                                                      .fieldType === undefined
                                                      ? 'multiLineTextField'
                                                      : RCMData.meta.fields[key]
                                                          .fieldType
                                                  }
                                                  value={
                                                    tableName ===
                                                      'Overall Conclusion' &&
                                                    key ===
                                                      'Overall Conclusion (Effective / Ineffective)'
                                                      ? row[
                                                          'Operating Effectiveness'
                                                        ] === 'Ineffective'
                                                        ? 'Ineffective'
                                                        : row[
                                                            'Design  Effectiveness'
                                                          ]
                                                      : row[key]
                                                  }
                                                  onChangeCB={(
                                                    rowNumber,
                                                    fieldName,
                                                    cellValue
                                                  ) =>
                                                    handleChangeRCM(
                                                      rowNumber,
                                                      fieldName,
                                                      cellValue,
                                                      'Master Table'
                                                    )
                                                  }
                                                  isEditable={
                                                    (tableName ===
                                                      'Overall Conclusion' &&
                                                      (key ===
                                                        'Test Procedures' ||
                                                        key ===
                                                          'Control Activity')) ||
                                                    (tableName ===
                                                      'Operating Table' &&
                                                      (key ===
                                                        'Test Procedures' ||
                                                        key === 'Control No.'))
                                                      ? false
                                                      : true
                                                  }
                                                  options={
                                                    RCMData.meta.fields[key]
                                                      .options
                                                  }
                                                  styles={{
                                                    padding: '10px',
                                                    margin: '5px',
                                                    borderRadius: '5px'
                                                  }}
                                                />
                                              </StyledTableCell>
                                            </StyledTableRow>
                                          );
                                        }
                                      )}
                                    </Table>
                                  </TableContainer>
                                </Box>
                              </>
                            );
                          }
                        )}
                    {testingSheetType !== 'BDO' ? (
                      <Box
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          margin: '10px'
                        }}
                      >
                        (
                        <>
                          <Typography sx={{ fontWeight: 'bold' }} variant="h6">
                            Work Paper Reference
                          </Typography>
                          <GetTableCell
                            key={`rcm-link-proof`}
                            rowNumber={idx}
                            fieldName="Work Paper Reference"
                            fieldType={
                              RCMData.meta.fields['Proofs Link'].fieldType
                            }
                            value={
                              RCMData['rows']['Master Table'][idx][
                                'Proofs Link'
                              ]
                            }
                            onChangeCB={(rowNumber, fieldName, cellValue) =>
                              handleChangeRCM(
                                rowNumber,
                                'Proofs Link',
                                cellValue,
                                'Master Table'
                              )
                            }
                            isEditable={true}
                            styles={{ marginLeft: '10px' }}
                          />
                        </>
                        )
                      </Box>
                    ) : null}
                  </TabPanel>
                );
              })
            : null
          : null}
        <Modal
          open={openDownloadModal}
          onClose={handleDownloadClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={style}
            style={{ width: '50%', height: '95%', overflowX: 'scroll' }}
          >
            <Box
              style={{
                marginBottom: '25px',
                display: 'flex',
                gap: '20px',
                flexDirection: 'column'
              }}
            >
              <Typography
                fullWidth
                id="modal-modal-title"
                variant="h5"
                component="h2"
                style={{ marginBottom: '10px' }}
              >
                Download Template
              </Typography>
              {errorMessage && (
                <Typography
                  variant="h5"
                  color="red"
                >{`Error: ${errorMessage}`}</Typography>
              )}
            </Box>
            <Box
              style={{
                display: 'flex',
                justifyContent: 'space-around',
                marginBottom: '40px'
              }}
            >
              <Typography variant="h5">Upload Template</Typography>
              <TextField
                type="file"
                name="file"
                label="Upload Master"
                onChange={(e) => setSelectedFile(e.target.files[0])}
                InputLabelProps={{ shrink: true }}
              />
            </Box>
            <Box
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: '30px'
              }}
            >
              <Button
                color="error"
                variant="contained"
                onClick={handleDownloadClose}
              >
                Close
              </Button>
              <Button variant="contained" onClick={handleDownload}>
                Download
              </Button>
            </Box>
          </Box>
        </Modal>
      </TabContext>
    </>
  );
}
