import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { useDataLayerValue } from '../../contextAPI/DataLayer';
import { BACKEND_URL } from '../../config';
import { Link } from 'react-router-dom';
import { useLoading } from '../../hooks/useLoading.js';
import {
  getEngagementByIdRequest,
  getEngagementPolicyById
} from '../../constants/requests';
import CustomTable from '../../pages/Services/common/CustomTable';

const stylesObj = {
  container: {
    backgroundColor: 'whitesmoke',
    boxShadow: '2px 2px 10px grey',
    marginTop: '30px',
    padding: '40px 60px'
  },
  keyval: {
    display: 'flex'
  },
  key: {
    marginRight: '10px',
    fontWeight: 'bolder'
  },
  count: {
    fontWeight: 'bolder',
    marginRight: '10px'
  },
  history: {
    marginTop: '10px',
    fontWeight: 'bolder'
  },
  historyContainer: {
    margin: '0px 60px'
  },
  marker: {
    position: 'relative',
    top: '40px',
    right: '50px'
  }
};

function ActiveWorkflow() {
  const { id } = useParams();
  const [workflowHandlerDetails, setWorkflowHandlerDetails] = useState(null);
  const [empName, setEmpName] = useState(null);
  const [{ _id }] = useDataLayerValue();
  const { request } = useLoading();
  const [initiatorEmployeeId, setInitiatorEmployeeId] = useState(null);

  const getWorkflowDetails = async () => {
    document.title = 'Active Workflow Information';
    try {
      let response;

      response = await request(() =>
        axios.get(BACKEND_URL + '/active-workflow/' + id, {
          headers: {
            employeeid: _id
          }
        })
      );

      const { workflowHandlerId } = response.data.body;

      response = await request(() =>
        axios.get(BACKEND_URL + '/workflow-handler/' + workflowHandlerId, {
          headers: {
            employeeid: _id
          }
        })
      );

      const { initiator: initiatorEmployeeId } = response.data.body.data;
      const employee = await request(() =>
        axios.get(BACKEND_URL + '/employee/status/' + initiatorEmployeeId)
      );

      console.log(response.data.body);

      setInitiatorEmployeeId(initiatorEmployeeId);
      setEmpName(`${employee.data.body.fname} ${employee.data.body.lname}`);
      setWorkflowHandlerDetails(response.data.body);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getWorkflowDetails();
  }, []);

  return (
    <div>
      <h3>Active Workflow Information</h3>

      <div style={stylesObj.container}>
        <span style={stylesObj.keyval}>
          <p style={stylesObj.key}>Employee ID of the Initiator: </p>
          <p>{initiatorEmployeeId}</p>
        </span>
        <span style={stylesObj.keyval}>
          <p style={stylesObj.key}>Employee Name of the Initiator: </p>
          <p>{empName}</p>
        </span>
        {workflowHandlerDetails && (
          <DisplayInformationForWhichApprovalWasRequested
            workflowHandlerDetails={workflowHandlerDetails}
          />
        )}
      </div>
    </div>
  );
}

function DisplayInformationForWhichApprovalWasRequested({
  workflowHandlerDetails
}) {
  const { request } = useLoading();

  const { data, endPoint } = workflowHandlerDetails;
  const { method, url } = endPoint;

  const [policy, setPolicy] = useState(null);
  const [engagement, setEngagement] = useState('');

  const getSCFTemplate = async () => {
    try {
      const policyId = data.RequestUrl.split('/')[2];

      const policyResponse = await request(() =>
        getEngagementPolicyById(policyId)
      );
      setPolicy(policyResponse.data.body);
    } catch (err) {
      console.log(err);
    }
  };

  const getEngagement = async () => {
    try {
      const engagementId = data.RequestUrl.split('/')[3];

      const engagementResponse = await request(() =>
        getEngagementByIdRequest(engagementId)
      );
      setEngagement(engagementResponse.data.body);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    switch (url) {
      case '/policy/:id': {
        getSCFTemplate();
        break;
      }
      case '/itgc/engagement/:id': {
        getEngagement();
        break;
      }
    }
  }, []);

  if (method === 'PATCH') {
    switch (url) {
      case '/itgc/engagement/:id': {
        const engagementId = data.RequestUrl.split('/')[3];
        return (
          <span style={stylesObj.keyval}>
            <p style={stylesObj.key}>Link:</p>
            <Link
              to={'/services/engagement/' + engagementId + '/understanding'}
            >
              <p>{engagement?.projectId?.projectName ?? ''}</p>
            </Link>
          </span>
        );
      }

      case '/policy/:id': {
        try {
          return (
            <>
              <span style={stylesObj.keyval}>
                <p style={stylesObj.key}>Policy Name:</p>
                <p>{policy.name}</p>
              </span>
              <span style={stylesObj.keyval}>
                <p style={stylesObj.key}>Policy Type:</p>
                <p>{policy.type}</p>
              </span>
              <span style={stylesObj.keyval}>
                <p style={stylesObj.key}>Aumyaa Preparer:</p>
                <p>{policy.aumyaaPreparer}</p>
              </span>
              <span style={stylesObj.keyval}>
                <p style={stylesObj.key}>Aumyaa Reviewer:</p>
                <p>{policy.aumyaaReviewer}</p>
              </span>
              <span style={stylesObj.keyval}>
                <p style={stylesObj.key}>Aumyaa Approver:</p>
                <p>{policy.aumyaaApprover}</p>
              </span>
              <span style={stylesObj.keyval}>
                <p style={stylesObj.key}>Client Preparer:</p>
                <p>{policy.clientPreparer}</p>
              </span>
              <span style={stylesObj.keyval}>
                <p style={stylesObj.key}>Client Reviewer:</p>
                <p>{policy.clientReviewer}</p>
              </span>
              <span style={stylesObj.keyval}>
                <p style={stylesObj.key}>Client Approver:</p>
                <p>{policy.clientApprover}</p>
              </span>
              <CustomTable
                fieldsMeta={policy.SCFTemplate.meta.fields}
                tableMeta={policy.SCFTemplate.meta.LastApprovedPolicyControls}
                rows={data.RequestBody.lastApprovedSCF.controls}
              />
            </>
          );
        } catch (err) {
          console.log(err);
        }
      }
    }
  }

  return null;
}

export default ActiveWorkflow;
