import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Container,
  Grid,
  Typography,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material";
import axios from "axios";
import { EMPLOYEE_URL } from "../../../constants/urls";
import { useNotifications } from "../../../hooks/useNotifications";
import { useLoading } from "../../../hooks/useLoading";
import SalaryStructure from "./SalaryStructure";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const templates = {
  template1: [
    "firstName",
    "lastName",
    "Probation_Period",
    "Reporting_Employee",
  ],
  template2: ["firstName", "lastName", "Reporting_Employee"],
  template3: ["firstName", "lastName"],
};

export const OfferLetter = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    salary: "",
    Salutation: "",
    Probation_Period: "",
    Reporting_Employee: "",
    Stipend: "",
  });

  const [selectedTemplate, setSelectedTemplate] = useState("");
  const [issuedBy, setIssuedBy] = useState("");
  const [formComplete, setFormComplete] = useState(false);
  const [requiredFields, setRequiredFields] = useState([]);
  const [buttonColor, setButtonColor] = useState("grey");
  const { showNotifications } = useNotifications();
  const { request, loading } = useLoading();
  const [salaryData, setSalaryData] = useState({});
  const [sigFile, setSigFile] = useState("");
  const [clearSalaryStructure, setClearSalaryStructure] = useState(false);
  const [issueDate, setIssueDate] = useState("");
  const [dateOfJoining, setDateOfJoining] = useState("");
  const [dateOfExit, setDateOfExit] = useState("");
  const [role, setRole] = useState("");
  const [newRole, setNewRole] = useState("");
  const [department, setDepartment] = useState("");
  const [newDepartment, setNewDepartment] = useState("");
  const [salutation, setSalutation] = useState("");
  const [reportingEmpDesignation, setReportingEmpDesignation] = useState("");
  const [issueByDesignation, setIssueByDesignation] = useState("");
  const [issueBySalutation, setIssueBySalutation] = useState("");
  const [stipend, setStipend] = useState("");
  const [email, setEmail] = useState("");
  const [errorEmail, setErrorEmail] = useState(false);
  const [roleError, setRoleError] = useState("");
  const [departmentError, setDepartmentError] = useState("");
  const [issuedByEmail, setIssuedByEmail] = useState("");

  useEffect(() => {
    setRequiredFields(templates[selectedTemplate] || []);
  }, [selectedTemplate]);

  useEffect(() => {
    const areAllFieldsFilled = requiredFields.every(
      (fieldName) => formData[fieldName] !== ""
    );

    let isTemplateSpecificFieldsFilled = true;
    let buttonColor = "grey";

    if (selectedTemplate === "template3") {
      isTemplateSpecificFieldsFilled =
        dateOfExit !== "" && stipend !== "" ;
    } else if (selectedTemplate === "template2") {
      isTemplateSpecificFieldsFilled =
        department !== "" && reportingEmpDesignation !== "";
    } else if (selectedTemplate === "template1") {
      isTemplateSpecificFieldsFilled =
        department !== "" && reportingEmpDesignation !== "" && stipend !== "";
    }

    const isFormComplete =
      areAllFieldsFilled &&
      issuedBy !== "" &&
      sigFile !== "" &&
      issueByDesignation !== "" &&
      issueBySalutation !== "" &&
      email !== "" &&
      issueDate !== "" &&
      dateOfJoining !== "" &&
      salutation !== "" &&
      role !== "" &&
      issuedByEmail !== "" &&
      isTemplateSpecificFieldsFilled;

    setFormComplete(isFormComplete);
    setButtonColor(isFormComplete ? "black" : "grey");
  }, [
    formData,
    requiredFields,
    selectedTemplate,
    issuedBy,
    sigFile,
    issueByDesignation,
    issueBySalutation,
    email,
    issueDate,
    dateOfExit,
    dateOfJoining,
    salutation,
    role,
    department,
    stipend,
    reportingEmpDesignation,
    issuedByEmail,
  ]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileSelection = async (e) => {
    const selectedFile = e.target.files[0];
    try {
      if (selectedFile) {
        const reader = new FileReader();
        reader.onloadend = () => {
          // The result property contains the base64 encoded image
          const base64String = reader.result.split(",")[1];
          setSigFile(base64String);
        };
        // Read the image file as a data URL
        reader.readAsDataURL(selectedFile);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSalaryStructureChange = (salaryStructureData) => {
    setSalaryData(salaryStructureData);
  };

  const handleClearSalaryStructure = () => {
    setClearSalaryStructure(!clearSalaryStructure);
  };

  const handleStipendChange = (e) => {
    const value = e.target.value;
    const formattedValue = value.replace(/[^0-9,.]/g, "");
    const isValid =
      /^\d{0,10}(,\d{10})*(\.\d{0,10})?$/.test(formattedValue) ||
      formattedValue === "";
    setStipend(formattedValue);
  };

  const handleEmailChange = (e) => {
    const value = e.target.value;
    const isValid = /^[^\s@]+@[^\s@]+\.[^\s@]+(\.[a-zA-Z]{2,})?$/.test(value); // Basic email format validation
    setErrorEmail(!isValid);
  };

  const handleRoleChange = (e) => {
    const { name, value } = e.target;

    // Values that newRole should not match
    const roleValues = [
      "Associate",
      "Assistant Manager",
      "Deputy Manager",
      "Senior Manager",
    ];

    // Validation for newRole
    if (name === "newRole" && roleValues.includes(value)) {
      setRoleError("New role cannot be one of the specific values.");
    } else {
      setRoleError("");
      setNewRole(value);
    }
  };

  const handleDepartmentChange = (e) => {
    const { name, value } = e.target;

    // Values new Department should not match with
    const departmentValues = [
      "Operations",
      "Risk Advisory",
      "IT Risk Management",
      "Sales & Marketing",
    ];

    //validation for new Department
    if (name === "newDepartment" && departmentValues.includes(value)) {
      setDepartmentError(
        "New Department cannot be one of the specific values."
      );
    } else {
      setDepartmentError("");
      setNewDepartment(value);
    }
  };

  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    let month = today.getMonth() + 1;
    let day = today.getDate();

    month = month < 10 ? `0${month}` : month;
    day = day < 10 ? `0${day}` : day;

    return `${year}-${month}-${day}`;
  };

  const handleFormSubmit = async (e, action) => {
    e.preventDefault();

    try {
      if (
        (selectedTemplate === "template1" ||
          selectedTemplate === "template2") &&
        Object.keys(salaryData).length === 0
      ) {
        showNotifications("error", "Please fill in the salary details", 5000);
        return;
      }

      if (dateOfExit && dateOfExit<=dateOfJoining) {
        showNotifications("error", "Date of Exit cannot be before or same as Date of Joining", 5000);
        return;
      }
      const formDataToSend = new FormData();

      Object.entries(formData).forEach(([key, value]) => {
        formDataToSend.append(key, value);
      });

      if (Object.keys(salaryData).length > 0) {
        formDataToSend.append("salaryData", JSON.stringify(salaryData));
      }
      formDataToSend.append("selectedTemplate", selectedTemplate);
      formDataToSend.append("issuedBy", issuedBy);
      formDataToSend.append("file", sigFile);
      formDataToSend.append("issueDate", issueDate);
      formDataToSend.append("role", role);
      formDataToSend.append("newRole", newRole);
      formDataToSend.append("department", department);
      formDataToSend.append("newDepartment", newDepartment);
      formDataToSend.append("dateOfJoining", dateOfJoining);
      formDataToSend.append("dateOfExit", dateOfExit);
      formDataToSend.append("Salutation", salutation);
      formDataToSend.append(
        "Reporting_Employee_Designation",
        reportingEmpDesignation
      );
      formDataToSend.append("IBSalutation", issueBySalutation);
      formDataToSend.append("IBDesignation", issueByDesignation);
      formDataToSend.append("Stipend", stipend);
      formDataToSend.append("email", email);
      formDataToSend.append("IBEmail", issuedByEmail);

      const response = await request(() =>
        axios.post(EMPLOYEE_URL + `/${action}`, formDataToSend)
      );

      if (action == "downloadofferletter") {
        const uint8Array = new Uint8Array(
          response.data.message.offerLetterBuffer.data
        );
        const blob = new Blob([uint8Array], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "OfferLetter.pdf";
        a.click();
        window.URL.revokeObjectURL(url);
      }

      if (response.data.status === "Success") {
        showNotifications(
          "success",
          `${
            action === "offerletter"
              ? "Offer Letter mailed"
              : "Offer Letter Downloaded"
          } successfully`,
          5000
        );
      } else {
        showNotifications(
          "error",
          `Error ${
            action === "offerletter" ? "sending" : "downloading"
          } Offer Letter`,
          5000
        );
      }

      const excelFileInput = document.getElementById("excelFile");
      if (excelFileInput) {
        excelFileInput.value = ""; // Reset the file input
      }

      setFormData({
        firstName: "",
        lastName: "",
        salary: "",
        Salutation: "",
        Probation_Period: "",
        Reporting_Employee: "",
        Stipend: "",
      });

      //Clear salary structure fields
      setSalaryData({});
      handleClearSalaryStructure();
      setIssuedBy("");
      setRole("");
      setNewRole("");
      setDepartment("");
      setNewDepartment("");
      setDateOfExit("");
      setIssueDate("");
      setDateOfJoining("");
      setSalutation("");
      setReportingEmpDesignation("");
      setIssueByDesignation("");
      setStipend("");
      setEmail("");
      setIssueBySalutation("");
      setSigFile("");
      setIssuedByEmail("");
    } catch (error) {
      console.error("Error sending form data:", error);
    }
  };

  const handleSubmit = (e) => {
    handleFormSubmit(e, "offerletter");
  };

  const handleDownload = (e) => {
    handleFormSubmit(e, "downloadofferletter");
  };

  return (
    <Container
      style={{
        marginTop: "3rem",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Typography
        variant="h2"
        align="center"
        style={{ fontFamily: "sans-serif" }}
        fontWeight={550}
        gutterBottom
      >
        Offer Letter Service
      </Typography>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <InputLabel htmlFor="template" style={{ width: "550px" }}>
              Select Template
            </InputLabel>
            <Select
              fullWidth
              variant="outlined"
              label="Select Template"
              name="template"
              value={selectedTemplate}
              onChange={(e) => {
                setSelectedTemplate(e.target.value);
              }}
            >
              <MenuItem value="" disabled>
                Select a template
              </MenuItem>
              <MenuItem value="template1">Internship With FTE</MenuItem>
              <MenuItem value="template2">FTE</MenuItem>
              <MenuItem value="template3">Internship</MenuItem>
            </Select>
          </Grid>

          {/*Issue Date*/}
          {selectedTemplate &&
            (selectedTemplate === "template1" ||
              selectedTemplate === "template2" ||
              selectedTemplate === "template3") && (
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Issue Date"
                  type="date"
                  variant="outlined"
                  value={issueDate}
                  onChange={(e) => setIssueDate(e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    min: getCurrentDate(),
                  }}
                />
              </Grid>
            )}

          {/*Date of Joining*/}
          {selectedTemplate &&
            (selectedTemplate === "template1" ||
              selectedTemplate === "template2" ||
              selectedTemplate === "template3") && (
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Date of Joining"
                  type="date"
                  variant="outlined"
                  value={dateOfJoining}
                  onChange={(e) => setDateOfJoining(e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    min: getCurrentDate(),
                  }}
                />
              </Grid>
            )}

          {/*Date Of Exit*/}
          {selectedTemplate && selectedTemplate === "template3" && (
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Date Of Exit"
                type="date"
                variant="outlined"
                value={dateOfExit}
                onChange={(e) => setDateOfExit(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  min: getCurrentDate(),
                }}
              />
            </Grid>
          )}

          {/*Email*/}
          {selectedTemplate &&
            (selectedTemplate === "template1" ||
              selectedTemplate === "template2" ||
              selectedTemplate === "template3") && (
              <Grid item xs={12}>
                <InputLabel htmlFor="email">Email</InputLabel>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="Email"
                  value={email}
                  error={errorEmail}
                  helperText={
                    errorEmail ? "Please enter a valid email address" : ""
                  }
                  onChange={(e) => {
                    setEmail(e.target.value);
                    handleEmailChange(e);
                  }}
                />
              </Grid>
            )}

          {/*Salutation*/}
          {selectedTemplate &&
            (selectedTemplate === "template1" ||
              selectedTemplate === "template2" ||
              selectedTemplate === "template3") && (
              <Grid item xs={12}>
                <InputLabel htmlFor="salutation">Salutation</InputLabel>
                <Select
                  fullWidth
                  variant="outlined"
                  label="Select Salutation"
                  name="salutation"
                  value={salutation}
                  onChange={(e) => setSalutation(e.target.value)}
                >
                  <MenuItem value="" disabled>
                    Select Salutation
                  </MenuItem>
                  <MenuItem value="Mr">Mr</MenuItem>
                  <MenuItem value="Ms">Ms</MenuItem>
                </Select>
              </Grid>
            )}

          {selectedTemplate &&
            templates[selectedTemplate].map((fieldName) => (
              <Grid item xs={12} key={fieldName}>
                <TextField
                  fullWidth
                  label={fieldName.charAt(0).toUpperCase() + fieldName.slice(1)}
                  variant="outlined"
                  name={fieldName}
                  value={formData[fieldName]}
                  onChange={handleChange}
                />
              </Grid>
            ))}

          {/*Reporting Employee Designation*/}
          {selectedTemplate &&
            (selectedTemplate === "template1" ||
              selectedTemplate === "template2") && (
              <Grid item xs={12}>
                <InputLabel htmlFor="reportigEmpDesignation">
                  Reporting Employee Designation
                </InputLabel>
                <Select
                  fullWidth
                  variant="outlined"
                  label="Select Designation"
                  name="reportingEmpDesignation"
                  value={reportingEmpDesignation}
                  onChange={(e) => setReportingEmpDesignation(e.target.value)}
                >
                  <MenuItem value="" disabled>
                    Select Designation
                  </MenuItem>
                  <MenuItem value="Associate">Associate</MenuItem>
                  <MenuItem value="Assistant Manager">
                    Assistant Manager
                  </MenuItem>
                  <MenuItem value="Deputy Manager">Deputy Manager</MenuItem>
                  <MenuItem value="Senior Manager">Senior Manager</MenuItem>
                </Select>
              </Grid>
            )}

          {/*Role*/}
          {["template1", "template2", "template3"].includes(
            selectedTemplate
          ) && (
            <Grid item xs={12}>
              <InputLabel htmlFor="role">Role</InputLabel>
              <Select
                fullWidth
                variant="outlined"
                label="Select an option"
                name="role"
                value={role}
                onChange={(e) => setRole(e.target.value)}
              >
                <MenuItem value="" disabled>
                  Select Role
                </MenuItem>
                {selectedTemplate === "template3" && (
                  <MenuItem value="Intern">Intern</MenuItem>
                )}
                {selectedTemplate !== "template3" && (
                  <MenuItem value="Associate">Associate</MenuItem>
                )}
                {selectedTemplate !== "template3" && (
                  <MenuItem value="Assistant Manager">
                    Assistant Manager
                  </MenuItem>
                )}
                {selectedTemplate !== "template3" && (
                  <MenuItem value="Deputy Manager">Deputy Manager</MenuItem>
                )}
                {selectedTemplate !== "template3" && (
                  <MenuItem value="Senior Manager">Senior Manager</MenuItem>
                )}
                {selectedTemplate !== "template3" && (
                  <MenuItem value="others">Other (Please Sepecify)</MenuItem>
                )}
              </Select>
            </Grid>
          )}

          {role === "others" && (
            <Grid item xs={12}>
              <TextField
                fullWidth
                variant="outlined"
                label="Enter New Role"
                name="newRole"
                value={newRole}
                onChange={handleRoleChange}
                error={roleError !== ""}
                helperText={roleError}
              />
            </Grid>
          )}

          {/*Department*/}
          {(selectedTemplate === "template1" ||
            selectedTemplate === "template2") && (
            <Grid item xs={12}>
              <InputLabel htmlFor="role">Department</InputLabel>
              <Select
                fullWidth
                variant="outlined"
                label="Select an option"
                name="department"
                value={department}
                onChange={(e) => setDepartment(e.target.value)}
              >
                {/* ... */}
                <MenuItem value="" disabled>
                  Select Department
                </MenuItem>
                <MenuItem value="Operations">Operations</MenuItem>
                <MenuItem value="Risk Advisory">Risk Advisory</MenuItem>
                <MenuItem value="IT Risk Management">
                  IT Risk Management
                </MenuItem>
                <MenuItem value="Sales & Marketing">Sales & Marketing</MenuItem>
                <MenuItem value="others">Other (Please Specify)</MenuItem>
              </Select>
            </Grid>
          )}

          {department === "others" && (
            <Grid item xs={12}>
              <TextField
                fullWidth
                variant="outlined"
                label="Enter New Department"
                name="newDepartment"
                value={newDepartment}
                onChange={handleDepartmentChange}
                error={departmentError !== ""}
                helperText={departmentError}
              />
            </Grid>
          )}

          {/*Stipend*/}
          {selectedTemplate &&
            (selectedTemplate === "template1" ||
              selectedTemplate === "template3") && (
              <Grid item xs={12}>
                <InputLabel htmlFor="Stipend">Stipend</InputLabel>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="Stipend"
                  value={stipend}
                  onChange={(e) => {
                    setStipend(e.target.value);
                    handleStipendChange(e);
                  }}
                />
              </Grid>
            )}

          {/*Salary Structure*/}
          {(selectedTemplate === "template1" ||
            selectedTemplate === "template2") && (
            <SalaryStructure
              formData={formData}
              onDataChange={handleSalaryStructureChange}
              onClear={clearSalaryStructure}
            />
          )}

          {/*Issuing Personel Salutation*/}
          {selectedTemplate &&
            (selectedTemplate === "template1" ||
              selectedTemplate === "template2" ||
              selectedTemplate === "template3") && (
              <Grid item xs={12}>
                <InputLabel htmlFor="issueBySalutation">
                  Issuing Person Salutation
                </InputLabel>
                <Select
                  fullWidth
                  variant="outlined"
                  label="Select Salutation"
                  name="issueBySalutation"
                  value={issueBySalutation}
                  onChange={(e) => setIssueBySalutation(e.target.value)}
                >
                  <MenuItem value="" disabled>
                    Select Salutation
                  </MenuItem>
                  <MenuItem value="Mr">Mr</MenuItem>
                  <MenuItem value="Ms">Ms</MenuItem>
                </Select>
              </Grid>
            )}

          {/*Issuing Personel Email*/}
          {selectedTemplate &&
            (selectedTemplate === "template1" ||
              selectedTemplate === "template2" ||
              selectedTemplate === "template3") && (
              <Grid item xs={12}>
                <InputLabel htmlFor="email">Issuing Person Email</InputLabel>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="Email"
                  value={issuedByEmail}
                  error={errorEmail}
                  helperText={
                    errorEmail ? "Please enter a valid email address" : ""
                  }
                  onChange={(e) => {
                    setIssuedByEmail(e.target.value);
                    handleEmailChange(e);
                  }}
                />
              </Grid>
            )}

          {/*Issuing Personel Name*/}
          {selectedTemplate &&
            (selectedTemplate === "template1" ||
              selectedTemplate === "template2" ||
              selectedTemplate === "template3") && (
              <Grid item xs={12}>
                <InputLabel htmlFor="issuedBy">
                  Issuing Person Name
                </InputLabel>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="issuedBy"
                  value={issuedBy}
                  onChange={(e) => setIssuedBy(e.target.value)}
                />
              </Grid>
            )}

          {/*Issuing Personel Designation*/}
          {selectedTemplate &&
            (selectedTemplate === "template1" ||
              selectedTemplate === "template2" ||
              selectedTemplate === "template3") && (
              <Grid item xs={12}>
                <InputLabel htmlFor="issuedByDesignation">
                  Issuing Person Designation
                </InputLabel>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="issuedByDesignation"
                  value={issueByDesignation}
                  onChange={(e) => setIssueByDesignation(e.target.value)}
                />
              </Grid>
            )}

          {issuedBy && (
            <Grid item xs={12}>
              <p>Please Upload your Signature</p>
              <label
                style={{
                  backgroundColor: "black",
                  color: "white",
                  padding: "0.5rem 1rem",
                  borderRadius: "4px",
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  width: "200px",
                  textAlign: "center",
                  justifyContent: "space-evenly",
                }}
              >
                Upload Signature
                <input
                  type="file"
                  accept=".jpg, .jpeg, .png"
                  onChange={handleFileSelection}
                  style={{
                    display: "none",
                  }}
                />
                {sigFile ? <CheckCircleIcon fontSize="large" /> : ""}
              </label>
              {sigFile && (
                <img
                  src={`data:image/jpeg;base64,${sigFile}`}
                  alt="Uploaded Signature"
                  style={{
                    maxWidth: "200px",
                    maxHeight: "150px",
                    marginTop: "10px",
                    border: "1px solid black",
                  }}
                />
              )}
            </Grid>
          )}

          {selectedTemplate && (
            <Grid
              container
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "20px",
                flexDirection: "row",
              }}
            >
              <Grid
                item
                xs={6}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: buttonColor,
                    color: "white",
                    fontSize: "1rem",
                    minWidth: "100px",
                  }}
                  type="submit"
                  disabled={!formComplete || loading}
                  onClick={handleDownload}
                >
                  Download Offer Letter
                </Button>
              </Grid>

              <Grid
                style={{
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "end",
                  marginLeft: "30px",
                }}
              >
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: buttonColor,
                    color: "white",
                    fontSize: "1rem",
                  }}
                  type="submit"
                  disabled={!formComplete || loading}
                  onClick={handleSubmit}
                >
                  Mail Offer Letter
                </Button>
              </Grid>
            </Grid>
          )}
        </Grid>
      </form>
    </Container>
  );
};
