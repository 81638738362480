import React, { useState, useEffect } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { Modal } from '../Modal';
import { useDataLayerValue } from '../../contextAPI/DataLayer';
import axios from 'axios';
import { useNotifications } from '../../hooks/useNotifications';
import { BACKEND_URL } from '../../config';
export const Form = ({
  title,
  url,
  open,
  handleClose,
  fields,
  type,
  headers
}) => {
  const [initialState] = useDataLayerValue();
  const [roles, setRoles] = useState([]);
  const [depts, setDepts] = useState([]);
  const [selectedRole, setSRole] = useState({});
  const [selectedDept, setSDept] = useState({});
  const { showNotifications } = useNotifications();

  useEffect(() => {
    axios
      .get(BACKEND_URL + '/roles', {
        headers: {
          employeeid: initialState._id
        }
      })
      .then((res) => {
        setRoles(res.data.body);
      })
      .catch((er) => {
        console.log(er);
      });
    axios
      .get(BACKEND_URL + '/departments', {
        headers: {
          employeeid: initialState._id
        }
      })
      .then((res) => {
        setDepts(res.data.body);
      })
      .catch((er) => {
        console.log(er);
      });
  }, []);

  useEffect(() => {}, [depts, roles]);

  const handleRoles = (e) => {
    console.log(JSON.parse(e.target.value));
    setSRole(JSON.parse(e.target.value));
  };

  const handleDepts = (e) => {
    console.log(JSON.parse(e.target.value));
    setSDept(JSON.parse(e.target.value));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    let data = {};
    for (let i = 0; i < fields.length; i++) {
      if (event.target[i].name === 'role') {
        data['role'] = selectedRole['name'];
        data['profile'] = selectedRole['profiles'];
        data['roleId'] = selectedRole['_id'];
      } else if (event.target[i].name === 'department') {
        data['department'] = selectedDept['name'];
        data['departmentId'] = selectedDept['_id'];
      } else data[event.target[i].name] = event.target[i].value;
    }
    console.log(data);
    if (type === 'post') {
      axios
        .post(url, data, { headers })
        .then((response) => {
          if (
            response.data.statusCode === 200 ||
            response.data.statusCode === 201
          ) {
            showNotifications('success', response.data.message, 5000);
          } else {
            showNotifications('error', response.data.message, 5000);
          }
        })
        .catch((error) => {
          console.log(error);
          showNotifications('error', error, 5000);
        });
    } else if (type === 'patch') {
      axios.patch(url, data, { headers }).catch((error) => {
        console.log(error);
      });
    } else if (type === 'delete') {
      axios.delete(url, data, { headers }).catch((error) => {
        console.log(error);
      });
    }
    handleClose();
  };
  return (
    <Modal open={open} handleClose={handleClose}>
      <Typography variant="h5">{title}</Typography>
      <form
        style={{ margin: '1.5vw 0', overflow: 'auto' }}
        onSubmit={handleSubmit}
      >
        {fields.map((field) => {
          if (field.name === 'role') {
            return (
              <Box
                sx={{
                  m: 2,
                  '& select': {
                    margin: '2px',
                    width: '100%'
                  }
                }}
                key={field.name}
              >
                <label htmlFor="role">
                  Change Role (Currently
                  {' ' + field.value + ' '} )
                </label>
                <select name="role" defaultValue="none" onChange={handleRoles}>
                  <option value="none" disabled hidden>
                    Select an Option
                  </option>
                  {roles.map((role, idx) => (
                    <option key={idx} value={JSON.stringify(role)}>
                      {role.name}
                    </option>
                  ))}
                </select>
              </Box>
            );
          } else if (field.name === 'department') {
            return (
              <Box
                sx={{
                  m: 2,
                  '& select': {
                    margin: '2px',
                    width: '100%'
                  }
                }}
                key={field.name}
              >
                <label htmlFor="department">
                  Change Department (Currently
                  {' ' + field.value + ' '} )
                </label>
                <select
                  name="department"
                  defaultValue="none"
                  onChange={handleDepts}
                >
                  <option value="none" disabled hidden>
                    Select an Option
                  </option>
                  {depts.map((dept, idx) => (
                    <option key={idx} value={JSON.stringify(dept)}>
                      {dept.name}
                    </option>
                  ))}
                </select>
              </Box>
            );
          } else if (
            (field.display === 'required' || field.display === 'optional') &&
            field.type === 'dropdown'
          ) {
            return (
              <Box
                sx={{
                  m: 2,
                  '& select': {
                    margin: '2px',
                    width: '100%'
                  }
                }}
                key={field.name}
              >
                <label htmlFor={field.name}>{field.label}</label>
                <br />
                <select defaultValue={field.defaultValue} name={field.name}>
                  {field.value.map((option) => {
                    return (
                      <option
                        value={option[field.info[field.info.length - 1]]}
                        key={option[field.info[field.info.length - 1]]}
                      >
                        {option[field.info[0]]}
                      </option>
                    );
                  })}
                </select>
              </Box>
            );
          } else if (
            field.display === 'required' ||
            field.display === 'optional'
          ) {
            return (
              <Box
                sx={{
                  m: 2,
                  '& input': { margin: '2px' }
                }}
                key={field.name}
              >
                <label htmlFor={field.name}>{field.label}</label>
                <br />
                <input
                  type={field.type}
                  defaultValue={
                    field.value !== undefined
                      ? field.value
                      : initialState[field.name] !== undefined
                      ? initialState[field.name]
                      : ''
                  }
                  name={field.name}
                  required={field.display === 'required'}
                />
                <br />
              </Box>
            );
          } else if (field.display === 'auto') {
            return (
              <Box sx={{ m: 2 }} key={field.name}>
                <label htmlFor={field.name}>{field.label}</label>
                <br />
                <input
                  type={field.type}
                  defaultValue={
                    field.value !== undefined
                      ? field.value
                      : initialState[field.name] !== undefined
                      ? initialState[field.name]
                      : ''
                  }
                  name={field.name}
                  readOnly
                />
                <br />
              </Box>
            );
          } else {
            return (
              <Box key={field.name}>
                <input
                  type="hidden"
                  defaultValue={
                    field.value !== undefined
                      ? field.value
                      : initialState[field.name] !== undefined
                      ? initialState[field.name]
                      : ''
                  }
                  name={field.name}
                  disabled
                />
              </Box>
            );
          }
        })}
        <Button type="submit">Submit</Button>
      </form>
    </Modal>
  );
};
