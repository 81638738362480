import React, { useState, useEffect } from 'react';
import {
  createAll,
  deleteLibraryById,
  findAllLibraryType,
  findThreatById,
  findThreatByProperties,
  updateLibraryById
} from '../../../../constants/requests';
import {
  Box,
  Typography,
  Button,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material';
import { useLoading } from '../../../../hooks/useLoading';
import { useNotifications } from '../../../../hooks/useNotifications';

const style = {
  width: '100%',
  bgcolor: 'white',
  borderRadius: '5px',
  maxHeight: '85vh',
  overflowY: 'auto',
  p: 4
};

function Threat() {
  const { request } = useLoading();
  const { showNotifications } = useNotifications();
  const [isOpen, setIsOpen] = useState(false);
  const [formData, setFormData] = useState({
    description: '',
    name: '',
    type: 'threat'
  });
  const [data, setData] = useState([]);
  const [ThreatData, setThreatData] = useState(null);

  useEffect(() => {
    fetchData();
    loadApplication();
  }, []);

  const fetchData = async () => {
    try {
      const dta = ['threat'];
      const response = await request(() => findAllLibraryType(dta[0]));
      setData(response.data.body);
    } catch (error) {
      console.error(error);
      showNotifications('error', error.toString(), 5000);
    }
  };

  const handleOpenDialog = () => {
    setIsOpen(true);
  };

  const handleCloseDialog = () => {
    setIsOpen(false);
  };

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleAddData = async () => {
    try {
      const response = await request(() => createAll([formData]));
      console.log(response.data);
      if (
        response.data.statusCode === 200 ||
        response.data.statusCode === 201
      ) {
        console.log(response.data); // You can do something with the response if needed
        handleCloseDialog();
        fetchData(); // Refresh the data after adding
        showNotifications('success', response.data.message, 5000);
      } else {
        showNotifications('error', response.response.data.message, 5000);
      }
    } catch (error) {
      showNotifications('error', error.toString(), 5000);
      console.error(error);
    }
  };

  const getThreatRepositoryById = async (ThreatId) => {
    try {
      const res = await request(() => findThreatById(ThreatId));

      if (res.data.statusCode === 200) {
        setThreatData(res.data.body);
        console.log(res.data.body.data);
        showNotifications('success', res.data.message, 5000);
      } else {
        showNotifications('error', res.data.message, 5000);
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  const getThreatRepository = async () => {
    try {
      const res = await request(() => findThreatByProperties());

      if (res.data.statusCode === 200) {
        getThreatRepositoryById(res.data.body[0]._id);
        showNotifications('success', res.data.message, 5000);
      } else {
        showNotifications('error', res.data.message, 5000);
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  const loadApplication = () => {
    try {
      getThreatRepository();
      let array1 = [];
      //console.log(ThreatData.data.meta.fields['Threat No']);
      //console.log(data[0]['number']);
      // Check if 'Control No.' field already exists in ThreatData.data.meta.fields
      //console.log('here');

      data.forEach((element) => {
        if (element['name']) {
          array1.push(element['name']);
        }
      });

      console.log(data['name']);

      let updatettotal = Object.keys(
        ThreatData.data.meta.fields['Threat No'].options
      ).length;
      let array2 = [];

      for (let i = 0; i < updatettotal; i++) {
        array2.push(ThreatData.data.meta.fields['Threat No'].options[i].value);
      }

      const missingFromArray1 = array1.filter(
        (element) => !array2.includes(element)
      );

      // Find missing elements from array2
      const missingFromArray2 = array2.filter(
        (element) => !array1.includes(element)
      );

      // Combine the missing elements into array3
      const array3 = [...missingFromArray1, ...missingFromArray2];

      //console.log(array1);
      //console.log(array2);
      //console.log('difference');
      const filterarray = array3.filter((element) => element !== '');
      // console.log(filterarray);

      if (filterarray.length > 0) {
        for (let i = 0; i < filterarray.length; i++) {
          let a = filterarray[i];
          //console.log('hi');

          // Check if options property and its nested properties exist
          if (!ThreatData.data.meta.fields['Threat No'].options) {
            ThreatData.data.meta.fields['Threat No'].options = [];
          }

          ThreatData.data.meta.fields['Threat No'].options.push({
            label: a,
            value: a
          });
          //ThreatData.data.meta.fields['Control No.'].options.value.push(a);
          // a.splice(i, 1);
        }
      }

      let updatetotal = Object.keys(
        ThreatData.data.meta.fields['Threat No'].options
      ).length;

      console.log(updatetotal);
      console.log(ThreatData.data.meta.fields['Threat No']);
    } catch (err) {
      console.log(err);
    }
  };

  const handleUpdateData = async (controlId) => {
    try {
      const response = await request(() =>
        updateLibraryById(controlId, formData)
      );

      if (response?.data?.statusCode === 200) {
        showNotifications('success', 'Save successful', 5000);

        console.log(response.data); // You can do something with the response if needed
        handleCloseDialog();
        fetchData();
        showNotifications('success', response.data.message, 5000);
      } else {
        if (response.response.data.statusCode !== 200) {
          showNotifications('error', response.data.message, 5000);
        }
      }
    } catch (error) {
      showNotifications('error', error.toString(), 5000);
      console.error(error);
    }
  };

  const handleEdit = (controlId) => {
    const selectedData = data.find((item) => item._id === controlId);
    setFormData(selectedData);
    handleOpenDialog();
  };

  const handleDelete = async (controlId) => {
    try {
      const response = await request(() => deleteLibraryById(controlId));
      // Handle successful deletion
      if (response.data.statusCode === 200) {
        fetchData();
        showNotifications('success', response.data.message, 5000);
      } else {
        showNotifications('error', response.data.message, 5000);
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
      console.log(err);
    }
  };

  return (
    <Box sx={style}>
      <Box>
        <Button
          variant="contained"
          style={{
            marginTop: '10px',
            backgroundColor: 'black',
            justifyItems: 'end'
          }}
          onClick={handleOpenDialog}
        >
          Add Threat
        </Button>

        {isOpen && (
          <Box
            className="dialog"
            sx={{ margin: '20px', padding: '100px 100px 100px' }}
          >
            <Box className="dialog-content">
              <Typography variant="h4" sx={{ margin: '20px' }}>
                {formData._id ? 'Update Threat' : 'Add Threat'}
              </Typography>
              <form>
                <Box>
                  <label htmlFor="description">Description* :</label>
                  <input
                    type="text"
                    id="description"
                    name="description"
                    value={formData.description}
                    placeholder="Description"
                    required
                    onChange={handleInputChange}
                  />
                </Box>
                <Box>
                  <label htmlFor="name">Name* :</label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    placeholder="Name"
                    required
                    value={formData.name}
                    onChange={handleInputChange}
                  />
                </Box>
                {formData._id ? (
                  <Button
                    variant="contained"
                    style={{
                      marginTop: '10px',
                      marginInline: '4px',
                      backgroundColor: 'black'
                    }}
                    type="button"
                    onClick={() => handleUpdateData(formData._id)}
                  >
                    Update
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    style={{
                      marginTop: '10px',
                      marginInline: '4px',
                      backgroundColor: 'black'
                    }}
                    type="button"
                    onClick={handleAddData}
                  >
                    Add
                  </Button>
                )}
                <Button
                  variant="contained"
                  style={{
                    marginTop: '10px',
                    marginInline: '2px',
                    backgroundColor: 'black'
                  }}
                  type="button"
                  onClick={handleCloseDialog}
                >
                  Cancel
                </Button>
              </form>
            </Box>
          </Box>
        )}
      </Box>
      <Typography variant="h4">Threat List</Typography>
      <Box>
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Threat Name</TableCell>
                <TableCell>Threat Description</TableCell>
                <TableCell>Threat Actions</TableCell>
                {/* Add more table headers if needed */}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((item) => (
                <TableRow
                  key={item._id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {item.name}
                  </TableCell>

                  <TableCell>{item.description}</TableCell>
                  <TableCell>
                    <Box
                      style={{
                        display: 'flex',
                        marginTop: '2px'
                      }}
                    >
                      <Button
                        variant="contained"
                        sx={{
                          justifyContent: 'space-between',
                          marginLeft: '2px',
                          marginRight: '2px',
                          backgroundColor: 'black'
                        }}
                        onClick={() => handleEdit(item._id)}
                      >
                        Edit
                      </Button>
                      <Button
                        variant="contained"
                        sx={{
                          justifyContent: 'space-between',
                          backgroundColor: 'black'
                        }}
                        onClick={() => handleDelete(item._id)}
                      >
                        Delete
                      </Button>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
}

export default Threat;
