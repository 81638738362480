import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { useDataLayerValue } from '../../contextAPI/DataLayer';
import { BACKEND_URL } from '../../config';

const operators = {
  Equals: '=',
  'Not Equal To': '!=',
  'Greater Than': '>',
  'Less Than': '<',
  'Greater Than Equal To': '>=',
  'Less Than Equal To': '<='
};

function WorkflowTypeIndividual() {
  const { id } = useParams();
  const [details, setDetails] = useState({});
  const [endPoint, setEndPoint] = useState({});
  const [constraints, setConstraints] = useState([]);
  const [approvalFlow, setApprovalFlow] = useState([]);
  const [roles, setRoles] = useState({});
  const [depts, setDepts] = useState({});
  const [{ _id }] = useDataLayerValue();
  useEffect(() => {
    document.title = 'Workflow Type Information';
    axios
      .get(BACKEND_URL + '/workflow/' + id, {
        headers: {
          employeeid: _id
        }
      })
      .then((res) => {
        console.log(res.data.body);
        setDetails(res.data.body);
        setEndPoint(res.data.body.endPoint);
        const constraintsArr = res.data.body.constraints;
        const resArray = [];
        constraintsArr.forEach((constraint) => {
          resArray.push(
            `${constraint.field} ${operators[constraint.operation]} ${
              constraint.value
            }`
          );
        });
        setConstraints(resArray);
        setApprovalFlow(res.data.body.approvalFlow);
      })
      .catch((er) => console.log(er));
  }, []);

  useEffect(() => {
    axios
      .get(BACKEND_URL + '/roles', {
        headers: {
          employeeid: _id
        }
      })
      .then((res) => {
        const rolesArray = res.data.body;
        let rolesObj = {};
        rolesArray.forEach((role) => {
          rolesObj = { ...rolesObj, [role._id]: role.name };
        });
        setRoles(rolesObj);
      })
      .catch((er) => {
        console.log(er);
      });
    axios
      .get(BACKEND_URL + '/departments', {
        headers: {
          employeeid: _id
        }
      })
      .then((res) => {
        const deptsArray = res.data.body;
        let deptsObj = {};
        deptsArray.forEach((dept) => {
          deptsObj = { ...deptsObj, [dept._id]: dept.name };
        });
        setDepts(deptsObj);
      })
      .catch((er) => {
        console.log(er);
      });
  }, []);

  const stylesObj = {
    container: {
      backgroundColor: 'whitesmoke',
      boxShadow: '2px 2px 10px grey',
      marginTop: '30px',
      padding: '60px 90px'
    },
    keyval: {
      display: 'flex'
    },
    key: {
      marginRight: '10px',
      fontWeight: 'bolder'
    },
    parts: {
      marginLeft: '40px'
    },
    parts2: {
      marginLeft: '60px'
    },
    approvers: {
      display: 'flex',
      justifyContent: 'space-between',
      marginLeft: '40px',
      marginRight: '150px'
    },
    attributes: {
      display: 'flex'
    },
    roleDept: {
      marginRight: '20px'
    }
  };

  return (
    <div>
      <h3>Workflow Type Information</h3>
      <div style={stylesObj.container}>
        <span style={stylesObj.keyval}>
          <p style={stylesObj.key}>Workflow Type ID:</p>
          <p>{details._id}</p>
        </span>
        <span style={stylesObj.keyval}>
          <p style={stylesObj.key}>Workflow Type Name:</p>
          <p>{details.name}</p>
        </span>
        <span style={stylesObj.keyval}>
          <p style={stylesObj.key}>Internal OR External:</p>
          <p>{details.internalOrExternal}</p>
        </span>
        <span style={stylesObj.keyval}>
          <p style={stylesObj.key}>Qualitative OR Quantitative:</p>
          <p>{details.qualitativeOrQuantitative}</p>
        </span>
        <br />
        <>
          <span style={stylesObj.keyval}>
            <p style={stylesObj.key}>
              <li>ENDPOINT</li>
            </p>
          </span>
          <div style={stylesObj.parts}>
            <span style={stylesObj.keyval}>
              <p style={stylesObj.key}>URL: </p>
              <p>{endPoint.url}</p>
            </span>
            <span style={stylesObj.keyval}>
              <p style={stylesObj.key}>METHOD: </p>
              <p>{endPoint.method}</p>
            </span>
            <span style={stylesObj.keyval}>
              <p style={stylesObj.key}>LABEL: </p>
              <p>{endPoint.label}</p>
            </span>
          </div>
          <br />
        </>
        <>
          <span style={stylesObj.keyval}>
            <p style={stylesObj.key}>
              <li>CONSTRAINTS</li>
            </p>
          </span>
          <ol style={stylesObj.parts2}>
            {constraints.map((constraint, idx) => {
              return <li key={idx}>{constraint}</li>;
            })}
          </ol>
        </>
        <br />
        <>
          <span>
            <p style={stylesObj.key}>
              <li>APPROVAL FLOW</li>
            </p>
            <br />
          </span>
          {approvalFlow.map((flow, idx) => {
            return (
              <React.Fragment key={idx}>
                <div style={stylesObj.approvers}>
                  <div>{`Approver ${idx + 1}: `}</div>
                  <div style={stylesObj.attributes}>
                    <div style={stylesObj.roleDept}>
                      <b>Department:</b>
                    </div>
                    <div>{depts[flow.departmentId]}</div>
                  </div>
                  <div style={stylesObj.attributes}>
                    <div style={stylesObj.roleDept}>
                      <b>Role:</b>
                    </div>
                    <div>{roles[flow.roleId]}</div>
                  </div>
                </div>
                <br />
              </React.Fragment>
            );
          })}
        </>
      </div>
    </div>
  );
}

export default WorkflowTypeIndividual;
