import React, { useState } from 'react';
import {
  Box,
  Typography,
  TextField,
  Button,
  Grid,
  Checkbox
} from '@mui/material';
import { Document, pdfjs, Page } from 'react-pdf';
import { getPdfToExcel, getSocReport } from '../../../constants/requests';
import { useLoading } from '../../../hooks/useLoading';
import { useNotifications } from '../../../hooks/useNotifications';
import TableComponent from './TableComponent';
import SaveModal from './SaveModal';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import PDFselection from './PDFselection';
import FileDownload from 'js-file-download';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
const stepsToConvert = [
  'Click on "Choose File" Button and select the PDF file that you want to convert.',
  'Make sure that PDF should not have any password protection or watermark in it.',
  'Make sure that the PDF file should not have implicipt columns in it.',
  'Select the pages which you want to convert to Excel.',
  'Click on "Convert" Button to convert the PDF file to Excel.',
  'Select the pages which you want to parse for tables',
  'Once the file is converted, click on "Download" button to download the converted file.',
  'Customize the tables data after parsing the PDF file.',
  'If you want to convert another file, click on "Reset" button to clear the input fields.'
];

const stepToConvertBullets = [
  'Click on "Choose File" Button and select the PDF file that you want to convert.',
  'Select the points you want to get added into the excel sheet',
  'Click on Add text to list button after selection and you will see a list of points appear below.',
  'Type in the cell address in the input just aside the point.',
  'Click on Export to excel.'
];
const pageContainerStyle = {
  position: 'relative',
  width: '200px',
  height: '300px',
  overflow: 'hidden'
};
const pageStyles = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)'
};
const checkboxStyle = {
  position: 'absolute',
  bottom: 0,
  right: 0,
  margin: '10px'
};
const labelStyle = {
  position: 'absolute',
  bottom: 0,
  left: 0,
  margin: '10px'
};

const PDFToExcel = () => {
  const { request } = useLoading();
  const { showNotifications } = useNotifications();
  const [numPages, setNumPages] = useState(null);
  const [selectedPages, setSelectedPages] = useState([]);
  const [file, setFile] = useState(null);
  const [fetchedData, setFetchedData] = useState([]);
  const [save, setSave] = useState(false);
  const [openSaveModal, setOpenSaveModal] = useState(false);
  const [tabValue, setTabValue] = useState('1');
  const [pageTabValue, setPageTabValue] = useState('1');
  const [companyName, setCompanyName] = useState('');
  const [reportFY, setReportFY] = useState('');
  const [criteriaCol, setCriteriaCol] = useState('');
  const [selectedPageForSheetOne, setSelectedPageForSheetOne] = useState([]);
  const [selectedPageForSheetTwo, setSelectedPageForSheetTwo] = useState([]);
  const [selectedPageForSheetThree, setSelectedPageForSheetThree] = useState([]);
  const [sheetOneTableResponse, setSheetOneTableResponse] = useState({ headers: [], rows: [] });
  const [sheetTwoTableResponse, setSheetTwoTableResponse] = useState({ headers: [], rows: [] });
  const [sheetThreeTableResponse, setSheetThreeTableResponse] = useState({ headers: [], rows: [] });
  const [markdowntext, setMarkdowntext] = useState('');
  const [pdfFile, setPdfFile] = useState(null);
  const [showMarkdown, setShowMarkdown] = useState(false);
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const handlePageTabChange = (event, newValue) => {
    setPageTabValue(newValue);
  };
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };
  const onFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      setPdfFile(file);
      const reader = new FileReader();
      console.log('here');
      reader.onload = async (e) => {
        const buffer = e.target.result;
        const typedArray = new Uint8Array(buffer);
        const pdf = await pdfjs.getDocument({ data: typedArray }).promise;

        let text = '';

        for (let i = 1; i <= pdf.numPages; i++) {
          const page = await pdf.getPage(i);
          const content = await page.getTextContent();
          content.items.forEach((item) => {
            text += item.str + ' ';
          });
        }
        setShowMarkdown(true);
        setMarkdowntext(text);
      };

      reader.readAsArrayBuffer(file);
    }
  };

  const isPagesVisible = (tabValue, pageTabValue) => {
    if (tabValue === '1') {
      return !fetchedData || fetchedData.length === 0;
    } else {
      if (pageTabValue === '1') {
        return sheetOneTableResponse.headers.length === 0;
      } else if (pageTabValue === '2') {
        return sheetTwoTableResponse.headers.length === 0;
      } else {
        return sheetThreeTableResponse.headers.length === 0;
      }
    }
  };

  const handlePageSelection = (pageNumber) => {
    if (tabValue === '1') {
      const newSelectedPages = [...selectedPages];
      if (newSelectedPages.includes(pageNumber)) {
        const index = newSelectedPages.indexOf(pageNumber);
        newSelectedPages.splice(index, 1);
      } else {
        newSelectedPages.push(pageNumber);
      }
      setSelectedPages(newSelectedPages);
    } else {
      if (pageTabValue === '1') {
        const newSelectedPages = [...selectedPageForSheetOne];
        if (newSelectedPages.includes(pageNumber)) {
          const index = newSelectedPages.indexOf(pageNumber);
          newSelectedPages.splice(index, 1);
        } else {
          newSelectedPages.push(pageNumber);
        }
        setSelectedPageForSheetOne(newSelectedPages);
      } else if (pageTabValue === '2') {
        const newSelectedPages = [...selectedPageForSheetTwo];
        if (newSelectedPages.includes(pageNumber)) {
          const index = newSelectedPages.indexOf(pageNumber);
          newSelectedPages.splice(index, 1);
        } else {
          newSelectedPages.push(pageNumber);
        }
        setSelectedPageForSheetTwo(newSelectedPages);
      } else {
        const newSelectedPages = [...selectedPageForSheetThree];
        if (newSelectedPages.includes(pageNumber)) {
          const index = newSelectedPages.indexOf(pageNumber);
          newSelectedPages.splice(index, 1);
        } else {
          newSelectedPages.push(pageNumber);
        }
        setSelectedPageForSheetThree(newSelectedPages);
      }
    }
  };
  const handleOpenSaveModal = () => setOpenSaveModal(true);
  const handleCloseSaveModal = () => setOpenSaveModal(false);
  const getFetchedData = (tabValue, pageTabValue) => {
    if (tabValue === '1') {
      return {
        outputTable: fetchedData,
        setOutputTable: setFetchedData
      }
    } else {
      if (pageTabValue === '1') {
        return {
          outputTable: sheetOneTableResponse,
          setOutputTable: setSheetOneTableResponse
        }
      } else if (pageTabValue === '2') {
        return {
          outputTable: sheetTwoTableResponse,
          setOutputTable: setSheetTwoTableResponse
        }
      } else {
        return {
          outputTable: sheetThreeTableResponse,
          setOutputTable: setSheetThreeTableResponse
        }
      }
    }
  };



  const getHeadersAndRows = (outputTable) => {
    let headers = [];
    let verticalRows = [];
    for (let key in outputTable) {
      headers.push(key);
      verticalRows.push(outputTable[key]);
    }
    let rows = [];
    for (let i = 0; i < verticalRows[0]?.length; i++) {
      let row = [];
      for (let j = 0; j < verticalRows.length; j++) {
        row.push(verticalRows[j][i] === "NA" ? "" : verticalRows[j][i]);
      }
      rows.push(row);
    }
    return { headers, rows };
  }
  const handleSubmit = async () => {
    if (file === null)
      return showNotifications('error', 'Please select a file', 5000);
    try {
      const formData = new FormData();
      formData.append('pdfFile', file);
      console.log(selectedPages);
      if (tabValue === '1') {
        formData.append('pagesToBeExtracted', selectedPages);
      } else {
        if (pageTabValue === '1') {
          formData.append('pagesToBeExtracted', selectedPageForSheetOne);
        } else if (pageTabValue === '2') {
          formData.append('pagesToBeExtracted', selectedPageForSheetTwo);
        } else {
          formData.append('pagesToBeExtracted', selectedPageForSheetThree);
        }
      }
      // setFile(null);
      const response = await request(() => getPdfToExcel(formData));
      console.log(response.data);
      if (tabValue === '1') {
        setFetchedData(
          getHeadersAndRows(response.data.body)
        );
      } else {
        if (pageTabValue === '1') {
          setSheetOneTableResponse(getHeadersAndRows(response.data.body));
        } else if (pageTabValue === '2') {
          setSheetTwoTableResponse(getHeadersAndRows(response.data.body));
        } else {
          setSheetThreeTableResponse(getHeadersAndRows(response.data.body));
        }
      }
      showNotifications('success', 'File converted successfully', 5000);
    } catch (error) {
      showNotifications('error', 'Error converting file', 5000);
      console.log(error);
    }
  };

  const parseAllSheetResponses = () => {
    let json_data = {
      Sheet1: {},
      Sheet2: {},
      Sheet3: {}
    };

    const sheetOneData = getFormattedDataForSoc(sheetOneTableResponse);
    const sheetTwoData = getFormattedDataForSoc(sheetTwoTableResponse);
    const sheetThreeData = getFormattedDataForSoc(sheetThreeTableResponse);

    const sheetOneKeys = Object.keys(sheetOneData);
    const sheetTwoKeys = Object.keys(sheetTwoData);
    const sheetThreeKeys = Object.keys(sheetThreeData);

    for (let i = 0; i < sheetOneKeys.length; i++) {
      const key = sheetOneKeys[i];
      const value = sheetOneData[key];
      const data = value.slice(0);
      json_data['Sheet1'][`COL${i}`] = {
        displayName: key,
        data: data
      };
    }

    console.log();
    json_data['Sheet1']['companyName'] = {
      displayName: companyName,
      data: []
    };
    json_data['Sheet1']['reportFY'] = {
      displayName: reportFY,
      data: []
    };

    for (let i = 0; i < sheetTwoKeys.length; i++) {
      const key = sheetTwoKeys[i];
      const value = sheetTwoData[key];
      const data = value.slice(0);
      json_data['Sheet2'][`COL${i}`] = {
        displayName: key,
        data: data
      };
    }

    json_data['Sheet2']['companyName'] = {
      displayName: companyName,
      data: []
    };
    json_data['Sheet2']['reportFY'] = {
      displayName: reportFY,
      data: []
    };

    for (let i = 0; i < sheetThreeKeys.length; i++) {
      const key = sheetThreeKeys[i];
      const value = sheetThreeData[key];
      const data = value.slice(0);
      json_data['Sheet3'][`COL${i}`] = {
        displayName: key,
        data: data
      };
    }

    json_data['Sheet3']['companyName'] = {
      displayName: companyName,
      data: []
    };
    json_data['Sheet3']['reportFY'] = {
      displayName: reportFY,
      data: []
    };

    return json_data;
  };

  const getFormattedDataForSoc = (data) => {
    let formattedData = {};
    for (let i = 0; i < data.headers.length; i++) {
      formattedData[data.headers[i]] = [];
    }

    for (let i = 0; i < data.rows.length; i++) {
      for (let j = 0; j < data.rows[i].length; j++) {
        formattedData[data.headers[j]].push(data.rows[i][j]);
      }
    }

    return formattedData;
  }

  const handleDownloadSoc = async () => {
    const formData = new FormData();
    formData.append('Sheet1', sheetOneTableResponse);
    formData.append('Sheet2', sheetTwoTableResponse);
    formData.append('Sheet3', sheetThreeTableResponse);
    const parsed_data = parseAllSheetResponses();
    const output_file = await request(() => getSocReport(parsed_data));
    FileDownload(output_file.data, 'SOC Report.xlsx');
  };

  const getSelectedPagesValue = (tabValue, pageTabValue) => {
    if (tabValue === '1') {
      return selectedPages.join(', ');
    } else {
      if (pageTabValue === '1') {
        return selectedPageForSheetOne.join(', ');
      } else if (pageTabValue === '2') {
        return selectedPageForSheetTwo.join(', ');
      } else {
        return selectedPageForSheetThree.join(', ');
      }
    }
  };
  const getIfPageChecked = (tabValue, pageTabValue, index) => {
    if (tabValue === '1') {
      return selectedPages.includes(index + 1);
    } else {
      if (pageTabValue === '1') {
        return selectedPageForSheetOne.includes(index + 1);
      } else if (pageTabValue === '2') {
        return selectedPageForSheetTwo.includes(index + 1);
      } else {
        return selectedPageForSheetThree.includes(index + 1);
      }
    }
  };
  const getIfDisabled = (tabValue, pageTabValue) => {
    if (tabValue === '1') {
      return selectedPages.length === 0;
    } else {
      if (pageTabValue === '1') {
        return selectedPageForSheetOne.length === 0;
      } else if (pageTabValue === '2') {
        return selectedPageForSheetTwo.length === 0;
      } else {
        return selectedPageForSheetThree.length === 0;
      }
    }
  };
  const handleReset = (tabValue, pageTabValue) => () => {
    if (tabValue === '1') {
      setSelectedPages([]);
    } else {
      if (pageTabValue === '1') {
        setSelectedPageForSheetOne([]);
      } else if (pageTabValue === '2') {
        setSelectedPageForSheetTwo([]);
      } else {
        setSelectedPageForSheetThree([]);
      }
    }
  };

  const addControlNumber = () => {
    if (sheetOneTableResponse.headers.includes('Control Number')) {
      showNotifications('error', 'Column already exists', 5000);
      return;
    }

    if (criteriaCol === '') {
      showNotifications('error', 'Column name cannot be empty', 5000);
      return;
    }

    if (!sheetOneTableResponse.headers.includes(criteriaCol)) {
      showNotifications('error', 'Column name does not exist', 5000);
      return;
    }

    let prevNum = "NA";
    const colValueIndex = sheetOneTableResponse.headers.indexOf(criteriaCol);
    let colValue = [];
    for (let i = 0; i < sheetOneTableResponse.rows.length; i++) {
      colValue.push(sheetOneTableResponse.rows[i][colValueIndex]);
    }
    console.log(colValue);
    const controlNumber = []
    for (let i = 0; i < colValue.length; i++) {
      const str = colValue[i];
      if (str === "") {
        if (prevNum == "NA") {
          controlNumber.push("");
        } else {
          let lastInputed = controlNumber[controlNumber.length - 1];
          let lastInputedDotIndex = lastInputed.lastIndexOf(".");
          let lastInputedNum = lastInputed.substring(lastInputedDotIndex + 1);
          controlNumber.push(`${prevNum}.${parseInt(lastInputedNum) + 1}`);
        }
      } else {
        let regex = /CC\d+\.\d+|\d+\.\d+/g;
        let match = str.match(regex);
        console.log(match);
        if (match) {
          let substr = match[0];
          let newNum = 1;
          controlNumber.push(`${substr}.${newNum}`);
          prevNum = substr;
        } else {
          controlNumber.push("");
        }
      }
    }

    sheetOneTableResponse.headers.splice(colValueIndex + 1, 0, 'Control Number');
    for (let i = 0; i < sheetOneTableResponse.rows.length; i++) {
      sheetOneTableResponse.rows[i].splice(colValueIndex + 1, 0, controlNumber[i]);
    }

    setSheetOneTableResponse({ headers: sheetOneTableResponse.headers, rows: sheetOneTableResponse.rows });


  }

  const addSNoColumn = () => {
    if (pageTabValue === '1') {
      if (sheetOneTableResponse.headers.includes('S.No')) {
        showNotifications('error', 'Column already exists', 5000);
        return;
      }
      sheetOneTableResponse.headers.unshift('S.No');
      for (let i = 0; i < sheetOneTableResponse.rows.length; i++) {
        sheetOneTableResponse.rows[i].unshift(i + 1);
      }
    } else if (pageTabValue === '2') {
      if (sheetTwoTableResponse.headers.includes('S.No')) {
        showNotifications('error', 'Column already exists', 5000);
        return;
      }
      sheetTwoTableResponse.headers.unshift('S.No');
      for (let i = 0; i < sheetTwoTableResponse.rows.length; i++) {
        sheetTwoTableResponse.rows[i].unshift(i + 1);
      }
    } else {
      if (sheetThreeTableResponse.headers.includes('S.No')) {
        showNotifications('error', 'Column already exists', 5000);
        return;
      }
      sheetThreeTableResponse.headers.unshift('S.No');
      for (let i = 0; i < sheetThreeTableResponse.rows.length; i++) {
        sheetThreeTableResponse.rows[i].unshift(i + 1);
      }
    }
  }


  return (
    <>
      <Box style={{ flex: 1, marginLeft: 30 }}>
        <Typography variant="h3" component="h4" gutterBottom>
          PDF To Excel
        </Typography>
        <Typography variant="h4" gutterBottom>
          Steps to convert PDF {tabValue == 3 && 'Bullets'} to Excel:
        </Typography>
        {
          <Box>
            {tabValue <= 2 ? (
              <ol>
                {stepsToConvert.map((step, index) => (
                  <li key={index}>{step}</li>
                ))}
              </ol>
            ) : (
              <ol>
                {stepToConvertBullets.map((step, index) => (
                  <li key={index}>{step}</li>
                ))}
              </ol>
            )}
          </Box>
        }
        <Box sx={{ width: '100%', typography: 'body1' }}>
          <TabContext value={tabValue} sx={{ display: 'flex' }}>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: 'divider',
                marginBottom: 5,
                width: '50%'
              }}
            >
              <TabList onChange={handleTabChange} aria-label="PDF TO EXCEL">
                <Tab label="General Use" value="1" sx={{ flexGrow: 1 }} />
                <Tab label="SOC Report" value="2" sx={{ flexGrow: 1 }} />
                {/* <Tab label="Bullets to Excel" value="3" sx={{ flexGrow: 1 }} /> */}
              </TabList>
            </Box>
          </TabContext>
        </Box>
        {tabValue <= 2 && (
          <Box>
            <Grid container spacing={2} alignItems="center">
              <Grid item>
                <TextField
                  id="outlined-basic"
                  label="Choose File"
                  variant="outlined"
                  type="file"
                  accept="application/pdf"
                  focused
                  onChange={(e) => setFile(e.target.files[0])}
                />
              </Grid>
            </Grid>
            {tabValue === '2' && (
              <Box sx={{ width: '100%', typography: 'body1' }}>
                <TabContext value={pageTabValue} sx={{ display: 'flex' }}>
                  <Box
                    sx={{
                      borderBottom: 1,
                      borderColor: 'divider',
                      marginTop: 5,
                      width: '50%'
                    }}
                  >
                    <TabList onChange={handlePageTabChange} aria-label="Sheets">
                      <Tab label="Sheet 1" value="1" sx={{ flexGrow: 1 }} />
                      <Tab label="Sheet 2" value="2" sx={{ flexGrow: 1 }} />
                      <Tab label="Sheet 3" value="3" sx={{ flexGrow: 1 }} />
                    </TabList>
                  </Box>
                </TabContext>
              </Box>
            )}
            <Grid container spacing={2} alignItems="center" marginTop={2}>
              <Grid item>
                <TextField
                  label="Selected Pages"
                  variant="outlined"
                  focused
                  value={getSelectedPagesValue(tabValue, pageTabValue)}
                />
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                  disabled={getIfDisabled(tabValue, pageTabValue)}
                >
                  Convert
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleReset(tabValue, pageTabValue)}
                >
                  Reset
                </Button>
              </Grid>
            </Grid>
            {tabValue === '2' && pageTabValue === '2' && <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                bgcolor: 'background.paper',
                gap: 2,
                marginY: 2
              }}
            >
              <Button
                variant='contained'
                color='primary'
                onClick={() => {
                  setSheetTwoTableResponse(sheetOneTableResponse)
                }}
              >
                Copy Data from SOC REPORT
              </Button>
            </Box>}
            <Box>
              {isPagesVisible(tabValue, pageTabValue) && (
                <Box>
                  <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
                    <Grid container spacing={2}>
                      {Array.from(new Array(numPages), (el, index) => (
                        <Grid item xs={12} sm={6} md={6} lg={4} key={index}>
                          <Typography syle={labelStyle}>
                            Page {index + 1}
                          </Typography>
                          <div style={pageContainerStyle}>
                            <Page
                              pageNumber={index + 1}
                              renderTextLayer={false}
                              width={200}
                              style={pageStyles}
                              onClick={() => handlePageSelection(index + 1)}
                            />
                            <Checkbox
                              style={checkboxStyle}
                              checked={getIfPageChecked(
                                tabValue,
                                pageTabValue,
                                index
                              )}
                              onChange={() => handlePageSelection(index + 1)}
                              label={`Page ${index + 1}`}
                            />
                          </div>
                        </Grid>
                      ))}
                    </Grid>
                  </Document>
                </Box>
              )}
            </Box>
            {tabValue === '2' && pageTabValue === '1' && sheetOneTableResponse.headers.length > 0 && <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                bgcolor: 'background.paper',
                gap: 2,
                marginY: 2
              }}
            >
              <TextField
                label="Criteria Column"
                variant="outlined"
                placeholder="Enter Criteria Column"
                onChange={(e) => setCriteriaCol(e.target.value)}
                value={criteriaCol}
              />
              <Button
                variant='contained'
                color='primary'
                onClick={addControlNumber}
              >
                Add Control Number
              </Button>
            </Box>}
            {tabValue === '2' && <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                bgcolor: 'background.paper',
                gap: 2,
                marginY: 2
              }}
            >
              <Button
                variant='contained'
                color='primary'
                onClick={addSNoColumn}
              >
                Add S.No Column
              </Button>
            </Box>}

            <TableComponent
              outputTable={getFetchedData(tabValue, pageTabValue).outputTable}
              setOutputTable={getFetchedData(tabValue, pageTabValue).setOutputTable}
              save={save}
              setSave={setSave}
              handleOpenSaveModal={handleOpenSaveModal}
              tabValue={tabValue}
            />
            <SaveModal
              openSaveModal={openSaveModal}
              handleCloseSaveModal={handleCloseSaveModal}
              fetchedData={getFetchedData(tabValue, pageTabValue)}
            />
            {tabValue == 2 && (
              <div>
                <Box
                  sx={{
                    display: 'flex',
                    gap: 2,
                    marginTop: 2,
                    marginBottom: 2
                  }}
                >
                  <TextField
                    label="Company Name"
                    variant="outlined"
                    placeholder="Enter Company Name"
                    onChange={(e) => setCompanyName(e.target.value)}
                    value={companyName}
                  />
                  <TextField
                    label="Report FY"
                    variant="outlined"
                    placeholder="Enter Report FY"
                    onChange={(e) => setReportFY(e.target.value)}
                    value={reportFY}
                  />
                </Box>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleDownloadSoc}
                >
                  Download SOC Report
                </Button>
              </div>
            )}
          </Box>
        )}
        {tabValue == 3 && (
          <Box>
            <PDFselection />
          </Box>
        )}
      </Box>
    </>
  );
};

export default PDFToExcel;
