import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/material';
import {
  getTrustServiceCriteriaById,
  updateTrustServiceCriteriaRequest
} from '../../../constants/requests';
import { useLoading } from '../../../hooks/useLoading';
import { useNotifications } from '../../../hooks/useNotifications';
import CustomTable from '../common/CustomTable';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

const TrustServiceCriteria = () => {
  const navigate = useNavigate();
  const { request } = useLoading();
  const { showNotifications } = useNotifications();
  const { engagementId, trustServiceId } = useParams();
  const [tscData, setTSCData] = useState(null);

  const handleRowChange = (idx, name, cellValue) => {
    let newData = { ...tscData };
    newData['data']['rows']['TrustCriteria'][idx][name] = cellValue;
    setTSCData(newData);
  };

  const loadApplication = async () => {
    try {
      const res = await request(() =>
        getTrustServiceCriteriaById(trustServiceId)
      );
      if (res.data.statusCode === 200) {
        setTSCData(res.data.body);
        showNotifications('success', res.data.message, 5000);
      } else {
        showNotifications('error', res.data.message, 5000);
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  const handleSave = async () => {
    try {
      const payload = tscData;
      const res = await request(() =>
        updateTrustServiceCriteriaRequest(trustServiceId, payload)
      );
      if (res.data.statusCode === 200) {
        setTSCData(tscData);
        showNotifications('success', res.data.message, 5000);
      } else {
        showNotifications('error', res.data.message, 5000);
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  useEffect(() => {
    loadApplication();
  }, []);

  return (
    <React.Fragment>
      <Box
        style={{
          marginBottom: '25px',
          display: 'flex',
          justifyContent: 'space-between'
        }}
      >
        <Button
          sx={{
            color: 'white',
            backgroundColor: 'black',
            paddingLeft: 1
          }}
          variant="contained"
          onClick={() => {
            navigate(`/services/engagement/${engagementId}/understanding`);
          }}
        >
          <ChevronLeftIcon />
          UNDERSTANDING
        </Button>
      </Box>
      <Box
        style={{
          marginBottom: '25px',
          display: 'flex',
          justifyContent: 'space-between'
        }}
      >
        <Typography sx={{ fontWeight: 'bold' }} variant="h4">
          Trust Service Criteria Applicability
        </Typography>
        {tscData !== null ? (
          <Button variant="contained" color="success" onClick={handleSave}>
            Save
          </Button>
        ) : null}
      </Box>
      {tscData !== null && tscData.data.rows.TrustCriteria.length > 0 ? (
        <CustomTable
          fieldsMeta={tscData.data.meta.fields}
          tableMeta={tscData.data.meta.TrustCriteria}
          rows={tscData.data.rows.TrustCriteria}
          handleChangeCB={handleRowChange}
          topStyle="25px"
        />
      ) : (
        <Box style={{ display: 'flex', justifyContent: 'center' }}>
          <Typography sx={{ fontWeight: 'bold', color: 'Red' }} variant="h5">
            No Records Found.
          </Typography>
        </Box>
      )}
    </React.Fragment>
  );
};

export default TrustServiceCriteria;
