import React, { useState, useEffect } from 'react';
import {
  getTasksRequest,
  createTimesheet
} from '../../../../constants/requests';
import { TableCell } from '@mui/material';
import { useDataLayerValue } from '../../../../contextAPI/DataLayer';
import { useLoading } from '../../../../hooks/useLoading';
import { useNotifications } from '../../../../hooks/useNotifications';
export const RowInp = ({ projectData, dateArray, trigger, defaultProject }) => {
  const [taskData, setTaskData] = useState([]);
  const [selectedProject, setSelectedProject] = useState('');
  const [selectedTask, setSelectedTask] = useState('');
  const { request } = useLoading();
  const [user] = useDataLayerValue();
  const [inpDate, setInpDate] = useState([]);

  const { showNotifications } = useNotifications();
  console.log(user);
  const getTaskData = async () => {
    try {
      if (selectedProject) {
        await request(() =>
          getTasksRequest(selectedProject).then((res) => {
            if (
              res.status === 200 &&
              [201, 200].includes(res.data.statusCode)
            ) {
              showNotifications('success', 'Tasks loaded successfully', 1000);
              // console.log(response.data.body);
              setTaskData(res.data.body);
              setSelectedTask(res.data.body[0]);
            }
          })
        );
        // console.log(response);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    console.log(selectedProject);
    getTaskData();
  }, [selectedProject]);
  useEffect(() => {
    const inpDate = [];
    for (const date of dateArray) {
      inpDate.push({ date, time: 0 });
    }
    setInpDate([...inpDate]);
  }, [dateArray]);
  function formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }

  const handleSubmit = async () => {
    try {
      for (const date of inpDate) {
        const data = {
          taskName: selectedTask.jobName,
          taskId: selectedTask._id,
          projectName: selectedProject,
          projectId: projectData.find(
            (project) => project.projectName == selectedProject
          )._id,
          employeeEmail: user.email,
          employeeId: user._id,
          employeeName: user.fname + ' ' + user.lname,
          isBillable: selectedTask.billableStatus,
          managerName: user.reportingManagerName,
          managerEmail: user.reportingManagerEmail,
          managerId: user.reportingManager
        };
        const d = new Date(date.date);
        data['date'] = formatDate(d);
        data['hoursSpent'] = Number(date.time);
        console.log(data);
        await request(() => {
          createTimesheet(data).then((data) => {
            if (data.data.statusCode == 201) {
              showNotifications(
                'success',
                `Timesheet for ${selectedProject} for ${selectedTask.jobName} task added successfully`,
                1000
              );
            } else {
              showNotifications('error', data.data.message);
            }
          });
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    console.log(trigger);
    if (trigger) handleSubmit();
  }, [trigger]);

  useEffect(() => {
    setSelectedProject(defaultProject);
  }, [defaultProject]);
  useEffect(() => {
    console.log(inpDate);
  }, [inpDate]);
  useEffect(() => {
    console.log(selectedTask);
  }, [selectedTask]);
  return (
    <>
      {/* <div className="" style={{ display: "flex", flexWrap: "noWrap" }}> */}
      <TableCell component="th" scope="row">
        <select
          onChange={(e) => {
            setSelectedProject(e.target.value);
          }}
        >
          {projectData.map((project) => {
            return (
              <option value={project.projectName}>{project.projectName}</option> // eslint-disable-line
            );
          })}
        </select>
      </TableCell>
      <TableCell component="th" scope="row">
        <select
          onChange={(e) => {
            console.log(e.target.value);
            setSelectedTask(
              taskData.find((task) => task._id === e.target.value)
            );
          }}
        >
          {taskData.map((task) => {
            return <option value={task._id}>{task.jobName}</option>; // eslint-disable-line
          })}
        </select>
      </TableCell>
      {dateArray.map((date, idx) => {
        return (
          <TableCell key={`dateArray-${idx}`}>
            <input
              type="number"
              name="HoursSpent"
              id=""
              placeholder="Hours Spent"
              min="0"
              max="15"
              onChange={(e) => {
                inpDate.find((x) => x.date == date).time = e.target.value;
                setInpDate([...inpDate]);
              }}
            />
          </TableCell>
        );
      })}
      {/* </div> */}
    </>
  );
};
