import * as React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

export default function PaymentForm({
  handleBack,
  handleNext,
  handleChange,
  employeeDetails
}) {
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Personal Information
      </Typography>
      <Box component="form" onSubmit={handleNext} sx={{ mt: 3 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <TextField
              required
              id="aadharNumber"
              name="aadharNumber"
              label="Aadhar Number"
              fullWidth
              autoComplete="aadharNumber"
              variant="standard"
              onChange={handleChange}
              value={employeeDetails.aadharNumber}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              required
              helperText="Add '-' if not applicable"
              id="SSN"
              name="SSN"
              label="SSN Number"
              fullWidth
              autoComplete="aadharNumer"
              variant="standard"
              onChange={handleChange}
              value={employeeDetails.SSN}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              required
              id="PAN"
              name="PAN"
              label="PAN Number"
              fullWidth
              autoComplete="PAN"
              variant="standard"
              onChange={handleChange}
              value={employeeDetails.PAN}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              required
              id="bankAccountNumber"
              name="bankAccountNumber"
              label="Bank Account Number"
              fullWidth
              autoComplete="bankAccountNumber"
              variant="standard"
              onChange={handleChange}
              value={employeeDetails.bankAccountNumber}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              required
              id="IFSCCode"
              name="IFSCCode"
              label="IFSC Code"
              fullWidth
              autoComplete="IFSCCode"
              variant="standard"
              onChange={handleChange}
              value={employeeDetails.IFSCCode}
            />
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button onClick={handleBack} sx={{ mt: 3, ml: 1 }}>
                Back
              </Button>

              <Button type="submit" variant="contained" sx={{ mt: 3, ml: 1 }}>
                {'Next'}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  );
}
