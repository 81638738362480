import React, { useState } from 'react';
import { Box, TextField } from '@mui/material';

//onChange, onBlur is should call that bad boi -- done also, now what,
//give it some stuff to work its magic with, simple no??

const TextFieldColumn = ({ type, data, onChangeCB }) => {
  const [name, setName] = useState(data.name);
  const [email, setEmail] = useState(data.email);
  const [role, setRole] = useState(data.role);
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        height: '100px'
      }}
    >
      <TextField
        key={`${type}-name-1`}
        id="outlined-basic"
        label="Name"
        variant="outlined"
        value={name}
        onChange={(event) => {
          setName(event.target.value);
        }}
        onBlur={() => {
          onChangeCB(type, name, email, role);
        }}
      />
      <TextField
        key={`${type}-email-2`}
        id="outlined-basic"
        label="Email"
        variant="outlined"
        value={email}
        onChange={(event) => {
          setEmail(event.target.value);
        }}
        onBlur={() => {
          onChangeCB(type, name, email, role);
        }}
      />
      <TextField
        key={`${type}-role-3`}
        id="outlined-basic"
        label="Role"
        variant="outlined"
        value={role}
        onChange={(event) => {
          setRole(event.target.value);
        }}
        onBlur={() => {
          onChangeCB(type, name, email, role);
        }}
      />
    </Box>
  );
};

export default TextFieldColumn;
