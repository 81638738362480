import React, { useState, createContext, useContext, useMemo } from 'react';

const NotificationsContext = createContext();

export const NotificationsProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [type, setType] = useState('success');
  const [message, setMessage] = useState('');
  const [duration, setDuration] = useState(5000);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') return;
    setIsOpen(false);
  };

  const showNotifications = (type, message, duration) => {
    setType(type);
    setMessage(message);
    setDuration(duration);
    setIsOpen(true);
  };

  const value = useMemo(
    () => ({
      isOpen,
      type,
      message,
      duration,
      handleClose,
      showNotifications
    }),
    [isOpen]
  );

  return (
    <NotificationsContext.Provider value={value}>
      {children}
    </NotificationsContext.Provider>
  );
};

export const useNotifications = () => useContext(NotificationsContext);
