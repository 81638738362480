export const clients = {
  _id: 'Client ID',
  name: 'Name',
  type: 'Type',
  riskType: 'Risk Type',
  currency: 'Currency',
  billingMethod: 'Billing Method',
  address: 'Address',
  city: 'City',
  state: 'State',
  zip: 'Zip',
  country: 'Country',
  phone: 'Phone',
  email: 'Email',
  registeredDate: 'Date of Registration'
};
