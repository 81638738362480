import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
  FormControl,
  Select,
  MenuItem,
  Typography
} from '@mui/material';
import React from 'react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { HeaderWithCreateModal } from '../../../components/Master/HeaderWithCreateModal';
import {
  getRightsRequest,
  getProfilesRequest,
  updateProfileRequest,
  getEntitiesRequest
} from '../../../constants/requests';
import { PROFILES_URL } from '../../../constants/urls';
import { useDataLayerValue } from '../../../contextAPI/DataLayer';
import { useLoading } from '../../../hooks/useLoading';
import { useNotifications } from '../../../hooks/useNotifications';

const profileCreationFields = (entities) => [
  {
    label: 'Profile Name*',
    name: 'name',
    display: 'required',
    type: 'text'
  },
  {
    label: 'Activity Type*',
    name: 'activityId',
    display: 'required',
    type: 'dropdown',
    value: entities,
    info: ['name', 'id']
  }
];

export const ProfileRights = () => {
  const { request } = useLoading();
  const { showNotifications } = useNotifications();
  const [updated, setUpdated] = useState(false);
  const [rights, setRights] = useState([]);
  const [profiles, setProfiles] = useState([]);
  const [entities, setEntities] = useState([]);
  const [user] = useDataLayerValue();
  const { CREATE, EDIT, VIEW_REPORT } = user.rights.ProfileRight;
  const navigate = useNavigate();
  if (VIEW_REPORT === false) {
    navigate('/');
  }

  const handleRightChange = async (profile, right) => {
    if (EDIT) {
      //if right is present in profile.rights remove it and vice versa
      const idx = profile.rights.indexOf(right),
        updatedRights = profile.rights;
      if (idx === -1) {
        updatedRights.push(right);
      } else {
        updatedRights.splice(idx, 1);
      }
      request(async () => {
        updateProfileRequest(profile._id, {
          rights: updatedRights
        }).then((response) => {
          if (response.data.statusCode === 200) {
            showNotifications('success', response.data.message, 5000);
            setUpdated(!updated);
          } else {
            showNotifications('error', response.data.message, 5000);
          }
        });
      });
    }
  };

  const changeProfileActivity = async (profile, newActivityId) => {
    if (EDIT) {
      request(async () => {
        updateProfileRequest(profile._id, {
          activityId: newActivityId
        }).then((response) => {
          if (response.data.statusCode === 200) {
            showNotifications('success', response.data.message, 5000);
            setUpdated(!updated);
          } else {
            showNotifications('error', response.data.message, 5000);
          }
        });
      });
    }
  };

  const getRights = async () => {
    const rightsResponse = await request(getRightsRequest);
    const rightsArray = rightsResponse.data.body.map((right) => right.name);
    setRights(rightsArray);
  };

  const getProfiles = async () => {
    const profilesResponse = await request(getProfilesRequest);
    setProfiles(profilesResponse.data.body);
  };

  const getEntities = async () => {
    const entitiesResponse = await request(getEntitiesRequest);
    setEntities(entitiesResponse.data.body.registeredEntities);
  };

  useEffect(() => {
    document.title = 'Profile Rights';
    getRights();
    getProfiles();
    getEntities();
  }, [updated]);

  return (
    <>
      <HeaderWithCreateModal
        title="Profile Rights"
        options={{ createOption: CREATE }}
        creationFields={profileCreationFields(entities)}
        reqUrl={PROFILES_URL}
        reqHeaders={{}}
      />
      <TableContainer
        component={Paper}
        sx={{ width: '100%', mt: 5, maxHeight: '70vh' }}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow key="head">
              <TableCell align="left">
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                  Profiles
                </Typography>
              </TableCell>
              <TableCell align="left">
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                  Activity
                </Typography>
              </TableCell>
              {rights.map((columnHead, idx) => {
                return (
                  <TableCell key={`rights-1234-${idx}`} align="left">
                    <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                      {columnHead}
                    </Typography>
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {profiles.map((profile, profileIdx) => {
              return (
                <TableRow
                  key={profile.name}
                  sx={{
                    '&:last-child td, &:last-child th': {
                      border: 0
                    }
                  }}
                >
                  <TableCell>{profile.name}</TableCell>
                  <TableCell>
                    <FormControl fullWidth>
                      {/* {Select for configuring the activityId of profile} */}
                      <Select
                        id={`${profile.name} Activity Control`}
                        value={profiles[profileIdx].activityId}
                        onChange={(event) => {
                          changeProfileActivity(
                            profiles[profileIdx],
                            event.target.value
                          );
                        }}
                      >
                        {entities.map((entity) => {
                          return (
                            <MenuItem value={entity.id} key={entity.id}>
                              {entity.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </TableCell>
                  {/* {These TableCells are for configuring and updating the various rights a profile has} */}
                  {rights.map((right, idx) => {
                    return (
                      <TableCell key={`rights-9999-${idx}`}>
                        <Checkbox
                          checked={profile.rights.includes(right)}
                          onChange={() => {
                            handleRightChange(profile, right);
                          }}
                        />
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
