import React, { useEffect, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useNavigate, Link } from 'react-router-dom';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import { headers } from '../../../constants/WorkflowTypeHeaders';
import axios from 'axios';
import { useDataLayerValue } from '../../../contextAPI/DataLayer';
import { BACKEND_URL } from '../../../config';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
function WorkflowType() {
  const navigate = useNavigate();
  const [entities, setEntities] = useState({});
  const [workflows, setWorkflows] = useState([]);
  const [user] = useDataLayerValue();
  const { _id } = user;
  const { CREATE, DELETE, VIEW_REPORT } = user.rights.WorkflowType;
  if (VIEW_REPORT === false) {
    navigate('/');
  }
  useEffect(() => {
    document.title = 'Workflow Types';
    axios
      .get(BACKEND_URL + '/workflow/', {
        headers: {
          employeeid: _id
        }
      })
      .then((res) => setWorkflows(res.data.body))
      .catch((er) => console.log(er));
    axios
      .get(BACKEND_URL + '/entities/', {
        headers: {
          employeeid: _id
        }
      })
      .then((res) => {
        const entitiesArray = res.data.body.registeredEntities;
        let entityObj = {};
        entitiesArray.forEach((entity) => {
          entityObj[entity.id] = entity.name;
        });
        setEntities(entityObj);
      })
      .catch((er) => console.log(er));
  }, []);

  async function handleWorkflowDelete(id) {
    const resp = window.confirm('Are You Sure to Delete the Workflow Type');
    console.log(resp);
    if (resp) {
      await axios
        .delete(BACKEND_URL + '/workflow/' + id, {
          headers: {
            employeeid: _id
          }
        })
        .then(() => {
          window.location.reload();
        })
        .catch((er) => console.log(er));
    }
  }

  return (
    <React.Fragment>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
          m: -1
        }}
      >
        <Typography sx={{ m: 1, fontWeight: 'bold' }} variant="h4">
          Workflow Type
        </Typography>

        {CREATE ? (
          <Button
            variant="contained"
            onClick={() => navigate('/workflow/type/add')}
            sx={{ color: 'white', backgroundColor: 'black' }}
          >
            Add Workflow Type
          </Button>
        ) : null}
      </Box>
      <TableContainer
        component={Paper}
        sx={{ width: '100%', mt: 5, maxHeight: '70vh' }}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow key="head">
              {headers.map((field, idx) => {
                return (
                  <TableCell key={idx} align="center">
                    <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                      {field.label}
                    </Typography>
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {workflows.map((workflow) => {
              return (
                <TableRow key={workflow._id}>
                  {headers.map((field, idx) => {
                    return field.key === 'viewDetails' ? (
                      <TableCell key={idx} align="center">
                        <Link to={'/workflow/type/' + workflow['_id']}>
                          View Details
                        </Link>
                      </TableCell>
                    ) : field.key === 'actions' ? (
                      <TableCell key={idx} align="center">
                        {DELETE ? (
                          <IconButton
                            color="primary"
                            onClick={() =>
                              handleWorkflowDelete(workflow['_id'])
                            }
                          >
                            <DeleteIcon />
                          </IconButton>
                        ) : null}
                      </TableCell>
                    ) : (
                      <TableCell key={idx} align="center">
                        {field.key === 'activityId'
                          ? entities[workflow[field.key]]
                          : workflow[field.key]}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </React.Fragment>
  );
}

export default WorkflowType;
