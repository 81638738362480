import React from 'react';
import { TextField, Box, Modal, Button, Typography } from '@mui/material';
import FormControl from '@mui/material/FormControl';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  borderRadius: '10px',
  boxShadow: 24,
  p: 4,
  maxHeight: '100%',
  minWidth: '600px'
};

const AddPolicyScopeModal = ({
  openDeleteModal,
  handleCloseDeleteModal,
  typeName,
  handleTypeNameChange,
  handleAddType
}) => {
  return (
    <Modal
      open={openDeleteModal}
      onClose={handleCloseDeleteModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: 2
          }}
        >
          <Typography variant="h5" component="h2">
            Add Policy Scope
          </Typography>
        </Box>
        <Box
          style={{
            width: '100%',
            display: 'flex',
            gap: '10px',
            marginBottom: '10px',
            alignItems: 'flex-start',
            flexDirection: 'column'
          }}
        >
          <FormControl style={{ width: '100%' }}>
            <TextField
              label="Type Name"
              value={typeName}
              onChange={handleTypeNameChange}
            />
          </FormControl>

          <FormControl style={{ width: '100%' }}>
            <Button
              variant="contained"
              onClick={() => {
                handleAddType();
                handleCloseDeleteModal();
              }}
            >
              Add Policy Scope
            </Button>
          </FormControl>
        </Box>
      </Box>
    </Modal>
  );
};

export default AddPolicyScopeModal;
