import React, { useEffect, useState } from 'react';
import { Box, Modal, Typography, Button } from '@mui/material';
import {
  getGenericPolicyMasterById,
  updateGenericPolicyMasterById
} from '../../../constants/requests';
import { useLoading } from '../../../hooks/useLoading';
import { useNotifications } from '../../../hooks/useNotifications';
import GetTableCell from '../Components/GetTableCell';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 750,
  bgcolor: 'white',
  boxShadow: 24,
  borderRadius: '10px',
  maxHeight: '85vh',
  overflowY: 'auto',
  p: 4
};

const PreviewGenericPolicyModal = ({
  openPreviewGenericPolicyModal,
  handleClosePreviewGenericPolicyModal,
  selectPolicyId,
  openPreview,
  setOpenPreview
}) => {
  const { request } = useLoading();
  const { showNotifications } = useNotifications();
  const [policyContent, setPolicyContent] = useState({
    generic_introduction: '',
    generic_objective: '',
    generic_scope: '',
    generic_responsibility: '',
    generic_exceptions: '',
    generic_violation: '',
    generic_review: '',
    generic_reference: ''
  });

  const getPolicyDetails = async () => {
    try {
      const response = await request(() =>
        getGenericPolicyMasterById(selectPolicyId)
      );
      if (response.data.statusCode === 200) {
        console.log(response);
        const { name, _id, __v, ...updatedPolicyContent } = response.data.body;
        setPolicyContent(updatedPolicyContent);
        name;
        _id;
        __v;
      } else {
        showNotifications('error', response.data.message, 5000);
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  const updatePolicyDetails = async () => {
    try {
      const updatedClientData = { ...policyContent };
      const response = await request(() =>
        updateGenericPolicyMasterById(selectPolicyId, updatedClientData)
      );
      if (response.data.statusCode === 200) {
        showNotifications('success', 'Save successful', 5000);
        handleClosePreviewGenericPolicyModal();
      } else {
        showNotifications('error', response.data.message, 5000);
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  const policyContentChangeHandler = (rowNumber, heading, value) => {
    const newPolicyContent = { ...policyContent };
    newPolicyContent[heading] = value;
    setPolicyContent(newPolicyContent);
  };

  useEffect(() => {
    if (selectPolicyId) getPolicyDetails();
  }, []);

  if (openPreview) {
    getPolicyDetails();
    setOpenPreview(false);
  }

  return (
    <Modal
      open={openPreviewGenericPolicyModal}
      onClose={handleClosePreviewGenericPolicyModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography
          id="modal-modal-title"
          variant="h4"
          component="h2"
          sx={{ textAlign: 'center', marginBottom: 1, fontWeight: 600 }}
        >
          Current Policy Preview
        </Typography>
        <Box>
          {Object.keys(policyContent)
            .slice(0, 4)
            .map((heading) => {
              return (
                <Box key={Math.random()} sx={{ marginTop: '10px' }}>
                  <Typography
                    variant="h6"
                    sx={{ fontWeight: '600', fontSize: '25px' }}
                    key={Math.random()}
                  >
                    {heading.split('_')[1].charAt(0).toUpperCase() +
                      heading.split('_')[1].slice(1)}{' '}
                  </Typography>
                  <GetTableCell
                    key={Math.random()}
                    rowNumber={'1'}
                    fieldName={heading}
                    fieldType={'multiLineTextField'}
                    value={policyContent[heading]}
                    onChangeCB={policyContentChangeHandler}
                    isEditable={true}
                  />
                </Box>
              );
            })}

          {Object.keys(policyContent)
            .slice(4)
            .map((heading) => {
              return (
                <Box key={Math.random()} sx={{ marginTop: '10px' }}>
                  <Typography
                    variant="h6"
                    sx={{ fontWeight: '600', fontSize: '25px' }}
                    key={Math.random()}
                  >
                    {heading.split('_')[1].charAt(0).toUpperCase() +
                      heading.split('_')[1].slice(1)}{' '}
                  </Typography>
                  <GetTableCell
                    key={Math.random()}
                    rowNumber={'1'}
                    fieldName={heading}
                    fieldType={'multiLineTextField'}
                    value={policyContent[heading]}
                    onChangeCB={policyContentChangeHandler}
                    isEditable={true}
                  />
                </Box>
              );
            })}
        </Box>

        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '2px'
          }}
        >
          <Button
            variant="contained"
            style={{ marginTop: '10px' }}
            onClick={updatePolicyDetails}
          >
            Save
          </Button>
          <Button
            variant="contained"
            style={{ marginTop: '10px' }}
            onClick={handleClosePreviewGenericPolicyModal}
          >
            Close
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default PreviewGenericPolicyModal;
