import React, { useState } from 'react';
import {
  Table,
  TableCell,
  tableCellClasses,
  TableHead,
  TableRow,
  TableBody,
  TableContainer
} from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import GetTableCell from './GetTableCell';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14
  },
  padding: '0px 5px 0px 5px'
}));

const StyledTableRow = styled(TableRow)(() => ({
  '&:last-child td, &:last-child th': {
    border: 0
  },
  padding: '0px auto',
  margin: '0px'
}));

const isReceivedOptions = [
  {
    value: 'true',
    label: 'Yes'
  },
  {
    value: 'false',
    label: 'No'
  }
];

function ModalTable({ data, save }) {
  const [sample, setSample] = useState(data);

  const handleChange = (idx, name, value) => {
    const newData = Object.assign(sample);
    newData[idx][name] = value;
    setSample(newData);
    save(newData);
  };

  const getTable = () => {
    return data.map((val, idx) => (
      <StyledTableRow
        style={{ cursor: 'pointer' }}
        hover
        key={`row-${uuidv4()}`}
      >
        {Object.keys(val).map((key) => {
          return key === 'isReceived' ||
            key === 'Evidence Received (Yes/No)' ? (
            <StyledTableCell
              style={{
                minWidth: '100px',
                minHeight: '0px',
                padding: '10px'
              }}
              align="left"
            >
              <GetTableCell
                fieldName={key}
                rowNumber={idx}
                value={val[key]}
                isEditable="true"
                options={isReceivedOptions}
                fieldType="dropDown"
                onChangeCB={(rowNumber, fieldName, cellValue) =>
                  handleChange(rowNumber, fieldName, cellValue)
                }
              />
            </StyledTableCell>
          ) : (
            key !== '_id' && (
              <StyledTableCell
                style={{
                  minWidth: '100px',
                  minHeight: '0px',
                  padding: '10px'
                }}
                align="left"
              >
                {val[key].toString()}
              </StyledTableCell>
            )
          );
        })}
      </StyledTableRow>
    ));
  };

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table
          stickyHeader
          sx={{ minWidth: 700 }}
          aria-label="customized table"
        >
          <TableHead>
            <TableRow>
              {data &&
                Object.keys(data[0]).map((header) => {
                  return (
                    header !== '_id' && (
                      <StyledTableCell
                        align="left"
                        style={{
                          minWidth: '100px'
                        }}
                      >
                        {header}
                      </StyledTableCell>
                    )
                  );
                })}
            </TableRow>
          </TableHead>
          <TableBody>{getTable()}</TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}

export default ModalTable;
