import React, { useEffect } from 'react';
import {
  Box,
  Button,
  Table,
  TableCell,
  tableCellClasses,
  TableHead,
  TableRow,
  TableBody,
  TableContainer,
  Typography
} from '@mui/material';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { getAllPolicyEngagement } from '../../../constants/requests';
import { useLoading } from '../../../hooks/useLoading';
import { useNotifications } from '../../../hooks/useNotifications';
import DeleteClientPolicyModal from '../Components/DeleteClientPolicyModal';
import { useDataLayerValue } from '../../../contextAPI/DataLayer';
import HelpIcon from '@mui/icons-material/Help';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14
  }
}));

const StyledTableRow = styled(TableRow)(() => ({
  '&:last-child td, &:last-child th': {
    border: 0
  }
}));

const PolicyEngagements = () => {
  const navigate = useNavigate();
  const { request } = useLoading();
  const { showNotifications } = useNotifications();
  const [{ _id }] = useDataLayerValue();

  const [tableData, setTableData] = useState([]);

  const getClients = async () => {
    try {
      const response = await request(() => getAllPolicyEngagement(_id));
      if (response?.data?.statusCode === 200) {
        const newTable = response.data.body.map((data) => {
          return {
            id: data._id,
            clientName: data.clientName,
            industry: data.industry,
            subIndustry: data.subIndustry,
            country: data.country
          };
        });
        setTableData(newTable);
      } else {
        if (response.response.data.statusCode !== 200) {
          showNotifications('error', response.response.data.message, 5000);
        }
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  console.log(tableData);

  useEffect(() => {
    getClients();
  }, []);

  const [selectEngagementId, setSelectedEngagementId] = useState('');

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const handleOpenDeleteModal = () => setOpenDeleteModal(true);
  const handleCloseDeleteModal = () => setOpenDeleteModal(false);

  return (
    <React.Fragment>
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '30px'
        }}
      >
        <Typography sx={{ fontWeight: 'bold', flexGrow: 1 }} variant="h4">
          Policy Engagements
        </Typography>
        <Box>
          <Button
            sx={{
              color: 'white',
              backgroundColor: 'primary',
              height: 50,
              marginTop: '3px',
              marginRight: '10px'
            }}
            variant="contained"
            onClick={() => {
              window.open(
                'https://drive.google.com/drive/folders/1OX4tlEX8hrb3y_MDCdXFEeEWXd9_U43h?usp=sharing'
              );
            }}
          >
            <HelpIcon />
          </Button>
          <Button
            sx={{
              color: 'white',
              backgroundColor: 'black',
              height: 50,
              marginTop: '3px'
            }}
            variant="contained"
            onClick={() => {
              navigate('add');
            }}
          >
            Add Policy Engagement
          </Button>
        </Box>
      </Box>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 500 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell key={`s.no`} align="left">
                S.no
              </StyledTableCell>
              <StyledTableCell key={'client'}>Client Name</StyledTableCell>
              <StyledTableCell key={`industry`} align="left">
                Industry
              </StyledTableCell>
              <StyledTableCell key={`sub-industry`} align="left">
                Sub-Industry
              </StyledTableCell>
              <StyledTableCell key={`country`} align="left">
                Country
              </StyledTableCell>
              <StyledTableCell align="center">Options</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData.map((data, idx) => {
              return (
                <StyledTableRow
                  hover
                  style={{ cursor: 'pointer' }}
                  key={`policy-row-${idx}-1`}
                >
                  <StyledTableCell
                    key={`policy-row-${idx}-2`}
                    onClick={() => {
                      navigate(`/policy/engagement/${data.id}`);
                    }}
                  >
                    {idx + 1}
                  </StyledTableCell>
                  <StyledTableCell
                    key={`policy-row-${idx}-3`}
                    onClick={() => {
                      navigate(`/policy/engagement/${data.id}`);
                    }}
                  >
                    {data.clientName}
                  </StyledTableCell>
                  <StyledTableCell
                    key={`policy-row-${idx}-4`}
                    onClick={() => {
                      navigate(`/policy/engagement/${data.id}`);
                    }}
                  >
                    {data.industry}
                  </StyledTableCell>
                  <StyledTableCell
                    key={`policy-row-${idx}-5`}
                    onClick={() => {
                      navigate(`/policy/engagement/${data.id}`);
                    }}
                  >
                    {data.subIndustry}
                  </StyledTableCell>
                  <StyledTableCell
                    key={`policy-row-${idx}`}
                    onClick={() => {
                      navigate(`/policy/engagement/${data.id}`);
                    }}
                  >
                    {data.country}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {
                      <Box
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          gap: '5px'
                        }}
                      >
                        <EditIcon
                          onClick={() => {
                            navigate(`/policy/engagement/${data.id}/edit`);
                          }}
                        />
                        <DeleteIcon
                          onClick={() => {
                            handleOpenDeleteModal();
                            setSelectedEngagementId(data.id);
                          }}
                        />
                      </Box>
                    }
                  </StyledTableCell>
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <DeleteClientPolicyModal
        openDeleteModal={openDeleteModal}
        handleCloseDeleteModal={handleCloseDeleteModal}
        selectEngagementId={selectEngagementId}
        getClients={getClients}
      />
    </React.Fragment>
  );
};

export default PolicyEngagements;
