import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Button,
  Typography,
  TextField,
  Modal,
  Collapse,
  MenuItem
} from '@mui/material';
import {
  getTemplateById,
  getAllService,
  updateTemplate,
  reuploadTemplate,
  createDocument,
  getDocumentsByTemplateId,
  reuploadDocument
} from '../../../constants/requests';
import { style } from '../common/styles';
import { useLoading } from '../../../hooks/useLoading';
import { useNavigate, useParams } from 'react-router-dom';
import { useNotifications } from '../../../hooks/useNotifications';
import SetTable from '../common/SetTable';
import TemplateTable from '../common/TemplateTable';
import VersionManager from '../common/VersionManager';
import SingleLineForm from '../common/SingleLineForm';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import DeleteDialog from '../../Services/common/DeleteDialog';
import ModalNotFound from '../../Services/common/ModalNotFound';
import CreateEditDialog from '../../Services/common/CreateEditDialog';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

const screenWise = {
  field1: 'fieldName',
  field2: 'isEditable',
  field3: 'displayName'
};

const templateGroupOptions = [
  {
    label: 'RCM',
    value: 'RCM'
  },
  {
    label: 'GAP_TRACKER',
    value: 'GAP_TRACKER'
  },
  {
    label: 'ORG_TABLE',
    value: 'ORG_TABLE'
  },
  {
    label: 'POINT_OF_FOCUS',
    value: 'POINT_OF_FOCUS'
  },
  {
    label: 'TRUST_CRITERIA',
    value: 'TRUST_CRITERIA'
  },
  {
    label: 'SCF',
    value: 'SCF'
  },
  {
    label: 'ASSET_REGISTER',
    value: 'ASSET_REGISTER'
  },
  {
    label: 'SECTION3_HEADER',
    value: 'SECTION3_HEADER'
  },
  {
    label: 'CUEC_CSOC',
    value: 'CUEC_CSOC'
  },
  {
    label: 'GUIDELINE',
    value: 'GUIDELINE'
  },
  {
    label: 'THREAT',
    value: 'THREAT'
  },
  {
    label: 'THREAT_VULENRABILITY',
    value: 'THREAT_VULENRABILITY'
  },
  {
    label: 'RISK_REGISTER',
    value: 'RISK_REGISTER'
  },
  {
    label: 'GAP_ASSESSMENT',
    value: 'GAP_ASSESSMENT'
  },
  {
    label: 'INTERNAL_AUDIT',
    value: 'INTERNAL_AUDIT'
  }
];

const fieldTypeOptions = [
  {
    label: 'TEXT',
    value: 'textField'
  },
  {
    label: 'MULTI-LINE TEXT',
    value: 'multiLineTextField'
  },
  {
    label: 'DATE',
    value: 'date'
  },
  {
    label: 'DROPDOWN',
    value: 'dropDown'
  },
  {
    label: 'MULTI-SELECT DROPDOWN',
    value: 'multiSelect'
  }
];

const pagesOptions = [
  {
    label: 'UNDERSTANDING',
    value: 'UNDERSTANDING'
  },
  {
    label: 'RCM',
    value: 'RCM'
  },
  {
    label: 'DRL',
    value: 'DRL'
  },
  {
    label: 'TESTING',
    value: 'TESTING'
  },
  {
    label: 'CSA',
    value: 'CSA'
  },
  {
    label: 'MLP',
    value: 'MLP'
  },
  {
    label: 'AIT',
    value: 'AIT'
  }
];

const editableOptions = [
  {
    label: 'YES',
    value: true
  },
  {
    label: 'NO',
    value: false
  }
];

const pageMeta = {
  pageName: {
    isEditable: false,
    fieldType: 'multiLineTextField',
    displayName: 'Page Name'
  },
  displayName: {
    isEditable: true,
    fieldType: 'multiLineTextField',
    displayName: 'Page Display Name'
  },
  Action: {
    isEditable: true,
    fieldType: 'button',
    displayName: 'Action'
  }
};

const pageToGroupMap = {
  RCM: ['RCM', 'Testing', 'CSA', 'TestingSheetTab', 'MLP', 'AIT'],
  GAP_TRACKER: ['GapTracker'],
  ORG_TABLE: ['OrgTable'],
  POINT_OF_FOCUS: ['PointOfFocus'],
  DRL: ['DRL'],
  SCF: ['SCF', 'PolicyControls', 'LastApprovedPolicyControls'],
  ASSET_REGISTER: ['AssetRegister'],
  CUEC_CSOC: ['CUEC', 'CSOC'],
  TRUST_CRITERIA: ['TrustCriteria'],
  SECTION3_HEADER: ['SectionsHeader'],
  GUIDELINE: ['ThreatTable', 'NormalizedThreatImpact', 'ThreatImpact'],
  THREAT: ['Threat'],
  THREAT_VULENRABILITY: ['ThreatVulnerability'],
  RISK_REGISTER: ['RiskRegister'],
  GAP_ASSESSMENT: ['GapTracker', 'SOA'],
  INTERNAL_AUDIT: ['InternalAudit']
};

const styleAdditionalFormContainer = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '10px'
};

const TemplateCreator = () => {
  const navigate = useNavigate();
  const initialRender = useRef(true);
  const { request } = useLoading();
  const { templateId } = useParams();
  const { showNotifications } = useNotifications();
  const [delIdx, setDelIdx] = useState(null);
  const [tableName, setTableName] = useState(null);
  const [serviceId, setServiceId] = useState(null);
  const [templateName, setTemplateName] = useState(null);
  const [docManagerId, setDocManagerId] = useState(null);
  const [templateGroup, setTemplateGroup] = useState(null);
  const [testingSheetType, setTestingSheetType] = useState(null);
  const [pageOpen, setPageOpen] = useState(false);
  const [fieldOpen, setFieldOpen] = useState(false);
  const [deleteCall, setDeleteCall] = useState(false);
  const [openTableModal, setOpenTableModal] = useState(false);
  const [openAddPageModal, setopenAddPageModal] = useState(false);
  const [openDeletePageModal, setopenDeletePageModal] = useState(false);
  const [rowData, setRowData] = useState({});
  const [metaData, setMetaData] = useState({});
  const [pageList, setPageList] = useState([]);
  const [tableView, setTableView] = useState([]);
  const [fieldTable, setFieldTable] = useState([]);
  const [serviceList, setServiceList] = useState([]);
  const [fieldNameOptions, setFieldNameOptions] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [additionalField, setAdditionalField] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [testingTemplate, setTestingTemplate] = useState(null);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleopenAddPageModal = () => setopenAddPageModal(true);
  const handleCloseAddPageModal = () => setopenAddPageModal(false);
  const handleOpenDeletePageModal = (idx) => {
    setDelIdx(idx);
    setopenDeletePageModal(true);
  };
  const handleCloseDeletePageModal = () => {
    setDelIdx(null);
    setopenDeletePageModal(false);
  };

  const createPagesModalFormFields = [
    {
      type: 'dropDown',
      fieldName: 'pages',
      fieldLabel: 'Select Page to be added',
      dropDownOptions: pagesOptions,
      value: '',
      required: true
    }
  ];
  const getTestingTemplate = async () => {
    try {
      const res = await request(() => getDocumentsByTemplateId(templateId));
      if (res.data.statusCode === 200) {
        res.data.body.map((item) => {
          if (item.documentType === 'testing') setTestingTemplate(item);
        });
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };
  const tableMeta = {
    fieldName: {
      isEditable: false,
      fieldType: 'multiLineTextField',
      displayName: 'Field Name'
    },
    fieldType: {
      isEditable: true,
      fieldType: 'dropDown',
      options: fieldTypeOptions,
      displayName: 'Type'
    },
    options: {
      isEditable: true,
      fieldType: 'multiLineTextField',
      displayName: 'Options'
    }
  };
  /**
   *  @author Tejas Sharma
   * Two types of Tables are defined in this screen
   * 1. Mazar
   * 2. BDO
   * Mazar is the default one where there are no seperate testing sheet for every row, so for any application
   * where the RCM is created using template with testing sheet type Mazar
   *
   * For BDO
   * There are seperate testing sheets for every row, so for any application
   *
   */

  const testingSheetTypeOptions = [
    {
      label: 'Mazar',
      value: 'Mazar'
    },
    {
      label: 'BDO',
      value: 'BDO'
    }
  ];
  const handleCloseTableModal = () => {
    setTableName(null);
    setTableView([]);
    setOpenTableModal(false);
  };

  const createFieldOptions = (data) => {
    const fieldOptions = Object.keys(data.fields).map((key) => {
      return {
        value: key,
        label: key
      };
    });
    setFieldNameOptions(fieldOptions);
  };

  const handlePageInfoChange = (rowNumber, fieldName, fieldValue) => {
    const newPageList = [...pageList];
    newPageList[rowNumber][fieldName] = fieldValue;
    setPageList(newPageList);
  };

  const addAdditionalField = async () => {
    try {
      metaData.fields[additionalField] = { fieldType: 'multiLineTextField' };
      const payload = {
        templateName: templateName,
        templateGroup: templateGroup,
        data: {
          meta: metaData,
          rows: rowData
        },
        serviceId: serviceId,
        pages: pageList,
        testingSheetType: testingSheetType
      };

      const res = await request(() => updateTemplate(templateId, payload));
      if (res.data.statusCode === 200) {
        location.reload();
        showNotifications('success', res.data.message, 5000);
      } else {
        showNotifications('error', res.data.message, 5000);
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  const handleFieldsChange = (
    rowNumber,
    fieldName,
    fieldValue,
    isAdditional
  ) => {
    console.log(
      'Change Handled' + rowNumber + fieldName + fieldValue + isAdditional
    );
    const newFieldTable = [...fieldTable];
    newFieldTable[rowNumber][fieldName] = fieldValue;
    setFieldTable(newFieldTable);
    var newData = { ...metaData };
    console.log(JSON.stringify(newData));
    if (isAdditional) {
      var newField = {
        [fieldValue]: {
          fieldType: 'multiLineTextField'
        }
      };
      var keys = Object.keys(newData);
      var index = keys.indexOf('RowsToMerge');

      if (index !== -1) {
        keys.splice(index, 0, '[fieldValue]');
      }

      // Create a new object with the updated keys
      var updatedJsonObject = {};
      keys.forEach(function (key) {
        if (key === 'NewFieldName') {
          updatedJsonObject[key] = newField['NewFieldName'];
        } else {
          updatedJsonObject[key] = newData[key];
        }
      });
      console.log(updatedJsonObject['fields']);
      newData = updatedJsonObject;
    }
    console.log(JSON.stringify(newData));
    const fieldKeys = Object.keys(newData.fields);
    if (fieldName === 'fieldType') {
      newData['fields'][fieldKeys[rowNumber]]['fieldType'] = fieldValue;
    } else {
      if (fieldValue !== '' && !isAdditional) {
        const optionArr = fieldValue.split(',');
        newData['fields'][fieldKeys[rowNumber]]['options'] = optionArr.map(
          (key) => {
            return {
              label: key.trim(),
              value: key.trim()
            };
          }
        );
      } else {
        if (newData['fields'][fieldKeys[rowNumber]]['options'] !== undefined) {
          delete newData['fields'][fieldKeys[rowNumber]]['options'];
        }
      }
    }
    setMetaData(newData);
  };

  const handleScreenChange = (screenList, screenName) => {
    const newData = Object.assign(metaData);

    if (newData[screenName] === undefined) {
      newData[screenName] = {};
    }
    for (const screenObj of screenList) {
      const { fieldName, isEditable, displayName } = screenObj;
      if (fieldName === '') continue;
      newData[screenName][fieldName] = {};
      newData[screenName][fieldName]['isEditable'] = isEditable;
      if (displayName === '') {
        newData[screenName][fieldName]['displayName'] = fieldName;
      } else {
        newData[screenName][fieldName]['displayName'] = displayName;
      }
    }
    setMetaData(newData);
  };
  const handleUploadClose = () => {
    setOpenUploadModal(false);
    setSelectedFile(null);
    console.log(selectedFile);
    setErrorMessage(null);
  };
  const handleAddPage = async (newPage) => {
    try {
      const pageName = newPage.pages.value;
      const pageObj = { pageName, displayName: pageName };
      const newPageList = [...pageList, pageObj];
      handleCloseAddPageModal();
      const payload = {
        pages: newPageList
      };
      const res = await request(() => updateTemplate(templateId, payload));
      if (res.data.statusCode === 200) {
        setPageList(newPageList);
        showNotifications('success', res.data.message, 5000);
      } else {
        showNotifications('error', res.data.message, 5000);
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  const handleDeletePage = async () => {
    try {
      const filterPages = pageList.filter((_, pageIdx) => pageIdx !== delIdx);
      handleCloseDeletePageModal();
      const payload = {
        pages: filterPages
      };
      const res = await request(() => updateTemplate(templateId, payload));
      if (res.data.statusCode === 200) {
        setPageList(filterPages);
        showNotifications('success', res.data.message, 5000);
      } else {
        showNotifications('error', res.data.message, 5000);
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  const getServiceList = async () => {
    try {
      const res = await request(() => getAllService());
      if (res.data.statusCode === 200) {
        const services = res.data.body.map((service) => {
          return {
            label: service.name,
            value: service._id
          };
        });
        setServiceList(services);
      } else {
        showNotifications('error', res.data.message, 5000);
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  const handleTestingUpload = async () => {
    const formData = new FormData();
    formData.append('documentName', `${templateName}_testing`);
    formData.append('templateId', templateId);
    formData.append('document', selectedFile);
    formData.append('dataFetchingStrategy', 'SINGLE_APPLICATION');
    formData.append('documentType', 'testing');
    try {
      const res = await request(() => createDocument(formData));
      if (
        res?.response?.data?.statusCode === 422 ||
        res?.response?.data?.statusCode === 500
      ) {
        showNotifications('error', res.response.data.message, 5000);
      } else if (res.data.statusCode === 201) {
        showNotifications('success', res.data.message, 5000);
        // handleCreateDocumentClose();
      }
    } catch (error) {
      console.log(error);
    }
  };
  const loadTemplate = async () => {
    setFieldTable([]);
    try {
      const res = await request(() => getTemplateById(templateId));
      if (res.data.statusCode === 200) {
        const metaResponse = res.data.body.data.meta;
        const result = Object.keys(metaResponse.fields).map((key) => {
          return {
            fieldName: key,
            fieldType: metaResponse.fields[key]['fieldType'],
            options:
              metaResponse.fields[key]['options'] !== undefined
                ? metaResponse.fields[key]['options'].map((item) => item.value)
                : ''
          };
        });

        setFieldTable(result);
        setMetaData(metaResponse);
        createFieldOptions(metaResponse);
        setPageList(res.data.body.pages);
        setRowData(res.data.body.data.rows);
        setServiceId(res.data.body.serviceId);
        setTemplateName(res.data.body.templateName);
        setTemplateGroup(res.data.body.templateGroup);
        setDocManagerId(res.data.body.documentManagerId);
        setTestingSheetType(res.data.body.testingSheetType);
        showNotifications('success', res.data.message, 5000);
      } else {
        showNotifications('error', res.data.message, 5000);
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  const handleDeleteFields = async (idx) => {
    const oldData = Object.assign(metaData);
    const removeName = tableView[idx]['Field Name'];
    const newData = Object.fromEntries(
      Object.entries(oldData[tableName]).filter(([key]) => key !== removeName)
    );

    setMetaData({ ...oldData, [tableName]: newData });
    const newTableView = tableView.filter(
      (ele) => ele['Field Name'] !== removeName
    );
    setTableView(newTableView);
    setDeleteCall((deleteCall) => !deleteCall);
  };

  const handleView = async (currentTableName) => {
    setOpenTableModal(true);
    setTableName(currentTableName);
    try {
      const res = await request(() => getTemplateById(templateId));
      if (res.data.statusCode === 200) {
        const metaResponse = res.data.body.data.meta[currentTableName];
        if (metaResponse !== undefined) {
          const result = Object.keys(metaResponse).map((key) => {
            return {
              'Field Name': key,
              'isEditable?': metaResponse[key]['isEditable'],
              'Display Name':
                metaResponse[key]['displayName'] !== ''
                  ? metaResponse[key]['displayName']
                  : key,
              Action: 'Delete'
            };
          });
          setTableView(result);
        }
        showNotifications('success', res.data.message, 5000);
      } else {
        showNotifications('error', res.data.message, 5000);
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  const handleUpdateField = async () => {
    try {
      const payload = {
        templateName: templateName,
        templateGroup: templateGroup,
        data: {
          meta: metaData,
          rows: rowData
        },
        serviceId: serviceId,
        pages: pageList,
        testingSheetType: testingSheetType
      };

      const res = await request(() => updateTemplate(templateId, payload));
      if (res.data.statusCode === 200) {
        showNotifications('success', res.data.message, 5000);
      } else {
        showNotifications('error', res.data.message, 5000);
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  const handleAdditionalFieldChange = (e) => {
    setAdditionalField(e.target.value);
  };

  useEffect(() => {
    loadTemplate();
    getServiceList();
    getTestingTemplate();
    return () => {
      setRowData({});
      setMetaData({});
      setTableView([]);
      setFieldTable([]);
      // setTestingTemplate([]);
      // setTestingSheetType(null);
    };
  }, []);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
    } else {
      handleUpdateField();
    }
  }, [deleteCall]);

  return (
    <>
      <Box
        style={{
          marginBottom: '25px',
          display: 'flex',
          justifyContent: 'space-between'
        }}
      >
        <Button
          sx={{
            color: 'white',
            backgroundColor: 'black'
          }}
          variant="contained"
          onClick={() => navigate(`/templateEditor `)}
        >
          <ChevronLeftIcon />
          Template Home
        </Button>
      </Box>
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '30px'
        }}
      >
        <Typography sx={{ fontWeight: 'bold' }} variant="h4">
          Edit Template
        </Typography>
        <Button variant="contained" color="success" onClick={handleUpdateField}>
          Save
        </Button>
      </Box>
      <Box
        style={{
          marginBottom: '25px',
          display: 'flex',
          gap: '20px',
          justifyContent: 'space-between'
        }}
      >
        <TextField
          fullWidth
          value={templateName}
          name="templateName"
          label="Enter Template Name"
          onChange={(e) => setTemplateName(e.target.value)}
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          fullWidth
          select
          value={templateGroup}
          name="templateGroup"
          label="Select Template Group"
          onChange={(e) => setTemplateGroup(e.target.value)}
          InputLabelProps={{ shrink: true }}
        >
          {templateGroupOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          fullWidth
          select
          value={serviceId}
          name="serviceId"
          label="Select Service"
          onChange={(e) => setServiceId(e.target.value)}
          InputLabelProps={{ shrink: true }}
        >
          {serviceList.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          fullWidth
          select
          value={testingSheetType}
          name="testingSheetType"
          label="Select Testing Sheet Type"
          onChange={(e) => setTestingSheetType(e.target.value)}
          InputLabelProps={{ shrink: true }}
        >
          {testingSheetTypeOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </Box>

      <VersionManager
        heading="Templates"
        formDataText="master"
        entityId={templateId}
        uploadRequest={reuploadTemplate}
        refreshCB={loadTemplate}
        docManagerId={docManagerId}
      />

      {fieldTable.length > 0 ? (
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            marginBottom: '15px'
          }}
        >
          <Box
            style={{
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <div style={{ display: 'flex' }}>
              {fieldOpen ? (
                <KeyboardArrowDownIcon
                  style={{ marginTop: '5px' }}
                  onClick={() => setFieldOpen(!fieldOpen)}
                />
              ) : (
                <KeyboardArrowRightIcon
                  style={{ marginTop: '5px' }}
                  onClick={() => setFieldOpen(!fieldOpen)}
                />
              )}
              <Typography sx={{ marginBottom: '20px' }} variant="h5">
                Fields
              </Typography>
            </div>
            <Button
              variant="contained"
              sx={{ background: 'black', color: 'white' }}
              onClick={handleOpen}
            >
              Add Additional Field
            </Button>
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={styleAdditionalFormContainer}>
                <TextField
                  label="Field Name"
                  variant="outlined"
                  onChange={handleAdditionalFieldChange}
                />
                <Button
                  variant="contained"
                  onClick={() => addAdditionalField()}
                >
                  {' '}
                  Save{' '}
                </Button>
              </Box>
            </Modal>
          </Box>
          <Collapse in={fieldOpen}>
            <SetTable
              data={fieldTable}
              saveFields={handleFieldsChange}
              tableMeta={tableMeta}
              styles={style}
            />
          </Collapse>
        </Box>
      ) : null}

      {pageList.length > 0 ? (
        <>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: '15px'
            }}
          >
            <Box
              style={{
                display: 'flex',
                justifyContent: 'flex-start'
              }}
            >
              {pageOpen ? (
                <KeyboardArrowDownIcon
                  style={{ marginTop: '5px' }}
                  onClick={() => setPageOpen(!pageOpen)}
                />
              ) : (
                <KeyboardArrowRightIcon
                  style={{ marginTop: '5px' }}
                  onClick={() => setPageOpen(!pageOpen)}
                />
              )}
              <Typography variant="h5">Pages Info</Typography>
            </Box>
            <Box
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                gap: '10px'
              }}
            >
              <Box
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  gap: '10px'
                }}
              >
                <Button
                  sx={{ background: 'black', color: 'white' }}
                  variant="contained"
                  onClick={handleopenAddPageModal}
                >
                  Add
                </Button>
              </Box>
            </Box>
          </Box>
          <Box>
            <Collapse in={pageOpen}>
              <SetTable
                tableMeta={pageMeta}
                data={pageList}
                saveFields={handlePageInfoChange}
                styles={style}
                deleteCB={handleOpenDeletePageModal}
              />
            </Collapse>
          </Box>
        </>
      ) : null}

      {Object.keys(metaData).length !== 0 ? (
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: '15px'
          }}
        >
          <Typography sx={{ fontWeight: 'bold' }} variant="h4">
            Screen Wise Access
          </Typography>
        </Box>
      ) : null}

      {Object.keys(metaData).length !== 0 ? (
        <React.Fragment>
          {React.Children.toArray(
            pageToGroupMap[templateGroup].map((pageName) => (
              <SingleLineForm
                formData={screenWise}
                heading={pageName}
                fieldOptions={fieldNameOptions}
                typeOptions={editableOptions}
                saveFields={handleScreenChange}
                viewCB={handleView}
                testingSheetType={testingSheetType}
                setOpenUploadModal={setOpenUploadModal}
              />
            ))
          )}
        </React.Fragment>
      ) : null}

      <Modal
        open={openAddPageModal}
        onClose={handleCloseAddPageModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <CreateEditDialog
          formData={createPagesModalFormFields}
          onCloseCB={handleCloseAddPageModal}
          refreshPageContentCB={loadTemplate}
          entityName="Page"
          customUpdateCB={handleAddPage}
        />
      </Modal>

      <Modal open={openDeletePageModal} onClose={handleCloseDeletePageModal}>
        <DeleteDialog
          entityName={`Page ${delIdx + 1}`}
          customDeleteCB={handleDeletePage}
          onCloseCB={handleCloseDeletePageModal}
        />
      </Modal>

      <Modal
        open={openTableModal}
        onClose={handleCloseTableModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={style}
          style={{ width: '80%', height: '80%', overflowX: 'scroll' }}
        >
          <Box
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: '20px'
            }}
          >
            <Typography
              fullWidth
              id="modal-modal-title"
              variant="h5"
              component="h2"
            >
              {tableName}
            </Typography>
            <Button
              color="error"
              variant="contained"
              onClick={handleCloseTableModal}
            >
              Close
            </Button>
          </Box>
          {tableView.length > 0 ? (
            <TemplateTable data={tableView} deleteCB={handleDeleteFields} />
          ) : (
            <ModalNotFound data="Records For This Template" />
          )}
        </Box>
      </Modal>
      <Modal
        open={openUploadModal}
        onClose={handleUploadClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={style}
          style={{ width: '75%', height: '40%', overflowX: 'scroll' }}
        >
          {testingTemplate ? (
            <VersionManager
              heading="Testing Sheet"
              formDataText="document"
              entityId={testingTemplate['_id']}
              uploadRequest={reuploadDocument}
              refreshCB={() => console.log('pressed')}
              docManagerId={testingTemplate['documentManagerId']}
            />
          ) : (
            <>
              <Box
                style={{
                  marginBottom: '25px',
                  display: 'flex',
                  gap: '20px',
                  flexDirection: 'column'
                }}
              >
                <Typography
                  fullWidth
                  id="modal-modal-title"
                  variant="h5"
                  component="h2"
                  style={{ marginBottom: '10px' }}
                >
                  Upload Testing Template
                </Typography>
                {errorMessage && (
                  <Typography
                    variant="h5"
                    color="red"
                  >{`Error: ${errorMessage}`}</Typography>
                )}
              </Box>
              <Box
                style={{
                  display: 'flex',
                  justifyContent: 'space-around',
                  marginBottom: '40px'
                }}
              >
                <Typography variant="h5">Upload</Typography>
                <TextField
                  type="file"
                  name="file"
                  label="Upload Master"
                  onChange={(e) => setSelectedFile(e.target.files[0])}
                  InputLabelProps={{ shrink: true }}
                />
              </Box>
              <Box
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginTop: '30px'
                }}
              >
                <Button
                  color="error"
                  variant="contained"
                  onClick={handleUploadClose}
                >
                  Close
                </Button>
                <Button variant="contained" onClick={handleTestingUpload}>
                  Upload
                </Button>
              </Box>
            </>
          )}
        </Box>
      </Modal>
    </>
  );
};

export default TemplateCreator;
