import React from 'react';
import { TableRow, TableCell, TableBody } from '@mui/material';
import { Link } from 'react-router-dom';

export const TaskTableBody = ({ currHeaders, data }) => {
  // console.log(currHeaders);
  return (
    <TableBody>
      {data.body.map((row) => {
        return (
          <TableRow
            key={row._id}
            sx={{
              '&:last-child td, &:last-child th': { border: 0 }
            }}
          >
            {currHeaders.map((currHeader) => {
              return (
                <TableCell key={currHeader.id} align="center">
                  {typeof row[currHeader.id] === 'boolean' ? (
                    row[currHeader.id] ? (
                      'Yes'
                    ) : (
                      'No'
                    )
                  ) : currHeader.id === 'jobName' ? (
                    <Link to={`${row['jobName']}`}>{row['jobName']}</Link>
                  ) : (
                    row[currHeader.id]
                  )}
                </TableCell>
              );
            })}
          </TableRow>
        );
      })}
    </TableBody>
  );
};
