import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Modal,
  Typography,
  MenuItem,
  TextField
} from '@mui/material';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import { useLoading } from '../../../../hooks/useLoading';
import { useNotifications } from '../../../../hooks/useNotifications';
import {
  findInternalAuditById,
  findInternalAuditByProperties,
  updateInternalAuditById
} from '../../../../constants/requests';
import CustomGrid from '../../common/CustomGrid';
import CreateEditDialog from '../../common/CreateEditDialog';
import DeleteDialog from '../../common/DeleteDialog';
import UploadDialog from '../../AssetRepository/UploadAsset';

const InternalAudit = () => {
  const navigate = useNavigate();
  const [assetData, setAssetData] = useState(null);

  const [assetId, setId] = useState(null);
  const { request } = useLoading();
  const { showNotifications } = useNotifications();
  const [currentAssetTabName, setCurrentAssetTabName] = useState('');
  const [delRow, setDelRow] = useState(null);
  const [otherScreenFields, setOtherScreenFields] = useState('');

  const [addRecordForm, setAddRecordForm] = useState([]);
  const [openAddRecordModal, setOpenAddRecordModal] = useState(false);
  const [openDeleteRecordModal, setOpenDeleteRecordModal] = useState(false);

  const [openUploadModal, setOpenUploadModal] = useState(false);
  const handleOpenUploadModal = () => setOpenUploadModal(true);
  const handleCloseUploadModal = () => setOpenUploadModal(false);

  //const handleOpenAddRecordModal = () => setOpenAddRecordModal(true);
  const handleCloseAddRecordModal = () => setOpenAddRecordModal(false);
  const handleOpenDeleteRecordModal = (row) => {
    setDelRow(row);
    setOpenDeleteRecordModal(true);
  };
  const handleCloseDeleteRecordModal = () => {
    setDelRow(null);
    setOpenDeleteRecordModal(false);
  };

  const handleOpenAddRecordModal = () => createAddRecordForm();

  const createAddRecordForm = () => {
    const meta = assetData.data.meta;

    console.log(meta);

    const AssetRegister = meta.InternalAudit;
    const fields = meta.fields;

    console.log(fields);
    console.log(AssetRegister);

    const recordForm = Object.keys(AssetRegister)
      .map((key) => {
        if (key === 'SNo.') {
          return null;
        }
        return {
          type: fields[key]['fieldType'],
          fieldName: key,
          fieldLabel: `Enter ${key}`,
          value: '',
          required: false,
          dropDownOptions: fields[key]?.['options']
        };
      })
      .filter((field) => field !== null);

    const otherForm = Object.keys(fields).map((key) => {
      return {
        type: fields[key]['fieldType'],
        fieldName: key,
        fieldLabel: `Enter ${key}`,
        value: '',
        required: false,
        dropDownOptions: fields[key]?.['options']
      };
    });

    //console.log(otherForm);

    setAddRecordForm(recordForm);
    setOpenAddRecordModal(true);
    setOtherScreenFields(otherForm);
  };

  console.log(otherScreenFields);

  const getAssetRepositoryById = async (assetId) => {
    try {
      const res = await request(() => findInternalAuditById(assetId));
      //console.log(res.data.body.data.meta);
      //console.log(res.data.body.data.rows);

      if (res.data.statusCode === 200) {
        setAssetData(res.data.body);
        setCurrentAssetTabName(Object.keys(res.data.body.data.rows)[0]);
        //console.log(Object.keys(res.data.body.rows));
        //createAddRecordForm(res.data.body.data.meta);
        //setCurrentRCMTabName(Object.keys(res.data.body.data.rows)[0]);
        showNotifications('success', res.data.message, 5000);
      } else {
        showNotifications('error', res.data.message, 5000);
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  const getAssetRepository = async () => {
    try {
      const res = await request(() => findInternalAuditByProperties());
      console.log(res);
      if (res.data.statusCode === 200) {
        // Check if the assetData contains specific tags
        // console.log(res.data.body);

        getAssetRepositoryById(res.data.body[0]._id);
        setId(res.data.body[0]._id);

        showNotifications('success', res.data.message, 5000);
      } else {
        showNotifications('error', res.data.message, 5000);
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  const handleSave = async () => {
    try {
      const payload = assetData;
      console.log(payload);
      const res = await request(() =>
        updateInternalAuditById(assetId, payload)
      );
      if (res.data.statusCode === 200) {
        setAssetData(assetData);
        console.log(assetData);
        showNotifications('success', res.data.message, 5000);
      } else {
        showNotifications('error', res.data.message, 5000);
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  const handleAddRecord = async (newRecord) => {
    try {
      const screenData = {},
        otherScreenData = {};
      Object.keys(newRecord).map((record) => {
        screenData[record] = newRecord[record]['value'];
      });
      console.log(otherScreenFields);

      otherScreenFields.map((row) => {
        otherScreenData[row['FieldName']] = '';
      });
      const newData = { ...otherScreenData, ...screenData };

      const newAssetData = { ...assetData };

      let newRows = newAssetData['data']['rows'][currentAssetTabName];
      newRows = [...newRows, newData];
      newAssetData['data']['rows'][currentAssetTabName] = newRows;
      const payload = newAssetData;

      let response = await request(() =>
        updateInternalAuditById(assetId, payload)
      );

      if (response.data.statusCode === 200) {
        setAssetData(newAssetData);
        handleCloseAddRecordModal();
        showNotifications('success', response.data.message, 5000);
      } else {
        showNotifications('error', response.data.message, 5000);
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
      console.log(err);
    }
  };

  //console.log(assetData);

  const handleDeleteRecord = async () => {
    try {
      const filteredData = assetData['data']['rows'][
        currentAssetTabName
      ].filter((row) => JSON.stringify(row) !== JSON.stringify(delRow));

      const newAssetData = { ...assetData };
      assetData['data']['rows'][currentAssetTabName] = filteredData;
      const payload = newAssetData;

      const response = await request(() =>
        updateInternalAuditById(assetId, payload)
      );

      if (response.data.statusCode === 200) {
        setAssetData(newAssetData);
        handleCloseDeleteRecordModal();
        showNotifications('success', response.data.message, 5000);
      } else {
        showNotifications('error', response.data.message, 5000);
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
      console.log(err);
    }
  };

  const handleRowUpdate = (newRow, oldRow) => {
    const newAssetRows = [...assetData.data.rows[currentAssetTabName]];

    newAssetRows.forEach((element, index) => {
      if (JSON.stringify(oldRow) === JSON.stringify(element)) {
        console.log('index ' + index + ' element ' + JSON.stringify(element));
        newAssetRows[index] = newRow;
      }
    });

    const newAssetData = { ...assetData };
    newAssetData.data.rows[currentAssetTabName] = newAssetRows;
    // console.log(newAssetData);
    setAssetData(newAssetData);
    //console.log(assetData);
  };

  const handleUploadSave = async (Asset) => {
    try {
      const payload = {
        data: Asset
      };
      let response = await request(() =>
        updateInternalAuditById(assetId, payload)
      );
      if (response.data.statusCode === 200) {
        showNotifications('success', response.data.message, 5000);
      } else {
        showNotifications('error', response.data.message, 5000);
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
      console.log(err);
    }
  };

  useEffect(() => {
    getAssetRepository();
  }, []);

  return (
    <React.Fragment>
      <Box
        style={{
          marginBottom: '25px',
          display: 'flex',
          justifyContent: 'space-between'
        }}
      ></Box>
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          gap: '10px',
          marginBottom: '10px'
        }}
      >
        <Typography sx={{ fontWeight: 'bold' }} variant="h4">
          Internal Audit
        </Typography>
      </Box>

      <Box style={{ marginBottom: '40px' }}>
        <Box
          style={{
            display: 'flex',
            gap: '10px',
            marginBottom: '10px',
            justifyContent: 'flex-end',
            alignItems: 'flex-end'
          }}
        >
          <Button
            sx={{
              color: 'white',
              backgroundColor: 'black'
            }}
            variant="contained"
            onClick={handleOpenAddRecordModal}
            disabled={assetData === null}
          >
            Add Record
          </Button>
          <Button
            color="success"
            sx={{ alignContent: 'space-between' }}
            variant="contained"
            disabled={assetData === null}
            onClick={handleOpenUploadModal}
          >
            Upload Internal Audit
          </Button>

          <Button
            sx={{
              color: 'white',
              backgroundColor: '#ff5722',
              alignContent: 'space-between'
            }}
            variant="contained"
            disabled={assetData === null}
            onClick={() => {
              navigate(`/services/${currentAssetTabName}/${assetId}/threat`);
            }}
          >
            Connect
          </Button>
        </Box>

        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            margin: '40px 0px 10px'
          }}
        ></Box>

        <Box
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginBottom: '30px',
            gap: '10px'
          }}
        >
          {assetData && (
            <TextField
              disabled={assetData === null}
              select
              label="Tab Name"
              value={currentAssetTabName}
              onChange={(e) => setCurrentAssetTabName(e.target.value)}
              style={{ minWidth: '200px' }}
              size="small"
              InputLabelProps={{ shrink: true }}
            >
              {assetData.data.rows &&
                Object.keys(assetData.data.rows)?.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
            </TextField>
          )}
          <Button
            variant="contained"
            color="success"
            onClick={handleSave}
            disabled={assetData === null}
          >
            Save
          </Button>
        </Box>
      </Box>

      <TabContext value={currentAssetTabName}>
        {assetData ? (
          Object.keys(assetData.data.rows).map((AssetTab) => {
            return (
              <TabPanel
                key={`Asset Table: ${AssetTab}`}
                value={AssetTab}
                index={AssetTab}
                sx={{ p: '0px' }}
              >
                <CustomGrid
                  tabName={AssetTab}
                  rows={assetData.data.rows[AssetTab]}
                  fieldsMeta={assetData.data.meta.fields}
                  tableMeta={assetData.data.meta.InternalAudit}
                  handleChangeCB={handleRowUpdate}
                  deleteCB={handleOpenDeleteRecordModal}
                />
              </TabPanel>
            );
          })
        ) : (
          <Box style={{ display: 'flex', justifyContent: 'center' }}>
            <Typography sx={{ fontWeight: 'bold', color: 'Red' }} variant="h5">
              No Data Available to Track
            </Typography>
          </Box>
        )}
      </TabContext>

      {/* Update Asset Repository Modal */}
      <Modal
        open={openAddRecordModal}
        onClose={handleCloseAddRecordModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <CreateEditDialog
          formData={addRecordForm}
          onCloseCB={handleCloseAddRecordModal}
          refreshPageContentCB={getAssetRepository}
          entityName="Record"
          customUpdateCB={handleAddRecord}
        />
      </Modal>
      <Modal
        open={openDeleteRecordModal}
        onClose={handleCloseDeleteRecordModal}
      >
        <DeleteDialog
          entityName={`Record`}
          customDeleteCB={handleDeleteRecord}
          onCloseCB={handleCloseDeleteRecordModal}
        />
      </Modal>
      {/*upload Asset */}
      <Modal
        open={openUploadModal}
        onClose={handleCloseUploadModal}
        aria-labelledby="modal-upload-Asset"
        aria-describedby="modal-upload-Asset-description"
      >
        <UploadDialog
          name="UPLOAD ASSET"
          onCloseCB={handleCloseUploadModal}
          assetData={assetData}
          handleUploadSave={handleUploadSave}
          refreshPageContentCB={getAssetRepository}
        />
      </Modal>
    </React.Fragment>
  );
};

export default InternalAudit;
