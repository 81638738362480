import React, { useEffect } from 'react';
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  tableCellClasses,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate, useParams } from 'react-router-dom';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useLoading } from '../../../hooks/useLoading';
import { useNotifications } from '../../../hooks/useNotifications';
import {
  getAllTemplateType,
  updatePolicyTemplateById
} from '../../../constants/requests';
import InputLabel from '@mui/material/InputLabel';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    fontSize: 15,
    fontWeight: 500
  }
}));

const EditQuestionTable = ({
  tableData,
  setTableData,
  policyName,
  setPolicyName,
  policyType,
  setPolicyType,
  policyTemplateTypes,
  setPolicyTemplateTypes
}) => {
  const { request } = useLoading();
  const { showNotifications } = useNotifications();
  const navigate = useNavigate();
  const params = useParams();

  const handleQuestionChange = (index, event) => {
    const { value } = event.target;
    const newData = [...tableData];
    newData[index].Question = value;
    setTableData(newData);
  };

  const EditPolicyHandler = async () => {
    const tags = [];
    const tables = [];
    tableData.forEach((data) => {
      if (data.Type === 'tag') {
        tags.push({ Question: data.Question, Tag: data.Tag });
      } else {
        tables.push(data.Tag);
      }
    });
    try {
      const formData = new FormData();
      formData.append('name', policyName);
      formData.append('type', policyType);
      formData.append('tags', JSON.stringify(tags));
      const response = await request(() =>
        updatePolicyTemplateById(params.id, formData)
      );
      if (response?.data?.statusCode === 200) {
        navigate('/policy');
        showNotifications('success', response.data.message, 5000);
      } else {
        if (response.response.data.statusCode !== 200) {
          showNotifications('error', response.response.data.message, 5000);
          navigate('/');
        }
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
      navigate('/');
    }
  };

  const getTemplateTypes = async () => {
    try {
      const response = await request(() => getAllTemplateType());
      if (response?.data?.statusCode === 200) {
        setPolicyTemplateTypes(response.data.body);
      } else {
        if (response.response.data.statusCode !== 200) {
          showNotifications('error', response.response.data.message, 5000);
        }
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  useEffect(() => {
    getTemplateTypes();
  }, []);

  if (tableData.length === 0) {
    return <Typography variant="h4"> No Tags Found. </Typography>;
  }

  return (
    <React.Fragment>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', justifyContent: 'left' }}>
          <TextField
            id="outlined-basic"
            label="Policy Name"
            name="Policy Name"
            value={policyName}
            variant="outlined"
            sx={{ margin: 2, marginLeft: 0, width: 250 }}
            onChange={(event) => {
              setPolicyName(event.target.value);
            }}
            disabled
          />
          <FormControl sx={{ margin: 2, marginLeft: 0, width: 250 }}>
            <InputLabel id="demo-simple-select-label">Policy Type</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={policyType}
              label="policyType"
              onChange={(event) => {
                setPolicyType(event.target.value);
              }}
              disabled
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {policyTemplateTypes.map((type) => {
                return (
                  <MenuItem value={type.name} key={type.id}>
                    {type.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Box>
        <Button
          variant="contained"
          sx={{ margin: 2 }}
          onClick={() => {
            EditPolicyHandler();
          }}
          disabled={policyName.length === 0}
        >
          SAVE
        </Button>
      </Box>
      <TableContainer sx={{ marginBottom: 1 }}>
        <Table sx={{ border: 2, borderColor: 'rgba(224, 224, 224, 1)' }}>
          <TableHead>
            <TableRow>
              <StyledTableCell>Tags</StyledTableCell>
              <StyledTableCell>Questions</StyledTableCell>
              <StyledTableCell>Type</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData.map((data, index) => (
              <TableRow key={index}>
                <TableCell sx={{ width: 250 }}>{data.Tag}</TableCell>
                <TableCell>
                  <TextField
                    value={data.Question}
                    fullWidth
                    disabled={data.Type === 'table'}
                    onChange={(event) => handleQuestionChange(index, event)}
                  />
                </TableCell>
                <TableCell sx={{ width: 200 }}>
                  <FormControl fullWidth>
                    <Select
                      value={data.Type}
                      displayEmpty
                      inputProps={{ 'aria-label': 'Without label' }}
                      disabled
                    >
                      <MenuItem value="tag">Tag</MenuItem>
                      <MenuItem value="table">Table</MenuItem>
                    </Select>
                  </FormControl>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </React.Fragment>
  );
};

export default EditQuestionTable;
