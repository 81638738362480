export const headers = [
  { key: '_id', label: 'Employee ID' },
  { key: 'isActive', label: 'Current Status' },
  { key: 'fname', label: 'First Name' },
  { key: 'lname', label: 'Last Name' },
  { key: 'about', label: 'About' },
  { key: 'employmentType', label: 'Employment Type' },
  { key: 'department', label: 'Department' },
  { key: 'designation', label: 'Designation' },
  { key: 'profilePicture', label: 'Profile Picture' },
  { key: 'qualificationDocuments', label: 'Qualification Documents' },
  { key: 'gender', label: 'Gender' },
  { key: 'address', label: 'Address' },
  { key: 'city', label: 'City' },
  { key: 'state', label: 'State' },
  { key: 'zip', label: 'PIN Code' },
  { key: 'country', label: 'Country' },
  { key: 'email', label: 'Email' }
];
