import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/material';
import { useLoading } from '../../hooks/useLoading';
import { useNotifications } from '../../hooks/useNotifications';
import {
  getAllDocuments,
  downloadDocument,
  getGapTrackerDocumentById,
  updateGapTrackerDocumentRequest
} from '../../constants/requests';
import moment from 'moment';
import FileDownload from 'js-file-download';
import CustomTable from '../Services/common/CustomTable';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const getFormattedDate = () => {
  return moment().format('D_MMM_YYYY_h_mm_ss_a');
};

const GapTracker = () => {
  const navigate = useNavigate();
  const { gapId } = useParams();
  const { request } = useLoading();
  const { showNotifications } = useNotifications();
  const [gapData, setGapData] = useState(null);
  const [documentId, setDocumnetId] = useState(null);
  const [engagementId, setEngagementId] = useState(null);
  const [policyEngagementId, setPolicyEngagementId] = useState(null);

  const handleChangeData = (rowNumber, fieldName, cellValue, tabName) => {
    const newGapData = { ...gapData };
    newGapData.rows[tabName][rowNumber][fieldName] = cellValue;
    if (fieldName === 'Status (Open/Closed/Drop)') {
      if (cellValue === 'Closed') {
        newGapData.rows[tabName][rowNumber]['Actual Date of closure'] =
          moment().format('yyyy-MM-DD');
      } else {
        newGapData.rows[tabName][rowNumber]['Actual Date of closure'] = '';
      }
    }
    setGapData(newGapData);
  };

  const getGapDocument = async () => {
    try {
      const res = await request(() => getGapTrackerDocumentById(gapId));
      if (res.data.statusCode === 200) {
        setGapData(res.data.body.data);
        setEngagementId(res.data.body.engagementId);
        setPolicyEngagementId(res.data.body.policyEngagementId);
        showNotifications('success', res.data.message, 5000);
      } else {
        showNotifications('error', res.data.message, 5000);
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  const getGapDocumentId = async () => {
    try {
      const res = await request(() => getAllDocuments());

      if (res.data.statusCode === 200) {
        const requiredDocument = res.data.body.filter(
          (doc) => doc.documentName === 'GAP TRACKER'
        );
        setDocumnetId(requiredDocument[0]._id);
        showNotifications('success', res.data.message, 5000);
      } else {
        showNotifications('error', res.data.message, 5000);
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  const handleDownload = async () => {
    try {
      const payload = {
        gapTrackerId: gapId
      };
      const response = await request(() =>
        downloadDocument(documentId, payload)
      );
      if (response?.response?.data?.statusCode) {
        showNotifications('error', response.response.data.message, 5000);
      } else {
        FileDownload(
          response.data,
          `GapTracker_${gapId}_${getFormattedDate()}.xlsx`
        );
        showNotifications('success', 'Downloaded successfully', 5000);
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  const handleSave = async () => {
    try {
      const payload = {
        data: gapData
      };
      let response = await request(() =>
        updateGapTrackerDocumentRequest(payload, gapId)
      );
      if (response.data.statusCode === 200) {
        showNotifications('success', response.data.message, 5000);
      } else {
        showNotifications('error', response.data.message, 5000);
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  useEffect(() => {
    getGapDocument();
    getGapDocumentId();
  }, []);

  return (
    <React.Fragment>
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '25px'
        }}
      >
        {policyEngagementId !== null ? (
          <Button
            sx={{
              color: 'white',
              backgroundColor: 'black',
              paddingLeft: 1
            }}
            variant="contained"
            onClick={() => {
              navigate(`/policy/engagement/${policyEngagementId}`);
            }}
          >
            <ChevronLeftIcon />
            Policy Engagement
          </Button>
        ) : null}
      </Box>
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '25px'
        }}
      >
        {engagementId !== null ? (
          <Button
            sx={{
              color: 'white',
              backgroundColor: 'black',
              paddingLeft: 1
            }}
            variant="contained"
            onClick={() => {
              navigate(`/services/engagement/${engagementId}/understanding`);
            }}
          >
            <ChevronLeftIcon />
            Understanding
          </Button>
        ) : null}
        {engagementId !== null ? (
          <Button
            sx={{
              color: 'white',
              backgroundColor: 'black',
              paddingLeft: 1
            }}
            variant="contained"
            onClick={() => {
              navigate(`/services/engagement/${engagementId}/mlp`);
            }}
          >
            MLP
            <ChevronRightIcon />
          </Button>
        ) : null}
      </Box>
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          gap: '10px',
          marginBottom: '10px'
        }}
      >
        <Typography sx={{ fontWeight: 'bold' }} variant="h4">
          Gap Tracker
        </Typography>

        {gapData && gapData.rows['GapTracker'].length > 0 ? (
          <Box
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              gap: '10px',
              marginBottom: '10px'
            }}
          >
            <Button
              sx={{
                color: 'white',
                backgroundColor: 'black'
              }}
              variant="contained"
              disabled={gapData === null}
              onClick={handleDownload}
            >
              Download
            </Button>
            <Button
              variant="contained"
              onClick={handleSave}
              disabled={gapData === null}
            >
              Save
            </Button>
          </Box>
        ) : null}
      </Box>

      {gapData && gapData.rows['GapTracker'].length > 0 ? (
        <CustomTable
          fieldsMeta={gapData['meta']['fields']}
          tableMeta={gapData.meta['GapTracker']}
          rows={gapData.rows['GapTracker']}
          handleChangeCB={handleChangeData}
          tabName="GapTracker"
        />
      ) : (
        <Box style={{ display: 'flex', justifyContent: 'center' }}>
          <Typography sx={{ fontWeight: 'bold', color: 'Red' }} variant="h5">
            No Data Available to Track
          </Typography>
        </Box>
      )}
    </React.Fragment>
  );
};

export default GapTracker;
