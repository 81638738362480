import React from 'react';
import { Box } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AllPolicyGovernance from '../Policy/AllPolicyGovernance';
// import TempAllPolicyGovernance from '../Policy/TempAllPolicyGovernance';

const colorCodeObj = {
  Approved: '#99ff99',
  Rejected: '#ff9999',
  Create: '#ffffff'
};

const formatCompletionDate = (completionDate) => {
  const datetime = new Date(completionDate);
  const year = datetime.getFullYear();
  const month = datetime.getMonth() + 1; // Months are zero-based, so add 1
  const day = datetime.getDate();
  let hours = datetime.getHours();
  const minutes = datetime.getMinutes();
  const seconds = datetime.getSeconds();
  let ampm = 'AM';
  if (hours >= 12) {
    ampm = 'PM';
    hours -= 12;
  }
  if (hours === 0) {
    hours = 12;
  }
  const formattedDate = `${day.toString().padStart(2, '0')}/${month
    .toString()
    .padStart(2, '0')}/${year}`;
  const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes
    .toString()
    .padStart(2, '0')}:${seconds.toString().padStart(2, '0')} ${ampm}`;

  return `(${formattedDate}) - (${formattedTime})`;
};

const AccordionWorkflow = ({ heading, message, empName, time, controls }) => {
  const colorCode = colorCodeObj[heading];

  return (
    <Accordion sx={{ borderRadius: '10px', backgroundColor: colorCode }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            paddingRight: '20px'
          }}
        >
          <Box>
            <Typography sx={{ fontWeight: 'bold', fontSize: '20px' }}>
              {heading}
            </Typography>
          </Box>
          <Box>{formatCompletionDate(time)}</Box>
        </Box>
      </AccordionSummary>
      <AccordionDetails sx={{ marginBottom: '10px', marginTop: '-15px' }}>
        {message !== '' && heading !== 'Rejected' && (
          <Box
            sx={{
              marginBottom: '8px',
              backgroundColor: '#f2f2f2',
              borderRadius: '5px',
              padding: '5px'
            }}
          >
            {message}
          </Box>
        )}

        {heading === 'Create' && controls.controls !== null && (
          <Box sx={{ marginBottom: '8px' }}>
            <AllPolicyGovernance
              governanceTableData={controls}
              tabName={'hey there'}
              marginLeft="0"
              marginRight="0"
              stickyColumnName={
                'Secure Controls Framework (SCF) – Control Description'
              }
            />
          </Box>
        )}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            marginLeft: '5px',
            marginRight: '5px',
            marginTop: '10px'
          }}
        >
          <Box sx={{ fontWeight: '500' }}>{`${
            heading === 'Create' ? 'Created' : heading
          } by - ${empName}`}</Box>
          <Box></Box>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default AccordionWorkflow;
