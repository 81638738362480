import React, { useState } from 'react';
import { Box, Typography, Button } from '@mui/material';
import UploadIcon from '@mui/icons-material/Upload';
import DownloadIcon from '@mui/icons-material/Download';
import { Modal } from '../../Modal';
import { useNotifications } from '../../../hooks/useNotifications';
import { CSVLink } from 'react-csv';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useDataLayerValue } from '../../../contextAPI/DataLayer';
export const MasterHeader = ({
  title,
  options,
  toggleUpdate,
  csvReport,
  bulkApprove,
  links,
  arrsize
}) => {
  const [open, setOpen] = useState(false);
  const [isSelected, setIsSelected] = useState(false);
  const { showNotifications } = useNotifications();
  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = React.useState(false);
  const [{ _id, fname, lname, email }] = useDataLayerValue();
  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [selectedCSVFile, setSelectedCSVFile] = useState({
    data: '',
    name: ''
  });

  async function handleCSVUpload() {
    if (!isSelected) {
      showNotifications('error', 'No file selected', 5000);
      return;
    }
    const formData = new FormData();
    formData.append('file', selectedCSVFile.data);
    formData.append('fileName', selectedCSVFile.name);
    try {
      const res = await axios.post(links.importLink, formData, {
        params: {
          addedByID: _id,
          addedByName: `${fname} ${lname}`,
          addedByEmail: email
        },
        headers: {
          employeeid: _id
        }
      });
      if (res.data.statusCode !== 201) {
        showNotifications('error', res.data.message, 5000);
      } else {
        showNotifications('success', res.data.message, 5000);
      }
      handleClose();
      setIsSelected(false);
      toggleUpdate();
    } catch (ex) {
      showNotifications('error', 'Error Occurred', 5000);
    }
  }

  function handleCsvChange(e) {
    setSelectedCSVFile({
      data: e.target.files[0],
      name: e.target.files[0].name
    });
    setIsSelected(true);
    // console.log("I was Changed");
  }
  // console.log(selectedCSVFile);

  return (
    <Box>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
          m: -1
        }}
      >
        <Typography sx={{ m: 1, fontWeight: 'bold' }} variant="h4">
          {title}
        </Typography>
        <Box sx={{ m: 1 }}>
          {options.importOption ? (
            <Button
              startIcon={<UploadIcon />}
              sx={{ mr: 1, color: 'black' }}
              onClick={handleOpen}
            >
              Import
            </Button>
          ) : null}
          {options.exportOption ? (
            <Button startIcon={<DownloadIcon />} sx={{ mr: 1, color: 'black' }}>
              <CSVLink
                data={csvReport.data}
                headers={csvReport.headers}
                filename={csvReport.filename}
                style={{
                  textDecoration: 'none',
                  color: 'inherit',
                  'a:link, a:visited': {
                    backgroundColor: 'inherit',
                    textDecoration: 'none'
                  },

                  'a:hover, a:active': {
                    textDecoration: 'none'
                  }
                }}
              >
                Export
              </CSVLink>
            </Button>
          ) : null}
          {options.createOption ? (
            <Button
              variant="contained"
              sx={{ color: 'white', backgroundColor: 'black' }}
              onClick={() => navigate(links.createLink)}
            >
              Add {title}
            </Button>
          ) : null}
          {options.bulkApproveOption && arrsize > 0 ? (
            <>
              <Button
                variant="contained"
                sx={{
                  color: 'white',
                  backgroundColor: 'green',
                  ml: 2
                }}
                onClick={handleClickOpen}
              >
                Approve All
              </Button>
              <Dialog
                open={openDialog}
                onClose={handleDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  {'Bulk Approve'}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    Are you sure you want to approve all the timesheets at once?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose}>NO</Button>
                  <Button onClick={bulkApprove} autoFocus>
                    YES
                  </Button>
                </DialogActions>
              </Dialog>
            </>
          ) : null}
        </Box>
      </Box>
      <Modal open={open} handleClose={handleClose}>
        <Typography variant="h5">Upload CSV/Excel File</Typography>
        <input
          type="file"
          name="upload-csv"
          id="upload-csv"
          onChange={handleCsvChange}
          accept=".csv,.xlsx"
          style={{ margin: '1.5vw 0' }}
        />
        <Button onClick={handleCSVUpload}>Upload</Button>
      </Modal>
    </Box>
  );
};
