export const employees = {
  _id: 'Employee ID',

  fname: 'First Name',
  lname: 'Last Name',
  about: 'About',
  employmentType: 'Employment Type',
  department: 'Department',
  designation: 'Designation',
  gender: 'Gender',
  address: 'Address',
  city: 'City',
  state: 'State',
  zip: 'PIN Code',
  country: 'Country',
  email: 'Email',
  dateOfBirth: 'Date of Birth',
  dateOfJoining: 'Date of Joining',
  maritalStatus: 'Marital Status',
  reportingManager: 'Reporting Manager',
  reportingManagerName: 'Reporting Manager Name',
  reportingManagerEmail: 'Reporting Manager Email',
  tags: 'Tags',
  qualificationDocuments: 'Qualification Documents',
  aadharNumber: 'Aadhar Number',
  SSN: 'SSN Number',
  PAN: 'PAN Number',
  bankAccountNumber: 'Bank Account Number',
  IFSCCode: 'IFSC Code',

  ctc: 'CTC',
  grossSalary: 'Gross Salary',
  baseSalary: 'Base Salary',
  HRA: 'HRA',
  medicalAllowance: 'Medical Allowance',
  conveyanceAllowance: 'Coveyance Allowance',
  specialAllowance: 'Special Allowance',
  PFContribution: 'PF Contribution',
  ESIContribution: 'ESI Contribution',
  PTdeduction: 'PT Deduction',
  totalDeductions: 'Total Deductions',
  netSalary: 'Net Salary',
  workHoursToday: 'Work Hours Today',
  workHoursCurrentWeek: 'Work Hours Current Week'
};
