import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Button, Modal, TextField, Typography } from '@mui/material';
import {
  getDRLById,
  addDRLById,
  updateDRLById,
  deleteDRLRequest,
  createDRLRequest,
  downloadDRLRequest,
  getEmployeeByIdRequest,
  getEngagementByIdRequest,
  getApplicationByIdRequest,
  getDRLTemplateByTemplateType,
  getDRLTemplatesByServiceIdRequest
} from '../../../constants/requests';
import { useLoading } from '../../../hooks/useLoading';
import { useNotifications } from '../../../hooks/useNotifications';
import moment from 'moment';
import Navbar from '../common/Navbar';
import FileDownload from 'js-file-download';
import ModalTable from '../common/ModalTable';
import CustomGrid from '../common/CustomGrid';
import DeleteDialog from '../common/DeleteDialog';
import ModalNotFound from '../common/ModalNotFound';
import CreateEditDialog from '../common/CreateEditDialog';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  marginBottom: '10px',
  bgcolor: 'background.paper',
  borderRadius: '10px',
  boxShadow: 24,
  p: 4,
  overflow: 'scroll',
  maxHeight: '100%',
  minWidth: '600px'
};

const DRL = () => {
  const { request } = useLoading();
  const { showNotifications } = useNotifications();
  const { engagementId, applicationId } = useParams();
  const navigate = useNavigate();
  const [rowForm, setRowForm] = useState([]);
  const [addRecordForm, setAddRecordForm] = useState([]);
  const [templateList, setTemplateList] = useState([]);
  const [logs, setLogs] = useState([]);
  const [metaData, setMetaData] = useState({});
  const [formData, setFormData] = useState({});
  const [type, setType] = useState('');
  const [applicationName, setApplicationName] = useState('');
  const [drlId, setDRLId] = useState(null);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [bigOpen, setBigOpen] = useState(false);
  const [addOpen, setAddOpen] = useState(false);

  const createDRLModalFormFields = [
    {
      type: 'dropDown',
      fieldName: 'templateType',
      fieldLabel: 'Select Template Type',
      dropDownOptions: templateList,
      value: '',
      required: true
    }
  ];

  const handleBigOpen = () => setBigOpen(true);
  const handleBigClose = () => setBigOpen(false);
  const handleAddOpen = () => createAddRecordForm();
  const handleAddClose = () => setAddOpen(false);
  const handleOpenAddModal = () => setOpenAddModal(true);
  const handleCloseAddModal = () => setOpenAddModal(false);
  const handleOpenDeleteModal = () => setOpenDeleteModal(true);
  const handleCloseDeleteModal = () => setOpenDeleteModal(false);

  const handleRowChange = (newRow, oldRow) => {
    let newData = [...rowForm];

    newData.forEach((element, index) => {
      if (JSON.stringify(oldRow) === JSON.stringify(element)) {
        newData[index] = newRow;
      }
    });
    if (newRow['dateOfRequest'] === null) {
      newRow['dateOfRequest'] = moment().format('yyyy-MM-DD');
    }
    if (newRow['status'] === 'RECEIVED') {
      newRow['dateOfReceipt'] = moment().format('yyyy-MM-DD');
    } else {
      newRow['dateOfReceipt'] = '';
    }
    setRowForm(newData);
  };

  const createAddRecordForm = () => {
    const recordForm = Object.keys(metaData).map((key) => {
      return {
        type: metaData[key]['fieldType'],
        fieldName: key,
        fieldLabel: `Enter ${metaData[key]['displayName']}`,
        value:
          key === 'status'
            ? 'NOT_RECEIVED'
            : key === 'SNo'
            ? rowForm.length + 1
            : '',
        required: !metaData[key]['isEditable'],
        dropDownOptions: metaData[key]?.['options']
      };
    });
    setAddRecordForm(recordForm);
    setAddOpen(true);
  };

  const getDRLTemplates = async () => {
    try {
      const engagementResponse = await request(() =>
        getEngagementByIdRequest(engagementId)
      );
      const { serviceId } = engagementResponse.data.body;

      const response = await request(() =>
        getDRLTemplatesByServiceIdRequest(serviceId)
      );
      if (response.data.statusCode === 200) {
        const templateNames = response.data.body.map((template) => {
          return {
            value: template.templateType,
            label: template.templateType
          };
        });
        setTemplateList(templateNames);
      } else {
        showNotifications('error', response.data.message, 5000);
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
      console.log(err);
    }
  };

  const getDRL = async (DRL_ID) => {
    try {
      if (DRL_ID) {
        const res = await request(() => getDRLById(DRL_ID));
        if (res.data.statusCode === 200) {
          const temp = res.data.body;
          setType(temp.templateType);
          await getMetaData(temp.templateType);
          const data = temp.documents.map((item) => item);
          setRowForm(data);
          setLogs(temp.logs);
          showNotifications('success', res.data.message, 5000);
        } else {
          showNotifications('error', res.data.message, 5000);
        }
      } else {
        setRowForm([]);
        setDRLId(null);
        setType('');
        showNotifications('error', 'No DRL Found', 5000);
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  const loadApplication = async () => {
    try {
      let res = await request(() => getApplicationByIdRequest(applicationId));
      if (res.data.statusCode === 200) {
        const DRL_ID = res.data.body.DRLId?._id;
        if (!DRL_ID) {
          setDRLId(null);
          return;
        }
        setDRLId(DRL_ID);
        setApplicationName(res.data.body.applicationName);
        await getDRL(DRL_ID);
      } else {
        showNotifications('error', res.data.message, 5000);
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  const getAuditorName = async (eId) => {
    try {
      const res = await request(() => getEmployeeByIdRequest(eId));
      if (res.data.statusCode === 200) {
        const eName = res.data.body.fname + res.data.body.lname;
        return eName;
      } else {
        showNotifications('error', res.data.message, 5000);
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  const getDetails = async () => {
    try {
      const res = await request(() => getEngagementByIdRequest(engagementId));
      if (res.data.statusCode === 200) {
        const result = res.data.body;
        const audId = result.aumyaaSPOCID1;
        const audName = await getAuditorName(audId);
        const audEmail = result.aumyaaSPOCEmail1;
        setFormData({
          AuditorName: audName,
          AuditorEmail: audEmail
        });
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  const getMetaData = async (tempType) => {
    try {
      const res = await request(() => getDRLTemplateByTemplateType(tempType));
      if (res.data.statusCode === 200) {
        const temp = res.data.body.metadata;
        temp['status']['options'] = temp['status']['options'].map((item) => {
          return {
            value: item,
            label: item
          };
        });
        // createAddRecordForm(temp);
        setMetaData(temp);
      } else {
        showNotifications('error', res.data.message, 5000);
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  const handleSaveAll = async () => {
    try {
      const payload = {
        templateType: type,
        documents: rowForm
      };
      const res = await request(() => updateDRLById(drlId, payload));
      if (res.data.statusCode === 200) {
        setRowForm(rowForm);
        showNotifications('success', res.data.message, 5000);
      } else {
        showNotifications('error', res.data.message, 5000);
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  const handleDownload = async () => {
    try {
      const res = await request(() =>
        downloadDRLRequest(engagementId, applicationId)
      );
      if (res?.response?.data?.statusCode) {
        showNotifications('error', res.response.data.message, 5000);
      } else {
        FileDownload(res.data, `DRL_${applicationId}.xlsx`);
        showNotifications('success', 'Downloaded successfully', 5000);
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  useEffect(() => {
    loadApplication();
    getDetails();
    return () => {
      setRowForm([]);
      setMetaData({});
    };
  }, []);

  useEffect(() => {
    getDRLTemplates();
    return () => {
      setTemplateList([]);
    };
  }, [drlId]);

  return (
    <>
      <Navbar
        pageName="DRL"
        engagementId={engagementId}
        applicationId={applicationId}
      />
      <Box
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginBottom: '30px'
        }}
      >
        <Box style={{ display: 'flex', gap: '5px' }}>
          <Button
            variant="contained"
            onClick={handleOpenAddModal}
            disabled={drlId !== null}
          >
            Add DRL
          </Button>
          <Button
            color="error"
            variant="contained"
            onClick={handleOpenDeleteModal}
            disabled={drlId === null}
          >
            Delete DRL
          </Button>
          <Button
            sx={{ color: 'white', backgroundColor: 'black' }}
            variant="contained"
            disabled={drlId === null}
            onClick={handleDownload}
          >
            Download DRL
          </Button>
        </Box>
      </Box>

      {rowForm.length > 0 && (
        <Box
          fullWidth
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '10px'
          }}
        >
          <TextField
            label="Auditor Name"
            name="AuditorName"
            fullWidth
            disabled
            value={formData.AuditorName}
            style={{ marginBottom: '10px' }}
            InputLabelProps={{ shrink: true }}
          ></TextField>
          <TextField
            disabled
            label="Auditor Email"
            name="AuditorEmail"
            value={formData.AuditorEmail}
            fullWidth
            style={{ marginBottom: '10px' }}
            InputLabelProps={{ shrink: true }}
          ></TextField>
        </Box>
      )}

      {rowForm.length > 0 && (
        <Box
          fullWidth
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '10px'
          }}
        >
          <TextField
            fullWidth
            name="applicationName"
            label="Application Name"
            disabled
            value={applicationName}
          />
          <TextField
            fullWidth
            disabled
            label="Template Type"
            name="templateType"
            value={type}
          ></TextField>
        </Box>
      )}

      {rowForm.length > 0 && (
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            margin: '30px 0px'
          }}
        >
          <Typography
            sx={{ fontWeight: 'bold', marginRight: '30%' }}
            variant="h5"
          >
            Records
          </Typography>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              gap: '10px'
            }}
          >
            <Button variant="contained" onClick={handleAddOpen}>
              Add Record
            </Button>
            <Button
              sx={{ color: 'white', backgroundColor: '#ff5722' }}
              variant="contained"
              onClick={() =>
                navigate(
                  `/services/engagement/${engagementId}/application/${applicationId}/drl/${drlId}/sampling`
                )
              }
            >
              Sampling
            </Button>
            <Button
              sx={{
                color: 'white',
                backgroundColor: 'black'
              }}
              variant="contained"
              onClick={handleBigOpen}
            >
              Show Logs
            </Button>
            <Button variant="contained" color="success" onClick={handleSaveAll}>
              Save
            </Button>
          </Box>
        </Box>
      )}
      {rowForm.length > 0 ? (
        <CustomGrid
          fieldsMeta={metaData}
          tableMeta={metaData}
          rows={rowForm}
          stickyColumnName="requiredEvidence"
          handleChangeCB={handleRowChange}
          topStyle="25px"
        />
      ) : null}

      <Modal
        open={openAddModal}
        onClose={handleCloseAddModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <CreateEditDialog
          formData={createDRLModalFormFields}
          onCloseCB={handleCloseAddModal}
          refreshPageContentCB={loadApplication}
          entityName="DRL"
          createEntityRequest={createDRLRequest}
          additionalPayloadParameters={{
            applicationID: applicationId
          }}
        />
      </Modal>

      <Modal open={openDeleteModal} onClose={handleCloseDeleteModal}>
        <DeleteDialog
          entityName="DRL"
          deleteRequest={deleteDRLRequest}
          entityId={drlId}
          onCloseCB={handleCloseDeleteModal}
          refreshPageContentCB={getDRL}
        />
      </Modal>

      <Modal
        open={addOpen}
        onClose={handleAddClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <CreateEditDialog
          formData={addRecordForm}
          onCloseCB={handleAddClose}
          entityName="Document"
          refreshPageContentCB={loadApplication}
          createEntityRequest={addDRLById}
          additionalPayloadParameters={{
            DRLId: drlId
          }}
        />
      </Modal>

      <Modal
        open={bigOpen}
        onClose={handleBigClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={style}
          style={{ width: '50%', height: '80%', overflowX: 'scroll' }}
        >
          {logs.length > 0 && (
            <Box
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: '20px'
              }}
            >
              <Typography
                fullWidth
                id="modal-modal-title"
                variant="h5"
                component="h2"
              >
                Logs
              </Typography>
              <Button
                color="error"
                variant="contained"
                onClick={handleBigClose}
              >
                Close
              </Button>
            </Box>
          )}
          {logs.length > 0 ? (
            <ModalTable data={logs} />
          ) : (
            <ModalNotFound data="Logs For This DRL" />
          )}
        </Box>
      </Modal>
    </>
  );
};

export default DRL;
