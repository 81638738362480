import React, { useState } from 'react';
import {
  Box,
  Button,
  Modal,
  Typography,
  TextField,
  FormHelperText
} from '@mui/material';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  borderRadius: '10px',
  boxShadow: 24,
  p: 4,
  maxHeight: '100%',
  minWidth: '600px'
};

const AddCommentModal = ({
  openAddCommentModal,
  handleCloseAddCommentModal,
  comment,
  setComment,
  createNewPolicyVersionHandler
}) => {
  const [commentError, setCommentError] = useState(false);

  const setCommentHandler = (event) => {
    if (event.target.value.trim().length) {
      setCommentError(false);
    }
    setComment(event.target.value);
  };

  const checkErrorHandler = () => {
    if (!comment.trim().length) {
      setCommentError(true);
    } else {
      createNewPolicyVersionHandler();
    }
  };

  return (
    <Modal
      open={openAddCommentModal}
      onClose={handleCloseAddCommentModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography
          variant="h5"
          component="h2"
          style={{ marginBottom: '20px' }}
        >
          Add Comment
        </Typography>
        <TextField
          fullWidth
          id="filled-multiline-static"
          label="Add Comment"
          multiline
          rows={4}
          defaultValue={comment}
          variant="filled"
          sx={{ marginBottom: '5px' }}
          onChange={(event) => {
            setCommentHandler(event);
          }}
          error={commentError}
        />
        {commentError && (
          <FormHelperText> Please add a comment.</FormHelperText>
        )}
        <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button
            variant="contained"
            color="error"
            style={{ marginTop: '10px' }}
            onClick={() => {
              checkErrorHandler();
            }}
          >
            Add
          </Button>
          <Button
            variant="contained"
            style={{ marginTop: '10px' }}
            onClick={handleCloseAddCommentModal}
          >
            Close
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default AddCommentModal;
