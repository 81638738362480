import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
  Typography
} from '@mui/material';
import React from 'react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { HeaderWithCreateModal } from '../../../components/Master/HeaderWithCreateModal';
import {
  getProfilesRequest,
  getRolesRequest,
  updateRoleRequest
} from '../../../constants/requests';
import { ROLES_URL } from '../../../constants/urls';
import { useDataLayerValue } from '../../../contextAPI/DataLayer';
import { useLoading } from '../../../hooks/useLoading';
import { useNotifications } from '../../../hooks/useNotifications';

const roleCreationFields = [
  {
    label: 'Role Name*',
    name: 'name',
    display: 'required',
    type: 'text'
  }
];

export const MasterRoles = () => {
  const { request } = useLoading();
  const { showNotifications } = useNotifications();
  const [updated, setUpdated] = useState(false);
  const [roles, setRoles] = useState([]);
  const [profiles, setProfiles] = useState([]);
  const [user] = useDataLayerValue();
  const { CREATE, EDIT, VIEW_REPORT } = user.rights.Role;
  const navigate = useNavigate();
  if (VIEW_REPORT === false) {
    navigate('/');
  }

  const getRoles = async () => {
    const rolesResponse = await request(getRolesRequest);
    setRoles(rolesResponse.data.body);
  };

  const getProfiles = async () => {
    const profilesResponse = await request(getProfilesRequest);
    setProfiles(profilesResponse.data.body);
  };

  const handleChange = async (role, profileName) => {
    if (EDIT) {
      const idx = role.profiles.indexOf(profileName),
        updatedProfiles = role.profiles;
      if (idx === -1) {
        updatedProfiles.push(profileName);
      } else {
        updatedProfiles.splice(idx, 1);
      }
      request(async () => {
        updateRoleRequest(role._id, {
          profiles: updatedProfiles
        }).then((response) => {
          if (response.data.statusCode === 200) {
            showNotifications('success', response.data.message, 5000);
            setUpdated(!updated);
          } else {
            showNotifications('error', response.data.message, 5000);
          }
        });
      });
    }
  };

  useEffect(() => {
    document.title = 'Roles';
    getRoles();
    getProfiles();
  }, [updated]);

  return (
    <>
      <HeaderWithCreateModal
        title="Roles"
        options={{ createOption: CREATE }}
        creationFields={roleCreationFields}
        reqUrl={ROLES_URL}
        reqHeaders={{}}
      />
      <TableContainer
        component={Paper}
        sx={{ width: '100%', mt: 5, maxHeight: '70vh' }}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow key="head">
              <TableCell>
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                  Roles
                </Typography>
              </TableCell>
              {profiles.map((profile, idx) => {
                return (
                  <TableCell key={`profile-head-${idx}`}>
                    <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                      {profile.name}
                    </Typography>
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {roles.map((role) => {
              return (
                <TableRow key={role.name}>
                  <TableCell>
                    <Typography variant="body2">{role.name}</Typography>
                  </TableCell>
                  {/* {These TableCells are for configuring and updating the various profiles a role has} */}
                  {profiles.map((profile, idx) => {
                    return (
                      <TableCell key={`profile-cell-${idx}`}>
                        <Checkbox
                          checked={role.profiles.includes(profile.name)}
                          onChange={() => {
                            handleChange(role, profile.name);
                          }}
                        />
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
