import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useLoading } from '../../../hooks/useLoading';
import { useNotifications } from '../../../hooks/useNotifications';
import { getPagesByEngagementIdRequest } from '../../../constants/requests';

const getPageLink = (pageName, engagementId, applicationId) => {
  switch (pageName) {
    case 'UNDERSTANDING':
      return `/services/engagement/${engagementId}/understanding`;
    case 'RCM':
      return `/services/engagement/${engagementId}/application/${applicationId}/rcm`;
    case 'DRL':
      return `/services/engagement/${engagementId}/application/${applicationId}/drl`;
    case 'TESTING':
      return `/services/engagement/${engagementId}/application/${applicationId}/testing`;
    case 'CSA':
      return `/services/engagement/${engagementId}/application/${applicationId}/csa`;
    case 'MLP':
      return `/services/engagement/${engagementId}/mlp`;
    case 'AIT':
      return `/services/engagement/${engagementId}/ait`;
    case 'ConvertPPT':
      return `/services/engagement/${engagementId}/convertppt`;
  }
};

const getPage = (pages, pageName, engagementId, applicationId, position) => {
  if (pages === null) {
    if (pageName === 'RCM') {
      if (position === 'left')
        return {
          displayName: 'Understanding',
          pageLink: getPageLink('UNDERSTANDING', engagementId, applicationId)
        };
      else if (position === 'right')
        return {
          displayName: 'Document Request List',
          pageLink: getPageLink('DRL', engagementId, applicationId)
        };
    } else if (pageName === 'DRL') {
      if (position === 'left')
        return {
          displayName: 'Risk Control Matrix',
          pageLink: getPageLink('RCM', engagementId, applicationId)
        };
      else if (position === 'right')
        return { displayName: 'Create RCM First', pageLink: null };
    } else if (pageName === 'AIT') {
      if (position === 'left')
        return {
          displayName: 'MLP',
          pageLink: getPageLink('MLP', engagementId, applicationId)
        };
      else if (position === 'right')
        return {
          displayName: 'Convert PPT',
          pageLink: getPageLink('ConvertPPT', engagementId, applicationId)
        };
    } else {
      // While the page is loading
      return { displayName: '', pageLink: null };
    }
  }

  const pageIdx = pages.map((page) => page.pageName).indexOf(pageName);

  let searchIdx = position === 'left' ? pageIdx - 1 : pageIdx + 1;
  if (searchIdx >= pages.length) searchIdx = 0;

  let { pageName: prevPageName, displayName } = pages[searchIdx];
  let pageLink = getPageLink(prevPageName, engagementId, applicationId);

  if (position === 'left') {
    if (pageName === 'MLP') {
      if (pageIdx === pages.length - 1) {
        pageLink = null;
        displayName = '';
      } else {
        displayName = 'Understanding';
        pageLink = getPageLink('UNDERSTANDING', engagementId, applicationId);
      }
    }
  }

  return { displayName, pageLink };
};

const getCurrentPageDisplayName = (pages, pageName) => {
  if (pages === null) return '';
  const pageIdx = pages.map((page) => page.pageName).indexOf(pageName);
  return pages[pageIdx].displayName;
};

function Navbar({ shouldRefresh, pageName, engagementId, applicationId }) {
  const navigate = useNavigate();
  const { showNotifications } = useNotifications();
  const { request } = useLoading();

  const [pages, setPages] = useState(null);

  const getPages = async () => {
    try {
      const response = await request(() =>
        getPagesByEngagementIdRequest(engagementId)
      );
      setPages(response.data.body.pages);
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
      console.log(err);
    }
  };

  useEffect(() => {
    getPages();
  }, [shouldRefresh]);

  return (
    <>
      <Box
        style={{
          marginBottom: '25px',
          display: 'flex',
          justifyContent: 'space-between'
        }}
      >
        <Button
          sx={{
            color: 'white',
            backgroundColor: 'black'
          }}
          variant="contained"
          onClick={() =>
            navigate(
              getPage(pages, pageName, engagementId, applicationId, 'left')
                .pageLink
            )
          }
          disabled={
            getPage(pages, pageName, engagementId, applicationId, 'left')
              .pageLink === null
          }
        >
          <ChevronLeftIcon />
          {
            getPage(pages, pageName, engagementId, applicationId, 'left')
              .displayName
          }
        </Button>
        <Button
          sx={{ color: 'white', backgroundColor: 'black' }}
          variant="contained"
          onClick={() =>
            navigate(
              getPage(pages, pageName, engagementId, applicationId, 'right')
                .pageLink
            )
          }
          disabled={
            getPage(pages, pageName, engagementId, applicationId, 'right')
              .pageLink === null
          }
        >
          {
            getPage(pages, pageName, engagementId, applicationId, 'right')
              .displayName
          }
          <ChevronRightIcon />
        </Button>
      </Box>
      <Typography sx={{ fontWeight: 'bold' }} variant="h4">
        {getCurrentPageDisplayName(pages, pageName)}
      </Typography>
    </>
  );
}

export default Navbar;
