import React from 'react';
import PropTypes from 'prop-types';
import {
  Table,
  TableCell,
  tableCellClasses,
  TableHead,
  TableRow,
  TableBody,
  TableContainer,
  Typography,
  Box,
  Button
} from '@mui/material';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import GetTableCell from './GetTableCell';
import DeleteIcon from '@mui/icons-material/Delete';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  paddingTop: '0px',
  paddingBottom: '0px',
  paddingRight: '5px',
  paddingLeft: '5px',
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    fontSize: 15
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14
  }
}));

const stickyStyle = {
  position: 'sticky',
  left: 0,
  zIndex: 800,
  minWidth: '300px',
  minHeight: '0px'
};

const normalStyle = {
  minWidth: '250px',
  minHeight: '0px'
};

const StyledTableRow = styled(TableRow)(() => ({
  paddingTop: '0px',
  paddingBottom: '0px',
  margin: '0px',
  '&:last-child td, &:last-child th': {
    border: 0
  }
}));

export default function CustomTablePolicy({
  fieldsMeta,
  tableMeta,
  rows,
  stickyColumnName,
  handleChangeCB,
  incrementTableRows,
  deleteSelectedRow,
  tabName
}) {
  return (
    <React.Fragment>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-end',
          marginTop: '30px',
          marginBottom: '15px'
        }}
      >
        <Typography sx={{ fontWeight: 600, fontSize: 25 }}>
          {tabName}
        </Typography>
        <Button
          sx={{ backgroundColor: 'black', marginLeft: 1, height: 40 }}
          variant="contained"
          onClick={() => incrementTableRows(tabName)}
        >
          Add Rows
        </Button>
      </Box>
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table
            stickyHeader
            sx={{ minWidth: 700 }}
            aria-label="customized table"
          >
            <TableHead>
              <TableRow>
                <StyledTableCell sx={{ width: '20px' }}></StyledTableCell>
                {rows.length > 0 &&
                  Object.keys(tableMeta).map((tableHeader, tableHeaderIdx) => {
                    return (
                      <StyledTableCell
                        key={`Header-${tableHeader}-${tableHeaderIdx}`}
                        style={
                          tableHeader === stickyColumnName
                            ? stickyStyle
                            : normalStyle
                        }
                        align="left"
                      >
                        {tableMeta[tableHeader]['displayName'] === undefined
                          ? tableHeader
                          : tableMeta[tableHeader]['displayName']}
                      </StyledTableCell>
                    );
                  })}
                <StyledTableCell sx={{ width: '20px' }}></StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, idx) => (
                <StyledTableRow
                  key={`CustomTable-row-${Math.random()}-${Math.random()}-${Math.random()}`}
                >
                  <StyledTableCell>{idx + 1}</StyledTableCell>
                  {Object.keys(tableMeta).map((key, colIdx) => {
                    return (
                      <StyledTableCell
                        key={`CustomTable-cellData-${Math.random()}-${Math.random()}-${Math.random()}-${colIdx}`}
                        align="left"
                        style={
                          key === stickyColumnName ? stickyStyle : normalStyle
                        }
                        sx={{
                          top: '25px',
                          background:
                            key === stickyColumnName ? '#F5F5F5' : '#fff'
                        }}
                      >
                        <GetTableCell
                          key={`CustomTable-cell-${Math.random()}-${Math.random()}-${Math.random()}`}
                          rowNumber={idx}
                          fieldName={key}
                          fieldType={fieldsMeta[key].fieldType}
                          value={row[key]}
                          onChangeCB={(rowNumber, fieldName, cellValue) =>
                            handleChangeCB(
                              rowNumber,
                              fieldName,
                              cellValue,
                              tabName
                            )
                          }
                          isEditable={tableMeta[key].isEditable}
                          options={fieldsMeta[key].options}
                        />
                      </StyledTableCell>
                    );
                  })}
                  <StyledTableCell>
                    <DeleteIcon
                      onClick={() => deleteSelectedRow(tabName, idx)}
                    />
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </React.Fragment>
  );
}

CustomTablePolicy.propTypes = {
  fieldsMeta: PropTypes.objectOf(PropTypes.object),
  tableMeta: PropTypes.objectOf(PropTypes.object),
  rows: PropTypes.arrayOf(PropTypes.object),
  stickyColumnName: PropTypes.string,
  handleChangeCB: PropTypes.func,
  tabName: PropTypes.string
};
