import React from 'react';
import { Box } from '@mui/material';

function ModalNotFound({ data }) {
  return (
    <Box style={{ display: 'flex', justifyContent: 'center' }}>
      <h1 style={{ color: 'red' }}>No {data} Found</h1>
    </Box>
  );
}

export default ModalNotFound;
