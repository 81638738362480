import React, { useEffect, useState } from 'react';
import { Box, Modal, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useLoading } from '../../../hooks/useLoading';
import { useNotifications } from '../../../hooks/useNotifications';
import {
  getActiveWorkflowHandlerByIdRequest,
  getEmployeesRequest,
  getEngagementPolicyById,
  getWorkflowHandlerByIdRequest
} from '../../../constants/requests';
import AccordionWorkflow from './AccordionWorkflow';

//this modal needs some heavy updation for sure, let's figure those out
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  borderRadius: '10px',
  boxShadow: 24,
  p: 4,
  maxWidth: '800px',
  maxHeight: '85vh',
  overflowY: 'auto'
};

const WorkflowHistoryModal = ({
  openWorkflowHistoryModal,
  handleCloseWorkflowHistoryModal,
  exctractAndCreateTableDataForGovernance,
  title
}) => {
  const params = useParams();
  const { request } = useLoading();
  const { showNotifications } = useNotifications();
  const [workflowData, setWorkflowData] = useState([]);

  const getWorkflowHistoryForPolicy = async () => {
    try {
      const response = await request(() =>
        getEngagementPolicyById(params.policyid)
      );
      if (response?.data?.statusCode === 200) {
        const promisesActive = response.data.body.SCFWorkflowHandlerHistory.map(
          async (id) => {
            const response = await request(() =>
              getActiveWorkflowHandlerByIdRequest(id)
            );
            return response.data.body;
          }
        );
        const promises = response.data.body.SCFWorkflowHandlerHistory.map(
          async (id) => {
            const response = await request(() =>
              getWorkflowHandlerByIdRequest(id)
            );
            return response.data.body;
          }
        );
        const workflowArray = await Promise.all(promisesActive);
        const workflowControlsArray = await Promise.all(promises);
        getAllEmployees(
          workflowArray,
          workflowControlsArray,
          response.data.body
        );
      } else {
        if (response.response.data.statusCode !== 200) {
          showNotifications('error', response.response.data.message, 5000);
        }
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  const getAllEmployees = async (
    workflowArray,
    workflowControlsArray,
    data
  ) => {
    try {
      const response = await request(() => getEmployeesRequest());
      if (response?.data?.statusCode === 200) {
        const hashmap = response.data.body.reduce(
          (acc, { _id, fname, lname }) => {
            acc[_id] = `${fname} ${lname}`;
            return acc;
          },
          {}
        );
        const hashmapControls = workflowControlsArray.reduce((acc, obj) => {
          acc[obj._id] = obj.data.RequestBody.lastApprovedSCF;
          return acc;
        }, {});
        const formattedData = [];
        workflowArray.forEach((workflow) => {
          workflow[0].history.forEach((history) => {
            if (history.type === 'Rejected') {
              formattedData.pop();
            } else {
              const newControlsData = exctractAndCreateTableDataForGovernance(
                hashmapControls[workflow[0].workflowHandlerId],
                false,
                data
              );
              formattedData.push({
                heading: history.type,
                empName: hashmap[history.employeeId],
                message: history.body,
                time: history.dateTime,
                controls: newControlsData
              });
            }
          });
        });
        setWorkflowData(formattedData.reverse());
      } else {
        if (response.response.data.statusCode !== 200) {
          showNotifications('error', response.response.data.message, 5000);
        }
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  useEffect(() => {
    getWorkflowHistoryForPolicy();
    workflowData;
  }, []);

  return (
    <Modal
      open={openWorkflowHistoryModal}
      onClose={handleCloseWorkflowHistoryModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography
          id="modal-modal-title"
          variant="h5"
          component="h2"
          sx={{ textAlign: 'center', marginBottom: 3, fontWeight: 600 }}
        >
          {title}
        </Typography>
        {workflowData.length === 0 ? (
          <Box style={{ display: 'flex', justifyContent: 'center' }}>
            <Typography sx={{ fontWeight: 'bold', color: 'Red' }} variant="h6">
              No Approval Requested
            </Typography>
          </Box>
        ) : (
          workflowData.map((instance) => {
            return (
              <AccordionWorkflow
                key={instance.time}
                heading={instance.heading}
                message={instance.message}
                empName={instance.empName}
                time={instance.time}
                controls={instance.controls}
              />
            );
          })
        )}
      </Box>
    </Modal>
  );
};

export default WorkflowHistoryModal;
