import React, { useEffect, useState } from 'react';
import { Box, Modal, Typography } from '@mui/material';
import { useLoading } from '../../../../hooks/useLoading';
import { useNotifications } from '../../../../hooks/useNotifications';
import { getPolicyEngagementPublishHistory } from '../../../../constants/requests';
import { useParams } from 'react-router-dom';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 550,
  bgcolor: 'white',
  boxShadow: 24,
  borderRadius: '10px',
  p: 4
};

const formatCompletionDate = (completionDate) => {
  const datetime = new Date(completionDate);
  const year = datetime.getFullYear();
  const month = datetime.getMonth() + 1; // Months are zero-based, so add 1
  const day = datetime.getDate();
  let hours = datetime.getHours();
  const minutes = datetime.getMinutes();
  const seconds = datetime.getSeconds();
  let ampm = 'AM';
  if (hours >= 12) {
    ampm = 'PM';
    hours -= 12;
  }
  if (hours === 0) {
    hours = 12;
  }
  const formattedDate = `${day.toString().padStart(2, '0')}/${month
    .toString()
    .padStart(2, '0')}/${year}`;
  const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes
    .toString()
    .padStart(2, '0')}:${seconds.toString().padStart(2, '0')} ${ampm}`;

  return `(${formattedDate}) - (${formattedTime})`;
};

const HistoryModal = ({
  openHistoryModal,
  handleCloseHistoryModal,
  open,
  setOpen
}) => {
  const { request } = useLoading();
  const { showNotifications } = useNotifications();
  const params = useParams();
  const [history, setHistory] = useState([]);

  const getPublishHistory = async () => {
    try {
      const response = await request(() =>
        getPolicyEngagementPublishHistory(params.id)
      );
      if (response?.data?.statusCode === 200) {
        console.log(response);
        setHistory(response.data.body.publishHistory);
      } else {
        if (response.response.data.statusCode !== 200) {
          showNotifications('error', response.response.data.message, 5000);
        }
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  useEffect(() => {
    getPublishHistory();
  }, []);

  if (open) {
    getPublishHistory();
    setOpen(false);
  }

  return (
    <Modal
      open={openHistoryModal}
      onClose={handleCloseHistoryModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography
          id="modal-modal-title"
          variant="h5"
          component="h2"
          sx={{ textAlign: 'center', marginBottom: 3, fontWeight: 600 }}
        >
          Publish History
        </Typography>

        {history.length === 0 ? (
          <Box style={{ display: 'flex', justifyContent: 'center' }}>
            <Typography sx={{ fontWeight: 'bold', color: 'Red' }} variant="h5">
              No Publish History is Present.
            </Typography>
          </Box>
        ) : (
          history.reverse().map((curr, id) => {
            const emails = JSON.parse(curr.emails).map((email) => (
              <Box key={Math.random()}>{email}</Box>
            ));
            return (
              <Box key={Math.random()}>
                <Accordion sx={{ borderRadius: '10px' }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '100%',
                        paddingRight: '20px'
                      }}
                    >
                      <Box>
                        <Typography
                          sx={{ fontWeight: 'bold', fontSize: '20px' }}
                        >
                          Publish {history.length - id}
                        </Typography>
                      </Box>
                      <Box>{formatCompletionDate(curr.publishedAt)}</Box>
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails sx={{ marginTop: '-18px' }}>
                    <Box sx={{ marginBottom: '5px' }}>
                      <Typography variant="h6">Link</Typography>
                      <Box>{curr.driveLink}</Box>
                    </Box>
                    <Box>
                      <Typography variant="h6">Emails</Typography>
                      <Box>{emails}</Box>
                    </Box>
                  </AccordionDetails>
                </Accordion>
              </Box>
            );
          })
        )}
      </Box>
    </Modal>
  );
};

export default HistoryModal;
