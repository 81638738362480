import React, { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import { Button, Typography } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { DataGrid } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useLoading } from '../../../hooks/useLoading';
import { useNotifications } from '../../../hooks/useNotifications';
import {
  addPolicyEngagement,
  getAllGenericPolicyMaster,
  getAllIndustry,
  getAllPolicyTemplates,
  getAllTemplateType,
  getAssignedProjectsByEmployeeId,
  getClientsRequest,
  getEngagementByServiceId,
  getProjectByIdRequest,
  getServicesRequest
} from '../../../constants/requests';
import TextField from '@mui/material/TextField';
// import Autocomplete from '@mui/material/Autocomplete';
import { country_list } from '../../../constants/countryList';
import { IconButton } from '@mui/material';
import RemoveIcon from '@mui/icons-material/Remove';
import Grid from '@mui/material/Grid';
import { v4 as uuidv4 } from 'uuid';
import AddIcon from '@mui/icons-material/Add';
import { useDataLayerValue } from '../../../contextAPI/DataLayer';

const columns = [
  {
    field: 'version',
    headerClassName: 'super-app-theme--header',
    headerName: 'S.No',
    width: 150
  },
  {
    field: 'policyName',
    headerClassName: 'super-app-theme--header',
    headerName: 'Policy name',
    width: 500
  },
  {
    field: 'type',
    headerClassName: 'super-app-theme--header',
    headerName: 'Type',
    width: 200
  }
];

const AddPolicyEngagement = () => {
  const navigate = useNavigate();
  const { request } = useLoading();
  const { showNotifications } = useNotifications();
  const [{ _id }] = useDataLayerValue();

  const [projectNames, setProjectNames] = useState([]);
  const [industrySubIndustry, setIndustrySubIndustry] = useState({});
  const [firstDropdownValue, setFirstDropdownValue] = useState('');
  const [secondDropdownValue, setSecondDropdownValue] = useState('');

  const [policyData, setPolicyData] = useState([]);
  const [formOptions, setFormOptions] = useState({
    projectId: '',
    clientName: '',
    industry: '',
    subIndustry: '',
    country: '',
    aumyaaSPOCID: '',
    engagementRequired: '',
    engagementId: ''
  });
  const [policyTemplateTypes, setPolicyTemplateTypes] = useState([]);
  const [policyMasterTypes, setPolicyMasterTypes] = useState([]);

  const [inputFields, setInputFields] = useState([
    // { id: uuidv4(), field: '', operation: '', value: '' }
  ]);

  const handleRemoveFields = (id) => {
    const values = [...inputFields];
    values.splice(
      values.findIndex((value) => value.id === id),
      1
    );
    setInputFields(values);
  };

  const handleAddFields = () => {
    setInputFields([
      ...inputFields,
      { id: uuidv4(), name: '', type: '', genericPolicyMasterID: '' }
    ]);
  };

  const handleDeleteFields = () => {
    setInputFields(inputFields.slice(0, inputFields.length - 1));
  };

  const handleVarFieldInput = (id, event, name) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i[name] = event.target.value;
      }
      return i;
    });
    setInputFields(newInputFields);
  };

  const setFormOptionsHandler = (value, type) => {
    const newFormOptions = { ...formOptions };
    newFormOptions[type] = value;
    setFormOptions(newFormOptions);
  };

  const setFormOptionsHandler2 = async (value1, type1, value2, type2) => {
    const newFormOptions = { ...formOptions };
    newFormOptions[type1] = value1;
    newFormOptions[type2] = value2;
    setFormOptions(newFormOptions);

    const assignedEmployees = await getAssignedEmployees(value1);
    setDropDownOptions({
      ...dropDownOptions,
      employee: assignedEmployees
    });
  };

  const getAssignedEmployees = async (projectId) => {
    try {
      const response = await request(() => getProjectByIdRequest(projectId));
      if (response.data.statusCode === 200) {
        const assignedEmployees = response.data.body.projectWorkerIds.map(
          (id, idx) => {
            return {
              label: response.data.body.projectWorkerNames[idx],
              value: id
            };
          }
        );
        return assignedEmployees;
      } else {
        showNotifications('error', response.data.message, 5000);
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  const [dropDownOptions, setDropDownOptions] = useState({
    clients: [],
    industry: Object.keys(industrySubIndustry),
    country: country_list,
    employee: []
  });
  const [engagements, setEngagements] = useState([]);

  const [allSelectedPolicies, setAllSelectedPolicies] = useState([]);
  const selectPolicies = (selectedPolicies) => {
    setAllSelectedPolicies(selectedPolicies);
  };

  const [file, setFile] = useState(null);
  const handleFileSelection = (event) => {
    setFile(event.target.files[0]);
  };

  const getProjectsAssignedToEmployee = async () => {
    try {
      const response = await request(() =>
        getAssignedProjectsByEmployeeId(_id)
      );
      if (response?.data?.statusCode === 200) {
        const projectNames = response.data.body.map((project) => {
          return {
            label: project.projectName,
            value: project._id,
            clientName: project.clientName
          };
        });
        setProjectNames(projectNames);
      } else {
        if (response.response.data.statusCode !== 200) {
          showNotifications('error', response.response.data.message, 5000);
        }
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  const getPolicies = async () => {
    try {
      const response = await request(() => getAllPolicyTemplates());
      if (response?.data?.statusCode === 200) {
        const newTableData = response.data.body.map((data) => {
          return {
            version: `${data.wordDocumentID.versions.length}.0`,
            policyName: data.name,
            id: data._id,
            type: data.type
          };
        });
        setPolicyData(newTableData);
      } else {
        if (response.response.data.statusCode !== 200) {
          showNotifications('error', response.response.data.message, 5000);
        }
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  const getClients = async () => {
    try {
      const response = await request(() => getClientsRequest());
      if (response?.data?.statusCode === 200) {
        const clients = response.data.body.map(({ _id, name }) => ({
          id: _id,
          name
        }));
        const newDropDownOptions = { ...dropDownOptions, clients };
        getIndustries(newDropDownOptions);
      } else {
        if (response.response.data.statusCode !== 200) {
          showNotifications('error', response.response.data.message, 5000);
        }
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  const getIndustries = async (newDropDownOptions) => {
    try {
      const response = await request(() => getAllIndustry());
      if (response?.data?.statusCode === 200) {
        const newIndustrySubIndustry = {};
        response.data.body.map((data) => {
          newIndustrySubIndustry[data.industry] = data.subIndustry;
        });
        setIndustrySubIndustry(newIndustrySubIndustry);
        const latestDropDownOptions = {
          ...newDropDownOptions,
          industry: Object.keys(newIndustrySubIndustry)
        };
        setDropDownOptions(latestDropDownOptions);
      } else {
        if (response.response.data.statusCode !== 200) {
          showNotifications('error', response.response.data.message, 5000);
        }
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  const getEngagements = async () => {
    try {
      const allServices = await getServicesRequest();
      if (allServices?.data?.statusCode === 200) {
        const SOC2service = allServices.data.body.find(
          (service) => service.name === 'SOC2.0'
        );
        let newEngagement = [];
        if (SOC2service !== undefined) {
          const allEngagementForSOC2 = await getEngagementByServiceId(
            SOC2service._id
          );
          newEngagement = [...newEngagement, ...allEngagementForSOC2.data.body];
        }
        const ISO7001service = allServices.data.body.find(
          (service) => service.name === 'ISO27001'
        );

        if (ISO7001service !== undefined) {
          const allEngagementForISO27001 = await getEngagementByServiceId(
            ISO7001service._id
          );
          newEngagement = [
            ...newEngagement,
            ...allEngagementForISO27001.data.body
          ];
        }
        setEngagements(newEngagement);
      } else {
        if (allServices.response.data.statusCode !== 200) {
          showNotifications('error', allServices.response.data.message, 5000);
        }
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  const createClientPolicyHandler = async () => {
    const inputFieldsToBeChecked = [
      'projectId',
      'clientName',
      'aumyaaSPOCID',
      'industry',
      'subIndustry',
      'country'
    ];
    for (let i = 0; i < inputFieldsToBeChecked.length; i++) {
      const fieldName = inputFieldsToBeChecked[i];
      if (formOptions[fieldName] === '') {
        showNotifications('error', `Please fill the ${fieldName}`, 5000);
        return;
      }
    }
    if (file === null) {
      showNotifications('error', 'Please add the logo', 5000);
      return;
    }
    if (formOptions.engagementRequired === '') {
      showNotifications(
        'error',
        'Please select whether you want to link this policy engagement',
        5000
      );
      return;
    }
    if (formOptions.engagementRequired && formOptions.engagementId === '') {
      showNotifications('error', 'Please select the engagement', 5000);
      return;
    }
    const checkGenericEmptyFields = inputFields.every(
      (obj) =>
        obj.name !== '' && obj.type !== '' && obj.genericPolicyMasterID !== ''
    );
    if (!checkGenericEmptyFields) {
      showNotifications(
        'error',
        'Please fill all the fields for generic policies',
        5000
      );
      return;
    }
    const genericPolicies = inputFields.map((policy) => {
      return {
        name: policy.name,
        type: policy.type,
        genericPolicyMasterID: policy.genericPolicyMasterID
      };
    });
    const formData = new FormData();
    formData.append('projectId', formOptions.projectId);
    formData.append('clientName', formOptions.clientName);
    formData.append('industry', formOptions.industry);
    formData.append('subIndustry', formOptions.subIndustry);
    formData.append('country', formOptions.country);
    formData.append('aumyaaSPOCID', formOptions.aumyaaSPOCID);
    formData.append('policyTemplates', JSON.stringify(allSelectedPolicies));
    formData.append('file', file);
    formData.append('genericPolicies', JSON.stringify(genericPolicies));
    if (formOptions.engagementRequired)
      formData.append('engagementId', formOptions.engagementId);
    try {
      const response = await request(() => addPolicyEngagement(formData));
      if (response?.data?.statusCode === 201) {
        navigate('/policy/engagement');
        showNotifications('success', 'Client created successfully', 5000);
      } else {
        if (response.response.data.statusCode !== 201) {
          showNotifications('error', response.response.data.message, 5000);
        }
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  const getTemplateTypes = async () => {
    try {
      const response = await request(() => getAllTemplateType());
      if (response?.data?.statusCode === 200) {
        setPolicyTemplateTypes(response.data.body);
      } else {
        if (response.response.data.statusCode !== 200) {
          showNotifications('error', response.response.data.message, 5000);
        }
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  const getGenericContentMaster = async () => {
    try {
      const response = await request(() => getAllGenericPolicyMaster());
      if (response?.data?.statusCode === 200) {
        setPolicyMasterTypes(response.data.body);
      } else {
        if (response.response.data.statusCode !== 200) {
          showNotifications('error', response.response.data.message, 5000);
        }
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  useEffect(() => {
    getPolicies();
    getClients();
    getEngagements();
    getTemplateTypes();
    getProjectsAssignedToEmployee();
    getGenericContentMaster();
    projectNames;
    engagements;
    file;
    policyTemplateTypes;
    policyMasterTypes;
  }, []);

  const dropDownOptionsCreator = (type) => {
    return dropDownOptions[type].map((value) => (
      <MenuItem value={value} key={value}>
        {value}
      </MenuItem>
    ));
  };

  const createDropDownOptionsIndustry = (industry) => {
    if (!industry) return <MenuItem>Empty</MenuItem>;
    return industrySubIndustry[industry].map((value) => (
      <MenuItem value={value} key={value}>
        {value}
      </MenuItem>
    ));
  };

  const createDropDownOptionsEmployee = () => {
    return dropDownOptions['employee'].map((curr) => (
      <MenuItem value={curr.value} key={curr.value}>
        {curr.label}
      </MenuItem>
    ));
  };

  const createDropDownOptionsEngagement = () => {
    return engagements.map((engagement) => (
      <MenuItem value={engagement._id} key={engagement._id}>
        {engagement.projectId.projectName}
      </MenuItem>
    ));
  };

  const createDropDownOptionsProject = () => {
    return projectNames.map((project) => (
      <MenuItem value={project.value} key={project.value}>
        {project.label}
      </MenuItem>
    ));
  };

  return (
    <React.Fragment>
      <Box
        style={{
          marginBottom: '25px',
          display: 'flex',
          justifyContent: 'space-between'
        }}
      >
        <Button
          sx={{
            color: 'white',
            backgroundColor: 'black',
            paddingLeft: 1
          }}
          variant="contained"
          onClick={() => {
            navigate('/policy/engagement');
          }}
        >
          <ChevronLeftIcon />
          Back
        </Button>
      </Box>

      <Box
        style={{
          display: 'flex',
          justifyContent: 'start',
          alignItems: 'center'
        }}
      >
        <Typography
          sx={{ fontWeight: 'bold', marginBottom: '20px' }}
          variant="h4"
        >
          Add Policy Engagement
        </Typography>
      </Box>

      {/* Now I have to the textFields which are all there simple  */}
      <Box>
        <Typography
          sx={{ fontWeight: 'bold', marginRight: '50%', marginBottom: '20px' }}
          variant="h5"
        >
          Client Information
        </Typography>
        <Box
          style={{
            width: '100%',
            alignItems: 'center',
            gap: '10px',
            marginBottom: '13px',
            display: 'flex'
          }}
        >
          <FormControl style={{ width: '100%' }}>
            <InputLabel id="first-dropdown-label">Project Name * </InputLabel>
            <Select
              labelId="first-dropdown-label"
              label="Project Name * "
              value={formOptions.projectId}
              onChange={(event) => {
                const obj = projectNames.filter(
                  (project) => project.value === event.target.value
                );
                setFormOptionsHandler2(
                  event.target.value,
                  'projectId',
                  obj[0].clientName,
                  'clientName'
                );
              }}
            >
              {createDropDownOptionsProject()}
            </Select>
          </FormControl>
        </Box>
        <Box>
          <Box
            style={{
              width: '100%',
              alignItems: 'center',
              gap: '10px',
              marginBottom: '13px',
              display: 'flex'
            }}
          >
            {/* <Autocomplete
              fullWidth
              id="free-solo-demo"
              freeSolo
              options={dropDownOptions['clients'].map((option) => option.name)}
              onChange={(event, value) => {
                setFormOptionsHandler(value, 'clientName');
              }}
              onInputChange={(event) => {
                setFormOptionsHandler(event.target.value, 'clientName');
              }}
              renderInput={(params) => (
                <TextField {...params} label="Client Name" />
              )}
            /> */}
            <TextField
              id="outlined-basic"
              label="Client Name * "
              variant="outlined"
              fullWidth
              value={formOptions.clientName}
              onChange={(event) => {
                setFormOptionsHandler(event.target.value, 'clientName');
              }}
            />
            <TextField
              type="file"
              name="master"
              label="Upload Logo * "
              fullWidth
              // onChange={(e) => setSelectedFile(e.target.files[0])}
              onChange={handleFileSelection}
              InputLabelProps={{ shrink: true }}
            />
            <FormControl style={{ width: '100%' }}>
              <InputLabel id="first-dropdown-label">
                Aumyaa SPOC Name *
              </InputLabel>
              <Select
                labelId="first-dropdown-label"
                label="Aumyaa SPOC Name *"
                value={formOptions.aumyaaSPOCID}
                onChange={(event) => {
                  setFormOptionsHandler(event.target.value, 'aumyaaSPOCID');
                }}
              >
                {createDropDownOptionsEmployee()}
              </Select>
            </FormControl>
          </Box>

          <Box
            style={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              marginBottom: '13px',
              gap: '10px'
            }}
          >
            <FormControl style={{ width: '100%' }}>
              <InputLabel id="first-dropdown-label">Industry *</InputLabel>
              <Select
                labelId="first-dropdown-label"
                label="Industry *"
                value={firstDropdownValue}
                onChange={(event) => {
                  setFormOptionsHandler(event.target.value, 'industry');
                  setFirstDropdownValue(event.target.value);
                  setSecondDropdownValue('');
                }}
              >
                {dropDownOptionsCreator('industry')}
              </Select>
            </FormControl>

            <FormControl style={{ width: '100%' }}>
              <InputLabel id="second-dropdown-label">Sub Industry *</InputLabel>
              <Select
                labelId="second-dropdown-label"
                label="Sub Industry *"
                value={secondDropdownValue}
                onChange={(event) => {
                  setFormOptionsHandler(event.target.value, 'subIndustry');
                  setSecondDropdownValue(event.target.value);
                }}
                disabled={!firstDropdownValue}
              >
                {createDropDownOptionsIndustry(firstDropdownValue)}
              </Select>
            </FormControl>
            <FormControl style={{ width: '100%' }}>
              <InputLabel id="demo-simple-select-label">Country * </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={formOptions.country}
                label="Country *"
                onChange={(event) =>
                  setFormOptionsHandler(event.target.value, 'country')
                }
              >
                {dropDownOptionsCreator('country')}
              </Select>
            </FormControl>
          </Box>

          <Box
            style={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              gap: '10px'
            }}
          >
            <FormControl style={{ width: '100%' }}>
              <InputLabel id="first-dropdown-label">
                Do you want to link this policy engagement to an existing SOC
                2.0 / ISO 27001 engagement? *
              </InputLabel>
              <Select
                labelId="first-dropdown-label"
                label="Do you want to link this policy engagement to an existing SOC 2.0 / ISO 27001
                engagement?"
                value={formOptions.engagementRequired}
                onChange={(event) => {
                  setFormOptionsHandler(
                    event.target.value,
                    'engagementRequired'
                  );
                }}
              >
                <MenuItem value={true} key={'engagement-True'}>
                  Yes
                </MenuItem>
                <MenuItem value={false} key={'engagement-False'}>
                  No
                </MenuItem>
              </Select>
            </FormControl>
            {formOptions.engagementRequired && (
              <FormControl style={{ width: '100%' }}>
                <InputLabel id="first-dropdown-label">Engagement</InputLabel>
                <Select
                  labelId="first-dropdown-label"
                  label="Engagement"
                  value={formOptions.engagementId}
                  onChange={(event) => {
                    setFormOptionsHandler(event.target.value, 'engagementId');
                  }}
                >
                  {createDropDownOptionsEngagement()}
                </Select>
              </FormControl>
            )}
          </Box>
        </Box>

        <Box sx={{ marginTop: '25px' }}>
          <Typography
            sx={{
              fontWeight: 'bold',
              marginRight: '50%',
              marginBottom: '10px'
            }}
            variant="h5"
          >
            Create Generic Policy
          </Typography>

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              marginBottom: '25px'
            }}
          >
            <IconButton onClick={handleDeleteFields}>
              <RemoveIcon />
            </IconButton>
            <TextField
              sx={{
                width: inputFields.length < 10 ? '38px' : '45px',
                marginLeft: '10px',
                marginRight: '10px'
              }}
              value={inputFields.length}
              disabled
            />
            <IconButton onClick={handleAddFields}>
              <AddIcon />
            </IconButton>
          </Box>

          <Box
            sx={{
              width: '100%',
              '& .MuiDataGrid-columnHeaders': {
                backgroundColor: '#5A5A5A',
                color: 'white',
                fontSize: 16
              }
            }}
          >
            {inputFields.map((field) => (
              <Grid
                container
                spacing={2}
                key={field.id}
                sx={{ marginBottom: '10px' }}
              >
                <Grid item xs={4}>
                  <TextField
                    id="value"
                    name="value"
                    value={field.name}
                    onChange={(e) => handleVarFieldInput(field.id, e, 'name')}
                    label="Policy Name *"
                    fullWidth
                    autoComplete="value"
                  />
                </Grid>
                <Grid item xs={3}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Policy Type *
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      defaultValue={''}
                      label="Policy Type *"
                      onChange={(e) => handleVarFieldInput(field.id, e, 'type')}
                    >
                      {policyTemplateTypes.map((type) => {
                        return (
                          <MenuItem value={type.name} key={type.id}>
                            {type.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={3}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Master Type *
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      defaultValue={''}
                      label="Master Type *"
                      onChange={(e) => {
                        handleVarFieldInput(
                          field.id,
                          e,
                          'genericPolicyMasterID'
                        );
                      }}
                    >
                      {policyMasterTypes.map((type) => {
                        return (
                          <MenuItem value={type._id} key={type._id}>
                            {type.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={1}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center'
                    }}
                  >
                    <IconButton onClick={() => handleRemoveFields(field.id)}>
                      <RemoveIcon />
                    </IconButton>
                  </Box>
                </Grid>
                <Grid item xs={1}>
                  <IconButton onClick={handleAddFields}>
                    <AddIcon />
                  </IconButton>
                </Grid>
              </Grid>
            ))}
          </Box>
        </Box>

        <Box sx={{ marginTop: '25px' }}>
          <Typography
            sx={{
              fontWeight: 'bold',
              marginRight: '50%',
              marginBottom: '10px'
            }}
            variant="h5"
          >
            Select Policy Template
          </Typography>
          <Box
            sx={{
              height: 400,
              width: '100%',
              '& .MuiDataGrid-columnHeaders': {
                backgroundColor: '#5A5A5A',
                color: 'white',
                fontSize: 16
              }
            }}
          >
            <DataGrid
              rowHeight={60}
              rows={policyData}
              columns={columns}
              pageSize={5}
              rowsPerPageOptions={[5]}
              checkboxSelection
              onRowSelectionModelChange={(selectedPolicies) => {
                selectPolicies(selectedPolicies);
              }}
            />
          </Box>
        </Box>
      </Box>

      <Box style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          variant="contained"
          style={{
            marginTop: '20px',
            fontSize: '18px'
          }}
          onClick={createClientPolicyHandler}
        >
          Create
        </Button>
      </Box>
    </React.Fragment>
  );
};

export default AddPolicyEngagement;
