import * as React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Box, Button } from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import RestoreIcon from '@mui/icons-material/Restore';

export default function CustomGrid({
  rows,
  tabName,
  deleteCB,
  revertCB,
  tableMeta,
  fieldsMeta,
  handleChangeCB
}) {
  const onButtonClick = (e, row) => {
    e.stopPropagation();
    deleteCB(row);
  };

  const onRevertButtonClick = (e, row) => {
    e.stopPropagation();
    revertCB(row);
  };

  let columns = Object.keys(tableMeta).map((key) => {
    return {
      field: key,
      headerName: tableMeta[key]['isEditable']
        ? tableMeta[key]['displayName'] + '*'
        : tableMeta[key]['displayName'],
      editable: tableMeta[key]['isEditable'],
      type:
        fieldsMeta[key]['fieldType'] === 'dropDown'
          ? 'singleSelect'
          : fieldsMeta[key]['fieldType'],
      valueOptions:
        fieldsMeta[key]['fieldType'] === 'dropDown'
          ? fieldsMeta[key]['options']
          : '',
      width: fieldsMeta[key]['fieldType'] === 'multiLineTextField' ? 550 : 200,
      valueGetter:
        fieldsMeta[key]['fieldType'] === 'date'
          ? ({ value }) => value && new Date(value)
          : null
    };
  });

  const deleteAction = {
    field: 'deleteButton',
    headerName: 'Delete Action',
    description: 'Delete Column',
    sortable: false,
    width: 100,
    renderCell: (params) => {
      return (
        <>
          <Button onClick={(e) => onButtonClick(e, params.row)}>
            <DeleteIcon />
          </Button>
        </>
      );
    }
  };

  const revertAction = {
    field: 'revertButton',
    headerName: 'Revert Action',
    description: 'Revert column.',
    sortable: false,
    width: 100,
    renderCell: (params) => {
      return (
        <Button onClick={(e) => onRevertButtonClick(e, params.row)}>
          <RestoreIcon />
        </Button>
      );
    }
  };

  if (deleteCB) {
    columns = [...columns, deleteAction];
  }

  if (revertCB) {
    columns = [...columns, revertAction];
  }

  return (
    <Box
      sx={{
        height: 700,
        width: '100%'
      }}
    >
      <DataGrid
        sx={{
          boxShadow: 2,
          '& .MuiDataGrid-cell:hover': {
            color: 'primary.main'
          },
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: '#434242',
            color: 'white',
            fontSize: 16
          },
          '.MuiDataGrid-row.Mui-odd': {
            backgroundColor: '#D8D8D8'
          },
          '.MuiDataGrid-row.Mui-even': {
            backgroundColor: '#F5EDDC'
          }
        }}
        rows={rows}
        columns={columns}
        getRowId={() => uuidv4()}
        slots={{
          toolbar: GridToolbar
        }}
        getRowHeight={() => 'auto'}
        getEstimatedRowHeight={() => 150}
        checkboxSelection
        disableRowSelectionOnClick
        unstable_ignoreValueFormatterDuringExport
        experimentalFeatures={{ clipboardPaste: true }}
        processRowUpdate={(newRow, oldRow) =>
          handleChangeCB(newRow, oldRow, tabName)
        }
        getRowClassName={(params) =>
          params.indexRelativeToCurrentPage % 2 === 0 ? 'Mui-even' : 'Mui-odd'
        }
      />
    </Box>
  );
}
