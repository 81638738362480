import React, { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import { Button, Typography } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import TextField from '@mui/material/TextField';
import { useNavigate, useParams } from 'react-router-dom';
import {
  getAllTags,
  getAllVersionsOfPolicyTemplateById
} from '../../../constants/requests';
import { useLoading } from '../../../hooks/useLoading';
import { useNotifications } from '../../../hooks/useNotifications';
import AddNewQuestionTable from '../Components/AddNewQuestionTable';
import { TagsToSkip } from '../../../constants/TagsToSkip';

const AddPolicyTemplateVersion = () => {
  const navigate = useNavigate();
  const { request } = useLoading();
  const { showNotifications } = useNotifications();
  const params = useParams();

  const [policyName, setPolicyName] = useState('');
  const [policyType, setPolicyType] = useState('');
  const [policyTemplateTypes, setPolicyTemplateTypes] = useState([]);
  const [tableTagsType, setTableTagsType] = useState('Tags');

  const [originalTableData, setOriginalTableData] = useState([]);

  const [file, setFile] = useState(null);
  const [possible, setPossible] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [error, setError] = useState(false);

  const getCurrentPolicyTemplate = async () => {
    try {
      const response = await request(() =>
        getAllVersionsOfPolicyTemplateById(params.id)
      );
      if (response?.data?.statusCode === 200) {
        setPolicyName(response.data.body.name);
        setPolicyType(response.data.body.type);
        const tags =
          response.data.body.wordDocumentID.versions.at(-1).data.tags;
        const { data } = response.data.body.wordDocumentID.versions.at(-1);
        const tableNames = data?.tables ? Object.keys(data.tables) : [];
        const newTableData = tags.map(({ Tag, Question }) => ({
          Tag,
          Question,
          Type: 'tag',
          status: 'common'
        }));
        tableNames.forEach((tableName) => {
          newTableData.push({
            Tag: tableName,
            Question: 'Q. ',
            Type: 'table',
            status: 'common'
          });
        });
        setTableData([...newTableData]);
        setOriginalTableData([...newTableData]);
      } else {
        if (response.response.data.statusCode !== 200) {
          showNotifications('error', response.response.data.message, 5000);
        }
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  const handleFileUpload = async () => {
    setError(false);
    try {
      const formData = new FormData();
      formData.append('file', file);
      const response = await request(() => getAllTags(formData));
      if (response?.data?.statusCode === 200) {
        const newTableData = response.data.body.map((tag) => {
          return {
            Tag: tag,
            Question: 'Q. ',
            Type: 'tag'
          };
        });
        const filteredTableData = newTableData.filter((tag) => {
          return !TagsToSkip.includes(tag.Tag);
        });
        const finalTableData = handleNewTagsFilteration(filteredTableData);
        setTableData(finalTableData);
        setPossible(true);
      } else {
        if (response.response.data.statusCode !== 200) {
          showNotifications('error', response.response.data.message, 5000);
          setError(true);
          setPossible(false);
        }
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
      setError(true);
      setPossible(false);
    }
  };

  const handleNewTagsFilteration = (newTableData) => {
    const commonTags = [];
    const onlyInNewTableData = [];
    const onlyInTableData = [];
    originalTableData.forEach((tableTag) => {
      const matchingTag = newTableData.find(
        (newTag) => newTag.Tag === tableTag.Tag
      );
      if (matchingTag) {
        commonTags.push({ ...tableTag, status: 'common' });
      } else {
        onlyInTableData.push({ ...tableTag, status: 'removed' });
      }
    });
    newTableData.forEach((newTag) => {
      const matchingTag = originalTableData.find(
        (tableTag) => tableTag.Tag === newTag.Tag
      );
      if (!matchingTag) {
        onlyInNewTableData.push({ ...newTag, status: 'new' });
      }
    });
    return [...onlyInNewTableData, ...commonTags, ...onlyInTableData];
  };

  const handleFileSelection = (event) => {
    setFile(event.target.files[0]);
  };

  useEffect(() => {
    getCurrentPolicyTemplate();
  }, []);

  return (
    <React.Fragment>
      <Box
        style={{
          marginBottom: '25px',
          display: 'flex',
          justifyContent: 'space-between'
        }}
      >
        <Button
          sx={{
            color: 'white',
            backgroundColor: 'black',
            paddingLeft: 1
          }}
          variant="contained"
          onClick={() => navigate(`/policy`)}
        >
          <ChevronLeftIcon />
          Back
        </Button>
      </Box>

      <Box
        style={{
          display: 'flex',
          justifyContent: 'start',
          alignItems: 'center',
          marginBottom: '30px'
        }}
      >
        <Typography sx={{ fontWeight: 'bold', flexGrow: 1 }} variant="h4">
          Add New Version
        </Typography>
      </Box>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-around',
          flexWrap: 'wrap',
          margin: 'auto',
          marginBottom: '50px',
          border: '2px solid #cfcfcf',
          borderRadius: '10px',
          boxShadow: '3px 3px 13px -8px rgba(0,0,0,0.75)',
          padding: 4,
          width: '70%'
        }}
      >
        <TextField type="file" onChange={handleFileSelection} />
        <Button
          variant="contained"
          sx={{ backgroundColor: 'black' }}
          onClick={handleFileUpload}
          disabled={!file}
        >
          Read Tags
        </Button>
      </Box>
      {error && (
        <Typography variant="h3" sx={{ color: 'red' }}>
          There is some error in the file you just submitted.
        </Typography>
      )}
      {possible && (
        <AddNewQuestionTable
          tableData={tableData}
          setTableData={setTableData}
          file={file}
          policyName={policyName}
          setPolicyName={setPolicyName}
          policyType={policyType}
          setPolicyType={setPolicyType}
          policyTemplateTypes={policyTemplateTypes}
          setPolicyTemplateTypes={setPolicyTemplateTypes}
          tableTagsType={tableTagsType}
          setTableTagsType={setTableTagsType}
          disableTagsAndTables={false}
        />
      )}
    </React.Fragment>
  );
};

export default AddPolicyTemplateVersion;
