import React, { useEffect } from 'react';
import { Box } from '@mui/system';
import { Button, Typography } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { useNavigate, useParams } from 'react-router-dom';
import { useState } from 'react';
import {
  Table,
  Modal,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  TableHead,
  tableCellClasses
} from '@mui/material';
import { styled } from '@mui/material/styles';
import CustomTablePolicy from '../Components/CustomTablePolicy';
import {
  createControl,
  getEngagementPolicyById,
  getPolicyTemplateById,
  updateEngagementPolicyById_Table,
  downloadPolicyById,
  triggerPolicyApprovalWorkflow,
  getWorkflowHandlerByIdRequest,
  generateSentence
} from '../../../constants/requests';
import { useLoading } from '../../../hooks/useLoading';
import { useNotifications } from '../../../hooks/useNotifications';
import fileDownload from 'js-file-download';
import AddGovernancesModal from '../Components/AddGovernancesModal';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import AllPolicyGovernance from './AllPolicyGovernance';
import CreateEditDialog from '../../Services/common/CreateEditDialog';
import { useDataLayerValue } from '../../../contextAPI/DataLayer';
import WorkflowHistoryModal from '../Components/WorkflowHistoryModal';
import PromptDialogue from '../Components/PromptDialogue';
// import TempAllPolicyGovernance from './TempAllPolicyGovernance';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    fontSize: '17px',
    fontWeight: 500
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16
  }
}));

const addControlForm = [
  {
    type: 'textField',
    fieldName: 'SCF Domain',
    fieldLabel: 'Enter Domain',
    value: '',
    required: true
  },
  {
    type: 'textField',
    fieldName: 'SCF Control',
    fieldLabel: 'Enter Control',
    value: '',
    required: true
  },
  {
    type: 'textField',
    fieldName: 'SCF #',
    fieldLabel: 'Enter Control Number',
    value: '',
    required: true
  },
  {
    type: 'textField',
    fieldName: 'Secure Controls Framework (SCF) – Control Description',
    fieldLabel: 'Enter Control Description',
    value: '',
    required: true
  },
  {
    type: 'textField',
    fieldName: 'Methods To Comply With SCF Controls',
    fieldLabel: 'Enter Methods To Comply With Controls',
    value: '',
    required: true
  },
  {
    type: 'textField',
    fieldName: 'Evidence Request List (ERL) #',
    fieldLabel: 'Enter Evidence Request List Number',
    value: '',
    required: true
  },
  {
    type: 'textField',
    fieldName: 'SCF Control Question',
    fieldLabel: 'Enter Control Question',
    value: '',
    required: true
  },
  {
    type: 'textField',
    fieldName: 'SCF Control Answer',
    fieldLabel: 'Enter Control Answer',
    value: '',
    required: true
  },
  {
    type: 'textField',
    fieldName: 'Function Grouping',
    fieldLabel: 'Enter Process Grouping',
    value: '',
    required: true
  }
];

const FillEngagementPolicy = () => {
  const [{ _id }] = useDataLayerValue();
  const navigate = useNavigate();
  const params = useParams();
  const { request } = useLoading();
  const { showNotifications } = useNotifications();
  const [policyName, setPolicyName] = useState('');
  const [alignment, setAlignment] = useState('Tags');
  const [originalPolicyId, setOriginalPolicyId] = useState('');
  const [tableData, setTableData] = useState([]);
  const [governanceId, setGovernanceId] = useState([]);
  const [questionAnswer, setQuestionAnswer] = useState([]);
  const [governanceTableData, setGovernanceTableData] = useState(null);
  const [showPromptDialogue, setShowPromptDialogue] = useState(false);
  const [lastApprovedGovernanceTableData, setLastApprovedGovernanceTableData] =
    useState(null);
  const [view, setView] = useState(true);
  const [workflowHandlerStatus, setWorkflowHandlerStatus] = useState(null);
  const [workflowHandlerStatusDownload, setWorkflowHandlerStatusDownload] =
    useState(null);

  const [openWorkflowHistoryModal, setOpenWorkflowHistoryModal] =
    useState(false);
  const handleOpenWorkflowHistoryModal = () =>
    setOpenWorkflowHistoryModal(true);
  const handleCloseWorkflowHistoryModal = () =>
    setOpenWorkflowHistoryModal(false);

  const [completionDate, setCompletionDate] = useState('');
  const [generateButtonDisable, setGenerateButtonDisable] = useState([]);
  // const [generatedControlActivities, setGeneratedControlActivities] = useState([]);

  const [openAddControlModal, setOpenAddControlModal] = useState(false);
  const handleOpenAddControlModal = () => setOpenAddControlModal(true);
  const handleCloseAddControlModal = () => setOpenAddControlModal(false);

  const [generate, setGenerate] = useState(null);

  const [openAddGovernanceModal, setOpenAddGovernanceModal] = useState(false);
  const handleOpenAddGovernanceModal = () => setOpenAddGovernanceModal(true);
  const handleCloseAddGovernanceModal = () => setOpenAddGovernanceModal(false);
  const handlePromptDialogue = () => setShowPromptDialogue(true);
  const handleClosePromptDialogue = () => setShowPromptDialogue(false);
  const formatCompletionDate = () => {
    if (completionDate === '') return;
    const datetime = new Date(completionDate);
    const year = datetime.getFullYear();
    const month = datetime.getMonth() + 1; // Months are zero-based, so add 1
    const day = datetime.getDate();
    let hours = datetime.getHours();
    const minutes = datetime.getMinutes();
    const seconds = datetime.getSeconds();
    let ampm = 'AM';
    if (hours >= 12) {
      ampm = 'PM';
      hours -= 12;
    }
    if (hours === 0) {
      hours = 12;
    }
    const formattedDate = `${day.toString().padStart(2, '0')}/${month
      .toString()
      .padStart(2, '0')}/${year}`;
    const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes
      .toString()
      .padStart(2, '0')}:${seconds.toString().padStart(2, '0')} ${ampm}`;

    return `(${formattedDate}) - (${formattedTime})`;
  };

  const toggleHandleChange = (event, newAlignment) => {
    if (newAlignment !== null) {
      setAlignment(newAlignment);
    }
  };

  const updateAnswerHandler = (tag, event) => {
    const newQuestionAnswer = questionAnswer.map((question) => {
      if (question.Tag === tag) {
        return { ...question, Answer: event.target.value };
      }
      return question;
    });
    setQuestionAnswer(newQuestionAnswer);
  };

  const isButtonUsable = (buttonType, workflowHandlerStatus) => {
    if (buttonType === 'submit') {
      if (workflowHandlerStatus === null) return true;
      if (workflowHandlerStatus === 'Active') return false;
      if (workflowHandlerStatus === 'Completed') return true;
      if (workflowHandlerStatus === 'Rejected') return true;
    }

    if (buttonType === 'download-approval') {
      if (workflowHandlerStatus === null) return false;
      if (workflowHandlerStatus === 'Active') return true;
      if (workflowHandlerStatus === 'Completed') return true;
      if (workflowHandlerStatus === 'Rejected') return false;
    }

    if (buttonType === 'download') {
      if (workflowHandlerStatus === null) return false;
      if (workflowHandlerStatus === 'Active') return false;
      if (workflowHandlerStatus === 'Completed') return true;
      if (workflowHandlerStatus === 'Rejected') return false;
    }
  };

  const getSubmitButtonText = (buttonType, workflowHandlerStatus) => {
    if (buttonType === 'submit') {
      if (workflowHandlerStatus === null) return 'Submit For Approval';
      if (workflowHandlerStatus === 'Active') return 'Approval pending';
      if (workflowHandlerStatus === 'Completed') return 'Submit For Approval';
      if (workflowHandlerStatus === 'Rejected') return 'Submit For Approval';
    }

    if (buttonType === 'download') {
      if (workflowHandlerStatus === null) return 'Submit For Download Approval';
      if (workflowHandlerStatus === 'Active') return 'Approval pending';
      if (workflowHandlerStatus === 'Completed') return 'Download Approved';
      if (workflowHandlerStatus === 'Rejected')
        return 'Submit For Download Approval';
    }
  };

  const incrementTableRows = (tabName) => {
    const singleRow = {};
    Object.keys(tableData[tabName].meta.fieldsMeta).map((columnName) => {
      singleRow[columnName] = '';
    });
    const modifiedTableData = { ...tableData };
    const newRows = [...tableData[tabName].rows];
    newRows.push(singleRow);
    modifiedTableData[tabName] = {
      ...modifiedTableData[tabName],
      rows: newRows
    };
    setTableData(modifiedTableData);
  };

  const deleteSelectedRow = (tabName, idx) => {
    const modifiedTableData = { ...tableData };
    const tabData = modifiedTableData[tabName];
    const newRows = tabData.rows.filter((row, index) => index !== idx);
    tabData.rows = newRows;
    setTableData(modifiedTableData);
  };

  const handleChange = (rowNumber, fieldName, cellValue, tabName) => {
    const modifiedTableData = { ...tableData };
    modifiedTableData[tabName]['rows'][rowNumber][fieldName] = cellValue;
    setTableData(modifiedTableData);
  };

  const handleChangeGovernance = (rowNumber, fieldName, cellValue, tabName) => {
    const modifiedTableData = { ...governanceTableData };
    modifiedTableData[tabName]['rows'][rowNumber][fieldName] = cellValue;
    setGovernanceTableData(modifiedTableData);
    if (
      fieldName === 'Automated/Manual' ||
      fieldName === 'System (If Automated)' ||
      fieldName === 'Which Way (If Manual)' ||
      fieldName === 'How It Is Done'
    ) {
      let generateButtonStatus = [...generateButtonDisable];
      generateButtonStatus[rowNumber] = false;
      setGenerateButtonDisable(generateButtonStatus);
    }
  };

  // const handleChangeGovernance2 = (newRow, oldRow, tabName) => {
  //   const modifiedTableData = {
  //     ...governanceTableData,
  //     [tabName]: {
  //       ...governanceTableData[tabName],
  //       rows: governanceTableData[tabName].rows.map((row) =>
  //         JSON.stringify(row) === JSON.stringify(oldRow) ? { ...newRow } : row
  //       )
  //     }
  //   };
  //   setGovernanceTableData(modifiedTableData);
  // };

  const getPolicyDetails = async () => {
    try {
      const response = await request(() =>
        getEngagementPolicyById(params.policyid)
      );
      if (response?.data?.statusCode === 200) {
        setOriginalPolicyId(response.data.body.templateID);
        setPolicyName(response.data.body.name);
        const tags = response.data.body.tags;
        const newTableData = exctractAndCreateTableData(response.data.body);
        const newGovernanceData = exctractAndCreateTableDataForGovernance(
          response.data.body.SCF,
          true,
          response.data.body
        );

        let generateButtonStatus = [];
        for (let i = 0; i < newGovernanceData['controls']['rows'].length; i++) {
          generateButtonStatus.push(false);
        }

        setGovernanceId(response.data.body.SCF.governanceIDs);
        setTableData(newTableData);
        setGovernanceTableData(newGovernanceData);
        setGenerateButtonDisable(generateButtonStatus);
        setQuestionAnswer(tags);
        setWorkFlowStateHandler();
        setView(true);
      } else {
        if (response.response.data.statusCode !== 200) {
          showNotifications('error', response.response.data.message, 5000);
        }
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  const [payloadDataReceived, setPayloadDataReceived] = useState(false);

  const handlePayloadData = (payload) => {
    console.log('Received data: ', payload);
    setGenerate(payload);
    setPayloadDataReceived(true);
    loadApplication();
  };

  const handleAddCustomControl = async (newRecord) => {
    try {
      let config = {};
      console.log(generate);
      Object.keys(newRecord).forEach((record) => {
        config[record] = newRecord[record].value;
      });
      config['Status'] = '';
      config['Reason'] = '';
      config['Flow in Policy?'] = 'false';
      //config['SCF Domain']=generate[0].process;//mapped

      const newGovernanceTableData = { ...governanceTableData };
      let newRows = newGovernanceTableData['controls']['rows'];
      newRows = [...newRows, config];
      newGovernanceTableData['controls']['rows'] = newRows;
      const payload = {
        data: config
      };
      const response = await request(() => createControl(payload));
      if (response?.data?.statusCode === 201) {
        setGovernanceTableData(newGovernanceTableData);
        handleCloseAddControlModal();
        showNotifications('success', response.data.message, 5000);
      } else {
        if (response.response.data.statusCode !== 201) {
          showNotifications('error', response.response.data.message, 5000);
        }
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  const loadApplication = async () => {
    try {
      console.log(governanceTableData);
      console.log(generate);
      const newGovernanceTableData = { ...governanceTableData };
      const newRows = [...newGovernanceTableData.controls.rows];
      let ind = newRows.length;
      for (var i = 0; i < generate.response.length; i++) {
        const domain = generate.process;

        for (var j = 0; j < generate.response[i].control_activity.length; j++) {
          const controll = generate.response[i].control_activity[j].subprocess;
          for (
            var k = 0;
            k < generate.response[i].control_activity[j].activity.length;
            k++
          ) {
            const control_act =
              generate.response[i].control_activity[j].activity[k];
            console.log(generate.response[i].control_activity[j].activity[k]);
            console.log(control_act);
            const newrow = {
              'SCF Domain': domain,
              'SCF Control': controll,
              'Control Activity': control_act,
              'SCF #': JSON.stringify(ind + 1)
            };
            ind = ind + 1;
            newRows.push(newrow);
          }
        }
      }

      newGovernanceTableData.controls.rows = newRows;
      const updatedClientData = {
        SCF: {
          governanceIDs: governanceId,
          controls: newGovernanceTableData['controls']['rows']
        }
      };
      const response = await request(() =>
        updateEngagementPolicyById_Table(params.policyid, updatedClientData)
      );

      if (response.data.statusCode === 200) {
        console.log(response);
        //setGovernanceTableData(newGovernanceTableData);
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  useEffect(() => {
    if (payloadDataReceived) {
      loadApplication();
    }
  }, [payloadDataReceived]);

  const getPolicyDetailsForGovernance = async () => {
    try {
      const response = await request(() =>
        getEngagementPolicyById(params.policyid)
      );
      if (response?.data?.statusCode === 200) {
        const newGovernanceData = exctractAndCreateTableDataForGovernance(
          response.data.body.SCF,
          true,
          response.data.body
        );
        setGovernanceId(response.data.body.SCF.governanceIDs);
        setGovernanceTableData(newGovernanceData);
        setView(true);
      } else {
        if (response.response.data.statusCode !== 200) {
          showNotifications('error', response.response.data.message, 5000);
        }
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  const getPolicyDetailsForLastApprovedGovernance = async () => {
    try {
      const response = await request(() =>
        getEngagementPolicyById(params.policyid)
      );
      if (response?.data?.statusCode === 200) {
        if (response.data.body.lastApprovedSCF === null) {
          setLastApprovedGovernanceTableData(null);
        } else {
          const lastApprovedGovernanceData =
            exctractAndCreateTableDataForGovernance(
              response.data.body.lastApprovedSCF,
              false,
              response.data.body
            );
          setLastApprovedGovernanceTableData(lastApprovedGovernanceData);
        }
        setView(true);
      } else {
        if (response.response.data.statusCode !== 200) {
          showNotifications('error', response.response.data.message, 5000);
        }
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  const controlTableChecks = (each) => {
    if (each) {
      const hasEmptyStatus = governanceTableData.controls.rows.some(
        (row) => row.Status === ''
      );
      if (hasEmptyStatus) {
        showNotifications('error', 'Assign Status to each Control', 5000);
        return false;
      }
    }
    const hasEmptyReasons = governanceTableData.controls.rows.some(
      (row) =>
        (row.Status === 'NOT_APPLICABLE' || row.Status === 'NOT_COMPLIANT') &&
        row.Reason === ''
    );
    if (hasEmptyReasons) {
      showNotifications(
        'error',
        'Kindly fill reason for all Non-Compliant and Non-Applicable Status',
        5000
      );
      return false;
    }
    return true;
  };

  const updatePolicyDetails = async () => {
    if (!controlTableChecks(false)) return false;

    try {
      const reqTable = {};
      Object.keys(tableData).map((key) => {
        reqTable[key] = tableData[key]['rows'];
      });
      const updatedClientData = {
        tags: questionAnswer,
        tables: reqTable,
        SCF: {
          governanceIDs: governanceId,
          controls: governanceTableData['controls']['rows']
        }
      };
      const response = await request(() =>
        updateEngagementPolicyById_Table(params.policyid, updatedClientData)
      );
      console.log(response);
      if (response?.data?.statusCode === 200) {
        handleCloseAddGovernanceModal();
        showNotifications('success', 'Save successful', 5000);
      } else {
        if (response.response.data.statusCode !== 200) {
          showNotifications('error', response.response.data.message, 5000);
        }
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
    return true;
  };

  const submitForSCFApprovalHandler = async () => {
    const updatePolicyResponse = await updatePolicyDetails();
    if (!updatePolicyResponse) return;
    if (!controlTableChecks(true)) return false;

    try {
      const updatedSCFData = {
        isSCFApproved: true,
        lastApprovedSCF: {
          governanceIDs: governanceId,
          controls: governanceTableData['controls']['rows']
        }
      };
      const response = await request(() =>
        triggerPolicyApprovalWorkflow(params.policyid, updatedSCFData, _id)
      );
      if (response?.data?.statusCode === 200) {
        const { _id: SCFWorkflowHandlerId } = response.data.body;
        const updatedClientData = {
          SCFWorkflowHandlerId
        };
        const policyUpdateResponse = await request(() =>
          updateEngagementPolicyById_Table(params.policyid, updatedClientData)
        );
        if (policyUpdateResponse.data.statusCode !== 200) {
          showNotifications('error', 'Server Error', 5000);
          return;
        }
        setWorkFlowStateHandler();
        showNotifications(
          'success',
          'Approval Request Submitted Successfully',
          5000
        );
      } else {
        if (response.response.data.statusCode !== 200) {
          showNotifications('error', response.response.data.message, 5000);
        }
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  const submitForDownloadApprovalHandler = async () => {
    try {
      const updatedClientData = {
        isPolicyDownloadApproved: true
      };
      const response = await request(() =>
        triggerPolicyApprovalWorkflow(params.policyid, updatedClientData, _id)
      );
      if (response?.data?.statusCode === 200) {
        const { _id: policyDownloadWorkflowHandlerId } = response.data.body;
        const updatedClientData = {
          policyDownloadWorkflowHandlerId
        };
        const policyUpdateResponse = await request(() =>
          updateEngagementPolicyById_Table(params.policyid, updatedClientData)
        );
        if (policyUpdateResponse.data.statusCode !== 200) {
          showNotifications('error', 'Server Error', 5000);
          return;
        }
        setWorkFlowStateHandler();
        showNotifications(
          'success',
          'Download Approval Request Submitted Successfully',
          5000
        );
      } else {
        if (response.response.data.statusCode !== 200) {
          showNotifications('error', response.response.data.message, 5000);
        }
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  //somehow the workflowHanlder Id's are different for both use cases - so figure that out now
  const setWorkFlowStateHandler = async () => {
    try {
      const response = await request(() =>
        getEngagementPolicyById(params.policyid)
      );
      if (response?.data?.statusCode === 200) {
        if (response.data.body.SCFWorkflowHandlerId !== null) {
          const workflowHandlerSCF = await request(() =>
            getWorkflowHandlerByIdRequest(
              response.data.body.SCFWorkflowHandlerId
            )
          );

          //for using the completion date of previous workflow
          const numberOfWorkflows =
            response.data.body.SCFWorkflowHandlerHistory.length;
          if (
            workflowHandlerSCF.data.body.completionDate === undefined &&
            numberOfWorkflows > 1
          ) {
            const workflowHandlerSCFPrevious = await request(() =>
              getWorkflowHandlerByIdRequest(
                response.data.body.SCFWorkflowHandlerHistory[
                  numberOfWorkflows - 2
                ]
              )
            );
            setCompletionDate(
              workflowHandlerSCFPrevious.data.body.completionDate
            );
          } else {
            setCompletionDate(workflowHandlerSCF.data.body.completionDate);
          }

          if (workflowHandlerSCF.data.statusCode === 200) {
            setWorkflowHandlerStatus(workflowHandlerSCF.data.body.status);
          }
        }

        if (response.data.body.policyDownloadWorkflowHandlerId !== null) {
          const workflowHandlerDownload = await request(() =>
            getWorkflowHandlerByIdRequest(
              response.data.body.policyDownloadWorkflowHandlerId
            )
          );

          if (workflowHandlerDownload.data.statusCode === 200) {
            // setWorkflowHandlerStatus(workflowHandler.data.body.status);
            setWorkflowHandlerStatusDownload(
              workflowHandlerDownload.data.body.status
            );
          }
        }
      } else {
        if (response.response.data.statusCode !== 200) {
          showNotifications('error', response.response.data.message, 5000);
        }
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  //how to download word!
  const policyDownloadHandler = async () => {
    try {
      const response1 = await request(() =>
        getPolicyTemplateById(originalPolicyId)
      );
      const response2 = await request(() =>
        downloadPolicyById(params.policyid)
      );
      console.log(response2);
      if (response2?.status === 200) {
        fileDownload(response2.data, `${response1.data.body.name}.docx`);
        setTimeout(() => {
          showNotifications(
            'success',
            'Refresh the page if the file is not downloaded',
            1000
          );
        }, 2000);
      } else {
        if (response2.response.data.statusCode !== 200) {
          showNotifications('error', response2.response.data.message, 5000);
        }
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  useEffect(() => {
    getPolicyDetails();
    getPolicyDetailsForLastApprovedGovernance();
    completionDate;
    workflowHandlerStatusDownload;
    AllPolicyGovernance;
  }, []);

  const exctractAndCreateTableData = (body) => {
    const tableData = {};
    for (const key in body) {
      if (key !== 'tables') continue;
      for (const tableKey in body[key]) {
        const fieldsMeta = {};
        const tableMeta = {};
        for (const field in body[key][tableKey][0]) {
          fieldsMeta[field] = { fieldType: 'multiLineTextField' };
          tableMeta[field] = { isEditable: true, displayName: field };
        }
        const newRows = body[key][tableKey].map((obj) => {
          return Object.entries(obj).reduce((acc, [key, value]) => {
            acc[key] = value === null ? '' : value;
            return acc;
          }, {});
        });
        tableData[tableKey] = {};
        tableData[tableKey]['meta'] = {};
        tableData[tableKey]['meta']['fieldsMeta'] = fieldsMeta;
        tableData[tableKey]['meta']['tableMeta'] = tableMeta;
        tableData[tableKey]['rows'] = newRows;
      }
    }
    return tableData;
  };

  const handleDeleteControls = async (idx) => {
    const filteredData = governanceTableData['controls']['rows'].filter(
      (_, controlIndex) => idx !== controlIndex
    );
    const newGovData = { ...governanceTableData };
    governanceTableData['controls']['rows'] = filteredData;
    setGovernanceTableData(newGovData);
  };

  // //updated delete function baby!
  // const handleDeleteControls2 = async (currRow) => {
  //   const filteredData = governanceTableData['controls']['rows'].filter(
  //     (row) => JSON.stringify(row) !== JSON.stringify(currRow)
  //   );
  //   const newGovData = { ...governanceTableData };
  //   governanceTableData['controls']['rows'] = filteredData;
  //   setGovernanceTableData(newGovData);
  // };

  const handleReorderControls = (idx, move) => {
    console.log(idx);
    const controlsData = governanceTableData['controls']['rows'];
    const control = controlsData[idx];
    let newIndex = idx;
    if (move === 'up' && idx > 0) {
      newIndex = idx - 1;
    } else if (move === 'down' && idx < controlsData.length - 1) {
      newIndex = idx + 1;
    } else {
      return; // Ignore invalid move
    }
    const filteredData = controlsData.filter(
      (_, controlIndex) => idx !== controlIndex
    );
    filteredData.splice(newIndex, 0, control);
    const newGovData = { ...governanceTableData };
    newGovData['controls']['rows'] = filteredData;
    setGovernanceTableData(newGovData);
  };

  // const handleReorderControls2 = (currRow, move) => {
  //   const idx = governanceTableData['controls']['rows'].findIndex(
  //     (row) => JSON.stringify(row) === JSON.stringify(currRow)
  //   );
  //   const controlsData = governanceTableData['controls']['rows'];
  //   const control = controlsData[idx];
  //   let newIndex = idx;
  //   if (move === 'up' && idx > 0) {
  //     newIndex = idx - 1;
  //   } else if (move === 'down' && idx < controlsData.length - 1) {
  //     newIndex = idx + 1;
  //   } else {
  //     return; // Ignore invalid move
  //   }
  //   const filteredData = controlsData.filter(
  //     (_, controlIndex) => idx !== controlIndex
  //   );
  //   filteredData.splice(newIndex, 0, control);
  //   const newGovData = { ...governanceTableData };
  //   newGovData['controls']['rows'] = filteredData;
  //   setGovernanceTableData(newGovData);
  // };

  const exctractAndCreateTableDataForGovernance = (
    body,
    editable = true,
    data
  ) => {
    const tableData = {};
    const newRows = body['controls'].map((obj) => {
      return Object.entries(obj).reduce((acc, [key, value]) => {
        acc[key] = value === null ? '' : value;
        return acc;
      }, {});
    });
    tableData['controls'] = {};
    tableData['controls']['meta'] = {};
    tableData['controls']['meta']['fieldsMeta'] = data.SCFTemplate.meta.fields;
    tableData['controls']['meta']['tableMeta'] = editable
      ? data.SCFTemplate.meta.PolicyControls
      : data.SCFTemplate.meta.LastApprovedPolicyControls;
    const updatedRows = newRows.map((obj) => {
      return {
        ...obj,
        ['Flow in Policy?']:
          obj['Flow in Policy?'] === '' ? 'false' : obj['Flow in Policy?']
      };
    });
    tableData['controls']['rows'] = updatedRows;
    return tableData;
  };

  //this needs
  const generateSentenceFromKeyword = async (keywordObject, rowNumber) => {
    try {
      const response = await request(() => generateSentence(keywordObject));
      if (response.data.statusCode === 200) {
        handleChangeGovernance(
          rowNumber,
          'Secure Controls Framework (SCF) – Control Description',
          response.data.body,
          'controls'
        );
        let generateButtonStatus = [...generateButtonDisable];
        generateButtonStatus[rowNumber] = true;
        setGenerateButtonDisable(generateButtonStatus);
        showNotifications('success', response.data.message, 200);
      } else {
        showNotifications('error', response.data.message, 5000);
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  return (
    <React.Fragment>
      <Box sx={{ marginBottom: '50px' }}>
        <Box
          style={{
            marginBottom: '25px',
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <Button
            sx={{
              color: 'white',
              backgroundColor: 'black',
              paddingLeft: 1
            }}
            variant="contained"
            onClick={() => navigate(`/policy/engagement/${params.id}`)}
          >
            <ChevronLeftIcon />
            Back
          </Button>
        </Box>
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '30px'
          }}
        >
          <Typography sx={{ fontWeight: 'bold', flexGrow: 1 }} variant="h4">
            Fill Policy Form - {policyName}
          </Typography>
          <Button
            sx={{
              marginLeft: 1,
              height: 50,
              marginRight: 0.5
            }}
            variant="contained"
            onClick={() => {
              updatePolicyDetails();
            }}
          >
            Save
          </Button>
          <Button
            sx={{ backgroundColor: 'black', marginLeft: 1, height: 50 }}
            variant="contained"
            onClick={() => handleOpenAddGovernanceModal()}
          >
            Governance
          </Button>
          <Button
            sx={{ backgroundColor: 'black', marginLeft: 1, height: 50 }}
            variant="contained"
            onClick={submitForDownloadApprovalHandler}
            disabled={isButtonUsable(
              'download-approval',
              workflowHandlerStatusDownload
            )}
          >
            {getSubmitButtonText('download', workflowHandlerStatusDownload)}
          </Button>
          <Button
            sx={{ backgroundColor: 'black', marginLeft: 1, height: 50 }}
            variant="contained"
            onClick={policyDownloadHandler}
            disabled={
              !isButtonUsable('download', workflowHandlerStatusDownload)
            }
          >
            Download
          </Button>
        </Box>

        <Box sx={{ margin: 'auto', textAlign: 'center', width: '80%' }}>
          <ToggleButtonGroup
            fullWidth
            color="primary"
            value={alignment}
            exclusive
            onChange={toggleHandleChange}
            aria-label="Platform"
          >
            <ToggleButton value="Tags">Tags</ToggleButton>
            <ToggleButton value="Tables">Tables</ToggleButton>
            <ToggleButton value="Governance">Governance</ToggleButton>
          </ToggleButtonGroup>
        </Box>

        {view && alignment === 'Tags' && (
          <Box
            sx={{
              marginTop: '15px',
              marginLeft: 1,
              marginRight: 1,
              border: '2px solid rgba(224, 224, 224, 1)',
              borderLeftColor: 'white'
            }}
          >
            <TableContainer sx={{ maxHeight: '500px', overflow: 'auto' }}>
              <Table sx={{ border: 2, borderColor: 'rgba(224, 224, 224, 1)' }}>
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Tags</StyledTableCell>
                    <StyledTableCell>Questions</StyledTableCell>
                    <StyledTableCell>Answers</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {questionAnswer.map((question, idx) => (
                    <TableRow key={idx}>
                      <StyledTableCell style={{ width: '100px' }}>
                        {question.Tag}
                      </StyledTableCell>
                      <StyledTableCell style={{ maxWidth: '250px' }}>
                        {question.Question}
                      </StyledTableCell>
                      <StyledTableCell>
                        <TextField
                          fullWidth
                          variant="outlined"
                          placeholder="Enter your answer here"
                          value={question.Answer}
                          onChange={(event) => {
                            updateAnswerHandler(question.Tag, event);
                          }}
                          required
                        />
                      </StyledTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        )}

        {view && alignment === 'Tables' && (
          <Box sx={{ marginLeft: 1.5, marginRight: 1.5, marginTop: 2 }}>
            {Object.keys(tableData).map((tableName) => {
              return (
                <Box key={tableName} sx={{ marginTop: '13px' }}>
                  <CustomTablePolicy
                    tabName={tableName}
                    fieldsMeta={tableData[tableName]['meta']['fieldsMeta']}
                    tableMeta={tableData[tableName]['meta']['tableMeta']}
                    rows={tableData[tableName]['rows']}
                    handleChangeCB={handleChange}
                    incrementTableRows={incrementTableRows}
                    deleteSelectedRow={deleteSelectedRow}
                  />
                </Box>
              );
            })}
          </Box>
        )}

        {/* Policy Governance Component */}
        {view && alignment === 'Governance' && (
          <Box style={{ marginTop: '10px' }}>
            <Box
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: '30px'
              }}
            >
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: 26,
                  marginTop: '10px',
                  marginLeft: '15px'
                }}
              >
                Controls
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Button
                  variant="contained"
                  fontSize="large"
                  sx={{ marginLeft: 1, marginRight: 0.5, height: 40 }}
                  onClick={() => {
                    handleOpenWorkflowHistoryModal();
                  }}
                >
                  Approval History
                </Button>
                <Button
                  sx={{ marginLeft: 1, marginRight: 1.1, height: 40 }}
                  variant="contained"
                  onClick={() => {
                    submitForSCFApprovalHandler();
                  }}
                  disabled={!isButtonUsable('submit', workflowHandlerStatus)}
                >
                  {getSubmitButtonText('submit', workflowHandlerStatus)}
                </Button>
                <Button
                  variant="contained"
                  onClick={handleOpenAddControlModal}
                  sx={{
                    color: 'white',
                    background: 'black',
                    marginLeft: 1,
                    marginRight: 0.5,
                    height: 40
                  }}
                >
                  Add Controls
                </Button>
                <Button
                  variant="contained"
                  onClick={handlePromptDialogue}
                  sx={{
                    color: 'white',
                    background: 'black',
                    height: 40,
                    marginLeft: 1,
                    marginRight: 0.5,
                    maxWidth: '200px'
                  }}
                >
                  Generate Prompt Responses
                </Button>
              </Box>
            </Box>
            <AllPolicyGovernance
              deleteCB={handleDeleteControls}
              reorderCB={handleReorderControls}
              governanceTableData={governanceTableData}
              handleChangeGovernance={handleChangeGovernance}
              getPolicyDetailsForGovernance={getPolicyDetailsForGovernance}
              tableName={'Governance-Table'}
              miscellaneous={generateSentenceFromKeyword}
              generateButtonStatus={generateButtonDisable}
              stickyColumnName={
                'Secure Controls Framework (SCF) – Control Description'
              }
            />
            {/* <TempAllPolicyGovernance
              deleteCB={handleDeleteControls2}
              reorderCB={handleReorderControls2}
              governanceTableData={governanceTableData}
              handleChangeGovernance={handleChangeGovernance2}
              getPolicyDetailsForGovernance={getPolicyDetailsForGovernance}
              tableName={'Governance-Table'}
              miscellaneous={generateSentenceFromKeyword}
              generateButtonStatus={generateButtonDisable} */}

            {lastApprovedGovernanceTableData !== null && (
              <React.Fragment>
                <Box
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <Typography
                    sx={{
                      fontWeight: 600,
                      fontSize: 26,
                      marginTop: '30px',
                      marginBottom: '5px',
                      marginLeft: '15px'
                    }}
                  >
                    Last Approved Controls - {formatCompletionDate()}
                  </Typography>
                </Box>
                <AllPolicyGovernance
                  governanceTableData={lastApprovedGovernanceTableData}
                  tableName={'Last-Approved-Governance-Table'}
                  generateButtonStatus={true}
                  stickyColumnName={
                    'Secure Controls Framework (SCF) – Control Description'
                  }
                />
              </React.Fragment>
            )}
          </Box>
        )}
      </Box>

      <WorkflowHistoryModal
        openWorkflowHistoryModal={openWorkflowHistoryModal}
        handleCloseWorkflowHistoryModal={handleCloseWorkflowHistoryModal}
        title={'Controls Approval History'}
        exctractAndCreateTableDataForGovernance={
          exctractAndCreateTableDataForGovernance
        }
      />

      <AddGovernancesModal
        openAddGovernancesModal={openAddGovernanceModal}
        handleCloseAddGovernancesModal={handleCloseAddGovernanceModal}
        getPolicyDetailsForGovernance={getPolicyDetailsForGovernance}
      />
      <PromptDialogue
        open={showPromptDialogue}
        onCloseCB={handleClosePromptDialogue}
        handlePayloadData={handlePayloadData}
      />
      <Modal
        open={openAddControlModal}
        onClose={handleCloseAddControlModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <CreateEditDialog
          formData={addControlForm}
          onCloseCB={handleCloseAddControlModal}
          refreshPageContentCB={getPolicyDetailsForGovernance}
          entityName="Control"
          customUpdateCB={handleAddCustomControl}
        />
      </Modal>
    </React.Fragment>
  );
};

export default FillEngagementPolicy;
