import React, { useState, useEffect } from 'react';
import { MasterHeader } from '../../../components/Master/Header';
import { MasterTable } from '../../../components/Master/Table';
import { headers } from '../../../constants/ClientHeaders';
import axios from 'axios';
import { CLIENT_URL } from '../../../constants/urls';
import { BACKEND_URL } from '../../../config';
import { useDataLayerValue } from '../../../contextAPI/DataLayer';
import { useNavigate } from 'react-router-dom';

const sampleFields = [
  {
    label: 'Name',
    name: 'name',
    display: 'required',
    type: 'text'
  },
  {
    label: 'PAN Number',
    name: 'PAN',
    display: 'required',
    type: 'text'
  },
  {
    label: 'Phone',
    name: 'phone',
    display: 'required',
    type: 'text'
  },
  {
    label: 'Email',
    name: 'email',
    display: 'required',
    type: 'text'
  },
  {
    label: 'Risk Type',
    name: 'riskType',
    display: 'required',
    type: 'text'
  },
  {
    label: 'Address',
    name: 'address',
    display: 'required',
    type: 'text'
  },
  {
    label: 'City',
    name: 'city',
    display: 'required',
    type: 'text'
  },
  {
    label: 'State',
    name: 'state',
    display: 'required',
    type: 'text'
  },
  {
    label: 'Zip/PIN',
    name: 'zip',
    display: 'required',
    type: 'text'
  },
  {
    label: 'Country',
    name: 'country',
    display: 'required',
    type: 'text'
  }
];

export const MasterClient = () => {
  const [data, setData] = useState({ body: [] });
  const [updated, setUpdated] = useState(false);
  const [user] = useDataLayerValue();
  const { _id } = user;
  const { DOWNLOAD, CREATE, BULK_UPLOAD, EDIT, VIEW_REPORT, DELETE } =
    user.rights.Client;
  const navigate = useNavigate();
  if (VIEW_REPORT === false) {
    navigate('/');
  }
  const toggleUpdate = () => {
    // console.log('Toggling');
    setUpdated((prevUpdated) => !prevUpdated);
  };

  const getData = async () => {
    const res = await axios.get(BACKEND_URL + '/clients', {
      headers: {
        employeeid: _id
      }
    });
    if (res.status !== 200) return;
    setData(res.data);
  };

  useEffect(() => {
    document.title = 'Clients';
    getData();
  }, [updated]);

  const findHeaders = (headers, data) => {
    if (data.body.length === 0) return [];
    const currHeaders = [];
    for (let i = 0; i < headers.length; i++) {
      if (headers[i].key in data.body[0]) currHeaders.push(headers[i]);
    }
    return currHeaders;
  };

  const currHeaders = findHeaders(headers, data);

  const csvReport = {
    data: data.body,
    headers: currHeaders,
    filename: 'Clients.csv'
  };

  return (
    <>
      <MasterHeader
        title="Clients"
        options={{
          importOption: BULK_UPLOAD,
          exportOption: DOWNLOAD,
          createOption: CREATE
        }}
        toggleUpdate={toggleUpdate}
        csvReport={csvReport}
        links={{
          importLink: BACKEND_URL + '/clients/upload',
          createLink: '/master/register/client'
        }}
      />
      <MasterTable
        headers={headers}
        data={data}
        toggleUpdate={toggleUpdate}
        csvReport={csvReport}
        url={CLIENT_URL}
        fields={sampleFields}
        options={{
          editOption: EDIT,
          deleteOption: DELETE,
          rightsOption: false
        }}
      />
    </>
  );
};
