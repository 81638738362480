import React from 'react';
import { TextField, Box, Modal, Button, Typography } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import Autocomplete from '@mui/material/Autocomplete';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  borderRadius: '10px',
  boxShadow: 24,
  p: 4,
  maxHeight: '100%',
  minWidth: '600px'
};

const AddIndustryModal = ({
  openDeleteModal,
  handleCloseDeleteModal,
  handleAddIndustry,
  handleAddSubIndustry,
  handleIndustryNameChange,
  handleSubIndustryNameChange,
  industries,
  subIndustryName,
  subIndustries
}) => {
  return (
    <Modal
      open={openDeleteModal}
      onClose={handleCloseDeleteModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: 2
          }}
        >
          <Typography variant="h5" component="h2">
            Add Industry
          </Typography>
        </Box>

        <Box
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
            marginBottom: '20px',
            alignItems: 'flex-start'
          }}
        >
          <FormControl style={{ width: '100%' }}>
            <Autocomplete
              id="free-solo-demo"
              freeSolo
              options={industries.map((industry) => industry.name)}
              onChange={(event, value) => {
                handleIndustryNameChange(value);
              }}
              onInputChange={(event) => {
                handleIndustryNameChange(event.target.value);
              }}
              renderInput={(params) => (
                <TextField {...params} label="Industry Name" />
              )}
            />
          </FormControl>
          <FormControl style={{ width: '100%' }}>
            <TextField
              label="Sub Industry Name"
              value={subIndustryName}
              onChange={handleSubIndustryNameChange}
            />

            {subIndustries.map((data) => {
              return <Box key={data}>{data}</Box>;
            })}
          </FormControl>

          <FormControl style={{ width: '100%' }}>
            <Button variant="contained" onClick={handleAddSubIndustry}>
              Add Sub-Industry
            </Button>
          </FormControl>
          <FormControl style={{ width: '100%' }}>
            <Button variant="contained" onClick={handleAddIndustry}>
              Add Industry
            </Button>
          </FormControl>
        </Box>
      </Box>
    </Modal>
  );
};

export default AddIndustryModal;
