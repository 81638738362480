import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';

const OVERLAY_STYLES = {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(250, 250, 250)',
  zIndex: 10000000000
};

export const AuthLoader = () => {
  return (
    <div style={OVERLAY_STYLES}>
      <CircularProgress
        sx={{ position: 'fixed', top: '50%', left: '50%' }}
        color="inherit"
      />
    </div>
  );
};
