import React from 'react';
import { NotificationsProvider } from './hooks/useNotifications';
import { LoadingProvider } from './hooks/useLoading';
import { Routers } from './routes';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { useDataLayerValue } from './contextAPI/DataLayer';
import { AuthLoader } from './components/AuthLoader';
import { BACKEND_URL } from './config';

// const rightsStringtoRightsObjMap = {
// 	Initiator: Initiator,
// 	Approver: Approver,
// 	Viewer: Viewer,
// 	Reviewer: Reviewer,
// };

function App() {
  const [checkAuth, setCheckAuth] = useState(false);
  const [{ _id }, dispatch] = useDataLayerValue();

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem('token');
      if (token !== null) {
        await axios
          .get(BACKEND_URL + '/credentials/employee/verify', {
            headers: {
              'x-auth-token': token
            }
          })
          .then((res) => {
            if (res.data.statusCode === 200) {
              // console.log(res.data.body);
              dispatch({
                type: 'SET_USER',
                details: res.data.body
              });
              dispatch({
                type: 'SET_LOGGED',
                loggedIn: true
              });
              //get corresponding profile rights here
              // setRights();
            }
          })
          .catch((err) => {
            console.log(err);
          });
        await axios
          .get(BACKEND_URL + '/credentials/employee/rights', {
            headers: {
              'x-auth-token': token,
              employeeid: _id
            }
          })
          .then((res) => {
            dispatch({
              type: 'SET_RIGHTS',
              rights: res.data.body
            });
          });
      }
      setCheckAuth(true);
    };
    fetchData();
  }, [dispatch]);

  return checkAuth === false ? (
    <AuthLoader />
  ) : (
    <NotificationsProvider>
      <LoadingProvider>
        <Routers />
      </LoadingProvider>
    </NotificationsProvider>
  );
}

export default App;
