import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
// import Typography from '@mui/material/Typography';
import TableRows from './TableRows';
import { fields } from '../../constants/WorkflowTableFields';
import { useDataLayerValue } from '../../contextAPI/DataLayer';

const TicketList = ({ tasks, onAction }) => {
  const [{ roleId, departmentId, role, department }] = useDataLayerValue();
  console.log(roleId, role, departmentId, department);
  return (
    <>
      {/* <Typography
        sx={{ m: 1, fontWeight: 'bold', mb: 4, align: 'center' }}
        variant="h4"
      >
        {title}
      </Typography> */}
      <TableContainer component={Paper}>
        <Table aria-label="a dense table">
          <TableHead>
            <TableRow>
              {fields.map((field, idx) => {
                return (
                  <TableCell
                    key={idx}
                    align="center"
                    sx={{
                      fontWeight: 'bold',
                      minWidth: '110px',
                      color: 'white',
                      backgroundColor: '#0081C9'
                    }}
                  >
                    {field.key}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {tasks.map((row, idx) => {
              return <TableRows key={idx} row={row} onAction={onAction} />;
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default TicketList;
