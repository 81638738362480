import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Typography } from '@mui/material';
import { MasterHeader } from '../../../components/Master/Header';
import { TimesheetMasterHeaders } from '../../../constants/TimesheetMasterHeaders';
import { useLoading } from '../../../hooks/useLoading';
import { getTimesheetsRequestByEmployee } from '../../../constants/requests';
import { useDataLayerValue } from '../../../contextAPI/DataLayer';
import { GET_TIMESHEET_URL } from '../../../constants/urls';
import { TimesheetMasterTable } from './TimesheetMasterTable';
import { ADMINISTRATOR_ROLE_ID } from '../../../constants/AdministratorConstants';

const timesheetEditFields = [
  {
    label: 'Hours Spent*',
    name: 'hoursSpent',
    display: 'required',
    type: 'Number'
  }
];

export const MasterTimesheet = () => {
  const { projectName, taskName } = useParams();
  const { request } = useLoading();
  const [user] = useDataLayerValue();
  const { _id, roleId } = user;
  const [data, setData] = useState({ body: [] });
  const [updated, setUpdated] = useState(false);

  let reqFilters = { projectName, taskName };
  if (roleId !== ADMINISTRATOR_ROLE_ID) {
    reqFilters['employeeId'] = _id;
  }

  const toggleUpdate = () => {
    // console.log('Toggling');
    setUpdated((prevUpdated) => !prevUpdated);
  };

  const getData = async () => {
    try {
      const response = await request(() =>
        getTimesheetsRequestByEmployee(reqFilters)
      );
      // console.log(response);
      if (response.status === 200) {
        // response.data.body.sort(
        //   (t1, t2) => new Date(t1.date).getTime() < new Date(t2.date).getTime()
        // );
        response.data.body = response.data.body
          .map((timesheet) => {
            return {
              ...timesheet,
              date: new Date(timesheet.date).toDateString()
            };
          })
          .sort(
            (timesheet1, timesheet2) =>
              new Date(timesheet2.date) - new Date(timesheet1.date)
          );
        // console.log(response.data.body);
        setData(response.data);
      } else {
        console.log('some error happened');
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    document.title = `${projectName}: ${taskName}`;
    getData();
  }, [updated]);

  return (
    <>
      <Typography sx={{ fontWeight: 'bold' }} variant="h4">
        {projectName}
      </Typography>
      <MasterHeader
        title={taskName}
        options={{
          importOption: false,
          exportOption: false,
          createOption: false
        }}
      />
      <TimesheetMasterTable
        headers={TimesheetMasterHeaders}
        data={data}
        toggleUpdate={toggleUpdate}
        url={GET_TIMESHEET_URL}
        fields={timesheetEditFields}
        options={{
          editOption: true,
          deleteOption: false,
          rightsOption: false
        }}
      />
    </>
  );
};
