import React, { useEffect, useState } from 'react';
import { Box, Typography, Button } from '@mui/material';
import { useLoading } from '../../../hooks/useLoading';
import { useNotifications } from '../../../hooks/useNotifications';
import {
  getisoPolicyContent,
  updateisoPolicyContent
} from '../../../constants/requests';
import GetTableCell from '../Components/GetTableCell';

const style = {
  width: '100%',
  bgcolor: 'white',
  borderRadius: '5px',
  maxHeight: '85vh',
  overflowY: 'auto',
  p: 4
};

const IsoPolicyContent = () => {
  const { request } = useLoading();
  const { showNotifications } = useNotifications();
  const [policyContent, setPolicyContent] = useState({
    iso_introduction: '',
    iso_objective: '',
    iso_responsibility: '',
    iso_scope: '',
    iso_exceptions: '',
    iso_violation: '',
    iso_review: '',
    iso_reference: ''
  });

  const getPolicyDetails = async () => {
    try {
      const response = await request(() => getisoPolicyContent());
      if (response?.data?.statusCode === 200) {
        const newPolicyContent = { ...policyContent };
        Object.keys(newPolicyContent).map((key) => {
          newPolicyContent[key] =
            response.data.body[key] === undefined
              ? ''
              : response.data.body[key];
        });
        setPolicyContent(newPolicyContent);
      } else {
        if (response.response.data.statusCode !== 200) {
          showNotifications('error', response.response.data.message, 5000);
        }
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  const updatePolicyDetails = async () => {
    try {
      const response = await request(() =>
        updateisoPolicyContent(policyContent)
      );
      if (response?.data?.statusCode === 200) {
        showNotifications('success', 'Save successful', 5000);
      } else {
        if (response.response.data.statusCode !== 200) {
          showNotifications('error', response.response.data.message, 5000);
        }
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  const policyContentChangeHandler = (rowNumber, heading, value) => {
    const newPolicyContent = { ...policyContent };
    newPolicyContent[heading] = value;
    setPolicyContent(newPolicyContent);
  };

  useEffect(() => {
    getPolicyDetails();
  }, []);

  return (
    <Box>
      <Box sx={style}>
        <Box>
          {Object.keys(policyContent).map((heading) => {
            return (
              <Box
                key={Math.random()}
                sx={{
                  marginTop: heading === 'iso_introduction' ? '0px' : '10px'
                }}
              >
                <Typography
                  variant="h6"
                  sx={{ fontWeight: '600', fontSize: '25px' }}
                  key={Math.random()}
                >
                  {heading.split('_')[1].charAt(0).toUpperCase() +
                    heading.split('_')[1].slice(1)}{' '}
                </Typography>
                <GetTableCell
                  key={Math.random()}
                  rowNumber={'1'}
                  fieldName={heading}
                  fieldType={'multiLineTextField'}
                  value={policyContent[heading]}
                  onChangeCB={policyContentChangeHandler}
                  isEditable={true}
                />
              </Box>
            );
          })}
        </Box>

        <Box
          style={{
            display: 'flex',
            justifyContent: 'end',
            marginTop: '2px'
          }}
        >
          <Button
            variant="contained"
            style={{ marginTop: '10px', backgroundColor: 'black' }}
            onClick={updatePolicyDetails}
          >
            Save
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default IsoPolicyContent;
