import React, { useEffect, useState } from 'react';
import { Box, Button } from '@mui/material';
import { useLoading } from '../../../hooks/useLoading';
import { useNotifications } from '../../../hooks/useNotifications';
import AddGenericContentModal from './AddGenericContentModal';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  tableCellClasses,
  Paper
} from '@mui/material';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import PreviewGenericPolicyModal from './PreviewGenericPolicyModal';
import {
  deleteGenericPolicyMaster,
  getAllGenericPolicyMaster
} from '../../../constants/requests';

// const style = {
//   width: '100%',
//   bgcolor: 'white',
//   borderRadius: '5px',
//   maxHeight: '85vh',
//   overflowY: 'auto',
//   p: 4
// };

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14
  }
}));

const StyledTableRow = styled(TableRow)(() => ({
  '&:last-child td, &:last-child th': {
    border: 0
  }
}));

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired
};

const GenericPolicyContent = () => {
  const { request } = useLoading();
  const { showNotifications } = useNotifications();

  const getPolicyDetails = async () => {
    try {
      const response = await request(() => getAllGenericPolicyMaster());
      if (response?.data?.statusCode === 200) {
        setType(response.data.body);
      } else {
        if (response.response.data.statusCode !== 200) {
          showNotifications('error', response.response.data.message, 5000);
        }
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  const handleDeleteGenericPolicyContent = async (id) => {
    try {
      const response = await request(() => deleteGenericPolicyMaster(id));
      console.log(response);
      if (response?.data?.statusCode === 200) {
        getPolicyDetails();
        showNotifications('success', response.data.message, 5000);
      } else {
        if (response.response.data.statusCode !== 200) {
          showNotifications('error', response.response.data.message, 5000);
        }
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  // const updatePolicyDetails = async () => {
  //   try {
  //     const response = await request(() =>
  //       updateGenericPolicyContent(policyContent)
  //     );
  //     if (response?.data?.statusCode === 200) {
  //       showNotifications('success', 'Save successful', 5000);
  //     } else {
  //       if (response.response.data.statusCode !== 200) {
  //         showNotifications('error', response.response.data.message, 5000);
  //       }
  //     }
  //   } catch (err) {
  //     showNotifications('error', err.toString(), 5000);
  //   }
  // };

  // const policyContentChangeHandler = (rowNumber, heading, value) => {
  //   const newPolicyContent = { ...policyContent };
  //   newPolicyContent[heading] = value;
  //   setPolicyContent(newPolicyContent);
  // };

  const [openAddGenericContentModal, setOpenAddGenericContentModal] =
    useState(false);
  const handleOpenAddGenericContentModal = () =>
    setOpenAddGenericContentModal(true);
  const handleCloseAddGenericContentModal = () =>
    setOpenAddGenericContentModal(false);

  const [openPreviewGenericPolicyModal, setOpenPreviewGenericPolicyModal] =
    useState(false);
  const handleOpenPreviewGenericPolicyModal = () =>
    setOpenPreviewGenericPolicyModal(true);
  const handleClosePreviewGenericPolicyModal = () =>
    setOpenPreviewGenericPolicyModal(false);

  const [selectPolicyId, setSelectedPolicyId] = useState('');
  const [openPreview, setOpenPreview] = useState(false);
  const [open, setOpen] = useState(false);

  const [types, setType] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - types.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    getPolicyDetails();
    setType;
  }, []);

  return (
    <React.Fragment>
      <Box>
        <Box
          style={{
            width: '100%',
            display: 'flex',
            gap: '10px',
            justifyContent: 'end',
            marginBottom: '20px',
            alignItems: 'flex-start'
          }}
        >
          <Button
            variant="contained"
            sx={{ backgroundColor: 'black' }}
            onClick={() => {
              setOpen(true);
              handleOpenAddGenericContentModal();
            }}
          >
            Add New Generic Policy Master
          </Button>
        </Box>

        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 500 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <TableCell
                  align="center"
                  sx={{
                    fontWeight: 'bold',
                    width: '50px',
                    color: 'white',
                    backgroundColor: 'black'
                  }}
                >
                  S.No
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    fontWeight: 'bold',
                    minWidth: '110px',
                    color: 'white',
                    backgroundColor: 'black'
                  }}
                >
                  Generic Policy Content Master Name
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    fontWeight: 'bold',
                    width: '50px',
                    color: 'white',
                    backgroundColor: 'black'
                  }}
                >
                  Actions
                </TableCell>
                {/* <StyledTableCell key={`s.no`} sx={{ width: '50px' }}>
                S.No
              </StyledTableCell>
              <StyledTableCell key={`s.no`}>Policy Type</StyledTableCell>
              <StyledTableCell align="center" sx={{ width: '20px' }}>
                Actions
              </StyledTableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {(rowsPerPage > 0
                ? types.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : types
              ).map((type, idx) => (
                <StyledTableRow key={type._id}>
                  <StyledTableCell align="center">
                    {page * rowsPerPage + idx + 1}
                  </StyledTableCell>
                  <StyledTableCell align="center">{type.name}</StyledTableCell>
                  <StyledTableCell align="center">
                    <Box sx={{ display: 'flex' }}>
                      <Button
                        variant="contained"
                        sx={{ marginRight: '20px' }}
                        onClick={() => {
                          setOpenPreview(true);
                          setSelectedPolicyId(type._id);
                          handleOpenPreviewGenericPolicyModal();
                        }}
                      >
                        Preview
                      </Button>
                      <Button
                        variant="contained"
                        sx={{ marginRight: '5px' }}
                        color="error"
                        onClick={() => {
                          handleDeleteGenericPolicyContent(type._id);
                        }}
                      >
                        Delete
                      </Button>
                    </Box>
                  </StyledTableCell>
                </StyledTableRow>
              ))}

              {emptyRows > 0 && (
                <StyledTableRow style={{ height: 53 * emptyRows }}>
                  <StyledTableCell colSpan={6} />
                </StyledTableRow>
              )}
            </TableBody>
            <TableFooter>
              <StyledTableRow
                sx={{ borderTop: '1px solid rgba(224, 224, 224, 1)' }}
              >
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                  colSpan={3}
                  count={types.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      'aria-label': 'rows per page'
                    },
                    native: true
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </StyledTableRow>
            </TableFooter>
          </Table>
        </TableContainer>

        {/* <Box>
            {Object.keys(policyContent).map((heading) => {
              return (
                <Box
                  key={Math.random()}
                  sx={{
                    marginTop:
                      heading === 'generic_introduction' ? '0px' : '10px'
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{ fontWeight: '600', fontSize: '25px' }}
                    key={Math.random()}
                  >
                    {heading.split('_')[1].charAt(0).toUpperCase() +
                      heading.split('_')[1].slice(1)}{' '}
                  </Typography>
                  <GetTableCell
                    key={Math.random()}
                    rowNumber={'1'}
                    fieldName={heading}
                    fieldType={'multiLineTextField'}
                    value={policyContent[heading]}
                    onChangeCB={policyContentChangeHandler}
                    isEditable={true}
                  />
                </Box>
              );
            })} */}
        {/* </Box> */}

        {/* <Box
            style={{
              display: 'flex',
              justifyContent: 'end',
              marginTop: '2px'
            }}
          >
            <Button
              variant="contained"
              style={{ marginTop: '10px', backgroundColor: 'black' }}
              // onClick={updatePolicyDetails}
            >
              Save
            </Button>
          </Box> */}
      </Box>

      <AddGenericContentModal
        openAddGenericContentModal={openAddGenericContentModal}
        handleCloseAddGenericContentModal={handleCloseAddGenericContentModal}
        getPolicyDetails={getPolicyDetails}
        open={open}
        setOpen={setOpen}
      />

      {/*Policy Preview Modal*/}
      <PreviewGenericPolicyModal
        openPreviewGenericPolicyModal={openPreviewGenericPolicyModal}
        handleClosePreviewGenericPolicyModal={
          handleClosePreviewGenericPolicyModal
        }
        openPreview={openPreview}
        setOpenPreview={setOpenPreview}
        selectPolicyId={selectPolicyId}
      />
    </React.Fragment>
  );
};

export default GenericPolicyContent;
