import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Button, Typography, Modal } from '@mui/material';
import {
  getRolesRequest,
  getAllDocuments,
  updateRCMRequest,
  downloadDocument,
  getRCMByIdRequest,
  getEngagementByIdRequest
} from '../../../constants/requests';
import { useLoading } from '../../../hooks/useLoading';
import { useDataLayerValue } from '../../../contextAPI/DataLayer';
import { useNotifications } from '../../../hooks/useNotifications';
import moment from 'moment';
import FileDownload from 'js-file-download';
import CustomTable from '../common/CustomTable';
import DeleteDialog from '../common/DeleteDialog';
import CreateEditDialog from '../common/CreateEditDialog';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const getFormattedDate = () => {
  return moment().format('D_MMM_YYYY_h_mm_ss_a');
};

const POF = () => {
  const navigate = useNavigate();
  const { request } = useLoading();
  const { showNotifications } = useNotifications();
  const [{ _id, roleId }] = useDataLayerValue();
  const { engagementId, applicationId, rcmId } = useParams();
  const [delIdx, setDelIdx] = useState(null);
  const [POFData, setPOFData] = useState(null);
  const [documentId, setDocumnetId] = useState(null);
  const [addRecordForm, setAddRecordForm] = useState([]);
  const [userType, setUserType] = useState('PROJECT_MEMBER');
  const [openAddRecordModal, setOpenAddRecordModal] = useState(false);
  const [openDeleteRecordModal, setOpenDeleteRecordModal] = useState(false);

  const handleOpenAddRecordModal = () => setOpenAddRecordModal(true);
  const handleCloseAddRecordModal = () => setOpenAddRecordModal(false);

  const handleOpenDeleteRecordModal = (idx) => {
    setDelIdx(idx);
    setOpenDeleteRecordModal(true);
  };
  const handleCloseDeleteRecordModal = () => {
    setDelIdx(null);
    setOpenDeleteRecordModal(false);
  };

  const getPOFDocument = async () => {
    try {
      const res = await request(() => getAllDocuments());
      if (res.data.statusCode === 200) {
        const requiredDocument = res.data.body.filter(
          (doc) => doc.documentName === 'POF'
        );
        setDocumnetId(requiredDocument[0]._id);
        showNotifications('success', res.data.message, 5000);
      } else {
        showNotifications('error', res.data.message, 5000);
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  const createAddRecordForm = (metaProperties) => {
    const { fields } = metaProperties;
    const recordForm = Object.keys(fields).map((key) => {
      return {
        type: fields[key]['fieldType'],
        fieldName: key,
        fieldLabel: `Enter ${key}`,
        value: '',
        required: false,
        dropDownOptions: fields[key]?.['options']
      };
    });
    setAddRecordForm(recordForm);
  };

  const handleChange = (rowNumber, fieldName, cellValue, tabName) => {
    const modifiedPOFData = { ...POFData };
    modifiedPOFData.rows[tabName][rowNumber][fieldName] = cellValue;
    setPOFData(modifiedPOFData);
  };

  const loadApplication = async () => {
    try {
      const response = await request(() =>
        getEngagementByIdRequest(engagementId)
      );
      if (response.data.statusCode !== 200) {
        showNotifications('error', 'No Engagements Found', 5000);
        return;
      }

      const roleRes = await request(() => getRolesRequest());
      let role = null;
      if (roleRes.data.statusCode === 200) {
        role = roleRes.data.body.filter((roles) => roles._id === roleId);
        if (role[0].name === 'Administrator') {
          setUserType('ADMIN');
        }
      }

      if (_id === response.data.body.aumyaaSPOCID1) {
        setUserType('SPOC');
      }
      const res = await request(() => getRCMByIdRequest(rcmId));
      if (res.data.statusCode === 200) {
        let controlList = [],
          controls = [];
        for (const rcmTable of Object.keys(res.data.body.data.rows)) {
          for (const rcm of res.data.body.data.rows[rcmTable]) {
            if (rcm['Control No.'] !== '') {
              const option = {
                label: rcm['Control No.'],
                value: rcm['Control No.']
              };
              controls.push(option);
              controlList.push(rcm['Control No.']);
            }
          }
        }

        res.data.body.POFdata.meta.fields['Link Controls']['options'] =
          controls;
        if (
          _id === response.data.body.aumyaaSPOCID1 ||
          role[0].name === 'Administrator'
        ) {
          res.data.body.POFdata.meta.PointOfFocus['Link Controls'][
            'isEditable'
          ] = true;
        }

        for (const table of Object.keys(res.data.body.POFdata.rows)) {
          for (const pof of res.data.body.POFdata.rows[table]) {
            pof['Link Controls'] = pof['Link Controls'].filter((el) =>
              controlList.includes(el)
            );
          }
        }

        setPOFData(res.data.body.POFdata);
        createAddRecordForm(res.data.body.POFdata.meta);
        showNotifications('success', res.data.message, 5000);
      } else {
        showNotifications('error', res.data.message, 5000);
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  const handleSave = async () => {
    try {
      const payload = {
        POFdata: POFData
      };
      let response = await request(() => updateRCMRequest(rcmId, payload));
      if (response.data.statusCode === 200) {
        showNotifications('success', response.data.message, 5000);
      } else {
        showNotifications('error', response.data.message, 5000);
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
      console.log(err);
    }
  };

  const handleDeleteRecord = async () => {
    try {
      const filteredData = POFData['rows']['PointOfFocus'].filter(
        (_, index) => index !== delIdx
      );
      const newPOFData = {
        meta: POFData.meta,
        rows: { ...POFData['rows'], ['PointOfFocus']: filteredData }
      };
      const payload = { POFdata: newPOFData };
      let response = await request(() => updateRCMRequest(rcmId, payload));
      if (response.data.statusCode === 200) {
        setPOFData(newPOFData);
        handleCloseDeleteRecordModal();
        showNotifications('success', response.data.message, 5000);
      } else {
        showNotifications('error', response.data.message, 5000);
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
      console.log(err);
    }
  };

  const handleAddRecord = async (newRecord) => {
    try {
      const newData = {};
      Object.keys(newRecord).map((record) => {
        newData[record] = newRecord[record]['value'];
      });
      const newPOFData = { ...POFData };
      newPOFData['rows']['PointOfFocus'].push(newData);
      const payload = {
        POFdata: newPOFData
      };
      let response = await request(() => updateRCMRequest(rcmId, payload));
      if (response.data.statusCode === 200) {
        setPOFData(newPOFData);
        handleCloseAddRecordModal();
        showNotifications('success', response.data.message, 5000);
      } else {
        showNotifications('error', response.data.message, 5000);
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
      console.log(err);
    }
  };

  const handleDownload = async () => {
    try {
      const payload = {
        engagementId: engagementId
      };
      const response = await request(() =>
        downloadDocument(documentId, payload)
      );
      if (response?.response?.data?.statusCode) {
        showNotifications('error', response.response.data.message, 5000);
      } else {
        FileDownload(
          response.data,
          `POF_${engagementId}_${getFormattedDate()}.xlsx`
        );
        showNotifications('success', 'Downloaded successfully', 5000);
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  useEffect(() => {
    loadApplication();
    getPOFDocument();
  }, []);

  return (
    <React.Fragment>
      <Box
        style={{
          marginBottom: '25px',
          display: 'flex',
          justifyContent: 'space-between'
        }}
      >
        <Button
          sx={{
            color: 'white',
            backgroundColor: 'black',
            paddingLeft: 1
          }}
          variant="contained"
          onClick={() => {
            navigate(
              `/services/engagement/${engagementId}/application/${applicationId}/rcm`
            );
          }}
        >
          <ChevronLeftIcon />
          RCM
        </Button>
        <Button
          sx={{
            color: 'white',
            backgroundColor: 'black',
            paddingLeft: 1
          }}
          variant="contained"
          onClick={() => {
            navigate(
              `/services/engagement/${engagementId}/application/${applicationId}/rcm/${rcmId}/section3`
            );
          }}
        >
          SECTION - 3
          <ChevronRightIcon />
        </Button>
      </Box>
      <Box
        style={{
          marginBottom: '25px',
          display: 'flex',
          justifyContent: 'space-between'
        }}
      >
        <Typography sx={{ fontWeight: 'bold' }} variant="h4">
          POF
        </Typography>
        <Box
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            gap: '10px'
          }}
        >
          <Button
            variant="contained"
            onClick={handleOpenAddRecordModal}
            disabled={POFData === null}
          >
            Add Record
          </Button>
          <Button
            sx={{
              color: 'white',
              backgroundColor: 'black'
            }}
            variant="contained"
            disabled={POFData === null}
            onClick={handleDownload}
          >
            Download
          </Button>
          <Button
            variant="contained"
            color="success"
            onClick={handleSave}
            disabled={userType === 'PROJECT_MEMBER'}
          >
            Save
          </Button>
        </Box>
      </Box>
      {POFData !== null && POFData.rows.PointOfFocus.length > 0 ? (
        <CustomTable
          tabName="PointOfFocus"
          handleChangeCB={handleChange}
          fieldsMeta={POFData.meta.fields}
          rows={POFData.rows.PointOfFocus}
          tableMeta={POFData.meta.PointOfFocus}
          deleteCB={
            userType !== 'PROJECT_MEMBER'
              ? handleOpenDeleteRecordModal
              : undefined
          }
        />
      ) : null}

      <Modal
        open={openAddRecordModal}
        onClose={handleCloseAddRecordModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <CreateEditDialog
          formData={addRecordForm}
          onCloseCB={handleCloseAddRecordModal}
          refreshPageContentCB={loadApplication}
          entityName="Record"
          customUpdateCB={handleAddRecord}
        />
      </Modal>

      <Modal
        open={openDeleteRecordModal}
        onClose={handleCloseDeleteRecordModal}
      >
        <DeleteDialog
          entityName={`Record ${delIdx + 1}`}
          customDeleteCB={handleDeleteRecord}
          onCloseCB={handleCloseDeleteRecordModal}
        />
      </Modal>
    </React.Fragment>
  );
};

export default POF;
