import * as React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

export default function PaymentForm({
  handleBack,
  handleNext,
  handleChange,
  clientDetails
}) {
  // Function to validate email format
  const isValidEmail = (email) => {
    const emailPattern = /^\S+@\S+\.\S+$/;
    return emailPattern.test(email);
  };

  // Function to validate phone number format
  const isValidPhoneNumber = (phoneNumber) => {
    const phoneNumberPattern = /^\d{3}-?\d{3}-?\d{4}$/;
    return phoneNumberPattern.test(phoneNumber);
  };

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        CEO & Project Information
      </Typography>
      <Box component="form" onSubmit={handleNext} sx={{ mt: 3 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <TextField
              required
              id="ceofName"
              name="ceofName"
              label="CEO First Name"
              fullWidth
              autoComplete="ceofName"
              variant="standard"
              onChange={handleChange}
              value={clientDetails.ceofName}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              required
              id="ceolName"
              name="ceolName"
              label="CEO Last Name"
              fullWidth
              autoComplete="ceolName"
              variant="standard"
              onChange={handleChange}
              value={clientDetails.ceolName}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              required
              id="ceoEmail"
              name="ceoEmail"
              label="CEO Email"
              fullWidth
              type="email"
              autoComplete="ceoEmail"
              variant="standard"
              onChange={handleChange}
              value={clientDetails.ceoEmail}
              InputProps={{
                onBlur: (event) => {
                  const email = event.target.value;

                  // Perform validation
                  if (!email) {
                    event.target.setCustomValidity('Email is required');
                  } else if (!isValidEmail(email)) {
                    event.target.setCustomValidity('Invalid email format');
                  } else {
                    event.target.setCustomValidity('');
                  }
                }
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              required
              id="ceoPhone"
              name="ceoPhone"
              label="CEO Phone"
              fullWidth
              autoComplete="ceoPhone"
              variant="standard"
              onChange={handleChange}
              value={clientDetails.ceoPhone}
              InputProps={{
                onBlur: (event) => {
                  const phoneNumber = event.target.value;

                  // Perform validation
                  if (!phoneNumber) {
                    event.target.setCustomValidity('Phone number is required');
                  } else if (!isValidPhoneNumber(phoneNumber)) {
                    event.target.setCustomValidity(
                      'Invalid phone number format'
                    );
                  } else {
                    event.target.setCustomValidity('');
                  }
                }
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button onClick={handleBack} sx={{ mt: 3, ml: 1 }}>
                Back
              </Button>

              <Button type="submit" variant="contained" sx={{ mt: 3, ml: 1 }}>
                {'Submit'}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  );
}
