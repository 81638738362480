import React, { useState } from 'react';
import { Box, Button, Typography, TextField } from '@mui/material';
import { uploadAssetRequest } from '../../../constants/requests';
import { modalStyle } from '../common/styles';
import { useLoading } from '../../../hooks/useLoading';
import { useNotifications } from '../../../hooks/useNotifications';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

export default function UploadDialog({
  name,
  assetData,
  onCloseCB,
  handleUploadSave,
  refreshPageContentCB
}) {
  const { request } = useLoading();
  const { showNotifications } = useNotifications();
  const [assetFile, setAssetFile] = useState(null);
  const [receivedAsset, setReceivedAsset] = useState(null);
  const [recievedFields, setReceivedFields] = useState({});
  const [displayFields, setDisplayFields] = useState({});

  const handleSubmit = async () => {
    if (!assetFile) {
      showNotifications('error', 'Upload a Asset File', 5000);
    } else {
      const form = new FormData();
      form.append('file', assetFile);
      try {
        console.log(form);
        const res = await request(() => uploadAssetRequest(form));
        console.log(res.data);
        if (res.data.statusCode === 200) {
          const assetRows = res.data.body.data.rows;
          console.log(assetRows);
          setReceivedAsset(assetRows);
          let fieldsDetected = {};
          let fieldsDisplay = {};
          Object.keys(assetRows).map((table) => {
            let originalFields = Object.keys(assetData.data['rows'][table][0]);
            let newFields = Object.keys(assetRows[table][0]);
            const commonFields = originalFields.filter(
              (x) => newFields.indexOf(x) !== -1
            );
            fieldsDetected[table] = commonFields;
            fieldsDisplay[table] = false;
          });
          setReceivedFields({
            ...recievedFields,
            ...fieldsDetected
          });
          setDisplayFields({
            ...displayFields,
            ...fieldsDisplay
          });
        } else {
          showNotifications('error', res.data.message, 5000);
        }
      } catch (error) {
        console.log(error);
        showNotifications('error', error.toString(), 5000);
      }
    }
  };

  const handleSave = async () => {
    if (receivedAsset) {
      let newRows = {};
      const rows = assetData.data.rows;
      Object.keys(receivedAsset).map((table) => {
        let tableRow = [];
        const fields = Object.keys(rows[table][0]);
        receivedAsset[table].map((row) => {
          let newObj = {};
          fields.map((field) => {
            if (row[field]) newObj[field] = row[field];
            else newObj[field] = '';
          });
          tableRow.push(newObj);
        });
        newRows[table] = tableRow;
      });

      const newAsset = {
        meta: assetData.data.meta,
        rows: newRows
      };

      handleUploadSave(newAsset);
      refreshPageContentCB();
      setReceivedAsset(null);
      setDisplayFields({});
      setReceivedFields({});
      onCloseCB();
    }
  };

  return (
    <React.Fragment>
      <Box sx={modalStyle}>
        <Typography variant="h5">{name}</Typography>
        <Box sx={{ display: 'flex', margin: '20px 0', gap: '10px' }}>
          <TextField
            fullWidth
            type="file"
            InputLabelProps={{ shrink: true }}
            onChange={(e) => setAssetFile(e.target.files[0])}
          >
            Select Asset Repository
          </TextField>
          <Button
            variant="contained"
            onClick={() => {
              handleSubmit();
            }}
            size="small"
          >
            Upload
          </Button>
        </Box>
        {
          <Box>
            {Object.keys(recievedFields).map((tableName) => {
              return (
                <Box key={tableName}>
                  <Box
                    key={tableName}
                    sx={{
                      display: 'flex',
                      fontSize: '20px',
                      fontWeight: 'bold',
                      cursor: 'pointer',
                      backgroundColor: 'rgba(240,240,240,1)',
                      marginTop: '5px',
                      alignItems: 'center',
                      padding: '10px'
                    }}
                    onClick={() => {
                      setDisplayFields({
                        ...displayFields,
                        [tableName]: !displayFields[tableName]
                      });
                    }}
                  >
                    {tableName}
                    <ArrowDropDownIcon />
                  </Box>
                  <Box
                    sx={
                      displayFields[tableName]
                        ? {
                            display: 'flex',
                            flexDirection: 'column'
                          }
                        : { display: 'none' }
                    }
                  >
                    {recievedFields[tableName].map((field) => {
                      return <Box key={field}>{field}</Box>;
                    })}
                  </Box>
                </Box>
              );
            })}
          </Box>
        }
        <Box
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginBottom: '30px',
            gap: '10px'
          }}
        >
          <Button
            variant="contained"
            color="success"
            disabled={assetFile === null}
            style={{ marginTop: '10px' }}
            onClick={() => {
              handleSave();
            }}
          >
            SET
          </Button>
        </Box>
      </Box>
    </React.Fragment>
  );
}
