import { BACKEND_URL, FLASK_HELPER_URL } from '../config';

export const GET_PROJECT_URL = `${BACKEND_URL}/projects`;
export const PROJECT_CREATION_URL = `${BACKEND_URL}/projects/add`;
export const TASK_CREATION_URL = (projectName) =>
  `${BACKEND_URL}/projects/name/${projectName}/tasks`;
export const ASSIGN_EMPLOYEE_PROJECT_URL = (projectName) =>
  `${BACKEND_URL}/projects/name/${projectName}/assignEmployee`;
export const DEASSIGN_EMPLOYEE_PROJECT_URL = (projectName) =>
  `${BACKEND_URL}/projects/name/${projectName}/deAssignEmployee`;
export const GET_TIMESHEET_URL = `${BACKEND_URL}/timesheets`;
export const EMPLOYEE_URL = `${BACKEND_URL}/employee`;
export const CLIENT_URL = `${BACKEND_URL}/clients`;
export const HOLIDAY_URL = `${BACKEND_URL}/holidays`;
export const GET_MONTH_HOLIDAYS_URL = (month, year) =>
  `${BACKEND_URL}/holidays/${year}/${month}`;
export const ADD_HOLIDAY_URL = (month, year) =>
  `${BACKEND_URL}/holidays/${year}/${month}/addDay`;
export const REMOVE_HOLIDAY_URL = (month, year) =>
  `${BACKEND_URL}/holidays/${year}/${month}/removeDay`;
export const RIGHTS_URL = `${BACKEND_URL}/rights`;
export const PROFILES_URL = `${BACKEND_URL}/profiles`;
export const ROLES_URL = `${BACKEND_URL}/roles`;
export const ENTITIES_URL = `${BACKEND_URL}/entities`;
export const DEPARTMENTS_URL = `${BACKEND_URL}/departments`;

// Services
export const ENGAGEMENTS_URL = `${BACKEND_URL}/itgc/engagement`;
export const UNDERSTANDING_URL = `${BACKEND_URL}/itgc/understanding`;
export const APPLICATION_URL = `${BACKEND_URL}/itgc/application`;
export const RCM_URL = `${BACKEND_URL}/itgc/rcm`;
export const DRL_TEMPLATE_URL = `${BACKEND_URL}/itgc/drl-template`;
export const DRL_URL = `${BACKEND_URL}/itgc/drl`;
export const DRL_LOGS_URL = `${BACKEND_URL}/itgc/drl-logs`;
export const WORKFLOW_HANDLER_URL = `${BACKEND_URL}/workflow-handler`;
export const ACTIVE_WORKFLOW_HANDLER_URL = `${BACKEND_URL}/active-workflow/workflow-handler`;
export const DRL_SAMPLES_URL = `${BACKEND_URL}/itgc/drl-document-samples`;
export const SERVICES_URL = `${BACKEND_URL}/itgc/service`;
export const FIELD_REFRENCE_URL = `${BACKEND_URL}/itgc/fieldreference`;
export const ORG_TABLE_URL = `${BACKEND_URL}/engagement/org-table`;
export const TRUST_SERVICE_CRITERIA_URL = `${BACKEND_URL}/engagement/trustServiceCriteria`;
export const TESTING_SHEET_CREATOR_URL = `${BACKEND_URL}/testingSheetCreator`;

// Template Editor
export const SERVICE_URL = `${BACKEND_URL}/itgc/service`;
export const TEMPLATE_URL = `${BACKEND_URL}/itgc/template`;
export const DOCUMENT_URL = `${BACKEND_URL}/itgc/document`;

// Document Manager
export const DOCUMENT_MANAGER_URL = `${BACKEND_URL}/documents`;
export const READ_MSG_FILE_URL = `${FLASK_HELPER_URL}/evidences/file`;
export const SEND_OFFER_LETTER = `${FLASK_HELPER_URL}/send-offer-letter`;

// Policies
export const POLICY_TEMPLATE_URL = `${BACKEND_URL}/policy-template`;
export const GET_ALL_TAGS_URL = `${BACKEND_URL}/policy-template/get-tags`;
export const POLICY_ENGAGEMENT_URL = `${BACKEND_URL}/policy-engagement`;
export const POLICY_URL = `${BACKEND_URL}/policy`;
export const POLICY_MASTER_URL = `${BACKEND_URL}/policy/master`;
export const GENERIC_POLICY_MASTER_URL = `${BACKEND_URL}/policy/master/genericPolicy`;
export const EMAIL_POLICY_MASTER_URL = `${BACKEND_URL}/policy/master/publishEmail`;
export const GAP_TRACKER_URL = `${BACKEND_URL}/gap-tracker`;
export const SCF_CONTROLS_URL = `${BACKEND_URL}/policy/scf/control`;

//iso assets
export const ASSET_ADD_URL = `${BACKEND_URL}/iso/asset/add`;
export const ASSET_GET_PROPERTY_URL = `${BACKEND_URL}/iso/asset`;
export const ASSET_EDIT_URL = `${BACKEND_URL}/iso/asset`;

export const RISK_ADD_URL = `${BACKEND_URL}/iso/risk/add`;
export const RISK_GET_PROPERTY_URL = `${BACKEND_URL}/iso/risk`;
export const RISK_EDIT_URL = `${BACKEND_URL}/iso/risk`;

export const GAP_ADD_URL = `${BACKEND_URL}/iso/gap/add`;
export const GAP_GET_PROPERTY_URL = `${BACKEND_URL}/iso/gap`;
export const GAP_EDIT_URL = `${BACKEND_URL}/iso/gap`;

export const THREAT_ADD_URL = `${BACKEND_URL}/iso/threat/add`;
export const THREAT_GET_PROPERTY_URL = `${BACKEND_URL}/iso/threat`;
export const THREAT_EDIT_URL = `${BACKEND_URL}/iso/threat`;

export const THREAT_VULNERABILITY_ADD_URL = `${BACKEND_URL}/iso/threatV/add`;
export const THREAT_VULNERABILITY_GET_PROPERTY_URL = `${BACKEND_URL}/iso/threatV`;
export const THREAT_VULNERABILITY_EDIT_URL = `${BACKEND_URL}/iso/threatV`;

export const GET_ALL_GUILDELINES_URL = `${BACKEND_URL}/iso/guideline`;
export const ADD_GUIDALINES_URL = `${BACKEND_URL}/iso/guideline/add`;
export const EDIT_GUILDALINES_URL = `${BACKEND_URL}/iso/guideline/`;

export const GET_LIBRARY = `${BACKEND_URL}/iso/library/`;
export const ADD_LIBRARY = `${BACKEND_URL}/iso/library/add`;
export const ADD_ALL_LIBRARY = `${BACKEND_URL}/iso/library/addAll`;
export const FINDALL_LIBRARY = `${BACKEND_URL}/iso/library/findAll`;
export const DELETE_LIBRARY = `${BACKEND_URL}/iso/library/delete`;
export const UPDATE_LIBRARY = `${BACKEND_URL}/iso/library/update`;
export const FINDBY_ID_LIBRARY = `${BACKEND_URL}/iso/library/findById`;

export const GET_THREAT = `${BACKEND_URL}/iso/threat`;
export const ADD_THREAT = `${BACKEND_URL}/iso/threat/add`;

//internal audit
export const GET_INTERNAL_AUDIT = `${BACKEND_URL}/iso/internalAudit`;
export const ADD_INTERNAL_AUDIT = `${BACKEND_URL}/iso/internalAudit/add`;

//department added
export const GET_DEPARTMENT = `${BACKEND_URL}/iso/department`;
export const ADD_DEPARTMENT = `${BACKEND_URL}/iso/department/add`;

//gap assessment added after seeing asset page
export const GAPISO_ADD_URL = `${BACKEND_URL}/iso/gapiso/add`;
export const GAPISO_GET_PROPERTY_URL = `${BACKEND_URL}/iso/gapiso`;

export const SCF_GOVERNANCE_URL = `${BACKEND_URL}/policy/scf/governance`;
export const OPEN_AI_URL = `${BACKEND_URL}/openai`;
export const PROMPT_URL = `${BACKEND_URL}/prompt`;

//pdf to excel
export const PDF_TO_EXCEL_URL = `${FLASK_HELPER_URL}/pdf-to-excel`;
export const SOC_PDF_DOWNLOAD = `${FLASK_HELPER_URL}/soc-report-generator`;
export const SOC_REPORT_SAVE_URL = `${BACKEND_URL}/itgc/soc-report-excel`;
