import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  tableCellClasses,
  Paper,
  Typography
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { styled } from '@mui/material/styles';
import FormControl from '@mui/material/FormControl';
import AddIndustryModal from '../Components/AddIndustryModal';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import {
  createIndustry,
  deleteIndustryById,
  deleteSubIndustryByName,
  getAllIndustry
} from '../../../constants/requests';
import { useLoading } from '../../../hooks/useLoading';
import { useNotifications } from '../../../hooks/useNotifications';
// import { industrySubIndustry } from '../../../constants/Industries';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14
  }
}));

const StyledTableRow = styled(TableRow)(() => ({
  '&:last-child td, &:last-child th': {
    border: 0
  }
}));

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired
};

const IndustryTable = () => {
  const { request } = useLoading();
  const { showNotifications } = useNotifications();

  const [industries, setIndustries] = useState([]);

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const handleOpenDeleteModal = () => setOpenDeleteModal(true);
  const handleCloseDeleteModal = () => setOpenDeleteModal(false);

  const [industryName, setIndustryName] = useState('');
  const [subIndustryName, setSubIndustryName] = useState('');
  const [subIndustries, setSubIndustries] = useState([]);

  const handleIndustryNameChange = (value) => {
    setIndustryName(value);
  };

  const handleSubIndustryNameChange = (event) => {
    setSubIndustryName(event.target.value);
  };

  const handleAddIndustry = async () => {
    if (industryName === '') {
      setSubIndustryName('');
      setSubIndustries([]);
      return;
    }
    if (subIndustries.length === 0) return;
    const newIndustry = {
      industry: industryName,
      subIndustry: subIndustries
    };
    try {
      const response = await request(() => createIndustry(newIndustry));
      if (response?.status === 201 || response?.status === 200) {
        getIndustryAndSubIndustry();
        setIndustryName('');
        setSubIndustries([]);
        showNotifications(
          'success',
          response.status === 201
            ? 'Successuful created industry'
            : 'Successfully updated industry',
          5000
        );
      } else {
        if (
          response.response.data.statusCode !== 201 ||
          response.response.data.statusCode !== 200
        ) {
          showNotifications('error', response.response.data.message, 5000);
        }
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  const handleAddSubIndustry = () => {
    if (subIndustryName === '') return;
    setSubIndustries([...subIndustries, subIndustryName.trim()]);
    setSubIndustryName('');
  };

  const handleDeleteIndustry = async (id) => {
    try {
      const response = await request(() => deleteIndustryById(id));
      if (response.data.statusCode === 200) {
        getIndustryAndSubIndustry();
        showNotifications('success', response.data.message, 5000);
      } else {
        showNotifications('error', response.data.message, 5000);
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  const handleDeleteSubIndustry = async (subIndustryToDelete, Id) => {
    try {
      const response = await request(() =>
        deleteSubIndustryByName(Id, subIndustryToDelete)
      );
      if (response.data.statusCode === 200) {
        getIndustryAndSubIndustry();
        showNotifications('success', response.data.message, 5000);
      } else {
        showNotifications('error', response.data.message, 5000);
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  const getIndustryAndSubIndustry = async () => {
    try {
      const response = await request(() => getAllIndustry());
      if (response?.data?.statusCode === 200) {
        const newIndustries = response.data.body.map((industry) => {
          return {
            id: industry._id,
            name: industry.industry,
            subIndustries: industry.subIndustry
          };
        });
        setIndustries(newIndustries);
      } else {
        if (response.response.data.statusCode !== 200) {
          showNotifications('error', response.response.data.message, 5000);
        }
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  // const seedDataInsertion = async () => {
  //   for (const industry of Object.keys(industrySubIndustry)) {
  //     const details = {
  //       industry: industry,
  //       subIndustry: industrySubIndustry[industry]
  //     };
  //     try {
  //       const response = await request(() => createIndustry(details));
  //       if (response.status === 201 || response.status === 200) {
  //         await getIndustryAndSubIndustry();
  //         setIndustryName('');
  //         setSubIndustries([]);
  //         showNotifications(
  //           'success',
  //           response.status === 201
  //             ? 'Successfully created industry'
  //             : 'Successfully updated industry',
  //           5000
  //         );
  //       } else {
  //         showNotifications('error', response.message, 5000);
  //       }
  //     } catch (err) {
  //       showNotifications('error', err.toString(), 5000);
  //     }
  //   }
  // };

  useEffect(() => {
    getIndustryAndSubIndustry();
    // seedDataInsertion();
  }, []);

  //All of the below states are for managing pagingation inside table
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - industries.length) : 0;
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <React.Fragment>
      <Box
        style={{
          width: '100%',
          display: 'flex',
          gap: '10px',
          justifyContent: 'end',
          marginBottom: '20px',
          alignItems: 'flex-start'
        }}
      >
        <FormControl>
          <Button
            variant="contained"
            sx={{ backgroundColor: 'black' }}
            onClick={handleOpenDeleteModal}
          >
            Add Industry
          </Button>
        </FormControl>
      </Box>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 500 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <TableCell
                align="center"
                sx={{
                  fontWeight: 'bold',
                  width: '50px',
                  color: 'white',
                  backgroundColor: 'black'
                }}
              >
                S.No
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  fontWeight: 'bold',

                  color: 'white',
                  backgroundColor: 'black'
                }}
              >
                Industry{' '}
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  fontWeight: 'bold',
                  color: 'white',
                  backgroundColor: 'black'
                }}
              >
                Sub-Industries
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  fontWeight: 'bold',
                  width: '50px',
                  color: 'white',
                  backgroundColor: 'black'
                }}
              >
                Actions
              </TableCell>
              {/* <StyledTableCell key={`s.no`} sx={{ width: '50px' }}>
                S.No
              </StyledTableCell>
              <StyledTableCell key={`industry`}>Industry</StyledTableCell>
              <StyledTableCell key={`sub-industry`}>
                Sub-Industries
              </StyledTableCell>
              <StyledTableCell
                align="center"
                sx={{ width: '20px' }}
                key={'actions'}
              >
                Actions
              </StyledTableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? industries.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
              : industries
            ).map((industry, idx) => (
              <StyledTableRow key={industry.id}>
                <StyledTableCell align="center">
                  {page * rowsPerPage + idx + 1}
                </StyledTableCell>
                <StyledTableCell align="left">
                  <Typography>{industry.name}</Typography>
                </StyledTableCell>
                <StyledTableCell align="center">
                  {industry.subIndustries.map((subIndustry, index) => (
                    <Box
                      key={index}
                      sx={{ display: 'flex', justifyContent: 'space-between' }}
                      align="left"
                    >
                      <Typography>{subIndustry}</Typography>
                      <DeleteIcon
                        variant="outlined"
                        size="small"
                        onClick={() =>
                          handleDeleteSubIndustry(subIndustry, industry.id)
                        }
                      />
                    </Box>
                  ))}
                </StyledTableCell>
                <StyledTableCell align="center">
                  <DeleteIcon
                    variant="outlined"
                    size="small"
                    onClick={() => handleDeleteIndustry(industry.id)}
                  />
                </StyledTableCell>
              </StyledTableRow>
            ))}

            {emptyRows > 0 && (
              <StyledTableRow style={{ height: 53 * emptyRows }}>
                <StyledTableCell colSpan={6} />
              </StyledTableRow>
            )}
          </TableBody>
          <TableFooter>
            <StyledTableRow
              sx={{ borderTop: '1px solid rgba(224, 224, 224, 1)' }}
            >
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                colSpan={3}
                count={industries.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    'aria-label': 'rows per page'
                  },
                  native: true
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </StyledTableRow>
          </TableFooter>
        </Table>
      </TableContainer>

      <AddIndustryModal
        openDeleteModal={openDeleteModal}
        handleCloseDeleteModal={handleCloseDeleteModal}
        handleAddIndustry={handleAddIndustry}
        handleAddSubIndustry={handleAddSubIndustry}
        handleIndustryNameChange={handleIndustryNameChange}
        handleSubIndustryNameChange={handleSubIndustryNameChange}
        industries={industries}
        subIndustryName={subIndustryName}
        subIndustries={subIndustries}
      />
    </React.Fragment>
  );
};

export default IndustryTable;
