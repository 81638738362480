import React, { useState } from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
// import FormControlLabel from "@mui/material/FormControlLabel";
// import Checkbox from "@mui/material/Checkbox";
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import axios from 'axios';
import { useNotifications } from '../../hooks/useNotifications';
import { useDataLayerValue } from '../../contextAPI/DataLayer';
import { useNavigate } from 'react-router-dom';
import { BACKEND_URL } from '../../config';
function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const theme = createTheme();

export default function SignUp() {
  const navigate = useNavigate();
  const [{ _id }] = useDataLayerValue();

  const { showNotifications } = useNotifications();

  const [fields, setFields] = useState({
    prevPassword: '',
    password: '',
    confirmPassword: ''
  });

  const [viewRules, setViewRules] = useState(false);

  let colour1 = 'red',
    colour2 = 'red',
    colour3 = 'red',
    colour4 = 'red',
    colour5 = 'red';

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFields((prev) => {
      return { ...prev, [name]: value };
    });
  };

  if (fields.password.length >= 8) {
    colour1 = 'green';
  }
  if (fields.password.match(/^(?=.*[A-Z]).*$/)) {
    colour2 = 'green';
  }
  if (fields.password.match(/^(?=.*[a-z]).*$/)) {
    colour3 = 'green';
  }
  if (fields.password.match(/^(?=.*[0-9]).*$/)) {
    colour4 = 'green';
  }
  if (
    fields.password.match(/^(?=.*[~`!@#$%^&*()--+={}[\]|\\:;"'<>,.?/_₹]).*$/)
  ) {
    colour5 = 'green';
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    if (fields.password !== fields.confirmPassword) {
      showNotifications('error', 'Passwords do not match', 2000);
      return;
    } else if (fields.password === fields.prevPassword) {
      showNotifications(
        'error',
        'New password cannot be same as old password',
        2000
      );
      return;
    } else if (
      colour1 === 'red' ||
      colour2 === 'red' ||
      colour3 === 'red' ||
      colour4 === 'red' ||
      colour5 === 'red'
    ) {
      showNotifications('error', 'Password must follow the rules', 2000);
    } else {
      console.log(fields);
      axios
        .post(
          BACKEND_URL + '/credentials/employee/update/' + _id,
          {
            prevPassword: fields.prevPassword,
            password: fields.password
          },
          {
            headers: {
              employeeid: _id
            }
          }
        )
        .then((res) => {
          console.log(res.data);
          if (res.data.statusCode === 200) {
            //check
            showNotifications('success', 'Password Updated Successfully', 2000);
            setTimeout(() => {
              navigate('/');
            }, 1000);
          } else showNotifications('error', res.data.message, 2000);
        })
        .catch((err) => showNotifications('error', err.data.message, 2000));
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <Typography component="h1" variant="h5">
            Update Password
          </Typography>
          <br />
          <div id="signInDiv"></div>
          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="prevPassword"
                  label="Previous Password"
                  type="password"
                  id="prevPassword"
                  onChange={handleChange}
                  autoComplete="new-password"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  onChange={handleChange}
                  autoComplete="new-password"
                  onFocus={() => {
                    setViewRules(true);
                  }}
                  onBlur={() => {
                    setViewRules(false);
                  }}
                />
              </Grid>
              {viewRules && (
                <Grid item xs={12}>
                  <p>
                    <i
                      style={{
                        color: colour1,
                        fontSize: '20px'
                      }}
                      className="fa fa-check-circle"
                      aria-hidden="true"
                    ></i>{' '}
                    At least 8 characters
                  </p>
                  <p>
                    <i
                      style={{
                        color: colour2,
                        fontSize: '20px'
                      }}
                      className="fa fa-check-circle"
                      aria-hidden="true"
                    ></i>{' '}
                    At least 1 uppercase letter
                  </p>
                  <p>
                    <i
                      style={{
                        color: colour3,
                        fontSize: '20px'
                      }}
                      className="fa fa-check-circle"
                      aria-hidden="true"
                    ></i>{' '}
                    At least 1 lowercase letter
                  </p>
                  <p>
                    <i
                      style={{
                        color: colour4,
                        fontSize: '20px'
                      }}
                      className="fa fa-check-circle"
                      aria-hidden="true"
                    ></i>{' '}
                    At least 1 number
                  </p>
                  <p>
                    <i
                      style={{
                        color: colour5,
                        fontSize: '20px'
                      }}
                      className="fa fa-check-circle"
                      aria-hidden="true"
                    ></i>{' '}
                    At least 1 special character
                  </p>
                  <p>
                    <i
                      style={{
                        color: 'green',
                        fontSize: '20px'
                      }}
                      className="fa fa-check-circle"
                      aria-hidden="true"
                    ></i>{' '}
                    Encrypted
                  </p>
                </Grid>
              )}
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="confirmPassword"
                  label="Confirm Password"
                  type="password"
                  id="confirmPassword"
                  autoComplete="new-password"
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                {fields.confirmPassword === '' ? (
                  ''
                ) : fields.password === fields.confirmPassword ? (
                  <p
                    style={{
                      color: 'green',
                      fontWeight: 'bold'
                    }}
                  >
                    {' '}
                    Passwords match{' '}
                  </p>
                ) : (
                  <p
                    style={{
                      color: 'red',
                      fontWeight: 'bold'
                    }}
                  >
                    {' '}
                    Passwords not match{' '}
                  </p>
                )}
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Update Password
            </Button>
          </Box>
        </Box>
        <Copyright sx={{ mt: 5 }} />
      </Container>
    </ThemeProvider>
  );
}
