import React, { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import PptxGenJS from 'pptxgenjs';
import { useNotifications } from '../../../hooks/useNotifications';
import { useLoading } from '../../../hooks/useLoading';
import { useParams, useNavigate } from 'react-router-dom';
import {
  getEngagementByIdRequest,
  getApplicationByIdRequest,
  getWorkflowHandlerByIdRequest,
  getRolesRequest
} from '../../../constants/requests';
import { useDataLayerValue } from '../../../contextAPI/DataLayer';
import './convert.css';

const TableComponent = ({
  headers,
  selectedHeaders,
  aitSheetData,
  selectedRows
}) => {
  return (
    <div style={{ overflowX: 'auto' }}>
      <table style={{ borderRadius: '10px', fontSize: '13px' }}>
        <thead>
          <tr className="heading-row">
            {headers?.map((item, index) => {
              if (selectedHeaders.includes(item)) {
                return (
                  <th className="heading" key={index}>
                    {item}
                  </th>
                );
              }
            })}
          </tr>
        </thead>
        <tbody>
          {aitSheetData[0] &&
            aitSheetData?.map((item, index) => {
              if (selectedRows.includes(index + 1)) {
                return (
                  <tr key={index} style={{ margin: '2px' }}>
                    {item.map((item1, index1) => {
                      if (selectedHeaders.includes(Object.keys(item1)[0])) {
                        return (
                          <td className="data" key={index1}>
                            {item1[Object.keys(item1)[0]]}
                          </td>
                        );
                      }
                    })}
                  </tr>
                );
              }
            })}
        </tbody>
      </table>
    </div>
  );
};
const getHeaders2 = (
  dataRows,
  setHeaders,
  setAITSheetData,
  setSelectedHeaders,
  setSelectedRows,
  setRows,
  showNotifications
) => {
  const data = [];
  const rows = [];
  let checkKeys = [];
  for (let j = 0; j < dataRows.length; j++) {
    const outputData = [];
    let keys = Object.keys(dataRows[j]);
    if (j == 0) {
      checkKeys = keys;
    } else {
      if (checkKeys != keys) {
        showNotifications('error', 'Data in not Consistent', 5000);
      }
    }
    setHeaders(keys);
    setSelectedHeaders(keys);
    for (let i = 0; i < keys.length; i++) {
      let tempObj = {};
      tempObj[keys[i]] = dataRows[j][keys[i]];
      outputData.push(tempObj);
    }
    data.push(outputData);
    rows.push(rows.length + 1);
  }
  setRows(rows);
  setSelectedRows(rows);
  setAITSheetData(data);
};

const ConvertPPT = () => {
  const navigate = useNavigate();
  const { engagementId } = useParams();
  const { request } = useLoading();
  const { showNotifications } = useNotifications();
  const [headers, setHeaders] = useState([]);
  const [selectedHeaders, setSelectedHeaders] = useState([]);
  const [rows, setRows] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [aitSheetData, setAITSheetData] = useState([]);
  const [userType, setUserType] = useState(null);
  const [{ roleId }] = useDataLayerValue();
  const [workflowHandlerStatus, setWorkflowHandlerStatus] = useState(null);
  const theme = useTheme();
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  let count = 0;

  const getRole = async () => {
    try {
      const res = await request(() => getRolesRequest());
      if (res.data.statusCode === 200) {
        const role = res.data.body.filter((roles) => roles._id === roleId);
        if (role[0].name === 'Administrator') setUserType('ADMIN');
      } else {
        showNotifications('error', res.data.message, 5000);
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  const getApplications = async () => {
    try {
      let response = await request(() =>
        getEngagementByIdRequest(engagementId)
      );
      if (response.data.body.workflowHandlerId !== null) {
        const workflowHandler = await request(() =>
          getWorkflowHandlerByIdRequest(response.data.body.workflowHandlerId)
        );
        setWorkflowHandlerStatus(workflowHandler.data.body.status);
      }
      if (response.data.statusCode === 200) {
        const application = response.data.body.applicationID;

        const applicationData = application.map((item) => {
          return {
            applicationId: item._id,
            rcmId: item.RCMId,
            applicationName: item.applicationName
          };
        });
        await getRCM(applicationData);
      } else {
        showNotifications('error', response.data.message, 5000);
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
      console.log(err);
    }
  };

  const getRCM = async (application) => {
    try {
      for (let i = 0; i < application.length; i++) {
        const response = await request(() =>
          getApplicationByIdRequest(application[i].applicationId)
        );
        if (response.data.statusCode === 200) {
          // eslint-disable-next-line no-prototype-builtins
          if (!response.data.body.hasOwnProperty('RCMId')) {
            continue;
          }
          if (response.data.body.RCMId === null) {
            continue;
          }
          const RCM = response.data.body.RCMId.data;
          const filtered_rows = RCM['rows']['Master Table'].filter(
            (row) =>
              row['Additional Conclusion'] === 'Exception noted' ||
              (row['Additional Conclusion'] === 'N/A' &&
                row['Result'] === 'Exception noted')
          );

          let RCMData = {};
          RCMData['applicationName'] = application[i].applicationName;
          RCMData['rcmID'] = application[i].rcmId;
          let indices = [];
          RCMData['rows'] = filtered_rows.map((row, idx) => {
            let obj = {};
            indices.push(row['S.No'] - 1);
            obj['Application Name'] = application[i].applicationName;
            obj['S.No'] = ++count;
            obj['ApplicationNo'] = i;
            obj['index'] = idx;
            // eslint-disable-next-line array-callback-return
            Object.keys(RCM.meta.AIT).map((key) => {
              obj[key] = row[key];
            });
            return obj;
          });

          getHeaders2(
            RCMData.rows,
            setHeaders,
            setAITSheetData,
            setSelectedHeaders,
            setSelectedRows,
            setRows,
            showNotifications
          );
          showNotifications('success', response.data.message, 5000);
        } else {
          showNotifications('error', response.data.message, 5000);
        }
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
      console.log(err);
    }
  };

  useEffect(() => {
    getApplications();
    getRole();
  }, [userType, workflowHandlerStatus]);
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250
      }
    }
  };

  const handleChange = (event) => {
    const {
      target: { value }
    } = event;
    setSelectedHeaders(typeof value === 'string' ? value.split(',') : value);
  };

  const handleChange2 = (event) => {
    const {
      target: { value }
    } = event;
    setSelectedRows(typeof value === 'string' ? value.split(',') : value);
  };

  const getStyles = (name, personName, theme) => {
    return {
      fontWeight:
        personName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium
    };
  };

  useEffect(() => {
    if (workflowHandlerStatus === 'Completed' || userType === 'ADMIN') {
      navigate(`/services/engagement/${engagementId}/AIT`);
    }
  }, []);

  const handleExportClick = async () => {
    const pptx = new PptxGenJS();
    const fileName = 'Audit_Comittee_Presentation.pptx';
    pptx.author = 'Aumyaa';
    pptx.tableToSlides('table_to_ppt', {
      x: 0,
      y: 0,
      slideMargin: 1,
      autoPageCharWeight: -1,
      addHeaderToEach: true,
      w: 10,
      addText: {
        text: 'Audit Comittee Presentation',
        options: { x: 1, y: 0.5, color: '176A67' }
      }
    });
    pptx.writeFile(fileName);
  };
  return (
    <div>
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <Button
          variant="contained"
          style={{ backgroundColor: 'black' }}
          onClick={() => navigate(`/services/engagement/${engagementId}/AIT`)}
        >
          <ChevronLeftIcon />
          AIT
        </Button>
        <Button
          onClick={handleExportClick}
          variant="contained"
          style={{ backgroundColor: 'black' }}
        >
          Convert to PPT & Download
        </Button>
      </div>
      {aitSheetData[0] && (
        <div>
          <h1 style={{ fontSize: '16px', marginTop: '4rem' }}>
            Please Deselect Unwanted Headers
          </h1>
          <FormControl sx={{ m: 1, width: 'full' }}>
            <Select
              multiple
              displayEmpty
              value={selectedHeaders}
              onChange={handleChange}
              input={<OutlinedInput />}
              renderValue={(selectedHeaders) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selectedHeaders.map((value) => (
                    <Chip key={value} label={value} />
                  ))}
                </Box>
              )}
              MenuProps={MenuProps}
            >
              <MenuItem disabled value="">
                <em>Please Select Columns</em>
              </MenuItem>
              {headers.map((name) => (
                <MenuItem
                  key={name}
                  value={name}
                  style={getStyles(name, selectedHeaders, theme)}
                >
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <br />
          <FormControl sx={{ m: 1, width: 'full' }}>
            <h1 style={{ fontSize: '16px', marginTop: '1rem' }}>
              Please Deselect Unwanted Rows
            </h1>
            <Select
              multiple
              value={selectedRows}
              onChange={handleChange2}
              input={<OutlinedInput />}
              renderValue={(selectedRows) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selectedRows.map((value) => (
                    <Chip key={value} label={value} />
                  ))}
                </Box>
              )}
              MenuProps={MenuProps}
            >
              {rows.map((row) => (
                <MenuItem
                  key={row}
                  value={row}
                  style={getStyles(row, selectedRows, theme)}
                >
                  {row}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <br />
          <div id="table_to_ppt">
            <TableComponent
              headers={headers}
              selectedHeaders={selectedHeaders}
              aitSheetData={aitSheetData}
              selectedRows={selectedRows}
            />
          </div>
          <br />
        </div>
      )}
    </div>
  );
};

export default ConvertPPT;
