import React from 'react';
import { Navigate } from 'react-router-dom';
import { useDataLayerValue } from '../contextAPI/DataLayer';

export default function PrivateRoute({ children }) {
  const [{ loggedIn }] = useDataLayerValue();
  if (!loggedIn) {
    return <Navigate to="/signin" />;
  }

  return children;
}
