import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Box,
  Modal,
  Button,
  InputBase,
  InputLabel,
  Typography,
  FormControl,
  NativeSelect
} from '@mui/material';
import {
  createControl,
  getAllControls,
  getControlById,
  getPolicyTemplateById,
  getControlByPolicyTemplateId,
  connectControlsToPolicyTemplate
} from '../../../constants/requests';
import { styled } from '@mui/material/styles';
import { useLoading } from '../../../hooks/useLoading';
import { useNotifications } from '../../../hooks/useNotifications';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import TemplateTable from '../../TemplateEditor/common/TemplateTable';
import CreateEditDialog from '../../Services/common/CreateEditDialog';

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(2)
  },
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)'
    }
  }
}));

const emptyList = {
  value: null,
  label: 'Select'
};

const addControlForm = [
  {
    type: 'textField',
    fieldName: 'SCF Domain',
    fieldLabel: 'Enter SCF Domain',
    value: '',
    required: true
  },
  {
    type: 'textField',
    fieldName: 'SCF Control',
    fieldLabel: 'Enter SCF Control',
    value: '',
    required: true
  },
  {
    type: 'textField',
    fieldName: 'SCF #',
    fieldLabel: 'Enter SCF #',
    value: '',
    required: true
  },
  {
    type: 'textField',
    fieldName: 'Secure Controls Framework (SCF) – Control Description',
    fieldLabel: 'Enter Secure Controls Framework (SCF)',
    value: '',
    required: true
  },
  {
    type: 'textField',
    fieldName: 'Methods To Comply With SCF Controls',
    fieldLabel: 'Enter Methods To Comply With SCF Controls',
    value: '',
    required: true
  },
  {
    type: 'textField',
    fieldName: 'Evidence Request List (ERL) #',
    fieldLabel: 'Enter Evidence Request List (ERL) #',
    value: '',
    required: true
  },
  {
    type: 'textField',
    fieldName: 'SCF Control Question',
    fieldLabel: 'Enter SCF Control Question',
    value: '',
    required: true
  },
  {
    type: 'textField',
    fieldName: 'SCF Control Answer',
    fieldLabel: 'Enter SCF Control Answer',
    value: '',
    required: true
  },
  {
    type: 'textField',
    fieldName: 'Function Grouping',
    fieldLabel: 'Enter Function Grouping',
    value: '',
    required: true
  }
];

const ConnectControlsToPolicyTemplate = () => {
  const navigate = useNavigate();
  const { request } = useLoading();
  const { policyTemplateId } = useParams();
  const { showNotifications } = useNotifications();
  const [controlId, setControlId] = useState(null);
  const [policyTemplateName, setPolicyTemplateName] = useState(null);
  const [controlData, setControlData] = useState([]);
  const [controlNames, setControlNames] = useState([]);
  const [openAddControlModal, setOpenAddControlModal] = useState(false);

  const handleOpenAddControlModal = () => setOpenAddControlModal(true);
  const handleCloseAddControlModal = () => setOpenAddControlModal(false);

  const getPolicyTemplate = async () => {
    try {
      const response = await request(() =>
        getPolicyTemplateById(policyTemplateId)
      );
      if (response?.data?.statusCode === 200) {
        setPolicyTemplateName(response.data.body.name);
        showNotifications('success', response.data.message, 5000);
      } else {
        if (response.response.data.statusCode !== 200) {
          showNotifications('error', response.response.data.message, 5000);
        }
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  const getControlsList = async () => {
    try {
      const response = await request(() => getAllControls());
      if (response?.data?.statusCode === 200) {
        const controlNameList = response.data.body.map((controlData) => {
          return {
            value: controlData._id,
            label: controlData['data']['SCF Control']
          };
        });
        setControlNames([emptyList, ...controlNameList]);
        showNotifications('success', response.data.message, 5000);
      } else {
        if (response.response.data.statusCode !== 200) {
          showNotifications('error', response.response.data.message, 5000);
        }
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  const getLinkedControls = async () => {
    try {
      const response = await request(() =>
        getControlByPolicyTemplateId(policyTemplateId)
      );
      if (response?.data?.statusCode === 200) {
        const controlDataList = response.data.body.controls.map(
          (controlData, idx) => {
            return {
              _id: controlData._id,
              'S.No': idx + 1,
              ...controlData['data'],
              Action: 'Delete'
            };
          }
        );
        setControlData(controlDataList);
        showNotifications('success', response.data.message, 5000);
      } else {
        if (response.response.data.statusCode !== 200) {
          showNotifications('error', response.response.data.message, 5000);
        }
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  const handleAddControl = async () => {
    try {
      const response = await request(() => getControlById(controlId));
      if (response?.data?.statusCode === 200) {
        const newControl = {
          _id: response.data.body._id,
          'S.No': controlData.length + 1,
          ...response.data.body.data,
          Action: 'Button'
        };
        setControlData((controlData) => [...controlData, newControl]);
        showNotifications('success', response.data.message, 5000);
      } else {
        if (response.response.data.statusCode !== 200) {
          showNotifications('error', response.response.data.message, 5000);
        }
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  const handleSave = async () => {
    try {
      const allControlIds = controlData.map((controls) => controls._id);
      const payload = {
        connect: allControlIds
      };
      const response = await request(() =>
        connectControlsToPolicyTemplate(policyTemplateId, payload)
      );
      if (response?.data?.statusCode === 200) {
        showNotifications('success', response.data.message, 5000);
      } else {
        if (response.response.data.statusCode !== 200) {
          showNotifications('error', response.response.data.message, 5000);
        }
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  const handleAddCustomControlToTable = async () => {
    try {
      const response = await request(() => getAllControls());
      if (response?.data?.statusCode === 200) {
        const len = response.data.body.length;
        const controlNameList = response.data.body.map((controlData) => {
          return {
            value: controlData._id,
            label: controlData['data']['SCF Control']
          };
        });
        const newControl = {
          _id: response.data.body[len - 1]._id,
          'S.No': controlData.length + 1,
          ...response.data.body[len - 1].data,
          Action: 'Button'
        };
        setControlNames(controlNameList);
        setControlData((controlData) => [...controlData, newControl]);
        showNotifications('success', response.data.message, 5000);
      } else {
        if (response.response.data.statusCode !== 200) {
          showNotifications('error', response.response.data.message, 5000);
        }
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  const handleAddCustomControl = async (newRecord) => {
    try {
      let config = {};
      Object.keys(newRecord).forEach((record) => {
        config[record] = newRecord[record].value;
      });
      const payload = {
        data: config
      };
      const response = await request(() => createControl(payload));
      if (response?.data?.statusCode === 201) {
        await handleAddCustomControlToTable();
        handleCloseAddControlModal();
        showNotifications('success', response.data.message, 5000);
      } else {
        if (response.response.data.statusCode !== 201) {
          showNotifications('error', response.response.data.message, 5000);
        }
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  const handleDeleteControls = async (idx) => {
    try {
      const newControlData = controlData.filter(
        (_, controlIndex) => idx !== controlIndex
      );
      const allControlIds = newControlData.map((controls) => controls._id);
      const payload = {
        connect: allControlIds
      };
      const response = await request(() =>
        connectControlsToPolicyTemplate(policyTemplateId, payload)
      );
      if (response?.data?.statusCode === 200) {
        setControlData(newControlData);
        showNotifications('success', response.data.message, 5000);
      } else {
        if (response.response.data.statusCode !== 200) {
          showNotifications('error', response.response.data.message, 5000);
        }
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  useEffect(() => {
    getControlsList();
    getPolicyTemplate();
    getLinkedControls();
  }, []);

  return (
    <React.Fragment>
      <Box
        style={{
          marginBottom: '25px',
          display: 'flex',
          justifyContent: 'space-between'
        }}
      >
        <Button
          sx={{
            color: 'white',
            backgroundColor: 'black'
          }}
          variant="contained"
          onClick={() => navigate('/policy')}
        >
          <ChevronLeftIcon />
          Policy Template
        </Button>
      </Box>
      <Box
        style={{
          marginBottom: '25px',
          display: 'flex',
          justifyContent: 'space-between'
        }}
      >
        <Typography sx={{ fontWeight: 'bold' }} variant="h4">
          Link Controls To Policy Template {`: ${policyTemplateName}`}
        </Typography>
      </Box>

      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          gap: '10px',
          marginBottom: '25px'
        }}
      >
        <FormControl fullWidth variant="standard">
          <InputLabel
            variant="standard"
            shrink={true}
            htmlFor="uncontrolled-native"
          >
            Select Controls
          </InputLabel>
          <NativeSelect
            value={controlId}
            onChange={(e) => setControlId(e.target.value)}
            input={<BootstrapInput />}
            inputProps={{
              name: 'controlId',
              id: 'uncontrolled-native',
              shrink: true
            }}
          >
            {controlNames.length > 0 &&
              controlNames.map((control) => (
                <option key={control.value} value={control.value}>
                  {control.label}
                </option>
              ))}
          </NativeSelect>
        </FormControl>
        <Button
          variant="contained"
          sx={{
            marginTop: 2,
            marginRight: 2,
            color: 'white',
            background: 'black'
          }}
          onClick={handleAddControl}
        >
          Add
        </Button>
      </Box>
      {controlData.length > 0 ? (
        <Box>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              margin: '40px 0px 10px'
            }}
          >
            <Typography
              sx={{ fontWeight: 'bold', marginRight: '30%' }}
              variant="h5"
            >
              Controls
            </Typography>
            <Box
              style={{
                display: 'flex',
                justifyContent: 'flexEnd',
                gap: '10px'
              }}
            >
              <Button
                variant="contained"
                sx={{
                  color: 'white',
                  background: 'black'
                }}
                onClick={handleOpenAddControlModal}
              >
                Add Custom Controls
              </Button>
              <Button
                variant="contained"
                sx={{ marginRight: 2 }}
                onClick={handleSave}
              >
                Save
              </Button>
            </Box>
          </Box>
          <TemplateTable
            data={controlData}
            deleteCB={handleDeleteControls}
            widthStyle={{ minWidth: '200px' }}
          />
        </Box>
      ) : (
        <Box style={{ display: 'flex', justifyContent: 'center' }}>
          <Typography sx={{ fontWeight: 'bold', color: 'Red' }} variant="h5">
            No Controls Linked To This Policy Template
          </Typography>
        </Box>
      )}

      <Modal
        open={openAddControlModal}
        onClose={handleCloseAddControlModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <CreateEditDialog
          formData={addControlForm}
          onCloseCB={handleCloseAddControlModal}
          refreshPageContentCB={getLinkedControls}
          entityName="Control"
          customUpdateCB={handleAddCustomControl}
        />
      </Modal>
    </React.Fragment>
  );
};

export default ConnectControlsToPolicyTemplate;
