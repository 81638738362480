import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Modal,
  TextField,
  Typography,
  MenuItem
} from '@mui/material';
import {
  getDRLById,
  getSamplesByIdRequest,
  getSamplesByDRL,
  createSamples,
  updateSamples,
  downloadDRLRequest
} from '../../../constants/requests';
import { useLoading } from '../../../hooks/useLoading';
import { debounce } from 'lodash';
import { useNotifications } from '../../../hooks/useNotifications';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import ModalTable from '../common/ModalTable';
import ModalNotFound from '../common/ModalNotFound';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import FileDownload from 'js-file-download';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  marginBottom: '10px',
  bgcolor: 'background.paper',
  borderRadius: '10px',
  boxShadow: 24,
  p: 4,
  overflow: 'scroll',
  maxHeight: '100%',
  minWidth: '600px'
};

const Sampling = () => {
  const navigate = useNavigate();
  const { request } = useLoading();
  const { showNotifications } = useNotifications();
  const { engagementId, applicationId, drlId } = useParams();
  const [saveData, setSaveData] = useState({});
  const [allSamples, setAllSamples] = useState({});
  const [master, setMaster] = useState([]);
  const [drl, setDrl] = useState({});
  const [sampleCount, setSampleCount] = useState(0);
  const [fileType, setFileType] = useState('E');
  const [drlDocId, setDRLDocId] = useState(null);
  const [currentTabName, setCurrentTabName] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [openSampling, setOpenSampling] = useState(false);

  const handleSampleOpen = () => setOpenSampling(true);

  const fileTypeOptions = [
    {
      value: 'E',
      label: 'Change Management'
    },
    {
      value: 'U',
      label: 'User'
    }
  ];

  const handleFileSelect = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const debounceCount = (value) => {
    const debounced = debounce(() => {
      setSampleCount(value);
    }, 25);

    debounced();
  };

  const saveSamples = (data) => {
    setSaveData(data);
  };

  const handleSampleClose = () => {
    setOpenSampling(false);
    setDRLDocId(null);
  };

  const createMaster = (docId, count) => {
    const row = {};
    row['Sample'] = `Sample-${drl[docId]['key']}`;
    row['Required Evidence'] = drl[docId]['label'];
    row['Sample Count'] = count;
    setMaster((master) => [...master, row]);
  };

  const getInfo = async () => {
    try {
      if (drlId) {
        const res = await request(() => getDRLById(drlId));
        if (res.data.statusCode === 200) {
          const row = {};
          res.data.body.documents.map((item) => {
            return (row[item['_id']] = {
              label: item['requiredEvidence'],
              key: item['SNo']
            });
          });
          setDrl({ ...drl, ...row });
          showNotifications('success', res.data.message, 5000);
        } else {
          showNotifications('error', res.data.message, 5000);
        }
      } else {
        showNotifications('error', 'No DRL Found', 5000);
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  const getSamples = async (docId) => {
    try {
      const res = await request(() => getSamplesByIdRequest(docId));
      if (res?.response?.data?.statusCode === 404) {
        showNotifications('error', res.response.data.message, 5000);
      } else if (res.data.statusCode === 200) {
        const rows = res.data.body[0].data;
        const sId = res.data.body[0]._id;
        const row = {};
        row[sId] = {
          rows: rows,
          key: drl[docId]['key']
        };
        if (row[sId]['rows'].length > 0) {
          createMaster(docId, row[sId]['rows'].length);
        } else {
          createMaster(docId, 0);
        }
        setAllSamples((allSamples) => ({ ...allSamples, ...row }));
        // keys of all samples is sample id
        showNotifications('success', res.data.message, 5000);
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  const getAllSamples = async () => {
    try {
      const res = await request(() => getSamplesByDRL(drlId));
      if (res?.response?.data?.statusCode === 404) {
        showNotifications('error', res.response.data.message, 5000);
      } else if (res.data.statusCode === 200) {
        const allSamples = res.data.body;
        allSamples.map((samples) => {
          const sId = samples._id;
          const docId = samples.DRLDocumentId;
          const row = {};
          row[sId] = {
            rows: samples.data,
            key: drl[docId]['key']
          };
          if (samples.data !== undefined && row[sId]['rows'].length > 0) {
            createMaster(docId, row[sId]['rows'].length);
          } else {
            createMaster(docId, 0);
          }
          setAllSamples((allSamples) => ({ ...allSamples, ...row }));
        });
        showNotifications('success', res.data.message, 5000);
      }
    } finally {
      showNotifications('success', 'Done', 5000);
    }
  };

  const handleCreate = async () => {
    const formData = new FormData();
    formData.append('DRLDocumentId', drlDocId);
    formData.append('population', selectedFile);
    formData.append('sampleCount', sampleCount);
    formData.append('fileType', fileType);
    try {
      const res = await request(() => createSamples(formData));
      if (
        res?.response?.data?.statusCode === 422 ||
        res?.response?.data?.statusCode === 500
      ) {
        showNotifications('error', res.response.data.message, 5000);
      } else if (res.data.statusCode === 201) {
        showNotifications('success', res.data.message, 5000);
        await getSamples(drlDocId);
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
    handleSampleClose();
  };

  const handleUpdateSample = async (id) => {
    try {
      const payload = {
        data: saveData
      };
      const res = await request(() => updateSamples(id, payload));
      if (res.data.statusCode === 200) {
        showNotifications('success', res.data.message, 5000);
      } else {
        showNotifications('error', res.data.message, 5000);
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  const handleDownload = async () => {
    try {
      const res = await request(() =>
        downloadDRLRequest(engagementId, applicationId)
      );
      if (res?.response?.data?.statusCode) {
        showNotifications('error', res.response.data.message, 5000);
      } else {
        FileDownload(res.data, `DRL_${applicationId}.xlsx`);
        showNotifications('success', 'Downloaded successfully', 5000);
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  useEffect(() => {
    getInfo();
  }, []);

  useEffect(() => {
    getAllSamples();
  }, [drl]);

  return (
    <>
      <Box
        style={{
          marginBottom: '25px',
          display: 'flex',
          justifyContent: 'space-between'
        }}
      >
        <Button
          sx={{
            color: 'white',
            backgroundColor: 'black'
          }}
          variant="contained"
          onClick={() =>
            navigate(
              `/services/engagement/${engagementId}/application/${applicationId}/drl`
            )
          }
        >
          <ChevronLeftIcon />
          DRL
        </Button>
        <Button
          sx={{ color: 'white', backgroundColor: 'black' }}
          variant="contained"
          onClick={() =>
            navigate(
              `/services/engagement/${engagementId}/application/${applicationId}/testing`
            )
          }
        >
          Testing
          <ChevronRightIcon />
        </Button>
      </Box>
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '30px'
        }}
      >
        <Typography sx={{ fontWeight: 'bold' }} variant="h4">
          Sampling
        </Typography>
        <Box style={{ display: 'flex', gap: '5px' }}>
          <Button
            sx={{ color: 'white', backgroundColor: 'black' }}
            variant="contained"
            disabled={drlId === null}
            onClick={handleSampleOpen}
          >
            Add Samples
          </Button>
          <TextField
            select
            label="Select Sample No."
            value={currentTabName}
            onChange={(e) => setCurrentTabName(e.target.value)}
            style={{ minWidth: '200px' }}
            size="small"
          >
            {allSamples &&
              Object.keys(allSamples)?.map((option) => (
                <MenuItem key={option} value={option}>
                  {`Sample-${allSamples[option]['key']}`}
                </MenuItem>
              ))}
          </TextField>
          <Button
            sx={{ color: 'white', backgroundColor: 'black' }}
            variant="contained"
            disabled={drlId === null}
            onClick={handleDownload}
          >
            Download DRL
          </Button>
        </Box>
      </Box>
      {master.length > 0 ? (
        <ModalTable data={master} />
      ) : (
        <ModalNotFound data="Samples" />
      )}
      <TabContext value={currentTabName}>
        {allSamples
          ? Object.keys(allSamples).map((id) => {
              return (
                <TabPanel
                  key={`Sampling Table: ${id}`}
                  value={id}
                  index={id}
                  sx={{ p: '0px' }}
                >
                  <Box
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      margin: '30px 0'
                    }}
                  >
                    <Typography variant="h5">{`Sample-${allSamples[id]['key']}`}</Typography>
                    <Box
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        gap: '10px'
                      }}
                    >
                      <Button
                        variant="contained"
                        onClick={() =>
                          navigate(
                            `/services/engagement/${engagementId}/application/${applicationId}/evidence/${id}/`
                          )
                        }
                      >
                        Upload Evidence
                      </Button>
                      <Button
                        variant="contained"
                        color="success"
                        onClick={() => handleUpdateSample(id)}
                      >
                        Save
                      </Button>
                    </Box>
                  </Box>
                  {allSamples[id]['rows'] ? (
                    <ModalTable
                      data={allSamples[id]['rows']}
                      save={saveSamples}
                    />
                  ) : (
                    <ModalNotFound data="Samples For This Evidence" />
                  )}
                </TabPanel>
              );
            })
          : null}
      </TabContext>

      <Modal
        open={openSampling}
        onClose={handleSampleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={style}
          style={{ width: '50%', height: '80%', overflowX: 'scroll' }}
        >
          <Box
            style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}
          >
            <Typography
              fullWidth
              id="modal-modal-title"
              variant="h5"
              component="h2"
              style={{ marginBottom: '30px' }}
            >
              Generate Samples
            </Typography>
            <TextField
              select
              label="Select Evidence"
              name="Evidence"
              onChange={(e) => setDRLDocId(e.target.value)}
              InputLabelProps={{ shrink: true }}
            >
              {Object.keys(drl).map((options) => (
                <MenuItem key={options} value={options}>
                  {`${drl[options]['key']}.  ${drl[options]['label']}`}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              label="Upload Samples File"
              name="population"
              type="file"
              onChange={handleFileSelect}
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              label="Enter Sample Count"
              name="sampleCount"
              onChange={(e) => debounceCount(e.target.value)}
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              select
              label="Select File Type"
              name="fileType"
              value={fileType}
              onChange={(e) => setFileType(e.target.value)}
              InputLabelProps={{ shrink: true }}
            >
              {fileTypeOptions.map((fileTypeOptions) => (
                <MenuItem
                  key={fileTypeOptions.value}
                  value={fileTypeOptions.value}
                >
                  {fileTypeOptions.label}
                </MenuItem>
              ))}
            </TextField>
            <Box
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: '30px'
              }}
            >
              <Button
                color="error"
                variant="contained"
                onClick={handleSampleClose}
              >
                Close
              </Button>
              <Button variant="contained" onClick={handleCreate}>
                Create
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default Sampling;
