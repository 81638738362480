import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/material';
import {
  getApplicationByIdRequest,
  getEngagementByIdRequest,
  updateRCMRequest
} from '../../../constants/requests';
import { useLoading } from '../../../hooks/useLoading';
import { useNotifications } from '../../../hooks/useNotifications';
import MergingIdentifiers from '../common/MergingIdentifiers';
import CustomTable from '../common/CustomTable';
import Navbar from '../common/Navbar';

const MLP = () => {
  const { request } = useLoading();
  const { showNotifications } = useNotifications();
  const { engagementId } = useParams();
  const [bodyMeta, setBodyMeta] = useState({});
  const [applicationData, setApplicationData] = useState([]);
  const [rowForm, setRowForm] = useState([]);
  const [nullIdx, setNullIdx] = useState([]);
  const [identifier, setIdentifier] = useState([]);
  const [table, setTable] = useState([]);
  const [meta, setMeta] = useState({
    SNo: {
      isEditable: false,
      displayName: 'S.No'
    },
    applicationName: {
      isEditable: false,
      displayName: 'Application Name'
    }
  });
  const [fields, setFields] = useState({
    SNo: {
      fieldType: 'textField'
    },
    applicationName: {
      fieldType: 'textField'
    }
  });

  let serialNumber = 0;

  const saveIdentifiers = (tags) => {
    setIdentifier(tags);
  };

  const handleRowChange = (idx, name, cellValue) => {
    let newData = Object.assign(table);
    let rcmData = Object.assign(rowForm);
    newData[idx][name] = cellValue;
    const applicationNo = newData[idx]['cnt'];
    const tabName = newData[idx]['table'];
    const tabIdx = newData[idx]['idx'];
    rcmData[applicationNo][tabName][tabIdx] = newData[idx];
    setRowForm(rcmData);
  };

  const checkCondition = (obj) => {
    if (
      obj['Design  Effectiveness'] !== undefined &&
      obj['Operating Effectiveness'] !== undefined
    ) {
      return (
        obj['Design  Effectiveness'] === 'Ineffective' ||
        obj['Operating Effectiveness'] === 'Ineffective'
      );
    } else if (
      obj['Overall Conclusion (Effective / Ineffective)'] !== undefined
    ) {
      return (
        obj['Overall Conclusion (Effective / Ineffective)'] === 'Ineffective'
      );
    } else if (obj['Conclusion (Effective / Ineffective)'] !== undefined) {
      return obj['Conclusion (Effective / Ineffective)'] === 'Ineffective';
    } else if (obj['Conclusion'] !== undefined) {
      return obj['Conclusion'] === 'Ineffective';
    } else {
      return (
        obj['Additional Conclusion'] === 'Exception noted' ||
        (obj['Additional Conclusion'] === 'N/A' &&
          obj['Result'] === 'Exception noted')
      );
    }
  };

  /*
  
  [
     {               -> 1st application
       LS: [{}],     -> here [{}] are rows.
       CM: [{}],
     },
     {               -> 2nd application
       LS: [{}],
       CM: [{}]
     }
   ]
  
   */

  const reshapeData = (row, cnt) => {
    for (const key in row) {
      for (const idx in row[key]) {
        if (checkCondition(row[key][idx])) {
          setTable((table) => [
            ...table,
            {
              ...row[key][idx],
              SNo: ++serialNumber,
              cnt: cnt,
              table: key,
              idx: idx
            }
          ]);
        }
      }
    }
  };

  const getRCM = async (application) => {
    try {
      let call = 0,
        rowNum = 0;
      for (let i = 0; i < application.length; i++) {
        const res = await request(() =>
          getApplicationByIdRequest(application[i].applicationId)
        );
        if (res.data.statusCode === 200) {
          if (
            !Object.prototype.hasOwnProperty.call(res.data.body, 'RCMId') ||
            res.data.body.RCMId === null
          ) {
            setNullIdx((nullIdx) => [...nullIdx, i]);
            continue;
          }
          const result = res.data.body.RCMId.data;
          if (call === 0) {
            const metaData = result.meta.MLP;
            const field = result.meta.fields;
            const mergers = result.meta.RowsToMerge;
            setIdentifier(mergers);
            setMeta((meta) => ({
              ...meta,
              ...metaData
            }));
            setFields((fields) => ({
              ...fields,
              ...field
            }));
            setBodyMeta(result.meta);
            call += 1;
          }
          const rows = {};
          Object.keys(result.rows).map(
            (k) =>
              (rows[k] = result.rows[k].map((row) => {
                return {
                  ...row,
                  applicationName: application[i].applicationName
                };
              }))
          );
          reshapeData(rows, rowNum++);
          setRowForm((rowForm) => [...rowForm, rows]);
          showNotifications('success', res.data.message, 5000);
        } else {
          showNotifications('error', res.data.message, 5000);
        }
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  const getMLPApplication = async () => {
    try {
      const res = await request(() => getEngagementByIdRequest(engagementId));
      if (res.data.statusCode === 200) {
        const application = res.data.body.applicationID.map((item) => {
          return {
            applicationId: item._id,
            rcmId: item.RCMId,
            applicationName: item.applicationName
          };
        });
        setApplicationData(application);
        showNotifications('success', res.data.message, 5000);
        await getRCM(application);
      } else {
        showNotifications('error', res.data.message, 5000);
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  const handleSave = async () => {
    try {
      const application = applicationData.filter(
        (data, idx) => !nullIdx.includes(idx)
      );
      setApplicationData(application);
      for (let i = 0; i < application.length; i++) {
        const filterTags = identifier.filter((val) => val !== '');
        const payload = {
          data: {
            meta: { ...bodyMeta, RowsToMerge: filterTags },
            rows: rowForm[i]
          }
        };
        const res = await request(() =>
          updateRCMRequest(application[i].rcmId, payload)
        );
        if (res.data.statusCode === 200) {
          showNotifications('success', res.data.message, 5000);
        } else {
          showNotifications('error', res.data.message, 5000);
        }
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  useEffect(() => {
    getMLPApplication();
  }, []);

  return (
    <>
      <Navbar pageName="MLP" engagementId={engagementId} />
      {rowForm.length > 0 ? (
        <Box
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginBottom: '30px'
          }}
        >
          {table.length > 0 && (
            <Button variant="contained" color="success" onClick={handleSave}>
              Save
            </Button>
          )}
        </Box>
      ) : (
        <Box style={{ display: 'flex', justifyContent: 'center' }}>
          <Typography sx={{ fontWeight: 'bold', color: 'Red' }} variant="h5">
            No MLP Found Please Create RCM First.
          </Typography>
        </Box>
      )}

      {table.length > 0 && (
        <MergingIdentifiers
          initialTags={identifier}
          saveIdentifiers={saveIdentifiers}
        />
      )}

      {table.length > 0 ? (
        <CustomTable
          fieldsMeta={fields}
          tableMeta={meta}
          rows={table}
          stickyColumnName="Observation"
          handleChangeCB={handleRowChange}
          topStyle="25px"
        />
      ) : (
        <Box style={{ display: 'flex', justifyContent: 'center' }}>
          <Typography sx={{ fontWeight: 'bold', color: 'Red' }} variant="h5">
            No Ineffective Controls are Present
          </Typography>
        </Box>
      )}
    </>
  );
};

export default MLP;
