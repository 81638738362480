import * as React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { IconButton } from '@mui/material';
import axios from 'axios';
import { BACKEND_URL } from '../../config';
import { useDataLayerValue } from '../../contextAPI/DataLayer';

export default function PaymentForm({
  handleBack,
  handleNext,
  handleChange,
  employeeDetails,
  inputFields,
  handleVarFieldInput,
  handleVarFieldSubmit,
  handleAddFields,
  handleRemoveFields,
  handleRoleChange,
  handleDeptChange
}) {
  const [{ _id }] = useDataLayerValue();
  const [depts, setDepts] = React.useState([]);
  const [roles, setRoles] = React.useState([]);
  React.useEffect(() => {
    axios
      .get(BACKEND_URL + '/roles', {
        headers: {
          employeeid: _id
        }
      })
      .then((res) => {
        setRoles(res.data.body);
      })
      .catch((er) => {
        console.log(er);
      });
    axios
      .get(BACKEND_URL + '/departments', {
        headers: {
          employeeid: _id
        }
      })
      .then((res) => {
        setDepts(res.data.body);
      })
      .catch((er) => {
        console.log(er);
      });
  }, []);

  React.useEffect(() => {}, [depts, roles]);

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Roles and Rights
      </Typography>
      <Box component="form" onSubmit={handleNext} sx={{ mt: 3 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <FormControl variant="standard" sx={{ m: 0, minWidth: 180 }}>
              <InputLabel id="demo-simple-select-standard-label">
                Employment Type*
              </InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={employeeDetails.employmentType}
                onChange={handleChange}
                label="Employment Type"
                name="employmentType"
                required
              >
                <MenuItem value={'Employee'}>Employee</MenuItem>
                <MenuItem value={'Intern'}>Intern</MenuItem>
                <MenuItem value={'Contract Worker'}>Contract Worker</MenuItem>
                <MenuItem value={'Gig Worker'}>Gig Worker</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl variant="standard" sx={{ m: 0, minWidth: 180 }}>
              <InputLabel id="demo-simple-select-standard-label">
                Role*
              </InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                onChange={handleRoleChange}
                label="Role"
                name="role"
                defaultValue={''}
                required
              >
                {roles.map((role) => {
                  return (
                    <MenuItem key={role._id} value={role}>
                      {role.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl variant="standard" sx={{ m: 0, minWidth: 180 }}>
              <InputLabel id="demo-simple-select-standard-label">
                Department*
              </InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                onChange={handleDeptChange}
                label="Department"
                name="department"
                defaultValue={''}
                required
              >
                {depts.map((dept) => {
                  return (
                    <MenuItem key={dept._id} value={dept}>
                      {dept.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              required
              id="designation"
              name="designation"
              label="Designation"
              fullWidth
              variant="standard"
              onChange={handleChange}
              value={employeeDetails.designation}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              Other Details
            </Typography>
          </Grid>
          <br />
          <Grid item xs={12}>
            {inputFields.map((field) => (
              <Grid container spacing={3} key={field.id}>
                <Grid item xs={3}>
                  <TextField
                    id="field"
                    name="key"
                    label="Field"
                    fullWidth
                    value={field.key}
                    onChange={(e) => handleVarFieldInput(field.id, e)}
                    autoComplete="key"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="val"
                    name="val"
                    value={field.val}
                    onChange={(e) => handleVarFieldInput(field.id, e)}
                    label="Value"
                    fullWidth
                    autoComplete="value"
                  />
                </Grid>
                <IconButton
                  disabled={inputFields.length === 1}
                  onClick={() => handleRemoveFields(field.id)}
                >
                  <RemoveIcon />
                </IconButton>
                <IconButton onClick={handleAddFields}>
                  <AddIcon />
                </IconButton>
              </Grid>
            ))}
            <Grid item xs={12}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end'
                }}
              >
                <Button
                  variant="contained"
                  sx={{ mt: 3, ml: 1 }}
                  onClick={handleVarFieldSubmit}
                >
                  Confirm Other Details
                </Button>
              </Box>
            </Grid>
          </Grid>
          {/* {console.log(rights)} */}
          <Grid item xs={12}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button onClick={handleBack} sx={{ mt: 3, ml: 1 }}>
                Back
              </Button>

              <Button type="submit" variant="contained" sx={{ mt: 3, ml: 1 }}>
                {'Submit'}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  );
}
