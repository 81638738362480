import React, { useState } from 'react';
import { Box } from '@mui/system';
import { Button, Typography } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import TextField from '@mui/material/TextField';
import { useNavigate } from 'react-router-dom';
import AddQuestionTable from '../Components/AddQuestionTable';
import { getAllTags } from '../../../constants/requests';
import { useLoading } from '../../../hooks/useLoading';
import { useNotifications } from '../../../hooks/useNotifications';

const AddPolicyTemplate = () => {
  const navigate = useNavigate();
  const { request } = useLoading();
  const { showNotifications } = useNotifications();
  const [file, setFile] = useState(null);
  const [possible, setPossible] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [error, setError] = useState(false);

  const [policyName, setPolicyName] = useState('');
  const [policyType, setPolicyType] = useState('');
  const [policyTemplateTypes, setPolicyTemplateTypes] = useState([]);

  const handleFileUpload = async () => {
    setError(false);
    setPossible(false);
    try {
      const formData = new FormData();
      formData.append('file', file);
      const response = await request(() => getAllTags(formData));
      if (response?.data?.statusCode === 200) {
        const newTableData = response.data.body.map((tag) => {
          return {
            Tag: tag,
            Question: 'Q. ',
            Type: 'tag'
          };
        });
        setTableData(newTableData);
        setPossible(true);
      } else {
        if (response.response.data.statusCode !== 200) {
          showNotifications('error', response.response.data.message, 5000);
          setError(true);
          setPossible(false);
        }
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
      setError(true);
      setPossible(false);
    }
  };

  const handleFileSelection = (event) => {
    setFile(event.target.files[0]);
  };

  return (
    <React.Fragment>
      <Box
        style={{
          marginBottom: '25px',
          display: 'flex',
          justifyContent: 'space-between'
        }}
      >
        <Button
          sx={{
            color: 'white',
            backgroundColor: 'black',
            paddingLeft: 1
          }}
          variant="contained"
          onClick={() => navigate(`/policy`)}
        >
          <ChevronLeftIcon />
          Back
        </Button>
      </Box>

      <Box
        style={{
          display: 'flex',
          justifyContent: 'start',
          alignItems: 'center',
          marginBottom: '30px'
        }}
      >
        <Typography sx={{ fontWeight: 'bold', flexGrow: 1 }} variant="h4">
          Add Policy Template
        </Typography>
      </Box>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-around',
          flexWrap: 'wrap',
          margin: 'auto',
          marginBottom: '50px',
          border: '2px solid #cfcfcf',
          borderRadius: '10px',
          boxShadow: '3px 3px 13px -8px rgba(0,0,0,0.75)',
          padding: 4,
          width: '70%'
        }}
      >
        <TextField type="file" onChange={handleFileSelection} />
        <Button
          variant="contained"
          sx={{ backgroundColor: 'black' }}
          onClick={handleFileUpload}
          disabled={!file}
        >
          Read Tags
        </Button>
      </Box>
      {error && (
        <Typography variant="h3" sx={{ color: 'red' }}>
          There is some error in the file you just submitted.
        </Typography>
      )}
      {possible && file && (
        <AddQuestionTable
          tableData={tableData}
          setTableData={setTableData}
          file={file}
          policyName={policyName}
          setPolicyName={setPolicyName}
          policyType={policyType}
          setPolicyType={setPolicyType}
          policyTemplateTypes={policyTemplateTypes}
          setPolicyTemplateTypes={setPolicyTemplateTypes}
        />
      )}
    </React.Fragment>
  );
};

export default AddPolicyTemplate;
