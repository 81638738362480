import React, { useEffect, useState } from 'react';
import { Box, Modal, Typography, Button, TextField } from '@mui/material';
import { useLoading } from '../../../../hooks/useLoading';
import { useNotifications } from '../../../../hooks/useNotifications';
import { addPolicyEngagementPublishHistory } from '../../../../constants/requests';
import { useParams } from 'react-router-dom';
import HistoryModal from './HistoryModal';
import EmailList from './EmailList';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 720,
  bgcolor: 'white',
  boxShadow: 24,
  borderRadius: '10px',
  p: 4
};

const PublishPolicyModal = ({
  openPublishPolicyModal,
  handleClosePublishPolicyModal
}) => {
  const { request } = useLoading();
  const { showNotifications } = useNotifications();
  const [link, setLink] = useState('');
  const [emails, setEmails] = useState([]);
  const [linkError, setLinkError] = useState('');
  const [emailErrors, setEmailErrors] = useState(['']);
  const [disableButton, setDisableButton] = useState(false);
  const [open, setOpen] = useState(false);
  const params = useParams();

  const [openHistoryModal, setOpenHistoryModal] = useState(false);
  const handleOpenHistoryModal = () => setOpenHistoryModal(true);
  const handleCloseHistoryModal = () => setOpenHistoryModal(false);

  const addPublishHistory = async () => {
    try {
      const formData = new FormData();
      formData.append('driveLink', link);
      formData.append('emails', JSON.stringify(emails));
      const response = await request(() =>
        addPolicyEngagementPublishHistory(params.id, formData)
      );
      if (response?.data?.statusCode === 200) {
        showNotifications('success', 'Published Successfully', 5000);
        setDisableButton(true);
      } else {
        if (response.response.data.statusCode !== 200) {
          showNotifications('error', response.response.data.message, 5000);
        }
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  useEffect(() => {
    disableButton;
    setEmails;
  }, []);

  const handleLinkChange = (event) => {
    setLink(event.target.value);
  };

  const validateLink = () => {
    if (!link) {
      setLinkError('Link is required');
    } else {
      setLinkError('');
    }
  };

  const validateEmail = (email, index) => {
    if (!email) {
      setEmailErrors((prevErrors) => {
        const updatedErrors = [...prevErrors];
        updatedErrors[index] = 'Email is required';
        return updatedErrors;
      });
    } else if (!isValidEmail(email)) {
      setEmailErrors((prevErrors) => {
        const updatedErrors = [...prevErrors];
        updatedErrors[index] = 'Invalid email format';
        return updatedErrors;
      });
    } else {
      setEmailErrors((prevErrors) => {
        const updatedErrors = [...prevErrors];
        updatedErrors[index] = '';
        return updatedErrors;
      });
    }
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handlePublish = () => {
    validateLink();
    emails.forEach((email, index) => validateEmail(email, index));
    if (!linkError && !emailErrors.some((error) => error)) {
      console.log('Link:', link);
      console.log('Emails:', emails);
      addPublishHistory();
    }
  };

  return (
    <React.Fragment>
      <Modal
        open={openPublishPolicyModal}
        onClose={handleClosePublishPolicyModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h5"
            component="h2"
            sx={{ textAlign: 'center', marginBottom: 3, fontWeight: 600 }}
          >
            Publish Policies
          </Typography>
          <TextField
            label="Link"
            variant="outlined"
            fullWidth
            value={link}
            onChange={handleLinkChange}
            onBlur={validateLink}
            margin="normal"
            error={!!linkError}
            helperText={linkError}
          />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: '6px'
            }}
          >
            <Typography variant="h6">Emails</Typography>
          </Box>
          <EmailList chips={emails} setChips={setEmails} />
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button
              variant="contained"
              onClick={() => {
                setOpen(true);
                handleOpenHistoryModal();
              }}
              style={{ marginTop: '16px' }}
            >
              History
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handlePublish}
              disabled={disableButton || !link || emails.length === 0}
              style={{ marginTop: '16px' }}
            >
              Publish
            </Button>
          </Box>
        </Box>
      </Modal>

      {/* Publish Policy Modal */}
      <HistoryModal
        openHistoryModal={openHistoryModal}
        handleCloseHistoryModal={handleCloseHistoryModal}
        open={open}
        setOpen={setOpen}
      />
    </React.Fragment>
  );
};

export default PublishPolicyModal;
