import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Modal, Button, TextField, Typography } from '@mui/material';
import {
  getOrgTableById,
  updateOrgTableRequest
} from '../../../constants/requests';
import { useLoading } from '../../../hooks/useLoading';
import { useNotifications } from '../../../hooks/useNotifications';
import CustomGrid from '../common/CustomGrid';
import DeleteDialog from '../common/DeleteDialog';
import CreateEditDialog from '../common/CreateEditDialog';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

const OrgTable = () => {
  const navigate = useNavigate();
  const { request } = useLoading();
  const { showNotifications } = useNotifications();
  const { engagementId, orgTableId } = useParams();
  const [delRow, setDelRow] = useState(null);
  const [orgData, setOrgData] = useState(null);
  const [addRecordForm, setAddRecordForm] = useState([]);
  const [openAddRecordModal, setOpenAddRecordModal] = useState(false);
  const [openDeleteRecordModal, setOpenDeleteRecordModal] = useState(false);

  const handleOpenAddRecordModal = () => setOpenAddRecordModal(true);
  const handleCloseAddRecordModal = () => setOpenAddRecordModal(false);
  const handleOpenDeleteRecordModal = (row) => {
    setDelRow(row);
    setOpenDeleteRecordModal(true);
  };
  const handleCloseDeleteRecordModal = () => {
    setDelRow(null);
    setOpenDeleteRecordModal(false);
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    const newFormData = { ...orgData };
    newFormData[name] = value;
    setOrgData(newFormData);
  };

  const createAddRecordForm = (metaProperties) => {
    const { fields } = metaProperties;
    const recordForm = Object.keys(fields).map((key) => {
      return {
        type: fields[key]['fieldType'],
        fieldName: key,
        fieldLabel: `Enter ${key}`,
        value: '',
        required: false,
        dropDownOptions: fields[key]?.['options']
      };
    });
    setAddRecordForm(recordForm);
  };

  const loadApplication = async () => {
    try {
      const res = await request(() => getOrgTableById(orgTableId));
      if (res.data.statusCode === 200) {
        setOrgData(res.data.body);
        createAddRecordForm(res.data.body.data.meta);
        showNotifications('success', res.data.message, 5000);
      } else {
        showNotifications('error', res.data.message, 5000);
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  const handleSave = async () => {
    try {
      const payload = orgData;
      const res = await request(() =>
        updateOrgTableRequest(orgTableId, payload)
      );
      if (res.data.statusCode === 200) {
        setOrgData(orgData);
        showNotifications('success', res.data.message, 5000);
      } else {
        showNotifications('error', res.data.message, 5000);
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  const handleAddRecord = async (newRecord) => {
    try {
      const newData = {};
      Object.keys(newRecord).map((record) => {
        newData[record] = newRecord[record]['value'];
      });
      const newOrgData = { ...orgData };
      let newRows = newOrgData['data']['rows']['OrgTable'];
      newRows = [...newRows, newData];
      newOrgData['data']['rows']['OrgTable'] = newRows;
      const payload = newOrgData;
      let response = await request(() =>
        updateOrgTableRequest(orgTableId, payload)
      );
      if (response.data.statusCode === 200) {
        setOrgData(newOrgData);
        handleCloseAddRecordModal();
        showNotifications('success', response.data.message, 5000);
      } else {
        showNotifications('error', response.data.message, 5000);
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
      console.log(err);
    }
  };

  const handleDeleteRecord = async () => {
    try {
      const filteredData = orgData['data']['rows']['OrgTable'].filter(
        (row) => JSON.stringify(row) !== JSON.stringify(delRow)
      );
      const newOrgData = { ...orgData };
      orgData['data']['rows']['OrgTable'] = filteredData;
      const payload = newOrgData;
      let response = await request(() =>
        updateOrgTableRequest(orgTableId, payload)
      );
      if (response.data.statusCode === 200) {
        setOrgData(newOrgData);
        handleCloseDeleteRecordModal();
        showNotifications('success', response.data.message, 5000);
      } else {
        showNotifications('error', response.data.message, 5000);
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
      console.log(err);
    }
  };

  const handleRowUpdate = (newRow, oldRow) => {
    const newOrgRows = [...orgData.data.rows.OrgTable];
    newOrgRows.forEach((element, index) => {
      if (JSON.stringify(oldRow) === JSON.stringify(element)) {
        newOrgRows[index] = newRow;
      }
    });
    const newOrgData = { ...orgData };
    newOrgData.data.rows.OrgTable = newOrgRows;
    setOrgData(newOrgData);
  };

  useEffect(() => {
    loadApplication();
  }, []);

  return (
    <React.Fragment>
      <Box
        style={{
          marginBottom: '25px',
          display: 'flex',
          justifyContent: 'space-between'
        }}
      >
        <Button
          sx={{
            color: 'white',
            backgroundColor: 'black',
            paddingLeft: 1
          }}
          variant="contained"
          onClick={() => {
            navigate(`/services/engagement/${engagementId}/understanding`);
          }}
        >
          <ChevronLeftIcon />
          UNDERSTANDING
        </Button>
      </Box>
      <Box
        style={{
          marginBottom: '25px',
          display: 'flex',
          justifyContent: 'space-between'
        }}
      >
        <Typography sx={{ fontWeight: 'bold' }} variant="h4">
          ORG TABLE
        </Typography>
        {orgData !== null ? (
          <Button variant="contained" color="success" onClick={handleSave}>
            Save
          </Button>
        ) : null}
      </Box>

      {orgData !== null ? (
        <Box style={{ marginBottom: '40px' }}>
          <Box
            fullWidth
            style={{
              display: 'flex',
              gap: '10px',
              marginBottom: '10px'
            }}
          >
            <TextField
              label="Organization Structure Frequency Review"
              name="orgStructureFrequencyReview"
              fullWidth
              style={{ marginBottom: '10px' }}
              value={orgData.orgStructureFrequencyReview}
              onChange={handleFormChange}
            ></TextField>
            <TextField
              label="Organization Structure Review"
              name="orgStructureReview"
              value={orgData.orgStructureReview}
              onChange={handleFormChange}
              fullWidth
              style={{ marginBottom: '10px' }}
            ></TextField>
          </Box>
          <Box
            fullWidth
            style={{
              display: 'flex',
              gap: '10px',
              marginBottom: '10px'
            }}
          >
            <TextField
              label="Email (Incident Handling)"
              name="IncidentHanldingEmail"
              fullWidth
              style={{ marginBottom: '10px' }}
              value={orgData.IncidentHanldingEmail}
              onChange={handleFormChange}
            ></TextField>
            <TextField
              label="Email (HR Issues)"
              name="HRIssuesEmail"
              value={orgData.HRIssuesEmail}
              onChange={handleFormChange}
              fullWidth
              style={{ marginBottom: '10px' }}
            ></TextField>
            <TextField
              label="Email (Whistle Blower)"
              name="WhistleBlowerEmail"
              value={orgData.WhistleBlowerEmail}
              onChange={handleFormChange}
              fullWidth
              style={{ marginBottom: '10px' }}
            ></TextField>
          </Box>
        </Box>
      ) : null}

      {orgData !== null ? (
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            margin: '40px 0px 10px'
          }}
        >
          <Typography
            sx={{ fontWeight: 'bold', marginRight: '30%' }}
            variant="h5"
          >
            Records
          </Typography>
          <Button
            sx={{
              color: 'white',
              backgroundColor: 'black'
            }}
            variant="contained"
            onClick={handleOpenAddRecordModal}
          >
            Add Record
          </Button>
        </Box>
      ) : null}

      {orgData !== null && orgData.data.rows.OrgTable.length > 0 ? (
        <CustomGrid
          handleChangeCB={handleRowUpdate}
          rows={orgData.data.rows.OrgTable}
          fieldsMeta={orgData.data.meta.fields}
          tableMeta={orgData.data.meta.OrgTable}
          deleteCB={handleOpenDeleteRecordModal}
        />
      ) : (
        <Box style={{ display: 'flex', justifyContent: 'center' }}>
          <Typography sx={{ fontWeight: 'bold', color: 'Red' }} variant="h5">
            No Records Found. Please Add Records First.
          </Typography>
        </Box>
      )}

      <Modal
        open={openAddRecordModal}
        onClose={handleCloseAddRecordModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <CreateEditDialog
          formData={addRecordForm}
          onCloseCB={handleCloseAddRecordModal}
          refreshPageContentCB={loadApplication}
          entityName="Record"
          customUpdateCB={handleAddRecord}
        />
      </Modal>

      <Modal
        open={openDeleteRecordModal}
        onClose={handleCloseDeleteRecordModal}
      >
        <DeleteDialog
          entityName={`Record`}
          customDeleteCB={handleDeleteRecord}
          onCloseCB={handleCloseDeleteRecordModal}
        />
      </Modal>
    </React.Fragment>
  );
};

export default OrgTable;
