import React, { useCallback, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Box,
  Table,
  Modal,
  Button,
  TableRow,
  MenuItem,
  TableCell,
  TableHead,
  TableBody,
  TextField,
  InputLabel,
  Typography,
  FormControl,
  TableContainer,
  TextareaAutosize,
  tableCellClasses,
  Select,
  Checkbox,
  ListItemText
} from '@mui/material';
import {
  getServiceById,
  getEngagementByIdRequest,
  createApplicationRequest,
  updateApplicationRequest,
  deleteApplicationRequest,
  getApplicationByIdRequest,
  updateUnderstandingRequest
} from '../../../constants/requests';
import { styled } from '@mui/material/styles';
import { useLoading } from '../../../hooks/useLoading';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useNotifications } from '../../../hooks/useNotifications';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import moment from 'moment';
import Paper from '@mui/material/Paper';
import EditIcon from '@mui/icons-material/Edit';
import DeleteDialog from '../common/DeleteDialog';
import DeleteIcon from '@mui/icons-material/Delete';
import CreateEditDialog from '../common/CreateEditDialog';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import getFormattedTableCell from '../../../utils/getFormattedTableCell';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14
  }
}));
const systemOptions = [
  {
    label: 'Finance & Legal',
    value: 'Finance & Legal'
  },
  {
    label: 'Admin',
    value: 'Admin'
  },
  {
    label: 'HR',
    value: 'HR'
  },
  {
    label: 'Tech & Product',
    value: 'Tech & Product'
  },
  {
    label: 'Operation',
    value: 'Operation'
  }
];
const StyledTableRow = styled(TableRow)(() => ({
  '&:last-child td, &:last-child th': {
    border: 0
  }
}));
const tableHeaders = [
  'Business Process',
  'Application Name',
  'Version',
  'Implemented/ upgraded during the year?',
  'How is the application hosted?',
  'Operating System',
  'Database',
  'Single Sign On',
  'Application Software Type',
  'Configurable Settings',
  'Description'
];

const scopeItems = [
  {
    label: 'SOC1 Type1',
    value: 'SOC1 Type1'
  },
  {
    label: 'SOC1 Type2',
    value: 'SOC1 Type2'
  },
  {
    label: 'SOC2 Type1',
    value: 'SOC2 Type1'
  },
  {
    label: 'SOC2 Type2',
    value: 'SOC2 Type2'
  },
  {
    label: 'Readiness SOC2',
    value: 'Readiness SOC2'
  },
  {
    label: 'Readiness SOC1',
    value: 'Readiness SOC1'
  },
  {
    label: 'ISAE-3000 Type 1',
    value: 'ISAE-3000 Type 1'
  },
  {
    label: 'ISAE-3000 Type 2',
    value: 'ISAE-3000 Type 2'
  },
  {
    label: 'ISAE-3402 Type 1',
    value: 'ISAE-3402 Type 1'
  },
  {
    label: 'ISAE-3402 Type 2',
    value: 'ISAE-3402 Type 2'
  }
];

export default function Understanding() {
  const navigate = useNavigate();
  const { engagementId } = useParams();
  const { request } = useLoading();
  const { showNotifications } = useNotifications();

  const [applications, setApplications] = useState([]);
  const [selectedApplicationId, setSelectedApplicationId] = useState('');

  const [openAddModal, setOpenAddModal] = React.useState(false);
  const handleOpenAddModal = () => setOpenAddModal(true);
  const handleCloseAddModal = () => setOpenAddModal(false);

  const [openEditModal, setOpenEditModal] = React.useState(false);
  const handleOpenEditModal = () => setOpenEditModal(true);
  const handleCloseEditModal = () => setOpenEditModal(false);

  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const handleOpenDeleteModal = () => setOpenDeleteModal(true);
  const handleCloseDeleteModal = () => setOpenDeleteModal(false);

  const [serviceName, setServiceName] = useState(null);
  const [understanding, setUnderstanding] = useState(null);
  const [formFields, setFormFields] = useState({
    auditStartDate: {
      value: '',
      required: true,
      error: false
    },
    auditEndDate: {
      value: '',
      required: true,
      error: false
    },
    CTOITHead: {
      value: '',
      required: false,
      error: false
    },
    applicationHead: {
      value: '',
      required: false,
      error: false
    },
    infraHead: {
      value: '',
      required: false,
      error: false
    },
    keyITSPOC: {
      value: '',
      required: true,
      error: false
    },
    keyFinanceSPOC: {
      value: '',
      required: false,
      error: false
    },
    financeSPOCEscalationName: {
      value: '',
      required: false,
      error: false
    },
    CTOITHeadEmail: {
      value: '',
      required: false,
      error: false
    },
    keyITSPOCEmail: {
      value: '',
      required: true,
      error: false
    },
    financeSPOCEscalationEmail: {
      value: '',
      required: false,
      error: false
    },
    businessDescription: {
      value: '',
      required: false,
      error: false
    },
    locationUnderScope: {
      value: '',
      required: false,
      error: false
    },
    industry: {
      value: '',
      required: false,
      error: false
    },
    subIndustry: {
      value: '',
      required: false,
      error: false
    },
    isoScope: {
      value: '',
      required: false,
      error: false
    },
    functionOfScope: {
      value: [],
      required: false,
      error: false
    },
    socStandard: {
      value: '',
      required: false,
      error: false
    },
    socScope: {
      value: '',
      required: false,
      error: false
    },
    Function: {
      value: '',
      required: false,
      error: false
    },
    Department: {
      value: '',
      required: false,
      error: false
    },
    EmpolyeeCount: {
      value: '',
      required: false,
      error: false
    }
  });

  const getService = async (serviceId) => {
    try {
      const serviceRes = await request(() => getServiceById(serviceId));
      if (serviceRes.data.statusCode === 200) {
        setServiceName(serviceRes.data.body.name);
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  const getEngagement = useCallback(async () => {
    try {
      const response = await request(() =>
        getEngagementByIdRequest(engagementId)
      );
      const savedFormFields = { ...formFields };
      const understanding = response.data.body.understandingId;
      await getService(response.data.body.serviceId);
      const applications = response.data.body.applicationID.map(
        (application) => {
          const {
            _id,
            businessProcess,
            applicationName,
            version,
            isImplementedDuringYear,
            applicationHosted,
            operatingSystem,
            database,
            singleSignOn,
            applicationSoftwareType,
            hasConfigurableSettings,
            description
          } = application;
          return [
            _id,
            businessProcess,
            applicationName,
            version,
            isImplementedDuringYear,
            applicationHosted,
            operatingSystem,
            database,
            singleSignOn,
            applicationSoftwareType,
            hasConfigurableSettings,
            description
          ];
        }
      );

      Object.keys(understanding).forEach((key) => {
        if (savedFormFields[key] !== undefined)
          savedFormFields[key].value = understanding[key];
      });

      setFormFields(savedFormFields);
      setUnderstanding(understanding);
      setApplications(applications);
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
      console.log(err);
    }
  }, []);

  useEffect(() => {
    getEngagement();
  }, [getEngagement]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    // console.log(name, value);
    setFormFields({
      ...formFields,
      [name]: {
        ...formFields[name],
        value
      }
    });
  };

  const handleDateChange = (name, value) => {
    setFormFields({
      ...formFields,
      [name]: {
        ...formFields[name],
        value
      }
    });
  };

  const handleSubmit = async () => {
    try {
      const formFieldErrors = { ...formFields };
      let hasValidationErrors = false;

      Object.keys(formFieldErrors).forEach((formField) => {
        if (formFieldErrors[formField].required) {
          if (
            formFieldErrors[formField].value === null ||
            formFieldErrors[formField].value.length === 0
          ) {
            formFieldErrors[formField].error = true;
            hasValidationErrors = true;
          } else {
            formFieldErrors[formField].error = false;
          }
        }
      });

      if (hasValidationErrors) {
        setFormFields(formFieldErrors);
        return;
      }

      const payload = {};
      Object.keys(formFields).forEach((formField) => {
        if (formField === 'auditStartDate' || formField === 'auditEndDate') {
          formFields[formField].value = moment(
            formFields[formField].value.$d
          ).format('MM-DD-YYYY');
        }
        payload[formField] = formFields[formField].value;
      });

      const response = await request(() =>
        updateUnderstandingRequest(understanding._id, payload)
      );
      showNotifications('success', response.data.message, 5000);
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
      console.log(err);
    }
  };

  const createEditFormData = [
    {
      type: 'textField',
      fieldName: 'businessProcess',
      fieldLabel: 'Business Process',
      value: '',
      required: false
    },
    {
      type: 'textField',
      fieldName: 'applicationName',
      fieldLabel: 'Application Name',
      value: '',
      required: true
    },
    {
      type: 'textField',
      fieldName: 'version',
      fieldLabel: 'Version',
      value: '',
      required: false
    },
    {
      type: 'dropDown',
      fieldName: 'isImplementedDuringYear',
      fieldLabel: 'Implemented/ upgraded during the year?',
      dropDownOptions: [
        { label: 'Yes', value: true },
        { label: 'No', value: false }
      ],
      value: false,
      required: false
    },

    {
      type: 'textField',
      fieldName: 'applicationHosted',
      fieldLabel: 'How is the application hosted?',
      value: '',
      required: false
    },
    {
      type: 'textField',
      fieldName: 'operatingSystem',
      fieldLabel: 'Operating System',
      value: '',
      required: false
    },
    {
      type: 'textField',
      fieldName: 'database',
      fieldLabel: 'Database',
      value: '',
      required: false
    },
    {
      type: 'dropDown',
      fieldName: 'singleSignOn',
      fieldLabel: 'Single Sign On',
      dropDownOptions: [
        { label: 'Yes', value: true },
        { label: 'No', value: false }
      ],
      value: false,
      required: false
    },
    {
      type: 'textField',
      fieldName: 'applicationSoftwareType',
      fieldLabel: 'Application Software Type',
      value: '',
      required: false
    },
    {
      type: 'dropDown',
      fieldName: 'hasConfigurableSettings',
      fieldLabel: 'Configurable Settings',
      dropDownOptions: [
        { label: 'Yes', value: true },
        { label: 'No', value: false }
      ],
      value: false,
      required: false
    },
    {
      type: 'textField',
      fieldName: 'description',
      fieldLabel: 'Enter Description',
      value: '',
      required: false
    }
  ];

  return (
    <>
      <Box
        style={{
          marginBottom: '25px',
          display: 'flex',
          justifyContent: 'space-between'
        }}
      >
        <Button
          sx={{
            color: 'white',
            backgroundColor: 'black'
          }}
          variant="contained"
          onClick={() => navigate(`/services/engagement`)}
        >
          <ChevronLeftIcon />
          Engagements
        </Button>

        {understanding !== null &&
        understanding.trustServiceCriteriaTableId !== null ? (
          <Button
            sx={{ color: 'white', backgroundColor: 'black' }}
            variant="contained"
            onClick={() =>
              navigate(
                `/services/engagement/${engagementId}/trustServiceCriteria/${understanding.trustServiceCriteriaTableId}`
              )
            }
          >
            Trust Service Criteria
            <ChevronRightIcon />
          </Button>
        ) : null}

        {/*ISO Gap Assessment */}
        {serviceName !== null && serviceName === 'ISO27001' ? (
          <Button
            sx={{ color: 'white', backgroundColor: 'black' }}
            variant="contained"
            onClick={() =>
              //navigate(`/services/engagement/${engagementId}/gapassesment`)
              navigate(`/services/gapassessmentIso`)
            }
          >
            Gap Assessment
            <ChevronRightIcon />
          </Button>
        ) : null}
      </Box>

      <Box
        style={{
          marginBottom: '25px',
          display: 'flex',
          justifyContent: 'space-between'
        }}
      >
        <Typography sx={{ fontWeight: 'bold' }} variant="h4">
          Understanding
        </Typography>

        <Button
          sx={{ color: 'white', backgroundColor: 'black' }}
          variant="contained"
          onClick={() =>
            navigate(`/services/engagement/${engagementId}/download-documents`)
          }
        >
          <CloudDownloadIcon style={{ marginRight: '10px' }} />
          Download Documents
        </Button>
      </Box>

      <Box style={{ marginBottom: '40px' }}>
        <Box
          fullWidth
          style={{
            display: 'flex',
            gap: '10px',
            marginBottom: '10px'
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              label="Audit Period Start Date"
              inputFormat="DD-MM-YYYY"
              renderInput={(params) => (
                <TextField
                  name="auditStartDate"
                  required
                  fullWidth
                  {...params}
                  error={formFields.auditStartDate.error}
                  helperText={
                    formFields.auditStartDate.error &&
                    formFields.auditStartDate.required
                      ? 'Required'
                      : ''
                  }
                />
              )}
              value={formFields.auditStartDate.value}
              onChange={(newValue) =>
                handleDateChange('auditStartDate', newValue)
              }
            />
            <DesktopDatePicker
              label="Audit Period End Date"
              inputFormat="DD-MM-YYYY"
              renderInput={(params) => (
                <TextField
                  name="auditEndDate"
                  value={formFields.auditEndDate.value}
                  onChange={handleChange}
                  required
                  fullWidth
                  {...params}
                  error={formFields.auditEndDate.error}
                  helperText={
                    formFields.auditEndDate.error &&
                    formFields.auditEndDate.required
                      ? 'Required'
                      : ''
                  }
                />
              )}
              value={formFields.auditEndDate.value}
              onChange={(newValue) =>
                handleDateChange('auditEndDate', newValue)
              }
            />
          </LocalizationProvider>
        </Box>
        <Box
          fullWidth
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '10px'
          }}
        >
          <TextField
            error={formFields.CTOITHead.error}
            helperText={
              formFields.CTOITHead.error && formFields.CTOITHead.required
                ? 'Required'
                : ''
            }
            label="CTO/IT Head Name"
            name="CTOITHead"
            fullWidth
            style={{ marginBottom: '10px' }}
            value={formFields.CTOITHead.value}
            onChange={handleChange}
          ></TextField>
          <TextField
            error={formFields.CTOITHeadEmail.error}
            helperText={
              formFields.CTOITHeadEmail.error &&
              formFields.CTOITHeadEmail.required
                ? 'Required'
                : ''
            }
            label="CTO/IT Head Email Address"
            name="CTOITHeadEmail"
            value={formFields.CTOITHeadEmail.value}
            onChange={handleChange}
            fullWidth
            style={{ marginBottom: '10px' }}
          ></TextField>
        </Box>

        <Box fullWidth style={{ display: 'flex', gap: '10px' }}>
          <TextField
            error={formFields.applicationHead.error}
            helperText={
              formFields.applicationHead.error &&
              formFields.applicationHead.required
                ? 'Required'
                : ''
            }
            label="Application Head"
            name="applicationHead"
            value={formFields.applicationHead.value}
            onChange={handleChange}
            fullWidth
            style={{ marginBottom: '10px' }}
          ></TextField>
          <TextField
            error={formFields.infraHead.error}
            helperText={
              formFields.infraHead.error && formFields.infraHead.required
                ? 'Required'
                : ''
            }
            label="Infrastructure Head"
            name="infraHead"
            value={formFields.infraHead.value}
            onChange={handleChange}
            fullWidth
            style={{ marginBottom: '10px' }}
          ></TextField>
        </Box>

        <Box fullWidth style={{ display: 'flex', gap: '10px' }}>
          <TextField
            error={formFields.keyITSPOC.error}
            helperText={
              formFields.keyITSPOC.error && formFields.keyITSPOC.required
                ? 'Required'
                : ''
            }
            required
            label="Key IT SPOC Name"
            name="keyITSPOC"
            value={formFields.keyITSPOC.value}
            onChange={handleChange}
            fullWidth
            style={{ marginBottom: '10px' }}
          ></TextField>
          <TextField
            error={formFields.keyITSPOCEmail.error}
            helperText={
              formFields.keyITSPOCEmail.error &&
              formFields.keyITSPOCEmail.required
                ? 'Required'
                : ''
            }
            required
            label="Key IT SPOC Email"
            name="keyITSPOCEmail"
            value={formFields.keyITSPOCEmail.value}
            onChange={handleChange}
            fullWidth
            style={{ marginBottom: '10px' }}
          ></TextField>
        </Box>

        <Box fullWidth style={{ display: 'flex', gap: '10px' }}>
          <TextField
            error={formFields.locationUnderScope.error}
            helperText={
              formFields.locationUnderScope.error &&
              formFields.locationUnderScope.required
                ? 'Required'
                : ''
            }
            label="Location Under Scope"
            name="locationUnderScope"
            value={formFields.locationUnderScope.value}
            onChange={handleChange}
            fullWidth
            style={{ marginBottom: '10px' }}
          ></TextField>
          <TextField
            error={formFields.keyFinanceSPOC.error}
            helperText={
              formFields.keyFinanceSPOC.error &&
              formFields.keyFinanceSPOC.required
                ? 'Required'
                : ''
            }
            label="Key Finance SPOC"
            name="keyFinanceSPOC"
            value={formFields.keyFinanceSPOC.value}
            onChange={handleChange}
            fullWidth
            style={{ marginBottom: '10px' }}
          ></TextField>
        </Box>
        <Box fullWidth style={{ display: 'flex', gap: '10px' }}>
          <TextField
            error={formFields.financeSPOCEscalationName.error}
            helperText={
              formFields.financeSPOCEscalationName.error &&
              formFields.financeSPOCEscalationName.required
                ? 'Required'
                : ''
            }
            label="Finance SPOC Escalation Name"
            name="financeSPOCEscalationName"
            value={formFields.financeSPOCEscalationName.value}
            onChange={handleChange}
            fullWidth
            style={{ marginBottom: '10px' }}
          ></TextField>
          <TextField
            error={formFields.financeSPOCEscalationEmail.error}
            helperText={
              formFields.financeSPOCEscalationEmail.error &&
              formFields.financeSPOCEscalationEmail.required
                ? 'Required'
                : ''
            }
            label="Finance SPOC Escalation Email"
            name="financeSPOCEscalationEmail"
            value={formFields.financeSPOCEscalationEmail.value}
            onChange={handleChange}
            fullWidth
            style={{ marginBottom: '10px' }}
          ></TextField>
        </Box>

        <Box fullWidth style={{ display: 'flex', gap: '10px' }}>
          <TextField
            error={formFields.industry.error}
            helperText={
              formFields.industry.error && formFields.industry.required
                ? 'Required'
                : ''
            }
            label="Industry"
            name="industry"
            value={formFields.industry.value}
            onChange={handleChange}
            fullWidth
            style={{ marginBottom: '10px' }}
          ></TextField>
          <TextField
            error={formFields.subIndustry.error}
            helperText={
              formFields.subIndustry.error && formFields.subIndustry.required
                ? 'Required'
                : ''
            }
            label="Sub Industry"
            name="subIndustry"
            value={formFields.subIndustry.value}
            onChange={handleChange}
            fullWidth
            style={{ marginBottom: '10px' }}
          ></TextField>
          {serviceName !== null && serviceName === 'SOC2.0' ? (
            <TextField
              select
              error={formFields.socScope.error}
              helperText={
                formFields.socScope.error && formFields.socScope.required
                  ? 'Required'
                  : ''
              }
              label="SOC Scope"
              name="socScope"
              value={formFields.socScope.value}
              onChange={handleChange}
              fullWidth
              style={{ marginBottom: '10px' }}
            >
              {React.Children.toArray(
                scopeItems.map((option) => (
                  <MenuItem value={option.value}>{option.label}</MenuItem>
                ))
              )}
            </TextField>
          ) : null}
        </Box>

        {serviceName !== null && serviceName === 'ISO27001' ? (
          <Box fullWidth>
            <TextField
              error={formFields.isoScope.error}
              helperText={
                formFields.isoScope.error && formFields.isoScope.required
                  ? 'Required'
                  : ''
              }
              label="ISO Scope"
              name="isoScope"
              value={formFields.isoScope.value}
              onChange={handleChange}
              fullWidth
              style={{ marginBottom: '10px' }}
            ></TextField>
            <FormControl fullWidth>
              <InputLabel
                variant="standard"
                shrink={true}
                htmlFor="uncontrolled-native"
              >
                Function of Scope
              </InputLabel>
              <Select
                multiple
                displayEmpty
                fullWidth
                style={{ margin: '20px 0' }}
                name="system"
                value={formFields.functionOfScope.value}
                onChange={(e) => {
                  setFormFields({
                    ...formFields,
                    functionOfScope: {
                      ...formFields.functionOfScope,
                      value: e.target.value
                    }
                  });
                }}
                renderValue={(selected) => {
                  if (selected.toString() === '' || selected.length === 0) {
                    return <em>Select</em>;
                  }
                  return selected.filter((val) => val !== '').join(',');
                }}
              >
                <MenuItem disabled value="">
                  <em>Select</em>
                </MenuItem>
                {systemOptions?.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    <Checkbox
                      checked={
                        formFields.functionOfScope.value.indexOf(option.value) >
                        -1
                      }
                    />
                    <ListItemText primary={option.label} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        ) : null}
        {serviceName !== null && serviceName === 'ISO27001' ? null : (
          <Box fullWidth style={{ display: 'flex', gap: '10px' }}>
            <FormControl fullWidth variant="standard">
              <InputLabel
                variant="standard"
                shrink={true}
                style={{ marginBottom: '10px' }}
                htmlFor="uncontrolled-native"
              >
                Business Description
              </InputLabel>
              <TextareaAutosize
                error={formFields.businessDescription.error}
                helperText={
                  formFields.businessDescription.error &&
                  formFields.businessDescription.required
                    ? 'Required'
                    : ''
                }
                fullWidth
                name="businessDescription"
                value={formFields.businessDescription.value}
                onChange={handleChange}
                placeholder="Business Description"
                minRows={2}
                maxRows={4}
                style={{ width: '100%', margin: '15px 0' }}
              />
            </FormControl>
          </Box>
        )}

        <Box style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            variant="contained"
            style={{
              marginTop: '10px'
            }}
            color="success"
            onClick={handleSubmit}
          >
            Save
          </Button>
        </Box>
      </Box>

      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '30px'
        }}
      >
        <Typography
          sx={{ fontWeight: 'bold', marginRight: '40%' }}
          variant="h5"
        >
          IT Landscape
        </Typography>

        <Box
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            gap: '15px'
          }}
        >
          {understanding !== null && understanding.orgTableId !== null ? (
            <Button
              sx={{ color: 'white', backgroundColor: 'black' }}
              variant="contained"
              onClick={() =>
                navigate(
                  `/services/engagement/${engagementId}/orgTable/${understanding.orgTableId}`
                )
              }
            >
              Org Table
            </Button>
          ) : null}

          <Button variant="contained" onClick={handleOpenAddModal}>
            Add Application
          </Button>
        </Box>
      </Box>
      <Box>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                {tableHeaders.map((tableHeader, idx) => {
                  return (
                    <StyledTableCell
                      key={`application-table-header-${idx}`}
                      align="right"
                    >
                      {tableHeader}
                    </StyledTableCell>
                  );
                })}

                <StyledTableCell align="right">Options</StyledTableCell>
                <StyledTableCell align="center">Actions</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {applications.map((application, idx) => (
                <StyledTableRow
                  hover
                  style={{ cursor: 'pointer' }}
                  key={`engagement-${idx}`}
                >
                  {application.map((cellData, colIdx) => {
                    if (colIdx === 0) return <></>; // skip adding _id to the table
                    return (
                      <StyledTableCell
                        key={`application-${idx}-${colIdx}`}
                        onClick={() =>
                          navigate(
                            `/services/engagement/${engagementId}/application/${application[0]}/rcm`
                          )
                        }
                        align="right"
                      >
                        {getFormattedTableCell(cellData)}
                      </StyledTableCell>
                    );
                  })}
                  <StyledTableCell align="right">
                    {
                      <Box
                        style={{
                          display: 'flex',
                          justifyContent: 'end',
                          gap: '5px'
                        }}
                      >
                        <EditIcon
                          onClick={() => {
                            handleOpenEditModal();
                            setSelectedApplicationId(application[0]);
                          }}
                        />
                        <DeleteIcon
                          onClick={() => {
                            handleOpenDeleteModal();
                            setSelectedApplicationId(application[0]);
                          }}
                        />
                      </Box>
                    }
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <Button
                      sx={{
                        color: 'white',
                        backgroundColor: 'black',
                        fontSize: '10px'
                      }}
                      variant="contained"
                      onClick={() =>
                        navigate(
                          `/services/engagement/${engagementId}/application/${application[0]}/rcm`
                        )
                      }
                    >
                      Goto RCM
                    </Button>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      {/* Create Application Modal */}
      <Modal
        open={openAddModal}
        onClose={handleCloseAddModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <CreateEditDialog
          entityName="Application"
          formData={createEditFormData}
          onCloseCB={handleCloseAddModal}
          refreshPageContentCB={getEngagement}
          createEntityRequest={createApplicationRequest}
          additionalPayloadParameters={{ engagementId }}
        />
      </Modal>

      {/* Update Engagement Modal */}
      <Modal
        open={openEditModal}
        onClose={handleCloseEditModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <CreateEditDialog
          formData={createEditFormData}
          entityId={selectedApplicationId}
          entityName="Application"
          onCloseCB={handleCloseEditModal}
          refreshPageContentCB={getEngagement}
          getEntityByIdRequest={getApplicationByIdRequest}
          updateEntityRequest={updateApplicationRequest}
        />
      </Modal>
      {/* Delete Engagement Modal */}
      <Modal
        open={openDeleteModal}
        onClose={handleCloseDeleteModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <DeleteDialog
          entityName="Application"
          entityId={selectedApplicationId}
          deleteRequest={deleteApplicationRequest}
          onCloseCB={handleCloseDeleteModal}
          refreshPageContentCB={getEngagement}
        />
      </Modal>
    </>
  );
}
