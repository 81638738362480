import React, { useEffect, useState } from 'react';
import { Box, Button, Modal, Typography } from '@mui/material';
import { useLoading } from '../../../hooks/useLoading';
import { useNotifications } from '../../../hooks/useNotifications';
import { useParams, useNavigate } from 'react-router-dom';
import {
  //findAllLibraryType,
  findAssetById,
  findRiskById,
  findRisksByProperties,
  updateRiskById,
  findThreatVulerabilityById
} from '../../../constants/requests';
import CustomGrid from '../common/CustomGrid';
import CreateEditDialog from '../common/CreateEditDialog';
import DeleteDialog from '../common/DeleteDialog';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

const RiskImpact = () => {
  const { currentAssetTabName, assetId, ThreatvulId } = useParams();
  const [RiskData, setRiskData] = useState(null);
  //console.log(currentAssetTabName);
  const navigate = useNavigate();
  const [RiskId, setId] = useState(null);
  const { request } = useLoading();
  const { showNotifications } = useNotifications();

  const [delRow, setDelRow] = useState(null);

  const [addRecordForm, setAddRecordForm] = useState([]);
  const [openAddRecordModal, setOpenAddRecordModal] = useState(false);
  const [openDeleteRecordModal, setOpenDeleteRecordModal] = useState(false);

  const handleOpenAddRecordModal = () => setOpenAddRecordModal(true);
  const handleCloseAddRecordModal = () => setOpenAddRecordModal(false);
  const handleOpenDeleteRecordModal = (row) => {
    setDelRow(row);
    setOpenDeleteRecordModal(true);
  };
  const handleCloseDeleteRecordModal = () => {
    setDelRow(null);
    setOpenDeleteRecordModal(false);
  };

  const handleClick = () => {
    navigate(-1);
  };

  const createAddRecordForm = (metaProperties) => {
    const { fields } = metaProperties;
    const recordForm = Object.keys(fields)
      .map((key) => {
        if (
          key === 'Asset Value' ||
          key === 'Asset Group' ||
          key === 'Overall Risk Value ' ||
          key === 'Revised Risk Level' ||
          key === 'Revised Risk Value Post Additional Control'
        ) {
          return null;
        }
        return {
          type: fields[key]['fieldType'],
          fieldName: key,
          fieldLabel: `Enter ${key}`,
          value: '',
          required: false,
          dropDownOptions: fields[key]?.['options']
        };
      })
      .filter((field) => field !== null);
    setAddRecordForm(recordForm);
  };

  const getRiskRepositoryById = async (RiskId) => {
    try {
      const res = await request(() => findRiskById(RiskId));

      if (res.data.statusCode === 200) {
        setRiskData(res.data.body);
        // console.log(res.data.body.rows.data);

        createAddRecordForm(res.data.body.data.meta);

        showNotifications('success', res.data.message, 5000);
      } else {
        showNotifications('error', res.data.message, 5000);
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  const getRiskRepository = async () => {
    try {
      const res = await request(() => findRisksByProperties());
      //console.log(res.data);

      if (res.data.statusCode === 200) {
        setId(res.data.body[0]._id);
        //console.log(res.data.body);

        getRiskRepositoryById(res.data.body[0]._id);
        showNotifications('success', res.data.message, 5000);
      } else {
        showNotifications('error', res.data.message, 5000);
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  const loadApplication = async () => {
    try {
      const assets = await request(() => findAssetById(assetId));
      //console.log(assets);
      if (assets.status !== 200) {
        console.log('asset not found');
        showNotifications('error', 'No Engagements Found', 5000);
        return;
      }
      //console.log(assets);
      const threatvuls = await request(() =>
        findThreatVulerabilityById(ThreatvulId)
      );
      if (threatvuls.data.statusCode !== 200) {
        console.log('threatvul not found');
        showNotifications('error', 'No Engagements Found', 5000);
        return;
      }
      const riskDataRows = [...RiskData.data.rows['RiskRegister']];
      //const riskDataRows = [];
      console.log(riskDataRows);
      console.log(assets.data.body.data.rows[currentAssetTabName]);
      const assetrows = assets.data.body.data.rows[currentAssetTabName]; // Assuming assetrows contain the necessary data
      const threatvulrows =
        threatvuls.data.body.data.rows['ThreatVulnerabilityPair'];
      console.log(threatvulrows);
      let newrows = [];
      let tot_pair = [];
      threatvulrows.forEach((row) => {
        tot_pair.push(row['Threat and Vulnerability Pair']);
      });
      let pair_in_risk = [];
      riskDataRows.forEach((row) => {
        pair_in_risk.push(row['Threat_Vulnerability Pair']);
      });
      let removepair = [];
      pair_in_risk.forEach((element) => {
        if (!tot_pair.includes(element)) {
          removepair.push(element);
        }
      });
      let addpair = [];
      tot_pair.forEach((element) => {
        if (!pair_in_risk.includes(element)) {
          addpair.push(element);
        }
      });
      console.log(removepair);
      console.log(addpair);
      riskDataRows.forEach((row) => {
        const p = row['Threat_Vulnerability Pair'];
        if (!removepair.includes(p)) {
          //console.log(p);
          console.log(row);
          newrows.push(row);
        }
      });
      assetrows.forEach((asset) => {
        threatvulrows.forEach((pair) => {
          if (addpair.includes(pair['Threat and Vulnerability Pair'])) {
            if (newrows.length <= 20000) {
              newrows.push({
                'Asset Group': asset['Asset Group'],
                'Asset Value': asset['Asset Value'],
                'Threat_Vulnerability Pair':
                  pair['Threat and Vulnerability Pair'],
                'Aggregate Threat Impact Value': pair['Aggregate Value'],
                'Likelihood(1 to 5)': pair['Likelihood'],
                'Control No.': pair['Control No.'],
                Control: pair['Control'],
                'New Control No': pair['New Control No'],
                'Control Description': pair['Control Description'],
                'New Control Descritption': pair['New Control Descritption']
              });
            }
          }
        });
      });
      //yaha tk data sahi aa raha
      console.log(newrows);
      const newRiskData = { ...RiskData };
      newRiskData['data']['rows']['RiskRegister'] = newrows;
      console.log(newRiskData['data']['rows']['RiskRegister']);

      const response = await request(() => updateRiskById(RiskId, newRiskData));
      if (response.data.statusCode === 200) {
        console.log(response);
        setRiskData(newRiskData);
        showNotifications('success', response.data.message, 5000);
      } else {
        console.log(response);
        showNotifications('error', response.data.message, 5000);
      }

      // const riskTable = threatvulrows
      //   .map((row) => {
      //     const matchedAsset = assetrows.find((element) => {
      //       // Match the asset based on some condition (e.g., ID or name)
      //       return element['Asset ID'] === row['Asset ID'];
      //     });

      //     if (!matchedAsset) {
      //       return null; // Skip this row if no matching asset is found
      //     }

      //     return {
      //       'Asset Group': matchedAsset['Asset Group'],
      //       'Asset Value': matchedAsset['Asset Value'],
      //       'Threat_Vulnerability Pair': row['Threat and Vulnerability Pair'],
      //       'Aggregate Value': row['Aggregate Value'],
      //       Likelihood: row['Likelihood'],
      //       'Control No.': row['Control No.'],
      //       Control: row['Control'],
      //       'New Control No': row['New Control No'],
      //       'Control Description': row['Control Description'],
      //       'New Control Descritption': row['New Control Descritption']
      //     };
      //   })
      //   .filter(Boolean); // Filter out any null/undefined entries

      // // Assuming RiskData is available and contains the necessary data

      // // Assuming RiskData is available and contains the necessary data
      // //const riskDataRows =RiskData.data.rows['RiskRegister'];
      // // Assuming RiskData is available and contains the necessary data

      // if (!riskDataRows) {
      //   console.log('RiskData rows not available');
      //   showNotifications('error', 'No Risk Data Found', 5000);
      //   return;
      // }

      // console.log(riskTable);

      // const updatedRiskDataRows = riskDataRows.map((riskDataRow) => {
      //   const matchedRiskTable = riskTable.find((riskTableData) => {
      //     // Match based on Threat_Vulnerability Pair
      //     //return riskTableData['Asset Group'] === riskDataRow['Asset Group'];
      //     riskTableData['Asset Group'] === riskDataRow['Asset Group'];
      //     return true; //for checking
      //   });

      //   //console.log(matchedRiskTable);
      //   //console.log(riskDataRow);

      //   if (matchedRiskTable) {
      //     // Merge the columns from riskTable into riskDataRow
      //     return {
      //       ...riskDataRow,
      //       ...matchedRiskTable
      //     };
      //   } else {
      //     return riskDataRow; // Return the original row if no match found
      //   }
      // });

      // // Print the updated RiskData rows
      // console.log(updatedRiskDataRows);

      // Print the resulting merged RiskData rows

      // Insert data into the risk table
      //threatvulrows.forEach((row) => {
      // assetrows.forEach((element)=>{
      // riskTable.push({
      //  'Asset Group': element['Asset Group'],
      // 'Asset Value': element['Asset Value'],

      //    'Threat_Vulnerability Pair': row['Threat_Vulnerability Pair'],
      //  'Aggregate Value': row['Aggregate Value'],
      //'Likelihood': row['Likelihood'],
      // 'Control No.': row['Control No.'],
      // 'Control': row['Control'],
      // 'New Control No': row['New Control No'],
      // 'Control Description': row['Control Description'],
      // 'New Control Descritption': row['New Control Descritption']
      // });
      // }
      // )});

      // Print the resulting risk table

      /*
      console.log(threatvuls);

      let tot_risk = [];
      let threatvulrows = [];
      
    console.log(threatvuls.data.body.rows['ThreatVulnerabilityPair']);

      threatvuls.data.body.data.rows['ThreatVulnerabilityPair'].foreach((element) => {
        tot_risk.push(element['Threat and Vulnerability Pair']);
         console.log(tot_risk);
        threatvulrows.push({
          'Threat_Vulnerability Pair': element['Threat and Vulnerability Pair'],
          'Aggregate Value': element['Aggregate Value'],
          'Likelihood': element['Likelihood'],
          'Control No.': element['Control No.'],
          'Control': element['Control'],
          'New Control No': element['New Control No'],
          'Control Description': element['Control Description'],
          'New Control Descritption': element['New Control Descritption']
        });
      });

      const risktable=[];
      threatvuls.forEach((row)=>{
        risktable.push({
          'Asset Group': assets.assetGroup,
        'Asset Value': assets.assetValue,
        'Threat_Vulnerability Pair': row['Threat_Vulnerability Pair']
        });
      });

      console.log(risktable);

      let threatdatarows=RiskData.data.rows['RiskRegister'];
      let threats_in_tv=[];
      let addthreat=[];
      let removerisk=[];

      threatdatarows.forEach((row)=>{
        const threatt=row['Threat_Vulnerability Pair'].split('_');
        let t=threatt[0];
        threats_in_tv.push(t);
      });

      threats_in_tv.forEach((t)=>{
        if(!tot_risk.includes(t)){
          removerisk.push(t);
        }
      });
      tot_risk.forEach((t)=>{
        if(!threats_in_tv.includes(t)){
          addthreat.push(t);
        }
      });

      let newrows=[];
      for(let i=0;i<threatdatarows.length;i++){
        const threatt=threatdatarows[i]['Threat_Vulnerability Pair'].split('_');
        let t=threatt[0];
        if(!removerisk.includes(t)){
          newrows.push(threatdatarows[i]);
          console.log(t);
        }
      }
      /*   assets.data.body.data.rows['Master Table'].forEach((asset) => {
           threatvuls.data.body.data.rows['ThreatVulnerabilityPair'].forEach(
             (threatv) => {
               const assetgrp = asset['Asset Group'];
               const assetval = asset['Asset Value'];
               const threatpair = threatv['Threat and Vulnerability Pair'];
               const aggval = threatv['Aggregate Value'];
               const likelihood = threatv['Likelihood'];
               const controlno = threatv['Control No'];
               const newcontrolno = threatv['New Control No'];
               const controldesc = threatv['Control Description'];
               const newcontroldesc = threatv['New Control Descritption'];
               const control = threatv['Control'];
               let row = {
                 'Asset Group': assetgrp,
                 'Asset Value': assetval,
                 'Threat_Vulnerability Pair': threatpair,
                 'Aggregate Threat Impact Value': aggval,
                 'Likelihood(1 to 5)': likelihood,
                 'Control No.': controlno,
                 'Control': control,
                 'New Control No': newcontrolno,
                 'Control Description': controldesc,
                 'New Control Descritption': newcontroldesc
               };
               //console.log(row);
               if (!riskrows.find(row)) {
                 console.log('added');
                 console.log(row);
                 riskrows.push(row);
               } else {
                 console.log('already present');
               }
             }
           );
         });
       
   
         threatvuls.data.body.rows['ThreatVulnerabilityPair'].forEach((threat)=>{
          if(addthreat.includes(threat['Threat and Vulnerability Pair'])){
            assets.data.body.forEach((asset)=>{
              const pair=threat['Threat and Vulnerability Pair']+'_'+asset['Asset Value'];

              newrows.push({
                'Threat_Vulnerability Pair':pair
              });
            });
          }
         });
*/

      // const newdata = {
      //   data: {
      //     meta: RiskData.data.meta.RiskRegister,
      //     rows: {
      //       RiskRegister: RiskData ///jab tk change nahi hoga yahi pass kro
      //     }
      //   },
      //   __v: 0,
      //   _id: RiskId
      // };
      // console.log(newdata);
      // setRiskData(newdata);
      // let response = await request(() => updateRiskById(RiskId, RiskData));

      // if (response.data.statusCode === 200) {
      //   showNotifications('success', response.data.message, 5000);
      // } else {
      //   showNotifications('error', response.data.message, 5000);
      // }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  const handleSave = async () => {
    try {
      const payload = RiskData;

      const res = await request(() => updateRiskById(RiskId, payload));
      // console.log(res.data);

      if (res.data.statusCode === 200) {
        setRiskData(RiskData);
        showNotifications('success', res.data.message, 5000);
      } else {
        showNotifications('error', res.data.message, 5000);
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  const handleAddRecord = async (newRecord) => {
    try {
      const newData = {};
      Object.keys(newRecord).map((record) => {
        newData[record] = newRecord[record]['value'];
      });

      const likelihood = parseInt(newData['Likelihood(1 to 5)'], 10) || 0;
      const AssetValue = parseInt(newData['Asset Value'], 10) || 0;
      const Aggregate =
        parseInt(newData['Aggregate Threat Impact Value'], 10) || 0;
      const likelihoodRevised =
        parseInt(newData['Likelihood Revised Post Additional'], 10) || 0;

      const OverAllRisk = likelihood * AssetValue * Aggregate;

      newData['Overall Risk Value'] = OverAllRisk;

      const RevisedRisk = AssetValue * likelihoodRevised * Aggregate;

      newData['Revised Risk Level'] = RevisedRisk;
      newData['Revised Risk Value Post Additional Control'] = RevisedRisk;
      const newRiskData = { ...RiskData };

      //console.log(JSON.stringify(newRiskData, null, 4));

      let newRows = newRiskData['data']['rows']['RiskRegister'];

      newRows = [...newRows, newData];
      newRiskData['data']['rows']['RiskRegister'] = newRows;
      const payload = newRiskData;

      let response = await request(() => updateRiskById(RiskId, payload));

      if (response.data.statusCode === 200) {
        setRiskData(newRiskData);
        handleCloseAddRecordModal();
        showNotifications('success', response.data.message, 5000);
      } else {
        showNotifications('error', response.data.message, 5000);
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
      console.log(err);
    }
  };

  const handleDeleteRecord = async () => {
    try {
      const filteredData = RiskData['data']['rows']['RiskRegister'].filter(
        (row) => JSON.stringify(row) !== JSON.stringify(delRow)
      );

      const newRiskData = { ...RiskData };
      RiskData['data']['rows']['RiskRegister'] = filteredData;
      const payload = newRiskData;

      const response = await request(() => updateRiskById(RiskId, payload));

      if (response.data.statusCode === 200) {
        setRiskData(newRiskData);
        handleCloseDeleteRecordModal();
        showNotifications('success', response.data.message, 5000);
      } else {
        showNotifications('error', response.data.message, 5000);
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
      console.log(err);
    }
  };

  const handleRowUpdate = (newRow, oldRow) => {
    const newRiskRows = [...RiskData.data.rows['RiskRegister']];

    const likelihood = parseInt(newRow['Likelihood(1 to 5)'], 10) || 0;
    const AssetValue = parseInt(newRow['Asset Value'], 10) || 0;
    const AgreagateRevised =
      parseInt(
        newRow['Aggregate Threat Impact Value Revised Post Additional Control'],
        10
      ) || 0;
    const Aggregate =
      parseInt(newRow['Aggregate Threat Impact Value'], 10) || 0;

    const OverAllRisk = likelihood * AssetValue * Aggregate;

    newRow['Overall Risk Value'] = OverAllRisk;

    const likelihoodRevised =
      parseInt(newRow['Likelihood Revised Post Additional Control'], 10) || 0;

    //console.log(likelihoodRevised);

    const Revised = AgreagateRevised * likelihoodRevised * AssetValue;
    //console.log(Revised);

    newRow['Revised Risk Level'] = Revised;
    newRow['Revised Risk Value Post Additional Control'] = Revised;

    newRiskRows.forEach((element, index) => {
      if (JSON.stringify(oldRow) === JSON.stringify(element)) {
        newRiskRows[index] = newRow;
      }
    });

    const newRiskData = { ...RiskData };
    newRiskData.data.rows['RiskRegister'] = newRiskRows;
    //console.log(newRiskData);

    setRiskData(newRiskData);
    //console.log(RiskData);
  };

  useEffect(() => {
    getRiskRepository();
  }, []);

  return (
    <React.Fragment>
      <Box
        style={{
          marginBottom: '25px',
          display: 'flex',
          justifyContent: 'space-between'
        }}
      ></Box>
      <Button
        sx={{
          color: 'white',
          backgroundColor: 'black',
          flexDirection: 'end',
          marginBottom: '10px'
        }}
        variant="contained"
        onClick={handleClick}
      >
        <ChevronLeftIcon />
        Threat Vulnerability
      </Button>
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          gap: '10px',
          marginBottom: '10px'
        }}
      >
        <Typography sx={{ fontWeight: 'bold' }} variant="h4">
          Risk Register
        </Typography>
      </Box>

      <Box style={{ marginBottom: '40px' }}>
        <Box
          style={{
            display: 'flex',
            gap: '10px',
            marginBottom: '10px',
            justifyContent: 'flex-end',
            alignItems: 'flex-end'
          }}
        >
          <Button
            sx={{
              color: 'white',
              backgroundColor: 'black'
            }}
            variant="contained"
            onClick={handleOpenAddRecordModal}
            disabled={RiskData !== null}
          >
            Add Record
          </Button>
          <Button
            variant="contained"
            color="success"
            onClick={handleSave}
            disabled={RiskData === null}
          >
            Save
          </Button>

          <Button
            sx={{
              color: 'white',
              backgroundColor: '#ff5722',
              alignContent: 'center'
            }}
            variant="contained"
            disabled={RiskData === null}
          >
            Connect Policy
          </Button>
          <Button
            onClick={loadApplication}
            sx={{
              color: 'white',
              backgroundColor: 'blue',
              alignContent: 'space-between'
            }}
            variant="contained"
          >
            Refresh
          </Button>
        </Box>
      </Box>

      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          margin: '40px 0px 10px'
        }}
      ></Box>

      {RiskData !== null &&
      RiskData.data !== null &&
      RiskData.data.rows['RiskRegister'].length > 0 ? (
        <CustomGrid
          rows={RiskData.data.rows['RiskRegister']}
          fieldsMeta={RiskData.data.meta.fields}
          tableMeta={RiskData.data.meta.RiskRegister}
          handleChangeCB={handleRowUpdate}
          deleteCB={handleOpenDeleteRecordModal}
        />
      ) : (
        <Box style={{ display: 'flex', justifyContent: 'center' }}>
          <Typography sx={{ fontWeight: 'bold', color: 'Red' }} variant="h5">
            No Data Available to Track
          </Typography>
        </Box>
      )}
      {/* Update Risk Repository Modal */}
      <Modal
        open={openAddRecordModal}
        onClose={handleCloseAddRecordModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <CreateEditDialog
          formData={addRecordForm}
          onCloseCB={handleCloseAddRecordModal}
          refreshPageContentCB={getRiskRepository}
          entityName="Record"
          customUpdateCB={handleAddRecord}
        />
      </Modal>
      <Modal
        open={openDeleteRecordModal}
        onClose={handleCloseDeleteRecordModal}
      >
        <DeleteDialog
          entityName={`Record`}
          customDeleteCB={handleDeleteRecord}
          onCloseCB={handleCloseDeleteRecordModal}
        />
      </Modal>
    </React.Fragment>
  );
};

export default RiskImpact;
