import React from 'react';
import { Box, Select, OutlinedInput, MenuItem, Chip } from '@mui/material';

export function MultiSelectComponent({
  listOfPolicyScopes,
  currentValue,
  updateValue,
  policyId
}) {
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250
      }
    }
  };

  const [personName, setPersonName] = React.useState(currentValue);

  const handleChange = (event) => {
    const {
      target: { value }
    } = event;
    setPersonName(typeof value === 'string' ? value.split(',') : value);
    updateValue(typeof value === 'string' ? value.split(',') : value, policyId);
  };

  return (
    <Select
      labelId="demo-multiple-name-label"
      id="demo-multiple-name"
      multiple
      value={personName}
      onChange={handleChange}
      // onBlur={() => { updateValue(personName, policyId) }}
      input={<OutlinedInput label="Name" />}
      MenuProps={MenuProps}
      sx={{ marginLeft: '20px', marginRight: '20px' }}
      renderValue={(selected) => (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
          {selected.map((value) => (
            <Chip key={value} label={value} />
          ))}
        </Box>
      )}
    >
      {listOfPolicyScopes.map((scope) => (
        <MenuItem key={scope} value={scope}>
          {scope}
        </MenuItem>
      ))}
    </Select>
  );
}
