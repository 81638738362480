import React from 'react';
import { useEffect, useState } from 'react';
import { RIGHTS_URL } from '../../../constants/urls';
import { useLoading } from '../../../hooks/useLoading';
import { HeaderWithCreateModal } from '../../../components/Master/HeaderWithCreateModal';
import {
  getRightsRequest,
  updateRightRequest
} from '../../../constants/requests';
import {
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { useNotifications } from '../../../hooks/useNotifications';
import { useDataLayerValue } from '../../../contextAPI/DataLayer';
import { useNavigate } from 'react-router-dom';

const broadRoleCreationFields = (actionTypes) => {
  const fields = [
    {
      label: 'Broad Role Name*',
      name: 'name',
      display: 'required',
      type: 'text'
    }
  ];
  actionTypes.forEach((actionType) => {
    fields.push({
      label: actionType,
      name: actionType,
      display: 'hidden',
      type: 'text',
      value: 'false'
    });
  });
  return fields;
};

export const BroadRoles = () => {
  const { request } = useLoading();
  const { showNotifications } = useNotifications();
  const [updated, setUpdated] = useState(false);
  const [rights, setRights] = useState([]);
  const [actionTypes, setActionTypes] = useState([]);
  const [user] = useDataLayerValue();
  const { CREATE, EDIT, VIEW_REPORT } = user.rights.BroadRole;
  const navigate = useNavigate();
  if (VIEW_REPORT === false) {
    navigate('/');
  }

  const getRightsAndActionTypes = async () => {
    const rightsResponse = await request(getRightsRequest);
    setRights(rightsResponse.data.body);
    setActionTypes(
      Object.getOwnPropertyNames(rightsResponse.data.body[0]).filter(
        (actionType) => !['_id', '__v', 'name'].includes(actionType)
      )
    );
  };

  const handleChange = (right, actionType) => {
    if (EDIT) {
      const updates = {};
      updates[actionType] = !right[actionType];
      request(() => {
        updateRightRequest(right._id, updates).then((response) => {
          if (response.data.statusCode === 200) {
            showNotifications('success', response.data.message, 5000);
            setUpdated(!updated);
          } else {
            showNotifications('error', response.data.message, 5000);
          }
        });
      });
    }
  };

  useEffect(() => {
    document.title = 'Broad Roles';
    getRightsAndActionTypes();
  }, [updated]);

  return (
    <>
      <HeaderWithCreateModal
        title="Broad Roles"
        options={{ createOption: CREATE }}
        creationFields={broadRoleCreationFields(actionTypes)}
        reqUrl={RIGHTS_URL}
        reqHeaders={{}}
      />
      <TableContainer
        component={Paper}
        sx={{ width: '100%', mt: 5, maxHeight: '70vh' }}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow key="head">
              <TableCell align="middle">
                <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
                  BROAD ROLE
                </Typography>
              </TableCell>
              {actionTypes.map((actionType) => {
                return (
                  // eslint-disable-next-line
                  <TableCell align="middle">
                    <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
                      {actionType}
                    </Typography>
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {rights.map((right) => {
              return (
                <TableRow
                  key={right.name}
                  sx={{
                    '&:last-child td, &:last-child th': {
                      border: 0
                    }
                  }}
                >
                  <TableCell>{right.name}</TableCell>
                  {/* {These TableCells are for configuring and updating the various actions a broad roles has} */}
                  {actionTypes.map((actionType) => {
                    return (
                      // eslint-disable-next-line
                      <TableCell align="middle">
                        <Checkbox
                          checked={right[actionType]}
                          onChange={() => handleChange(right, actionType)}
                        />
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
