export const stickyColumnTemplateMapping = {
  BDO_SAP: {
    column: 'Control Objective',
    style: '25px'
  },
  'Template B1': {
    column: 'Control Objective',
    style: '25px'
  },
  BDO_GENERIC: {
    column: 'Control Objective',
    style: '25px'
  },
  'Template B2': {
    column: 'Control Objective',
    style: '25px'
  },
  MAZAR: {
    column: 'Control objective',
    style: '25px'
  },
  'Template M': {
    column: 'Control objective',
    style: '25px'
  },
  'AUMYAA-SAP': {
    column: 'Control',
    style: '50px'
  },
  'Template A1': {
    column: 'Control',
    style: '50px'
  },
  'AUMYAA-GENERIC': {
    column: 'Control',
    style: '50px'
  },
  'Template A2': {
    column: 'Control',
    style: '50px'
  },
  SAIL: {
    column: 'Control',
    style: '50px'
  },
  'Template S1': {
    column: 'Control',
    style: '50px'
  },
  RBI: {
    column: 'Control Requirement',
    style: '25px'
  },
  'Template R': {
    column: 'Control Requirement',
    style: '25px'
  },
  'SOC2.0': {
    column: 'Control Activity',
    style: '25px'
  },
  'Template S2.0': {
    column: 'Control Activity',
    style: '25px'
  },
  CSA: {
    column: 'Control Activity',
    style: '100px'
  },
  Section3: {
    column: 'Sub Heading',
    style: '50px'
  }
};
