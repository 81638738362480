import React from 'react';
import { Box } from '@mui/system';
import { Typography } from '@mui/material';
import CustomControlTable from '../Components/CustomControlTable';

const AllPolicyGovernance = ({
  governanceTableData,
  handleChangeGovernance,
  deleteCB,
  reorderCB,
  tableName,
  marginLeftBox = 1.5,
  marginRightBox = 1.5,
  miscellaneous,
  generateButtonStatus,
  stickyColumnName
}) => {
  if (governanceTableData === null) {
    return;
  }
  return (
    <React.Fragment>
      <Box
        sx={{
          marginLeft: marginLeftBox,
          marginRight: marginRightBox,
          marginTop: 2
        }}
      >
        {governanceTableData['controls']['rows'].length > 0 ? (
          <Box sx={{ marginTop: 1 }} key={'controls'}>
            <CustomControlTable
              tabName={'controls'}
              deleteCB={deleteCB}
              reorderCB={reorderCB}
              fieldsMeta={governanceTableData['controls']['meta']['fieldsMeta']}
              tableMeta={governanceTableData['controls']['meta']['tableMeta']}
              rows={governanceTableData['controls']['rows']}
              stickyColumnName={stickyColumnName}
              handleChangeCB={handleChangeGovernance}
              topStyle="25px"
              miscellaneous={miscellaneous}
              generateButtonStatus={generateButtonStatus}
            />
          </Box>
        ) : (
          <Box style={{ display: 'flex', justifyContent: 'center' }}>
            <Typography sx={{ fontWeight: 'bold', color: 'Red' }} variant="h5">
              {tableName === 'Governance-Table'
                ? 'First Add Governances to the Policy.'
                : 'Approved Controls Table is empty'}
            </Typography>
          </Box>
        )}
      </Box>
    </React.Fragment>
  );
};

export default AllPolicyGovernance;
