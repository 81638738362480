import React, { useState, useEffect } from 'react';
import { Typography } from '@mui/material';
import { useLoading } from '../../../../hooks/useLoading';
import { useDataLayerValue } from '../../../../contextAPI/DataLayer';
import AddIcon from '@mui/icons-material/Add';
import { v4 as uuidv4 } from 'uuid';

import RemoveIcon from '@mui/icons-material/Remove';
import {
  IconButton,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper
} from '@mui/material';
import {
  getProjectsRequest,
  getHolidays
} from '../../../../constants/requests';
import { RowInp } from './RowInp';

export const CreateTimesheet = () => {
  const { request } = useLoading();
  const [projectData, setProjectData] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [dateArray, setDatearray] = useState([]);
  const [selectedProject, setSelectedProject] = useState('');
  const [excludedDates, setExcludedDates] = useState([]);
  const [{ _id }] = useDataLayerValue();
  const getProjectData = async () => {
    try {
      const response = await request(getProjectsRequest);
      // console.log(response);
      if (response.status === 200) {
        // console.log(response.data.body);
        response.data.body = response.data.body.filter(
          (project) =>
            project.projectName === 'Leave' ||
            project.projectWorkerIds.includes(_id)
        );
        response.data.body = response.data.body.map((project) => {
          return {
            ...project,
            startDate: new Date(project.startDate).toDateString(),
            endDate: new Date(project.endDate).toDateString()
          };
        });
        setProjectData([...response.data.body]);
        console.log(response.data.body[0].projectName);
        setSelectedProject(response.data.body[0].projectName);
      } else {
        console.log('some error happened');
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    document.title = `Create Timesheet`;
    getProjectData();
  }, []);
  useEffect(() => {
    console.log({ startDate, endDate });
    const sDate = new Date(startDate);
    const eDate = new Date(endDate);
    console.log({ excludedDates });
    let dateArray = [];
    for (var d = sDate; d <= eDate; d.setDate(d.getDate() + 1)) {
      if (
        excludedDates.find((date) => date.toDateString() === d.toDateString())
      ) {
        continue;
      }
      dateArray.push(d.toISOString());
    }
    setDatearray([...dateArray]);
  }, [startDate, endDate]);
  useEffect(() => {
    const initializeHolidays = async () => {
      const res = await request(getHolidays);
      const excludedDates = [];
      // const dateArray=[];
      for (const date of res.data.body) {
        for (const day of date.days) {
          excludedDates.push(new Date(date.year, date.month - 1, day));
        }
      }
      setExcludedDates([...excludedDates]);
    };
    initializeHolidays();
  }, []);
  useEffect(() => {
    console.log({ dateArray });
  }, [dateArray]);
  const [inputFields, setInputFields] = useState([
    { id: uuidv4(), key: '', val: '' }
  ]);
  const handleRemoveFields = (id) => {
    const values = [...inputFields];
    values.splice(
      values.findIndex((value) => value.id === id),
      1
    );
    setInputFields(values);
  };
  const [trigger, setTrigger] = useState(0);
  const handleAddFields = () => {
    setInputFields([...inputFields, { id: uuidv4(), key: '', val: '' }]);
  };
  return (
    <>
      <Typography sx={{ fontWeight: 'bold' }} variant="h4">
        Create Timesheet
      </Typography>
      <span style={{ paddingRight: '10px' }}>
        <span>Pick Start Date: </span>
        <input
          type="date"
          onChange={(e) => {
            setStartDate(e.target.value);
          }}
          name="startDate"
          id="startDate"
        />
      </span>
      <span style={{ paddingRight: '10px' }}>
        <span>Pick End Date: </span>
        <input
          type="date"
          onChange={(e) => {
            setEndDate(e.target.value);
          }}
          name="endDate"
          id="endDate"
        />
      </span>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left">Project</TableCell>
              <TableCell align="left">Task</TableCell>
              {dateArray.map((date) => {
                return <TableCell>{new Date(date).toDateString()}</TableCell>; // eslint-disable-line
              })}
              <TableCell align="center">Delete Row</TableCell>
              <TableCell align="center">Add Row</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {inputFields.map((field, idx) => (
              <TableRow key={`inputFields-${idx}`}>
                <RowInp
                  dateArray={dateArray}
                  projectData={projectData}
                  trigger={trigger}
                  defaultProject={selectedProject}
                ></RowInp>
                <TableCell align="center">
                  <IconButton
                    disabled={inputFields.length === 1}
                    onClick={() => handleRemoveFields(field.id)}
                  >
                    <RemoveIcon />
                  </IconButton>
                </TableCell>
                <TableCell align="center">
                  <IconButton onClick={handleAddFields}>
                    <AddIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Button
        variant="contained"
        disabled={trigger}
        onClick={() => setTrigger(1)}
      >
        Submit
      </Button>
    </>
  );
};
