import * as React from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import DeleteIcon from '@mui/icons-material/Delete';
import { SelectBox } from '../SelectBox';
import { removeHolidayRequest } from '../../constants/requests';
import { useNotifications } from '../../hooks/useNotifications';
import { useLoading } from '../../hooks/useLoading';

const monthsOfYear = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

export const HolidayList = ({
  holidays,
  month,
  year,
  setMonth,
  setYear,
  holidayAdmin,
  refresh,
  fname,
  lname,
  email
}) => {
  const { request } = useLoading();
  const { showNotifications } = useNotifications();

  const setMonthFromMonthString = (monthStr) => {
    // console.log(monthStr);
    setMonth(monthsOfYear.indexOf(monthStr) + 1);
  };

  const yearOptions = [];
  for (let i = year - 3; i <= year + 3; ++i) {
    yearOptions.push(i);
  }

  const deleteHoliday = async (day) => {
    // console.log(holidayDate);
    const reqBody = {
      empName: `${fname} ${lname}`,
      empEmail: email,
      day
    };
    try {
      const response = await request(() =>
        removeHolidayRequest(reqBody, month, year)
      );
      // console.log(response);
      if (response.data.statusCode === 200) {
        showNotifications('success', response.data.message, 5000);
        refresh();
      } else {
        showNotifications('error', response.data.message, 5000);
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
      console.log(err);
    }
  };

  return (
    <Box>
      <Grid item xs={12} md={6}>
        <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
          {/* {`${monthsOfYear[month - 1]}, ${year}`} */}
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'space-between',
              flexWrap: 'wrap',
              m: -1,
              maxWidth: 500
            }}
          >
            <SelectBox
              item={monthsOfYear[month - 1]}
              setItem={setMonthFromMonthString}
              itemList={monthsOfYear}
              label={'Month'}
            />
            <SelectBox
              item={year}
              setItem={setYear}
              itemList={yearOptions}
              label={'Year'}
            />
          </Box>
        </Typography>
        {holidayAdmin ? (
          <Box sx={{ maxWidth: 250 }}>
            <List dense={false}>
              {holidays.map((day, index) => {
                return (
                  <ListItem
                    secondaryAction={
                      holidayAdmin ? (
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          onClick={() => deleteHoliday(day)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      ) : null
                    }
                    key={index}
                  >
                    <ListItemText
                      primary={`${new Date(
                        year,
                        month - 1,
                        day
                      ).toDateString()}`}
                    />
                  </ListItem>
                );
              })}
            </List>
          </Box>
        ) : null}
      </Grid>
    </Box>
  );
};
