import React from 'react';
import { useEffect, useState } from 'react';
import { DEPARTMENTS_URL } from '../../../constants/urls';
import { useLoading } from '../../../hooks/useLoading';
import { HeaderWithCreateModal } from '../../../components/Master/HeaderWithCreateModal';
import { getDepartmentsRequest } from '../../../constants/requests';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { DepartmentHeaders } from '../../../constants/DepartmentHeaders';
import { useDataLayerValue } from '../../../contextAPI/DataLayer';
import { useNavigate } from 'react-router-dom';

const deptCreationFields = [
  {
    label: 'Department Name*',
    name: 'name',
    display: 'required',
    type: 'text'
  }
];

export const MasterDepartment = () => {
  const { request } = useLoading();
  const [departments, setDepartments] = useState([]);
  const [user] = useDataLayerValue();
  const { CREATE, VIEW_REPORT } = user.rights.Department;
  const navigate = useNavigate();
  if (VIEW_REPORT === false) {
    navigate('/');
  }

  const getDepartments = async () => {
    const deptResponse = await request(getDepartmentsRequest);
    setDepartments(deptResponse.data.body);
  };

  useEffect(() => {
    document.title = 'Departments';
    getDepartments();
  }, []);

  return (
    <>
      <HeaderWithCreateModal
        title="Departments"
        options={{ createOption: CREATE }}
        creationFields={deptCreationFields}
        reqUrl={DEPARTMENTS_URL}
        reqHeaders={{}}
      />
      <TableContainer
        component={Paper}
        sx={{ width: '100%', mt: 5, maxHeight: '70vh' }}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow key="head">
              {DepartmentHeaders.map((field) => {
                return (
                  // eslint-disable-next-line
                  <TableCell>
                    <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                      {field.label}
                    </Typography>
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {departments.map((department) => {
              return (
                <TableRow key={department._id}>
                  {DepartmentHeaders.map((field) => {
                    // eslint-disable-next-line
                    return <TableCell>{department[field.key]}</TableCell>;
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
