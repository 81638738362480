import React, { useState } from 'react';
import { Box, Button, Typography, TextField } from '@mui/material';
import { useLoading } from '../../../hooks/useLoading';
import { useNotifications } from '../../../hooks/useNotifications';
import { modalStyle } from '../../Services/common/styles';
import { generatePrompt, savePrompt } from '../../../constants/requests';

export default function PromptDialogue({ onCloseCB, open, handlePayloadData }) {
  if (!open) return null;
  const { request } = useLoading();
  const { showNotifications } = useNotifications();
  const [formFields, setFormFields] = useState([{ promptName: '' }]);
  const [process, setProcess] = useState('');
  const [specialist, setSpecialist] = useState('');
  const [isResponseGenerated, setIsResponseGenerated] = useState(false);
  const [jsonObject, setJsonObject] = useState(null);

  const addPrompt = () => {
    setFormFields([...formFields, { promptName: '' }]);
  };
  const handleSubmit = async () => {
    try {
      console.log(formFields.map((formField) => formField.promptName));
      const payload = {
        prompts: formFields.map((formField) => formField.promptName),
        process,
        specialist
      };
      const response = await request(() => generatePrompt(payload));

      if (response.data.statusCode === 200) {
        //setMarkdownText(`${response.data.body}`);
        setIsResponseGenerated(true);
        console.log(response);
        setJsonObject(response);
        showNotifications('success', response.data.message, 200);
      } else {
        showNotifications('error', response.data.message, 5000);
      }
    } catch (error) {
      showNotifications('error', error.toString(), 5000);
      console.log(error);
    }
  };

  const handleSave = async () => {
    try {
      const response = jsonObject.data.body;
      console.log(response);
      const payload = {
        specialist,
        process,
        prompt: formFields.map((formField) => formField.promptName),
        response
      };
      const res = await request(() => savePrompt(payload));

      if (res.data.statusCode === 200) {
        handlePayloadData(payload); //passing this to fill policy page
        //setMarkdownText(`${response.data.body}`);
        console.log(res);

        //setJsonObject(res);
        showNotifications('success', res.data.message, 200);
        setIsResponseGenerated(false);
      } else {
        showNotifications('error', res.data.message, 5000);
      }
    } catch (error) {
      showNotifications('error', error.toString(), 5000);
      console.log(error);
    }
  };

  if (isResponseGenerated)
    return (
      <Box sx={modalStyle}>
        <Typography
          variant="h5"
          component="h2"
          style={{ marginBottom: '20px' }}
        >
          Generated Response
        </Typography>
        <Box>
          {jsonObject &&
            jsonObject.data.body.map((item) => (
              <Box key={item.control_objective}>
                <h3>{item.control_objective}</h3>
                {item.control_activity.map((activityItem) => (
                  <Box key={activityItem.subprocess}>
                    <h4>{activityItem.subprocess}</h4>
                    <ul>
                      {activityItem.activity.map((activity) => (
                        <li key={activity}>{activity}</li>
                      ))}
                    </ul>
                  </Box>
                ))}
              </Box>
            ))}
        </Box>

        <Button
          variant="contained"
          style={{ marginTop: '10px', marginRight: '10px' }}
          onClick={handleSave}
        >
          Save
        </Button>
        <Button
          variant="contained"
          style={{ marginTop: '10px' }}
          onClick={handleSubmit}
        >
          Regenerate Response
        </Button>
      </Box>
    );
  return (
    <Box sx={modalStyle}>
      <Typography variant="h5" component="h2" style={{ marginBottom: '20px' }}>
        Create Prompt
      </Typography>
      <TextField
        label="Specialist"
        name={specialist}
        fullWidth
        style={{ marginBottom: '10px' }}
        onChange={(e) => {
          setSpecialist(e.target.value);
        }}
      />
      <TextField
        label="Process"
        name={process}
        fullWidth
        style={{ marginBottom: '10px' }}
        onChange={(e) => {
          setProcess(e.target.value);
        }}
      />
      {formFields.map((formField, index) => {
        return (
          <TextField
            key={index}
            label={`Prompt ${index + 1}`}
            name={formField.promptName}
            fullWidth
            style={{ marginBottom: '10px' }}
            onChange={(e) => {
              formField.promptName = e.target.value;
              setFormFields([...formFields]);
            }}
          />
        );
      })}
      <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button
          variant="contained"
          style={{ marginTop: '10px' }}
          onClick={handleSubmit}
        >
          Generate Response
        </Button>
        <Button
          variant="contained"
          style={{ marginTop: '10px' }}
          onClick={addPrompt}
        >
          Add Prompt
        </Button>
        <Button
          variant="contained"
          style={{ marginTop: '10px' }}
          onClick={onCloseCB}
        >
          Close
        </Button>
      </Box>
    </Box>
  );
}
