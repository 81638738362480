import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { employees } from '../../../constants/EmployeeDataPairs';
import { clients } from '../../../constants/clientDataPairs';
import { Form } from '../../Form';
import { BACKEND_URL } from '../../../config';
import axios from 'axios';
import { Button } from '@mui/material';
import { useNotifications } from '../../../hooks/useNotifications';
import { useLoading } from '../../../hooks/useLoading';
import { useDataLayerValue } from '../../../contextAPI/DataLayer';

const styles = {
  heading: {
    textAlign: 'center'
  },
  container: {
    marginTop: '50px',
    padding: '43px',
    backgroundColor: '#fafafa',
    boxShadow: '5px 10px 18px #888888'
  },
  table: {
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  key2: {},
  key: {
    fontWeight: 'bold',
    textAlign: 'left',
    fontSize: '1.2rem',
    marginBottom: '10px'
  },
  value: { textAlign: 'left', paddingLeft: '250px', fontSize: '1.1rem' }
};

export const nonAdminFields = [
  {
    label: 'Add link for Qualification Documents',
    name: 'qualificationDocuments',
    display: 'required',
    type: 'text'
  },
  {
    label: 'Tags',
    name: 'tags',
    display: 'required',
    type: 'text'
  },
  {
    label: 'About Yourself',
    name: 'about',
    display: 'required',
    type: 'text'
  }
];

function EntityData() {
  const location = useLocation();
  const [{ _id, fname, lname, email }] = useDataLayerValue();
  // eslint-disable-next-line
  const [updated, setUpdated] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const { request } = useLoading();
  const { showNotifications } = useNotifications();
  const { vals, editOption } = location.state;
  const toggleUpdate = () => {
    console.log('Toggling');
    setUpdated((prevUpdated) => !prevUpdated);
  };

  const handleEditOpen = async () => {
    const link = BACKEND_URL + `/employee/status/${vals._id}`;
    const res = await request(() =>
      axios.get(link, {
        headers: {
          employeeid: _id
        }
      })
    );
    if (res.data.statusCode !== 200) {
      showNotifications('error', 'Unable to Edit Item', 5000);
    } else {
      nonAdminFields.map((field) => {
        if (field.name in res.data.body) {
          field.value = res.data.body[field.name];
        }
        return field;
      });
      setOpenEdit(true);
    }
  };

  const handleEditClose = () => {
    setOpenEdit(false);
    toggleUpdate();
  };

  let entities, type;
  if (vals !== undefined) {
    entities = vals.employmentType !== undefined ? employees : clients;
    type = vals.employmentType !== undefined ? 'Employee' : 'Client';
  }
  return (
    <>
      <div>
        {vals === undefined ? (
          <div>No Data to Show</div>
        ) : (
          <div style={styles.heading}>
            <h2>Details for {type}</h2>
            <div style={styles.container}>
              {editOption && (
                <Button sx={{ fontSize: '1.2rem' }} onClick={handleEditOpen}>
                  EDIT DETAILS
                </Button>
              )}
              <table style={styles.table}>
                <tbody>
                  {Object.keys(vals).map(function (keyName, keyIndex) {
                    // use keyName to get current key's name
                    // and a[keyName] to get its value
                    return (
                      <tr key={keyIndex} style={styles.row}>
                        <td style={styles.key}>
                          {entities[keyName] !== undefined && entities[keyName]}
                        </td>
                        <td style={styles.value}>
                          {entities[keyName] !== undefined && vals[keyName]}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
      {editOption && (
        <Form
          title="Edit Details"
          url={BACKEND_URL + `/employee/update/${vals._id}`}
          open={openEdit}
          handleClose={handleEditClose}
          fields={nonAdminFields}
          type="patch"
          headers={{
            employeeid: _id,
            empname: `${fname} ${lname}`,
            empemail: email
          }}
        />
      )}
    </>
  );
}

export default EntityData;
