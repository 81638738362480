import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useNavigate, Link } from 'react-router-dom';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import { headers } from '../../../constants/WorkflowHandlerHeaders';
import axios from 'axios';
import { useDataLayerValue } from '../../../contextAPI/DataLayer';
import { BACKEND_URL } from '../../../config';

function WorkflowHandler() {
  const navigate = useNavigate();

  const [workflows, setWorkflows] = useState([]);
  const [user] = useDataLayerValue();
  const { _id } = user;
  const { VIEW_REPORT } = user.rights.WorkflowType;
  if (VIEW_REPORT === false) {
    navigate('/');
  }
  useEffect(() => {
    document.title = 'Workflow Handlers';
    axios
      .get(BACKEND_URL + '/workflow-handler/', {
        headers: {
          employeeid: _id
        }
      })
      .then((res) => setWorkflows(res.data.body))
      .catch((er) => console.log(er));
  }, []);

  return (
    <React.Fragment>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
          m: -1
        }}
      >
        <Typography sx={{ m: 1, fontWeight: 'bold' }} variant="h4">
          Workflow Handlers
        </Typography>
      </Box>
      <TableContainer
        component={Paper}
        sx={{ width: '100%', mt: 5, maxHeight: '70vh' }}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow key="head">
              {headers.map((field, idx) => {
                return (
                  <TableCell key={idx} align="center">
                    <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                      {field.label}
                    </Typography>
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {workflows.map((workflow) => {
              return (
                <TableRow key={workflow._id}>
                  {headers.map((field, idx) => {
                    return field.key === 'viewDetails' ? (
                      <TableCell key={idx} align="center">
                        <Link to={'/workflow/handler/' + workflow['_id']}>
                          View Details
                        </Link>
                      </TableCell>
                    ) : (
                      <TableCell key={idx} align="center">
                        {workflow[field.key]}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </React.Fragment>
  );
}

export default WorkflowHandler;
