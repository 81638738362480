import * as React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

export default function PaymentForm({
  handleBack,
  handleNext,
  handleChange,
  employeeDetails
}) {
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Salary Information
      </Typography>
      <Box component="form" onSubmit={handleNext} sx={{ mt: 3 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <TextField
              required
              id="ctc"
              name="ctc"
              label="CTC Amount"
              fullWidth
              autoComplete="ctc"
              variant="standard"
              onChange={handleChange}
              value={employeeDetails.ctc}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              id="grossSalary"
              required
              name="grossSalary"
              label="Gross Salary"
              fullWidth
              variant="standard"
              onChange={handleChange}
              value={employeeDetails.grossSalary}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              id="baseSalary"
              required
              name="baseSalary"
              label="Base Salary"
              fullWidth
              variant="standard"
              onChange={handleChange}
              value={employeeDetails.baseSalary}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              id="HRA"
              required
              name="HRA"
              label="HRA"
              fullWidth
              variant="standard"
              onChange={handleChange}
              value={employeeDetails.HRA}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              id="medicalAllowance"
              required
              name="medicalAllowance"
              label="Medical Allowance"
              fullWidth
              variant="standard"
              onChange={handleChange}
              value={employeeDetails.medicalAllowance}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              id="conveyanceAllowance"
              required
              name="conveyanceAllowance"
              label="Conveyance Allowance"
              fullWidth
              variant="standard"
              onChange={handleChange}
              value={employeeDetails.conveyanceAllowance}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              id="specialAllowance"
              required
              name="specialAllowance"
              label="Special Allowance"
              fullWidth
              variant="standard"
              onChange={handleChange}
              value={employeeDetails.specialAllowance}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              id="PFContribution"
              required
              name="PFContribution"
              label="PF Contribution"
              fullWidth
              variant="standard"
              onChange={handleChange}
              value={employeeDetails.PFContribution}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              id="ESIContribution"
              required
              name="ESIContribution"
              label="ESI Contribution"
              fullWidth
              variant="standard"
              onChange={handleChange}
              value={employeeDetails.ESIContribution}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              id="PTDeductions"
              required
              name="PTDeduction"
              label="PT Deductions"
              fullWidth
              variant="standard"
              onChange={handleChange}
              value={employeeDetails.PTDeduction}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              id="totalDeductions"
              required
              name="totalDeductions"
              label="Total Deductions"
              fullWidth
              variant="standard"
              onChange={handleChange}
              value={employeeDetails.totalDeductions}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              id="netSalary"
              required
              name="netSalary"
              label="Net Salary"
              fullWidth
              variant="standard"
              onChange={handleChange}
              value={employeeDetails.netSalary}
            />
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button onClick={handleBack} sx={{ mt: 3, ml: 1 }}>
                Back
              </Button>

              <Button type="submit" variant="contained" sx={{ mt: 3, ml: 1 }}>
                {'Next'}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  );
}
