export const rightsFields = [
  {
    label: 'Role',
    name: 'newRole',
    display: 'required',
    type: 'dropdown',
    value: [
      {
        name: 'L1'
      },
      {
        name: 'L2'
      },
      {
        name: 'L3'
      },
      {
        name: 'L4'
      },
      {
        name: 'L5'
      },
      {
        name: 'Auditor'
      },
      {
        name: 'Administrator'
      }
    ],
    info: ['name', 'name']
  },
  {
    label: 'Valid To',
    name: 'validTo',
    display: 'required',
    type: 'date'
  },
  {
    label: 'Previous Role',
    name: 'role',
    display: 'hidden',
    type: 'text'
  }
];
