import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import moment from 'moment';
import './index.css';

import {
  Box,
  Button,
  TextField,
  MenuItem,
  Table,
  TableCell,
  tableCellClasses,
  TableHead,
  TableRow,
  TableContainer
} from '@mui/material';

import Paper from '@mui/material/Paper';
import {
  getSamplesBySampleId,
  updateSamples,
  readMsgFile
} from '../../../constants/requests';

import { useLoading } from '../../../hooks/useLoading';
import { useNotifications } from '../../../hooks/useNotifications';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import ModalTable from '../common/ModalTable';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import GetTableCell from '../common/GetTableCell';
import { styled } from '@mui/material/styles';
import Highlighter from 'react-highlight-words';
import EvidenceModal from './EvidenceModal';

function Evidences() {
  const navigate = useNavigate();
  const { request } = useLoading();
  const { showNotifications } = useNotifications();
  const { engagementId, applicationId, sampleId } = useParams();
  const [sample, setSample] = useState(null);
  const [type, setType] = useState(null);
  const [currentTabName, setCurrentTabName] = useState(null);
  const [file, setFile] = useState(null);
  const [originalEmails, setOriginalEmails] = useState({});
  const [filteredEmails, setFilteredEmails] = useState({});
  const [attachements, setAttachements] = useState({});
  const [attachementContent, setAttachementContent] = useState({});
  const [identifier, setIdentifier] = useState(null);
  const [names, setNames] = useState(null);
  const [headers, setHeaders] = useState([]);
  const [filter1, setFilter1] = useState('');
  const [filter2, setFilter2] = useState('');
  const [filter3, setFilter3] = useState('');
  const [filter4, setFilter4] = useState('');
  const [filter5, setFilter5] = useState('');
  const [filter6, setFilter6] = useState('');

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14
    },
    padding: '0px 5px 0px 5px'
  }));

  const getSample = async () => {
    try {
      const res = await request(() => getSamplesBySampleId(sampleId));
      if (res.data.statusCode === 200) {
        const sampleTable = res.data.body.data;
        const sampleType = res.data.body.fileType;
        let identifier = Object.keys(sampleTable[0])[0];
        setIdentifier(identifier);
        let sortedSample = sampleTable.sort((p1, p2) =>
          p1[identifier] > p2[identifier]
            ? 1
            : p1[identifier] < p2[identifier]
            ? -1
            : 0
        );
        setSample(sortedSample);
        setType(sampleType);

        // console.log(sortedSample);

        let tableHeaders;
        if (sampleType === 'E') {
          tableHeaders = [
            'Change Approved By',
            'Date of approval',
            'Change tested by (UAT)',
            'UAT sign off date',
            'Pre migration approval by',
            'Pre migration approval date',
            'Designation of approver',
            'Designation of pre migration approver',
            'Conclusion (Effective/Ineffective)',
            'Comment'
          ];
        } else {
          tableHeaders = [
            'Access Approved By',
            'Date of Approval',
            'Designation of approver',
            'Conclusion (Effective/Ineffective)',
            'Comment'
          ];
        }

        setHeaders(tableHeaders);

        // console.log

        const newNames = {};
        if (sortedSample) {
          sortedSample.map((row) => {
            let obj = {};
            tableHeaders.map((header) => {
              if (row[header]) {
                obj[header] = row[header];
              } else {
                obj[header] = '';
              }
            });
            newNames[row[identifier]] = obj;
            // console.log("row", row);
            // console.log("object", obj);
          });

          // console.log(newNames);

          setNames(newNames);
          // console.log(names);
        }

        showNotifications('success', res.data.message, 5000);
      } else {
        showNotifications('error', res.data.message, 5000);
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  const handleFileSelect = (e, id) => {
    const newFile = e.target.files[0];

    if (
      newFile &&
      (newFile.name.endsWith('.eml') || newFile.name.endsWith('.msg'))
    ) {
      setFile((file) => ({
        ...file,
        ...{ [id]: e.target.files[0] }
      }));
    } else {
      setFile(null);
      showNotifications('error', 'Upload eml or msg files', 5000);
    }

    // console.log(e.target.files[0])
  };

  const processEmails = (object, id) => {
    // console.log(emails, id);
    const emails = object.emails;
    const obj = { [id]: emails };
    const attch_obj = { [id]: object.attachements };
    const attch_cont_obj = { [id]: object.attachements_content };
    setOriginalEmails((originalEmails) => ({
      ...originalEmails,
      ...obj
    }));
    setFilteredEmails((filteredEmails) => ({
      ...filteredEmails,
      ...obj
    }));
    setAttachements((attachements) => ({
      ...attachements,
      ...attch_obj
    }));
    setAttachementContent((attachementContent) => ({
      ...attachementContent,
      ...attch_cont_obj
    }));
  };

  const MailSender = (email) => {
    const lines = email.split('\n');
    return lines[0];
  };

  const DateOfMail = (email) => {
    const lines = email.split('\n');
    let dateString = lines[1];
    for (let i = 0; i < lines.length; i++) {
      if (lines[i].search('sent:') !== -1) {
        dateString = lines[i];
        break;
      }
    }
    dateString = dateString.slice(6);
    const format = 'DD MMMM YYYY HH:mm';
    const date = moment(dateString, format);
    return [date, dateString];
  };

  const filterEmails = (id) => {
    let newFilteredEmails = [];
    if (!originalEmails[id]) {
      return;
    }
    for (const email of originalEmails[id]) {
      const lowerCaseEmail = email.toLowerCase();
      const momentFromDate = moment(filter5);
      const momentToDate = moment(filter6);
      const mailDate = DateOfMail(lowerCaseEmail)[0];

      if (filter1.length !== 0) {
        if (lowerCaseEmail.search(filter1.toLowerCase()) === -1) continue;
      }

      if (filter2.length !== 0) {
        if (lowerCaseEmail.search(filter2.toLowerCase()) === -1) continue;
      }

      if (filter3.length !== 0) {
        if (lowerCaseEmail.search(filter3.toLowerCase()) === -1) continue;
      }

      if (filter4.length !== 0) {
        if (MailSender(lowerCaseEmail).search(filter4.toLowerCase()) === -1)
          continue;
      }

      if (
        mailDate.isValid() &&
        momentFromDate.isValid() &&
        momentToDate.isValid()
      ) {
        if (
          mailDate.diff(momentFromDate) < 0 ||
          mailDate.diff(momentToDate) > 0
        )
          continue;
      }

      newFilteredEmails.push(email);
    }

    setFilteredEmails((filteredEmails) => ({
      ...filteredEmails,
      ...{ [id]: newFilteredEmails }
    }));
  };

  const FillNames = (email, headerId1, headerId2, id) => {
    const nameField = headers[headerId1];
    const DateField = headers[headerId2];
    let newObj = {
      ...names[id],
      ...{ [nameField]: MailSender(email), [DateField]: DateOfMail(email)[1] }
    };
    const obj = { [id]: newObj };
    setNames((names) => ({
      ...names,
      ...obj
    }));
  };

  const removeFilter = (id) => {
    setFilteredEmails((filteredEmails) => ({
      ...filteredEmails,
      ...{ [id]: originalEmails[id] }
    }));

    setFilter1('');
    setFilter2('');
    setFilter3('');
    setFilter4('');
    setFilter5('');
    setFilter6('');
  };

  const handleSave = async () => {
    try {
      const newRows = [];

      for (const row of sample) {
        newRows.push({
          ...row,
          ...names[row[identifier]]
        });
      }

      const payload = {
        data: newRows
      };

      const res = await request(() => updateSamples(sampleId, payload));
      if (res.data.statusCode === 200) {
        showNotifications('success', res.data.message, 5000);
      } else {
        showNotifications('error', res.data.message, 5000);
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  const handleChange = async (id, field, value) => {
    let newObj = Object.assign(names);
    newObj[id][field] = value;
    setNames(newObj);
    // console.log(names);
  };

  const sendFile = async (id) => {
    if (!file) {
      showNotifications('error', 'Upload a msg/eml File', 5000);
    } else {
      const form = new FormData();
      form.append('file', file[id]);
      try {
        await request(() => readMsgFile(form)).then((res) => {
          processEmails(res.data.emailObject, id);
        });
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    getSample();
  }, []);

  return (
    <>
      <Box
        style={{
          marginBottom: '25px',
          display: 'flex',
          justifyContent: 'space-between'
        }}
      >
        <Button
          sx={{
            color: 'white',
            backgroundColor: 'black'
          }}
          variant="contained"
          onClick={() =>
            navigate(
              `/services/engagement/${engagementId}/application/${applicationId}/drl`
            )
          }
        >
          <ChevronLeftIcon />
          DRL
        </Button>
      </Box>
      <TextField
        select
        label="Select Sample No."
        value={currentTabName}
        onChange={(e) => setCurrentTabName(e.target.value)}
        style={{ minWidth: '200px', marginBottom: '20px' }}
        size="small"
      >
        {sample &&
          sample.map((row) => (
            <MenuItem key={row[identifier]} value={row[identifier]}>
              {row[identifier]}
            </MenuItem>
          ))}
      </TextField>
      {sample ? <ModalTable data={sample} /> : null}

      <Box
        sx={{
          display: 'flex',
          marginTop: '20px',
          justifyContent: 'flex-end'
        }}
      >
        <Button
          variant="contained"
          color="success"
          onClick={() => handleSave()}
        >
          Save
        </Button>
        {/* <Button variant="contained" sx={{ marginLeft: '10px' }} onClick={() => handleDownload()} >Download</Button> */}
      </Box>
      <Paper sx={{ width: '100%', overflow: 'hidden', marginTop: '20px' }}>
        <TableContainer sx={{ maxHeight: 350, marginBottom: '20px' }}>
          <Table
            stickyHeader
            sx={{ minWidth: 700 }}
            aria-label="customized table"
          >
            <TableHead>
              <TableRow>
                <StyledTableCell
                  align="left"
                  style={{
                    minWidth: '100px'
                  }}
                >
                  {identifier}
                </StyledTableCell>
                {headers.length > 0 &&
                  headers.map((header) => {
                    return (
                      <StyledTableCell
                        key={`Table-${header}`}
                        align="left"
                        style={{
                          minWidth: '100px'
                        }}
                      >
                        {header}
                      </StyledTableCell>
                    );
                  })}
              </TableRow>
            </TableHead>
            {names !== null &&
              Object.keys(names).map((Sno, idx) => {
                return (
                  <TableRow key={`Row-${idx}`}>
                    <StyledTableCell
                      key={`cell-SNo-${idx}`}
                      style={{
                        minWidth: '100px',
                        minHeight: '0px',
                        padding: '10px'
                      }}
                      align="left"
                    >
                      {Sno}
                    </StyledTableCell>
                    {Object.keys(names[Sno]).map((key, index) => {
                      return (
                        <StyledTableCell
                          key={`cell-${key}-${index}`}
                          align="left"
                          style={{
                            minWidth: '100px',
                            minHeight: '0px',
                            padding: '10px'
                          }}
                        >
                          <GetTableCell
                            isEditable={true}
                            value={names[Sno][key]}
                            fieldType="multiLineTextField"
                            rowNumber={Sno}
                            fieldName={key}
                            onChangeCB={(rowNumber, fieldType, cellValue) =>
                              handleChange(rowNumber, fieldType, cellValue)
                            }
                            styles={{ minWidth: '200px' }}
                          />
                        </StyledTableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </Table>
        </TableContainer>
      </Paper>
      <TabContext value={currentTabName}>
        {sample &&
          sample.map((row) => {
            return (
              <TabPanel
                key={`Evidences ${row[identifier]}`}
                value={row[identifier]}
                index={row[identifier]}
                sx={{ padding: '3px', marginTop: '15px' }}
              >
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Box
                    sx={{
                      // display: 'flex',
                      alignSelf: 'center',
                      margin: '10px',
                      gap: '10px'
                    }}
                  >
                    <TextField
                      label="Select Evidence"
                      name="Evidence"
                      type="file"
                      onChange={(e) => handleFileSelect(e, row[identifier])}
                      InputLabelProps={{ shrink: true }}
                    />

                    <Button
                      sx={{ margin: '10px' }}
                      variant="contained"
                      color="success"
                      onClick={() => {
                        sendFile(row[identifier]);
                      }}
                    >
                      Submit
                    </Button>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      margin: '10px 0',
                      gap: '10px'
                    }}
                  >
                    <TextField
                      fullWidth
                      label="Word Filter1"
                      name="Filter1"
                      value={filter1}
                      onChange={(e) => {
                        setFilter1(e.target.value);
                      }}
                      InputLabelProps={{ shrink: true }}
                      size="small"
                    />
                    <TextField
                      fullWidth
                      label="Word Filter2"
                      name="Filter2"
                      value={filter2}
                      onChange={(e) => {
                        setFilter2(e.target.value);
                      }}
                      InputLabelProps={{ shrink: true }}
                      size="small"
                    />
                    <TextField
                      fullWidth
                      label="Word Filter3"
                      name="Filter3"
                      value={filter3}
                      onChange={(e) => {
                        setFilter3(e.target.value);
                      }}
                      InputLabelProps={{ shrink: true }}
                      size="small"
                    />
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      margin: '10px 0',
                      gap: '10px'
                    }}
                  >
                    <TextField
                      fullWidth
                      label="Name/email filter"
                      name="Filter4"
                      value={filter4}
                      onChange={(e) => {
                        setFilter4(e.target.value);
                      }}
                      InputLabelProps={{ shrink: true }}
                      size="small"
                    />
                    <TextField
                      fullWidth
                      label="Time Filter From"
                      name="Filter5"
                      type="datetime-local"
                      value={filter5}
                      onChange={(e) => {
                        setFilter5(e.target.value);
                      }}
                      sx={{ marginRight: '5px' }}
                      InputLabelProps={{ shrink: true }}
                      size="small"
                    />
                    <TextField
                      fullWidth
                      label="Time Filter To"
                      name="Filter6"
                      type="datetime-local"
                      value={filter6}
                      onChange={(e) => {
                        setFilter6(e.target.value);
                      }}
                      InputLabelProps={{ shrink: true }}
                      size="small"
                    />
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      margin: '10px 0',
                      gap: '10px'
                    }}
                  >
                    <Button
                      variant="contained"
                      onClick={() => {
                        filterEmails(row[identifier]);
                      }}
                    >
                      Filter
                    </Button>
                    <Button
                      variant="contained"
                      color="error"
                      onClick={() => {
                        removeFilter(row[identifier]);
                      }}
                      sx={{ outline: 'none', marginLeft: '5px' }}
                    >
                      Remove Filter
                    </Button>
                  </Box>
                  <Box>
                    {attachements[row[identifier]] && <h5>Attachements</h5>}
                    <Box
                      style={{
                        display: 'flex',
                        marginBottom: '10px',
                        justifyContent: 'space-around',
                        fontSize: '16px',
                        fontFamily: 'bold',
                        flexWrap: 'wrap'
                      }}
                    >
                      {attachements[row[identifier]] &&
                        attachements[row[identifier]].map(
                          (attachement, idx) => {
                            return (
                              <Box
                                style={{ minWidth: '200px' }}
                                key={`Attachement Box - ${idx} - ${row[identifier]} `}
                              >
                                {/* {attachement} */}
                                <EvidenceModal
                                  fileName={attachement}
                                  data={
                                    attachementContent[row[identifier]][
                                      attachement
                                    ]
                                      ? attachementContent[row[identifier]][
                                          attachement
                                        ]
                                      : 'Content is displayed only for pdf files'
                                  }
                                />
                              </Box>
                            );
                          }
                        )}
                    </Box>
                  </Box>
                  <Box>
                    {filteredEmails[row[identifier]] && (
                      <h5>
                        Mail Count {filteredEmails[row[identifier]].length}
                      </h5>
                    )}
                  </Box>
                  <Box>
                    {filteredEmails[row[identifier]] &&
                      filteredEmails[row[identifier]].map((email, idx) => {
                        return (
                          <Box
                            key={`Mail Box - ${idx} - ${row[identifier]} `}
                            sx={{
                              padding: '10px',
                              border: '2px solid #1976D2',
                              borderRadius: '4px',
                              whiteSpace: 'pre-line',
                              marginBottom: '5px'
                            }}
                          >
                            <Box>
                              <Highlighter
                                highlightClassName="highlight"
                                searchWords={[
                                  filter1,
                                  filter2,
                                  filter3,
                                  filter4
                                ]}
                                caseSensitive={false}
                                textToHighlight={email}
                                sx={{ backgroundColor: 'blue' }}
                              />
                            </Box>
                            <Button
                              variant="contained"
                              sx={{ marginLeft: '5px' }}
                              onClick={() => {
                                FillNames(email, 0, 1, row[identifier]);
                              }}
                            >
                              Change Approval
                            </Button>
                            <Button
                              variant="contained"
                              sx={
                                type === 'E'
                                  ? { marginLeft: '5px' }
                                  : { display: 'none' }
                              }
                              onClick={() => {
                                FillNames(email, 2, 3, row[identifier]);
                              }}
                            >
                              UAT-SIGNOFF
                            </Button>
                            <Button
                              variant="contained"
                              sx={
                                type === 'E'
                                  ? { marginLeft: '5px' }
                                  : { display: 'none' }
                              }
                              onClick={() => {
                                FillNames(email, 4, 5, row[identifier]);
                              }}
                            >
                              Pre Migration Approval
                            </Button>
                          </Box>
                        );
                      })}
                  </Box>
                </Box>
              </TabPanel>
            );
          })}
      </TabContext>
    </>
  );
}

export default Evidences;
