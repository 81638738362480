import React, { useState } from 'react';

import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Modal } from '../Modal';
import { FormWithNoModal } from '../FormWithNoModal';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

export default function TabForm({ open, handleClose, forms, headers }) {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Modal open={open} handleClose={handleClose}>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            {forms.map((e, index) => {
              return (
                <Tab
                  key={index}
                  label={forms[index].title}
                  {...a11yProps(index)}
                />
              );
            })}
          </Tabs>
        </Box>
        {forms.map((e, index) => {
          return (
            <CustomTabPanel value={value} index={index} key={index}>
              <FormWithNoModal
                title={forms[index].title}
                url={forms[index].url}
                fields={forms[index].fields}
                type={forms[index].type}
                onSubmit={forms[index].onSubmit}
                headers={headers}
              />
            </CustomTabPanel>
          );
        })}
      </Box>
    </Modal>
  );
}
