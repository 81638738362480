import { getRolesRequest } from '../../../constants/requests';
import { useDataLayerValue } from '../../../contextAPI/DataLayer';
import { useLoading } from '../../../hooks/useLoading';

export const getRole = async () => {
  const { request } = useLoading();
  const [{ roleId }] = useDataLayerValue();
  try {
    const res = await request(() => getRolesRequest());
    if (res.data.statusCode === 200) {
      const role = res.data.body.filter((roles) => roles._id === roleId);
      if (role[0].name === 'Administrator') {
        return 'ADMIN';
      }
    } else {
      return '';
    }
  } catch (err) {
    return '';
  }
};
