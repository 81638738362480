import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { modalStyle } from '../common/styles';
import { useLoading } from '../../../hooks/useLoading';
import { useNotifications } from '../../../hooks/useNotifications';

/**
 * Props for DeleteDialog
 *
 * entityName: name to show in modal heading and submit button,
 * entityId: entityId to delete,
 * deleteRequest: Request to be called to delete an entity having id as entityId
 * onCloseCB: callback for closing the modal,
 * refreshPageContentCB: callback of parent component to refresh its content when the modal closes
 * customDeleteCB?: custom callback function for deletion
 */

export default function DeleteDialog({
  entityName,
  deleteRequest,
  entityId,
  onCloseCB,
  customDeleteCB,
  refreshPageContentCB
}) {
  const { request } = useLoading();
  const { showNotifications } = useNotifications();

  const handleDeleteEntity = async () => {
    if (customDeleteCB) {
      customDeleteCB();
      return;
    }
    try {
      const response = await request(() => deleteRequest(entityId));
      if (response.data.statusCode === 200) {
        onCloseCB();
        refreshPageContentCB();
      } else {
        showNotifications('error', response.data.message, 5000);
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
      console.log(err);
    }
  };

  return (
    <Box sx={modalStyle}>
      <Typography variant="h5" component="h2" style={{ marginBottom: '20px' }}>
        Delete {entityName}
      </Typography>
      <Typography id="modal-modal-description" style={{ marginBottom: '20px' }}>
        Are you sure you want to delete this {entityName}? Note: This action is
        irreversible
      </Typography>
      <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button
          variant="contained"
          color="error"
          style={{ marginTop: '10px' }}
          onClick={handleDeleteEntity}
        >
          Delete {entityName}
        </Button>
        <Button
          variant="contained"
          style={{ marginTop: '10px' }}
          onClick={onCloseCB}
        >
          Close
        </Button>
      </Box>
    </Box>
  );
}
