import React from 'react';
import { Box } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';

export default function CustomGuideTable({ rows, tableMeta, fieldsMeta }) {
  let columns = Object.keys(tableMeta).map((key) => {
    return {
      field: key,
      headerName: tableMeta[key]['isEditable']
        ? tableMeta[key]['displayName'] + '*'
        : tableMeta[key]['displayName'],
      editable: tableMeta[key]['isEditable'],
      type:
        fieldsMeta[key]['fieldType'] === 'dropDown'
          ? 'singleSelect'
          : fieldsMeta[key]['fieldType'],
      valueOptions:
        fieldsMeta[key]['fieldType'] === 'dropDown'
          ? fieldsMeta[key]['options']
          : '',
      width: fieldsMeta[key]['fieldType'] === 'multiLineTextField' ? 500 : 200,
      valueGetter:
        fieldsMeta[key]['fieldType'] === 'date'
          ? ({ value }) => value && new Date(value)
          : null
    };
  });

  return (
    <>
      <Box
        sx={{
          height: 600,
          width: '100%'
        }}
      >
        <DataGrid
          sx={{
            boxShadow: 2,
            '& .MuiDataGrid-cell:hover': {
              color: 'primary.main'
            },
            '& .MuiDataGrid-columnHeaders': {
              backgroundColor: '#5A5A5A',
              color: 'white',
              fontSize: 16
            }
          }}
          rows={rows}
          columns={columns}
          getRowId={() => uuidv4()}
          slots={{
            toolbar: GridToolbar
          }}
          getRowHeight={() => 'auto'}
          getEstimatedRowHeight={() => 300}
          disableRowSelectionOnClick
          unstable_ignoreValueFormatterDuringExport
          experimentalFeatures={{ clipboardPaste: true }}
        />
      </Box>
    </>
  );
}
