import React from 'react';
import { useNavigate } from 'react-router-dom';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Box, Button } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Threat from './Library/Threat';
import Vulnerability from './Library/Vulnerability';
import Control from './Library/Control';

const LibraryMaster = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(-1);
  };

  return (
    <React.Fragment>
      <Box
        style={{
          marginBottom: '25px',
          display: 'flex',
          justifyContent: 'space-between'
        }}
      >
        <Button
          sx={{
            color: 'white',
            backgroundColor: 'black',
            flexDirection: 'end',
            marginBottom: '10px'
          }}
          variant="contained"
          onClick={handleClick}
        >
          <ChevronLeftIcon />
          Threat
        </Button>
      </Box>
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '30px'
        }}
      >
        <Typography sx={{ fontWeight: 'bold', flexGrow: 1 }} variant="h4">
          Library Master
        </Typography>
      </Box>

      <Accordion
        sx={{ backgroundColor: 'rgba(224, 224, 224, 1)', borderRadius: '10px' }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography sx={{ fontWeight: 'bold', fontSize: '30px' }}>
            Threat
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ marginBottom: '20px' }}>
          <Threat />
        </AccordionDetails>
      </Accordion>
      <Accordion
        sx={{ backgroundColor: 'rgba(224, 224, 224, 1)', borderRadius: '10px' }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography sx={{ fontWeight: 'bold', fontSize: '30px' }}>
            Vulnerability
          </Typography>
        </AccordionSummary>

        <AccordionDetails sx={{ marginBottom: '20px' }}>
          <Vulnerability />
        </AccordionDetails>
      </Accordion>
      <Accordion
        sx={{ backgroundColor: 'rgba(224, 224, 224, 1)', borderRadius: '10px' }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography sx={{ fontWeight: 'bold', fontSize: '30px' }}>
            Control
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ marginBottom: '20px' }}>
          <Control />
        </AccordionDetails>
      </Accordion>
    </React.Fragment>
  );
};

export default LibraryMaster;
