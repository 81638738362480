import React, { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import { Button, Typography } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { useNavigate, useParams } from 'react-router-dom';
import EditQuestionTable from '../Components/EditQuestionTable';
import { getPolicyTemplateById } from '../../../constants/requests';
import { useLoading } from '../../../hooks/useLoading';
import { useNotifications } from '../../../hooks/useNotifications';

const EditPolicyTemplate = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { request } = useLoading();
  const { showNotifications } = useNotifications();

  const [possible, setPossible] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [error, setError] = useState(false);

  const [policyName, setPolicyName] = useState('');
  const [policyType, setPolicyType] = useState('');
  const [policyTemplateTypes, setPolicyTemplateTypes] = useState([]);

  const getSelectedPolicy = async () => {
    try {
      const response = await request(() => getPolicyTemplateById(params.id));
      if (response?.data?.statusCode === 200) {
        let tagsForCurrentPolicy = JSON.parse(response.data.body.tags);
        const newTableData = tagsForCurrentPolicy.map((tag) => {
          return {
            Tag: tag.Tag,
            Question: tag.Question,
            Type: 'tag'
          };
        });
        setPolicyName(response.data.body.name);
        setPolicyType(response.data.body.type);
        setTableData(newTableData);
        setPossible(true);
      } else {
        if (response.response.data.statusCode !== 200) {
          showNotifications('error', response.response.data.message, 5000);
        }
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  useEffect(() => {
    getSelectedPolicy();
    setError(false);
    setPossible(false);
  }, []);

  return (
    <React.Fragment>
      <Box
        style={{
          marginBottom: '25px',
          display: 'flex',
          justifyContent: 'space-between'
        }}
      >
        <Button
          sx={{
            color: 'white',
            backgroundColor: 'black',
            paddingLeft: 1
          }}
          variant="contained"
          onClick={() => navigate(`/policy`)}
        >
          <ChevronLeftIcon />
          Back
        </Button>
      </Box>

      <Box
        style={{
          display: 'flex',
          justifyContent: 'start',
          alignItems: 'center',
          marginBottom: '30px'
        }}
      >
        <Typography sx={{ fontWeight: 'bold', flexGrow: 1 }} variant="h4">
          Edit Policy Template
        </Typography>
      </Box>

      {error && (
        <Typography variant="h3" sx={{ color: 'red' }}>
          There is some error in the file you just submitted.
        </Typography>
      )}
      {possible && (
        <EditQuestionTable
          tableData={tableData}
          setTableData={setTableData}
          policyName={policyName}
          setPolicyName={setPolicyName}
          policyType={policyType}
          setPolicyType={setPolicyType}
          policyTemplateTypes={policyTemplateTypes}
          setPolicyTemplateTypes={setPolicyTemplateTypes}
        />
      )}
    </React.Fragment>
  );
};

export default EditPolicyTemplate;
