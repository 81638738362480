import React, { useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useLoading } from '../../../hooks/useLoading';
import { useNotifications } from '../../../hooks/useNotifications';
import { useEffect } from 'react';
import { modalStyle } from '../common/styles';
import { getFormField } from '../common/getFormField';
import { getProjectByIdRequest } from '../../../constants/requests';

const getInitialFormState = (formData) => {
  const initialFormState = {};
  formData.forEach((formDataItem) => {
    const { fieldName, value, required } = formDataItem;
    initialFormState[fieldName] = { value, required, error: false };
  });
  return initialFormState;
};

export default function CreateEditDialog({
  formData,
  onCloseCB,
  refreshPageContentCB,
  entityId,
  entityName,
  getEntityByIdRequest,
  updateEntityRequest,
  createEntityRequest,
  additionalPayloadParameters
}) {
  const { request } = useLoading();
  const { showNotifications } = useNotifications();

  const [formDataState, setFormDataState] = useState(formData);
  const [formFields, setFormFields] = useState(getInitialFormState(formData));

  const getEntityDetails = async () => {
    try {
      const response = await request(() => getEntityByIdRequest(entityId));
      if (response.data.statusCode === 200) {
        const savedFormFields = { ...formFields };
        Object.keys(response.data.body).forEach((key) => {
          if (savedFormFields[key] !== undefined) {
            savedFormFields[key].value =
              key === 'projectId'
                ? response.data.body[key]._id
                : response.data.body[key];
          }
        });

        const assignedEmployees = await getAssignedEmployees(
          savedFormFields['projectId'].value
        );
        setFormDataState(
          formDataState.map((item) => {
            if (
              item.fieldName === 'aumyaaSPOCID1' ||
              item.fieldName === 'aumyaaSPOCID2'
            )
              item.dropDownOptions = assignedEmployees;

            return item;
          })
        );
        setFormFields(savedFormFields);
      } else {
        showNotifications('error', response.data.message, 5000);
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
      console.log(err);
    }
  };

  useEffect(() => {
    if (entityId) getEntityDetails();
  }, []);

  const getAssignedEmployees = async (projectId) => {
    try {
      const response = await request(() => getProjectByIdRequest(projectId));
      if (response.data.statusCode === 200) {
        const assignedEmployees = response.data.body.projectWorkerIds.map(
          (id, idx) => {
            return {
              label: response.data.body.projectWorkerNames[idx],
              value: id
            };
          }
        );
        return assignedEmployees;
      } else {
        showNotifications('error', response.data.message, 5000);
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
      console.log(err);
    }
  };

  const handleChange = async (e) => {
    const { name, value } = e.target;
    const modifiedFormFields = {
      ...formFields,
      [name]: {
        ...formFields[name],
        value
      }
    };
    if (name === 'projectId') {
      const assignedEmployees = await getAssignedEmployees(value);
      setFormDataState(
        formDataState.map((item) => {
          if (
            item.fieldName === 'aumyaaSPOCID1' ||
            item.fieldName === 'aumyaaSPOCID2'
          )
            item.dropDownOptions = assignedEmployees;

          return item;
        })
      );
    }
    setFormFields(modifiedFormFields);
  };

  const formElements = formDataState.map((formDataItem) => {
    const { type, fieldName, fieldLabel, dropDownOptions } = formDataItem;
    return {
      type,
      formFieldsState: formFields,
      fieldName,
      fieldLabel,
      handleChangeCB: handleChange,
      dropDownOptions
    };
  });

  const handleSubmit = async () => {
    try {
      const formFieldErrors = { ...formFields };
      let hasValidationErrors = false;

      Object.keys(formFieldErrors).forEach((formField) => {
        if (formFieldErrors[formField].required) {
          if (formFieldErrors[formField].value.length === 0) {
            formFieldErrors[formField].error = true;
            hasValidationErrors = true;
          } else {
            formFieldErrors[formField].error = false;
          }
        }
      });

      if (hasValidationErrors) {
        setFormFields(formFieldErrors);
        return;
      }

      let payload = { ...additionalPayloadParameters };
      Object.keys(formFields).forEach((formField) => {
        payload[formField] = formFields[formField].value;
      });

      let response;
      if (entityId)
        response = await request(() => updateEntityRequest(entityId, payload));
      else response = await request(() => createEntityRequest(payload));

      if (
        response.data.statusCode === 200 ||
        response.data.statusCode === 201
      ) {
        onCloseCB();
        refreshPageContentCB();
        showNotifications('success', response.data.message, 5000);
      } else showNotifications('error', response.data.message, 5000);
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
      console.log(err);
    }
  };

  return (
    <Box sx={modalStyle}>
      <Typography variant="h5" component="h2" style={{ marginBottom: '20px' }}>
        {entityId ? `Edit ${entityName}` : `Add ${entityName}`}
      </Typography>

      {formElements.map((formElement) => getFormField(formElement))}

      <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button
          variant="contained"
          style={{ marginTop: '10px' }}
          onClick={handleSubmit}
        >
          {entityId ? `Edit ${entityName}` : `Create ${entityName}`}
        </Button>
        <Button
          variant="contained"
          style={{ marginTop: '10px' }}
          onClick={onCloseCB}
        >
          Close
        </Button>
      </Box>
    </Box>
  );
}
