import { TableContainer, Paper, Table } from '@mui/material';
import { MasterTableHead } from '../MasterTableHead';
import { MasterTableBody } from '../MasterTableBody';
import React from 'react';

export const MasterTable = ({
  headers,
  data,
  toggleUpdate,
  url,
  fields,
  options
}) => {
  const findHeaders = (headers, data) => {
    if (data.body.length === 0) return [];
    const currHeaders = [];
    for (let i = 0; i < headers.length; i++) {
      if (headers[i].key in data.body[0]) currHeaders.push(headers[i]);
    }
    return currHeaders;
  };

  const currHeaders = findHeaders(headers, data);
  return (
    <TableContainer
      component={Paper}
      sx={{ width: '100%', mt: 5, maxHeight: '70vh' }}
    >
      <Table stickyHeader>
        <MasterTableHead currHeaders={currHeaders} />
        <MasterTableBody
          currHeaders={currHeaders}
          data={data}
          toggleUpdate={toggleUpdate}
          url={url}
          fields={fields}
          options={options}
        />
      </Table>
    </TableContainer>
  );
};
