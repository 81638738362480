export const ProjectNonMonetaryHeaders = [
  { id: 'projectName', title: 'Project Name' },
  { id: 'clientName', title: 'Client Name' },
  { id: 'risk', title: 'Risk' },
  { id: 'isActive', title: 'Ongoing' },
  { id: 'description', title: 'Description' },
  { id: 'startDate', title: 'Start Date' },
  { id: 'endDate', title: 'End Date' },
  { id: 'zohoLinkId', title: 'Zoho Link Id' }
];

export const ProjectAllHeaders = [
  { id: 'projectName', title: 'Project Name' },
  { id: 'clientName', title: 'Client Name' },
  { id: 'risk', title: 'Risk' },
  { id: 'isActive', title: 'Ongoing' },
  { id: 'description', title: 'Description' },
  { id: 'startDate', title: 'Start Date' },
  { id: 'endDate', title: 'End Date' },
  { id: 'projectWorkersDisplay', title: 'Project Workers' },
  { id: 'zohoLinkId', title: 'Zoho Link Id' },
  { id: 'currencyCode', title: 'Currency Code' },
  { id: 'projectCost', title: 'Project Cost' },
  { id: 'budgetAmount', title: 'Budget Amount' },
  { id: 'costBudget', title: 'Cost Budget' },
  { id: 'budgetHours', title: 'Budget Hours' },
  { id: 'budgetType', title: 'Budget Type' },
  { id: 'billingType', title: 'Billing Type' }
];
