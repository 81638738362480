import React from 'react';
import {
  TableContainer,
  Table,
  TableHead,
  Typography,
  TableRow,
  TableCell,
  TableBody
} from '@mui/material';

export const MonthlyCalendarView = ({ month, year, highlightedDays }) => {
  const daysInMonth =
    month === 12
      ? new Date(year + 1, 0, 0).getDate()
      : new Date(year, month, 0).getDate();
  let day = 0;
  const firstDay = new Date(year, month - 1, 1);

  const getTableCellOfADay = (day) => {
    return (
      <TableCell
        sx={
          highlightedDays.includes(day)
            ? {
                bgcolor: 'orange',
                align: 'center',
                border: '1px solid black'
              }
            : { align: 'center', border: '1px solid black' }
        }
        align="center"
      >
        <Typography variant="h6">{day}</Typography>
      </TableCell>
    );
  };

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            {['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'].map(
              (dayOfWeek) => {
                return (
                  <TableCell
                    align="center"
                    sx={{ border: '1px solid black' }}
                    key={dayOfWeek}
                  >
                    <Typography
                      variant="h5"
                      sx={{
                        fontWeight: 'bold'
                      }}
                    >
                      {dayOfWeek}
                    </Typography>
                  </TableCell>
                );
              }
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {/* {first row} */}
          <TableRow>
            {[...Array(firstDay.getDay()).keys()].map(() => {
              return getTableCellOfADay(' ');
            })}
            {[...Array(7 - firstDay.getDay()).keys()].map(() => {
              ++day;
              return getTableCellOfADay(day);
            })}
          </TableRow>
          {/* {middle rows} */}
          {[...Array(Math.floor((daysInMonth - day) / 7)).keys()].map(() => {
            return (
              // eslint-disable-next-line
              <TableRow>
                {[...Array(7).keys()].map(() => {
                  ++day;
                  return getTableCellOfADay(day);
                })}
              </TableRow>
            );
          })}
          {/* {last row} */}
          <TableRow>
            {[...Array(7).keys()].map(() => {
              ++day;
              return day <= daysInMonth
                ? getTableCellOfADay(day)
                : getTableCellOfADay(' ');
            })}
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};
