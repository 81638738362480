import React, { useState, useEffect } from 'react';
import { Box, Button, Modal, Typography } from '@mui/material';
import { useLoading } from '../../../hooks/useLoading';
import { useNotifications } from '../../../hooks/useNotifications';
import { useNavigate, useParams } from 'react-router-dom';
import {
  findThreatVulerabilityById,
  findThreatVulerabilityByProperties,
  updateThreatVulerabilityById,
  findAssetById,
  findThreatById,
  findAllLibraryType
} from '../../../constants/requests';
import CustomGrid from '../common/CustomGrid';
import CreateEditDialog from '../common/CreateEditDialog';
import DeleteDialog from '../common/DeleteDialog';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

const ThreatVulnerability = () => {
  const navigate = useNavigate();
  const { currentAssetTabName, assetId, ThreatId } = useParams();
  const [ThreatData, setThreatData] = useState(null);

  const [ThreatvulId, setId] = useState(null);
  const { request } = useLoading();
  const { showNotifications } = useNotifications();

  const [delRow, setDelRow] = useState(null);

  const [addRecordForm, setAddRecordForm] = useState([]);
  const [openAddRecordModal, setOpenAddRecordModal] = useState(false);
  const [openDeleteRecordModal, setOpenDeleteRecordModal] = useState(false);

  const handleOpenAddRecordModal = () => setOpenAddRecordModal(true);
  const handleCloseAddRecordModal = () => setOpenAddRecordModal(false);
  const handleOpenDeleteRecordModal = (row) => {
    setDelRow(row);
    setOpenDeleteRecordModal(true);
  };
  const handleCloseDeleteRecordModal = () => {
    setDelRow(null);
    setOpenDeleteRecordModal(false);
  };

  const createAddRecordForm = (metaProperties) => {
    const { fields } = metaProperties;
    const recordForm = Object.keys(fields)
      .map((key) => {
        if (key === 'Aggregate Value') {
          return null;
        }
        return {
          type: fields[key]['fieldType'],
          fieldName: key,
          fieldLabel: `Enter ${key}`,
          value: '',
          required: false,
          dropDownOptions: fields[key]?.['options']
        };
      })
      .filter((field) => field !== null);
    setAddRecordForm(recordForm);
  };

  const loadApplication = async () => {
    try {
      const asset = await request(() => findAssetById(assetId));
      ////console.log(asset);
      if (asset.status !== 200) {
        //console.log('asset not found');
        showNotifications('error', 'No Assets Found', 5000);
        return;
      }
      const threat = await request(() => findThreatById(ThreatId));
      if (threat.data.statusCode !== 200) {
        //console.log('threat not found');
        showNotifications('error', 'No Threat Found', 5000);
        return;
      }
      const vuln = await request(() => findAllLibraryType('vulnerability'));
      if (vuln === null) {
        //console.log('no vuln found');
        showNotifications('error', 'No Vulnerability Found', 5000);
        return;
      }

      ////console.log(threat);
      ////console.log(vuln);
      let threatimpactrows = [];
      let tot_threat = [];
      threat.data.body.data.rows['Threat Impact'].forEach((element) => {
        tot_threat.push(element['Threat']);
        threatimpactrows.push({
          Threat: element['Threat'],
          'Threat Likelihood': element['Threat Likelihood'],
          'Aggregate Threat Impact Potential':
            element['Aggregate Threat Impact Potential']
        });
      });
      ////console.log(threatimpactrows);

      let Threatdatarows = [...ThreatData.data.rows['ThreatVulnerabilityPair']];
      let threats_in_tv = [];
      let addthreat = [];
      let removethreat = [];

      Threatdatarows.forEach((row) => {
        const threatt = row['Threat and Vulnerability Pair'].split('_');
        let t = threatt[0];
        threats_in_tv.push(t);
      });

      ////console.log(tot_threat);
      ////console.log(threats_in_tv);

      threats_in_tv.forEach((t) => {
        if (!tot_threat.includes(t)) {
          removethreat.push(t);
        }
      });

      ////console.log(removethreat);

      tot_threat.forEach((t) => {
        if (!threats_in_tv.includes(t)) {
          addthreat.push(t);
        }
      });
      ////console.log(addthreat);
      // //console.log(removethreat);
      let newrows = [];

      for (var i = 0; i < Threatdatarows.length; i++) {
        const threatt =
          Threatdatarows[i]['Threat and Vulnerability Pair'].split('_');
        let t = threatt[0];
        if (!removethreat.includes(t)) {
          newrows.push(Threatdatarows[i]);
          ////console.log(t);
        }
      }

      threat.data.body.data.rows['Threat Impact'].forEach((element) => {
        const t = element['Threat'];
        if (addthreat.includes(t)) {
          console.log(element['Threat']);
        }
      });
      vuln.data.body.forEach((vul) => {
        console.log(vul['name']);
      });
      threat.data.body.data.rows['Threat Impact'].forEach((Threat) => {
        if (addthreat.includes(Threat['Threat'])) {
          vuln.data.body.forEach((vulnerability) => {
            //console.log(Threat['Threat'] + '_' + vulnerability['name']);
            const Pair = Threat['Threat'] + '_' + vulnerability['name'];
            //console.log(Pair);
            const agg = Threat['Aggregate Threat Impact Potential'];
            console.log(agg);
            const likelihood = Threat['Threat Likelihood'];
            console.log(likelihood);
            newrows.push({
              'Threat and Vulnerability Pair': Pair,
              'Aggregate Value': Threat['Aggregate Threat Impact Potential'],
              Likelihood: Threat['Threat Likelihood']
            });
          });
        }
      });
      ////console.log(newrows);

      ////console.log(res);

      const newThreatData = { ...ThreatData };
      newThreatData.data.rows['ThreatVulnerabilityPair'] = newrows;

      //setThreatData(newdata);

      let response = await request(() =>
        updateThreatVulerabilityById(ThreatvulId, newThreatData)
      );

      if (response.data.statusCode === 200) {
        showNotifications('success', response.data.message, 5000);
      } else {
        showNotifications('error', response.data.message, 5000);
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  const getThreatRepositoryById = async (ThreatvulId) => {
    try {
      const res = await request(() => findThreatVulerabilityById(ThreatvulId));

      if (res.data.statusCode === 200) {
        setThreatData(res.data.body);
        console.log(res.data.body.rows.data);

        createAddRecordForm(res.data.body.data.meta);

        showNotifications('success', res.data.message, 5000);
      } else {
        showNotifications('error', res.data.message, 5000);
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  const getThreatRepository = async () => {
    try {
      const res = await request(() => findThreatVulerabilityByProperties());
      //console.log(res.data);

      if (res.data.statusCode === 200) {
        setId(res.data.body[0]._id);

        getThreatRepositoryById(res.data.body[0]._id);
        showNotifications('success', res.data.message, 5000);
      } else {
        showNotifications('error', res.data.message, 5000);
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  const ControlNoParing = async () => {
    try {
      getThreatRepository();

      const dta = ['control'];
      const response = await request(() => findAllLibraryType(dta[0]));
      const data = response.data.body;

      let array1 = [];
      console.log(ThreatData.data.meta.fields['Control No.']);
      //console.log(data[0]['number']);
      // Check if 'Control No.' field already exists in ThreatData.data.meta.fields
      console.log('here');

      data.forEach((element) => {
        if (element['number']) {
          array1.push(element['number']);
        }
      });

      let updatettotal = Object.keys(
        ThreatData.data.meta.fields['Control No.'].options
      ).length;
      let array2 = [];

      for (let i = 0; i < updatettotal; i++) {
        array2.push(
          ThreatData.data.meta.fields['Control No.'].options[i].value
        );
      }

      const missingFromArray1 = array1.filter(
        (element) => !array2.includes(element)
      );

      // Find missing elements from array2
      const missingFromArray2 = array2.filter(
        (element) => !array1.includes(element)
      );

      // Combine the missing elements into array3
      const array3 = [...missingFromArray1, ...missingFromArray2];

      console.log(array1);
      console.log(array2);
      console.log('difference');
      const filterarray = array3.filter((element) => element !== '');
      console.log(filterarray);

      if (filterarray.length > 0) {
        for (let i = 0; i < filterarray.length; i++) {
          let a = filterarray[i];
          console.log('hi');

          // Check if options property and its nested properties exist
          if (!ThreatData.data.meta.fields['Control No.'].options) {
            ThreatData.data.meta.fields['Control No.'].options = [];
          }

          ThreatData.data.meta.fields['Control No.'].options.push({
            label: a,
            value: a
          });
          //ThreatData.data.meta.fields['Control No.'].options.value.push(a);
          a.splice(i, 1);
        }
      }

      let updatetotal = Object.keys(
        ThreatData.data.meta.fields['Control No.'].options
      ).length;
      console.log(updatetotal);
    } catch (err) {
      console.log(err);
    }
  };

  const handleSave = async () => {
    try {
      const payload = ThreatData;
      //console.log(payload);
      const ress = await request(() =>
        updateThreatVulerabilityById(ThreatvulId, payload)
      );

      if (ress.data.statusCode === 200) {
        setThreatData(ThreatData);
        showNotifications('success', ress.data.message, 5000);
      } else {
        showNotifications('error', ress.data.message, 5000);
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  const handleAddRecord = async (newRecord) => {
    try {
      const newData = {};
      Object.keys(newRecord).map((record) => {
        newData[record] = newRecord[record]['value'];
      });

      //matching from library control
      const dta = ['control'];
      const res = await request(() => findAllLibraryType(dta[0]));
      // //console.log(res.number);
      const controlNo = newData['Control No']; // Threat No from the newRecord
      //console.log(controlNo);
      const matchedThreat = res.data.body.find(
        (threat) => threat.number === controlNo
      );
      if (matchedThreat) {
        newData['Control'] = matchedThreat.name;
        newData['Control Description'] = matchedThreat.description;
      }

      const newThreatData = { ...ThreatData };

      let newRows = newThreatData['data']['rows']['ThreatVulnerabilityPair'];
      newRows = [...newRows, newData];
      newThreatData['data']['rows']['ThreatVulnerabilityPair'] = newRows;
      const payload = newThreatData;

      let response = await request(() =>
        updateThreatVulerabilityById(ThreatvulId, payload)
      );
      ////console.log(response);
      if (response.data.statusCode === 200) {
        setThreatData(newThreatData);
        handleCloseAddRecordModal();
        showNotifications('success', response.data.message, 5000);
      } else {
        showNotifications('error', response.data.message, 5000);
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
      //console.log(err);
    }
  };

  const handleDeleteRecord = async () => {
    try {
      const filteredData = ThreatData['data']['rows'][
        'ThreatVulnerabilityPair'
      ].filter((row) => JSON.stringify(row) !== JSON.stringify(delRow));

      const newThreatData = { ...ThreatData };
      ThreatData['data']['rows']['ThreatVulnerabilityPair'] = filteredData;
      const payload = newThreatData;

      const response = await request(() =>
        updateThreatVulerabilityById(ThreatvulId, payload)
      );

      if (response.data.statusCode === 200) {
        setThreatData(newThreatData);
        handleCloseDeleteRecordModal();
        showNotifications('success', response.data.message, 5000);
      } else {
        showNotifications('error', response.data.message, 5000);
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
      //console.log(err);
    }
  };

  const handleRowUpdate = async (newRow, oldRow) => {
    //console.log(newRow);
    //console.log(oldRow);
    const newThreatRows = [...ThreatData.data.rows['ThreatVulnerabilityPair']];

    console.log(newRow['Control No.']);
    //matching from library control
    const dta = ['control'];
    const res = await request(() => findAllLibraryType(dta[0]));
    //console.log(res.data.body[0].number);
    const controlNo = newRow['Control No.']; // Threat No from the newRecord
    //console.log(controlNo);
    const matchedThreat = res.data.body.find(
      (threat) => threat.number === controlNo
    );
    if (matchedThreat) {
      newRow['Control'] = matchedThreat.name;
      newRow['Control Description'] = matchedThreat.description;
    }

    //console.log(newRow);

    newThreatRows.forEach((element, index) => {
      if (JSON.stringify(oldRow) === JSON.stringify(element)) {
        ////console.log('index ' + index + ' element ' + JSON.stringify(element));
        newThreatRows[index] = newRow;
      }
    });

    console.log(newThreatRows);

    const newThreatData = { ...ThreatData };
    newThreatData.data.rows['ThreatVulnerabilityPair'] = newThreatRows;

    setThreatData(newThreatData);
  };

  const handleClick = () => {
    navigate(-1);
  };

  useEffect(() => {
    ControlNoParing();
    loadApplication();
  }, []);

  return (
    <React.Fragment>
      <Box
        style={{
          marginBottom: '25px',
          display: 'flex',
          justifyContent: 'space-between'
        }}
      ></Box>
      <Button
        sx={{
          color: 'white',
          backgroundColor: 'black',
          flexDirection: 'end',
          marginBottom: '10px'
        }}
        variant="contained"
        onClick={handleClick}
      >
        <ChevronLeftIcon />
        Threat
      </Button>

      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          gap: '10px',
          marginBottom: '10px'
        }}
      >
        <Typography sx={{ fontWeight: 'bold' }} variant="h4">
          Threat Vulnerability
        </Typography>
      </Box>

      <Box style={{ marginBottom: '40px' }}>
        <Box
          style={{
            display: 'flex',
            gap: '10px',
            marginBottom: '10px',
            justifyContent: 'flex-end',
            alignItems: 'flex-end'
          }}
        >
          <Button
            sx={{
              color: 'white',
              backgroundColor: 'black'
            }}
            variant="contained"
            onClick={handleOpenAddRecordModal}
            disabled={ThreatData !== null}
          >
            Add Record
          </Button>

          <Button
            variant="contained"
            color="success"
            onClick={handleSave}
            disabled={ThreatData === null}
          >
            Save
          </Button>
          <Button
            sx={{
              color: 'white',
              backgroundColor: '#ff5722',
              alignContent: 'space-between'
            }}
            variant="contained"
            disabled={ThreatData === null}
            onClick={() => {
              navigate(
                `/services/asset/${currentAssetTabName}/${assetId}/threatVul/${ThreatvulId}/risk`
              );
            }}
          >
            Connect Risk Register
          </Button>
          <Button
            onClick={loadApplication}
            sx={{
              color: 'white',
              backgroundColor: 'blue',
              alignContent: 'space-between'
            }}
            variant="contained"
          >
            Refresh
          </Button>
        </Box>
      </Box>

      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          margin: '40px 0px 10px'
        }}
      ></Box>

      {ThreatData !== null && ThreatData.data !== null ? (
        <CustomGrid
          rows={ThreatData.data.rows['ThreatVulnerabilityPair']}
          fieldsMeta={ThreatData.data.meta.fields}
          tableMeta={ThreatData.data.meta.ThreatVulnerability}
          handleChangeCB={handleRowUpdate}
          deleteCB={handleOpenDeleteRecordModal}
        />
      ) : (
        <Box style={{ display: 'flex', justifyContent: 'center' }}>
          <Typography sx={{ fontWeight: 'bold', color: 'Red' }} variant="h5">
            No Data Available to Track
          </Typography>
        </Box>
      )}
      {/* Update Threat Repository Modal */}
      <Modal
        open={openAddRecordModal}
        onClose={handleCloseAddRecordModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <CreateEditDialog
          formData={addRecordForm}
          onCloseCB={handleCloseAddRecordModal}
          refreshPageContentCB={loadApplication}
          entityName="Record"
          customUpdateCB={handleAddRecord}
        />
      </Modal>
      <Modal
        open={openDeleteRecordModal}
        onClose={handleCloseDeleteRecordModal}
      >
        <DeleteDialog
          entityName={`Record`}
          customDeleteCB={handleDeleteRecord}
          onCloseCB={handleCloseDeleteRecordModal}
        />
      </Modal>
    </React.Fragment>
  );
};

export default ThreatVulnerability;
