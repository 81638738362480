import React from 'react';
import { groupBy } from 'lodash';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

export const EmployeeView = ({ data, fromDate, toDate, excludedDates }) => {
  const dateArray = [];
  const groupedData = data.length ? groupBy(data, 'taskName') : {};
  let startDate = fromDate,
    endDate = toDate;
  // console.log({ startDate, endDate });
  const filteredData = {};
  Object.entries(groupedData).forEach(([key, item]) => {
    item.sort((a, b) => (a.date > b.date ? 1 : b.date > a.date ? -1 : 0));
    // startDate = Math.min(new Date(startDate), new Date(item[0].date));
    // endDate = Math.max(new Date(endDate), new Date(item[item.length - 1].date));
    const dateTimeArray = [];
    for (const obj of item) {
      dateTimeArray.push({ timeSpent: obj.hoursSpent, date: obj.date });
    }
    filteredData[key] = {
      projectName: item[0].projectName,
      taskName: item[0].taskName,
      isBillable: item[0].isBillable,
      dateTimeArray: dateTimeArray
    };
  });
  // console.log({ filteredData });
  // useEffect(() => {
  const sDate = new Date(startDate);
  const eDate = new Date(endDate);

  for (var d = sDate; d <= eDate; d.setDate(d.getDate() + 1)) {
    if (
      excludedDates.find((date) => date.toDateString() === d.toDateString())
    ) {
      continue;
    }
    if (startDate) dateArray.push(d.toISOString());
  }
  // }, []);
  function total(timesheet, dateArray) {
    let total = 0;
    dateArray.map((date) => {
      const obj = timesheet.dateTimeArray.find(
        (o) => formatDate(new Date(o.date)) === formatDate(new Date(date))
      );
      total += obj ? obj.timeSpent : 0;
    });
    return total;
  }
  function formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }
  return (
    <>
      <div id="employeeTable">
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Project Name</TableCell>
                <TableCell align="right">Task</TableCell>
                <TableCell align="right">Billable/Non Billable</TableCell>
                {dateArray.map((date) => {
                  return (
                    // eslint-disable-next-line
                    <TableCell align="right">
                      {new Date(date).toDateString()}
                    </TableCell>
                  );
                })}
                <TableCell align="right">Total</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.entries(filteredData).map(([key, timesheet]) => {
                {
                  /* console.log({ key, timesheet }); */
                }
                return (
                  <TableRow
                    key={key}
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0 }
                    }}
                  >
                    <TableCell component="th" scope="row">
                      {timesheet.projectName}
                    </TableCell>
                    <TableCell align="right">{timesheet.taskName}</TableCell>
                    <TableCell align="right">
                      {timesheet.isBillable ? 'Yes' : 'No'}
                    </TableCell>
                    {dateArray.map((date) => {
                      {
                        /* console.log(date); */
                      }
                      const obj = timesheet.dateTimeArray.find(
                        (o) =>
                          formatDate(new Date(o.date)) ===
                          formatDate(new Date(date))
                      );
                      return (
                        // eslint-disable-next-line
                        <TableCell align="right">
                          {obj ? obj.timeSpent : ''}
                        </TableCell>
                      );
                    })}
                    <TableCell align="right">
                      {total(timesheet, dateArray)}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
};
