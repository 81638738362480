import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Table,
  TableCell,
  tableCellClasses,
  TableHead,
  TableRow,
  TableBody,
  TableContainer,
  TextField,
  Typography,
  MenuItem,
  Modal
} from '@mui/material';
import {
  getRolesRequest,
  getAllTemplates,
  downloadDocument,
  getRCMByIdRequest,
  uploadLogoRequest,
  reuploadLogoRequest,
  updateEngagementRequest,
  getDocumentsByTemplateId,
  getEngagementByIdRequest,
  getWorkflowHandlerByIdRequest,
  getServiceById,
  downloadVersionRequest,
  downloadTestingSheet
} from '../../../constants/requests';
import { styled } from '@mui/material/styles';
import { useLoading } from '../../../hooks/useLoading';
import { useDataLayerValue } from '../../../contextAPI/DataLayer';
import { useNotifications } from '../../../hooks/useNotifications';
import moment from 'moment';
import Paper from '@mui/material/Paper';
import FileDownload from 'js-file-download';
import DownloadIcon from '@mui/icons-material/Download';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import VersionManager from '../../TemplateEditor/common/VersionManager';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14
  }
}));
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  marginBottom: '10px',
  bgcolor: 'background.paper',
  borderRadius: '10px',
  boxShadow: 24,
  p: 4,
  overflow: 'scroll',
  maxHeight: '100%',
  minWidth: '600px'
};
const StyledTableRow = styled(TableRow)(() => ({
  '&:last-child td, &:last-child th': {
    border: 0
  }
}));

// Possible statuses: null, 'Active', 'Completed', 'Rejected'
// null -> submit on, dbtns off
// active -> submit off, dbtns off
// completed -> submit off, dbtns on
// rejected -> submit on, dbtns off
const isButtonUsable = (buttonType, workflowHandlerStatus, userType) => {
  if (userType === 'PROJECT_MEMBER') return false;
  if (buttonType === 'submit') {
    if (userType === 'ADMIN') return false;
    if (workflowHandlerStatus === null) return true;
    if (workflowHandlerStatus === 'Active') return false;
    if (workflowHandlerStatus === 'Completed') return false;
    if (workflowHandlerStatus === 'Rejected') return true;
  }

  if (buttonType === 'download') {
    if (userType === 'ADMIN') return true;
    if (workflowHandlerStatus === null) return false;
    if (workflowHandlerStatus === 'Active') return false;
    if (workflowHandlerStatus === 'Completed') return true;
    if (workflowHandlerStatus === 'Rejected') return false;
  }
};

const getSubmitButtonText = (workflowHandlerStatus, userType) => {
  if (userType === 'ADMIN') return 'Approved';
  if (workflowHandlerStatus === null || workflowHandlerStatus === 'Rejected')
    return 'Submit for approval';
  if (workflowHandlerStatus === 'Active') return 'Approval pending';
  if (workflowHandlerStatus === 'Completed') return 'Approved';
};

const getFormattedDate = () => {
  return moment().format('D_MMM_YYYY_h_mm_ss_a');
};

const getExtension = (contentType) => {
  switch (contentType) {
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      return 'xlsx';
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      return 'docx';
    default:
      return 'xlsx';
  }
};

export default function DownloadDocuments() {
  const navigate = useNavigate();
  const { request } = useLoading();
  const { engagementId } = useParams();
  const [{ _id, roleId, isVerifiedBySPOC }] = useDataLayerValue();
  const { showNotifications } = useNotifications();
  const [docId, setDocId] = useState(null);
  const [serviceType, setServiceType] = useState(null);
  const [userType, setUserType] = useState('PROJECT_MEMBER');
  const [applications, setApplications] = useState([]);
  const [documentInfo, setDocumentInfo] = useState([]);
  const [formFields, setFormFields] = useState({});
  const [applicationMapping, setApplicationMapping] = useState({});
  // Possible statuses: null, 'Active', 'Completed', 'Rejected'
  const [workflowHandlerStatus, setWorkflowHandlerStatus] = useState(null);
  const [isTestingDownloadOpen, setTestingDownloadOpen] = useState(false);
  const [allApplications, setAllApplications] = useState([]);
  const [documentManagerId, setDocumentManagerId] = useState('');

  const getInitialState = (tableData) => {
    const formFields = {};
    tableData.forEach((row) => {
      formFields[row['Document Name']] = {
        selectedId:
          row['Select Application'] === 'ALL_APPLICATIONS' ? engagementId : '',
        required:
          row['Select Application'] === 'ALL_APPLICATIONS' ? false : true,
        error: false
      };
    });
    setFormFields(formFields);
  };
  const handleDownloadClose = () => {
    setTestingDownloadOpen(false);
  };
  const getRole = async () => {
    try {
      const res = await request(() => getRolesRequest());
      if (res.data.statusCode === 200) {
        const role = res.data.body.filter((roles) => roles._id === roleId);
        if (role[0].name === 'Administrator') setUserType('ADMIN');
      } else {
        showNotifications('error', res.data.message, 5000);
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  const loadApplication = async () => {
    try {
      const response = await request(() =>
        getEngagementByIdRequest(engagementId)
      );
      if (response.data.statusCode !== 200) {
        showNotifications('error', 'No Engagements Found', 5000);
        return;
      }

      setDocId(response.data.body.logoId);
      if (
        _id === response.data.body.aumyaaSPOCID1 ||
        _id === response.data.body.aumyaaSPOCID2
      )
        setUserType('SPOC');

      const { serviceId } = response.data.body;

      const res = await request(() => getServiceById(serviceId));
      if (res.data.statusCode === 200) {
        setServiceType(res.data.body.name);
      }

      const applicationsInfo = response.data.body.applicationID;
      setAllApplications([]);
      for (const application of applicationsInfo) {
        if (application.RCMId === undefined) continue;
        const resaa = await request(() => getRCMByIdRequest(application.RCMId));
        if (resaa.data.statusCode === 200) {
          setAllApplications((prev) => [
            ...prev,
            {
              application: application,
              RCMData: resaa.data.body
            }
          ]);
        }
      }
      const applicationToTemplateMapping = {};
      let documentsInfo = [];
      let tempId = null;

      for (const applicationData of applicationsInfo) {
        const { _id, applicationName, RCMId } = applicationData;
        applicationToTemplateMapping[_id] = {};
        applicationToTemplateMapping[_id]['ApplicationName'] = applicationName;
        applicationToTemplateMapping[_id]['RCMId'] = RCMId;
      }

      for (const appId in applicationToTemplateMapping) {
        const { RCMId } = applicationToTemplateMapping[appId];

        const rcmResponse = await request(() => getRCMByIdRequest(RCMId));
        if (
          rcmResponse?.response?.data?.statusCode === 404 ||
          rcmResponse.data.statusCode !== 200
        ) {
          continue;
        }
        const tempName = rcmResponse.data.body.templateType;
        const templateResponse = await request(() => getAllTemplates());
        if (
          templateResponse?.response?.data?.statusCode === 404 ||
          templateResponse.data.statusCode !== 200
        ) {
          continue;
        }
        const templates = templateResponse.data.body.filter(
          (template) => template.templateName === tempName
        );
        if (templates[0]['_id'] === undefined) continue;

        tempId = templates[0]['_id'];
        const documentsResponse = await request(() =>
          getDocumentsByTemplateId(tempId)
        );

        if (documentsResponse.data.body) {
          for (const item of documentsResponse.data.body) {
            if (item.documentType === 'testing') {
              setDocumentManagerId(item.documentManagerId);
            }
          }
        }
        if (
          documentsResponse?.response?.data?.statusCode === 404 ||
          documentsResponse.data.statusCode !== 200
        ) {
          continue;
        }

        const documents = documentsResponse.data.body.map((item) => {
          if (item.documentType === 'testing') return null;
          return {
            'Document Id': item._id,
            'Document Name': item.documentName,
            'Select Application': item.dataFetchingStrategy
          };
        });

        if (documents.length === 0) continue;

        documentsInfo = documents;
        if (documentInfo.length === 0) {
          getInitialState(documents);
          setDocumentInfo(documents);
        }
        break;
      }

      for (const appId in applicationToTemplateMapping) {
        applicationToTemplateMapping[appId]['templateId'] = tempId;
        applicationToTemplateMapping[appId]['documentsInfo'] = documentsInfo;
      }

      setApplicationMapping(applicationToTemplateMapping);

      const applications = applicationsInfo.map((application) => {
        const { _id, applicationName } = application;
        return { label: applicationName, value: _id };
      });
      if (response.data.body.workflowHandlerId !== null) {
        const workflowHandler = await request(() =>
          getWorkflowHandlerByIdRequest(response.data.body.workflowHandlerId)
        );
        setWorkflowHandlerStatus(workflowHandler.data.body.status);
      }
      setApplications(applications);
    } finally {
      showNotifications('success', 'Searched All Documents Successfully', 5000);
    }
  };

  useEffect(() => {
    getRole();
    loadApplication();
  }, []);

  const handleChange = (e, documentType) => {
    setFormFields({
      ...formFields,
      [documentType]: {
        ...formFields[documentType],
        selectedId: e.target.value
      }
    });
  };

  const handleDownload = async (
    e,
    documentType,
    documentId,
    selectedId, // application id of selected application.
    fileName,
    fetchingType
  ) => {
    try {
      if (formFields[documentType].required) {
        let error = selectedId.length === 0;
        setFormFields({
          ...formFields,
          [documentType]: {
            ...formFields[documentType],
            error
          }
        });
        if (error) return;
      }

      let payload = {},
        applicationDocumentId;
      if (fetchingType === 'ALL_APPLICATIONS') {
        payload = {
          engagementId: engagementId
        };
        applicationDocumentId = documentId;
      } else {
        payload = {
          engagementId: engagementId,
          applicationId: selectedId
        };

        applicationDocumentId = applicationMapping[selectedId][
          'documentsInfo'
        ].filter((item) => item['Document Name'] === documentType)[0][
          'Document Id'
        ];
      }

      const response = await request(() =>
        downloadDocument(applicationDocumentId, payload)
      );

      // File will download if there is no status code i.e. responseType: 'blob'
      // statusCode will only be present if the response type is application/json
      // This will only be sent in case of errors
      if (response?.response?.data?.statusCode) {
        showNotifications('error', response.response.data.message, 5000);
      } else {
        FileDownload(
          response.data,
          `${fileName}_${selectedId}_${getFormattedDate()}.${getExtension(
            response.headers['content-type']
          )}`
        );
        showNotifications('success', 'Downloaded successfully', 5000);
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
      console.log(err);
    }
  };

  const handleSubmitForApproval = async () => {
    try {
      if (serviceType === 'SOC2.0' && !isVerifiedBySPOC) {
        showNotifications('error', 'You Have Not Verified The CSOC Page', 5000);
        return;
      }
      const response = await request(() =>
        updateEngagementRequest(
          engagementId,
          {
            isApproved: true
          },
          _id
        )
      );
      const workflowHandlerId = response.data.body._id;
      await request(() =>
        updateEngagementRequest(engagementId, { workflowHandlerId }, _id)
      );
      loadApplication();
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
      console.log(err);
    }
  };
  return (
    <>
      <Button
        sx={{
          marginBottom: '25px',
          color: 'white',
          backgroundColor: 'black'
        }}
        variant="contained"
        onClick={() =>
          navigate(`/services/engagement/${engagementId}/understanding`)
        }
      >
        <ChevronLeftIcon />
        Understanding
      </Button>

      <Box
        style={{
          marginBottom: '25px',
          display: 'flex',
          justifyContent: 'space-between'
        }}
      >
        <Typography sx={{ fontWeight: 'bold' }} variant="h4">
          Download Documents
        </Typography>

        <Box style={{ display: 'flex', gap: '10px' }}>
          <Button
            disabled={
              !isButtonUsable('submit', workflowHandlerStatus, userType)
            }
            sx={{ color: 'white', backgroundColor: 'black' }}
            variant="contained"
            onClick={handleSubmitForApproval}
          >
            {getSubmitButtonText(workflowHandlerStatus, userType)}
          </Button>
        </Box>
      </Box>
      <VersionManager
        heading="Logo(in png format only)"
        formDataText="logo"
        entityId={engagementId}
        uploadRequest={
          docId === null || docId === undefined
            ? uploadLogoRequest
            : reuploadLogoRequest
        }
        refreshCB={loadApplication}
        docManagerId={docId}
      />
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <Typography sx={{ fontWeight: 'bold' }} variant="h4">
          Testing Sheets
        </Typography>
        <Box style={{ display: 'flex', gap: '10px' }}>
          <Button
            disabled={isButtonUsable('submit', workflowHandlerStatus, userType)}
            sx={{ color: 'white', backgroundColor: 'black' }}
            variant="contained"
            onClick={() => setTestingDownloadOpen((prev) => !prev)}
          >
            Download Testing Sheet
          </Button>
        </Box>
      </Box>
      {documentInfo.length > 0 ? (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                {Object.keys(documentInfo[0]).map((header) => {
                  return (
                    header !== 'Document Id' && (
                      <StyledTableCell
                        key={header}
                        style={{ minWidth: '300px' }}
                        align="center"
                      >
                        {header}
                      </StyledTableCell>
                    )
                  );
                })}
                <StyledTableCell style={{ minWidth: '300px' }} align="center">
                  Action
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {documentInfo.map((documentsRow, idx) => (
                <StyledTableRow key={`DocumentRow-${idx}`}>
                  <StyledTableCell align="center">
                    <Typography variant="h6">
                      {documentsRow['Document Name']}
                    </Typography>
                  </StyledTableCell>

                  <StyledTableCell align="center">
                    {documentsRow['Select Application'] ===
                    'SINGLE_APPLICATION' ? (
                      <TextField
                        select
                        label="Select Application"
                        error={formFields[documentsRow['Document Name']].error}
                        helperText={
                          formFields[documentsRow['Document Name']].error &&
                          formFields[documentsRow['Document Name']].required
                            ? 'Required'
                            : ''
                        }
                        required
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        value={
                          formFields[documentsRow['Document Name']].selectedId
                        }
                        onChange={(e) =>
                          handleChange(e, documentsRow['Document Name'])
                        }
                      >
                        {applications.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    ) : null}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <Button
                      disabled={
                        !isButtonUsable(
                          'download',
                          workflowHandlerStatus,
                          userType
                        )
                      }
                      sx={{
                        color: 'white',
                        backgroundColor: 'black'
                      }}
                      variant="contained"
                      onClick={(e) =>
                        handleDownload(
                          e,
                          documentsRow['Document Name'],
                          documentsRow['Document Id'],
                          formFields[documentsRow['Document Name']].selectedId,
                          documentsRow['Document Name'],
                          documentsRow['Select Application']
                        )
                      }
                    >
                      <DownloadIcon
                        style={{
                          marginRight: '10px'
                        }}
                      />
                      Download
                    </Button>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Box style={{ display: 'flex', justifyContent: 'center' }}>
          <h1 style={{ color: 'red', align: 'center' }}>
            No Documents Found. Create RCM First
          </h1>
        </Box>
      )}
      <Modal
        open={isTestingDownloadOpen}
        onClose={handleDownloadClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={style}
          style={{ width: '80%', height: '50%', overflowX: 'scroll' }}
        >
          <Typography
            variant="h4"
            id="modal-modal-title"
            style={{ marginBottom: '10px' }}
          >
            Testing Sheets {allApplications.length}
          </Typography>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell style={{ minWidth: '300px' }} align="center">
                    Application Name
                  </StyledTableCell>
                  <StyledTableCell style={{ minWidth: '300px' }} align="center">
                    Action
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {allApplications.map((application) => {
                  if (application.RCMData.testingSheetType === 'BDO')
                    return (
                      <StyledTableRow
                        key={`ApplicationRow-${application.application.value}`}
                      >
                        <StyledTableCell align="center">
                          <Typography variant="h6">
                            {application.application.applicationName}
                          </Typography>
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <Button
                            sx={{
                              color: 'white',
                              backgroundColor: 'black'
                            }}
                            variant="contained"
                            onClick={async () => {
                              const formData = new FormData();
                              formData.append(
                                'applicationData',
                                JSON.stringify(application)
                              );
                              try {
                                if (documentManagerId) {
                                  const resp = await downloadVersionRequest(
                                    documentManagerId
                                  );
                                  formData.append(
                                    'signedUrl',
                                    resp.data.body.signedUrl
                                  );
                                }
                                try {
                                  const response = await request(() =>
                                    downloadTestingSheet(formData)
                                  );
                                  if (response?.response?.data?.statusCode) {
                                    showNotifications(
                                      'error',
                                      response.response.data.message,
                                      5000
                                    );
                                  } else {
                                    FileDownload(
                                      response.data,
                                      `testing_${getFormattedDate()}.${getExtension(
                                        response.headers['content-type']
                                      )}`
                                    );
                                    showNotifications(
                                      'success',
                                      'Downloaded successfully',
                                      5000
                                    );
                                  }
                                } catch (error) {
                                  console.log(error);
                                }
                              } catch (error) {
                                console.log(error);
                              }
                            }}
                          >
                            <DownloadIcon
                              style={{
                                marginRight: '10px'
                              }}
                            />
                            Download
                          </Button>
                        </StyledTableCell>
                      </StyledTableRow>
                    );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Modal>
    </>
  );
}
