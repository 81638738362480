import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  tableCellClasses,
  Paper
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { styled } from '@mui/material/styles';
import FormControl from '@mui/material/FormControl';
import AddPolicyTypeModal from '../Components/AddPolicyTypeModal';
import { useLoading } from '../../../hooks/useLoading';
import { useNotifications } from '../../../hooks/useNotifications';
import {
  createTemplateType,
  deleteTemplateType,
  getAllTemplateType
} from '../../../constants/requests';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14
  }
}));

const StyledTableRow = styled(TableRow)(() => ({
  '&:last-child td, &:last-child th': {
    border: 0
  }
}));

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired
};

const PolicyTypeTable = () => {
  const { request } = useLoading();
  const { showNotifications } = useNotifications();

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const handleOpenDeleteModal = () => setOpenDeleteModal(true);
  const handleCloseDeleteModal = () => setOpenDeleteModal(false);

  //this should update automatically
  const [types, setType] = useState([]);

  const [typeName, setTypeName] = useState('');

  const handleTypeNameChange = (event) => {
    setTypeName(event.target.value);
  };

  const handleAddType = async () => {
    if (typeName === '') {
      return;
    }
    const newType = {
      name: typeName
    };
    try {
      const response = await request(() => createTemplateType(newType));
      if (response?.data?.statusCode === 201) {
        getTemplateTypes();
        setTypeName('');
        showNotifications('success', response.data.message, 5000);
      } else {
        if (response.response.data.statusCode !== 201) {
          showNotifications('error', response.response.data.message, 5000);
        }
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  const handleDeleteType = async (id) => {
    try {
      const response = await request(() => deleteTemplateType(id));
      if (response?.data?.statusCode === 200) {
        getTemplateTypes();
        showNotifications('success', response.data.message, 5000);
      } else {
        if (response.response.data.statusCode !== 200) {
          showNotifications('error', response.response.data.message, 5000);
        }
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  const getTemplateTypes = async () => {
    try {
      const response = await request(() => getAllTemplateType());
      if (response?.data?.statusCode === 200) {
        setType(response.data.body);
      } else {
        if (response.response.data.statusCode !== 200) {
          showNotifications('error', response.response.data.message, 5000);
        }
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - types.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    getTemplateTypes();
  }, []);

  return (
    <React.Fragment>
      <Box
        style={{
          width: '100%',
          display: 'flex',
          gap: '10px',
          justifyContent: 'end',
          marginBottom: '20px',
          alignItems: 'flex-start'
        }}
      >
        <FormControl>
          <Button
            variant="contained"
            sx={{ backgroundColor: 'black' }}
            onClick={handleOpenDeleteModal}
          >
            Add Policy Type
          </Button>
        </FormControl>
      </Box>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 500 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <TableCell
                align="center"
                sx={{
                  fontWeight: 'bold',
                  width: '50px',
                  color: 'white',
                  backgroundColor: 'black'
                }}
              >
                S.No
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  fontWeight: 'bold',
                  minWidth: '110px',
                  color: 'white',
                  backgroundColor: 'black'
                }}
              >
                Policy Type
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  fontWeight: 'bold',
                  width: '50px',
                  color: 'white',
                  backgroundColor: 'black'
                }}
              >
                Actions
              </TableCell>
              {/* <StyledTableCell key={`s.no`} sx={{ width: '50px' }}>
                S.No
              </StyledTableCell>
              <StyledTableCell key={`s.no`}>Policy Type</StyledTableCell>
              <StyledTableCell align="center" sx={{ width: '20px' }}>
                Actions
              </StyledTableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? types.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
              : types
            ).map((type, idx) => (
              <StyledTableRow key={type.id}>
                <StyledTableCell align="center">
                  {page * rowsPerPage + idx + 1}
                </StyledTableCell>
                <StyledTableCell align="center">{type.name}</StyledTableCell>
                <StyledTableCell align="center">
                  <DeleteIcon
                    variant="outlined"
                    size="small"
                    onClick={() => {
                      handleDeleteType(type._id);
                    }}
                  />
                </StyledTableCell>
              </StyledTableRow>
            ))}

            {emptyRows > 0 && (
              <StyledTableRow style={{ height: 53 * emptyRows }}>
                <StyledTableCell colSpan={6} />
              </StyledTableRow>
            )}
          </TableBody>
          <TableFooter>
            <StyledTableRow
              sx={{ borderTop: '1px solid rgba(224, 224, 224, 1)' }}
            >
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                colSpan={3}
                count={types.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    'aria-label': 'rows per page'
                  },
                  native: true
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </StyledTableRow>
          </TableFooter>
        </Table>
      </TableContainer>

      <AddPolicyTypeModal
        openDeleteModal={openDeleteModal}
        handleCloseDeleteModal={handleCloseDeleteModal}
        typeName={typeName}
        handleTypeNameChange={handleTypeNameChange}
        handleAddType={handleAddType}
      />
    </React.Fragment>
  );
};

export default PolicyTypeTable;
