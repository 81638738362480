import React, { useState } from 'react';
import { pdfjs, Document, Page } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import { Button, TextField, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import * as XLSX from 'xlsx';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.js',
  import.meta.url
).toString();

const options = {
  cMapUrl: '/cmaps/',
  standardFontDataUrl: '/standard_fonts/'
};

const maxWidth = 800;

export default function Sample() {
  const [file, setFile] = useState(null);
  const [numPages, setNumPages] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [containerWidth, setContainerWidth] = useState('120%');
  const [selectedTextList, setSelectedTextList] = useState([]);
  const [selectedCellList, setSelectedCellList] = useState([]);

  function onFileChange(event) {
    const { files } = event.target;

    if (files && files[0]) {
      setFile(files[0] || null);
    }
  }

  function onDocumentLoadSuccess({ numPages: nextNumPages }) {
    setNumPages(nextNumPages);
    setCurrentPage(1); // Reset to the first page when a new document is loaded
  }

  const addText = () => {
    if (window.getSelection) {
      var selectionRange = window.getSelection();
      setSelectedTextList([...selectedTextList, selectionRange.toString()]);
    } else {
      if (document.selection.type === 'None') {
        return '';
      } else {
        var textRange = document.selection.createRange();
        setSelectedTextList([...selectedTextList, textRange.text]);
      }
    }
  };

  const deleteText = (index) => {
    const updatedList = [...selectedTextList];
    updatedList.splice(index, 1);
    setSelectedTextList(updatedList);
  };

  const editText = (index, newText) => {
    const updatedList = [...selectedTextList];
    updatedList[index] = newText;
    setSelectedTextList(updatedList);
  };

  const editCell = (index, newText) => {
    const updatedList = [...selectedCellList];
    updatedList[index] = newText;
    setSelectedCellList(updatedList);
  };

  const changePage = (offset) => {
    setCurrentPage((prevPage) =>
      Math.min(Math.max(prevPage + offset, 1), numPages)
    );
  };

  const exportToExcel = () => {
    console.log(selectedTextList);
    console.log(selectedCellList);
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet([{ 'Audit Checklist': '' }]);
    for (let i = 0; i < selectedTextList.length; i++) {
      XLSX.utils.sheet_add_aoa(worksheet, [[selectedTextList[i]]], {
        origin: selectedCellList[i]
      });
    }
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Audit Checklist');
    XLSX.writeFile(workbook, 'Checklist.xlsx');
  };

  return (
    <div className="Example">
      <div className="Example__container">
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <div className="Example__container__load">
            <label htmlFor="file">Load from file:</label>{' '}
            <input onChange={onFileChange} type="file" />
          </div>
        </div>
        <div className="Example__container__document">
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '20px'
            }}
          >
            <Document
              file={file}
              onLoadSuccess={onDocumentLoadSuccess}
              options={options}
            >
              <Page
                key={`page_${currentPage}`}
                pageNumber={currentPage}
                width={
                  containerWidth ? Math.min(containerWidth, maxWidth) : maxWidth
                }
              />
            </Document>
            {file && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  gap: '20px'
                }}
              >
                <div>
                  <Button variant="contained" onClick={addText}>
                    Add text to list
                  </Button>
                </div>
                <div>
                  <p>
                    Page {currentPage} of {numPages}
                  </p>
                  <Button
                    onClick={() => changePage(-1)}
                    disabled={currentPage <= 1}
                  >
                    Previous Page
                  </Button>
                  <Button
                    onClick={() => changePage(1)}
                    disabled={currentPage >= numPages}
                  >
                    Next Page
                  </Button>
                </div>
              </div>
            )}
          </div>
          {selectedTextList.length > 0 && (
            <div>
              <h3>Selected Text List</h3>
              <ul>
                {selectedTextList.map((text, index) => (
                  <div
                    style={{ margin: '20px 0', display: 'flex', gap: '5px' }}
                    key={index}
                  >
                    <TextField
                      variant="outlined"
                      value={text}
                      onChange={(e) => editText(index, e.target.value)}
                    />
                    <TextField
                      variant="outlined"
                      onChange={(e) => editCell(index, e.target.value)}
                    />
                    <IconButton onClick={() => deleteText(index)} color="error">
                      <DeleteIcon />
                    </IconButton>
                  </div>
                ))}
              </ul>
              <div>
                <Button variant="contained" onClick={exportToExcel}>
                  Export to Excel
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
