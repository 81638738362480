export const TaskNonMonetaryHeaders = [
  { id: 'jobName', title: 'Task Name' },
  { id: 'description', title: 'Description' },
  { id: 'isActive', title: 'Ongoing' },
  { id: 'billableStatus', title: 'Billable' },
  { id: 'startDate', title: 'Start Date' },
  { id: 'endDate', title: 'End Date' },
  { id: 'attachment', title: 'Attachment' },
  { id: 'zohoLinkId', title: 'Zoho Link ID' },
  { id: 'status', title: 'Status' },
  { id: 'reminder', title: 'Reminder' },
  { id: 'reminderTime', title: 'Reminder Time' }
];

export const TaskAllHeaders = [
  { id: 'jobName', title: 'Task Name' },
  { id: 'description', title: 'Description' },
  { id: 'isActive', title: 'Ongoing' },
  { id: 'billableStatus', title: 'Billable' },
  { id: 'hours', title: 'Hours' },
  { id: 'ratePerHour', title: 'Rate Per Hour' },
  { id: 'taskBudgetHours', title: 'Task Budget Hours' },
  { id: 'startDate', title: 'Start Date' },
  { id: 'endDate', title: 'End Date' },
  { id: 'attachment', title: 'Attachment' },
  { id: 'zohoLinkId', title: 'Zoho Link ID' },
  { id: 'status', title: 'Status' },
  { id: 'reminder', title: 'Reminder' },
  { id: 'reminderTime', title: 'Reminder Time' }
];
