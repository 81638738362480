import React, { useState, useEffect } from 'react';
import TaskList from '../../components/WorkflowTable/TaskList';
import axios from 'axios';
import { useDataLayerValue } from '../../contextAPI/DataLayer';
import { BACKEND_URL } from '../../config';
import Typography from '@mui/material/Typography';

function AllTasks() {
  const [{ role, roleId, department, departmentId }] = useDataLayerValue();
  const tempObj = {
    role: role,
    department: department,
    status: 'Active/In Progress'
  };
  const [tableObj, setTableObj] = useState([]);

  const getAllTasks = () => {
    document.title = 'Workflow Notifications';
    axios
      .get(BACKEND_URL + `/workflow-notification/${departmentId}/${roleId}`)
      .then((res) => {
        console.log(res);
        const arr = res.data.body;
        let tempArr = [];
        arr.forEach((ele) => {
          tempArr.push({
            activeWorkflowId: ele.activeWorkflowId,
            notificationId: ele._id,
            ...tempObj
          });
        });
        setTableObj(tempArr);
        // console.log(tempArr);
      })
      .catch((er) => {
        console.log(er);
      });
  };

  useEffect(() => {
    getAllTasks();
  }, []);

  useEffect(() => {}, [tableObj]);

  return (
    <div>
      <Typography
        sx={{ m: 1, fontWeight: 'bold', mb: 4, align: 'center' }}
        variant="h4"
      >
        Workflow Notifications
      </Typography>
      {tableObj.length > 0 ? (
        <TaskList tasks={tableObj} onAction={getAllTasks} />
      ) : (
        <Typography
          sx={{ m: 1, mb: 4, align: 'center', paddingBottom: '10px' }}
          variant="h5"
        >
          All Caught up for now
        </Typography>
      )}
    </div>
  );
}

export default AllTasks;
