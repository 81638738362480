import React from 'react';
import { Box, Button, Modal } from '@mui/material';
import AllPolicyTemplateVersions from './AllPolicyTemplateVersions';
import { useNavigate } from 'react-router-dom';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  borderRadius: '10px',
  boxShadow: 24,
  p: 4,
  maxHeight: '100%',
  minWidth: '600px'
};

const VersionPolicyModal = ({
  openVersionModal,
  handleCloseVersionModal,
  selectPolicyId,
  handleDownloadWordVersion
}) => {
  const navigate = useNavigate();

  return (
    <Modal
      open={openVersionModal}
      onClose={handleCloseVersionModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <AllPolicyTemplateVersions
          selectPolicyId={selectPolicyId}
          handleDownloadWordVersion={handleDownloadWordVersion}
        />
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '25px'
          }}
        >
          <Button
            sx={{
              color: 'white',
              backgroundColor: 'black',
              height: '40px'
            }}
            variant="contained"
            onClick={() => {
              navigate(`version/${selectPolicyId}`);
            }}
          >
            Add Version
          </Button>
          <Button variant="contained" onClick={handleCloseVersionModal}>
            Close
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default VersionPolicyModal;
