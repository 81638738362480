import React, { useState } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
// import Box from "@mui/material/Box";
import { v4 as uuidv4 } from 'uuid';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
// import Button from "@mui/material/Button";
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import AddressForm from './AddressForm';
import IdentificationForm from './IdentificationDetails';
import SalaryDetails from './SalaryDetails';
import Rights from './Rights';
import axios from 'axios';
import { useDataLayerValue } from '../../contextAPI/DataLayer';
import { useNotifications } from '../../hooks/useNotifications';
// import { HRRights, clientRights } from "../rightsMap";
import { BACKEND_URL } from '../../config';
function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const steps = [
  'Personal Details 1',
  'Personal Details 2',
  'Salary Details',
  'Roles and Rights'
];

const theme = createTheme();

export default function Checkout() {
  const { showNotifications } = useNotifications();
  const [{ _id, fname, lname, email }] = useDataLayerValue();
  const [inputFields, setInputFields] = useState([
    { id: uuidv4(), key: '', val: '' }
  ]);

  const [employeeDetails, setEmployeeDetails] = useState({
    fname: '',
    lname: '',

    about: '',
    // role: { type: String }, //super admin and All
    employmentType: '',
    // department: '',
    // role: '',
    designation: '',
    gender: '',
    address: '',
    city: '',
    state: '',
    zip: '',
    country: '',
    phone: '',
    email: '',
    dateOfBirth: '',
    dateOfJoining: '',
    dateOfExit: '',
    maritalStatus: '',

    reportingManager: '',
    reportingManagerEmail: '',
    reportingManagerName: '',

    tags: '',

    aadharNumber: '',
    SSN: '',
    PAN: '',
    bankAccountNumber: '',
    IFSCCode: '',

    //salary information
    ctc: '',
    grossSalary: '',
    baseSalary: '',
    HRA: '',
    medicalAllowance: '',
    conveyanceAllowance: '',
    specialAllowance: '',

    PFContribution: '',
    ESIContribution: '',
    PTDeduction: '',
    totalDeductions: '',
    netSalary: ''

    //rights defined
    // additionalDetails: { type: Object },
  });

  const [role, setRole] = useState({
    _id: '',
    name: '',
    profiles: []
  });

  const [dept, setDept] = useState({
    _id: '',
    name: ''
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setEmployeeDetails((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const handleRoleChange = (e) => {
    const { value } = e.target;
    setRole(value);
  };

  const handleDeptChange = (e) => {
    const { value } = e.target;
    setDept(value);
  };
  const [activeStep, setActiveStep] = React.useState(0);

  let object;

  const handleVarFieldSubmit = () => {
    object = inputFields.reduce(
      (obj, item) => Object.assign(obj, { [item.key]: item.val }),
      {}
    );
    console.log('Variable Field: ', object);
  };

  const handleNext = (e) => {
    e.preventDefault();
    if (activeStep === steps.length - 1) {
      const submissionObj = {
        ...employeeDetails,
        department: dept.name,
        departmentId: dept._id,
        role: role.name,
        roleId: role._id,
        profile: role.profiles,
        additionalDetails: object,
        addedByID: _id,
        addedByName: `${fname} ${lname}`,
        addedByEmail: email
      };
      console.log('Submission Object: ', submissionObj);
      axios
        .post(BACKEND_URL + '/employee/add', submissionObj, {
          headers: {
            employeeid: _id
          }
        })
        .then((res) => {
          console.log(res.data);
          if (res.data.statusCode === 201) {
            setActiveStep(activeStep + 1);
            console.log(res.data);
          } else showNotifications('error', res.data.message, 2000);
        })
        .catch((err) => showNotifications('error', err.data.message, 2000));
    } else setActiveStep(activeStep + 1);
  };

  // const handleRights = (e) => {
  // 	const { name, value } = e.target;
  // 	console.log(name, value);
  // 	setRights((prev) => {
  // 		return { ...prev, [name]: !rights[name] };
  // 	});
  // };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const handleRemoveFields = (id) => {
    const values = [...inputFields];
    values.splice(
      values.findIndex((value) => value.id === id),
      1
    );
    setInputFields(values);
  };

  const handleAddFields = () => {
    setInputFields([...inputFields, { id: uuidv4(), key: '', val: '' }]);
  };

  const handleVarFieldInput = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i[event.target.name] = event.target.value;
      }
      return i;
    });

    setInputFields(newInputFields);
  };

  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <AddressForm
            handleNext={handleNext}
            handleChange={handleChange}
            employeeDetails={employeeDetails}
          />
        );
      case 1:
        return (
          <IdentificationForm
            handleNext={handleNext}
            handleBack={handleBack}
            handleChange={handleChange}
            employeeDetails={employeeDetails}
          />
        );
      case 2:
        return (
          <SalaryDetails
            handleNext={handleNext}
            handleBack={handleBack}
            handleChange={handleChange}
            employeeDetails={employeeDetails}
          />
        );
      case 3:
        return (
          <Rights
            inputFields={inputFields}
            handleVarFieldInput={handleVarFieldInput}
            handleVarFieldSubmit={handleVarFieldSubmit}
            handleAddFields={handleAddFields}
            handleRemoveFields={handleRemoveFields}
            handleNext={handleNext}
            handleBack={handleBack}
            handleChange={handleChange}
            employeeDetails={employeeDetails}
            handleRoleChange={handleRoleChange}
            role={role}
            dept={dept}
            handleDeptChange={handleDeptChange}
            // handleRights={handleRights}
            // rights={rights}
          />
        );
      default:
        throw new Error('Unknown step');
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container component="main" maxWidth="md" sx={{ mb: 4 }}>
        <Paper
          variant="outlined"
          sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
        >
          <Typography component="h1" variant="h4" align="center">
            Add Employee
          </Typography>
          <Stepper activeStep={activeStep} sx={{ pt: 3, pb: 5 }}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <React.Fragment>
            {activeStep === steps.length ? (
              <React.Fragment>
                <Typography variant="h5" gutterBottom>
                  Employee Added
                </Typography>
                <Typography variant="subtitle1">
                  Employee was created successfully.
                </Typography>
              </React.Fragment>
            ) : (
              <React.Fragment>{getStepContent(activeStep)}</React.Fragment>
            )}
          </React.Fragment>
        </Paper>
        <Copyright />
      </Container>
    </ThemeProvider>
  );
}
