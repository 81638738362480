import React, { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import { Button, Typography } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { useNavigate, useParams } from 'react-router-dom';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useLoading } from '../../../hooks/useLoading';
import { useNotifications } from '../../../hooks/useNotifications';
import {
  getAllIndustry,
  getClientsRequest,
  getPolicyEngagementById,
  updatePolicyEngagementById
} from '../../../constants/requests';
import TextField from '@mui/material/TextField';
import { country_list } from '../../../constants/countryList';

const EditPolicyEngagement = () => {
  const navigate = useNavigate();
  const { request } = useLoading();
  const { showNotifications } = useNotifications();
  const params = useParams();

  const [industrySubIndustry, setIndustrySubIndustry] = useState({});
  const [firstDropdownValue, setFirstDropdownValue] = useState('');
  const [secondDropdownValue, setSecondDropdownValue] = useState('');

  const [formOptions, setFormOptions] = useState({
    clientName: '',
    industry: '',
    subIndustry: '',
    country: ''
  });

  const setFormOptionsHandler = (value, type) => {
    const newFormOptions = { ...formOptions };
    newFormOptions[type] = value;
    setFormOptions(newFormOptions);
  };

  const [dropDownOptions, setDropDownOptions] = useState({
    clients: [],
    industry: Object.keys(industrySubIndustry),
    country: country_list
  });

  const [file, setFile] = useState(null);
  const handleFileSelection = (event) => {
    setFile(event.target.files[0]);
  };

  const getClients = async () => {
    try {
      const response = await request(() => getClientsRequest());
      if (response?.data?.statusCode === 200) {
        const clients = response.data.body.map(({ _id, name }) => ({
          id: _id,
          name
        }));
        const newDropDownOptions = { ...dropDownOptions, clients };
        getIndustries(newDropDownOptions);
      } else {
        if (response.response.data.statusCode !== 200) {
          showNotifications('error', response.response.data.message, 5000);
        }
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  const getIndustries = async (newDropDownOptions) => {
    try {
      const response = await request(() => getAllIndustry());
      if (response?.data?.statusCode === 200) {
        const newIndustrySubIndustry = {};
        response.data.body.map((data) => {
          newIndustrySubIndustry[data.industry] = data.subIndustry;
        });
        setIndustrySubIndustry(newIndustrySubIndustry);
        setDropDownOptions({
          ...newDropDownOptions,
          industry: Object.keys(newIndustrySubIndustry)
        });
        getCurrentEngagementData();
      } else {
        if (response.response.data.statusCode !== 200) {
          showNotifications('error', response.response.data.message, 5000);
        }
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  const getCurrentEngagementData = async () => {
    try {
      const response = await request(() => getPolicyEngagementById(params.id));
      if (response?.data?.statusCode === 200) {
        const { clientName, industry, subIndustry, country } =
          response.data.body;
        const newFormOptions = { ...formOptions };
        newFormOptions['clientName'] = clientName;
        newFormOptions['industry'] = industry;
        newFormOptions['subIndustry'] = subIndustry;
        newFormOptions['country'] = country;
        setFormOptions(newFormOptions);
        setFirstDropdownValue(industry);
        setSecondDropdownValue(subIndustry);
      } else {
        if (response.response.data.statusCode !== 200) {
          showNotifications('error', response.response.data.message, 5000);
        }
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  const editClientPolicyHandler = async () => {
    const inputFieldsToBeChecked = [
      'clientName',
      'industry',
      'subIndustry',
      'country'
    ];
    for (let i = 0; i < inputFieldsToBeChecked.length; i++) {
      const fieldName = inputFieldsToBeChecked[i];
      if (formOptions[fieldName] === '') {
        showNotifications('error', `Please fill the ${fieldName}`, 5000);
        return;
      }
    }
    const formData = new FormData();
    formData.append('clientName', formOptions.clientName);
    formData.append('industry', formOptions.industry);
    formData.append('subIndustry', formOptions.subIndustry);
    formData.append('country', formOptions.country);
    formData.append('file', file);
    try {
      const response = await request(() =>
        updatePolicyEngagementById(params.id, formData)
      );
      if (response?.data?.statusCode === 200) {
        navigate('/policy/engagement');
        showNotifications('success', 'Client updated successfully', 5000);
      } else {
        if (response.response.data.statusCode !== 200) {
          showNotifications('error', response.response.data.message, 5000);
        }
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  useEffect(() => {
    getClients();
  }, []);

  const dropDownOptionsCreator = (type) => {
    return dropDownOptions[type].map((value) => (
      <MenuItem value={value} key={value}>
        {value}
      </MenuItem>
    ));
  };

  const createDropDownOptionsIndustry = (industry) => {
    if (!industry) return <MenuItem>Empty</MenuItem>;
    return industrySubIndustry[industry].map((value) => (
      <MenuItem value={value} key={value}>
        {value}
      </MenuItem>
    ));
  };

  return (
    <React.Fragment>
      <Box
        style={{
          marginBottom: '25px',
          display: 'flex',
          justifyContent: 'space-between'
        }}
      >
        <Button
          sx={{
            color: 'white',
            backgroundColor: 'black',
            paddingLeft: 1
          }}
          variant="contained"
          onClick={() => {
            navigate('/policy/engagement');
          }}
        >
          <ChevronLeftIcon />
          Back
        </Button>
      </Box>

      <Box
        style={{
          display: 'flex',
          justifyContent: 'start',
          alignItems: 'center'
        }}
      >
        <Typography
          sx={{ fontWeight: 'bold', marginBottom: '20px' }}
          variant="h4"
        >
          Edit Policy Engagement
        </Typography>
      </Box>

      {/* Now I have to the textFields which are all there simple  */}
      <Box>
        <Typography
          sx={{ fontWeight: 'bold', marginRight: '50%', marginBottom: '20px' }}
          variant="h5"
        >
          Client Information
        </Typography>
        <Box>
          <Box
            style={{
              width: '100%',
              alignItems: 'center',
              gap: '10px',
              marginBottom: '13px',
              display: 'flex'
            }}
          >
            <TextField
              id="outlined-basic"
              label="Client Name"
              variant="outlined"
              fullWidth
              value={formOptions.clientName}
              onChange={(event) => {
                setFormOptionsHandler(event.target.value, 'clientName');
              }}
            />
            <TextField
              type="file"
              name="master"
              label="Re-Upload Logo"
              fullWidth
              // onChange={(e) => setSelectedFile(e.target.files[0])}
              onChange={handleFileSelection}
              InputLabelProps={{ shrink: true }}
            />
          </Box>
          <Box
            style={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              gap: '10px'
            }}
          >
            <FormControl style={{ width: '100%' }}>
              <InputLabel id="first-dropdown-label">Industry</InputLabel>
              <Select
                labelId="first-dropdown-label"
                label="Industry"
                value={firstDropdownValue}
                onChange={(event) => {
                  setFormOptionsHandler(event.target.value, 'industry');
                  setFirstDropdownValue(event.target.value);
                  setSecondDropdownValue('');
                }}
              >
                {dropDownOptionsCreator('industry')}
              </Select>
            </FormControl>

            <FormControl style={{ width: '100%' }}>
              <InputLabel id="second-dropdown-label">Sub Industry</InputLabel>
              <Select
                labelId="second-dropdown-label"
                label="Sub Industry"
                value={secondDropdownValue}
                onChange={(event) => {
                  setFormOptionsHandler(event.target.value, 'subIndustry');
                  setSecondDropdownValue(event.target.value);
                }}
                disabled={!firstDropdownValue}
              >
                {createDropDownOptionsIndustry(firstDropdownValue)}
              </Select>
            </FormControl>
            <FormControl style={{ width: '100%' }}>
              <InputLabel id="demo-simple-select-label">Country</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={formOptions.country}
                label="Country"
                onChange={(event) =>
                  setFormOptionsHandler(event.target.value, 'country')
                }
              >
                {dropDownOptionsCreator('country')}
              </Select>
            </FormControl>
          </Box>
        </Box>
      </Box>

      <Box style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          variant="contained"
          style={{
            marginTop: '20px',
            fontSize: '18px'
          }}
          onClick={() => {
            editClientPolicyHandler();
          }}
        >
          Update
        </Button>
      </Box>
    </React.Fragment>
  );
};

export default EditPolicyEngagement;
