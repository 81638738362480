import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/material';
import {
  updateRCMRequest,
  getRCMByIdRequest,
  generateSentence
} from '../../../constants/requests';
import { useLoading } from '../../../hooks/useLoading';
import { useNotifications } from '../../../hooks/useNotifications';
import { stickyColumnTemplateMapping } from '../common/stickyColumnTemplateMapping';
import CustomTable from '../common/CustomTable';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const Section3 = () => {
  const navigate = useNavigate();
  const { request } = useLoading();
  const { showNotifications } = useNotifications();
  const { engagementId, applicationId, rcmId } = useParams();
  const [Section3Data, setSection3Data] = useState(null);
  const [controlMapping, setControlMapping] = useState({});
  const [generateButtonDisable, setGenerateButtonDisable] = useState([]);

  const handleChange = (rowNumber, fieldName, cellValue, tabName) => {
    const modifiedSectionData = { ...Section3Data };
    modifiedSectionData.rows[tabName][rowNumber][fieldName] = cellValue;
    // For SOC2.0 only
    if (fieldName === 'Control No.') {
      let controlActivity = '';
      for (const control of cellValue) {
        controlActivity += controlMapping[control] + '(' + control + ') ';
      }
      modifiedSectionData.rows[tabName][rowNumber]['Control Description'] =
        controlActivity;
    }
    setSection3Data(modifiedSectionData);
    if (
      fieldName === 'Automated/Manual(Where it is done)' ||
      fieldName === 'System (If automated which system)' ||
      fieldName === 'Which Way(if manual)' ||
      fieldName === 'How it is done'
    ) {
      let generateButtonStatus = [...generateButtonDisable];
      generateButtonStatus[rowNumber] = false;
      setGenerateButtonDisable(generateButtonStatus);
    }
  };

  const loadApplication = async () => {
    try {
      const res = await request(() => getRCMByIdRequest(rcmId));
      if (res.data.statusCode === 200) {
        let controlActivityMap = {},
          controls = [],
          controlList = [];
        for (const header of Object.keys(res.data.body.data.rows)) {
          for (const row of res.data.body.data.rows[header]) {
            controlActivityMap[row['Control No.']] = row['Control Activity'];
            if (row['Control No.'] !== '') {
              const option = {
                label: row['Control No.'],
                value: row['Control No.']
              };
              controls.push(option);
              controlList.push(row['Control No.']);
            }
          }
        }
        res.data.body.Section3Data.meta.fields['Control No.']['options'] =
          controls;
        setControlMapping(controlActivityMap);

        for (const header of Object.keys(res.data.body.Section3Data.rows)) {
          for (const row of res.data.body.Section3Data.rows[header]) {
            row['Control No.'] = row['Control No.'].filter((el) =>
              controlList.includes(el)
            );

            let controlActivity = '';
            for (const controls of row['Control No.']) {
              controlActivity +=
                controlActivityMap[controls] + '(' + controls + ') ';
              row['Control Description'] = controlActivity;
            }
          }
        }

        let generateButtonStatus = [];
        for (let i = 0; i < res.data.body.Section3Data.rows.length; i++) {
          generateButtonStatus.push(false);
        }
        setGenerateButtonDisable(generateButtonStatus);

        const payload = {
          Section3Data: res.data.body.Section3Data
        };
        const response = await request(() => updateRCMRequest(rcmId, payload));
        if (response.data.statusCode === 200) {
          setSection3Data(res.data.body.Section3Data);
          showNotifications('success', response.data.message, 5000);
        } else {
          showNotifications('error', response.data.message, 5000);
        }
        showNotifications('success', res.data.message, 5000);
      } else {
        showNotifications('error', res.data.message, 5000);
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  const handleSave = async () => {
    try {
      const payload = {
        Section3Data: Section3Data
      };
      let response = await request(() => updateRCMRequest(rcmId, payload));
      if (response.data.statusCode === 200) {
        showNotifications('success', response.data.message, 5000);
      } else {
        showNotifications('error', response.data.message, 5000);
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
      console.log(err);
    }
  };

  const generateSentenceFromKeyword = async (keywordObject, rowNumber) => {
    try {
      let newKeywordObject = {};

      newKeywordObject['Control Activity'] =
        keywordObject['keywordObject'][
          'Control Activity (What is done-Action sentence)/Sub Process Desciption'
        ];
      newKeywordObject['Control Performer'] =
        keywordObject['keywordObject'][
          'Control Performer(By whom action is performed)'
        ];
      newKeywordObject['Frequency'] =
        keywordObject['keywordObject']['Frequency(When it is done)'];
      newKeywordObject['Automated/Manual'] =
        keywordObject['keywordObject']['Automated/Manual(Where it is done)'];
      newKeywordObject['System (If Automated)'] =
        keywordObject['keywordObject']['System (If automated which system)'];
      newKeywordObject['Which Way (If Manual)'] =
        keywordObject['keywordObject']['Which Way(if manual)'];
      newKeywordObject['How It Is Done'] =
        keywordObject['keywordObject']['How it is done'];

      const response = await request(() =>
        generateSentence({ keywordObject: newKeywordObject })
      );
      if (response.data.statusCode === 200) {
        handleChange(
          rowNumber,
          'Final Description',
          response.data.body,
          'SectionsHeader'
        );
        let generateButtonStatus = [...generateButtonDisable];
        generateButtonStatus[rowNumber] = true;
        setGenerateButtonDisable(generateButtonStatus);
        showNotifications('success', response.data.message, 200);
      } else {
        showNotifications('error', response.data.message, 5000);
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  useEffect(() => {
    loadApplication();
  }, []);

  return (
    <React.Fragment>
      <Box
        style={{
          marginBottom: '25px',
          display: 'flex',
          justifyContent: 'space-between'
        }}
      >
        <Button
          sx={{
            color: 'white',
            backgroundColor: 'black',
            paddingLeft: 1
          }}
          variant="contained"
          onClick={() => {
            navigate(
              `/services/engagement/${engagementId}/application/${applicationId}/rcm`
            );
          }}
        >
          <ChevronLeftIcon />
          RCM
        </Button>
        <Button
          sx={{
            color: 'white',
            backgroundColor: 'black',
            paddingLeft: 1
          }}
          variant="contained"
          onClick={() => {
            navigate(
              `/services/engagement/${engagementId}/application/${applicationId}/rcm/${rcmId}/cuec-csoc`
            );
          }}
        >
          CUEC/CSOC
          <ChevronRightIcon />
        </Button>
      </Box>
      <Box
        style={{
          marginBottom: '25px',
          display: 'flex',
          justifyContent: 'space-between'
        }}
      >
        <Typography sx={{ fontWeight: 'bold' }} variant="h4">
          SECTION - 3
        </Typography>
        <Box
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            gap: '10px'
          }}
        >
          <Button variant="contained" color="success" onClick={handleSave}>
            Save
          </Button>
        </Box>
      </Box>
      {Section3Data !== null && Section3Data.rows.SectionsHeader.length > 0 ? (
        <CustomTable
          tabName="SectionsHeader"
          handleChangeCB={handleChange}
          fieldsMeta={Section3Data.meta.fields}
          rows={Section3Data.rows.SectionsHeader}
          tableMeta={Section3Data.meta.SectionsHeader}
          stickyColumnName={stickyColumnTemplateMapping['Section3']['column']}
          topStyle={stickyColumnTemplateMapping['Section3']['style']}
          miscellaneous={generateSentenceFromKeyword}
          generateButtonStatus={generateButtonDisable}
        />
      ) : null}
    </React.Fragment>
  );
};

export default Section3;
