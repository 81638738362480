import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { IconButton } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import { BACKEND_URL } from '../../config';
import axios from 'axios';
import { useDataLayerValue } from '../../contextAPI/DataLayer';
import { useNotifications } from '../../hooks/useNotifications';
import { useNavigate } from 'react-router-dom';
//add a dropdown for internal/ext
//external ke liye alag
//ways as a dropdown
export default function WorkflowForm() {
  const { showNotifications } = useNotifications();
  const navigate = useNavigate();
  const [internalOrExternal, setIe] = useState('');
  const [qualitativeOrQuantitative, setQq] = useState('');
  const [workflowDetails, setWorkflowDetails] = useState({
    name: ''
  });
  const [approverNo, setApproverNo] = useState(2);

  const [inputFields, setInputFields] = useState([
    { id: uuidv4(), field: '', operation: '', value: '' }
  ]);
  const [{ _id }] = useDataLayerValue();

  const [roles, setRoles] = useState([]);
  const [depts, setDepts] = useState([]);
  const [entities, setEntities] = useState([]);
  const [approvalFlow, setApprovalFlow] = useState([]);

  const [properties, setProperties] = useState([]);
  const [entityId, setEntityId] = useState('');

  const [endpoints, setEndpoints] = useState([]);
  const [selectedEndpoint, setSelectedEndpoint] = useState({});

  const operations = [
    'Equals',
    'Not Equal To',
    'Greater Than',
    'Less Than',
    'Greater Than Equal To',
    'Less Than Equal To'
  ];

  const handleChange = (event) => {
    const { name, value } = event.target;
    setWorkflowDetails((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const handleApprovers = (e) => {
    console.log(e.target.value);
    const arr = [...new Array(e.target.value)].map(() => ({
      id: uuidv4(),
      roleId: '',
      departmentId: ''
    }));
    setApprovalFlow(arr);
    // console.log(approvalFlow);
    setApproverNo(e.target.value);
  };

  const handleRemoveFields = (id) => {
    const values = [...inputFields];
    values.splice(
      values.findIndex((value) => value.id === id),
      1
    );
    setInputFields(values);
  };

  const handleAddFields = () => {
    setInputFields([
      ...inputFields,
      { id: uuidv4(), field: '', operation: '', value: '' }
    ]);
  };

  const handleVarFieldInput = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i[event.target.name] = event.target.value;
      }
      return i;
    });

    setInputFields(newInputFields);
  };

  const handleApprovalFlowInput = (id, event) => {
    const newInputFields = approvalFlow.map((i) => {
      if (id === i.id) {
        i[event.target.name] = event.target.value;
      }
      return i;
    });

    setApprovalFlow(newInputFields);
  };

  useEffect(() => {
    async function getEntities() {
      try {
        const res = await axios.get(BACKEND_URL + '/entities', {
          headers: {
            employeeid: _id
          }
        });
        // console.log(res.data.body.registeredEntities);
        setEntities(res.data.body.registeredEntities);

        const deptres = await axios.get(BACKEND_URL + '/departments', {
          headers: {
            employeeid: _id
          }
        });
        setDepts(deptres.data.body);
        const rolesres = await axios.get(BACKEND_URL + '/roles', {
          headers: {
            employeeid: _id
          }
        });
        setRoles(rolesres.data.body);
      } catch (er) {
        console.log(er);
      }
    }
    getEntities();
  }, []);

  useEffect(() => {}, [entities, roles, depts]);

  function handleSubmit(e) {
    e.preventDefault();
    const submissionObj = {
      name: workflowDetails.name,
      internalOrExternal: internalOrExternal,
      qualitativeOrQuantitative: qualitativeOrQuantitative,
      activityId: entityId,
      constraints: inputFields.map((obj) => {
        delete obj['id'];
        return obj;
      }),
      approvalFlow: approvalFlow.map((obj) => {
        delete obj['id'];
        return obj;
      }),
      endPoint: selectedEndpoint
    };
    console.log('Submission Object => ', submissionObj);
    axios
      .post(BACKEND_URL + '/workflow/', submissionObj, {
        headers: {
          employeeid: _id
        }
      })
      .then((res) => {
        console.log(res);
        if (res.data.statusCode === 201) {
          showNotifications('success', res.data.message, 2000);
          navigate('/');
        } else {
          showNotifications('error', res.data.message, 2000);
        }
      })
      .catch((e) => {
        console.log(e);
        showNotifications('error', e.data.message, 2000);
      });
  }

  return (
    <Container component="main" maxWidth="md" sx={{ mb: 4 }}>
      <Paper
        variant="outlined"
        sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
      >
        <Typography component="h1" variant="h4" align="center">
          Add a Workflow Type
        </Typography>
        <br />
        <React.Fragment>
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{ mt: 3, ml: 3, mr: 3 }}
          >
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  autoFocus
                  required
                  id="name"
                  name="name"
                  label="Name of the Workflow Type"
                  fullWidth
                  variant="standard"
                  value={workflowDetails.name}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl>
                  <FormLabel id="demo-radio-buttons-group-label">
                    Internal or External*
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    name="radio-buttons-group1"
                    value={internalOrExternal}
                    onChange={(e) => setIe(e.target.value)}
                    required
                  >
                    <FormControlLabel
                      value="internal"
                      control={<Radio />}
                      label="Internal"
                    />
                    <FormControlLabel
                      value="external"
                      control={<Radio />}
                      label="External"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl>
                  <FormLabel id="demo-radio-buttons-group-label">
                    Qualitative or Quantitative*
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    name="radio-buttons-group2"
                    value={qualitativeOrQuantitative}
                    onChange={(e) => setQq(e.target.value)}
                  >
                    <FormControlLabel
                      value="qualitative"
                      control={<Radio />}
                      label="Qualitative"
                    />
                    <FormControlLabel
                      value="quantitative"
                      control={<Radio />}
                      label="Quantitative"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl variant="standard" sx={{ m: 0, minWidth: 180 }}>
                  <InputLabel id="demo-simple-select-standard-label">
                    Select Entity*
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    defaultValue={''}
                    onChange={(e) => {
                      // console.log(e.target.value.endpoint);
                      setEntityId(e.target.value.id);
                      setProperties(e.target.value.properties);
                      setEndpoints(e.target.value.endpoint);
                    }}
                    label="Select Entity"
                    name="entity"
                    variant="standard"
                    fullWidth
                    required
                  >
                    {entities.map((entity, idx) => {
                      return (
                        <MenuItem value={entity} key={idx}>
                          {entity.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl variant="standard" sx={{ m: 0, minWidth: 180 }}>
                  <InputLabel id="demo-simple-select-standard-label">
                    Select Endpoint*
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    defaultValue={''}
                    onChange={(e) => {
                      setSelectedEndpoint(e.target.value);
                    }}
                    label="Select Endpoint"
                    name="endpoint"
                    variant="standard"
                    fullWidth
                    required
                  >
                    {endpoints.map((entity, idx) => {
                      return (
                        <MenuItem value={entity} key={idx}>
                          {entity.label}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Typography component="h6" variant="h6">
                  Enter Workflow Attributes
                </Typography>
                <br />
                {inputFields.map((field) => (
                  <Grid container spacing={6} key={field.id}>
                    <Grid item xs={3}>
                      <FormControl
                        variant="standard"
                        sx={{ m: 0, minWidth: 180 }}
                      >
                        <InputLabel id="demo-simple-select-standard-label">
                          Field*
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-standard-label"
                          defaultValue={''}
                          id="field"
                          name="field"
                          label="Field"
                          variant="standard"
                          fullWidth
                          onChange={(e) => handleVarFieldInput(field.id, e)}
                          required
                        >
                          {properties.map((property, idx) => {
                            return (
                              <MenuItem value={property} key={idx}>
                                {property}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                      <FormControl
                        variant="standard"
                        sx={{ m: 0, minWidth: 180 }}
                      >
                        <InputLabel id="demo-simple-select-standard-label">
                          Operation*
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-standard-label"
                          defaultValue={''}
                          id="operation"
                          name="operation"
                          label="Operation"
                          variant="standard"
                          fullWidth
                          onChange={(e) => handleVarFieldInput(field.id, e)}
                          required
                        >
                          {operations.map((operation, idx) => {
                            return (
                              <MenuItem value={operation} key={idx}>
                                {operation}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        id="value"
                        name="value"
                        value={field.value}
                        onChange={(e) => handleVarFieldInput(field.id, e)}
                        label="Value"
                        fullWidth
                        autoComplete="value"
                      />
                    </Grid>
                    <Grid item xs={1}>
                      <IconButton
                        disabled={inputFields.length === 1}
                        onClick={() => handleRemoveFields(field.id)}
                      >
                        <RemoveIcon />
                      </IconButton>
                    </Grid>
                    <Grid item xs={1}>
                      <IconButton onClick={handleAddFields}>
                        <AddIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
              <Grid item xs={12}>
                <Typography component="h6" variant="h6">
                  Configure the Approval Flow
                </Typography>
                <br />
                <FormControl variant="standard" sx={{ m: 0, minWidth: 180 }}>
                  <InputLabel id="demo-simple-select-standard-label">
                    Number of Ways*
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={approverNo}
                    onChange={handleApprovers}
                    label="Number of Ways"
                    name="noOfApprovers"
                    variant="standard"
                    required
                  >
                    <MenuItem value={1}>2 Way</MenuItem>
                    <MenuItem value={2}>3 Way</MenuItem>
                    <MenuItem value={3}>4 Way</MenuItem>
                    <MenuItem value={4}>5 Way</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              {approvalFlow.map((flow, idx) => (
                <React.Fragment key={flow.id}>
                  <Grid item xs={12} sm={5}>
                    <FormControl
                      variant="standard"
                      sx={{ m: 0, minWidth: 180 }}
                    >
                      <InputLabel id="demo-simple-select-standard-label">
                        {`Approver ${idx + 1} Role*`}
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-standard-label"
                        defaultValue={''}
                        id="roleId"
                        name="roleId"
                        label="Role"
                        variant="standard"
                        fullWidth
                        onChange={(e) => handleApprovalFlowInput(flow.id, e)}
                        required
                      >
                        {roles.map((role, idx) => {
                          return (
                            <MenuItem value={role._id} key={idx}>
                              {role.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={5}>
                    <FormControl
                      variant="standard"
                      sx={{ m: 0, minWidth: 180 }}
                    >
                      <InputLabel id="demo-simple-select-standard-label">
                        Department*
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-standard-label"
                        defaultValue={''}
                        id="department"
                        name="departmentId"
                        label="Department"
                        variant="standard"
                        fullWidth
                        onChange={(e) => handleApprovalFlowInput(flow.id, e)}
                        required
                      >
                        {depts.map((dept, idx) => {
                          return (
                            <MenuItem value={dept._id} key={idx}>
                              {dept.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                </React.Fragment>
              ))}
              <Grid item xs={12}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end'
                  }}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    // onClick={handleNext}
                    sx={{ mt: 3, ml: 1 }}
                  >
                    {'Add Workflow Action'}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </React.Fragment>
      </Paper>
    </Container>
  );
}
